import { Injectable } from '@angular/core';
import { ClaimExternalAssignmentService } from '../../../shared/services/claim-external-assignment.service';
import { ClaimAssignmentVM, ClaimRequestVM } from './claim-assignment.model';

@Injectable({
  providedIn: 'root'
})
export class ClaimAssignmentService {

  constructor(private claimExternalAssignmentService: ClaimExternalAssignmentService) { }
    

  getClaim(claimRequest: ClaimRequestVM): any {    
  return this.claimExternalAssignmentService.getClaim(claimRequest);
  }

  updateClaimAssignment(assignment: ClaimAssignmentVM): any {
    return this.claimExternalAssignmentService.claimAssignment(assignment);
  }


}

export class PaymentBordereaux {
  paymentID: number;
  paymentRecovery: string;
  handlingOrganisationId: number;
  handlingOrganisationName: string;
  claimant: string;
  policyHolder: string;
  policyHolderID: number;
  policyHolderStatus: string;
  claimType: string;
  fscsProtected: string;
  paymentCode: string;
  paymentType: string;
  paymentAmount: number;
  payeeType: string;
  payeeName: string;
  paymentMethod: string;
  addressName: string;
  addressLine1: string;
  addressLine2: string;
  town: string;
  county: string;
  postCode: string;
  sortCode: string;
  accountNumber: string;
  paymentStatus: string;
  requestedby: string;
  requestedDate: Date;
  chequeNo: string;
  approvedStage1: string;
  approvedByIdStage1: number;
  approvedByStage1: string;
  approvedDateStage1: Date;
  approvedByIdStage2: number;
  approvedByStage2: string;
  approvedDateStage2: Date;
  isSelected: boolean;
  pageIndex: number;
  pageSize: number;
  sortBy?: string;
  sortOrder?: string;
  payee: string;
}

import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { ConfigurationService } from "./configuration.service";
import { tap } from "rxjs/operators";
import { StorageService } from "./storage.service";
import { HttpClient } from "@angular/common/http";
import * as _ from "lodash";
import { Subject } from "rxjs";

@Injectable()
export class LookupService {
  private lookupUrl: string = '';
  private settingsLoadedSource = new Subject();
  settingsLoaded$ = this.settingsLoadedSource.asObservable();
  constructor(private service: DataService, private configurationService: ConfigurationService, private storageService: StorageService, private http: HttpClient, ) {

  }

  load() {
    this.configurationService.settingsLoaded$.subscribe(x => {
      this.lookupUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + '/api/lookup';
      //return this.service.get(this.lookupUrl).pipe<any>(tap((response: any) => {
      //  this.storageService.store("lookups", response);
      //  this.settingsLoadedSource.next();
      //  return;
      //}));
      this.http.get(this.lookupUrl).subscribe((response) => {
        this.storageService.store("lookups", response);
        this.settingsLoadedSource.next();
      });
    });
  }

  getLookupsByTypeId(typeId) {
    var lookups = this.storageService.retrieve("lookups");
    let filteredlookups = _.filter(lookups, function (o) { return o.lookupTypeId == typeId; });
    let sortedlookups = filteredlookups.sort(function (a, b) {
      return a.displayOrder - b.displayOrder
    })

    return sortedlookups;
  }

  getLookupsByTypeName(typeName) {
    var lookups = this.storageService.retrieve("lookups");
    return _.filter(lookups, function (o) { return o.lookupTypeName == typeName; });
  }

  getLookupById(id) {
    var lookups = this.storageService.retrieve("lookups");
    return _.filter(lookups, function (o) { return o.id == id; });
  }
  getLookupByParentId(parentId) {
    var lookups = this.storageService.retrieve("lookups");
    return _.filter(lookups, function (o) { return o.parentLookupValueId == parentId && parentId != null; });
  }
}

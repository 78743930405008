import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElLayerComponent } from './el-layer/el-layer.component';
import { TpLayerComponent } from './tp-layer/tp-layer.component';
import { IndexationComponent } from './indexation/indexation.component';
import { WeeklyWagesComponent } from './weekly-wages/weekly-wages.component';
import { ElCoverComponent } from './el-cover/el-cover.component';
import { TpCoverComponent } from './tp-cover/tp-cover.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  declarations: [
ElLayerComponent,
TpLayerComponent,
IndexationComponent,
WeeklyWagesComponent,
ElCoverComponent,
TpCoverComponent
],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    SharedModule
  ],
  providers: []
})
export class ReinsuranceModule { }

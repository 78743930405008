import { Injectable } from '@angular/core';
import { ReinsuranceService } from '../../../shared/services/reinsurance.service';
import { IELLayer } from './el-layer.model';

@Injectable({
  providedIn: 'root'
})
export class ELLayerService {

  formData: IELLayer[] = [];

  constructor(private reinsuranceService: ReinsuranceService) { }

  getELLayerDetails() {
    //debugger
    return this.reinsuranceService.getELLayerDetails();
  }

}

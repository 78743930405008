import { Injectable } from '@angular/core';
import { LitigationService } from '../../../shared/services/litigation.service'
import { Observable } from 'rxjs';
import { Ilitigation } from './litigation.model'
import { IlitigationNote } from './litigation-note.model'


@Injectable({
  providedIn: 'root'
})
export class ClaimLitigationService {

  formData: Ilitigation;
  constructor(private litigationService: LitigationService) {
  }

  getLitigationById(id: number): any {
    return this.litigationService.getLitigationById(id);
  }
  getLitigationByClaimId(id: number): any {
    return this.litigationService.getLitigationByClaimId(id);
  }


  saveOrUpdatePolicy(): any {
    var body = {
      ...this.formData
    };
    if (this.formData.id > 0)
      return this.litigationService.updateLitigation(body);
    else
      return this.litigationService.createLitigation(body);
  }

}

import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReinsuranceService {

  private reinsuranceUrl: string = "/api/reInsurance/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.reinsuranceUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.reinsuranceUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.reinsuranceUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.reinsuranceUrl);
  }

  getClaimReinsuranceById(id: number): any {
    let url = this.reinsuranceUrl + "getReinsuranceByClaimId";

    return this.dataService.get(url, { claimId: id });
  }

  updateClaimReinsurance(data): any {

    let url = this.reinsuranceUrl + "updateReinsurance";

    return this.dataService.post(url, data);
  }

  getClaimReinsuranceDetailForDownload(claimId: any) {
    let url = this.reinsuranceUrl + "extractReinsuranceDetailsByClaimId";
    return this.dataService.get(url, { claimId: claimId });
  }
  getELLayerDetails(): any {
    let url = this.reinsuranceUrl + "getELLayerDetails";
    return this.dataService.get(url);
  }

  getTPLayerDetails(): any {
    let url = this.reinsuranceUrl + "getTPLayerDetails";
    return this.dataService.get(url);
  }

  getTPCoverData(): any {
    let url = this.reinsuranceUrl + "getTPCoverData";

    return this.dataService.get(url);
  }

  getELCoverData(): any {
    let url = this.reinsuranceUrl + "getELCoverData";

    return this.dataService.get(url);
  }

  getRecoverableReceivable(riRecoverableId: number): any {
    let url = this.reinsuranceUrl + "getRecoverableReceivable";

    return this.dataService.get(url, { riRecoverableId: riRecoverableId });
  }

  saveRecoverableReceivable(data): any {
    let url = this.reinsuranceUrl + "saveRecoverableReceivable";

    return this.dataService.post(url, data);
  }

  updateRecoverableReceivable(data): any {
    let url = this.reinsuranceUrl + "updateRecoverableReceivable";

    return this.dataService.post(url, data);
  }

  deleteRecoverableReceivable(id: number): any {
    let url = this.reinsuranceUrl + "deleteRecoverableReceivable";

    return this.dataService.get(url, { Id: id });
  }

}

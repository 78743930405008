import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppModule } from '../../app.module';
import { SharedModule } from '../../shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PaymentBordereauxRoutingModule } from './payment-bordereaux-routing.module';
import { SearchPaymentBordereauxComponent } from '../payment-bordereaux/search-payment-bordereaux/search-payment-bordereaux.component';
import { VerifyChequesComponent } from '../payment-bordereaux/verify-cheques/verify-cheques.component';
import { CancelChequesComponent } from '../payment-bordereaux/cancel-cheques/cancel-cheques.component';
import { PayChequesComponent } from '../payment-bordereaux/pay-cheques/pay-cheques.component';
import { PayBACSComponent } from '../payment-bordereaux/pay-bacs/pay-bacs.component';


import { SearchPaymentBordereauxService } from 'src/app/components/payment-bordereaux/search-payment-bordereaux/search-payment-bordereaux.service';
import { PaymentBordereauxComponent } from './payment-bordereaux.component';


@NgModule({
  declarations: [SearchPaymentBordereauxComponent, VerifyChequesComponent, CancelChequesComponent,
    PayChequesComponent, PayBACSComponent, PaymentBordereauxComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    MatTableModule,
    MatSortModule,
    NgbModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    PaymentBordereauxRoutingModule,
    NgxMaskModule
  ],
  providers: [SearchPaymentBordereauxService]
})
export class PaymentBordereauxModule { }

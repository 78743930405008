export class ReinsuranceListing {
  claimStatus: any[];
  claimStatusValues: any[];
  reinsurerStatus: any[];
  reinsurerStatusValues: any[];
  lineOfBusiness: number;
  claimType: any[];
  claimTypeValues: any[];
  aggregatedClaims: number;
}


import { ApportionmentService } from '../../../shared/services/apportionment.service';
import { Observable } from 'rxjs';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { ReportsService } from '../../../shared/services/reports.service';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service';
import { claimsReport } from '../../../models/claims-report.model';
import { ClaimHandlerService } from '../../../shared/services/claim-handler.service';
import { Injectable } from '@angular/core';
import { enumRecoveryType, enumReportsType, enumLitigatedType } from '../../../shared/models/common.enum';
import { NotificationService } from '../../../shared/services/notification.service';
import { paymentsInPeriodReport } from '../../../models/reporting-period-payment.model';



@Injectable({
  providedIn: 'root'
})
export class ReportingPeriodPaymentsService {
  formDataInput: paymentsInPeriodReport


  constructor(private reportsService: ReportsService, private handlingOrganizationService: HandlingOrganisationService,
    private notificationService: NotificationService) { }

  generatePaymentsInPeriodReport(): any {
    var body = {
      ...this.formDataInput

    };
    this.reportsService.getPaymentsInPeriodReport(this.formDataInput).subscribe((response: any[]) => {
      if (response != null && response.length > 0) {
        const paymentsInPeriodReportHeader = [
          "MMI Claim Reference",
          "Insured Title",
          "Claim Type",
          "Perpetrator",
          "Location",
          "Exposure Period(s)",
          "Claim Status",
          "Open Date",
          "Date Last Re-Opened",
          "Settled Date",
          "MMI Paid Total",
          "MMI Reserve Total",
          "MMI Incurred Total",
          "Excess Paid (Total)"
        ];

        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Payments in Period Report');
        let paymentsInPeriodReportData: any[] = [];
        paymentsInPeriodReportData = [];
        response.forEach((item) => {

          paymentsInPeriodReportData.push([
            item["mmiClaimReference"],
            item["insuredTitle"],
            item["claimType"],
            item["perpetrator"] == null ? "" : item["perpetrator"],
            item["location"] == null ? "" : item["location"],
            item["exposurePeriods"] == null ? "" : item["exposurePeriods"],
            item["claimStatus"],
            item["openDate"] == null ? "" : moment(item["openDate"]).format('DD/MM/YYYY'),
            item["dateLastReOpened"] == null ? "" : moment(item["dateLastReOpened"]).format('DD/MM/YYYY'),
            item["settledDate"] == null ? "" : moment(item["settledDate"]).format('DD/MM/YYYY'),
            item["mmiPaidTotal"],
            item["mmiReserveTotal"],
            item["mmiIncurredTotal"],
            item["excessPaidTotal"]
          ]);
        });

        let paymentsInPeriodReportHeaderRow = worksheet.addRow(paymentsInPeriodReportHeader);
        // Cell Style : Fill and Border
        paymentsInPeriodReportHeaderRow.eachCell((cell, number) => {
          this.fillBackGroundInExcellCell(cell);
          this.setBorderExcelCell(cell);

        })
        paymentsInPeriodReportHeaderRow.font = { bold: true };
        paymentsInPeriodReportData.forEach(d => {
          let row = worksheet.addRow(d);
          row.eachCell((cell, number) => {
            this.setBorderExcelCell(cell);
          })
          this.setCellFormatOfPaymentsInPeriodReportHeader(row);
        });
        for (var i = 1; i <= worksheet.columnCount; i++) {
          worksheet.getColumn(i).width = 25;
        }

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, 'PaymentsInPeriodReport_' + Math.random() + '.xlsx');
        });

      }
      else {
        this.notificationService.printWarningMessage("No Records found for the selected input")
      }
    });
  }

  setBorderExcelCell(cell: any) {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
  }

  setCellFormatOfPaymentsInPeriodReportHeader(row: ExcelProper.Row) {
    row.findCell(11).value >= 0 ? row.findCell(11).numFmt = '£###,###,##0.00;' : row.findCell(11).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(12).value >= 0 ? row.findCell(12).numFmt = '£###,###,##0.00;' : row.findCell(12).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(13).value >= 0 ? row.findCell(13).numFmt = '£###,###,##0.00;' : row.findCell(13).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(14).value >= 0 ? row.findCell(14).numFmt = '£###,###,##0.00;' : row.findCell(14).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(4).alignment = { wrapText: true };
    row.findCell(5).alignment = { wrapText: true };
    row.findCell(6).alignment = { wrapText: true };
    row.findCell(8).numFmt = 'DD/MM/YYYY';
    row.findCell(9).numFmt = 'DD/MM/YYYY';
    row.findCell(10).numFmt = 'DD/MM/YYYY';
  }


  fillBackGroundInExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00C4C4C4' },
      bgColor: { argb: 'FF0000FF' }
    }
  }


}

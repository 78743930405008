import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { SearchWorkflowResponseModel } from '../../shared/models/search-workflow.response.model';
import { IPager } from '../../shared/models/pager.model';
import { Subject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { SearchWorkflowRequestModel } from './search-workflow.model';
import { SearchflowItemService } from './search-workflow.service';
import { catchError } from 'rxjs/operators';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LookupService } from '../../shared/services/lookup.service';
import { enumHandlingOrganization, enumWorkflowPriorityCode, enumWorkflowStatus, enumSLAStatus, enumWorkFlowCategory } from '../../shared/models/common.enum';
import * as moment from 'moment';
import { NotificationService } from '../../shared/services/notification.service';
import { Router } from '@angular/router';
import { SecurityService } from '../../shared/services/security.service';

@Component({
  selector: 'app-search-workflow',
  templateUrl: './search-workflow.component.html',
  styleUrls: ['./search-workflow.component.css']
})
export class SearchWorkflowComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;

  public searchWorkflowItem: SearchWorkflowResponseModel[];
  public searchFilter: any = {};
  public dtOptions: any = {};
  public paginationInfo: IPager;
  public dtTrigger: Subject<any>;
  errorReceived: boolean;
  isHidden: boolean = true;
  isValid: boolean;
  isSelectAllChecked: boolean;
  statusEnumKeys: any[];
  statusEnum = enumWorkflowStatus;
  claimTypes: any[]
  HandlingOrganisationList: any
  mmiUserList: any
  priorityCodes: any
  workItemCategories: any[];
  workItemStatus: any[];
  loggedInUserId: number;
  selectedListData: DataTables.Api;
  modalRef: NgbModalRef;
  displayeDataList = [];
  mmiSupervisorList: any;
  displayClaimFields: boolean = true;
  enableFields: boolean = true;
  enableClaimFields: boolean;
  disableSuperVisorDropdown: boolean
  displayedColumns: any[] = [
    { isAlwaysVisible: true, title: 'Work Item Category', data: 'workItemCategory', visible: true },
    { isAlwaysVisible: true, title: 'Priority Code', data: 'priorityCodeAssigned' },
    { isAlwaysVisible: true, title: 'Linked Documents', data: 'workflowLinkedDocuments', visible: true },
    { isAlwaysVisible: true, title: 'MMI Claims Reference', data: 'mmiClaimsReference' },
    { isAlwaysVisible: true, title: 'Insured Title', data: 'insuredTitle' },
    { isAlwaysVisible: true, title: 'Claimant', data: 'claimant' },

    { isAlwaysVisible: true, title: 'Claim Type', data: 'claimType', visible: true },
    { isAlwaysVisible: true, title: 'Handling Organisation', data: 'handlingOrganisation', visible: true },
    { isAlwaysVisible: true, title: 'Date Received', data: 'workflowReceivedDate', visible: true, type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
    { isAlwaysVisible: true, title: 'Deadline Date', data: 'deadlineDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
    { isAlwaysVisible: true, title: 'MMI User Allocated', data: 'mmiUserAllocatedText', visible: true },
    { isAlwaysVisible: true, title: 'MMI Supervisor Allocated', data: 'mmiSupervisorAllocatedText', visible: true },
    { isAlwaysVisible: true, title: 'Work Completion Status', data: 'workflowCompletionStatusText', visible: true },
    { isAlwaysVisible: true, title: 'Date Completed', data: 'workflowCompletionDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
    { isAlwaysVisible: true, title: 'SLA Status', data: 'slaStatus', visible: true },

    { isAlwaysVisible: false, title: 'MMI Supervisor Allocated', data: 'mmiSupervisorAllocated', visible: false },
    { isAlwaysVisible: false, title: 'MMI User Allocated', data: 'mmiUserAllocated', visible: false },
    { isAlwaysVisible: true, title: 'Work Completion Status', data: 'workflowCompletionStatus', visible: false },
    { isAlwaysVisible: true, title: 'Action', data: null, visible: true, orderable: false },
    { isAlwaysVisible: true, title: '<input type="checkbox" id="selectAll"/>', data: 'isSelected', visible: true, orderable: false },
  ];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  constructor(private searchflowItemService: SearchflowItemService, private LookupService: LookupService, private notificationService: NotificationService, private modalService: NgbModal, private router: Router, private securityService: SecurityService) {
    this.dtTrigger = new Subject();
  }


  claimTypesdropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  mmiClaimHandlerdropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'claimsHandlers',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  mmiSupervisordropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'claimsHandlers',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };
  handlingOrganisationdropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'handlingOrganisationName',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  priorityCodesdropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  workItemCategoryDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  ngOnInit(): void {
    this.loggedInUserId = this.securityService.getUserData().id;
    this.isSelectAllChecked = false;
    this.resetForm();
    this.initialiseDataTable();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'data',
      textField: 'title',
      enableCheckAll: true,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false,
      closeDropDownOnSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All'
    };
    this.loadData();
    this.isHidden = false;
    this.columnsForMultiSelect().forEach(a => {
      if (a.data != "isSelected") {
        this.displayeDataList.push(a);
      }
    });

    this.columnsForMultiSelect().forEach(a => {
      if (a.data != "isSelected") {
        this.selectedItems.push(a);
      }
    });
  }

  resetForm() {
    this.isHidden = true;
    this.searchflowItemService.formData = {
      mmiClaimsReference: '',
      claimant: '',
      handlingOrganisation: [{ "id": 0, "handlingOrganisationName": "All" }],
      handlingOrganisationId: [],
      mmiUserAllocatedId:[],
      mmiUserAllocated: [{ "id": 0, "claimsHandlers": "All" }],
      dateFrom: null,
      dateTo: null,
      dateType: 0,
      claimType: [{ "id": 0, "text": "All" }],
      claimTypeId: [],
      slaStatus: 0,
      sortBy: null,
      sortOrder: null,
      completionStatus: 0,
      isSelected: false,
      insuredTitle: '',
      pageSize: 0,
      pageIndex: 0,
      priorityCode: [{ "id": 0, "text": "All" }],
      priorityCodeId: [],
      dateFromDate: null,
      dateToDate: null,
      mmiSupervisorAllocated: [{ "id": 0, "claimsHandlers": "All" }],
      mmiSuperVisorId: [],
      workItemCategory: [],
      workItemCategoryId: 0
      
    };
    
  }

  searchClaimWorkflow() {
    
    this.searchflowItemService.formData.claimTypeId = _.map(this.searchflowItemService.formData.claimType, function (i: any) { return i.id })
    this.searchflowItemService.formData.handlingOrganisationId = _.map(this.searchflowItemService.formData.handlingOrganisation, function (i: any) { return i.id })
    this.searchflowItemService.formData.mmiUserAllocatedId = _.map(this.searchflowItemService.formData.mmiUserAllocated, function (i: any) { return i.id })
    this.searchflowItemService.formData.priorityCodeId = _.map(this.searchflowItemService.formData.priorityCode, function (i: any) { return i.id })
    this.searchflowItemService.formData.mmiSuperVisorId = _.map(this.searchflowItemService.formData.mmiSupervisorAllocated, function (i: any) { return i.id })
    //this.searchflowItemService.formData.workItemCategoryId = _.map(this.searchflowItemService.formData.workItemCategory, function (i: any) { return i.id })
    this.searchflowItemService.formData.dateFromDate = this.searchflowItemService.formData.dateFrom != null ? !(this.isIsoDate(this.searchflowItemService.formData.dateFrom)) ? this.searchflowItemService.formData.dateFrom.toUTCString() : this.searchflowItemService.formData.dateFrom : "";
    this.searchflowItemService.formData.dateToDate = this.searchflowItemService.formData.dateTo != null ? !(this.isIsoDate(this.searchflowItemService.formData.dateTo)) ? this.searchflowItemService.formData.dateTo.toUTCString() : this.searchflowItemService.formData.dateTo : "";
    //if (this.validateSearch()) {
    this.isHidden = false;
    localStorage.setItem("workflowSearchCriteria", JSON.stringify(this.searchflowItemService.formData));
    if (this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
    else {
      this.dtTrigger.next();
    }
    //}

  }

  initialiseDataTable(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedColumns,
      serverSide: true,
      processing: true,
      searching: false,
      dom: 'Blfrtip',
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          tag: '',
          action: this.newExportAction,
          messageTop: "Claim Workflow Result",
          title: "Claim Workflow",
          filename: function () { return "ClaimWorkflowTracker" + Math.random(); },
          exportOptions: {
            columns: [0,1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
          }
        }
      ],
      select: {
        style: 'multi'
      },
      scrollY: '50vh',
      scrollX: true,
      //scrollCollapse: true,
      "initComplete": function (settings, json) {
        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });

      },
      drawCallback: function (settings, json) {
        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
      },
      columnDefs: [{
        orderable: false,
        targets: 19,
        className: "sticky-column-right",
        render: function (data, type, full, meta: any) {
          
          var html = "";
          if (full.workflowCompletionStatus!=enumWorkflowStatus.Outstanding && full.mmiSupervisorAllocated != null) {
            if (full.mmiSupervisorAllocated == that.loggedInUserId) {
              meta.settings.aoColumns[19].sClass = 'select-checkbox sticky-column-right';
            }
            else {
              meta.settings.aoColumns[19].sClass = 'sticky-column-left';
            }
          }
          else {
            if (full.mmiUserAllocated != null && full.mmiUserAllocated == that.loggedInUserId) {
              meta.settings.aoColumns[19].sClass = 'select-checkbox sticky-column-right';
            } else {
              meta.settings.aoColumns[19].sClass = 'sticky-column-left';
            }
          }
          if (full.workflowCompletionStatus == enumWorkflowStatus.Outstanding && full.workItemCategory == "New Claim") {
            meta.settings.aoColumns[19].sClass = 'sticky-column-left';
          }
            //this.searchflowItemService.formData.workItemCategoryId == enumWorkFlowCategory.NewClaim
          return html;
        }
      },
      { type: 'date-uk', "targets": 7 },
      { type: 'date-uk', "targets": 8 },

      //{ type: 'date-uk', "targets": 39 },
      {

        targets: 1, render: function (data) {
          switch (data) {
            case (enumWorkflowPriorityCode.P1):
              data = enumWorkflowPriorityCode[enumWorkflowPriorityCode.P1];
              break;
            case (enumWorkflowPriorityCode.P2):
              data = enumWorkflowPriorityCode[enumWorkflowPriorityCode.P2]
              break;
            case (enumWorkflowPriorityCode.P3):
              data = enumWorkflowPriorityCode[enumWorkflowPriorityCode.P3]
              break;
            case (enumWorkflowPriorityCode.P4):
              data = enumWorkflowPriorityCode[enumWorkflowPriorityCode.P4]
              break;
            case (enumWorkflowPriorityCode.P5):
              data = enumWorkflowPriorityCode[enumWorkflowPriorityCode.P5]
              break;
            case (enumWorkflowPriorityCode.P6):
              data = enumWorkflowPriorityCode[enumWorkflowPriorityCode.P6]
              break;
            case (enumWorkflowPriorityCode.P7):
              data = enumWorkflowPriorityCode[enumWorkflowPriorityCode.P7]
              break;
            case (enumWorkflowPriorityCode.P3NewClaim):
              data = "P3 – New Claim";
              break;

          }
          return data;
        }

      },
      {

        targets: 14, render: function (data) {
          switch (data) {
            case (enumSLAStatus.ToBeCompletedToday):
              data = "To Be Completed Today"
              break;
            case (enumSLAStatus.OutsideSLA):
              data = "Outside SLA"
              break;
            case (enumSLAStatus.InsideSLA):
              data = "Inside SLA"
              break;
            case (enumSLAStatus.Completed):
              data = "Completed"
              break;
            default:              
              data = ""
              break;
              
          }
          return data;
        }



      },
      //{
      //  targets: 10, render: function (adata) {
      //    switch (adata) {
      //      case (enumWorkflowStatus.Completed):
      //        adata = "Completed"
      //        break;
      //      case (enumWorkflowStatus.Outstanding):
      //        adata = "Outstanding"
      //        break;


      //    }
      //    return adata;

      //  }
      //},
      {
        targets: -2,
        className: "sticky-column-2nd-right",
        render: function (data) {
          data = '<button class="btn edit-button btn-sm"><i class="fa fa-edit"></i></button>';
          return data;

        }
      },
      {
        targets: 2,
        render: function (data, type, full, meta: any) {
          return that.bindWorkflowLinkedDocuments(data);
        }
        },
        {
          targets: 3,
          render: function (data, type, full, meta: any) {            
            var result = "";
            return that.goToClaimDetails(full);
          }
        }
      ],
      order: [[9, 'asc']],
      headerCallback: function (thead, data, start, end, display) {
        var selectAllCheckBox = $('#selectAll');
        selectAllCheckBox.click(function () { that.selectAll(this) });
      },
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        $('.edit-button btn-sm', row).unbind('click');
        $('.edit-button', row).bind('click', () => {
          self.navigateToClaims(data);
        });
        //$('.nav-claim', row).bind('click', () => {
        //  self.goToClaimDetails(data);
        //});

        if (data["workflowCompletionStatus"] == enumWorkflowStatus.Completed && data["slaStatus"] == enumSLAStatus.InsideSLA)
        {
          $(row).removeClass("odd");
          $('td', row).css('background-color', '#28a745');
        }
        else if (data["workflowCompletionStatus"] == enumWorkflowStatus.Completed && data["slaStatus"] == enumSLAStatus.OutsideSLA) {
          $(row).removeClass("odd");
          $('td', row).css('background-color', '#dc3545');
        }
        else {
          switch (data["slaStatus"]) {
            case (enumSLAStatus.ToBeCompletedToday):
              $(row).removeClass("odd");
              $('td', row).css('background-color', '#ffc107');
              //$('td', row).css('color', '#fff');
              break;
            case (enumSLAStatus.OutsideSLA):
              $(row).removeClass("odd");
              $('td', row).css('background-color', '#dc3545');
              break;
            case (enumSLAStatus.Completed):
              $(row).removeClass("odd");
              $('td', row).css('background-color', '#28a745');
              break;
            default:
              break;

          }
        }
        // $(row).addClass("highlightData");
        // $('td', row).css('background-color', 'Red');
        return row;
      },

      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        this.searchflowItemService.formData.sortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
        this.searchflowItemService.formData.sortOrder = dataTablesParameters.order[0].dir;
        this.searchflowItemService.formData.pageIndex = dataTablesParameters.start > 0 ? parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) : 0;
        this.searchflowItemService.formData.pageSize = dataTablesParameters.length;
        //this.searchFilter.mmiClaimsReference = '';
        //this.searchflowItemService.formData.mmiClaimsReference = '19203000002';
        //this.searchFilter.mmiClaimsReference = this.searchflowItemService.formData.mmiClaimsReference

        this.getData(this.searchFilter).then(resp => {
          callback({
            page: that.paginationInfo.actualPage,
            pages: that.paginationInfo.totalPages,
            recordsTotal: that.paginationInfo.totalItems,
            recordsFiltered: that.paginationInfo.totalItems,
            data: that.searchWorkflowItem
          });
          that.isHidden = false;
          //if (!this.isReadOnlyMode) {
          //  that.selectGridRows();
          //}
          var dataTable: any = this.datatableElement;

          dataTable.dt.on('user-select', (e, dt, type, cell, originalEvent, event) => {            
            if (dt.rows(cell.index()["row"]).data()[0].workflowCompletionStatus != enumWorkflowStatus.Outstanding && dt.rows(cell.index()["row"]).data()[0].mmiSupervisorAllocated != null) {
              if (dt.rows(cell.index()["row"]).data()[0].mmiSupervisorAllocated != that.loggedInUserId) {
                return false;
              }
            }
            else {
              if (dt.rows(cell.index()["row"]).data()[0].mmiUserAllocated != that.loggedInUserId) {
                return false;
              }
            }
            if (dt.rows(cell.index()["row"]).data()[0].workflowCompletionStatus != enumWorkflowStatus.Outstanding && dt.rows(cell.index()["row"]).data()[0].workItemCategory == "New Claim") {              
                return false;              
            }  
            });
        });

      }
    };
  }

  navigateToClaims(data) {
    if (data.claimId != null && data.claimId != 0) {
      this.router.navigate(['/claims/' + data.policyId + '/' + data.claimId + '/add-workflow-item/edit/' + data.id]);
    }
    else {
      this.router.navigate(['/add-workflow-item/edit/' + data.id]);
    }
  }

  getData(filter: any): Promise<any> {
    this.errorReceived = false;
    var promise = new Promise((resolve, reject) => {
      this.searchflowItemService.getClaimWorkflows()
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          this.searchWorkflowItem = res.searchWorkflowResultItems;
          this.paginationInfo = res.paginationInfo;
          resolve();
        });
    });
    return promise;
  }

  columnsForMultiSelect() {
    let c = _.filter(this.displayedColumns, function (o) { return o.isAlwaysVisible == true; });
    return c;
  }

  selectAll(data) {
    this.isSelectAllChecked = data.checked;

    this.selectGridRows();
  }

  selectGridRows() {
    var dataTable: any = this.datatableElement;
    var checked = this.isSelectAllChecked;
    let handlerId = this.loggedInUserId;
    var selectChk: any = document.getElementById("selectAll");
    selectChk.checked = checked;
    dataTable.dt.rows().every(function (rowIdx, tableLoop, rowLoop) {
      var rowData = this.data();
      if (rowData.workflowCompletionStatus != enumWorkflowStatus.Outstanding && rowData.mmiSupervisorAllocated != null) {
        if (rowData.mmiSupervisorAllocated == handlerId) {
          if (checked) {
            this.rows(rowIdx).select();
          }
          else {
            this.rows(rowIdx).deselect();
          }
        }
      }
      else
      {
        if (rowData.mmiUserAllocated == handlerId) {
          if (checked) {
            this.rows(rowIdx).select();
          }
          else {
            this.rows(rowIdx).deselect();
          }
        }

      }
    });

  }

  openDialog(content) {    
    var dataTable: any = this.datatableElement;
    var selectedrows: any[] = dataTable.dt.rows('.selected').data();
    var selectedPaymentIds: number[] = _.map(selectedrows, i => i.paymentID);
    if (selectedPaymentIds.length === 0) {
      this.notificationService.printWarningMessage('No workflow item selected to update the status');
      return;
    }

    this.modalRef = this.modalService.open(content);
  }

  onViewHideItemSelect(item: any) {
    let c = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    c[0].visible = true;

    this.searchClaimWorkflow();
  }

  showHideClaimDetails(item: any, showclaimFields: boolean) {    
    if (this.searchflowItemService.formData.workItemCategory.filter(i => i.id == enumWorkFlowCategory.ExistingClaim || i.id == enumWorkFlowCategory.ChaseForLLA || i.id == enumWorkFlowCategory.PrepareLLA || i.id == enumWorkFlowCategory.ReviewNonDAReferral || i.id == enumWorkFlowCategory.ConfirmDAStatus || i.id == enumWorkFlowCategory.ConfirmLitigationStatus || i.id == 0).length > 0) {
      this.displayClaimFields = true;
    }
    else {
      this.displayClaimFields = false;
    }

  }

  onViewHideItemDeSelect(item: any) {
    let c = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    c[0].visible = false;

    this.searchClaimWorkflow();
  }

  onViewHideSelectAll() {    
    this.displayedColumns.forEach(a => {
      if (a.data != "isSelected") {
        a.isAlwaysVisible ? a.visible = true : a.visible = false;
      }
    });

    this.searchClaimWorkflow();
  }

  onViewHideDeSelectAll() {
    this.displayedColumns.forEach(a => {
      if (a.data != "isSelected") {
        a.visible = false;
      }
    });
    this.searchClaimWorkflow();
  }

  loadData() {    
    this.isHidden = true;
    this.loadLookups();
    var searchCriteria = localStorage.getItem("workflowSearchCriteria");
    if (searchCriteria != null) {      
      this.searchflowItemService.formData = (JSON.parse(searchCriteria));
      this.onWorkItemCategoryChange(true);
      setTimeout(r => { this.searchClaimWorkflow() }, 0);
    }
  }

  loadLookups() {
    this.claimTypes = this.LookupService.getLookupsByTypeId(1);
    //this.claimTypes.unshift({ "id": 0, "text": "All" });
    this.workItemCategories = this.LookupService.getLookupsByTypeId(59);
    this.workItemStatus = this.LookupService.getLookupsByTypeId(61);
    this.searchflowItemService.getClaimHandlersByHandlingOrganisation(enumHandlingOrganization.MMI).subscribe(
      mmiClaimHandler => {
        this.mmiUserList = mmiClaimHandler;
        //this.mmiUserList.unshift({ "id": 0, "claimsHandlers": "All" });
      });

    this.searchflowItemService.getClaimHandlersByHandlingOrganisation(enumHandlingOrganization.MMI).subscribe(
      mmiClaimHandler => {
        this.mmiSupervisorList = mmiClaimHandler;
        // this.mmiSupervisorList.unshift({ "id": 0, "claimsHandlers": "All" });
      });
    this.searchflowItemService.getHandlingOrganisation().subscribe(
      handlingOrganisationList => {
        this.HandlingOrganisationList = handlingOrganisationList;
        // this.HandlingOrganisationList.unshift({ "id": 0, "handlingOrganisationName": "All" });
      });
    this.priorityCodes = this.LookupService.getLookupsByTypeId(60);
    //this.priorityCodes.unshift({ "id": 0, "text": "All" });
    this.statusEnumKeys = Object.values(this.statusEnum).filter(Number)
  }
  updateStatus(workflowStatus) {
    this.modalRef.close();

    var dataTable: any = this.datatableElement;
    var selectedItems: any[] = dataTable.dt.rows('.selected').data();

    var selectedWorkflowItemIDs: number[] = _.map(selectedItems, i => i.id);

    if (selectedWorkflowItemIDs.length === 0) {
      this.notificationService.printWarningMessage('Please select workflow items');
      return;
    }
    //if (confirm("Click OK to approve payment (s)"))
    this.searchflowItemService.updateWorkflowStatus(selectedWorkflowItemIDs, workflowStatus).subscribe(res => {
      this.notificationService.printSuccessMessage('Selected worflow status updated successfully');
      this.searchClaimWorkflow();
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    )
  }

  validateSearch(): boolean {
    this.isValid = true

    return this.isValid;
  }
  onWorkItemCategoryChange(isOnload?: boolean) {   
    this.displayClaimFields = false;
    if (this.searchflowItemService.formData.workItemCategoryId == 0) {      
      this.priorityCodes = this.LookupService.getLookupsByTypeId(60);
      this.searchflowItemService.formData.priorityCode = [{ "id": 0, "text": "All" }];
      this.workItemStatus = this.LookupService.getLookupsByTypeId(61);
      //this.resetForm();
      this.displayClaimFields = true;
    }

    else if (this.searchflowItemService.formData.workItemCategoryId == enumWorkFlowCategory.NewClaim) {
      
      this.priorityCodes = this.LookupService.getLookupsByTypeId(60).filter(i => i.id == enumWorkflowPriorityCode.P3NewClaim);
      this.searchflowItemService.formData.priorityCode = this.priorityCodes;
      this.workItemStatus = this.LookupService.getLookupsByTypeId(61);
      this.resetAllValues();
      //this.resetClaimFields();
      this.disableSuperVisorDropdown = false;
    }
    
    else {   
      if (this.searchflowItemService.formData.workItemCategoryId != enumWorkFlowCategory.ExistingClaim
        && this.searchflowItemService.formData.workItemCategoryId != enumWorkFlowCategory.ChaseForLLA
        && this.searchflowItemService.formData.workItemCategoryId != enumWorkFlowCategory.PrepareLLA
        && this.searchflowItemService.formData.workItemCategoryId != enumWorkFlowCategory.ConfirmDAStatus
        && this.searchflowItemService.formData.workItemCategoryId != enumWorkFlowCategory.ConfirmLitigationStatus
        && this.searchflowItemService.formData.workItemCategoryId != enumWorkFlowCategory.ReviewNonDAReferral) {
        var sessionval = this.searchflowItemService.formData.completionStatus
          
        /*this.searchflowItemService.formData.completionStatus = sessionval;*/
        if (isOnload != true) {
          this.searchflowItemService.formData.priorityCode = [];
          this.searchflowItemService.formData.priorityCode = [{ "id": 0, "text": "All" }];
          this.searchflowItemService.formData.completionStatus = 0;
        }
        this.resetAllValues();
        //this.resetClaimFields();
      }      
      this.priorityCodes = this.LookupService.getLookupsByTypeId(60).filter(i => i.id != enumWorkflowPriorityCode.P3NewClaim);
      
      this.workItemStatus = this.LookupService.getLookupsByTypeId(61).filter(i => i.id != enumWorkflowStatus.CompletedStage1);
      
      if (this.searchflowItemService.formData.workItemCategoryId == enumWorkFlowCategory.ExistingClaim || this.searchflowItemService.formData.workItemCategoryId == enumWorkFlowCategory.ChaseForLLA || this.searchflowItemService.formData.workItemCategoryId == enumWorkFlowCategory.PrepareLLA || this.searchflowItemService.formData.workItemCategoryId == enumWorkFlowCategory.ReviewNonDAReferral || this.searchflowItemService.formData.workItemCategoryId == enumWorkFlowCategory.ConfirmDAStatus || this.searchflowItemService.formData.workItemCategoryId == enumWorkFlowCategory.ConfirmLitigationStatus) {        
        this.displayClaimFields = true;
        if (isOnload != true) {
          this.searchflowItemService.formData.priorityCode = [];
          this.searchflowItemService.formData.priorityCode = [{ "id": 0, "text": "All" }];
          this.searchflowItemService.formData.completionStatus = 0;
        }
        //this.resetAllValues();
        //this.resetClaimFields();      
                             
      }
    }
  }

  resetClaimFields() {
    this.searchflowItemService.formData.claimTypeId = [];
    this.searchflowItemService.formData.claimType = [];
    this.searchflowItemService.formData.handlingOrganisation = [];
    this.searchflowItemService.formData.handlingOrganisationId = [];
    this.searchflowItemService.formData.insuredTitle = '';
    this.searchflowItemService.formData.claimant = '',
      this.searchflowItemService.formData.mmiClaimsReference = '';
  }

  clearFields() {
    this.resetForm();
    this.displayClaimFields = true;
    localStorage.removeItem("workflowSearchCriteria");
    //this.searchClaimWorkflow();
  }

  resetAllValues() {
    this.searchflowItemService.formData.handlingOrganisation = [];
    this.searchflowItemService.formData.claimType = [];
    //this.searchflowItemService.formData.mmiUserAllocated = [];
    //this.searchflowItemService.formData.mmiSuperVisor = [];
  }
  isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str);
    return d.toISOString() === str;
  }

  public newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    console.log("buttons");
    //console.log($.fn.dataTable.ext.buttons);
    dt.one('preXhr', function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 50000; // Set from 5000 to 50000 to export upto 50000 workflow items from DB.

      dt.one('preDraw', function (e, settings) {
        // Call the original action function
        oldExportAction(self, e, dt, button, config);

        dt.one('preXhr', function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    // Requery the server with the new one-time export settings
    dt.ajax.reload();

    function oldExportAction(self, e, dt, button, config) {
      if (button[0].className.indexOf('buttons-excel') >= 0) {
        //this.datatableElement.dtInstance.
        var buttons: any = ($.fn.dataTable.ext as any).buttons;
        if (buttons.excelHtml5.available(dt, config)) {
          buttons.excelHtml5.action.call(self, e, dt, button, config);
        }
        else {
          buttons.excelFlash.action.call(self, e, dt, button, config);
        }
      } else if (button[0].className.indexOf('buttons-print') >= 0) {
        buttons.print.action(e, dt, button, config);
      }
    }
  };

  public oldExportAction(self, e, dt, button, config) {
    if (button[0].className.indexOf('buttons-excel') >= 0) {
      //this.datatableElement.dtInstance.
      var buttons: any = ($.fn.dataTable.ext as any).buttons;
      if (buttons.excelHtml5.available(dt, config)) {
        buttons.excelHtml5.action.call(self, e, dt, button, config);
      }
      else {
        buttons.excelFlash.action.call(self, e, dt, button, config);
      }
    } else if (button[0].className.indexOf('buttons-print') >= 0) {
      buttons.print.action(e, dt, button, config);
    }
  }

  private handleError(error: any) {
    this.errorReceived = true;
    return Observable.throw(error);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  bindWorkflowLinkedDocuments(linkDocs) {
    let result = "";
    for (var i = 0; i < linkDocs.length; i++) {
      result += "<a href='" + linkDocs[i].linkedDocumentUrl + "' target = '_blank' > " + linkDocs[i].linkedDocumentName + " </a></br>";
    }
    return "<span>" + result + "</span>";
  }

  goToClaimDetails(claimDetails) {    
    let result = "";
    if (claimDetails.mmiClaimsReference != null && claimDetails.mmiClaimsReference != '') {
      result += "<a href='" + "claims/" + claimDetails.policyId + "/" + claimDetails.claimId + "/" + "claimdetail" + "' target = '_blank' > " + claimDetails.mmiClaimsReference + " </a></br>";
    }

    return "<span>" + result + "</span>";
  }

}

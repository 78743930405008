export class SearchParticipantVM {
  participantName: string;
  participantTypeId: Number;
  participantType: string;
  pageIndex: number;
  pageSize: number;
  sortBy?: string;
  sortOrder?: string;
  creditorNo: string;
  policyholderID: string;
  policyholderName: string;
  policyholderStatus: string;
  aggregatedPolicyholderID: string;
  aggregatedPolicyholderStatus: string;
  aggregatedPolicyholderName: string;
  
  
  constructor() {
    this.participantTypeId = 0;
  }
}

import { Component, OnInit, ElementRef, ViewChild, ViewChildren, QueryList, OnDestroy, AfterViewChecked } from '@angular/core';
import { TpCoverService } from './tp-cover.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgForm } from '@angular/forms';
import { ELTPCoverModel } from '../../../shared/models/reinsurance-cover.model';

@Component({
  selector: 'app-tp-cover',
  templateUrl: './tp-cover.component.html',
  styleUrls: ['./tp-cover.component.css']
})
export class TpCoverComponent implements OnDestroy, OnInit, AfterViewChecked {
  @ViewChild(NgForm, { static: false })
  @ViewChild('tpCoverTable') tpCoverTable: ElementRef;
  public dtOptions: any = {};
  public tpCoverList: ELTPCoverModel[];
  @ViewChildren(DataTableDirective)
  private datatableElements: QueryList<DataTableDirective>;
 dtTrigger = new Subject();
  isGridLoaded: boolean = false;

  constructor(private tpCoverService: TpCoverService, private notificationService: NotificationService) {
    this.dtTrigger = new Subject();
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      dom: 'Blfrtip',
      columnDefs:
        [{
          targets: 8, render: function (data) {
            if (data != null) {
              //return data + " %";
              return (((String(data).split('.').length === 1) && parseInt(data) > 0 && parseInt(data) < 100) ? (String(data).split('.')[0] + '.00') : ((String(data).split('.').length === 2) && (String(data).split('.')[1].length === 1)) ? (String(data).split('.')[0] + "." + String(data).split('.')[1] + "0") : data) + " " + "%"
            }
          }
        }],
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          tag: '',
          title: "TP Cover Data",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8]
          },
          filename: function () { return "TPCoverData" + Math.random(); },

        }

      ]
    };
    this.loadTPCoverData();
  }


  loadTPCoverData() {
    this.tpCoverService.getTPCoverData()
      .subscribe(response => {
        //debugger;
        this.tpCoverList = response;
        this.refreshGrid();
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }


  refreshGrid() {
    if (this.tpCoverTable) {
        if (this.datatableElements.toArray()[0] && this.datatableElements.toArray()[0].dtInstance) {
          this.datatableElements.toArray()[0].dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });
        }
        else {
          this.dtTrigger.next();
        }
        this.isGridLoaded = true;
      }
  }

  public newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    console.log("buttons");
    //console.log($.fn.dataTable.ext.buttons);
    dt.one('preXhr', function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 500;

      dt.one('preDraw', function (e, settings) {
        // Call the original action function
        //debugger;
        oldExportAction(self, e, dt, button, config);

        dt.one('preXhr', function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    //  // Requery the server with the new one-time export settings
    dt.ajax.reload();

    function oldExportAction(self, e, dt, button, config) {
      if (button[0].className.indexOf('buttons-excel') >= 0) {
        //this.datatableElement.dtInstance.
        var buttons: any = ($.fn.dataTable.ext as any).buttons;
        if (buttons.excelHtml5.available(dt, config)) {
          buttons.excelHtml5.action.call(self, e, dt, button, config);
        }
        else {
          buttons.excelFlash.action.call(self, e, dt, button, config);
        }
      } else if (button[0].className.indexOf('buttons-print') >= 0) {
        buttons.print.action(e, dt, button, config);
      }
    }
  };
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClaimService } from '../../../shared/services/claim.service';
import { ISearchClaimVM } from '../../../models/search-claim.model';
import { IClaims } from '../../../models/claims.model';
import { PolicyService } from '../../../shared/services/policy.service';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service';
import { ClaimHandlerService } from '../../../shared/services/claim-handler.service';

@Injectable()
export class SearchClaimService {
    
  
  constructor(private claimService: ClaimService, private policyService: PolicyService,
    private handlingOrganisationService: HandlingOrganisationService,
    private claimHandlerService:ClaimHandlerService) {
    
  }

  getClaims(filterCriteria: ISearchClaimVM):any {
    return this.claimService.getClaims(filterCriteria)
  }

  searchTitle(term: string) {
    if (!term || term.length < 3 || term.toLowerCase() == 'cou') { return of([]); }
    return this.policyService.getPolicyInsuredTitle(term);
  }

  getAllHandlingOrganisations() {
    return this.handlingOrganisationService.getAllHandlingOrganisations();
  }

  getClaimHandlers(organisationId: any) {
    return this.claimHandlerService.getClaimHandlers(organisationId);
  }

  checkClaimAccessiblity(claimId: number, organisationId: number): any {
    return this.claimService.checkClaimAccessiblity(claimId, organisationId);
  }

  searchPerpetrators(term: string) {
    if (!term || term.length < 3) { return of([]); }
    return this.policyService.getSearchedPerpetrators(term);
  }
}

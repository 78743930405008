import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ReserveHistoryService {

  private reserveHistoryUrl: string = "/api/reservehistory/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.reserveHistoryUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.reserveHistoryUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.reserveHistoryUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.reserveHistoryUrl);

  }




  getReserveHistoryByClaimId(claimId: number): any {
    let url = this.reserveHistoryUrl + "getReserveHistoryByClaimId";

    return this.dataService.get(url, { claimId: claimId});
  }
}

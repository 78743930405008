export class claimCreditorModel {
  schemeCreditorId: number;
  creditorName: string;
  creditorStatus: string;
  creditorNo: string;
  aggregatedCreditorName: string;
  aggregatedCreditorNo: string;
  aggregatedCreditorStatus:string
  constructor(Id: number, name: string, status: string, creditorNo: string, aggregatedCreditorName: string,
    aggregatedCreditorNo: string, aggregatedCreditorStatus: string) {
    this.schemeCreditorId = Id;
    this.creditorName = name;
    this.creditorStatus = status;
    this.creditorNo = creditorNo;
    this.aggregatedCreditorName = aggregatedCreditorName;
    this.aggregatedCreditorStatus = aggregatedCreditorStatus;
    this.aggregatedCreditorNo = aggregatedCreditorNo;
  }
}

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  private paymentDetailUrl: string = "/api/PaymentDetail/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.paymentDetailUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.paymentDetailUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.paymentDetailUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.paymentDetailUrl);
  }

  getPaymentDetailById(id: number): any {
    let url = this.paymentDetailUrl + "getPaymentDetailById";

    return this.dataService.get(url, { id: id });
  }

  createPaymentDetail(data): any {

    let url = this.paymentDetailUrl + "savePaymentDetail";

    return this.dataService.post(url, data);

  }

  updatePaymentDetail(data): any {

    let url = this.paymentDetailUrl + "updatePaymentDetail";

    return this.dataService.putWithId(url, data);
  }

  getTotalMMIReserveById(id: number, paymentCategoryId: number): any {
    let url = this.paymentDetailUrl + "getTotalMMIReserve";

    return this.dataService.get(url, { claimId: id, paymentCategoryId: paymentCategoryId });
  }

  getExcessByClaimId(id: number): any {
    let url = this.paymentDetailUrl + "getExcessApplicable";

    return this.dataService.get(url, { claimId: id });
  }

  getLOIByClaimId(id: number): any {
    let url = this.paymentDetailUrl + "getLOIApplicable";

    return this.dataService.get(url, { claimId: id });
  }

  getPaymentDetailInformationByPaymentId(claimId: number, paymentId: number): any {
    let url = this.paymentDetailUrl + "getPaymentDetailByPaymentId";

    return this.dataService.get(url, { claimId: claimId, paymentId: paymentId });
  }

  getLinkDocumentFolderUrl(claimId: number, folderName: string): any {
    let url = this.paymentDetailUrl + "getLinkDocumentFolderUrl";
    return this.dataService.get(url, { claimId: claimId, folderName: folderName });
  }

  checkIsPaymentsAvailable(claimId: number): any {
    let url = this.paymentDetailUrl + "checkPaymentsAvailable";

    return this.dataService.get(url, { id: claimId });
  }

}


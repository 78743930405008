import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserDetailsComponent } from './user-details.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyHandlingOrganisationComponent } from './my-handling-organisation/my-handling-organisation.component';
import { MyColleaguesComponent } from './my-colleagues/my-colleagues.component';
import { RoleGuardService as RoleGuard } from '../../shared/services/role-guard.service';



const routes: Routes = [
  {
    path: 'nav-menu', component: UserDetailsComponent, children: [
      {
        path: 'my-profile', component: MyProfileComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1163' }
      },
      {
        path: 'my-organisation', component: MyHandlingOrganisationComponent   //, canActivate: [RoleGuard], data: { userPermissionsId: '1164' }
      },
      {
        path: 'my-colleagues', component: MyColleaguesComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1165' }
      }
      
    ]
  }
    ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserDetailsRoutingModule { }

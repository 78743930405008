

export class Lookup {
  ID: number;
  lookupTypeId: number;
  name: string;
  text: string;
  value: number;
  displayOrder: number;
  alternateText: string;
  parentLookupValueId: number;
  isDelete: boolean;
  booleanValue: boolean;
}

import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, AfterViewChecked, HostListener, Inject } from '@angular/core';
import { LookupService } from '../../../shared/services/lookup.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { PaymentDetailService } from './payment-detail.service';
import { NgForm } from '@angular/forms';
import { strict } from 'assert';
import { IPayment } from './payment.model';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../../../shared/guard/unsaved-changes.guard';
import { IBusinessRule, IMMIValuation } from './business-rule.model';
import { IPaymentDetail } from './payment-detail.model';
import { SecurityService } from '../../../shared/services/security.service';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { ClaimDetailService } from '../claim-detail/claim-detail.service';
import { ApportionmentService } from "../../../shared/services/apportionment.service";
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPaymentBordereaux } from '../../../shared/models/payment-bordereaux.model';
import { ClaimComponent } from '../claim.component';
import * as _ from 'lodash';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service';
import { ManageDwpService } from '../../../shared/services/manage-dwp.service';
import { UniqueExcessArrangements } from '../../../shared/models/unique-excess-arrangment';
import { enumUserRole } from '../../../shared/models/common.enum';
import { IDropdownSettings } from 'ng-multiselect-dropdown';



@Component({
  selector: 'app-payment-detial',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.css']
})
export class PaymentDetailComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked, ComponentCanDeactivate {

  @ViewChild('paymentDetailForm', null) paymentDetailForm: NgForm;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('paymentDetailTable') paymentDetailTable: ElementRef;
  dtOptions: any = {};
  paymentForm: IPayment;
  defaultPaymentForm: IPayment;
  vaildPayment: boolean = true;
  itemIndex: number = null;
  paymentMethodDisable: boolean = false;
  isfieldPopulated: boolean = false;

  paymentRecoverykeys: any[];
  paymentTypekeys: any[];
  paymentCodekeys: any[];
  payeeCodekeys: any[];
  paymentMethodkeys: any[];
  paymentStatuskeys: any[];
  requiredLinkedDocumentTypeLookup: any[] = [];

  isGridLoaded: boolean = false;
  dtTrigger = new Subject();

  claimId: number;
  policyId: number;
  showPaymentAddPanelFlag: boolean = false;
  recoveryTypekeys: any[] = [];
  isTotalMMIReserverSufficient: boolean = true;
  mmiValuation: IMMIValuation;
  excess: IBusinessRule;
  lOI: IBusinessRule;
  isPolicyDateFlag: boolean = false;
  isPaymentOrRecovery: boolean = true;

  isMMIUser: boolean = false;
  hideWorkflowButton: boolean = true;

  //aggregatedClaim: number;

  saveMethod: any;
  isFormSubmitted: boolean = true;
  formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  handlingOrganisation: any[];
  originalBACSRemittancEmail: string
  validEmail: boolean
  dwpList: any[];
  isSettledButtonClicked: boolean = false;
  uniqueExcessArrangement = UniqueExcessArrangements.UniqueExcessArrangement;
  objUniqueExcess = {
    PolicyNumber: "",
    Policyholder: "",
    ClaimType: "",
    Location: [],
    IsExposurePeriod: 0
  };
  totalOfPaymentSummary: number;
  totalOfMMIPaid: number;
  totalOfMMIRecovered: number;
  totalOfExcessPaid: number;
  selectedPaymentLinkedDocuments: any[];
  //requiredLinkedDocument: any[];
  totalMMISharePercentage: number;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    this.saveMethod = this.onSubmit;
    let canNavigate: boolean = this.canNavigateWOSaving();
    return canNavigate;
  }


  private originalPaymentData: IPayment[];
  // isFormDirty(): boolean { return false; }

  isFormDirty(): boolean {
    let isDataSame = false;
    if (this.paymentForm.id == null) {
      isDataSame = _.isEqual(this.paymentForm, this.defaultPaymentForm);
      if (!isDataSame) {
        this.showFormDirtyWarningMessage();
        this.validationPayment();
      }
    }
    else if (this.paymentForm.id > 0) {
      let paymentrecord = Object.assign({}, this.paymentDetailService.formData.paymentDetail.find(a => a.id == this.paymentForm.id));
      isDataSame = _.isEqual(this.paymentForm, paymentrecord);
      if (!isDataSame) {
        this.showFormDirtyWarningMessage();
      }
    }

    return !isDataSame;
  }

  showFormDirtyWarningMessage(): void {
    this.notificationService.printWarningMessage("You have unsaved changes on Form. Please add them to Payment Grid and Save. Or Discard them using cancel button and Proceed.");
  }

  canNavigateWOSaving(): boolean {
    let currentPayments = { ...this.paymentDetailService.formData.paymentDetail };
    let isDataSame = _.isEqual(currentPayments, this.originalPaymentData);
    return isDataSame;
  }

  amberPaymentCode = [966, 967, 968, 969, 970, 971, 972];
  userPaymentAuthority: number = 0;
  totalMMIPaid: number = 0;

  modalRef: NgbModalRef;
  public verifiedPaymentDetail: IPaymentBordereaux;
  isMMISuperUserWithFinanceRole: boolean = false;
  deletedPaymentDetails: IPayment[] = [];

  isMMIUserWithClaimSuperUserRole: boolean = false;
  isMMIUserWithClaimsAndFinanceSuperUserRole: boolean = false;

  constructor(private lookupService: LookupService,
    private currentRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private paymentDetailService: PaymentDetailService,
    private claimDetailService: ClaimDetailService,
    securityService: SecurityService,
    private apportionmentService: ApportionmentService,
    private modalService: NgbModal,
    @Inject(ClaimComponent) private parent: ClaimComponent,
    private handlingOrganisationService: HandlingOrganisationService,
    private manageDwpService: ManageDwpService) {

    super(securityService, 1146);
    this.loadLookups();
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
    this.userPaymentAuthority = this.securityService.getUserData() != null ? this.securityService.getUserData().paymentAuthority : 0;

    this.showPaymentAddPanelFlag = false;
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
      this.hideWorkflowButton = (this.isMMIUser) ? false : true
    }

    let userDetail = this.securityService.getUserData();
    if (userDetail != null) {
      this.isMMISuperUserWithFinanceRole = ((userDetail.organisationId == 1) && (userDetail.superUser) && (userDetail.userRoleId == enumUserRole.Finance));
      this.isMMIUserWithClaimSuperUserRole = ((userDetail.organisationId == 1) && (userDetail.superUser) && (userDetail.userRoleId == enumUserRole.Claims));
      this.isMMIUserWithClaimsAndFinanceSuperUserRole = ((userDetail.organisationId == 1) && (userDetail.superUser) && (userDetail.userRoleId == enumUserRole.ClaimsAndFinance));
    }

    if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
      this.isReadOnlyMode = true;
    }
    this.parent.claimStatusId$.subscribe(x => {
      if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
        this.isReadOnlyMode = true;
      }
    });

    this.initialiseDataTable();

    let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    this.policyId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('policyId'));

    if (claimId != null) {
      this.claimId = claimId;
      this.checkPolicyDateOutOfRange(claimId);
      this.setLoadData(claimId);
    }

    this.dtTrigger.next();
    this.refreshGrid();

    this.bindHandlingOrganisation();
    this.bindDwp();

  }

  initialiseDataTable(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      scrollY: '50vh',
      scrollX: true,
      columnDefs: [
        { orderable: false, targets: -1 },
        { type: 'date-uk', "targets": 2 },
        { type: 'date-uk', "targets": 3 },
        { type: 'date-uk', "targets": 4 },
        { type: 'date-uk', "targets": 16 }
      ],
      "initComplete": function (settings, json) {
        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
      },
      dom: 'Blfrtip',
      //Configure the buttons
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          messageTop: "",
          title: "Payment Detail",
          filename: "PaymentDetail-" + Math.random(),
          exportOptions: {
            columns: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
          }
        }
      ],
    };
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: false,
    selectAllText: "All",
    itemsShowLimit: 1,
    allowSearchFilter: true,
    maxHeight: 150,
  };

  showPaymentAddPanel() {
    this.clearFields();
    this.showPaymentAddPanelFlag = true;
  }

  clearFields() {
    this.removeDwpInPayeeType();
    this.showPaymentAddPanelFlag = false;
    this.initialPaymentDetail();
    this.validEmail = true;
  }

  cancelButton() {
    this.clearFields();
    this.vaildPayment = true;
    this.itemIndex = null;
  }

  setLoadData(claimId) {
    this.paymentDetailService.getPaymentByClaimId(parseInt(claimId)).subscribe(res => {
      this.paymentDetailService.formData = res;
      if (this.paymentDetailService.formData.paymentDetail == null) {
        this.paymentDetailService.formData.paymentDetail = [];
      }
      const paymentDetailFetchedData = JSON.parse(JSON.stringify(this.paymentDetailService.formData.paymentDetail));
      this.originalPaymentData = { ...paymentDetailFetchedData };
      this.calculateSummary();
      this.calculateTotalMMIPaid();
      this.refreshGrid();
      this.initialPaymentDetail();
      this.defaultPaymentForm = { ...this.paymentForm };
      this.objUniqueExcess = {
        PolicyNumber: res.policyNumber,
        Policyholder: res.policyholder,
        ClaimType: res.claimType,
        Location: res.location,
        IsExposurePeriod: res.isExposurePeriod
      };
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  calculateSummary() {
    this.resetValue();

    this.paymentDetailService.formData.totalDamages = this.paymentDetailService.formData.valuationSummary[0].netPaid + this.paymentDetailService.formData.valuationSummary[0].excessPaid;
    this.paymentDetailService.formData.totalCost = this.paymentDetailService.formData.valuationSummary[1].netPaid + this.paymentDetailService.formData.valuationSummary[1].excessPaid;
    this.paymentDetailService.formData.totalCRU = this.paymentDetailService.formData.valuationSummary[2].netPaid + this.paymentDetailService.formData.valuationSummary[2].excessPaid;
    this.paymentDetailService.formData.totalDefenceCost = this.paymentDetailService.formData.valuationSummary[3].netPaid + this.paymentDetailService.formData.valuationSummary[3].excessPaid;
    this.totalOfPaymentSummary = this.paymentDetailService.formData.totalDamages + this.paymentDetailService.formData.totalCost + this.paymentDetailService.formData.totalCRU + this.paymentDetailService.formData.totalDefenceCost;

    this.totalOfMMIPaid = this.paymentDetailService.formData.valuationSummary[0].paidValue + this.paymentDetailService.formData.valuationSummary[1].paidValue + this.paymentDetailService.formData.valuationSummary[2].paidValue + this.paymentDetailService.formData.valuationSummary[3].paidValue;
    this.totalOfMMIRecovered = this.paymentDetailService.formData.valuationSummary[0].recoveries + this.paymentDetailService.formData.valuationSummary[1].recoveries + this.paymentDetailService.formData.valuationSummary[2].recoveries + this.paymentDetailService.formData.valuationSummary[3].recoveries;
    this.totalOfExcessPaid = this.paymentDetailService.formData.valuationSummary[0].excessPaid + this.paymentDetailService.formData.valuationSummary[1].excessPaid + this.paymentDetailService.formData.valuationSummary[2].excessPaid + this.paymentDetailService.formData.valuationSummary[3].excessPaid;
    //this.totalMMISharePercentage = (this.paymentDetailService.formData.valuationSummary[0].mmiPercentageValue + this.paymentDetailService.formData.valuationSummary[1].mmiPercentageValue + this.paymentDetailService.formData.valuationSummary[2].mmiPercentageValue + this.paymentDetailService.formData.valuationSummary[3].mmiPercentageValue) / 4;

    let totalCount = 0;

    if (this.paymentDetailService.formData.valuationSummary[0].mmiPercentageValue != 0)
      totalCount++;

    if (this.paymentDetailService.formData.valuationSummary[1].mmiPercentageValue != 0)
      totalCount++;

    if (this.paymentDetailService.formData.valuationSummary[2].mmiPercentageValue != 0)
      totalCount++;

    if (this.paymentDetailService.formData.valuationSummary[3].mmiPercentageValue != 0)
      totalCount++;

    this.totalMMISharePercentage = (this.paymentDetailService.formData.valuationSummary[0].mmiPercentageValue + this.paymentDetailService.formData.valuationSummary[1].mmiPercentageValue + this.paymentDetailService.formData.valuationSummary[2].mmiPercentageValue + this.paymentDetailService.formData.valuationSummary[3].mmiPercentageValue) / totalCount;

  }

  resetValue() {
    this.paymentDetailService.formData.totalDamages = 0;
    this.paymentDetailService.formData.totalCost = 0;
    this.paymentDetailService.formData.totalCRU = 0;
    this.paymentDetailService.formData.totalDefenceCost = 0;
    this.totalOfPaymentSummary = 0;
    this.totalOfMMIPaid = 0;
    this.totalOfMMIRecovered = 0;
    this.totalOfExcessPaid = 0;
    this.totalMMISharePercentage = 0;
  }

  initialPaymentDetail() {
    this.paymentForm = {
      id: null,
      claimId: this.paymentDetailService.formData.claimId,
      paymentOrRecovery: null,
      paymentOrRecoveryText: '',
      paymentType: null,
      paymentTypeText: '',
      paymentCode: null,
      paymentCodeText: '',
      paymentAmount: null,
      paymentMethod: null,
      paymentMethodText: '',
      paymentCategory: '',
      paymentCategoryId: null,
      payeeType: null,
      payeeTypeOther: '',
      paymentStatus: null,
      addressLine1: '',
      addressLine2: '',
      town: '',
      country: '',
      postcode: '',
      sortCode: '',
      accountNumber: '',
      payeeTypeText: '',
      approvedDate: null,
      chequeNumber: null,
      payeeName: '',
      addresseeName: '',
      isPayeeNameOverWritten: false,
      isOverWritten: false,
      isCancelCheque: false,
      parentPaymentDetailId: null,
      invoiceRef: '',
      isRecordUpdated: false,
      bacsNumber: '',
      createdByUserName: '',
      //sharepointFolderUrl: '',
      // linkPaymentDoctuments: '',
      paymentLinkedDocuments: [],
      organisationID: null,
      creditorId: null,
      claimantDetailId: null,
      claimantSolicitorId: null,
      isRecordDeleted: false,
      bacsRemittanceEmail: '',
      dwpID: null,
      createdDate: null,

      billingPeriodStartDate: null,
      billingPeriodEndDate: null,
      doesMMIShareMatch: false,
      requiredLinkedDocumentTypeId: [],
      requiredLinkedDocumentTypeIds: []
    };
  }
  loadLookups() {

    this.paymentRecoverykeys = this.lookupService.getLookupsByTypeId(38);
    this.paymentCodekeys = this.lookupService.getLookupsByTypeId(37);
    this.payeeCodekeys = this.lookupService.getLookupsByTypeId(41);
    this.paymentMethodkeys = this.lookupService.getLookupsByTypeId(42);
    this.paymentStatuskeys = this.lookupService.getLookupsByTypeId(43);
    this.removeDwpInPayeeType();
    this.requiredLinkedDocumentTypeLookup = this.lookupService.getLookupsByTypeId(65);
  }
  paymentRecoveryChange() {
    //this.vaildPayment = true;
    //if (this.itemIndex == null) {
    //  this.resetAllFields();
    //}
    this.isPaymentOrRecoverySelected(this.paymentForm.paymentOrRecovery);
    if (this.paymentForm.paymentOrRecovery != null) {
      let allpaymentTypekeys = this.lookupService.getLookupByParentId(this.paymentForm.paymentOrRecovery);
      //this.paymentTypekeys = allpaymentTypekeys.filter(({ id }) => id != 983);
      if (this.isMMIUser) {
        this.paymentTypekeys = allpaymentTypekeys.filter(({ id }) => id != 983);
      }
      else {
        this.paymentTypekeys = allpaymentTypekeys.filter(({ id }) => id != 983 && id != 982 && id != 977);
      }
    }
    else {
      this.paymentTypekeys = [];
    }
  }

  paymentCodeChange(val) {
    //this.vaildPayment = true;
    //if (this.itemIndex == null) {
    //  this.resetFieldsBelowPaymentCode();
    //}
    if (this.paymentForm.paymentCode != null) {
      let paymentCategory = this.getPaymentCategory(this.paymentForm.paymentCode);
      this.paymentForm.paymentCategory = paymentCategory.text;
      this.paymentForm.paymentCategoryId = paymentCategory.id;
      if (this.paymentForm.paymentCode != 966 && this.paymentForm.payeeType == 1486) {
        this.paymentForm.payeeName = "";
        this.paymentForm.bacsRemittanceEmail = "";
        this.paymentForm.sortCode = "";
        this.paymentForm.accountNumber = "";
        this.paymentForm.addressLine1 = "";
        this.paymentForm.addressLine2 = "";
        this.paymentForm.town = "";
        this.paymentForm.country = "";
        this.paymentForm.postcode = "";
        this.paymentForm.payeeType = null;
        this.removeDwpInPayeeType();
      }
      this.addRemoveDwpInPayeeType();

      if (this.paymentForm.paymentCode == 960 || this.paymentForm.paymentCode == 967 || this.paymentForm.paymentCode == 968 || this.paymentForm.paymentCode == 969 || this.paymentForm.paymentCode == 970 || this.paymentForm.paymentCode == 971 || this.paymentForm.paymentCode == 972) {

        //let billingPeriodStartDate_test = this.paymentForm.billingPeriodStartDate != null ? !(this.isIsoDate(this.paymentForm.billingPeriodStartDate)) ? this.paymentForm.billingPeriodStartDate.toUTCString() : this.paymentForm.billingPeriodStartDate : null;
        this.paymentForm.billingPeriodStartDate = null;
        this.paymentForm.billingPeriodEndDate = null;
        this.paymentForm.doesMMIShareMatch = null;
        this.paymentForm.requiredLinkedDocumentTypeId = null;
      }
      else {
        this.paymentForm.billingPeriodStartDate = null;
        this.paymentForm.billingPeriodEndDate = null;
        this.paymentForm.doesMMIShareMatch = null;
        this.paymentForm.requiredLinkedDocumentTypeId = null;
      }
    }
    else {
      this.paymentForm.paymentCategory = '';
    }
  }
  paymentTypeChange() {
    //this.vaildPayment = true;
    //if (this.itemIndex == null) {
    //  this.resetFieldsBelowPaymentType();
    //}
    //payment
    if (this.paymentForm.paymentOrRecovery == 973) {
      this.paymentSelected();
      this.setBACSRemittanceEmail();

    }
    //Recovery
    if (this.paymentForm.paymentOrRecovery == 974) {
      this.recoverySelected();
    }
  }

  recoverySelected() {
    this.paymentMethodDisable = false;
    //Banked by Creditor
    //Recovery of Excess
    //Adjustment Entry
    if (this.paymentForm.paymentType == 979 || this.paymentForm.paymentType == 981 || this.paymentForm.paymentType == 982) {
      this.paymentForm.paymentMethod = null;
      this.paymentMethodDisable = true;
    }
  }
  paymentSelected() {
    this.filterPaymentCode();
    this.paymentMethodDisable = false;
    //Scheme Payment by Policyholder
    if (this.paymentForm.paymentType == 975) {
      this.paymentForm.paymentMethod = null;
      this.paymentMethodDisable = true;
    }
    //Scheme Payment by MMI
    this.filterPaymentCodeForSchemePayments();
    //Adjustment Entry
    if (this.paymentForm.paymentType == 977) {
      this.paymentForm.paymentMethod = null;
      this.paymentMethodDisable = true;
    }
    //Excess Payment by Creditor
    if (this.paymentForm.paymentType == 978) {
      this.paymentForm.paymentMethod = null;
      this.paymentMethodDisable = true;
    }
  }
  filterPaymentCode(paymentCode?) {
    if (paymentCode != null) {
      this.paymentCodekeys = this.paymentCodekeys.filter(function (item) {
        return paymentCode.includes(item.id);
      })
    } else {
      this.paymentCodekeys = this.lookupService.getLookupsByTypeId(37);
    }
  }

  getPaymentCategory(paymentCode): any {
    var paymentCategory = [];
    let tempLookup = this.lookupService.getLookupById(paymentCode);

    if (tempLookup != null && tempLookup.length > 0) {
      let parentId = tempLookup[0].parentLookupValueId;
      tempLookup = this.lookupService.getLookupById(parentId);
      if (tempLookup != null)
        paymentCategory = tempLookup[0];
      else
        paymentCategory = null;
    }

    return paymentCategory;
  };
  //paymentMethodChange() {
  //  this.vaildPayment = true;
  //  if (this.itemIndex == null) {
  //    this.resetFieldsBelowPaymentMethod();
  //  }

  //  this.popuateFileds();
  //}

  popuateFileds() {
    this.vaildPayment = true;
    let isOrganizationIdExist = false;
    if (this.paymentForm.addressLine1.trim() == '' && this.paymentForm.town.trim() == '' && this.paymentForm.country.trim() == ''
      && this.paymentForm.postcode.trim() == '' && this.paymentForm.sortCode.trim() == '' && this.paymentForm.accountNumber.trim() == '' && this.paymentForm.payeeName.trim() == ''
      && this.itemIndex == null) {
      this.isfieldPopulated = true;
    }

    this.filedReset();
    //this.vaildPayment = true;
    //if (this.itemIndex == null) {
    //  this.resetFieldsBelowPayeeType();
    //}
    if (this.itemIndex != null) {
      this.paymentForm.organisationID = this.paymentDetailService.formData.paymentDetail[this.itemIndex].organisationID;
      if (this.paymentForm.organisationID > 0) {
        isOrganizationIdExist = true
      }
    }

    //Claimant
    if (this.paymentForm.payeeType == 984 && this.paymentDetailService.formData.claimantName != null) {
      this.paymentForm.payeeName = this.paymentDetailService.formData.claimantName;
      this.paymentForm.claimantDetailId = this.paymentDetailService.formData.claimantId;
    }
    //Claimant Solicitor
    if (this.paymentForm.payeeType == 985 && this.paymentDetailService.formData.claimantSolicitorName != null) {
      this.paymentForm.payeeName = this.paymentDetailService.formData.claimantSolicitorName;
      this.paymentForm.claimantSolicitorId = this.paymentDetailService.formData.claimantSolicitorId;
    }
    //BACS
    if (this.paymentForm.paymentMethod == 989) {
      //Handling Organisation
      if (this.paymentForm.payeeType == 986 && this.paymentDetailService.formData.handlingOrganisationDetail != null && !isOrganizationIdExist) {
        this.paymentForm.payeeName = this.paymentDetailService.formData.handlingOrganisationDetail.handlingOrganisationName;
        this.paymentForm.organisationID = this.paymentDetailService.formData.handlingOrganisationDetail.handlingOrganisationId;
        if (this.paymentForm.paymentOrRecovery == 973) {
          this.paymentForm.sortCode = this.paymentDetailService.formData.handlingOrganisationDetail.sortCode;
          this.paymentForm.accountNumber = this.paymentDetailService.formData.handlingOrganisationDetail.accountNumber;
        }
      }
      //Scheme Policyholder
      if (this.paymentForm.payeeType == 987 && this.paymentDetailService.formData.creditorDetail != null) {
        this.paymentForm.payeeName = this.paymentDetailService.formData.creditorDetail.creditorName;
        this.paymentForm.creditorId = this.paymentDetailService.formData.creditorDetail.creditorId;
      }
      if (this.paymentForm.paymentOrRecovery == 973) {
        //this.paymentForm.bacsRemittanceEmail = this.originalBACSRemittancEmail;
        this.setBACSRemittanceEmail();
      }

    }
    //Cheque
    if (this.paymentForm.paymentMethod == 990) {
      //Handling Organisation
      if (this.paymentDetailService.formData.handlingOrganisationDetail != null && this.paymentForm.payeeType == 986 && this.paymentForm.paymentOrRecovery == 973 && !isOrganizationIdExist) {
        this.paymentForm.addressLine1 = this.paymentDetailService.formData.handlingOrganisationDetail.addressLine1;
        this.paymentForm.addressLine2 = this.paymentDetailService.formData.handlingOrganisationDetail.addressLine2;
        this.paymentForm.town = this.paymentDetailService.formData.handlingOrganisationDetail.town;
        this.paymentForm.country = this.paymentDetailService.formData.handlingOrganisationDetail.country;
        this.paymentForm.postcode = this.paymentDetailService.formData.handlingOrganisationDetail.postcode;
        this.paymentForm.organisationID = this.paymentDetailService.formData.handlingOrganisationDetail.handlingOrganisationId;
      }
      //Scheme Policyholder
      if (this.paymentDetailService.formData.creditorDetail != null && this.paymentForm.payeeType == 987 && this.paymentForm.paymentOrRecovery == 973) {
        this.paymentForm.addressLine1 = this.paymentDetailService.formData.creditorDetail.addressLine1;
        this.paymentForm.addressLine2 = this.paymentDetailService.formData.creditorDetail.addressLine2;
        this.paymentForm.town = this.paymentDetailService.formData.creditorDetail.town;
        this.paymentForm.country = this.paymentDetailService.formData.creditorDetail.country;
        this.paymentForm.postcode = this.paymentDetailService.formData.creditorDetail.postcode;
        this.paymentForm.creditorId = this.paymentDetailService.formData.creditorDetail.creditorId;
      }
    }

    //Handling Organisation
    if (this.paymentForm.payeeType == 986 && this.paymentDetailService.formData.handlingOrganisationDetail != null && !isOrganizationIdExist) {
      this.paymentForm.payeeName = this.paymentDetailService.formData.handlingOrganisationDetail.handlingOrganisationName;
      this.paymentForm.organisationID = this.paymentDetailService.formData.handlingOrganisationDetail.handlingOrganisationId;
    }
    //Scheme Policyholder
    if (this.paymentForm.payeeType == 987 && this.paymentDetailService.formData.creditorDetail != null) {
      this.paymentForm.payeeName = this.paymentDetailService.formData.creditorDetail.creditorName;
      this.paymentForm.creditorId = this.paymentDetailService.formData.creditorDetail.creditorId;

    }
    if (isOrganizationIdExist) {
      this.changeHandlingOrganization();
    }


  }



  //paymentFieldReset() {
  //  this.paymentForm.payeeName = '';
  //  this.paymentForm.sortCode = '';
  //  this.paymentForm.accountNumber = '';
  //  this.paymentForm.addressLine1 = '';
  //  this.paymentForm.addressLine2 = '';
  //  this.paymentForm.town = '';
  //  this.paymentForm.country = '';
  //  this.paymentForm.postcode = ''; 
  //}

  //recoveryFieldReset() {
  //  this.paymentForm.paymentType = null;
  //  this.paymentForm.paymentCode = null;
  //  this.paymentForm.paymentAmount = null;
  //  this.paymentForm.paymentMethod = null;
  //  this.paymentForm.paymentCategory = null;
  //  this.paymentForm.payeeType = null;
  //  this.paymentForm.payeeName = '';
  //}

  //resetAllFields() {
  //  this.resetPaymentTypeField();
  //  this.resetPaymentCodeField();
  //  this.resetPaymentAmountField();
  //  this.resetPaymentMethodField();
  //  this.resetPaymentCategoryField();
  //  this.resetPayeeTypeField();
  //  this.resetFieldsBelowPayeeType();
  //}
  //resetFieldsBelowPaymentType() {
  //  this.resetPaymentCodeField();
  //  this.resetPaymentAmountField();
  //  this.resetPaymentMethodField();
  //  this.resetPaymentCategoryField();
  //  this.resetPayeeTypeField();
  //  this.resetFieldsBelowPayeeType();
  //}
  //resetFieldsBelowPaymentCode() {
  //  this.resetPaymentAmountField();
  //  this.resetPaymentMethodField();
  //  this.resetPaymentCategoryField();
  //  this.resetPayeeTypeField();
  //  this.resetFieldsBelowPayeeType();
  //}
  //resetFieldsBelowPaymentMethod() {
  //  this.resetPayeeTypeField();
  //  this.resetFieldsBelowPayeeType();
  //}

  //resetPaymentTypeField() {
  //  this.paymentForm.paymentType = null;
  //}
  //resetPaymentCodeField() {
  //  this.paymentForm.paymentCode = null;
  //}  
  //resetPaymentAmountField() {
  //  this.paymentForm.paymentAmount = null;
  //}
  //resetPaymentMethodField() {
  //  this.paymentForm.paymentMethod = null;
  //}
  //resetPaymentCategoryField() {
  //  this.paymentForm.paymentCategory = null;
  //}
  //resetPayeeTypeField() {
  //  this.paymentForm.payeeType = null;    
  //}

  //resetFieldsBelowPayeeType() {
  //  this.paymentForm.payeeName = '';
  //  this.paymentForm.sortCode = '';
  //  this.paymentForm.accountNumber = '';
  //  this.paymentForm.addressLine1 = '';
  //  this.paymentForm.addressLine2 = '';
  //  this.paymentForm.town = '';
  //  this.paymentForm.country = '';
  //  this.paymentForm.postcode = ''; 
  //}

  filedReset() {
    this.paymentForm.payeeName = '';
    this.paymentForm.sortCode = '';
    this.paymentForm.accountNumber = '';
    this.paymentForm.addressLine1 = '';
    this.paymentForm.addressLine2 = '';
    this.paymentForm.town = '';
    this.paymentForm.country = '';
    this.paymentForm.postcode = '';
    this.paymentForm.organisationID = null;
    this.paymentForm.creditorId = null;
    this.paymentForm.claimantDetailId = null;
    this.paymentForm.claimantSolicitorId = null;
    this.paymentForm.bacsRemittanceEmail = '';
    this.paymentForm.dwpID = null;
    this.paymentForm.doesMMIShareMatch = null;
  }

  validateBusinessRule() {
    let isValid: boolean = true;
    if (this.paymentForm.paymentType == 975 && this.paymentDetailService.formData.policyholderStatus.toUpperCase() == 'SOVR' && this.amberPaymentCode.includes(this.paymentForm.paymentCode)) {
      isValid = confirm('MMI should fund this payment type for SOVR Policyholders. ');
      if (isValid && this.paymentDetailService.formData.fscsProtected > 0.01) {
        isValid = confirm('MMI should fund this payment type for protected claims.');
      }
    }
    if (this.paymentForm.paymentType == 975 && (this.paymentDetailService.formData.policyholderStatus.toUpperCase() == 'SNON' || this.paymentDetailService.formData.policyholderStatus.toUpperCase() == 'SUND')) {
      isValid = confirm('MMI should fund this payment type for this class of Policyholder (SUND or SNON).');
      if (isValid && this.paymentDetailService.formData.fscsProtected > 0.01) {
        isValid = confirm('MMI should fund this payment type for protected claims.');
      }
    }
    return isValid;
  }
  verifyAddressOverWritten() {
    //Claimant
    if (this.paymentForm.payeeType == 984 && this.paymentDetailService.formData.claimantName != null) {
      if ((this.paymentForm.paymentType == 975 || this.paymentForm.paymentMethod == 989 || this.paymentForm.paymentMethod == 990)
        && (this.paymentForm.payeeName != this.paymentDetailService.formData.claimantName)) {
        this.paymentForm.isPayeeNameOverWritten = true;
      }
      else {
        this.paymentForm.isPayeeNameOverWritten = false;
      }
    }
    //Claimant Solicitor
    if (this.paymentForm.payeeType == 985 && this.paymentDetailService.formData.claimantSolicitorName != null) {
      if ((this.paymentForm.paymentType == 975 || this.paymentForm.paymentMethod == 989 || this.paymentForm.paymentMethod == 990)
        && (this.paymentForm.payeeName != this.paymentDetailService.formData.claimantSolicitorName)) {
        this.paymentForm.isPayeeNameOverWritten = true;
      }
      else {
        this.paymentForm.isPayeeNameOverWritten = false;
      }
    }

    //Handling Organisation
    if (this.paymentForm.payeeType == 986 && this.paymentDetailService.formData.handlingOrganisationDetail != null) {
      if (this.paymentForm.paymentOrRecovery == 973 && this.paymentForm.paymentMethod == 990 && (this.paymentForm.addressLine1 != this.paymentDetailService.formData.handlingOrganisationDetail.addressLine1 ||
        this.paymentForm.town != this.paymentDetailService.formData.handlingOrganisationDetail.town ||
        this.paymentForm.country != this.paymentDetailService.formData.handlingOrganisationDetail.country ||
        this.paymentForm.postcode != this.paymentDetailService.formData.handlingOrganisationDetail.postcode)) {
        this.paymentForm.isOverWritten = true;
      }
      else {
        this.paymentForm.isOverWritten = false;
      }

      if (this.paymentForm.paymentType == 976 && this.paymentForm.paymentMethod == 989 && (
        this.paymentForm.sortCode != this.paymentDetailService.formData.handlingOrganisationDetail.sortCode ||
        this.paymentForm.accountNumber != this.paymentDetailService.formData.handlingOrganisationDetail.accountNumber ||
        this.paymentForm.payeeName != this.paymentDetailService.formData.handlingOrganisationDetail.handlingOrganisationName)) {
        this.paymentForm.isPayeeNameOverWritten = true;
      }
      else if ((this.paymentForm.paymentType == 975 || this.paymentForm.paymentMethod == 989 || this.paymentForm.paymentMethod == 990)
        && (this.paymentForm.payeeName != this.paymentDetailService.formData.handlingOrganisationDetail.handlingOrganisationName)) {
        this.paymentForm.isPayeeNameOverWritten = true;
      }
      else {
        this.paymentForm.isPayeeNameOverWritten = false;
      }
    }
    else if (this.paymentForm.payeeType == 987 && this.paymentDetailService.formData.creditorDetail != null) {
      if (this.paymentForm.paymentOrRecovery == 973 && this.paymentForm.paymentMethod == 990 && (this.paymentForm.addressLine1 != this.paymentDetailService.formData.creditorDetail.addressLine1 ||
        this.paymentForm.town != this.paymentDetailService.formData.creditorDetail.town ||
        this.paymentForm.country != this.paymentDetailService.formData.creditorDetail.country ||
        this.paymentForm.postcode != this.paymentDetailService.formData.creditorDetail.postcode)) {
        this.paymentForm.isOverWritten = true;
      }
      else {
        this.paymentForm.isOverWritten = false;
      }
      if ((this.paymentForm.paymentType == 975 || this.paymentForm.paymentMethod == 989 || this.paymentForm.paymentMethod == 990)
        && (this.paymentForm.payeeName != this.paymentDetailService.formData.creditorDetail.creditorName)) {
        this.paymentForm.isPayeeNameOverWritten = true;
      }
      else {
        this.paymentForm.isPayeeNameOverWritten = false;
      }
    }
  }
  focusOutFunction() {
    this.validEmail = true;
  }
  validationPayment() {
    this.vaildPayment = true;
    this.validEmail = true;
    if (this.paymentForm.paymentOrRecovery == null) {
      return this.vaildPayment = false;
    }

    if (this.paymentForm.paymentOrRecovery == 973) {

      if (this.paymentForm.paymentOrRecovery == null || this.paymentForm.paymentType == null || this.paymentForm.paymentCode == null || this.paymentForm.paymentAmount == null ||
        (!this.paymentMethodDisable && this.paymentForm.paymentMethod == null) || this.paymentForm.payeeType == null || this.paymentForm.payeeName == null) {
        return this.vaildPayment = false;
      }

      if (this.paymentForm.paymentAmount <= 0 || this.paymentForm.payeeName.trim() == '') {
        return this.vaildPayment = false;
      }

      //Scheme Payment by Policyholder
      if ((this.paymentForm.paymentType == 975 && (this.paymentMethodDisable && this.paymentForm.paymentMethod == null)) && this.paymentForm.payeeName.trim() == '') {
        return this.vaildPayment = false;
      }

      //BACS
      if (this.paymentForm.paymentMethod == 989 && (this.paymentForm.payeeName.trim() == '' || this.paymentForm.sortCode.trim() == '' || this.paymentForm.bacsRemittanceEmail.trim() == '' ||
        this.paymentForm.sortCode.trim().length < 6 || this.paymentForm.accountNumber.trim() == '' || this.paymentForm.accountNumber.trim().length < 8 || this.paymentDetailForm.controls["bacsRemittanceEmail"].invalid)) {


        if (this.paymentForm.accountNumber.trim().length < 8) {
          this.notificationService.printWarningMessage("Please enter 8 digit account number");
        }
        if (this.paymentForm.sortCode.trim().length < 6) {
          this.notificationService.printWarningMessage("Please enter 6 digit sort code in format");
        }
        if (this.paymentForm.bacsRemittanceEmail.trim().length == 0) {
          this.notificationService.printWarningMessage("Please enter email");
        } if (this.paymentDetailForm.controls["bacsRemittanceEmail"].invalid) {
          this.notificationService.printWarningMessage("Please enter valid email");
          this.validEmail = false;
          return this.vaildPayment = false;
        }
        return this.vaildPayment = false;
      }

      // Cheque
      if (this.paymentForm.paymentMethod == 990 && (this.paymentForm.payeeName == null || this.paymentForm.addressLine1 == null ||
        this.paymentForm.town == null || this.paymentForm.country == null || this.paymentForm.postcode == null)) {
        return this.vaildPayment = false;
      }
      else if (this.paymentForm.paymentMethod == 990 && (this.paymentForm.payeeName.trim() == '' || this.paymentForm.addressLine1.trim() == '' ||
        this.paymentForm.town.trim() == '' || this.paymentForm.country.trim() == '' || this.paymentForm.postcode.trim() == '')) {
        return this.vaildPayment = false;
      }
      if (this.paymentForm.paymentType == 976 && (this.paymentForm.invoiceRef == null || this.paymentForm.invoiceRef.trim() == '')) {
        return this.vaildPayment = false;
      }


      if (this.paymentForm.paymentCode == 960 || this.paymentForm.paymentCode == 967 || this.paymentForm.paymentCode == 968 || this.paymentForm.paymentCode == 969 || this.paymentForm.paymentCode == 970 || this.paymentForm.paymentCode == 971 || this.paymentForm.paymentCode == 972) {
        if (this.paymentForm.doesMMIShareMatch == null) {
          return this.vaildPayment = false;
        }

        if (this.paymentForm.billingPeriodStartDate == null) {
          return this.vaildPayment = false;
        }

        if (this.paymentForm.billingPeriodEndDate == null) {
          return this.vaildPayment = false;
        }

        if (this.paymentForm.requiredLinkedDocumentTypeIds.length == 0) {
          this.notificationService.printWarningMessage("Please select Required Linked Documents");

          return this.vaildPayment = false;
        }
      }

    }
    else if (this.paymentForm.paymentOrRecovery == 974) {

      if (this.paymentForm.paymentType == null || this.paymentForm.paymentCode == null || this.paymentForm.paymentAmount == null ||
        this.paymentForm.payeeType == null || this.paymentForm.payeeName.trim() == '') {
        return this.vaildPayment = false;
      }
      if (this.paymentForm.paymentAmount <= 0) {
        return this.vaildPayment = false;
      }

      if (this.paymentForm.paymentType == 980 && this.paymentForm.paymentMethod == null) {
        return this.vaildPayment = false;
      }


      if (this.paymentForm.paymentCode == 960 || this.paymentForm.paymentCode == 967 || this.paymentForm.paymentCode == 968 || this.paymentForm.paymentCode == 969 || this.paymentForm.paymentCode == 970 || this.paymentForm.paymentCode == 971 || this.paymentForm.paymentCode == 972) {

        if (this.paymentForm.doesMMIShareMatch == null) {
          return this.vaildPayment = false;
        }

        if (this.paymentForm.billingPeriodStartDate == null) {
          return this.vaildPayment = false;
        }

        if (this.paymentForm.billingPeriodEndDate == null) {
          return this.vaildPayment = false;
        }


        if (this.paymentForm.requiredLinkedDocumentTypeIds.length == 0) {

          this.notificationService.printWarningMessage("Please select Required Linked Documents");

          return this.vaildPayment = false;
        }

      }
    }

    return this.vaildPayment;
  }

  validatePaymentAgainstMMIReserve(InputpaymentAmount, MMIReserve: number): boolean {
    let isValidPayment: boolean = false;

    let currentRecordPaymentAmount: number = 0;
    if (this.itemIndex != null) {
      currentRecordPaymentAmount = this.paymentDetailService.formData.paymentDetail[this.itemIndex].paymentAmount;
    }
    let paymentCategoryId: number = this.paymentForm.paymentCategoryId;
    let unApprovedPaymentAmountIncludingExcess: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 973
      && i.paymentCategoryId == paymentCategoryId && i.paymentStatus != 994 && i.paymentStatus != 995)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    let unApprovedRecoveryAmountIncludingExcess: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 974
      && i.paymentCategoryId == paymentCategoryId && i.paymentStatus != 994 && i.paymentStatus != 995)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    unApprovedPaymentAmountIncludingExcess = unApprovedPaymentAmountIncludingExcess - currentRecordPaymentAmount;

    let RemainingMMIReserve: number = MMIReserve - (unApprovedPaymentAmountIncludingExcess - unApprovedRecoveryAmountIncludingExcess);
    RemainingMMIReserve = Number(RemainingMMIReserve.toFixed(2));
    if (RemainingMMIReserve >= InputpaymentAmount) {
      isValidPayment = true;
    }

    return isValidPayment;
  }

  validateExcessPaymentAgainstMMIReserve(InputpaymentAmount, MMIReserve: number): boolean {
    let isValidPayment: boolean = false;

    let currentRecordPaymentAmount: number = 0;
    if (this.itemIndex != null) {
      currentRecordPaymentAmount = this.paymentDetailService.formData.paymentDetail[this.itemIndex].paymentAmount;
    }
    let paymentCategoryId: number = this.paymentForm.paymentCategoryId;
    let sumOfExcessTypePayment: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 973
      && i.paymentCategoryId == paymentCategoryId && i.paymentType == 978 && i.paymentStatus != 994 && i.paymentStatus != 995)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    let sumOfExcessTypeRecovery: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 974
      && i.paymentCategoryId == paymentCategoryId && i.paymentType == 981 && i.paymentStatus != 994 && i.paymentStatus != 995)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    sumOfExcessTypePayment = sumOfExcessTypePayment - currentRecordPaymentAmount;

    let RemainingMMIReserve: number = MMIReserve - (sumOfExcessTypePayment - sumOfExcessTypeRecovery);
    RemainingMMIReserve = Number(RemainingMMIReserve.toFixed(2));
    if (RemainingMMIReserve >= InputpaymentAmount) {
      isValidPayment = true;
    }

    return isValidPayment;
  }

  calculateRemainingMMIReserve(MMIReserve: number): number {
    let remainingMMIReserve: number = 0;
    let currentRecordPaymentAmount: number = 0;
    if (this.itemIndex != null) {
      currentRecordPaymentAmount = this.paymentDetailService.formData.paymentDetail[this.itemIndex].paymentAmount;
    }
    let paymentCategoryId: number = this.paymentForm.paymentCategoryId;
    let unApprovedPaymentAmount: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 973
      && i.paymentCategoryId == paymentCategoryId && i.paymentStatus != 994 && i.paymentStatus != 995)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    let unApprovedRecoveryAmount: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 974
      && i.paymentCategoryId == paymentCategoryId && i.paymentStatus != 994 && i.paymentStatus != 995)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    unApprovedPaymentAmount = unApprovedPaymentAmount - currentRecordPaymentAmount;
    remainingMMIReserve = MMIReserve - (unApprovedPaymentAmount - unApprovedRecoveryAmount);
    remainingMMIReserve = Number(remainingMMIReserve.toFixed(2));

    return remainingMMIReserve;
  }

  calculateRemainingMMIPaid(MMIPaid: number): number {
    let remainingMMIPaid: number = 0;
    let currentRecoveryRecord: IPayment = null;
    if (this.itemIndex != null) {
      currentRecoveryRecord = this.paymentDetailService.formData.paymentDetail[this.itemIndex];
      //currentRecordPaymentAmount = this.paymentDetailService.formData.paymentDetail[this.itemIndex].paymentAmount;
    }

    let paymentCategoryId: number = this.paymentForm.paymentCategoryId;
    let unApprovedPaymentAmount: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 973
      && i.paymentCategoryId == paymentCategoryId && i.paymentType != 978 && i.paymentStatus != 994 && i.paymentStatus != 995)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    let unApprovedRecoveryAmount: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 974
      && i.paymentCategoryId == paymentCategoryId && i.paymentType != 981 && i.paymentStatus != 994 && i.paymentStatus != 995)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    if (currentRecoveryRecord != null) {
      if (currentRecoveryRecord.paymentStatus == 991) {
        unApprovedRecoveryAmount = unApprovedRecoveryAmount - currentRecoveryRecord.paymentAmount;
      }
      else if (currentRecoveryRecord.paymentStatus == 992) {
        MMIPaid = MMIPaid - currentRecoveryRecord.paymentAmount;
      }
    }

    remainingMMIPaid = MMIPaid + (unApprovedPaymentAmount - unApprovedRecoveryAmount);

    return remainingMMIPaid;
  }
  calculateExcessPaymentForAllCategory(): number {
    let netTotalExcess: number = 0;
    let currentRecordPaymentAmount: number = 0;
    if (this.itemIndex != null) {
      currentRecordPaymentAmount = this.paymentDetailService.formData.paymentDetail[this.itemIndex].paymentAmount;
    }

    let sumOfExcessTypePaymentOfAllCategory: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 973 && i.paymentType == 978
      && i.paymentStatus != 994 && i.paymentStatus != 995 && i.id == null)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    let sumOfExcessTypeRecoveryOfAllCategory: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 974 && i.paymentType == 981
      && i.paymentStatus != 994 && i.paymentStatus != 995 && i.id == null)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    sumOfExcessTypePaymentOfAllCategory = sumOfExcessTypePaymentOfAllCategory - currentRecordPaymentAmount;
    netTotalExcess = sumOfExcessTypePaymentOfAllCategory - sumOfExcessTypeRecoveryOfAllCategory;

    return netTotalExcess;
  }
  calculateExcessPaymentByCategory(excessPaid: number): number {
    let netExcessPaid: number = 0;
    let currentRecordPaymentAmount: number = 0;
    if (this.itemIndex != null) {
      currentRecordPaymentAmount = this.paymentDetailService.formData.paymentDetail[this.itemIndex].paymentAmount;
    }

    let paymentCategoryId: number = this.paymentForm.paymentCategoryId;
    let sumOfUnApprovedExcessTypePayment: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 973
      && i.paymentCategoryId == paymentCategoryId && i.paymentType == 978 && i.paymentStatus != 994 && i.paymentStatus != 995)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    let sumOfUnApprovedExcessTypeRecovery: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 974
      && i.paymentCategoryId == paymentCategoryId && i.paymentType == 981 && i.paymentStatus != 994 && i.paymentStatus != 995)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    sumOfUnApprovedExcessTypeRecovery = sumOfUnApprovedExcessTypeRecovery - currentRecordPaymentAmount;
    netExcessPaid = excessPaid + (sumOfUnApprovedExcessTypePayment - sumOfUnApprovedExcessTypeRecovery);

    return netExcessPaid;
  }

  calculateUnSavedPaymentForAllCategory(): number {
    let totalUnsavedPaymentAmount: number = 0;
    let currentRecordPaymentAmount: number = 0;
    //if (this.itemIndex != null) {
    //  currentRecordPaymentAmount = this.paymentDetailService.formData.paymentDetail[this.itemIndex].paymentAmount;
    //}

    let sumOfUnSavedPaymentOfAllCategory: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 973 && i.paymentType != 978
      && i.paymentCategoryId != 960 && i.id == null)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    let sumOfUnSavedRecoveryOfAllCategory: number = this.paymentDetailService.formData.paymentDetail.filter(i => i.paymentOrRecovery == 974 && i.paymentType != 981
      && i.paymentCategoryId != 960 && i.id == null)
      .reduce(function (a, b) {
        return a + b.paymentAmount;
      }, 0);

    sumOfUnSavedPaymentOfAllCategory = sumOfUnSavedPaymentOfAllCategory - currentRecordPaymentAmount;
    totalUnsavedPaymentAmount = sumOfUnSavedPaymentOfAllCategory - sumOfUnSavedRecoveryOfAllCategory;

    return totalUnsavedPaymentAmount;
  }

  makePayment() {
    //Payment
    if (this.paymentForm.paymentOrRecovery == 973 && this.paymentForm.paymentType != 978) {
      // MMI Reserve validation      
      this.paymentDetailService.getTotalMMIReserveByClaimId(this.claimId, this.paymentForm.paymentCategoryId).subscribe(response => {
        this.mmiValuation = response;

        console.log('MMIReserver: ' + this.mmiValuation.mmiReserve);
        let validPayment: boolean = this.validatePaymentAgainstMMIReserve(this.paymentForm.paymentAmount, this.mmiValuation.mmiReserve)
        if (validPayment) {
          let remainingMMIReserve = this.calculateRemainingMMIReserve(this.mmiValuation.mmiReserve)
          if (this.paymentForm.paymentAmount <= remainingMMIReserve) {
            //Call the LOI Check                      
            let totalUnsavedPaymentAmount: number = this.calculateUnSavedPaymentForAllCategory();
            totalUnsavedPaymentAmount = totalUnsavedPaymentAmount + this.paymentForm.paymentAmount;
            if (this.paymentForm.paymentCategoryId == 960) {
              //Add Payment in Grid
              this.addPaymentInGridAfterValidation();
            }
            else {
              //Validate LOI
              this.validateLOI(totalUnsavedPaymentAmount);
            }
          }
          else {
            this.notificationService.printHtmlErrorMessage('<p>Reserve is insufficient for Payment.</br></br>Please review Full Liability Valuation to correct the Reserve.</br></br> Alternatively, please review the Payment amount.</p>');
          }
        }
        else {
          this.notificationService.printHtmlErrorMessage('<p>Reserve is insufficient for Payment.</br></br>Please review Full Liability Valuation to correct the Reserve.</br></br> Alternatively, please review the Payment amount.</p>');
        }
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      );
    }
  }

  makeExcessPayment() {

    this.paymentDetailService.getTotalMMIReserveByClaimId(this.claimId, this.paymentForm.paymentCategoryId).subscribe(response => {
      this.mmiValuation = response;

      console.log('MMIReserver: ' + this.mmiValuation.mmiReserve);
      let validPayment: boolean = this.validateExcessPaymentAgainstMMIReserve(this.paymentForm.paymentAmount, this.mmiValuation.mmiReserve)
      if (validPayment) {
        // Excess Payment by Policyholder
        let netExcess: number = this.calculateExcessPaymentForAllCategory();
        let totalExcessPayment = this.paymentForm.paymentAmount + netExcess;
        this.validateExcess(totalExcessPayment, 'ExcessPayment')
      }
      else {
        this.notificationService.printHtmlErrorMessage('<p>Total Excess Paid exceeds MMI Reserve for this Payment Category.</br></br> Please adjust Payment amount and / or review the Full Liability Valuation for this Payment Category. </p>');
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  makeRecoveryPayment() {
    this.paymentDetailService.getTotalMMIReserveByClaimId(this.claimId, this.paymentForm.paymentCategoryId).subscribe(response => {
      this.mmiValuation = response;
      console.log('Recovery MMIPaid: ' + this.mmiValuation.mmiPaid);
      let userDetail = this.securityService.getUserData();
      let remainingMMIPaid = this.calculateRemainingMMIPaid(this.mmiValuation.mmiPaid)
      if (this.paymentForm.paymentAmount <= remainingMMIPaid) {
        this.addPaymentInGridAfterValidation();
      }
      else if (this.paymentForm.paymentAmount > remainingMMIPaid && userDetail.organisationId == 1) {
        this.addPaymentInGridAfterValidation();
        this.notificationService.printHtmlWarningMessage('<p>Recovery amount is greater than the MMI Paid amount for this payment category.</br></br> Please adjust recovery amount and / or review the payment category.</br></br> If the Recovery represents sums recovered across multiple payment categories i.e. Damages, Costs, Defence Costs or CRU, then a separate Recovery entry should be made to identify the amount recovered against each Payment Category.</p>');
      }
      else {
        this.notificationService.printHtmlErrorMessage('<p>Recovery amount is greater than the MMI Paid amount for this payment category.</br></br> Please adjust recovery amount and / or review the payment category.</br></br> If the Recovery represents sums recovered across multiple payment categories i.e. Damages, Costs, Defence Costs or CRU, then a separate Recovery entry should be made to identify the amount recovered against each Payment Category.</p>');
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  makeRecoveryExcessPayment() {
    this.paymentDetailService.getTotalMMIReserveByClaimId(this.claimId, this.paymentForm.paymentCategoryId).subscribe(response => {
      this.mmiValuation = response;
      console.log('ExcessPaid: ' + this.mmiValuation.excessPaid);
      let netExcessPaid: number = this.calculateExcessPaymentByCategory(this.mmiValuation.excessPaid);
      console.log('NetExcessPaid: ' + netExcessPaid);
      let userDetail = this.securityService.getUserData();
      if (this.paymentForm.paymentAmount <= netExcessPaid) {
        this.addPaymentInGridAfterValidation();
      }
      else if (this.paymentForm.paymentAmount > netExcessPaid && userDetail.organisationId == 1) {
        this.addPaymentInGridAfterValidation();
        this.notificationService.printHtmlWarningMessage('<p>Excess Recovery amount input exceeds Excess Paid balance.</br></br> Please adjust Excess Recovery amount.</p>');
      }
      else {
        this.notificationService.printHtmlErrorMessage('<p>Excess Recovery amount input exceeds Excess Paid balance.</br></br> Please adjust Excess Recovery amount.</p>');
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  addInPaymentGrid() {
    if (this.validationPayment()) {
      if (this.validateBusinessRule()) {
        if (this.paymentForm.paymentOrRecovery == 973 && this.paymentForm.paymentType == 978) {
          this.makeExcessPayment();
        }
        else if (this.paymentForm.paymentOrRecovery == 974 && this.paymentForm.paymentType == 981) {
          this.makeRecoveryExcessPayment();
        }
        else {
          let isAuthorise: boolean = this.checkUserPaymentAuthority();
          if (isAuthorise) {
            this.makePaymentIfExcessPaymentDone();
          }
          else {
            this.notificationService.printHtmlErrorMessage('<p>The requested payment exceeds your Payment Authority. Please refer to a colleague with a sufficient Payment Authority to request the payment.</p>');
          }
        }
      }
    }
  }

  checkUserPaymentAuthority() {
    let isAuthorise: boolean = false;
    let currentRecord: IPayment = null;

    if (this.itemIndex != null) {
      currentRecord = this.paymentDetailService.formData.paymentDetail[this.itemIndex];
      let netAmountEntered: number = 0;
      let currentRecordPaymentAmount: number = currentRecord.paymentAmount;
      if (this.paymentForm.paymentAmount >= currentRecordPaymentAmount) {
        netAmountEntered = this.paymentForm.paymentAmount - currentRecordPaymentAmount;
      }
      else {
        netAmountEntered = currentRecordPaymentAmount - this.paymentForm.paymentAmount;
        netAmountEntered = Math.abs(netAmountEntered);
      }
      isAuthorise = netAmountEntered <= this.userPaymentAuthority;
    }
    else {
      isAuthorise = this.paymentForm.paymentAmount <= this.userPaymentAuthority;
    }

    return isAuthorise;
  }

  makePaymentIfExcessPaymentDone() {

    if (this.paymentDetailService.formData.isAggregatedClaim) {
      if (this.paymentForm.paymentOrRecovery == 973) {
        if (this.paymentForm.paymentType != 978) {
          this.makePayment();
        }
      }
      else if (this.paymentForm.paymentOrRecovery == 974) {
        // Recovery Validation
        if (this.paymentForm.paymentType != 981) {
          this.makeRecoveryPayment();
        }
      }
    }
    else {
      this.paymentDetailService.getExcessByClaimId(this.claimId).subscribe(response => {
        this.excess = response;
        if (this.excess.outstanding <= 0 && (this.excess.totalPaidApproved >= this.excess.totalIncurred)) {
          if (this.paymentForm.paymentOrRecovery == 973) {
            if (this.paymentForm.paymentType != 978) {
              this.makePayment();
            }
          }
          else if (this.paymentForm.paymentOrRecovery == 974) {
            // Recovery Validation
            if (this.paymentForm.paymentType != 981) {
              this.makeRecoveryPayment();
            }
          }
        }
        else {
          let userDetail = this.securityService.getUserData();
          if (this.paymentForm.paymentOrRecovery == 974 && userDetail.organisationId == 1) {
            this.makeRecoveryPayment();
          }
          else if (this.excess.outstanding > 0 && this.paymentForm.paymentOrRecovery == 973 && (this.isMMIUserWithClaimSuperUserRole || this.isMMIUserWithClaimsAndFinanceSuperUserRole)) {
            this.makePayment();
          }
          else {
            this.notificationService.printHtmlErrorMessage('<p>Excess amount is £' + this.excess.totalIncurred + '</br>' + 'Excess paid is £' + this.excess.totalPaid + '</br>' + 'Excess (payable by Insured) is £' + this.excess.outstanding + '</br></br>' + 'Total Excess amount applicable to this claim has not yet been Paid/Recorded in Pierian.</br></br> Please review the Excess applicable and ensure the Policyholder has been advised of the same.</br></br> Once the Policyholder has Paid the Excess amount due on the claim, those payments should be recorded as an Excess Payment before any Scheme Payments are recorded.</p>');
          }
        }
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      );
    }
  }

  calculateTotalMMIPaid() {

    for (let index = 0; index < this.paymentDetailService.formData.valuationSummary.length; index++) {
      this.totalMMIPaid += this.paymentDetailService.formData.valuationSummary[index].netPaid;
      //this.paymentDetailService.formData.totalDamages = this.paymentDetailService.formData.valuationSummary[0].netPaid + this.paymentDetailService.formData.valuationSummary[0].excessPaid;
      //this.paymentDetailService.formData.totalCost = this.paymentDetailService.formData.valuationSummary[1].netPaid + this.paymentDetailService.formData.valuationSummary[1].excessPaid;
      //this.paymentDetailService.formData.totalCRU = this.paymentDetailService.formData.valuationSummary[2].netPaid + this.paymentDetailService.formData.valuationSummary[2].excessPaid;
      //this.paymentDetailService.formData.totalDefenceCost = this.paymentDetailService.formData.valuationSummary[3].netPaid + this.paymentDetailService.formData.valuationSummary[3].excessPaid;
    }
  }

  validateExcess(totalExcessAmount: number, type: string) {

    if (this.paymentDetailService.formData.isAggregatedClaim) {
      this.addPaymentInGridAfterValidation();
    }
    else {
      this.paymentDetailService.getExcessByClaimId(this.claimId).subscribe(response => {
        this.excess = response;
        if (this.excess != null) {
          console.log('Excess: ' + JSON.stringify(this.excess));
          if (this.excess.isExcessApplicable && totalExcessAmount <= this.excess.totalIncurred && totalExcessAmount <= this.excess.outstanding) {
            //Add Payment in Grid
            this.addPaymentInGridAfterValidation();
          }
          else {
            if (this.itemIndex != null) {
              let currentRecordPaymentAmount = this.paymentDetailService.formData.paymentDetail[this.itemIndex].paymentAmount;
              let userEnteredPaymentAmount = this.paymentForm.paymentAmount;
              if ((userEnteredPaymentAmount <= currentRecordPaymentAmount) && (userEnteredPaymentAmount <= this.excess.totalIncurred)) {
                this.addPaymentInGridAfterValidation();
              }
              else {
                this.notificationService.printHtmlErrorMessage('<p>Excess amount is £' + this.excess.totalIncurred + '</br>' + 'Excess paid is £' + this.excess.totalPaid + '</br>' + 'Excess (payable by Insured) is £' + this.excess.outstanding + '</br></br>Total Excess Paid exceeds Excess to be applied to the claim.</br></br>Please adjust the Excess Payment entry accordingly.</br></br>You can review the Excess calculation (via the button at the top of the apportionment tab).</p>');
              }
            }
            else {
              this.notificationService.printHtmlErrorMessage('<p>Excess amount is £' + this.excess.totalIncurred + '</br>' + 'Excess paid is £' + this.excess.totalPaid + '</br>' + 'Excess (payable by Insured) is £' + this.excess.outstanding + '</br></br>Total Excess Paid exceeds Excess to be applied to the claim.</br></br>Please adjust the Excess Payment entry accordingly.</br></br>You can review the Excess calculation (via the button at the top of the apportionment tab).</p>');
            }
          }
        }
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      );
    }
  }

  validateLOI(totalUnsavedPaymentAmount: number) {
    if (this.paymentDetailService.formData.isAggregatedClaim || this.paymentDetailService.formData.isLineOfBusinessEL) {
      //Add Payment in Grid
      this.addPaymentInGridAfterValidation();
    }
    else {
      this.paymentDetailService.getLOIByClaimId(this.claimId).subscribe(res => {
        this.lOI = res;
        if (this.lOI != null) {
          console.log('LOI: ' + JSON.stringify(this.lOI));
          if (this.lOI.isLOIEnable && ((this.lOI.outstanding < 0) || ((this.lOI.outstanding - totalUnsavedPaymentAmount) < 0))) {
            this.notificationService.printHtmlErrorMessage('<p>Total of MMI Paid amount is breaching the Limit of Indemnity applied on the claim.</br></br> Please review Limit of Indemnity to ensure correct value is being recorded as a Scheme Payment.</p>');
          }
          else {
            //Add Payment in Grid
            this.addPaymentInGridAfterValidation();
          }
        }
        else {
          //Add Payment in Grid
          this.addPaymentInGridAfterValidation();
        }
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      );
    }
  }

  addPaymentInGridAfterValidation() {
    this.getPaymentCategory
    this.verifyAddressOverWritten();
    this.assignTextforField();

    if (this.itemIndex == null) {
      this.paymentDetailService.formData.paymentDetail.push(this.paymentForm);
      if (this.paymentForm.paymentOrRecovery == 973) {
        this.ShowValidationMessageOfUniqueExcessArrangements();
      }
    }
    else {
      this.paymentForm.isRecordUpdated = true;
      this.paymentDetailService.formData.paymentDetail[this.itemIndex] = this.paymentForm;
      this.itemIndex = null;
    }

    this.refreshGrid();
    this.clearFields();
  }

  editPaymentDetail(indexItem) {
    this.showPaymentAddPanelFlag = true;
    this.paymentForm = Object.assign({}, this.paymentDetailService.formData.paymentDetail[indexItem]);
    this.addRemoveDwpInPayeeType();
    this.originalBACSRemittancEmail = this.paymentForm.bacsRemittanceEmail;
    this.itemIndex = indexItem;
    // this.paymentForm.linkPaymentDoctuments = "";
    this.paymentRecoveryChange();
    if (this.paymentForm.paymentMethod == null) {
      this.paymentMethodDisable = true;
    }
    else {
      this.paymentMethodDisable = false;
    }
    this.filterPaymentCodeForSchemePayments();

    this.bindrequiredLinkedDocumentType(this.requiredLinkedDocumentTypeLookup, this.paymentForm.requiredLinkedDocumentTypeId);
  }

  cancelPaymentDetail(indexItem) {
    let cancleCheque: boolean = false;
    cancleCheque = confirm('Do you wish to cancel this cheque. ');
    if (cancleCheque) {
      let approvedCheque = this.paymentDetailService.formData.paymentDetail[indexItem];
      approvedCheque.isCancelCheque = true;
      approvedCheque.isRecordUpdated = true;
      let approvedChequeCopy = Object.assign({}, approvedCheque);
      approvedChequeCopy.id = null;
      approvedChequeCopy.isCancelCheque = true;
      approvedChequeCopy.parentPaymentDetailId = approvedCheque.id;
      approvedChequeCopy.paymentOrRecovery = 974;
      approvedChequeCopy.paymentOrRecoveryText = this.paymentRecoverykeys.find(a => a.id == approvedChequeCopy.paymentOrRecovery).text;
      approvedChequeCopy.paymentType = 983; // Recovery
      approvedChequeCopy.paymentTypeText = 'Cancelled Cheque'
      approvedChequeCopy.paymentStatus = 991;
      approvedChequeCopy.paymentStatusText = this.paymentStatuskeys.find(a => a.id == approvedChequeCopy.paymentStatus).text;
      approvedChequeCopy.chequeNumber = 'x' + approvedCheque.chequeNumber;
      approvedChequeCopy.approvedDate = null;
      approvedChequeCopy.addresseeName = '';
      approvedChequeCopy.addressLine1 = '';
      approvedChequeCopy.addressLine2 = '';
      approvedChequeCopy.town = '';
      approvedChequeCopy.country = '';
      approvedChequeCopy.postcode = '';
      this.paymentDetailService.formData.paymentDetail.push(approvedChequeCopy);
    }
  }
  viewPaymentDetail(indexItem) {
    this.showPaymentAddPanelFlag = true;
    this.paymentForm = Object.assign({}, this.paymentDetailService.formData.paymentDetail[indexItem]);
    this.itemIndex = indexItem;
    this.paymentRecoveryChange();
    if (this.paymentForm.paymentMethod == null) {
      this.paymentMethodDisable = true;
    }
    else {
      this.paymentMethodDisable = false;
    }
  }
  deletePaymentDetail(indexItem) {
    if (confirm("Are you sure you want to delete this item?")) {

      let deletedPayment = this.paymentDetailService.formData.paymentDetail[indexItem];
      if (deletedPayment.id > 0) {
        deletedPayment.isRecordDeleted = true;
        this.deletedPaymentDetails.push(deletedPayment);
      }

      this.paymentDetailService.formData.paymentDetail = this.paymentDetailService.formData.paymentDetail.filter(function (place, index) {
        return index != indexItem
      });

      this.itemIndex = null;
      this.refreshGrid();
      this.cancelButton();
    }
  }

  assignTextforField() {
    this.paymentForm.paymentOrRecoveryText = this.paymentRecoverykeys.find(a => a.id == this.paymentForm.paymentOrRecovery).text;
    this.paymentForm.paymentTypeText = this.paymentTypekeys.find(a => a.id == this.paymentForm.paymentType).text;
    this.paymentForm.paymentCodeText = this.paymentCodekeys.find(a => a.id == this.paymentForm.paymentCode).text;
    if (this.paymentForm.paymentMethod != null) {
      this.paymentForm.paymentMethodText = this.paymentMethodkeys.find(a => a.id == this.paymentForm.paymentMethod).text;
    }
    else {
      this.paymentForm.paymentMethodText = '';
    }
    if (this.paymentForm.payeeType != null) {
      this.paymentForm.payeeTypeText = this.payeeCodekeys.find(a => a.id == this.paymentForm.payeeType).text;
    }
    if (this.paymentForm.paymentStatus == null) {
      this.paymentForm.paymentStatus = 991;
    }
    else if (this.paymentForm.paymentStatus == 992) {
      this.paymentForm.paymentStatus = 991;
    }
    this.paymentForm.paymentStatusText = this.paymentStatuskeys.find(a => a.id == this.paymentForm.paymentStatus).text;
  }

  validation() {
    this.isFormSubmitted = true;
    if (this.paymentDetailService.formData.paymentDetail.length == 0 && this.deletedPaymentDetails.length == 0) {
      this.notificationService.printWarningMessage('Please provide at least one record in payment breakdown');
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }
    return true;
  }
  onSubmit() {

    if (this.validation()) {
      if (this.deletedPaymentDetails.length > 0) {
        this.deletedPaymentDetails.forEach((deletedItem) => {
          this.paymentDetailService.formData.paymentDetail.push(deletedItem);
        });
      }

      this.checkDocumentsLinkedWithPaymentOrNot(this.paymentDetailService.formData.paymentDetail);

      this.paymentDetailService.saveOrUpdatePayment().subscribe(response => {
        this.notificationService.printSuccessMessage("Saved successfully");
        this.paymentDetailService.formData = response;
        const paymentDetailFetchedData = JSON.parse(JSON.stringify(this.paymentDetailService.formData.paymentDetail));
        this.originalPaymentData = { ...paymentDetailFetchedData };
        this.refreshGrid();
        this.calculateSummary();
        this.calculateTotalMMIPaid();

        this.clearFields();
        this.itemIndex = null;
        this.deletedPaymentDetails = [];
        this.isFormSubmitted = true;
        this.formValidityFlagLoaded.next();
        this.paymentDetailForm.form.markAsUntouched();
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      )
    }
  }
  refreshGrid() {
    if (this.paymentDetailTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }
  refresh(): void {
    if (confirm('Are you sure want to reset this page?')) {
      window.location.reload();
    }
  }

  //get_categories(number) {
  //  return this.http.post(url, body, { headers: headers, withCredentials: true })
  //    .map(response => response.json());
  //}

  getEFile() {
    if (this.paymentDetailService.formData.eFileURL != '') {
      this.goToLink(this.paymentDetailService.formData.eFileURL);
    }
    else {
      if (confirm("Claim folder does not exist for this claim. Do you want to create.")) {
        this.createClaimFolder();
      }
    }
  }

  createClaimFolder() {

    this.claimDetailService.createClaimFolder(this.claimId).subscribe(res => {
      if (res != null) {
        this.paymentDetailService.formData.eFileURL = res.filepath;
        if (this.paymentDetailService.formData.eFileURL != '') {
          this.goToLink(this.paymentDetailService.formData.eFileURL);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  checkPolicyDateOutOfRange(claimid) {
    this.apportionmentService.getApportionment(claimid)
      .subscribe(response => {
        if (response != null) {
          if (response.apportionmentPolicies != null) {
            this.updatePolicyDatesOutRangeFlag(response.apportionmentPolicies);
          }
        }
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        });
  }

  updatePolicyDatesOutRangeFlag(apportionmentPoliciesData) {
    if (apportionmentPoliciesData.filter(i => i.isPolicyDateOutRangeFlag == true).length > 0) {
      this.isPolicyDateFlag = true;
    }
    else {
      this.isPolicyDateFlag = false;
    }
  }

  getPaymentDetailInfo(indexItem, content) {
    let approvedPayment = this.paymentDetailService.formData.paymentDetail[indexItem];
    let claimId = approvedPayment.claimId;
    let paymentId = approvedPayment.id;

    if (claimId > 0 && paymentId > 0) {
      this.paymentDetailService.getPaymentDetailInfoByPaymentId(claimId, paymentId).subscribe(response => {
        this.verifiedPaymentDetail = response;
        if (response != null) {
          this.modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
        }
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      )
    }
  }

  getPaymentLinkedDocumentsInfo(indexItem, content) {
    let payment = this.paymentDetailService.formData.paymentDetail[indexItem];
    let claimId = payment.claimId;
    let paymentId = payment.id;

    this.selectedPaymentLinkedDocuments = [];
    if (claimId > 0 && paymentId > 0) {
      this.selectedPaymentLinkedDocuments = payment.paymentLinkedDocuments;
      this.modalRef = this.modalService.open(content, { size: 'md', backdrop: 'static' });
    }

  }

  openSharePointFolder(formValues, index) {
    console.log('clicked openSharePointFolder');
    console.log(formValues);
    this.vaildPayment = true;
    let isFalse = this.isPaymentOrRecoverySelected(formValues.paymentOrRecovery);
    if (!isFalse) return;
    let folderName = "";
    let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    if (formValues.paymentType == 983) {
      folderName = 'Recoveries';
    }
    else {
      if (formValues.paymentOrRecovery == 973) {
        if (formValues.paymentCode == 967 || formValues.paymentCode == 968 || formValues.paymentCode == 969 || formValues.paymentCode == 970 || formValues.paymentCode == 971 || formValues.paymentCode == 972) {
          folderName = 'Payments/Defence Costs';
        }
        else {
          folderName = 'Payments';
        }
      }
      if (formValues.paymentOrRecovery == 974) {
        folderName = 'Recoveries';
      }
    }
    let paymentId = formValues.id;
    if (paymentId == null || paymentId == 0) {
      //if (confirm("Payment doesn't saved, do you want to save it?")) {
      this.savePaymentDetailsBeforeOpenSharepointFolder(claimId, folderName, index)
      //}
    }
    else {
      this.getLinkDocumentFolderUrl(claimId, folderName, paymentId);
    }
  }

  //saveLinkedDocuments() {
  //  console.log('clicked saveLinkedDocuments' + this.paymentForm.linkPaymentDoctuments);
  //  this.getAndSetPyamentLinkedDocuments(this.paymentForm.linkPaymentDoctuments);
  //  console.log(this.paymentForm.paymentLinkedDocuments);
  //  this.modalService.dismissAll('close');
  //}

  removeLinkedDocuments(_index) {
    let isConfirmed = confirm('Are you sure to remove the linked document?');
    if (isConfirmed) {
      this.paymentForm.paymentLinkedDocuments = this.paymentForm.paymentLinkedDocuments.filter(function (value, index, arr) { return index != _index; });
    }
  }

  getAndSetPyamentLinkedDocuments(linkedValues) {
    let linkedUrls = linkedValues.split(';');
    for (let i = 0; i < linkedUrls.length; i++) {
      if (linkedUrls[i] != '' && linkedUrls[i] != null) {
        let documentName = this.extractLinkedDocumentName(linkedUrls[i], i);
        this.paymentForm.paymentLinkedDocuments.push({ id: 0, paymentId: 0, linkedDocumentUrl: linkedUrls[i], linkedDocumentName: documentName });
      }
    }
  }

  isPaymentOrRecoverySelected(paymentOrRecovery) {
    this.isPaymentOrRecovery = true;
    if (paymentOrRecovery == null || paymentOrRecovery == 0)
      this.isPaymentOrRecovery = false;
    return this.isPaymentOrRecovery;
  }

  extractLinkedDocumentName(url, index) {
    let urlArr = url.split('/');
    urlArr = urlArr.filter(function (item) { return item });
    let count = urlArr.length;
    if (count > 0) {
      return urlArr[count - 1].split('.').length == 2 ? urlArr[count - 1].split('.')[0] : urlArr[count - 1];
    }
    else {
      return 'Document' + index;
    }
  }

  getLinkDocumentFolderUrl(claimId, folderName, paymentId) {
    this.paymentDetailService.getLinkDocumentFolderUrl(claimId, folderName).subscribe(res => {
      if (res != null) {
        //this.paymentForm.sharepointFolderUrl = res.filepath;
        //console.log(this.paymentForm.sharepointFolderUrl);
        //let url = this.paymentForm.sharepointFolderUrl;
        let url = res.filepath;
        if (url != '') {
          let reformatUrl = this.reformatUrlWithQueryString(url, paymentId);
          if (reformatUrl)
            this.goToLink(reformatUrl);
          else
            this.goToLink(url);
        }
        else {
          //if (confirm("Claim folder does not exist for this claim. Do you want to create.")) {
          this.createClaimFolderByClaimID(claimId, folderName, paymentId);
          //}
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  createClaimFolderByClaimID(claimId, folderName, paymentId) {
    this.claimDetailService.createClaimFolder(this.claimId).subscribe(res => {
      if (res != null) {
        if (res.filepath != '') {
          this.getLinkDocumentFolderUrl(claimId, folderName, paymentId);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  savePaymentDetailsBeforeOpenSharepointFolder(claimId, folderName, index) {
    if (this.validation()) {
      this.paymentDetailService.saveOrUpdatePayment().subscribe(response => {
        this.paymentDetailService.formData = response;
        if (this.paymentDetailService.formData.paymentDetail != null) {
          this.refreshGrid();
          this.calculateSummary();
          this.calculateTotalMMIPaid();
          let paymentId = this.paymentDetailService.formData.paymentDetail[index].id;
          this.getLinkDocumentFolderUrl(claimId, folderName, paymentId);
        }
        this.clearFields();
        this.itemIndex = null;
        this.paymentDetailForm.form.markAsUntouched();
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      )
    }
  }

  reformatUrlWithQueryString(url, paymentId) {
    let pattern = new RegExp(/\?.+=.*/g);
    let isQueryStringFound = pattern.test(url);
    let resultedUrl = "";
    if (isQueryStringFound) {
      resultedUrl = url + "&paymentId=" + paymentId;
    }
    else {
      resultedUrl = url + "?paymentId=" + paymentId;
    }
    return resultedUrl;
  }

  checkDocumentsLinkedWithPaymentOrNot(paymentDetails) {
    let paymentsWithoudDocuments =
      paymentDetails.filter(x =>
        x.paymentLinkedDocuments.length == 0
        && x.id == null
        && x.paymentType != 983
        && x.paymentStatus != 993
        && x.paymentStatus != 994
        && x.paymentStatus != 995
      );
    if (paymentsWithoudDocuments.length > 0) {
      this.notificationService.printWarningMessage('You have created payment/recovery/adjustment or excess entry without linking a supporting document. Please link a supporting document using the link option in the “Action” column.');
    }
  }
  bindHandlingOrganisation() {
    this.claimDetailService.getHandlingOrganization().subscribe(
      result => {
        this.handlingOrganisation = result;
      },
      error => {
        console.log('this is error:' + error);
      });
  }
  filterPaymentCodeForSchemePayments() {
    if (this.paymentForm.paymentType == 976 && this.paymentDetailService.formData.policyholderStatus.toUpperCase() == 'SOVR' && this.paymentDetailService.formData.fscsProtected == 0.0) {
      const paymentCode = [966, 967, 968, 969, 970, 971, 972];
      this.filterPaymentCode(paymentCode);
    }
  }

  changeHandlingOrganization() {
    let id = this.paymentForm.organisationID;
    this.handlingOrganisationService.getHandlingOrganisationById(id).subscribe(res => {
      if (res != null) {
        //BACS
        if (this.paymentForm.paymentMethod == 989) {
          //Handling Organisation
          if (this.paymentForm.payeeType == 986) {
            this.paymentForm.payeeName = res.handlingOrganisationName;
            if (this.paymentForm.paymentOrRecovery == 973) {
              this.paymentForm.sortCode = res.sortCode;
              this.paymentForm.accountNumber = res.accountNumber;
            }
          }
        }
        //Cheque
        if (this.paymentForm.paymentMethod == 990) {
          //Handling Organisation
          if (this.paymentForm.payeeType == 986 && this.paymentForm.paymentOrRecovery == 973) {
            this.paymentForm.addressLine1 = res.firstline;
            this.paymentForm.addressLine2 = res.secondLine;
            this.paymentForm.town = res.town;
            this.paymentForm.country = res.country;
            this.paymentForm.postcode = res.postCode;
          }
        }

        //Handling Organisation
        if (this.paymentForm.payeeType == 986) {
          this.paymentForm.payeeName = res.handlingOrganisationName;
          this.paymentForm.bacsRemittanceEmail = res.bacsEmail;

        }
      }
    },
      error => {
        this.notificationService.printErrorMessage('Error in fetching Handling Organisation Details');
      }
    );
  }
  setBACSRemittanceEmail() {
    if (this.paymentForm.bacsRemittanceEmail == null || this.paymentForm.bacsRemittanceEmail == undefined || this.paymentForm.bacsRemittanceEmail == '') {
      let id = this.paymentForm.organisationID;
      this.handlingOrganisationService.getHandlingOrganisationById(id).subscribe(res => {
        if (res != null) {
          //Handling Organisation
          this.paymentForm.payeeName = res.handlingOrganisationName;
          //if (this.paymentDetailService.formData.bacsRemittanceEmail == null || this.paymentDetailService.formData.bacsRemittanceEmail == undefined) {
          this.paymentDetailService.formData.bacsRemittanceEmail = res.bacsEmail;
          this.paymentForm.bacsRemittanceEmail = res.bacsEmail;
          //}
        }
      },
        error => {
          this.notificationService.printErrorMessage('Error in fetching Handling Organisation Details');
        }
      );

    }
  }

  navigateToWorkflowComponent() {
    this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/add-workflow-item']);
  }

  changeDwp() {
    let id = this.paymentForm.dwpID;
    this.manageDwpService.getDwpById(id).subscribe(res => {
      if (res != null) {
        //BACS
        if (this.paymentForm.paymentMethod == 989) {
          //DWP
          if (this.paymentForm.payeeType == 1486) {
            this.paymentForm.payeeName = res.dwpName;
            if (this.paymentForm.paymentOrRecovery == 973) {
              this.paymentForm.sortCode = res.sortCode;
              this.paymentForm.accountNumber = res.accountNumber;
            }
          }
        }
        //Cheque
        if (this.paymentForm.paymentMethod == 990) {
          //DWP
          if (this.paymentForm.payeeType == 1486 && this.paymentForm.paymentOrRecovery == 973) {
            this.paymentForm.addressLine1 = res.firstLine;
            this.paymentForm.addressLine2 = res.secondLine;
            this.paymentForm.town = res.town;
            this.paymentForm.country = res.country;
            this.paymentForm.postcode = res.postCode;
          }
        }

        //DWP
        if (this.paymentForm.payeeType == 1486) {
          this.paymentForm.payeeName = res.dwpName;
          this.paymentForm.bacsRemittanceEmail = res.bacsEmail;

        }
      }
    },
      error => {
        this.notificationService.printErrorMessage('Error in fetching DWP Details');
      }
    );
  }

  bindDwp() {
    this.manageDwpService.getDwp().subscribe(
      result => {
        this.dwpList = result;
      },
      error => {
        console.log('this is error:' + error);
      });
  }

  addDwpInPayeeType() {
    this.payeeCodekeys = this.lookupService.getLookupsByTypeId(41);
  }

  removeDwpInPayeeType() {
    this.payeeCodekeys = this.lookupService.getLookupsByTypeId(41).filter(({ id }) => id != 1486);
  }

  addRemoveDwpInPayeeType() {
    if (this.paymentForm.paymentCode == 966) {
      this.addDwpInPayeeType();
    }
    else {
      this.removeDwpInPayeeType();
    }
  }

  ShowValidationMessageOfUniqueExcessArrangements() {
    let a = this.uniqueExcessArrangement;
    let b = this.objUniqueExcess;

    let firstFilter = a.filter(x => {
      return (
        x.PolicyNumber.trim().toUpperCase() === b.PolicyNumber.trim().toUpperCase() &&
        x.Policyholder.trim().toUpperCase() === b.Policyholder.trim().toUpperCase() &&
        x.IsExposurePeriod === b.IsExposurePeriod)
    });

    let secondFilter = firstFilter.filter(x => {
      return x.ClaimType !== "" ? x.ClaimType.trim().toUpperCase() === b.ClaimType.trim().toUpperCase() : true
    });

    let thirdFilter = secondFilter.filter(x => {
      return x.Location.length > 0 ? b.Location.indexOf(x.Location.trim().toUpperCase()) !== -1 : true
    });

    if (thirdFilter.length > 0)
      this.notificationService.printWarningMessage(thirdFilter[0].Message);
  }

  onRequiredLinkedDocumentSelect(item: any) {
    this.paymentForm.requiredLinkedDocumentTypeId = _.map(this.paymentForm.requiredLinkedDocumentTypeIds, function (o: any) { return o.id });
  }

  onRequiredLinkedDocumentDeSelect(item: any) {
    this.paymentForm.requiredLinkedDocumentTypeId = _.map(this.paymentForm.requiredLinkedDocumentTypeIds, function (o: any) { return o.id });
  }

  bindrequiredLinkedDocumentType(requiredLinkedDocumentTypeList, singleRequiredLinkedDocumentType) {
    let requiredLinkedDocumentType = [];
    this.paymentForm.requiredLinkedDocumentTypeIds = this.paymentForm.requiredLinkedDocumentTypeIds = [];
    if (requiredLinkedDocumentTypeList.length > 0) {
      for (var i = 0; i < requiredLinkedDocumentTypeList.length; i++) {
        let requiredLinkedDocumentTypeValue = this.requiredLinkedDocumentTypeLookup.filter(x => x.id == singleRequiredLinkedDocumentType[i]);
        if (requiredLinkedDocumentTypeValue.length > 0) {
          requiredLinkedDocumentType.push({ id: requiredLinkedDocumentTypeValue[0].id, text: requiredLinkedDocumentTypeValue[0].text });
        }
      }
      this.paymentForm.requiredLinkedDocumentTypeIds = requiredLinkedDocumentType;
    }
    else {
      if (singleRequiredLinkedDocumentType != null) {
        let requiredLinkedDocumentTypeValue = this.requiredLinkedDocumentTypeLookup.filter(x => x.id == singleRequiredLinkedDocumentType);
        if (requiredLinkedDocumentTypeValue.length > 0) {
          requiredLinkedDocumentType.push({ id: requiredLinkedDocumentTypeValue[0].id, text: requiredLinkedDocumentTypeValue[0].text });
        }
        this.paymentForm.requiredLinkedDocumentTypeIds = requiredLinkedDocumentType;
      }
    }
  }

  isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str))
      return false;
    var d = new Date(str);
    return d.toISOString() === str;
  }

}

import { Component, OnInit, ViewChild, OnDestroy, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { IPager } from '../../../shared/models/pager.model';
import { DataTableDirective } from 'angular-datatables';
import { SearchParticipantVM } from './search-participant.model';
import { LookUps } from '../../../shared/models/lookups-enum.model';
import { SearchParticipantService } from './search-participant.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { SecurityService } from '../../../shared/services/security.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../../../shared/services/notification.service';
import { StorageService } from '../../../shared/services/storage.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-search-participant',
  templateUrl: './search-participant.component.html',
  styleUrls: ['./search-participant.component.css']
})
export class SearchParticipantComponent implements OnInit, OnDestroy {
  participantTypes = LookUps.ParticipantType;
  isHidden: boolean = true;
  isParticipantInvalid: boolean = false;
  //@ViewChild(DataTableDirective, { static: false })
  //private datatableElement: DataTableDirective;

  @ViewChildren(DataTableDirective)
  private datatableElements: QueryList<DataTableDirective>;

  @ViewChild('otherTable') otherTable: ElementRef;
  @ViewChild('policyholderTable') policyholderTable: ElementRef;

  isOtherGridLoaded: boolean = false;
  isPolicyholderGridLoaded: boolean = false;

  public searchParticipantVM: SearchParticipantVM = new SearchParticipantVM();
  public searchParticipantResult: SearchParticipantVM[];
  public isInitialised: boolean = false;
  public dtOptions: any = {};
  public paginationInfo: IPager;
  public authenticated: boolean = false;
  public authSubscription: Subscription;
  public errorReceived: boolean;
  public dtTrigger: Subject<any>;
  public isMMIUser: boolean = false;
  public isParticipantPolicyholder: boolean = false;
  public dtPolicyholderTrigger: Subject<any>;
  public dtPolicyholderOptions: any = {};
  public isPolicyholderInitialised: boolean = false;
  public isDisplayPolicyholderID: boolean = false;
  @ViewChild('btnSearchParticipant') btnSearchParticipant: ElementRef;

  displayedColumns: any[] = [
    { title: 'Participant Name', data: 'participantName' },
    { title: 'Participant Type', data: 'participantType' },
    { title: 'Policyholder ID', data: 'policyholderID' },
    { title: 'Policyholder Status', data: 'policyholderStatus' },
    { title: 'Policyholder Name', data: 'policyholderName' },
    { title: 'Aggregated Policyholder ID', data: 'aggregatedPolicyholderID' },
    { title: 'Aggregated Policyholder Status', data: 'aggregatedPolicyholderStatus' },
    { title: 'Aggregated Policyholder Name', data: 'aggregatedPolicyholderName' },
    { title: 'Action', data: null, orderable: false }];

  displayedPolicyholderColumns: any[] = [
    { title: 'Policyholder ID', data: 'policyholderID' },
    { title: 'Policyholder Status', data: 'policyholderStatus' },
    { title: 'Policyholder Name', data: 'policyholderName' },
    { title: 'Aggregated Policyholder ID', data: 'aggregatedPolicyholderID' },
    { title: 'Aggregated Policyholder Status', data: 'aggregatedPolicyholderStatus' },
    { title: 'Aggregated Policyholder Name', data: 'aggregatedPolicyholderName' },
    { title: 'Participant Name', data: 'participantName' },
    { title: 'Participant Type', data: 'participantType' },
    { title: 'Action', data: null, orderable: false }
  ];

  constructor(private service: SearchParticipantService,
    private configurationService: ConfigurationService,
    private securityService: SecurityService,
    private storageService: StorageService,
    private router: Router, private notificationService: NotificationService) {
    this.dtTrigger = new Subject();
    this.dtPolicyholderTrigger = new Subject();
  }

  ngOnInit() {
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = (this.securityService.getUserData().organisationId == 1);
      if (!this.isMMIUser) {
        this.participantTypes = this.participantTypes.filter(({ Value }) => Value != 3);
      }
    }

    this.initialiseDataTable();
    this.initialisePolicyholderDataTable();
    var searchCriteria = this.storageService.retrieve("participantSearchCriteria");
    if (searchCriteria) {
      var that = this;
      this.searchParticipantVM = searchCriteria;
      this.displayPolicyholderInput(this.searchParticipantVM.participantTypeId);
      this.displayPolicyholderGrid();
      if (this.isParticipantPolicyholder) {

        this.dtPolicyholderOptions.displayStart = this.searchParticipantVM.pageIndex * this.searchParticipantVM.pageSize;
        this.dtPolicyholderOptions.order = [[_.findIndex(this.displayedPolicyholderColumns, function (o) { return o.data == that.searchParticipantVM.sortBy; }), , this.searchParticipantVM.sortOrder]]
        this.dtPolicyholderOptions.pageLength = this.searchParticipantVM.pageSize;
      }
      else {
        this.dtOptions.displayStart = this.searchParticipantVM.pageIndex * this.searchParticipantVM.pageSize;
        this.dtOptions.order = [[_.findIndex(this.displayedColumns, function (o) { return o.data == that.searchParticipantVM.sortBy; }), , this.searchParticipantVM.sortOrder]]
        this.dtOptions.pageLength = this.searchParticipantVM.pageSize;
      }
      setTimeout(r => { this.searchParticipant() }, 0);
    } else {
      if (this.isParticipantPolicyholder)
        this.isPolicyholderInitialised = true;
      else
        this.isInitialised = true;
    }
  }

  refreshOtherGrid() {
    if (this.otherTable) {
      if (this.datatableElements.toArray()[0] && this.datatableElements.toArray()[0].dtInstance) {
        this.datatableElements.toArray()[0].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isOtherGridLoaded = true;
    }
  }

  refreshPolicyholderGrid() {
    if (this.policyholderTable) {
      if (this.datatableElements.toArray()[1] && this.datatableElements.toArray()[1].dtInstance) {
        this.datatableElements.toArray()[1].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtPolicyholderTrigger.next();
        });
      }
      else {
        this.dtPolicyholderTrigger.next();
      }
      this.isPolicyholderGridLoaded = true;
    }
  }

  onParticipantTypeChange() {
    this.displayPolicyholderInput(this.searchParticipantVM.participantTypeId);
  }

  displayPolicyholderInput(participantTypeId) {
    if (participantTypeId === 2) {
      this.isDisplayPolicyholderID = true;
    }
    else {
      this.isDisplayPolicyholderID = false;
    }
    this.isHidden = true;
    this.isParticipantPolicyholder = false;
  }

  displayPolicyholderGrid() {
    if (this.searchParticipantVM.participantTypeId === 2) {
      this.isParticipantPolicyholder = true;
    }
    else {
      this.isParticipantPolicyholder = false;
    }

  }

  resetform() {
    this.searchParticipantVM = new SearchParticipantVM();
    this.isHidden = true;
    this.isParticipantPolicyholder = false;
    this.isDisplayPolicyholderID = false;
  }

  initialiseDataTable(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedColumns,
      columnDefs: [
        {
          render: function (data, type, row) {
            return '<span class="btnsearch"><button class="btn btn-primary btn-sm"><i class="fa fa-eye"</i></button></span>';
          },
          targets: 8
        },
        {
          targets: 2,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 3,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 4,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 5,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 6,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 7,
          visible: false,
          searchable: false
        }
      ],
      serverSide: true,
      processing: true,
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure th buttons
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          title: function () { return $("#participantTypeId option:selected").html() },
          tag: '',
          filename: function () { return $("#participantTypeId option:selected").html() + Math.random(); },
          action: this.newExportAction,
          messageTop: "Search Participant Result",
          exportOptions: {
            columns: [0, 1]
          }
        }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('button', row).unbind('click');
        $('button', row).bind('click', () => {
          self.viewButtonClickHandler(data);
        });
        return row;
      },
      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        if (this.isInitialised) {
          this.searchParticipantVM.sortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
          this.searchParticipantVM.sortOrder = dataTablesParameters.order[0].dir;
          //this.searchParticipantVM.pageIndex = dataTablesParameters.start;
          this.searchParticipantVM.pageIndex = dataTablesParameters.start > 0 ? parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) : 0;
          this.searchParticipantVM.pageSize = dataTablesParameters.length;
        } else {
          this.isInitialised = true;
        }
        this.getPartcipants(this.searchParticipantVM).then(resp => {
          callback({
            page: that.paginationInfo.actualPage,
            pages: that.paginationInfo.totalPages,
            recordsTotal: that.paginationInfo.totalItems,
            recordsFiltered: that.paginationInfo.totalItems,
            data: that.searchParticipantResult
          });
        });
      }
    };

  }

  initialisePolicyholderDataTable(): void {
    const that = this;
    this.dtPolicyholderOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedPolicyholderColumns,
      columnDefs: [
        {
          render: function (data, type, row) {
            return '<span class="btnsearch"><button class="btn btn-primary btn-sm"><i class="fa fa-eye"</i></button></span>';
          },
          targets: 8
        },
        {
          targets: 6,
          visible: false,
          searchable: false
        },
        {
          targets: 7,
          visible: false,
          searchable: false
        }
      ],
      serverSide: true,
      processing: true,
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          title: function () { return $("#participantTypeId option:selected").html() },
          tag: '',
          filename: function () { return $("#participantTypeId option:selected").html() + Math.random(); },
          action: this.newExportAction,
          messageTop: "Search Participant Result",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5]
          }
        }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('button', row).unbind('click');
        $('button', row).bind('click', () => {
          self.viewButtonClickHandler(data);
        });
        return row;
      },
      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        if (this.isPolicyholderInitialised) {
          this.searchParticipantVM.sortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
          this.searchParticipantVM.sortOrder = dataTablesParameters.order[0].dir;
          //this.searchParticipantVM.pageIndex = dataTablesParameters.start;
          this.searchParticipantVM.pageIndex = dataTablesParameters.start > 0 ? parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) : 0;
          this.searchParticipantVM.pageSize = dataTablesParameters.length;
        } else {
          this.isPolicyholderInitialised = true;
        }
        this.getPartcipants(this.searchParticipantVM).then(resp => {
          callback({
            page: that.paginationInfo.actualPage,
            pages: that.paginationInfo.totalPages,
            recordsTotal: that.paginationInfo.totalItems,
            recordsFiltered: that.paginationInfo.totalItems,
            data: that.searchParticipantResult
          });
        });
      }
    };

  }

  viewButtonClickHandler(data: any): void {
    let participantTypeId: number = data.participantTypeId;
    switch (participantTypeId) {
      case 1:
        //this.router.navigate(['/claim-handler/organigationid=' + data.participantParentId + '&claimhanderid=' + data.participantId]);
        this.router.navigate(['/claim-handler'], { queryParams: { organisationid: data.participantParentId, claimhanderid: data.participantId, isSearchFlag: true } });
        break;
      case 2:
        //this.router.navigate(['/claim-handler/organigationid=' + data.participantParentId + '&claimhanderid=' + data.participantId]);
        this.router.navigate(['/creditor-holder/edit/' + data.participantId], { queryParams: { isSearchFlag: true } });
        break;
      case 3:
        this.router.navigate(['/handling-organisation/edit/' + data.participantId], { queryParams: { isSearchFlag: true } });
        break;
      case 4:
        this.router.navigate(['/reinsurer/edit/' + data.participantId], { queryParams: { isSearchFlag: true } });
        break;
      case 5:
        this.router.navigate(['/dwp/edit/' + data.participantId], { queryParams: { isSearchFlag: true } });
        break;
    }
    //alert("Participant Name: " + data.participantName + " Participant Type:" + data.participantType);
  }

  getExportFileName() {
    return "a1.exls";
  }

  searchParticipant(): void {
    if (this.searchParticipantVM.participantTypeId == 0) {
      this.notificationService.printWarningMessage("Please select Participant Type.");
      this.isHidden = true;
      setTimeout(() => this.btnSearchParticipant.nativeElement.focus(), 0);
    } else {
      this.isParticipantInvalid = false;
      this.isHidden = false;
      this.displayPolicyholderGrid();
      if (this.isParticipantPolicyholder) {
        this.refreshPolicyholderGrid();
      }
      else {
        this.refreshOtherGrid();
      }
      setTimeout(() => this.btnSearchParticipant.nativeElement.focus(), 0);
    }
  }

  getPartcipants(filterCriteria: SearchParticipantVM): Promise<any> {
    this.errorReceived = false;
    this.storageService.store("participantSearchCriteria", filterCriteria);
    var promise = new Promise((resolve, reject) => {
      this.service.searchParticipants(filterCriteria)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(partcipants => {
          this.searchParticipantResult = partcipants.searchParticipantResultItems;
          this.paginationInfo = partcipants.paginationInfo;
          resolve();
        });
    });
    return promise;
  }

  private handleError(error: any) {
    this.errorReceived = true;
    return Observable.throw(error);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtPolicyholderTrigger.unsubscribe();
  }


  public oldExportAction(self, e, dt, button, config) {
    if (button[0].className.indexOf('buttons-excel') >= 0) {
      //this.datatableElement.dtInstance.
      var buttons: any = ($.fn.dataTable.ext as any).buttons;
      if (buttons.excelHtml5.available(dt, config)) {
        buttons.excelHtml5.action.call(self, e, dt, button, config);
      }
      else {
        buttons.excelFlash.action.call(self, e, dt, button, config);
      }
    } else if (button[0].className.indexOf('buttons-print') >= 0) {
      buttons.print.action(e, dt, button, config);
    }
  }

  public newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    console.log("buttons");
    //console.log($.fn.dataTable.ext.buttons);
    dt.one('preXhr', function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 5000;

      dt.one('preDraw', function (e, settings) {
        // Call the original action function
        oldExportAction(self, e, dt, button, config);

        dt.one('preXhr', function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    // Requery the server with the new one-time export settings
    dt.ajax.reload();

    function oldExportAction(self, e, dt, button, config) {
      if (button[0].className.indexOf('buttons-excel') >= 0) {
        //this.datatableElement.dtInstance.
        var buttons: any = ($.fn.dataTable.ext as any).buttons;
        if (buttons.excelHtml5.available(dt, config)) {
          buttons.excelHtml5.action.call(self, e, dt, button, config);
        }
        else {
          buttons.excelFlash.action.call(self, e, dt, button, config);
        }
      } else if (button[0].className.indexOf('buttons-print') >= 0) {
        buttons.print.action(e, dt, button, config);
      }
    }
  };

  enterKeydownOnForm(event): void {
    event.preventDefault();
    this.searchParticipant();
  };

}

import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, AfterContentInit, AfterViewChecked, ElementRef, ViewChildren, Inject } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { IPager } from '../../../shared/models/pager.model';
import { Subscription, Subject, Observable } from 'rxjs';

import { CancelChecquesService } from './cancel-checques.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from 'moment';
import { catchError } from 'rxjs/operators';
import { IPaymentBordereaux } from '../../../shared/models/payment-bordereaux.model';
import * as _ from 'lodash';
import { PaymentBordereaux } from '../../../shared/models/search-payment-bordereaux.model';
import { enumPaymentStatus, enumPaymentMethod, enumPaymentRecovery, enumPaymentType, enumRecoveryType } from '../../../shared/models/common.enum';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { SecurityService } from '../../../shared/services/security.service';


@Component({
  selector: 'app-cancel-cheques',
  templateUrl: './cancel-cheques.component.html',
  styleUrls: ['./cancel-cheques.component.css']
})
export class CancelChequesComponent extends BaseComponentComponent implements OnInit, AfterViewChecked {

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  @ViewChild('cancelChecquesTable') cancelChecquesTable: ElementRef;

  public paginationInfo: IPager;
  public authenticated: boolean = false;
  public authSubscription: Subscription;
  public errorReceived: boolean;
  public dtTrigger: Subject<any>;
  isGridLoaded: boolean = false;
  public searchCancelChequeVM: PaymentBordereaux = new PaymentBordereaux();

  public searchCancelChequeResult: IPaymentBordereaux[];
  public dtOptions: any = {};
  modalRef: NgbModalRef;
  dropdownList = [];
  isSelectAllChecked: boolean;
  loggedInUserPaymentAuthority: number;
  loggedInUserId: number;
  dropdownSettings = {};

  displayeDataList = [];
  displayedColumns: any[] = [
    { isAlwaysVisible: true, title: '<input type="checkbox" id="selectAll"/>', data: 'isSelected', visible: true },
    { isAlwaysVisible: true, title: 'Payment ID', data: 'paymentID', visible: true },
    { isAlwaysVisible: true, title: 'Linked Documents', data: 'paymentLinkedDocuments', visible: true },
    //{ isAlwaysVisible: true, title: 'Payment/Recovery', data: 'paymentRecovery', visible: true },
    //{ isAlwaysVisible: false, title: 'Handling Organisation ID', data: 'handlingOrganisationId', visible: false },
    { isAlwaysVisible: true, title: 'MMI Claim Reference', data: 'claimNumber', visible: true },
    { isAlwaysVisible: true, title: 'Claimant Name', data: 'claimant', visible: true },
    { isAlwaysVisible: true, title: 'Policyholder Name', data: 'policyHolder', visible: true },
    { isAlwaysVisible: true, title: 'Claim Type', data: 'claimType', visible: true },
    { isAlwaysVisible: true, title: 'Policyholder ID', data: 'policyHolderID', visible: true },
    { isAlwaysVisible: true, title: 'Payment Amount', data: 'paymentAmount', visible: true },
    { isAlwaysVisible: true, title: 'Cheque No.', data: 'chequeNumber', visible: true },
    { isAlwaysVisible: true, title: 'Payment Code', data: 'paymentCode', visible: true },
    { isAlwaysVisible: true, title: 'Payee Name', data: 'payeeName', visible: true },
    { isAlwaysVisible: true, title: 'Payee Override', data: 'payeeNameOverWrittenValue', visible: false },
    { isAlwaysVisible: true, title: 'Payee ID', data: 'payee', visible: false },
    { isAlwaysVisible: true, title: 'Payee/Invoice Ref.', data: 'invoiceRef', visible: false },
    { isAlwaysVisible: true, title: 'Addressee Name', data: 'addresseeName', visible: false },
    { isAlwaysVisible: true, title: 'Address Line 1', data: 'addressLine1', visible: false },
    { isAlwaysVisible: true, title: 'Address Line 2', data: 'addressLine2', visible: false },
    { isAlwaysVisible: true, title: 'Town', data: 'town', visible: false },
    { isAlwaysVisible: true, title: 'Country', data: 'county', visible: false },
    { isAlwaysVisible: true, title: 'Post Code', data: 'postCode', visible: false },
    { isAlwaysVisible: true, title: 'Address Override', data: 'addresseeOverWrittenValue', visible: false },
    { isAlwaysVisible: true, title: 'FSCS % Protected', data: 'fscsProtected', visible: false, render: function (data) { return data == null ? "" : (((String(data).split('.').length === 1) && parseInt(data) > 0 && parseInt(data) < 100) ? (String(data).split('.')[0] + '.00') : ((String(data).split('.').length === 2) && (String(data).split('.')[1].length === 1)) ? (String(data).split('.')[0] + "." + String(data).split('.')[1] + "0") : data) + " " + "%" } },
    { isAlwaysVisible: true, title: 'Policyholder Status', data: 'policyHolderStatus', visible: false },
    { isAlwaysVisible: true, title: 'Handling Organisation', data: 'handlingOrganisationName', visible: false },
    //{ isAlwaysVisible: true, title: 'Payment Type', data: 'paymentType', visible: false },

    //{ isAlwaysVisible: true, title: 'Payee Type', data: 'payeeType', visible: true },

    // { isAlwaysVisible: true, title: 'Payment Method', data: 'paymentMethod', visible: false },

    //{ isAlwaysVisible: true, title: 'Sort Code', data: 'sortCode', visible: false },
    //{ isAlwaysVisible: true, title: 'Account Number', data: 'accountNumber', visible: false },
    //{ isAlwaysVisible: false, title: 'Payment Status', data: 'paymentStatus', visible: false },
    //{ isAlwaysVisible: true, title: 'Payment Status', data: 'paymentStatus', visible: false },
    { isAlwaysVisible: true, title: 'Requested by', data: 'requestedby', visible: false },
    { isAlwaysVisible: true, title: 'Requested date', data: 'requestedDate', type: 'date', visible: false, render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },

    //{ isAlwaysVisible: true, title: 'Approved (Stage 1)', data: 'approvedStage1', visible: false },
    { isAlwaysVisible: true, title: 'Approved By (Stage 1)', data: 'approvedByStage1', visible: false },
    { isAlwaysVisible: true, title: 'Approved Date (Stage 1)', data: 'approvedDateStage1', type: 'date', visible: false, render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
    //{ isAlwaysVisible: false, title: 'Approved', data: 'approved', visible: false },
    //{ isAlwaysVisible: false, title: 'Approved By', data: 'approvedById', visible: false },
    //{ isAlwaysVisible: false, title: 'Approved Date', data: 'approvedDate', type: 'date', visible: false, render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
    { isAlwaysVisible: false, title: "overrideFlag", data: "isOverWritten", visible: false },
    { isAlwaysVisible: false, title: "overridePayeeNameFlag", data: "isPayeeNameOverWritten", visible: false }

  ];

  selectedItems = [
    { title: 'Payment ID', data: 'paymentID', visible: true },
    { title: 'MMI Claim Reference', data: 'claimNumber', visible: true },
    { title: 'Claimant Name', data: 'claimant', visible: true },
    { title: 'Policyholder Name', data: 'policyHolder', visible: true },
    { title: 'Claim Type', data: 'claimType', visible: true },
    { title: 'Policyholder ID', data: 'policyHolderID', visible: true },
    { title: 'Payment Amount', data: 'paymentAmount' },
    { title: 'Cheque No.', data: 'chequeNumber', visible: false },
    { title: 'Account Number', data: 'accountNumber', visible: true },
    { title: 'Payment Code', data: 'paymentCode', visible: true },
    { title: 'Payee Name', data: 'payeeName', visible: true }
  ];
  constructor(public cancelChecquesService: CancelChecquesService, public notificationService: NotificationService,
    private modalService: NgbModal, securityService: SecurityService) {
    super(securityService, 1150);
    this.dtTrigger = new Subject();
  }


  ngOnInit() {
    if (this.isReadOnlyMode) {
      this.displayedColumns[0].visible = false;
    }
    this.loggedInUserPaymentAuthority = (this.securityService.getUserData().financeApprovalAuthority != null) ? this.securityService.getUserData().financeApprovalAuthority : 0;
    this.loggedInUserId = this.securityService.getUserData().id;
    this.initialiseDataTable();
    this.isSelectAllChecked = false;
    this.searchCancelChequeVM;
    this.refreshGrid();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'data',
      textField: 'title',
      enableCheckAll: true,
      itemsShowLimit: 2,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false,
      closeDropDownOnSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All'
    };

    this.columnsForMultiSelect().forEach(a => {
      if (a.data != "isSelected") {
        this.displayeDataList.push(a);
      }
    });
  }

  initialiseDataTable(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedColumns,

      // Declare the use of the extension in the dom parameter
      //dom: 'Blfrtip',
      scrollY: '50vh',
      scrollX: true,
      //scrollCollapse: true,
      "initComplete": function (settings, json) {
        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
      },

      //drawCallback: function (settings, json) {
      //  $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
      //},
      serverSide: true,
      processing: true,
      searching: false,
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          tag: '',
          action: this.newExportAction,
          messageTop: "Cancel Cheques Payment PaymentBordereaux Result",
          title: " Cancel Cheques Payment PaymentBordereaux",
          filename: function () { return "CancelChequesPaymentBordereaux" + Math.random(); },
          exportOptions: {
            columns: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28]
          }
        }
      ],
      select: {
        style: 'multi'
      },
      columnDefs: [{
        orderable: false,
        className: 'select-checkbox',
        targets: 0,
        render: function (data, type, full, meta: any) {
          
          var html = "";//'<input type="checkbox" class="select-checkbox">';
          if (full.approvedByIdStage1 != that.loggedInUserId && full.createdBy != that.loggedInUserId && full.updatedBy != that.loggedInUserId) {
            meta.settings.aoColumns[0].sClass = 'select-checkbox';
          }
          else {
            meta.settings.aoColumns[0].sClass = '';
          }
          return html;
        }
      },
      {
        targets: 2,
        render: function (data, type, full, meta: any) {
          return that.bindPaymentLinkedDocuments(data);
        }
        },
        {
          targets: 3,
          render: function (data, type, full, meta: any) {

            return that.bindMMiClaimRefrence(full);
          }
        },
      ],
      headerCallback: function (thead, data, start, end, display) {
        var selectAllCheckBox = $('#selectAll');
        selectAllCheckBox.click(function () { that.selectAll(this) });
      },
      rowCallback: function (nRow, aData, iDisplayIndex) {
        if (!Number.isNaN(Number($(this).text()))) {
          if (aData.paymentOrRecovery == 973) {
            $('td:eq(8)', nRow).each(function () {
              $(this).html("£" + Number($(this).text()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
            });
          }
          else {
            $('td:eq(8)', nRow).each(function () {
              $(this).html("(£" + Number($(this).text()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ")");
            });
          }
        }
        if (aData.isPayeeNameOverWritten || aData.isOverWritten) {
          $('td', nRow).each(function () {
            $(this).html('<b>' + $(this).html() + '</b>');
          });
        }
        return nRow;

      },
      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        this.searchCancelChequeVM.sortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
        this.searchCancelChequeVM.sortOrder = dataTablesParameters.order[0].dir;
        this.searchCancelChequeVM.pageIndex = dataTablesParameters.start > 0 ? parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) : 0;
        this.searchCancelChequeVM.pageSize = dataTablesParameters.length;
        this.searchCancelChequeVM.paymentStatus = enumPaymentStatus.ApprovedStage1.toString();
        this.searchCancelChequeVM.paymentMethod = enumPaymentMethod.Cheque.toString();
        this.searchCancelChequeVM.paymentRecovery = enumPaymentRecovery.Recovery.toString();
        this.searchCancelChequeVM.paymentType = enumRecoveryType.cancelledCheque.toString();
        this.getData(this.searchCancelChequeVM).then(resp => {
          callback({
            page: that.paginationInfo.actualPage,
            pages: that.paginationInfo.totalPages,
            recordsTotal: that.paginationInfo.totalItems,
            recordsFiltered: that.paginationInfo.totalItems,
            data: that.searchCancelChequeResult
          });
          if (!this.isReadOnlyMode) {
            that.selectGridRows();
          }
          var dataTable: any = this.datatableElement;
          dataTable.dt.on('user-select', (e, dt, type, cell, originalEvent, event) => {
            if (dt.rows(cell.index()["row"]).data()[0].approvedByIdStage1 == this.loggedInUserId || this.isReadOnlyMode || dt.rows(cell.index()["row"]).data()[0].updatedBy == that.loggedInUserId || dt.rows(cell.index()["row"]).data()[0].createdBy == that.loggedInUserId)
              return false;
          });
        });
      }
    };
  }

  getData(paymentBordereauxModel: PaymentBordereaux): Promise<any> {
    this.errorReceived = false;
    var claimsData: any;
    var promise = new Promise((resolve, reject) => {
      this.cancelChecquesService.searchCancelChequePayments(paymentBordereauxModel)

        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {

          this.searchCancelChequeResult = res.serachPaymentBordereauxResultItems;
          this.paginationInfo = res.paginationInfo;
          resolve();
        });
    });
    return promise;
  }

  private handleError(error: any) {
    this.errorReceived = true;
    return Observable.throw(error);
  }

  selectAll(data) {
    this.isSelectAllChecked = data.checked;
    this.selectGridRows();
  }

  selectGridRows() {
    var dataTable: any = this.datatableElement;
    var checked = this.isSelectAllChecked;
    let handlerId = this.loggedInUserId;
    dataTable.dt.rows().every(function (rowIdx, tableLoop, rowLoop) {
      var rowData = this.data();
      if (rowData.approvedByIdStage1 != handlerId && rowData.createdBy != handlerId && rowData.updatedBy != handlerId) {
        if (checked) {
          this.rows(rowIdx).select();
        }
        else {
          this.rows(rowIdx).deselect();
        }
      }

    });
  }


  columnsForMultiSelect() {
    let c = _.filter(this.displayedColumns, function (o) { return o.data != 'isSelected' && o.data != 'isOverWritten' && o.data != 'isPayeeNameOverWritten'; });
    return c;
  }

  //onApproveReject(isApproved) {
  // if (!this.checkValidation()) {
  //    this.notificationService.printWarningMessage("Please select atleast one Payment");
  //  } else {
  //    if (isApproved ? this.CancelChequesModel.isApproved = true : this.CancelChequesModel.isApproved = false)

  //    this.CancelChequesModel.paymentBordereauxModel = this.paymentBordereauxResult.filter(i => i.isSelected == true);
  //    //this.CancelChequesModel.isApproved = true;
  //    this.cancelChecquesService.updatePaymentBordereauxDetails(this.CancelChequesModel).subscribe(res => {

  //      this.refreshGrid();


  //    },
  //      error => {
  //        this.notificationService.printErrorMessage(error.message);
  //      }
  //    )

  //}

  //}

  onApproveCancelChequePayments() {

    this.modalRef.close();
    var dataTable: any = this.datatableElement;
    var selectedItems: any[] = dataTable.dt.rows('.selected').data();
    if (!this.checkApprovalAuthorityValidation()) {
      this.notificationService.printWarningMessage("This payment exceeds your Approval (Finance) Authority. Please refer to a colleague with a sufficient Approval (Finance) Authority to approve this payment.");
      return;
    }
    var selectedPaymentIds: number[] = _.map(selectedItems, i => i.paymentID);

    if (selectedPaymentIds.length === 0) {
      this.notificationService.printWarningMessage('Please select payment(s) to Approve');
      return;
    }


    this.cancelChecquesService.approveCancelChequePayments(selectedPaymentIds, this.loggedInUserId).subscribe(res => {
      this.notificationService.printSuccessMessage('Selected payment(s) approved successfully');
      this.refreshGrid();
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    )
  }

  OnRejectCancelChequePayments() {
    this.modalRef.close();
    var dataTable: any = this.datatableElement;
    var selectedItems: any[] = dataTable.dt.rows('.selected').data();
    if (!this.checkApprovalAuthorityValidation()) {
      this.notificationService.printWarningMessage("This payment exceeds your Approval (Finance) Authority. Please refer to a colleague with a sufficient Approval (Finance) Authority to approve this payment.");
      return;
    }
    var selectedPaymentIds: number[] = _.map(selectedItems, i => i.paymentID);

    if (selectedPaymentIds.length === 0) {
      this.notificationService.printWarningMessage('Please select payment(s) to Reject');
      return;
    }
    this.cancelChecquesService.rejectCancelChequePayments(selectedPaymentIds).subscribe(res => {
      this.notificationService.printSuccessMessage('Selected payment(s) rejected successfully');
      this.refreshGrid();
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    )
  }

  openDialog(content) {
    var dataTable: any = this.datatableElement;
    var selectedrows: any[] = dataTable.dt.rows('.selected').data();
    var selectedPaymentIds: number[] = _.map(selectedrows, i => i.paymentID);
    if (selectedPaymentIds.length === 0) {
      this.notificationService.printWarningMessage('No payment(s) selected for action');
      return;
    }

    this.modalRef = this.modalService.open(content);
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  onViewHideItemSelect(item: any) {
    let c = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    c[0].visible = true;

    this.refreshGrid();
  }

  onViewHideItemDeSelect(item: any) {
    let c = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    c[0].visible = false;

    this.refreshGrid();
  }

  onViewHideSelectAll() {
    this.displayedColumns.forEach(a => {
      if (a.data != "isSelected") {
        a.isAlwaysVisible ? a.visible = true : a.visible = false;
      }
    });

    this.refreshGrid();
  }

  onViewHideDeSelectAll() {
    this.displayedColumns.forEach(a => {
      if (a.data != "isSelected") {
        a.visible = false;
      }
    });

    this.refreshGrid();
  }


  refreshGrid() {
    if (this.cancelChecquesTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;


    }
  }

  checkApprovalAuthorityValidation(): boolean {
    let isValidAuthority = true;
    let loggedInUserAuthority = this.loggedInUserPaymentAuthority;
    var dataTable: any = this.datatableElement;
    dataTable.dt.rows('.selected').every(function (rowIdx, tableLoop, rowLoop) {
      var rowData = this.data();
      if (rowData.paymentAmount > loggedInUserAuthority) {
        this.node().className = 'highlightData';
        isValidAuthority = false;
      }
    });
    return isValidAuthority;
  }

  public newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    console.log("buttons");
    //console.log($.fn.dataTable.ext.buttons);
    dt.one('preXhr', function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 500;

      dt.one('preDraw', function (e, settings) {
        // Call the original action function        
        oldExportAction(self, e, dt, button, config);

        dt.one('preXhr', function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    // Requery the server with the new one-time export settings
    dt.ajax.reload();

    function oldExportAction(self, e, dt, button, config) {
      if (button[0].className.indexOf('buttons-excel') >= 0) {
        //this.datatableElement.dtInstance.
        var buttons: any = ($.fn.dataTable.ext as any).buttons;
        if (buttons.excelHtml5.available(dt, config)) {
          buttons.excelHtml5.action.call(self, e, dt, button, config);
        }
        else {
          buttons.excelFlash.action.call(self, e, dt, button, config);
        }
      } else if (button[0].className.indexOf('buttons-print') >= 0) {
        buttons.print.action(e, dt, button, config);
      }
    }
  };

  public oldExportAction(self, e, dt, button, config) {
    if (button[0].className.indexOf('buttons-excel') >= 0) {
      //this.datatableElement.dtInstance.
      var buttons: any = ($.fn.dataTable.ext as any).buttons;
      if (buttons.excelHtml5.available(dt, config)) {
        buttons.excelHtml5.action.call(self, e, dt, button, config);
      }
      else {
        buttons.excelFlash.action.call(self, e, dt, button, config);
      }
    } else if (button[0].className.indexOf('buttons-print') >= 0) {
      buttons.print.action(e, dt, button, config);
    }
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  bindPaymentLinkedDocuments(linkDocs) {
    let result = "";
    for (var i = 0; i < linkDocs.length; i++) {
      result += "<a href='" + linkDocs[i].linkedDocumentUrl + "' target = '_blank' > " + linkDocs[i].linkedDocumentName + " </a></br>";
    }
    return "<span>" + result + "</span>";
  }
  bindMMiClaimRefrence(full) {    
    let result = "";
    result += "<a href='" + "claims/" + full.policyId + "/" + full.claimId + "/" + "claimdetail" + "' target = '_blank' > " + full.claimNumber + " </a></br>";
    return "<span>" + result + "</span>";
  }
}

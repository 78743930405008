import { Component, OnInit } from '@angular/core';
import { SearchPaymentBordereauxService } from '../../components/payment-bordereaux/search-payment-bordereaux/search-payment-bordereaux.service'

@Component({
  selector: 'app-payment-bordereaux',
  templateUrl: './payment-bordereaux.component.html'
})
export class PaymentBordereauxComponent implements OnInit {

  eFileURL = "";

  constructor(
    private searchPaymentBordereauxService: SearchPaymentBordereauxService) { }

  ngOnInit(): void {
    this.loadFinanceEFileUrl();
  }

  getEFile() {
    if (this.eFileURL)
      window.open(this.eFileURL, "_blank");
  }

  loadFinanceEFileUrl() {
    this.searchPaymentBordereauxService.getFinanceEFileUrl().subscribe((res: any) => {
      console.log(res);
      if (res != null) {
        this.eFileURL = res.finaceFolderURL;
      }
    },
      error => {
        console.log(error);
      });
  }

}

import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
//import { PolicyholderComponent } from './policyholder/policyholder.component'
//import { DatepickerPopupComponent } from '.././common-components/datepicker/datepicker-popup.component'
//import { NgbDateCustomParserFormatter } from '.././common-components/datepicker/dateformat'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreditorComponent } from './creditorholder/creditor.component';
import { CreditorholderService } from './creditorholder/creditorholder.service'
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    SharedModule
  ],
  providers: [CreditorholderService]
})
export class AddParticipantModule { }

import { Injectable } from '@angular/core';
import { SearchParticipantVM } from './search-participant.model';
import { ParticipantService } from '../../../shared/services/participant.service';

@Injectable()
export class SearchParticipantService {

  constructor(private participantservice: ParticipantService) {

  }

  searchParticipants(filterCriteria: SearchParticipantVM): any {
    return this.participantservice.searchParticipants(filterCriteria)
  }

}

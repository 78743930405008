import { Injectable } from '@angular/core';
import { IClaimDeatil } from 'src/app/shared/models/claim-detail.model'
import { ClaimService } from 'src/app/shared/services/claim.service'
import { PolicyService } from 'src/app/shared/services/policy.service'
import { HandlingOrganisationService } from 'src/app/shared/services/handling-organisation.service'
import { ClaimHandlerService } from 'src/app/shared/services/claim-handler.service'
import { Observable, BehaviorSubject, from, throwError } from "rxjs";
import { ApportionmentService } from '../../../shared/services/apportionment.service';


@Injectable({
  providedIn: 'root'
})

export class ClaimDetailService {

  
  constructor(private claimService: ClaimService,
    private handlingOrganizationService: HandlingOrganisationService,
    private policyService: PolicyService,
    private claimHandlerService: ClaimHandlerService,
    private apportionmentService: ApportionmentService
   ) {

  }

  getClaimById(id: number): any {
    return this.claimService.getClaimById(id);
  }

  getNewClaimByPolicyId(id: number): any {
    return this.claimService.getNewClaimByPolicyId(id);
  }

  getClaimAdditionlPolices(id: number): any {
    return this.claimService.getClaimAdditionlPolices(id);
  }

  getMatchedClaimant(id: number, matched: boolean): any {
    return this.claimService.getMatchedClaimant(id, matched);
  }

  addPolicy(claimId: number, policyId: number): any {
    return this.claimService.addPolicy(claimId, policyId);
  }

  removePolicy(additionalPolicyId: number): any {
    return this.claimService.removePolicy(additionalPolicyId);
  }

  getHandlingOrganization(): Observable<any> {
    return this.handlingOrganizationService.getHandlingOrganization();
  }

  getClaimHandlers(organisationId: any) : Observable<any> {
    return this.claimHandlerService.getClaimHandlers(organisationId);
  }

  saveOrUpdateClaim(formData: any): Observable<any> {
    if (formData.claimDetailId > 0)
      return this.claimService.updateClaim(formData);
    else
      return this.claimService.createClaim(formData);
  }

  getPolicydata(policyNumber: string) {
    return this.policyService.getPolicyByNumber(policyNumber);
  }

  getPolicyById(policyId: number):any {
    return this.policyService.getPolicyById(policyId);
  }
  isApportionmentAvailble(id: number): any {
    return this.claimService.isApportionmentAvailble(id);
  }
  checkValuationAvailble(id: number): any {
    return this.claimService.checkValuationAvailble(id);
  }

  createClaimFolder(claimId: number): any {
    return this.claimService.createClaimFolder(claimId);
  }

  checkRIApplicable(claimId: number): any {
    return this.claimService.checkRIApplicable(claimId);
  }

  updateClaimStatus(id: number, claimStatus: number, settlementReason1: number, settlementReason2: number, settlementReason3: number, version: string): any {
    return this.claimService.updateClaimStatus(id, claimStatus, settlementReason1, settlementReason2, settlementReason3, version);
  }
  checkPolicyUsedInApportionment(policyId: number,claimId:number): any {
    return this.apportionmentService.checkPolicyUsedInApportionment(policyId, claimId);
  }

  GetClaimEFileUrl(claimId: number): any {
    return this.claimService.GetClaimEFileUrl(claimId);
  }

  getMatchingClaimants(claimTypeId: number, firstName: string, surname: string, dateofBirth: Date, nationalInsuranceNumber: string): any {
    return this.claimService.getMatchingClaimants(claimTypeId, firstName, surname, dateofBirth, nationalInsuranceNumber);
  }

  getMatchingClaimantsData(data: any): any {
    // debugger
    return this.claimService.getMatchingClaimantsData(data);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { SearchPolicyComponent } from './search-policy/search-policy.component';
import { SearchClaimComponent } from './search-claim/search-claim.component';
import { SearchParticipantComponent } from './search-participant/search-participant.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SearchClaimService } from './search-claim/search-claim.service';
import { DataTablesModule } from 'angular-datatables';
import { SearchPolicyService } from './search-policy/search-policy.service';
import { SearchParticipantService } from './search-participant/search-participant.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppModule } from '../../app.module';
import { SharedModule } from '../../shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [HomeComponent, SearchClaimComponent, SearchPolicyComponent, SearchParticipantComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    MatTableModule,
    MatSortModule,
    NgbModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskModule
  ],
  exports: [SearchPolicyService],
  providers: [SearchClaimService, SearchPolicyService, SearchParticipantService]
})
export class HomeModule { }

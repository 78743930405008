import { Injectable } from '@angular/core';
import { PaymentBordereauxService } from '../../../shared/services/payment-bordereaux.service'
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class VerifyChequesService {

  constructor(private paymentBordereauxService: PaymentBordereauxService) {

  }

  getChequesBordereaux(filter: any): any {
    return this.paymentBordereauxService.getChequesBordereaux(filter);
  }

  approvePayments(chequeNo: number, paymentIds, loggedInUserId): any {
    return this.paymentBordereauxService.approvePayments(chequeNo, paymentIds, loggedInUserId);
  }

  rejectPayments(paymentIds): any {
    return this.paymentBordereauxService.rejectPayments(paymentIds);
  }

  printPayments(paymentIds): any {
    return this.paymentBordereauxService.printPayments(paymentIds);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { HomeModule } from './components/home/home.module';
import { PolicyholderComponent } from './components/add-participant/policyholder/policyholder.component'
import { RoleGuardService as RoleGuard } from './shared/services/role-guard.service';
import { CreditorComponent } from './components/add-participant/creditorholder/creditor.component';

import { HandlingorganisationComponent } from './components/add-participant/handlingorganisation/handlingorganisation.component';
import { ClaimModule } from './components/claim/claim.module';
import { ClaimComponent } from './components/claim/claim.component';
import { ParticipantClaimHandlerComponent } from './components/add-participant/claim-handler/participant-claim-handler.component';
import { ClaimDetailComponent } from './components/claim/claim-detail/claim-detail.component'
//import { ClaimDetailNotesComponent } from './components/claim/claim-detail-notes/claim-detail-notes.component';

import { PaymentBordereauxModule } from './components/payment-bordereaux/payment-bordereaux.module';
import { PaymentBordereauxComponent } from './components/payment-bordereaux/payment-bordereaux.component';
import { UnsavedChangesGuard } from './shared/guard/unsaved-changes.guard';
import { ClaimsReportComponent } from './components/reports/claims-report/claims-report.component';
import { ReportsModule } from './components/reports/reports.module';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { CaseloadSummaryComponent } from './components/reports/caseload-summary/caseload-summary.component';
import { NavMenuComponent } from './components/common-components/nav-menu/nav-menu.component';
import { UserDetailsModule } from './components/user-details/user-details.module';
import { LogOutComponent } from './components/log-out/log-out.component';
import { LitigationReportComponent } from './components/reports/litigation-report/litigation-report.component';
import { ClaimAssignmentComponent } from './components/claim/claim-assignment/claim-assignment.component';
import { ReinsurerComponent } from './components/add-participant/reinsurer/reinsurer.component';
import { ReinsuranceWorkflowComponent } from './components/reports/reinsurance-workflow/reinsurance-workflow.component';
import { AllocatedClaimComponent } from './components/reports/allocated-claim/allocated-claim.component';
import { EltoMatchingComponent } from './components/reports/elto-matching/elto-matching.component';
import { SearchWorkflowComponent } from './components/search-workflow/search-workflow.component';
import { ReinsuranceListingComponent } from './components/reports/reinsurance-listing/reinsurance-listing.component';

import { ReinsuranceCreditControlComponent } from './components/reports/reinsurance-credit-control/reinsurance-credit-control.component';
import { AggregatedClaimsReportComponent } from './components/reports/aggregated-claims-report/aggregated-claims-report.component';
import { ReportingPeriodPaymentsComponent } from './components/reports/reporting-period-payments/reporting-period-payments.component';
import { DwpComponent } from './components/add-participant/dwp/dwp.component';
import { AddWorkflowItemComponent } from './components/claim/add-workflow-item/add-workflow-item.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  {
    path: 'policy-holder', children: [
      { path: '', component: PolicyholderComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1135', permission: '2' } },
      { path: 'edit/:policyId', component: PolicyholderComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1135' } }
    ]
  },
  {
    path: 'creditor-holder', children: [
      {
        path: '', component: CreditorComponent,
        canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1136', permission: '2' }
      },
      { path: 'edit/:creditorId', component: CreditorComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1136' } }
    ]
  },
  {
    path: 'handling-organisation', children: [
      { path: '', component: HandlingorganisationComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1137', permission: '2' } },
      { path: 'edit/:handlingOrganisationId', component: HandlingorganisationComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1137' } }
    ]
  },
  {
    path: 'reinsurer', children: [
      { path: '', component: ReinsurerComponent },
      { path: 'edit/:reinsurerId', component: ReinsurerComponent }
    ]
  },
  {
    path: 'dwp', children: [
      { path: '', component: DwpComponent },
      { path: 'edit/:dwpId', component: DwpComponent }
    ]
  },
  // { path: 'claims', component: ClaimComponent },   

  // CR-18 Merge Request Changes
  //  {  path: 'policy-holder', component: PolicyholderComponent
  //canActivate: [RoleGuard], data: { userPermissionsId: '1136' }
  { path: 'policy-holder', component: PolicyholderComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1136' } },
  { path: 'manage-colleagues', component: ParticipantClaimHandlerComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1165' } },
  { path: 'claim-handler', component: ParticipantClaimHandlerComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1161' } },
  { path: 'claim-handler/:id', component: ParticipantClaimHandlerComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1161' } },
  {
    path: 'claim-detail', children: [
      { path: '', component: ClaimDetailComponent },
      { path: 'newclaim/:policyId', component: ClaimDetailComponent },
      { path: 'edit/:claimId', component: ClaimDetailComponent },
    ], canActivate: [RoleGuard], data: { userPermissionsId: '1140' }
  },
  { path: 'payment-bordereaux', component: PaymentBordereauxComponent },
  { path: 'claims-report', component: ClaimsReportComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1153' } },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'caseload-summary', component: CaseloadSummaryComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1155' } },
  { path: 'log-out', component: LogOutComponent, },
  { path: 'litigation-report', component: LitigationReportComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1154' } },
  { path: 'claim-assignment', component: ClaimAssignmentComponent },
  { path: 'reinsurance-workflow', component: ReinsuranceWorkflowComponent },
  { path: 'allocated-claims-report', component: AllocatedClaimComponent },
  { path: 'elto-report', component: EltoMatchingComponent },
  { path: 'reinsurance-listing', component: ReinsuranceListingComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1402' } },
  { path: 'reinsurance-credit-control', component: ReinsuranceCreditControlComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1403' } },
  { path: 'search-workflow', component: SearchWorkflowComponent },
  { path: 'aggregated-claims-report', component: AggregatedClaimsReportComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1405' } },
  { path: 'reporting-period-payments', component: ReportingPeriodPaymentsComponent },
  { path: 'add-workflow-item', component: AddWorkflowItemComponent },
  { path: 'add-workflow-item/edit/:workflowItemId', component: AddWorkflowItemComponent }
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false }), CommonModule, HomeModule, ClaimModule, PaymentBordereauxModule, ReportsModule, UserDetailsModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { NotificationService } from '../../../shared/services/notification.service';
import { ReinsuranceWorkflow } from '../../../models/reinsurance-workflow.model';
import { ManageReinsurerService } from '../../../shared/services/manage-reinsurer.service';
import { ReportsService } from '../../../shared/services/reports.service';

@Injectable({
  providedIn: 'root'
})
export class ReinsuranceWorkflowService {

  formDataInput: ReinsuranceWorkflow;

  constructor(
    private notificationService: NotificationService,
    private manageReinsurerService: ManageReinsurerService,
    private reportsService: ReportsService
  ) { }


  getAllReinsurers(): any {
    return this.manageReinsurerService.getAllReinsurers();
  }

  getReinsuranceWorkflowReport(): any {
    var body = {
      ...this.formDataInput

    };

    this.reportsService.getReinsuranceWorkflowReport(this.formDataInput).subscribe((response: any) => {
      if (response != null) {

      }
      else {
        this.notificationService.printWarningMessage("No data is available for the Report");
      }

    })

  }
}

import { Component, OnInit, Input, OnDestroy, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PolicyholderService } from '../policyholder.service'
import { NgForm } from '@angular/forms';
import { IClaimLevelSchemeCreditor } from './claim-level-scheme-creditor.model'
import { CreditorholderService } from '../../creditorholder/creditorholder.service';
import { enumYesNo, creditorStatus } from '../../../../shared/models/common.enum';
import { LookupService } from '../../../../shared/services/lookup.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs/internal/Subject';
import * as _ from "lodash";
import { BaseComponentComponent } from '../../../base-component/base-component.component';
import { SecurityService } from '../../../../shared/services/security.service';

@Component({
  selector: 'app-claim-level-scheme-creditor',
  templateUrl: './claim-level-scheme-creditor.component.html',
  styleUrls: ['./claim-level-scheme-creditor.component.css']
})
export class ClaimLevelSchemeCreditorComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked {
  @ViewChild(NgForm, { static: false })
  public form: NgForm;

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('claimLevelSchemeCreditorTable') claimLevelSchemeCreditorTable: ElementRef;
  dtOptions: DataTables.Settings = {};
  formData: IClaimLevelSchemeCreditor;
  @Input() isValid: boolean = true;
  @Input() formPanel: boolean = false;
  isGridLoaded: boolean = false;
  dtTrigger = new Subject();
  creditorStatuskeys: any[];
  isChecked: boolean = false;
  creditorLocalData: any[];
  aggregatedPolicyholderValue: any;

  constructor(
    private policyService: PolicyholderService,
    private creditorholderService: CreditorholderService,
    private lookupService: LookupService,
    private notificationService: NotificationService, securityService: SecurityService) {
    super(securityService, 1135);
    this.creditorStatuskeys = this.lookupService.getLookupsByTypeId(4);

    this.creditorLocalData = [];
  }

  ngOnInit() {
    this.creditorLocalData = this.policyService.claimLevelSchemeCreditor;
    this.LoadFrm();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      destroy: true,
      columnDefs: [
        { "orderable": false, "targets": 7 }
      ]
    };

    this.dtTrigger.next();
    this.refreshGrid();
  }

  refreshForm() {
    this.creditorLocalData = this.policyService.claimLevelSchemeCreditor;
    this.refreshGrid();
    this.form.form.markAsPristine();
  }

  LoadFrm(): void {
    this.initialpolicyHolderCrditor();
  }

  initialpolicyHolderCrditor() {
    this.formData = {
      claimLevelCreditorId: null,
      schemeCreditorId: null,
      policyId: this.policyService.formData.policyId,
      creditorName: '',
      creditorStatus: '',
      creditorId: '',
      aggregatedCreditorName: '',
      aggregatedCreditorStatus: '',
      aggregatedCreditorID: null,
      isNewAdded: true,
      isSelected: false,
      isClientDeletd: false,
      creditorNo: '',
      aggregatedCreditorNo: '',
      schemeCreditorNo: '',
      aggregatedCreditorFlag: false
    };
  }

  notDeletedCreditor() {
    let c = _.filter(this.creditorLocalData, function (o) { return o.isClientDeletd == false; });
    return c;
  }

  onDeleteschemeCreditorItem(i: number) {
    if (this.creditorLocalData.filter(a => !a.isClientDeletd)[i].isSelected == true) {
      this.notificationService.printWarningMessage("Primary policyholder record cannot be deleted. Please assign a different primary policyholder for the policy and then delete this policyholder record.");
      return;
    }
    this.creditorLocalData.filter(a => !a.isClientDeletd)[i].isClientDeletd = true;

    this.policyService.claimLevelSchemeCreditor = this.creditorLocalData;
    this.refreshGrid();
    this.form.form.markAsDirty();
  }

  getSatusById(statusID): string {
    let statusType = this.creditorStatuskeys.find(a => a.id == statusID);
    if (typeof statusType !== 'undefined') {
      return statusType.text;
    }
    return '';
  }

  getCreditorDetail() {
    this.creditorholderService.getPolicyHolderDetails(this.formData.schemeCreditorNo).subscribe(res => {
      if (res != null && res.aggregatedCreditorFlag) {
        this.notificationService.printWarningMessage('Only standard Policyholders can be associated with Policies directly. ' + res.creditorName + ' is an aggregated Policyholder and cannot be associated to this Policy directly. The relevant subordinate Policyholder should be associated with the Policy. If ' + res.creditorName + ' is the correct paymaster for this policy, please review the policyholder records and ensure the correct alignment.');
        this.initialpolicyHolderCrditor();
      }
      else if (res != null) {
        this.formData.schemeCreditorId = res.id;
        this.formData.creditorNo = res.creditorNo;
        this.formData.creditorName = res.creditorName;
        this.formData.creditorStatus = res.creditorStatus;
        this.formData.creditorId = res.creditorId;
        this.formData.aggregatedCreditorID = res.aggregatedCreditorID;
        this.formData.aggregatedCreditorName = res.aggregatedCreditorName;
        this.formData.aggregatedCreditorStatus = res.aggregatedCreditorStatus;
        this.formData.aggregatedCreditorNo = res.aggregatedCreditorNo;
        this.formData.isSelected = false;
      }
      else {
        this.notificationService.printWarningMessage('Policyholder not found');
        this.initialpolicyHolderCrditor();
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  validateForm() {
    this.isValid = true;

    if (this.formData.schemeCreditorId == null || this.formData.creditorId == null || this.formData.creditorName == '' || this.formData.creditorStatus == '') {
      this.notificationService.printWarningMessage('Please select a valid policyholder');
      return this.isValid = false;
    }
    if (this.formData.schemeCreditorId != null) {
      const isDulicateCreditor = this.creditorLocalData.find(a => a.schemeCreditorId == this.formData.schemeCreditorId
        && !a.isClientDeletd);
      if (isDulicateCreditor != null) {
        this.notificationService.printWarningMessage('Duplicate policyholder not allow to add in list');
        return this.isValid = false;
      }
    }
    return this.isValid;
  }

  onSubmit(form: NgForm) {
    if (this.validateForm()) {
      this.setPrimaryPolicy();
      this.formData.isNewAdded = true;
      this.formData.isClientDeletd = false;
      this.formData.aggregatedCreditorFlag = false;
      // this.formData.isSelected = false;
      this.formData.policyId = this.policyService.formData.policyId;
      this.creditorLocalData.push(this.formData);
      this.policyService.claimLevelSchemeCreditor = this.creditorLocalData;
      this.refreshGrid();
      this.initialpolicyHolderCrditor();
      this.formPanel = false;
    }


  }

  setPrimaryPolicy() {
    if (this.creditorLocalData.length == 0) {
      this.formData.isSelected = true;
    }
    else {
      this.formData.isSelected = false;
    }
  }

  onSelectionChange(i) {
    if (this.creditorLocalData.find(i => i.isSelected == true)) {
      let creditorID = this.creditorLocalData.find(i => i.isSelected == true).creditorId;
      this.creditorLocalData.find(i => i.creditorId == creditorID).isSelected = false;
    }

    this.creditorLocalData.filter(a => !a.isClientDeletd)[i].isSelected = true;

    this.policyService.claimLevelSchemeCreditor = this.creditorLocalData;
  }

  refreshGrid() {
    if (this.claimLevelSchemeCreditorTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ConfigurationService } from './configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandlingOrganisationSetting } from '../../components/administration/email-setup/email-setup.model';

@Injectable({
  providedIn: 'root'
})
export class AutoEmailSetupService {
  private settingUrl: string = '';
  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) { }

  getAllHandlingOrganisationSetting() {
    this.settingUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + '/api/handlingOrganisationSetting';
    return this.dataService.get(this.settingUrl);
  }  

  putHandlingOrganisationSetting(data): any {

    let url = this.configurationService.serverSettings.policyClaimsAgentUrl + '/api/handlingOrganisationSetting/' + "updateHOSetting";

    return this.dataService.putWithId(url, data);
  } 

}



export class ApportionmentNotes {
  id: number;
  apportionmentSummaryID: number;
  notesDate: Date;
  comments: string;
  //handlerID: number;
  isNewAdded: boolean;
  updatedByUserName: string;
}   

import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ReinsuranceCreditControlService } from './reinsurance-credit-control.service';
import { enumYesNo } from '../../../shared/models/common.enum';
import { SecurityService } from '../../../shared/services/security.service';
import { BaseComponentComponent } from '../../base-component/base-component.component';

@Component({
  selector: 'app-reinsurance-credit-control',
  templateUrl: './reinsurance-credit-control.component.html',
  styleUrls: ['./reinsurance-credit-control.component.css']
})
export class ReinsuranceCreditControlComponent extends BaseComponentComponent implements OnInit {
  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  reportTypes: any[] = [
    { "id": 1, "text": "Invoice" },
    { "id": 2, "text": "Receipts" },
  ]

  dateTypesInvoice: any[] = [
    { "id": 1, "text": "Settlement Presentation Date Submitted" },
    { "id": 2, "text": "Settlement Presentation Date Created" },
    { "id": 3, "text": "Adjusted Invoice Date Created" }
  ];

  dateTypesReceipts: any[] = [
    { "id": 1, "text": "Settlement Presentation Date Submitted" },
    { "id": 2, "text": "Settlement Presentation Date Created" },
    { "id": 3, "text": "Received Created" }
  ];

  dateTypes: any[];
  reinsurersData: any[];
  notificationStatusLookup: any[];
  yesNoEnum = enumYesNo;
  yesNokeys: any[];
  isValid: boolean = true;
  claimTypeLookup: any[];

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  constructor(
    private lookupService: LookupService,
    private reinsuranceCreditControlService: ReinsuranceCreditControlService,
    private notificationService: NotificationService,
    securityService: SecurityService) {
    super(securityService, 1403);
  }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.initializeForm();
    this.getAllReinsurers();
    this.notificationStatusLookup = this.lookupService.getLookupsByTypeId(57).filter(x => {
      return x.id == 1311 || x.id == 1312 || x.id == 1313 || x.id == 1314
    });
    this.notificationStatusLookup.unshift({ "id": 0, "text": "All" });
    this.yesNokeys = Object.values(this.yesNoEnum).filter(Number);
    this.claimTypeLookup = this.lookupService.getLookupsByTypeId(1);
    this.claimTypeLookup.unshift({ "id": 0, "text": "All" });
  }

  initializeForm() {
    this.reinsuranceCreditControlService.formDataInput = {
      mmiClaimReference: '',
      reinsurerNameID: [{ "id": 0, "text": "All" }],
      reinsurerNameIDValues: [],
      reportType: 0,
      invoiceNumber: '',
      calculationOverride: 0,
      notificationStatus: [{ "id": 0, "text": "All" }],
      notificationStatusValues: [],
      dateTypeId: null,
      dateFrom: null,
      dateTo: null,
      claimTypes: [{ "id": 0, "text": "All" }],
      claimTypeValues: []
    }
  }

  getAllReinsurers() {
    this.reinsuranceCreditControlService.getAllReinsurers().subscribe(response => {
      if (response != null) {
        this.reinsurersData = response.map(item => {
          let container: any = {};
          container.id = item.id;
          container.text = item.reinsurerName + "/" + item.id;
          return container;
        })
        this.reinsurersData.unshift({ "id": 0, "text": "All" });
      }
    },
      error => {
        this.notificationService.printErrorMessage(error);
      }
    );
  }

  onReportTypeChange() {
    this.dateTypes = [];
    if (this.reinsuranceCreditControlService.formDataInput.reportType == 1) {
      // Invoice
      this.dateTypes = this.dateTypesInvoice;
    }
    else if (this.reinsuranceCreditControlService.formDataInput.reportType == 2) {
      // Receipts
      this.dateTypes = this.dateTypesReceipts;
    }
  }

  generateReinsuranceCreditControlReport() {
    this.setValuesInRequestModel();
    if (this.validateForm()) {
      this.reinsuranceCreditControlService.getReinsuranceCreditControlReport();
    }
    else {
      this.notificationService.printWarningMessage("Please select all mandatory fields.");
    }
  }

  setValuesInRequestModel() {
    this.reinsuranceCreditControlService.formDataInput.reinsurerNameIDValues =
      this.reinsuranceCreditControlService.formDataInput.reinsurerNameID.map(x => x.id);

    this.reinsuranceCreditControlService.formDataInput.notificationStatusValues =
      this.reinsuranceCreditControlService.formDataInput.notificationStatus.map(x => x.id);

    this.reinsuranceCreditControlService.formDataInput.claimTypeValues =
      this.reinsuranceCreditControlService.formDataInput.claimTypes.map(x => x.id);
  }

  validateForm(): boolean {
    this.isValid = true;
    if (this.reinsuranceCreditControlService.formDataInput.reportType == 0 ||
      this.reinsuranceCreditControlService.formDataInput.reinsurerNameID.length == 0 ||
      this.reinsuranceCreditControlService.formDataInput.reinsurerNameID == null ||
      this.reinsuranceCreditControlService.formDataInput.notificationStatus == null ||
      this.reinsuranceCreditControlService.formDataInput.notificationStatus.length == 0
    ) {
      this.isValid = false;
      return false;
    }
    if ((this.reinsuranceCreditControlService.formDataInput.dateTypeId != null ||
      this.reinsuranceCreditControlService.formDataInput.dateFrom != null ||
      this.reinsuranceCreditControlService.formDataInput.dateTo != null) &&
      (this.reinsuranceCreditControlService.formDataInput.dateTypeId == null ||
        this.reinsuranceCreditControlService.formDataInput.dateFrom == null ||
        this.reinsuranceCreditControlService.formDataInput.dateTo == null)) {
      this.isValid = false;
      return false;
    }
    return this.isValid;
  }

}

import { Component, OnInit, ɵpublishDefaultGlobalUtils, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { PolicyholderService } from './policyholder.service'
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import {
  enumYesNo, enumYesNoUnknown, enumYesNoUnknownBlank, enumYesNoUnknownBlankTBC, enumYesNoUnknownTBC,
  policyType, lineOfBusiness, regionCode, fscsProtected, lookupType, creditorStatus, enumUserRole
} from '../../../shared/models/common.enum';
import { NgClass } from '@angular/common';
import { CreditorholderService } from '../../add-participant/creditorholder/creditorholder.service';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { error } from 'protractor';
import { LookupService } from '../../../shared/services/lookup.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NotificationService } from '../../../shared/services/notification.service';
import { IPolicyholder } from './policyholder.model';
import { ExcessDetailComponent } from './excess-detail/excess-detail.component';
import { LimitOfIndemnityComponent } from './limit-of-indemnity/limit-of-indemnity.component';
import { LongTermAgreementComponent } from './long-term-agreement/long-term-agreement.component';
import { ClaimLevelSchemeCreditorComponent } from './claim-level-scheme-creditor/claim-level-scheme-creditor.component';
import { PolicyNoteComponent } from './policy-note/policy-note.component';
import * as _ from "lodash";
import { ComponentCanDeactivate } from '../../../shared/guard/unsaved-changes.guard';
import { Observable, Subject } from 'rxjs';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { SecurityService } from '../../../shared/services/security.service';
import { User } from 'oidc-client';

@Component({
  selector: 'app-policyholder',
  templateUrl: './policyholder.component.html',
  styleUrls: ['./policyholder.component.css']
})

export class PolicyholderComponent extends BaseComponentComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  @ViewChild('policyExcessDetails') policyExcessDetails: ExcessDetailComponent;
  @ViewChild('policyLimitsIndemnity') policyLimitsIndemnity: LimitOfIndemnityComponent;
  @ViewChild('policyLongTermAgreements') policyLongTermAgreements: LongTermAgreementComponent;
  @ViewChild('policyHolder') policyHolder: ClaimLevelSchemeCreditorComponent;
  @ViewChild('policyNote') policyNote: PolicyNoteComponent;
  @ViewChild('policyTabset') policyTabset;

  isValid: boolean = true;
  isExcessValid: boolean = true;
  isLIValid: boolean = true;
  isLTAValid: boolean = true;
  isPolicyHolderValid: boolean = true;

  policyHolderFrm: FormGroup;
  submitted = false;
  tDate: Date;
  tempDate: any[];
  cancelDateMessage: string;
  loading: boolean = false;
  backButton: boolean = false;
  backToSearchButton: boolean = false;


  yesNokeys: any[];
  yesNoEnum = enumYesNo;
  YesNoUnknownkeys: any[];
  YesNoUnknownEnum = enumYesNoUnknown;
  yesNoUnknownBlankkeys: any[];
  yesNoUnknownBlankEnum = enumYesNoUnknownBlank;
  yesNoUnknownBlankTBCkeys: any[];
  yesNoUnknownBlankTBCEnum = enumYesNoUnknownBlankTBC;
  yesNoUnknownTBCkeys: any[];
  yesNoUnknownTBCEnum = enumYesNoUnknownTBC;
  lineOfBusinesskeys: any[];
  regionCodekeys: any[];
  fscsProtectedkeys: any[];
  fscsProtectedEnum = fscsProtected;
  policyTypekeys: any[];
  policyTypeEnum = policyType;
  policyId: any;
  eFileURL: string;
  //title = 'ng-bootstrap-tabset';
  fetchedPolicyLOB: any[] = [];
  fetchedAndSubmittedPolicyLOB: any[] = [];
  isMMIUserWithClaimRole: boolean = false;
  isMMIUserWithClaimAndFinanceRole: boolean = false;
  isLineOfBusinessHasEL: boolean = false;

  saveMethod: any;
  isFormSubmitted: boolean = true;
  formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean { return false; };
  isSettledButtonClicked: boolean = false;
  //activeTabId;
  userId: number;
  users = [6, 82, 217, 147];
  //users = [6, 12, 63]; //UAT
  //users = [34, 36]; //DEV

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    let hasPendingChanges: boolean = (!this.form.touched && !this.policyExcessDetails.form.dirty && !this.policyLimitsIndemnity.form.dirty &&
      !this.policyLongTermAgreements.form.dirty && !this.policyHolder.form.dirty && !this.policyNote.form.dirty)
    this.saveMethod = this.onSubmit;
    return hasPendingChanges;
  }

  constructor(private fb: FormBuilder,
    private modalService: NgbModal,
    private policyService: PolicyholderService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private lookupService: LookupService,
    private config: NgbDatepickerConfig,
    private notificationService: NotificationService, securityService: SecurityService) {
    super(securityService, 1135);
    this.loadLookups();

  }
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: false,
    itemsShowLimit: 5,
    allowSearchFilter: true
  };

  ngOnInit() {
    this.loading = true;
    this.policyId = this.currentRoute.snapshot.paramMap.get('policyId');
    let userDetail = this.securityService.getUserData();
   
    if (userDetail != null) {
      this.isMMIUserWithClaimRole = ((userDetail.organisationId == 1) && (userDetail.userRoleId == enumUserRole.Claims));
      this.isMMIUserWithClaimAndFinanceRole = ((userDetail.organisationId == 1) && (userDetail.userRoleId == enumUserRole.ClaimsAndFinance));
      this.userId = userDetail.id;
      
    }
    let isSearch = (this.currentRoute.snapshot.queryParams.isSearchFlag === 'true');
    if (isSearch) {
      this.backToSearchButton = true;
    }
    else {
      this.backToSearchButton = false;
    }
    //console.log("issearch ", isSearch);

    if (this.policyId == null) {
      this.resetForm();
    }
    else {
     
      this.setLoadData(this.policyId);
    }

    this.loading = false;
  }

  setLoadData(policyId) {
    this.policyService.excessDetails = [];
    this.policyService.limitofIndemnity = [];
    this.policyService.longTermAgreement = [];
    this.policyService.claimLevelSchemeCreditor = [];
    this.policyService.policyNotes = [];

    this.policyService.getPolicyByID(parseInt(policyId)).subscribe(res => {
      this.policyService.formData = res;
     // this.showHidePolicyOnELTOCheckbox();
      this.eFileURL = res.eFileURL;

      this.policyService.excessDetails = res.excessDetails;
      this.policyService.limitofIndemnity = res.limitsOfIndemnityDetails;
      this.policyService.longTermAgreement = res.longTermAgreements;
      this.policyService.claimLevelSchemeCreditor = res.claimLevelSchemeCreditors;

      //this.policyService.claimLevelSchemeCreditor.find(i => i.creditorId == res.creditorId).isSelected = true;

      let c = _.filter(this.policyService.claimLevelSchemeCreditor, function (o: any) { return o.creditorId == res.creditorId; });
      if (c.length > 0) {
        c[0].isSelected = true;
      }

      this.policyService.policyNotes = res.policyNotes;
      this.policytypeChange(this.policyService.formData.policyLineOfBusiness);

      if (this.policyExcessDetails != null && this.policyExcessDetails != undefined) {
        this.policyExcessDetails.refreshForm();
      }

      if (this.policyLimitsIndemnity != null && this.policyLimitsIndemnity != undefined) {
        this.policyLimitsIndemnity.refreshForm();
      }

      if (this.policyLongTermAgreements != null && this.policyLongTermAgreements != undefined) {
        this.policyLongTermAgreements.refreshForm();
      }

      if (this.policyHolder != null && this.policyHolder != undefined) {
        this.policyHolder.refreshForm();
      }

      if (this.policyNote != null && this.policyNote != undefined) {
        this.policyNote.refreshForm();
      }

      if (this.policyService.formData.policyType != null && (this.policyService.formData.policyType == 116 || this.policyService.formData.policyType == 142 || this.policyService.formData.policyType == 138)) {
        if (!this.users.includes(this.userId)) {
          this.isReadOnlyMode = true;
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );

    this.backButton = true;
  }

  getEFile() {
    if (this.eFileURL != '') {
      this.goToLink(this.eFileURL);
    }
    else {
      if (confirm("Policy folder does not exist for this policy. Do you want to create.")) {
        this.createPolicyFolder();
      }
    }
  }

  createPolicyFolder() {
    this.policyService.createPolicyFolder(this.policyId).subscribe(res => {
      if (res != null) {
        this.eFileURL = res.filepath;
        if (this.eFileURL != '') {
          this.goToLink(this.eFileURL);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  loadLookups() {
    this.yesNokeys = Object.values(this.yesNoEnum).filter(Number)
    this.YesNoUnknownkeys = Object.values(this.YesNoUnknownEnum).filter(Number)
    this.yesNoUnknownBlankkeys = Object.values(this.yesNoUnknownBlankEnum).filter(Number)
    this.yesNoUnknownBlankTBCkeys = Object.values(this.yesNoUnknownBlankTBCEnum).filter(Number)
    this.yesNoUnknownTBCkeys = Object.values(this.yesNoUnknownTBCEnum).filter(Number)
    //this.lineOfBusinesskeys = this.lookupService.getLookupsByTypeId(12);
    this.loadLOB();
    this.regionCodekeys = this.lookupService.getLookupsByTypeId(13);
    this.fscsProtectedkeys = Object.values(this.fscsProtectedEnum).filter(Number)
    //this.policyTypekeys = this.lookupService.getLookupsByTypeId(11);
    this.loadPolicyTypes();
  }

  resetForm(form?: NgForm) {
    if (form = null)
      form.resetForm();
    this.policyService.formData = {
      policyId: null,
      insuredTitle: '',
      excess: null,
      policyType: null,
      lta: null,
      policyLineOfBusiness: null,
      lastLTAPeriod: '',
      regionCode: null,
      limitofIndemnity: null,
      policyNumber: '',
      policyCoverSheet: null,
      coverFrom: null,
      coverTo: null,
      creditor: '',
      renewalPremium: null,
      cancelDate: null,
      renewalCurrentDate: null,
      zed: null,
      renewalPreviousDate: null,
      addressLine1: '',
      addressLine2: '',
      town: '',
      country: '',
      postCode: '',
      fscsProtected: null,
      fscsFrom: null,
      fscsTo: null,
      policyHolderSchemeStatus: '',
      policyHolderCreditorName: '',
      aggregatedCreditorStatus: '',
      aggregatedCreditorID: null,
      aggregatedCreditorName: '',
      creditorId: null,
      coninsured: null,
      policyOnELTO: null
    };
    this.policyService.excessDetails = [];
    this.policyService.limitofIndemnity = [];
    this.policyService.longTermAgreement = [];
    this.policyService.claimLevelSchemeCreditor = [];
    this.policyService.policyNotes = [];
    this.policyService.creditor = null;
    if (this.policyHolder != null) {
      this.policyHolder.refreshForm();
    }
    if (this.policyLimitsIndemnity != null) {
      this.policyLimitsIndemnity.refreshForm();
    }
    if (this.policyLongTermAgreements != null) {
      this.policyLongTermAgreements.refreshForm();
    }
    if (this.policyExcessDetails) {
      this.policyExcessDetails.refreshForm();
    }
    this.backButton = false;
  }

  policytypeChange(selectedValue?) {
    this.policyService.formData.policyLineOfBusiness = null;
    let policyType = this.policyTypekeys.find(a => a.id == this.policyService.formData.policyType);

    //this.lineOfBusinesskeys = this.lookupService.getLookupsByTypeId(12);
    this.loadLOB();
    if (policyType != null && policyType.text.toUpperCase() == "EL" || policyType.text.toUpperCase() == "TP") {
      if (policyType.text.toUpperCase() == "EL") {
        this.policyService.formData.limitofIndemnity = 2;
      }
      this.lineOfBusinesskeys = this.lineOfBusinesskeys.filter(a => a.text == policyType.text.toUpperCase());
    }

    if (selectedValue != null) {
      this.policyService.formData.policyLineOfBusiness = selectedValue;
      this.fetchedPolicyLOB = [];
      this.policyService.formData.policyLineOfBusiness.forEach((element) => {
        var lob = { id: element.id, text: element.text };
        this.fetchedPolicyLOB.push(lob);
      });
      // alert(JSON.stringify(this.fetchedPolicyLOB));
      // this.policyService.formData.policyLineOfBusiness[1] = { ...this.policyService.formData.policyLineOfBusiness[1], isDisabled: true };
    }

    let c = _.filter(this.policyService.formData.policyLineOfBusiness, function (o: any) { return o.text == "TP"; });
    if (c.length > 0) {
      this.policyService.formData.limitofIndemnity = 1;
    }
    
    this.showHidePolicyOnELTOCheckbox();
  }

  onLineOfBusinessSelect(item: any) {
    
    if (item.text.toUpperCase() == "TP") {
      this.policyService.formData.limitofIndemnity = 1;
    }
    //let tp = _.filter(this.policyService.formData.policyLineOfBusiness, function (o: any) { return o.text == "TP"; });
    let el = _.filter(this.policyService.formData.policyLineOfBusiness, function (o: any) { return o.text == "EL"; });

    if ((this.policyService.formData.policyLineOfBusiness.length == 0 || (this.policyService.formData.policyLineOfBusiness.length == 1) && el.length > 0)) {
      this.policyService.formData.limitofIndemnity = 2;
    }
    else {
      this.policyService.formData.limitofIndemnity = 1;
    }
    this.showHidePolicyOnELTOCheckbox();
  }


  onLineOfBusinessDeSelect(item: any) {
    this.deSelectLOB();
  }

  dateChangeCallback() {
    if (this.policyService.formData.cancelDate != null) {
      this.policyService.formData.coverTo = this.policyService.formData.cancelDate;
    }
    if (this.policyService.formData.zed != null && this.policyService.formData.zed.toString() != "") {
      var newDate = new Date(this.policyService.formData.zed);
      newDate.setDate(newDate.getDate() - 1);
      this.policyService.formData.coverTo = newDate;
    }
  }

  clearCoverfromValidation() {
    this.config.minDate = undefined;
    this.config.outsideDays = "visible";
  }

  getDateOnly(date: Date) {
    if (typeof date == 'string')
      var newDate = new Date(date);
    else
      var newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  validateForm() {
    this.isValid = true;
    this.isFormSubmitted = true;
    this.checkValidity(this.policyService.formData.insuredTitle, "", "basicDetailsTab");
    this.checkValidity(this.policyService.formData.excess, null, "basicDetailsTab");
    this.checkValidity(this.policyService.formData.policyType, null, "basicDetailsTab");
    this.checkValidity(this.policyService.formData.regionCode, null, "basicDetailsTab");
    this.checkValidity(this.policyService.formData.limitofIndemnity, null, "basicDetailsTab");
    this.checkValidity(this.policyService.formData.policyNumber, "", "basicDetailsTab");
    this.checkValidity(this.policyService.formData.policyCoverSheet, null, "basicDetailsTab");
    this.checkValidity(this.policyService.formData.fscsProtected, null, "basicDetailsTab");

    if (!this.isValid) {
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next()
      return false;
    }

    if (this.policyService.formData.coverFrom == null) {
      this.changeTab("basicDetailsTab");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    if (this.policyService.formData.cancelDate == null) {
      this.changeTab("basicDetailsTab");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next()
      return this.isValid = false;
    }

    if (this.policyService.formData.policyLineOfBusiness == null || this.policyService.formData.policyLineOfBusiness.length == 0) {
      this.changeTab("basicDetailsTab");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next()
      return this.isValid = false;
    }

    let c = _.filter(this.policyService.formData.policyLineOfBusiness, function (o: any) { return o.text == "TP"; });
    if (c.length > 0 && this.policyService.formData.limitofIndemnity != 1) {
      this.changeTab("basicDetailsTab");
      this.notificationService.printWarningMessage("Limits of Indemnity is mandatory for policy having any one Line of Business as 'TP' "
        + "Please Select Limits of Indemnity option as \"Yes\" on policy’s basic details screen and complete the LOI details under Limits of Indemnity tab.");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    if (this.policyService.formData.zed != null && (this.getDateOnly(this.policyService.formData.cancelDate) <= this.getDateOnly(
      this.policyService.formData.zed))) {
      this.changeTab("basicDetailsTab");
      this.notificationService.printWarningMessage("Cancellation Date should be greater than ZED");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    if (this.policyService.formData.renewalCurrentDate != null && (this.getDateOnly(this.policyService.formData.renewalCurrentDate) <= this.getDateOnly(
      this.policyService.formData.coverFrom))) {
      this.changeTab("basicDetailsTab");
      this.notificationService.printWarningMessage("Renewal Date should be greater than Policy Cover From");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    if (this.policyService.formData.renewalCurrentDate != null && (this.getDateOnly(this.policyService.formData.renewalCurrentDate) <= this.getDateOnly(
      this.policyService.formData.renewalPreviousDate))) {
      this.changeTab("basicDetailsTab");
      this.notificationService.printWarningMessage("Renewal Date should be greater than Renewal Date: Previous");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    if ((this.getDateOnly(this.policyService.formData.coverTo)) <= this.getDateOnly(
      this.policyService.formData.coverFrom)) {
      this.changeTab("basicDetailsTab");
      this.notificationService.printWarningMessage("Cover From should be less than Cover To");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    if (this.policyService.formData.zed != null && (this.getDateOnly(this.policyService.formData.zed) < this.getDateOnly(
      this.policyService.formData.coverFrom))) {
      this.changeTab("basicDetailsTab");
      this.notificationService.printWarningMessage("ZED should be greater than/equal to the Cover From");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    if (this.policyService.formData.fscsProtected == 3 && (this.policyService.formData.fscsFrom == null || this.policyService.formData.fscsTo == null)) {
      this.changeTab("basicDetailsTab");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    if (this.policyService.formData.fscsProtected == 3)
      if ((this.policyService.formData.fscsFrom != null && this.getDateOnly(this.policyService.formData.fscsFrom) < this.getDateOnly(this.policyService.formData.coverFrom)) ||
        (this.policyService.formData.fscsTo != null && this.getDateOnly(this.policyService.formData.fscsTo) > this.getDateOnly(this.policyService.formData.coverTo))) {
        this.changeTab("basicDetailsTab");
        this.notificationService.printWarningMessage("FSCS Date Should be greater than/equal to Cover From and less than/equal to than Cover To");
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        return this.isValid = false;
      }

    if (this.policyService.formData.fscsFrom != null && this.getDateOnly(this.policyService.formData.fscsFrom) >= this.getDateOnly(this.policyService.formData.fscsTo)) {
      this.changeTab("basicDetailsTab");
      this.notificationService.printWarningMessage("FSCS Date To should be greater than FSCS Date From ");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    if (this.policyService.formData.excess == 1 && this.policyService.excessDetails.filter(a => !a.isClientDeletd).length == 0) {
      this.isExcessValid = false;
      this.changeTab("excessTab");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    //if (this.policyService.formData.limitofIndemnity == 1 && this.policyService.limitofIndemnity.filter(a => !a.isClientDeletd).length == 0) {
    //  this.isLIValid = true;
    //  this.changeTab("limitsOfIndemnityTab");
    //  //return this.isValid = false;
    //}

    if (this.policyService.formData.lta == 1 && this.policyService.longTermAgreement.filter(a => !a.isClientDeletd).length == 0) {
      this.isLTAValid = false;
      this.changeTab("longTermAgreementsTab");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }
    if (this.policyService.claimLevelSchemeCreditor.filter(a => !a.isClientDeletd).length == 0) {

      this.isPolicyHolderValid = false;
      this.changeTab("policyholderTab");
      this.notificationService.printWarningMessage("At least one Policyholder is required on the Policy");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }
    else {

      if (this.policyService.claimLevelSchemeCreditor.filter(a => !a.isClientDeletd).length == 0) {
        this.notificationService.printWarningMessage("At least one Policyholder is required on the Policy");
        this.changeTab("policyholderTab");
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        return this.isValid = false;
      }
    }

    if (this.policyService.claimLevelSchemeCreditor.filter(a => a.isSelected == true).length == 0) {
      this.notificationService.printWarningMessage("Please select the primary policyholder");
      this.changeTab("policyholderTab");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }

    if (this.policyService.formData.excess == 1) {
      this.policyService.excessDetails.filter(a => !a.isClientDeletd).forEach(value => {
        if (this.isValid) {
          if (this.getDateOnly(value.dateFrom) < this.getDateOnly(this.policyService.formData.coverFrom)) {
            this.notificationService.printWarningMessage("Date From should be greater than/equal to Cover From");
            this.changeTab("excessTab");
            this.isFormSubmitted = false;
            this.formValidityFlagLoaded.next();
            return this.isValid = false;
          }

          if (this.getDateOnly(value.dateTo) > this.getDateOnly(this.policyService.formData.coverTo)) {
            this.notificationService.printWarningMessage("Date To should be less than Cover To");
            this.changeTab("excessTab");
            this.isFormSubmitted = false;
            this.formValidityFlagLoaded.next();
            return this.isValid = false;
          }
        }
      });
    }

    if (this.policyService.formData.limitofIndemnity == 1) {
      this.policyService.limitofIndemnity.filter(a => !a.isClientDeletd).forEach(value => {
        if (this.isValid) {
          if (this.getDateOnly(value.dateFrom) < this.getDateOnly(this.policyService.formData.coverFrom)) {
            this.notificationService.printWarningMessage("Date From should be greater than/equal to Cover From");
            this.changeTab("limitsOfIndemnityTab");
            this.isFormSubmitted = false;
            this.formValidityFlagLoaded.next();
            return this.isValid = false;
          }

          if (this.getDateOnly(value.dateTo) > this.getDateOnly(this.policyService.formData.coverTo)) {
            this.notificationService.printWarningMessage("Date To should be less than Cover To");
            this.changeTab("limitsOfIndemnityTab");
            this.isFormSubmitted = false;
            this.formValidityFlagLoaded.next();
            return this.isValid = false;
          }
        }
      });
    }

    if (this.policyService.formData.lta == 1) {
      this.policyService.longTermAgreement.filter(a => !a.isClientDeletd).forEach(value => {
        if (this.isValid) {
          if (this.getDateOnly(value.dateFrom) < this.getDateOnly(this.policyService.formData.coverFrom)) {
            this.notificationService.printWarningMessage("Date From should be greater than/equal to Cover From");
            this.changeTab("longTermAgreementsTab");
            this.isFormSubmitted = false;
            this.formValidityFlagLoaded.next();
            return this.isValid = false;
          }

          if (this.getDateOnly(value.dateTo) > this.getDateOnly(this.policyService.formData.coverTo)) {
            this.notificationService.printWarningMessage("Date To should be less than Cover To");
            this.changeTab("longTermAgreementsTab");
            this.isFormSubmitted = false;
            this.formValidityFlagLoaded.next();
            return this.isValid = false;
          }
        }
      });
    }
    return this.isValid;
  }

  checkValidity(control: any, errorValue: any, tabName: string) {
    if (control == null || control == errorValue) {
      this.changeTab(tabName);
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      this.isValid = false;
    }
  }


  changeTab(tabName) {
    this.policyService.activeTabName = tabName;
    this.policyTabset.select(this.policyService.activeTabName);
  }

  onSubmit() {
    
    try {
      if (this.validateForm()) {
        this.loading = true;
        if (this.policyNote.formData.comment != undefined && this.policyNote.formData.comment != '') {
          if (confirm("Comment / Note has been written but not Added. Do you want to save your Comment / Note ?")) {
            this.policyNote.saveNotes();
          }
        }

        if (this.policyService.formData.policyId > 0) {
          this.fetchedAndSubmittedPolicyLOB = [];
          this.fetchedAndSubmittedPolicyLOB = this.fetchedPolicyLOB;
          this.policyService.formData.policyLineOfBusiness.forEach((element) => {
            const found = this.fetchedAndSubmittedPolicyLOB.some(c => c.id === element.id);
            if (!found) {
              this.fetchedAndSubmittedPolicyLOB.push(element);
            }
          });
        }

        this.policyService.saveOrUpdatePolicy().subscribe(res => {
          this.notificationService.printSuccessMessage('Saved Successfully');
          if (res.policyId == undefined && res != null) {
            
            //this.isFormSubmitted = true;
            //this.formValidityFlagLoaded.next();
            this.form.form.markAsUntouched();
            this.policyExcessDetails.form.form.markAsPristine();
            this.policyLimitsIndemnity.form.form.markAsPristine();
            this.policyLongTermAgreements.form.form.markAsPristine();
            this.policyHolder.form.form.markAsPristine();
            this.policyNote.form.form.markAsPristine();
            this.fetchedAndSubmittedPolicyLOB = [];
            this.router.navigate(['/policy-holder/edit/' + res]);

          }
          else {
            //this.isFormSubmitted = true;
            //this.formValidityFlagLoaded.next();
            this.fetchedAndSubmittedPolicyLOB = [];
            this.setLoadData(this.policyId);
            //window.location.reload();
          }
          this.form.form.markAsUntouched();
          this.loading = false;
          this.isFormSubmitted = true;
          this.formValidityFlagLoaded.next();
        },
          error => {
            if (error.error == "Policy number already exist") {
              this.notificationService.printWarningMessage(error.error);
            }
            else if (error.error == "Line of Business is already used in claim. Hence can not be removed.") {
              if (this.fetchedAndSubmittedPolicyLOB.length > 0) {
                this.policyService.formData.policyLineOfBusiness = this.fetchedAndSubmittedPolicyLOB;
                this.fetchedAndSubmittedPolicyLOB = [];
                this.deSelectLOB();
              }
              this.notificationService.printWarningMessage(error.error);
            }
            else {
              this.notificationService.printErrorMessage(error.message);
            }
            this.loading = false;
          }
        )
      }
      else {
        this.changeTab(this.policyService.activeTabName);
      }


    }
    catch (Error) {
      this.notificationService.printErrorMessage(Error.message);
      this.loading = false;
    }
  }

  refresh(): void {
    if (confirm('Are you sure want to reset this page?')) {
      if (this.policyId == null) {
        this.resetForm();
      }
      else {
        this.setLoadData(this.policyId);
      }
      //window.location.reload();
    }
  }

  navigateToClaimComponent() {
    if (this.policyService.formData.policyId == null) {
      return false;
    }
    else {
      this.router.navigate(['/claims/' + this.policyService.formData.policyId])
    }
  }

  deSelectLOB() {
    
    //let tp = _.filter(this.policyService.formData.policyLineOfBusiness, function (o: any) { return o.text == "TP"; });
    let el = _.filter(this.policyService.formData.policyLineOfBusiness, function (o: any) { return o.text == "EL"; });

    if ((this.policyService.formData.policyLineOfBusiness.length == 0 || (this.policyService.formData.policyLineOfBusiness.length == 1) && el.length > 0)) {
      this.policyService.formData.limitofIndemnity = 2;
    }
    else {
      this.policyService.formData.limitofIndemnity = 1;
    }
    this.showHidePolicyOnELTOCheckbox();
  }

  showHidePolicyOnELTOCheckbox() {
    if (this.policyService.formData.policyLineOfBusiness != null && this.policyService.formData.policyLineOfBusiness.length > 0) {
      let el = _.filter(this.policyService.formData.policyLineOfBusiness, function (o: any) { return o.text == "EL"; });
      
      if (el.length > 0) {
        this.isLineOfBusinessHasEL = true;
      }
      else {
        this.isLineOfBusinessHasEL = false;
      }
    }
    else {
      this.isLineOfBusinessHasEL = false;
    }
  }

  loadLOB() {
    let ELandTP = this.lookupService.getLookupsByTypeId(12).filter(item => { return item.text.toUpperCase() == "EL" || item.text.toUpperCase() == "TP" });
    this.lineOfBusinesskeys = this.lookupService.getLookupsByTypeId(12).filter(item => { return item.text.toUpperCase() != "EL" && item.text.toUpperCase() != "TP" });
    this.lineOfBusinesskeys.sort((a, b) => (a.text > b.text) ? 1 : -1);
    this.lineOfBusinesskeys.unshift(ELandTP[0], ELandTP[1]);
  }

  loadPolicyTypes() {
    let policyTypesValues = this.lookupService.getLookupsByTypeId(11).filter(item => { return item.text.toUpperCase() == "EL" || item.text.toUpperCase() == "TP" || item.text.toUpperCase() == "RMP" });
    this.policyTypekeys = this.lookupService.getLookupsByTypeId(11).filter(item => { return item.text.toUpperCase() != "EL" && item.text.toUpperCase() != "TP" && item.text.toUpperCase() != "RMP" });
    this.policyTypekeys.sort((a, b) => (a.text > b.text) ? 1 : -1);
    this.policyTypekeys.unshift(policyTypesValues[0], policyTypesValues[2], policyTypesValues[1]);
  }
}

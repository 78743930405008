export class ApportionmentPolicy {
  id: number;
  apportionmentSummaryID: number;
  defendantGroup: string;
  defendantName :string
  mmiInsured: number;
  policyId: number;
  policyNumber: string;
  insuredTitle :string
  insurerRepresentative: string;
  abusefrom: Date;
  abuseto: Date;
  days: number
  contributesToDamages: number;
  contributesToDefenceCosts: number;
  fscsProtected: number;
  damages: number; 
  cost: number;
  cru: number; 
  defenceCosts: number;
  fscs: number;
  fscsDays: number;
  groupName: string;
  index: number;
  isPolicyDateOutRangeFlag: boolean;
  isPolicyExcessFlag: boolean;
  constructor()
    {
    this.abusefrom = null;
    this.abuseto = null;
    this.apportionmentSummaryID= null;
    this.defendantGroup = '';
    this.defendantName = '';
    this.mmiInsured = null;
    this.policyId = null;
    this.insuredTitle = '';
    this.insurerRepresentative = '';
    this.days = 0; this.contributesToDamages = null;
    this.contributesToDefenceCosts = null;
    this.fscsProtected = 0;
    this.damages = 0.1;
    this.cost = 0.1;
    this.cru = 0.1;
    this.defenceCosts = 0.1;
    this.fscs = 0.1;
    this.fscsDays = 0;
    this.groupName = '';
    this.id = null;
    this.policyNumber = '';
    this.index = null;
}
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SearchPolicyVM } from '../../../models/search-policy.model';
import { lineOfBusiness, policyType } from '../../../shared/models/common.enum';
import { IPager } from '../../../shared/models/pager.model';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { SecurityService } from '../../../shared/services/security.service';
import { SearchPolicyService } from './search-policy.service';
import { Router } from '@angular/router';
import { StorageService } from '../../../shared/services/storage.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-search-policy',
  templateUrl: './search-policy.component.html',
  styleUrls: ['./search-policy.component.css']
})


export class SearchPolicyComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  public dtOptions: any = {};
  public paginationInfo: IPager;
  public authenticated: boolean = false;
  public authSubscription: Subscription;
  public errorReceived: boolean;
  public dtTrigger: Subject<any>;
  public isInitialised: boolean = false;
  searching: boolean;

  public searchPolicyVM: SearchPolicyVM = new SearchPolicyVM();
  public searchPolicyResult: SearchPolicyVM[];
  public regionLookups: {}
  public policyTypeLookups: any[];
  public lobLookups: any[];
  isShown: boolean = true;
  public isHidden: boolean = true;
  public loggedInUserOrganisationId: number;
  public IsMMIUser: boolean = false;
  @ViewChild('btnSearch') btnSearchElm: ElementRef;

  displayedColumns: any[] = [{ title: 'Policy Number', data: 'policyNumber' }, { title: 'Policy Type', data: 'policyTypeValue' }, { title: 'Insured Title', data: 'insuredTitle' }
    , { title: 'Cover From', data: 'coverFrom', type: 'date', render: function (data) { return moment(data).format('DD/MM/YYYY') } }, { title: 'Cover To', data: 'coverTo', render: function (data) { return moment(data).format('DD/MM/YYYY') } }
    , { title: 'Policyholder Name', data: 'creditorName' }, { title: 'Policyholder Status', data: 'creditorStatus' }, { title: 'Aggregated Policyholder Name', data: 'aggregatedCreditorName' }, { title: 'Aggregated Policyholder Status', data: 'aggregatedCreditorStatus' }
    , { title: 'Policy on ELTO', data: 'policyOnELTO', visible: false, render: function (data) { return data == null ? null: data == 1 ? 'Yes' : 'No' } } , { title: 'Action', data: null }
  ];

  constructor(private service: SearchPolicyService,
    private configurationService: ConfigurationService,
    private securityService: SecurityService,
    private lookupService: LookupService,
    private storageService: StorageService,
    private notificationService: NotificationService, private router: Router) {
    this.dtTrigger = new Subject();
  }

  ngOnInit() {
    this.loggedInUserOrganisationId = this.securityService.getUserData().organisationId;
    this.InitializeForm();
    this.InitializePolicyDataTable();
    this.BindLookups();
    var searchCriteria = this.storageService.retrieve("policySearchCriteria");
    if (searchCriteria) {
      var that = this;
      this.searchPolicyVM = searchCriteria;
      this.dtOptions.displayStart = this.searchPolicyVM.pageIndex * this.searchPolicyVM.pageSize;
      this.dtOptions.order = [[_.findIndex(this.displayedColumns, function (o) { return o.data == that.searchPolicyVM.sortBy; }), , this.searchPolicyVM.sortOrder]]
      this.dtOptions.pageLength = this.searchPolicyVM.pageSize;
      setTimeout(r => { this.SearchPolicy() }, 0);
    } else {
      this.isInitialised = true;
    }
  }

  InitializePolicyDataTable(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedColumns,
      serverSide: true,
      processing: true,
      searching: false,

      //Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          title: 'PolicyExport',
          titleAttr: 'Excel',
          filename: function () { return "PolicyExport" + Math.random(); },
          tag: '',
          action: this.newExportAction,
          messageTop: "Search Policy Result",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
          }

        }
      ],
      'columnDefs': [
        {
          targets: -1,
          render: function (data) {
            //data = '<div class="btnsearch"><a class="btn btn-primary btn-sm" href="/policy-holder/edit/' + data["policyId"] + '"><i class="fa fa-eye"</i></a></div>';
            return '<span class="btnsearch"><button class="btn btn-primary btn-sm"><i class="fa fa-eye"</i></button></span>';

          },
          'bSortable': false,


        }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('button', row).unbind('click');
        $('button', row).bind('click', () => {
          self.viewButtonClickHandler(data);
        });
        return row;
      },
      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        if (this.isInitialised) {
          this.searchPolicyVM.sortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
          this.searchPolicyVM.sortOrder = dataTablesParameters.order[0].dir;
          this.searchPolicyVM.pageIndex = dataTablesParameters.start > 0 ? parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) : 0;
          this.searchPolicyVM.pageSize = dataTablesParameters.length;
        }
        this.isInitialised = true;
        this.getPolicy(this.searchPolicyVM).then(resp => {
          callback({
            page: that.paginationInfo.actualPage,
            pages: that.paginationInfo.totalPages,
            recordsTotal: that.paginationInfo.totalItems,
            recordsFiltered: that.paginationInfo.totalItems,
            data: that.searchPolicyResult
          });
        });
      }
    };
  }

  resetform() {
    this.searchPolicyVM = new SearchPolicyVM();
    this.isHidden = true;
  }

  SearchPolicy(): void {
    if (this.checkSearchPolicyValidation()) {
      if (this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isHidden = false;
    }
    else { this.notificationService.printWarningMessage("Please provide at least one search criteria"); }
    setTimeout(() => this.btnSearchElm.nativeElement.focus(), 0);
  }
  viewButtonClickHandler(data: any): void {
    this.router.navigate(['/policy-holder/edit/' + data.policyId], { queryParams: { isSearchFlag: true } });
    //if (this.loggedInUserOrganisationId == 1)
    //{
    //  this.router.navigate(['/policy-holder/edit/' + data.policyId], { queryParams: { isSearchFlag: true } });  
    //}
    //else {
    //  debugger;
    //  this.service.checkPolicyAccessiblity(data.policyId, this.loggedInUserOrganisationId)
    //    .subscribe((response: any) => {
    //      if (response) {
    //        this.router.navigate(['/policy-holder/edit/' + data.policyId], { queryParams: { isSearchFlag: true } }); 
    //      }
    //      else {
    //        this.router.navigate(['/access-denied']);
    //      }
    //    });
    //}

  }


  getPolicy(filterCriteria: SearchPolicyVM): Promise<any> {
    this.errorReceived = false;
    this.storageService.store("policySearchCriteria", this.searchPolicyVM);
    var promise = new Promise((resolve, reject) => {
      this.service.getPolicy(filterCriteria)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(policy => {
          this.searchPolicyResult = policy.searchPolicyResultItems;
          this.paginationInfo = policy.paginationInfo;
          resolve();
        });
    });
    return promise;
  }

  private handleError(error: any) {
    this.errorReceived = true;
    return Observable.throw(error);
  }

  checkSearchPolicyValidation(): Boolean {
    if (!this.searchPolicyVM.insuredTitle && !this.searchPolicyVM.policyType && !this.searchPolicyVM.policyNumber
      && !this.searchPolicyVM.lineOfBusiness && !this.searchPolicyVM.regionCode && !this.searchPolicyVM.creditorId)
      return false;
    else { return true; }
  }

  hideShowSearchPolicyPanel(): void {
    this.isShown = !this.isShown;
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  public newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    console.log("buttons");
    //console.log($.fn.dataTable.ext.buttons);
    dt.one('preXhr', function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 30000;

      dt.one('preDraw', function (e, settings) {
        // Call the original action function
        //debugger;
        oldExportAction(self, e, dt, button, config);

        dt.one('preXhr', function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    //  // Requery the server with the new one-time export settings
    dt.ajax.reload();

    function oldExportAction(self, e, dt, button, config) {
      if (button[0].className.indexOf('buttons-excel') >= 0) {
        //this.datatableElement.dtInstance.
        var buttons: any = ($.fn.dataTable.ext as any).buttons;
        if (buttons.excelHtml5.available(dt, config)) {
          buttons.excelHtml5.action.call(self, e, dt, button, config);
        }
        else {
          buttons.excelFlash.action.call(self, e, dt, button, config);
        }
      } else if (button[0].className.indexOf('buttons-print') >= 0) {
        buttons.print.action(e, dt, button, config);
      }
    }
  };

  searchTitle = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((searchText) => this.service.search(searchText)),
      tap(() => this.searching = false)
    )

  InitializeForm() {
    this.searchPolicyVM.lineOfBusiness = 0,
      this.searchPolicyVM.regionCode = 0,
      this.searchPolicyVM.policyType = 0
  }

  BindLookups() {
    this.regionLookups = this.lookupService.getLookupsByTypeId(13);
    //this.policyTypeLookups = this.lookupService.getLookupsByTypeId(11);
      this.loadPolicyTypes();
    //this.lobLookups = this.lookupService.getLookupsByTypeId(12);
    this.loadLOB();
  }

  enterKeydownOnForm(event): void {
    event.preventDefault();
    this.SearchPolicy();
  };

  loadLOB() {
    let ELandTP = this.lookupService.getLookupsByTypeId(12).filter(item => { return item.text.toUpperCase() == "EL" || item.text.toUpperCase() == "TP" });
    this.lobLookups = this.lookupService.getLookupsByTypeId(12).filter(item => { return item.text.toUpperCase() != "EL" && item.text.toUpperCase() != "TP" });
    this.lobLookups.sort((a, b) => (a.text > b.text) ? 1 : -1);
    this.lobLookups.unshift(ELandTP[0], ELandTP[1]);
  }

  loadPolicyTypes() {
    let policyTypesValues = this.lookupService.getLookupsByTypeId(11).filter(item => { return item.text.toUpperCase() == "EL" || item.text.toUpperCase() == "TP" || item.text.toUpperCase() == "RMP" });
    this.policyTypeLookups = this.lookupService.getLookupsByTypeId(11).filter(item => { return item.text.toUpperCase() != "EL" && item.text.toUpperCase() != "TP" && item.text.toUpperCase() != "RMP" });
    this.policyTypeLookups.sort((a, b) => (a.text > b.text) ? 1 : -1);
    this.policyTypeLookups.unshift(policyTypesValues[0], policyTypesValues[2], policyTypesValues[1]);
  }
}

import { Injectable } from '@angular/core';
import { CreditorService } from '../../../shared/services/creditor.service'
import { Observable } from 'rxjs';
import { Icreditorholder } from './creditorholder.model'
import { DataService } from '../../../shared/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class CreditorholderService {

  formData: Icreditorholder;
  private creditorUrl: string = "/api/creditor/";
  constructor(private creditorService: CreditorService, private dataService: DataService,) {
  }

  getCreditorById(id: number): any {
    return this.creditorService.getCreditorById(id);
  }

  saveOrUpdatePolicy(): any {

    var body = {
      ...this.formData
    };
    if (this.formData.id > 0)
      return this.creditorService.updateCreditor(body);
    else
      return this.creditorService.createCreditor(body);
  }

  getPolicyHolderDetails(creditorNumber: string): any {
    return this.creditorService.getPolicyholderDetails(creditorNumber);
  }

  checkPolicyHolderIsParentOfAnother(creditorId: number): any {
    return this.creditorService.checkPolicyHolderIsParentOfAnother(creditorId);
  }

/* createCreditorHolderFolder(creditorID: number, policyHolderID: string, policyHolderName:string): any {*/
  createCreditorHolderFolder(creditorID: number, policyHolderID: string, policyHolderName: string): any {
    return this.creditorService.createCreditorHolderFolder(creditorID, policyHolderID, policyHolderName);
  }

  getCreditorEFileUrl(creditorID: number, policyHolderID: string, policyHolderName: string): any {
    return this.creditorService.createCreditorHolderFolder(creditorID, policyHolderID, policyHolderName);
  }
  
}

import { Data } from "@angular/router";

export class claimAdditionalPoliciesVM {
  apId: number;
  apPolicyId: number;
  apPolicyNumber: string;
  apPolicyType: string;
  apInsuredTitle: string;
  apCreditorName: string;
  apCoverFrom: Date;
  apCoverTo: Date;
  isDelete: boolean = false;
  pageIndex: number;
  pageSize: number;
  sortBy?: string;
  sortOrder?: string;
}

import { Injectable } from '@angular/core';
import { ReinsuranceService } from '../../../shared/services/reinsurance.service';



@Injectable({
  providedIn: 'root'
})
export class ELCoverService {

  constructor(private reinsuranceService: ReinsuranceService) { }

  getELCoverData(): any {
    return this.reinsuranceService.getELCoverData();
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClaimComponent } from './claim.component';
import { ClaimDetailsComponent } from './claim-details/claim-details.component';
import { ApportionmentComponent } from './apportionment/apportionment.component';
import { ClaimValuationComponent } from './valuation/claim-valuation.component';
import { ClaimDetailComponent } from './claim-detail/claim-detail.component';
import { LitigationComponent } from './litigation/litigation.component';
import { ClaimDetailNotesComponent } from './claim-detail-notes/claim-detail-notes.component';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import { ReserveHistoryComponent } from './reserve-history/reserve-history.component';
import { UnsavedChangesGuard } from '../../shared/guard/unsaved-changes.guard';
import { RoleGuardService as RoleGuard } from '../../shared/services/role-guard.service';
import { ClaimReinsuranceComponent } from './claim-reinsurance/claim-reinsurance.component';
import { AddWorkflowItemComponent } from './add-workflow-item/add-workflow-item.component';

const routes: Routes = [

  {
    path: 'claims/:policyId', component: ClaimComponent, children: [
      {
        path: '', component: ClaimDetailComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1140' }
      }
    ]
  },
  {
    path: 'claims/:policyId/:claimId', component: ClaimComponent, children: [
      { path: '', redirectTo: 'claimdetail', pathMatch: 'full' },
      {
        path: 'claimdetail', component: ClaimDetailComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1140' }
      },
      {
        path: 'apportionment', component: ApportionmentComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1142' }
      },
      {
        path: 'litigation', component: LitigationComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1147' }
      },
      {
        path: 'valuation', component: ClaimValuationComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1143' }
      },
      {
        path: 'claim-detail-note', component: ClaimDetailNotesComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1145' }
      },
      {
        path: 'payment', component: PaymentDetailComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1146' }
      },
      {
        path: 'reservehistory', component: ReserveHistoryComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1144' }
      },
      {
        path: 'reinsurance', component: ClaimReinsuranceComponent, canDeactivate: [UnsavedChangesGuard], canActivate: [RoleGuard], data: { userPermissionsId: '1148' }
      },
      {
        //path: 'add-workflow-item', component: AddWorkflowItemComponent
        path: 'add-workflow-item', children: [
          { path: '', component: AddWorkflowItemComponent },
          { path: 'edit/:workflowItemId', component: AddWorkflowItemComponent }
        ]
      },
      //{
      //  path: 'add-workflow-item/edit/:workflowItemId', component: AddWorkflowItemComponent
      //}
      
    ]
  }

]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClaimRoutingModule { }

import { ApportionmentPolicy } from './apportionment-policy.model';
import { ApportionmentNotes } from './apportionment-notes.model';
import { BooleanLiteral } from 'typescript';


export class Apportionment {
  id: number;
  claimId: number;
  mmiLead: number;
  fscsProtected: number;
  mmiDamages: number;
  mmiCosts: number;
  mmicru: number;
  mmiDefenceCosts: number;
  overwrite: boolean;
  overwriteMMIDamages: number;
  overwriteMMICosts: number;
  overwriteMMICRU: number;
  overwriteMMIDefenceCosts: number;
  isMesothelioma: boolean;
  excessType: number;
  apportionmentPolicies: ApportionmentPolicy[];
  apportionmentPolicy: ApportionmentPolicy;
  apportionmentNotes: ApportionmentNotes[];
  isLOIAmountUpdatedInSettings: boolean;
  isAggregatedClaim: boolean;
  isCompensationAct: boolean;
  showMessageForLLA: boolean;
  showMessageForDA: boolean;
  handlingOrganisationId: number;
  version: string;
  contributionClaim: number;
}
 





import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LitigationService {

  private litigationUrl: string = "/api/litigation/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.litigationUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.litigationUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.litigationUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.litigationUrl);
  }

  getLitigationById(id: number): any {
    let url = this.litigationUrl + "getLitigationById";

    return this.dataService.get(url, { id: id });
  }

  getLitigationByClaimId(id: number): any {
    let url = this.litigationUrl + "getLitigationByCalimId";

    return this.dataService.get(url, { id: id });
  }

  createLitigation(data): any {

    let url = this.litigationUrl + "createLitigation";

    return this.dataService.post(url, data);

  }

  updateLitigation(data): any {

    let url = this.litigationUrl + "updateLitigation";

    return this.dataService.putWithId(url, data);
  }

}


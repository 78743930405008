import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service';
import { ClaimHandlerService } from '../../../shared/services/claim-handler.service';
import { ClaimHandler } from './participant-claim-handler.model';

@Injectable()
export class ParticipantClaimHandlerService {
   

  constructor(private handlingOrganisationService: HandlingOrganisationService, private claimHandlerService: ClaimHandlerService) {

  }

  getAllHandlingOrganisations() {
    return this.handlingOrganisationService.getAllHandlingOrganisations();
  }

  getClaimHandlers(organisationId: any) {
    return this.claimHandlerService.getClaimHandlers(organisationId);
  }
  getClaimHandlersByOrganisationId(organisationId: any, includeInActive: boolean) {
    return this.claimHandlerService.getClaimHandlersByOrganisationId(organisationId, includeInActive);
  }

  getClaimHandler(claimHandlerId: any) {
    return this.claimHandlerService.getClaimHandler(claimHandlerId);
  }

  postClaimHandlers(claimHandler: ClaimHandler) {
    return this.claimHandlerService.postClaimHandler(claimHandler);
  }

  putClaimHandlers(claimHandler: ClaimHandler) {
    return this.claimHandlerService.putClaimHandler(claimHandler);
  }

}

import { Component, OnInit, ViewChild, HostListener, ElementRef, OnDestroy, AfterViewChecked } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { SecurityService } from '../../../shared/services/security.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { ReinsurerService } from './reinsurer.service';
import { ReinsurerNote } from './reinsurer-notes.model';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { ComponentCanDeactivate } from '../../../shared/guard/unsaved-changes.guard';
import { Subject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-reinsurer',
  templateUrl: './reinsurer.component.html',
  styleUrls: ['./reinsurer.component.css']
})
export class ReinsurerComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked, ComponentCanDeactivate {

  @ViewChild(NgForm, { static: false })
  private form: NgForm;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('reinsuranceNotesTable') reinsuranceNotesTable: ElementRef;
  isValid: boolean = true;
  isformValid: boolean = true;
  reinsurerFound: string;
  eLstatuskeys: any[];
  tPstatuskeys: any[];
  loading: boolean = false;
  isShowSearchButton: boolean = false;
  // aggregatedPolicyholderValue: any = "No";
  isReadOnlyPermission: boolean = false;
  isDisabledFields: boolean = true;
  public searching: boolean;
  id: string;
  dtTrigger = new Subject();
  dtOptions: DataTables.Settings = {};
  isGridLoaded: boolean = false;

  saveMethod: any;
  isFormSubmitted: boolean = true;
  formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean { return false; };
  isSettledButtonClicked: boolean = false;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    this.saveMethod = this.onSubmit;
    let hasPendingChanges: boolean = !this.form.dirty;
    return hasPendingChanges;
  }

  reinsurerNote: ReinsurerNote = new ReinsurerNote();
  reinsurerNotes: ReinsurerNote[] = [];

  constructor(private reinsurerService: ReinsurerService,
    private currentRoute: ActivatedRoute,
    private lookupService: LookupService,
    private router: Router,
    //private activeRoute: ActivatedRoute,
    private notificationService: NotificationService,
    securityService: SecurityService) {
    super(securityService, 1139);
    this.loadLookups();
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  ngOnInit(): void {
    this.checkPermission();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      columnDefs: [
        { type: 'datetime-uk', "targets": 0 }
      ],
      order: [[0, 'desc']]
    };
    this.initializeForm();
    this.dtTrigger.next();
    this.initialReinsurerNote();
    this.refreshGrid();
  }

  checkPermission() {
    this.isReadOnlyPermission = this.permissionId == 1 ? true : false;
  }

  initializeForm() {
    this.loading = true;
    this.id = this.currentRoute.snapshot.paramMap.get('reinsurerId');

    let isSearch = Boolean(this.currentRoute.snapshot.queryParams.isSearchFlag);
    if (isSearch) {
      this.isShowSearchButton = true;
    }
    else {
      this.isShowSearchButton = false;

    }
    if (this.id == null) {
      this.resetForm();
    }
    else {
      this.setLoadData(this.id);
    }
    this.loading = false;
  }

  resetForm(form?: NgForm) {
    if (form = null)
      form.resetForm();
    this.reinsurerService.formData = {
      id: null,
      reinsurerName: '',
      elStatusId: null,
      tpStatusId: null,
      addressLine1: '',
      addressLine2: '',
      country: '',
      town: '',
      postCode: '',
      hasParentReinsurer: false,
      parentReinsurerId: null,
      reinsurerNotes: [],

      parentReinsurerName: '',
      parentReinsurerELStatusId: null,
      parentReinsurerTPStatusId: null,
      parentReinsurerAddressLine1: '',
      parentReinsurerAddressLine2: '',
      parentReinsurerCountry: '',
      parentReinsurerTown: '',
      parentReinsurerPostCode: ''
    };
  }

  loadLookups() {
    this.eLstatuskeys = this.lookupService.getLookupsByTypeId(50);
    this.tPstatuskeys = this.lookupService.getLookupsByTypeId(51);
  }

  setLoadData(reinsurerId) {
    this.reinsurerService.getReinsurerById(parseInt(reinsurerId)).subscribe(res => {
      this.reinsurerService.formData = res;
      if (res.reinsurerNotes != null) {
        this.reinsurerNotes = res.reinsurerNotes;
      }
      else {
        this.reinsurerNotes = [];
      }
      this.initialReinsurerNote();
      this.refreshGrid();
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  //getSatusById(statusID): string {
  //  let statusType = this.statuskeys.find(a => a.id == statusID);
  //  if (typeof statusType !== 'undefined') {
  //    return statusType.text;
  //  }
  //  return '';
  //}

  getReinurerDetail() {
    this.reinsurerFound = null;
    //  this.creditorService.getPolicyHolderDetails(this.creditorService.formData.aggregatedCreditorNo).subscribe(res => {
    //    if (res != null && res.isPolicyholderAssignedToPolicy) {
    //      if (this.creditorService.formData.id > 0) {
    //        let aggregatedPolicyholderName = res.creditorName;
    //        this.notificationService.printWarningMessage("You cannot aggregate " + aggregatedPolicyholderName + " to another policyholder as " + aggregatedPolicyholderName + " is a policyholder in its own right with associations with policy(ies). You must create a new policyholder record and aggregate both policholders to the new policyholder.");
    //      }
    //      else {
    //        this.notificationService.printWarningMessage("You cannot add an aggregated policyholder that does not already exists in Pierian. You just first create the policyholder and then add them to any relevant policyholder(s).");
    //      }

    //      this.resetAggregatedPolicyholder();
    //    }
    //    else if (res != null) {
    //      this.creditorService.formData.aggregatedCreditorName = res.creditorName
    //      this.creditorService.formData.aggregatedCreditorID = Number(res.creditorId);
    //      this.creditorService.formData.aggregatedCreditorStatusID = this.getSatusById(res.statusID);
    //      this.creditorService.formData.aggregatedCreditorFirstLineAddress = res.firstLineAddress
    //      this.creditorService.formData.aggregatedCreditorSecondLineAddress = res.secondLineAddress
    //      this.creditorService.formData.aggregatedCreditorTown = res.town
    //      this.creditorService.formData.aggregatedCreditorCountry = res.country
    //      this.creditorService.formData.aggregatedCreditorPostcode = res.postcode
    //      this.reinsurerFound = null;
    //    }
    //    else {
    //      this.creditorService.formData.aggregatedCreditorName = ''
    //      this.creditorService.formData.aggregatedCreditorStatusID = null
    //      this.creditorService.formData.aggregatedCreditorFirstLineAddress = ''
    //      this.creditorService.formData.aggregatedCreditorSecondLineAddress = ''
    //      this.creditorService.formData.aggregatedCreditorTown = ''
    //      this.creditorService.formData.aggregatedCreditorCountry = ''
    //      this.creditorService.formData.aggregatedCreditorPostcode = ''
    //      this.reinsurerFound = "No Data Found";
    //    }
    //  },
    //    error => this.creditorFound = 'Problem with the service. Please try again after sometime'
    //  );
  }

  validateForm() {
    this.isformValid = true;
    this.isFormSubmitted = true;
    if (this.reinsurerService.formData.reinsurerName == '' ||
      this.reinsurerService.formData.elStatusId == null ||
      this.reinsurerService.formData.tpStatusId == null) {
      this.isformValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
    }
    return this.isformValid;
  }
  onSubmit() {
    if (this.validateForm()) {
      if (this.reinsurerNote.comment != '') {
        if (confirm("Comment / Note has been written but not Added. Do you want to save your Comment / Note ?")) {
          this.reinsurerNote.userId = '';
          this.reinsurerService.formData.reinsurerNotes.push(this.reinsurerNote);
        }
      }
      this.reinsurerService.saveOrUpdateReinsurer().subscribe(res => {
        if (this.reinsurerService.formData.id == null) {
          this.notificationService.printSuccessMessage('Saved Successfully');
          this.resetForm();
          if (parseInt(res) > 0) {
            this.id = res;
            this.setLoadData(this.id);
          }
        }
        else {
          this.notificationService.printSuccessMessage('Updated Successfully');
          if (parseInt(res.id) > 0) {
            this.id = res.id;
            this.setLoadData(this.id);
          }
        }

        this.loading = false;

        if (this.form.submitted) {
          if (this.isShowSearchButton) {
            this.router.navigate(['/reinsurer/edit/' + this.id], { queryParams: { isSearchFlag: true } });
          }
          else {
            this.router.navigate(['/reinsurer/edit/' + this.id]);
          }
        }

        this.isFormSubmitted = true;
        this.formValidityFlagLoaded.next();
        this.form.form.markAsPristine();
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      )
    }
  }

  addNoteValidation() {
    this.isValid = true;
    this.isFormSubmitted = true;

    if (this.reinsurerNote.comment == '') {
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }
    return this.isValid;
  }


  addNotes() {
    if (this.addNoteValidation()) {
      this.reinsurerService.formData.reinsurerNotes.push(this.reinsurerNote);
      this.initialReinsurerNote();
      this.refreshGrid();
    }
  }

  initialReinsurerNote() {
    this.reinsurerNote = {
      id: null,
      reinsurerID: this.reinsurerService.formData != undefined ? this.reinsurerService.formData.id : null,
      userId: this.securityService.getUserData().id.toString() + " - " + this.securityService.getUserData().claimsHandlers,
      isNewAdded: true,
      comment: '',
      commentDate: new Date()
    };
  }

  refreshGrid() {
    if (this.reinsuranceNotesTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  refresh(): void {
    if (confirm('Are you sure want to reset this page?')) {
      this.initializeForm();
    }
  }

  navigateToSearch(): void {
    this.router.navigate(['/search-participant']);
  }

  resetParentReinsurer() {
    this.reinsurerService.formData.parentReinsurerId = null;
    this.reinsurerService.formData.parentReinsurerName = '';
    this.reinsurerService.formData.parentReinsurerELStatusId = null;
    this.reinsurerService.formData.parentReinsurerTPStatusId = null;
    this.reinsurerService.formData.parentReinsurerAddressLine1 = '';
    this.reinsurerService.formData.parentReinsurerAddressLine2 = '';
    this.reinsurerService.formData.parentReinsurerCountry = '';
    this.reinsurerService.formData.parentReinsurerTown = '';
    this.reinsurerService.formData.parentReinsurerPostCode = '';
  }

  searchReinsurerName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((searchText) => this.reinsurerService.searchReinsurerName(searchText)),
      tap(() => this.searching = false)
    )

  getReinsurerDetailsByName(obj) {
    if (obj.item) {
      console.log("Selected Reinsurer Name =  " + obj.item);
      this.getReinsurerByName(obj.item);
    }
  }

  getReinsurerByName(reinsurerName) {
    this.reinsurerService.getReinsurerByName(reinsurerName).subscribe(res => {
      if (res != null) {
        this.reinsurerService.formData.parentReinsurerId = res.id;
        this.reinsurerService.formData.parentReinsurerName = res.reinsurerName;
        this.reinsurerService.formData.parentReinsurerELStatusId = res.elStatusId;
        this.reinsurerService.formData.parentReinsurerTPStatusId = res.tpStatusId;
        this.reinsurerService.formData.parentReinsurerAddressLine1 = res.addressLine1;
        this.reinsurerService.formData.parentReinsurerAddressLine2 = res.addressLine2;
        this.reinsurerService.formData.parentReinsurerCountry = res.country;
        this.reinsurerService.formData.parentReinsurerTown = res.town;
        this.reinsurerService.formData.parentReinsurerPostCode = res.postCode;
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IClaims } from '../../models/claims.model';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { ISearchClaimVM } from '../../models/search-claim.model';
import { ClaimHandler } from '../../components/add-participant/claim-handler/participant-claim-handler.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ClaimHandlerService {

  private claimHandlerUrl: string = "/api/claimhandler/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.claimHandlerUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.claimHandlerUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.claimHandlerUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.claimHandlerUrl);

  }

  //getClaims(filterCriteria: ISearchClaimVM) {
  //  let url = this.claimHandlerUrl + "search";

  //  return this.service.get(url, filterCriteria).pipe<IClaims>(tap((response: any) => {
  //    return response;
  //  }));
  //}

  getClaimHandlers(organisationId: any) {    
    let url = this.claimHandlerUrl;
    return this.dataService.get(url, { organisationId: organisationId });
  }
  getClaimHandlersByOrganisationId(organisationId: any, includeInActive: boolean) {
    let url = this.claimHandlerUrl + "getClaimHandlerByOrganisationId";
    return this.dataService.get(url, { organisationId: organisationId, includeInActive: includeInActive });
  }
  getClaimHandler(claimHandlerId: any) {
    let url = this.claimHandlerUrl +"getClaimHandlerById";
    return this.dataService.get(url, { claimHandlerId: claimHandlerId });
  }

  postClaimHandler(claimHandler: ClaimHandler) {


    let url = this.claimHandlerUrl + "createClaimHandler";
    let body = JSON.stringify(claimHandler);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }

    return this.http.post<any>(url, claimHandler, options);
    //  .subscribe(data => {
    //  //console.log("claimHandler Data", data);
    //});

    ////let url = this.claimHandlerUrl;
    //let url = 'http://localhost:59328/api/claimhandler';
    ////return this.dataService.post(url, claimHandler).subscribe(response => {
    ////  return response;
    ////});
    //return this.dataService.post(url, { claimHandler });
  }

  putClaimHandler(claimHandler: ClaimHandler) {

    let url = this.claimHandlerUrl + "updateClaimHandler";
    let body = JSON.stringify(claimHandler);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }
    return this.http.put<any>(url, claimHandler, options);
    //  .subscribe(data => {
    //  return data;
    //});
  }

}

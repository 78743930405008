export class ReinsuranceWorkflow {
  claimStatus: any[];
  claimStatusValues: any[];
  notificationStatus: any[];
  notificationStatusValues: any[];
  recoverableMovement: boolean;
  reinsurerId: number;
  calculationOverride: boolean;
  recoverableMovementValue: number;
  calculationOverrideValue: number;

}

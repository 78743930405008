import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { SecurityService } from './security.service';
import * as decode from 'jwt-decode';

@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public securityService: SecurityService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {

    
  //  let expectedRoleArray = route.data;
  //  expectedRoleArray = expectedRoleArray.expectedRole;

   // const token = localStorage.getItem('jwt');

    // decode the token to get its payload
   // const tokenPayload = decode(token);

  //  let expectedRole = '';

    //for (let i = 0; i < expectedRoleArray.length; i++) {
    //  if (expectedRoleArray[i] == tokenPayload.role) {
    //    console.log("Roles Matched");
    //    expectedRole = tokenPayload.role;
    //  }
    //}
    let isAccessible: boolean = false;
    let expectedPagePermission = route.data;
    let pagePermissionId = expectedPagePermission.userPermissionsId;
    let permittedAction = expectedPagePermission.permission;
    if ((pagePermissionId == '1135' || pagePermissionId == '1136' || pagePermissionId == '1137') && permittedAction != undefined) {      
      isAccessible = this.securityService.getAccessAndPermissionOnPage(pagePermissionId.toString(), permittedAction.toString());
    }
    else {
      isAccessible = this.securityService.getAccessOnPage(pagePermissionId.toString());
    }

    if (this.securityService.IsAuthorized && isAccessible) {
      console.log("User permitted to access the route");
      return true;
    }
    else {
      this.router.navigate(['access-denied']);
      return false;
    }
    //this.router.navigate(['login']);
  }
}

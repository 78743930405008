import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentBordereauxService } from '../../../shared/services/payment-bordereaux.service'
import { IPaymentBordereaux } from '../../../shared/models/payment-bordereaux.model';
import { PaymentBordereaux } from '../../../shared/models/search-payment-bordereaux.model';


@Injectable({
  providedIn: 'root'
})
export class PayBACSService {


  constructor(private paymentBordereauxService: PaymentBordereauxService) { }



  searchPayBACSPayments(searchFilter: PaymentBordereaux): any {
    return this.paymentBordereauxService.searchChequePaymentsBordereaux(searchFilter);
  }

  approvePayBACSPayments(paymentIds, loggedInUserId): any {
    return this.paymentBordereauxService.approveBACSPaymentsBordereaux(paymentIds, loggedInUserId);
  }

  rejectPayBACSPayments(paymentIds): any {
    return this.paymentBordereauxService.rejectBACSPaymentsBordereaux(paymentIds);
  }
}

import { Injectable } from '@angular/core';
import { IExcessDetail } from './excess-detail/excess-details.model'
import { IPolicyholder } from './policyholder.model'
import { ILimitsOfIndemnity } from './limit-of-indemnity/limits-of-indemnity.model';
import { ILongTermAgreement } from './long-term-agreement/long-term-agreement.model'
import { IClaimLevelSchemeCreditor } from './claim-level-scheme-creditor/claim-level-scheme-creditor.model'
import { IPolicyNote } from './policy-note/policy-note.model'
import { PolicyService } from '../../../shared/services/policy.service';
import { Observable } from 'rxjs';
import { Icreditorholder } from '../../../components/add-participant/creditorholder/creditorholder.model';

@Injectable({
  providedIn: 'root'
})
export class PolicyholderService {

  activeTabName: string = "";
  formData: IPolicyholder;
  excessDetails: IExcessDetail[] = [];
  limitofIndemnity: ILimitsOfIndemnity[] = [];
  longTermAgreement: ILongTermAgreement[] = [];
  claimLevelSchemeCreditor: IClaimLevelSchemeCreditor[] = [];
  policyNotes: IPolicyNote[] = [];
  creditor: Icreditorholder;

  private policyUrl: string = '';

  constructor(private policyService: PolicyService) {
  }

  createPolicyFolder(id: number): any {
    return this.policyService.createPolicyFolder(id);
  }

  saveOrUpdatePolicy(): any {
    //this.decorateRequest();
    
    if (this.formData.policyId > 0) {
      var body = {
        ...this.formData,
        excessDetails: this.excessDetails,
        limitsOfIndemnityDetails: this.limitofIndemnity,
        longTermAgreements: this.longTermAgreement,
        claimLevelSchemeCreditors: this.claimLevelSchemeCreditor,
        policyNotes: this.policyNotes
      };
      return this.policyService.updatePolicy(body);
    }
    else {
      var body = {
        ...this.formData,
        excessDetails: this.excessDetails.filter(a => !a.isClientDeletd),
        limitsOfIndemnityDetails: this.limitofIndemnity.filter(a => !a.isClientDeletd),
        longTermAgreements: this.longTermAgreement.filter(a => !a.isClientDeletd),
        claimLevelSchemeCreditors: this.claimLevelSchemeCreditor.filter(a => !a.isClientDeletd),
        policyNotes: this.policyNotes
      };
      return this.policyService.createPolicy(body);
    }
  }

  getPolicyByID(id: number): any {
    return this.policyService.getPolicyById(id);
  }

  //decorateRequest() {
  //  this.claimLevelSchemeCreditor.forEach(function (item, index, object) {
  //    delete item.schemeCreditorName
  //    delete item.schemeCreditorStatus
  //  });

    //if (this.formData.coverTo != null) {
    //  var from = this.formData.coverTo.split("-");
    //  const newDate: Date = new Date(from[2], from[1] - 1, from[0]);
    //  newDate.setDate(newDate.getDate() + 1)
    //  this.formData.coverTo = newDate;
    //}

  //}

}

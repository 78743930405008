import { Injectable } from '@angular/core';
import { ValuationService } from '../../../shared/services/valuation.service'
import { Observable } from 'rxjs';
import { IValuation } from './claim-valuation.model'
import { IValuationSummary } from './claim-valuation-summary.model'


@Injectable({
  providedIn: 'root'
})
export class ClaimValuationService {

  formData: IValuation;
  ivaluationSummary: IValuationSummary[] = [];
  constructor(private valuationService: ValuationService) {
  }

  getValuationByClaimId(id: number): any {
    return this.valuationService.getValuationByClaimId(id);
  }


  saveOrUpdateValuation(): any {
    var body = {
      ...this.formData,
      valuationNotes: this.ivaluationSummary
    };
    if (this.formData.id > 0)
      return this.valuationService.updateValuation(body);
    else
      return this.valuationService.createValuation(body);
  }

  getValuationExcessApplicable(claimId) {
    return this.valuationService.getValuationExcessApplicable(claimId);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { AggregatedClaimsReportRequestModel } from '../../../models/aggregated-claims-report-request.model';
import { ReportsService } from '../../../shared/services/reports.service';
import { NotificationService } from '../../../shared/services/notification.service';


@Injectable({
  providedIn: 'root'
})
export class AggregatedClaimsReportService {

  formDataInput: AggregatedClaimsReportRequestModel;

  constructor(private reportsService: ReportsService,
    private notificationService: NotificationService) { }

  getAggregatedClaimsReport(): any {
    var body = {
      ...this.formDataInput

    };

    this.reportsService.getAggregatedClaimsReport(this.formDataInput).subscribe((response: any) => {
      if (response != null && response.length > 0) {

        const aggregatedClaimsReportHeader = [
          "MMI Claim Reference",
          "Insured Title",
          "Claim Type",
          "Perpetrator",
          "Location",
          "Exposure Period(s)",
          "Claim Status",
          "Open Date",
          "Date Last Re-Opened",
          "Settled Date",
          "MMI Paid Total",
          "MMI Reserve Total",
          "MMI Incurred Total",
          "Excess Paid (Total)"
        ];

        const aggregatedClaimsReportFooter = [
          "Summary",
          "Claim Count",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "MMI Paid Total",
          "MMI Reserve Total",
          "MMI Incurred Total",
          "Excess Paid (Total)"
        ];

        let aggregatedClaimsReportHeaderData: any[] = [];
        let aggregatedClaimsReportFooterData: any[] = [];

        //Create workbook and worksheet
        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Aggregated Claims Report');

        let nextPrintableRow = 1;

        response.forEach((item, index) => {

          let totalRows = 0;

          aggregatedClaimsReportHeaderData = [];
          aggregatedClaimsReportFooterData = [];

          let title = item["location"] != null ? "Location " + item["location"] : "Perpetrator " + item["perpetrator"];

          if (item.aggregatedClaimsModel != null && item.aggregatedClaimsModel.length > 0) {
            item.aggregatedClaimsModel.forEach(element => {
              aggregatedClaimsReportHeaderData.push([
                element["mmiClaimReference"],
                element["insuredTitle"],
                element["claimType"],
                element["perpetrator"] == null ? "" : element["perpetrator"],
                element["location"] == null ? "" : element["location"],
                element["exposurePeriods"] == null ? "" : element["exposurePeriods"],
                element["claimStatus"],
                element["openDate"] == null ? "" : moment(element["openDate"]).format('DD/MM/YYYY'),
                element["dateLastReOpened"] == null ? "" : moment(element["dateLastReOpened"]).format('DD/MM/YYYY'),
                element["settledDate"] == null ? "" : moment(element["settledDate"]).format('DD/MM/YYYY'),
                element["mmiPaidTotal"],
                element["mmiReserveTotal"],
                element["mmiIncurredTotal"],
                element["excessPaidTotal"]
              ]);

              totalRows += 1;
            });
          }

          if (item["openClaimCount"] > 0) {
            aggregatedClaimsReportFooterData.push([
              "Open",
              item["openClaimCount"], "", "", "", "", "", "", "", "",
              item["openMMIPaidTotal"],
              item["openMMIReserveTotal"],
              item["openMMIIncurredTotal"],
              item["openExcessPaidTotal"],
            ]);

            totalRows += 1;
          }

          if (item["settledClaimCount"] > 0) {
            aggregatedClaimsReportFooterData.push([
              "Settled",
              item["settledClaimCount"], "", "", "", "", "", "", "", "",
              item["settledMMIPaidTotal"],
              item["settledMMIReserveTotal"],
              item["settledMMIIncurredTotal"],
              item["settledExcessPaidTotal"],
            ]);

            totalRows += 1;
          }

          if (item["totalClaimCount"]) {
            aggregatedClaimsReportFooterData.push([
              "Grand Total",
              item["totalClaimCount"], "", "", "", "", "", "", "", "",
              item["mmiPaidGrandTotal"],
              item["mmiReserveGrandTotal"],
              item["mmiIncurredGrandTotal"],
              item["excessPaidGrandTotal"],
            ]);

            totalRows += 1;
          }

          //Add Row and formatting
          let titleRow = worksheet.addRow([title]);
          titleRow.font = { bold: true, size: 16 };
          titleRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);
          });
          worksheet.mergeCells('A"' + nextPrintableRow + '":N"' + (nextPrintableRow + 1) + '"');
          //Blank Row 
          worksheet.addRow([]);

          let aggregatedClaimsReportHeaderRow = worksheet.addRow(aggregatedClaimsReportHeader);
          // Cell Style : Fill and Border
          aggregatedClaimsReportHeaderRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

          })
          aggregatedClaimsReportHeaderRow.font = { bold: true };
          aggregatedClaimsReportHeaderData.forEach(d => {
            let row = worksheet.addRow(d);
            row.eachCell((cell, number) => {
              this.setBorderExcelCell(cell);
            })
            this.setCellFormatOfAggregatedClaimsReportHeader(row);
          });

          let aggregatedClaimsReportFooterRow = worksheet.addRow(aggregatedClaimsReportFooter);
          // Cell Style : Fill and Border
          aggregatedClaimsReportFooterRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

          })
          aggregatedClaimsReportFooterRow.font = { bold: true };
          aggregatedClaimsReportFooterData.forEach(d => {
            let row = worksheet.addRow(d);
            row.eachCell((cell, number) => {
              this.fillBackGroundInExcellCell(cell);
              this.setBorderExcelCell(cell);
              if (number == 1)
                cell.font = { bold: true };
            })
            this.setCellFormatOfAggregatedClaimsReportHeader(row);
          });

          //Blank Row 
          worksheet.addRow([]);
          worksheet.addRow([]);
          nextPrintableRow += totalRows + 7;
        });

        for (var i = 1; i <= worksheet.columnCount; i++) {
          worksheet.getColumn(i).width = 25;
        }

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, 'Aggregated-Claims-Report_' + Math.random() + '.xlsx');
        });

      }
      else {
        this.notificationService.printWarningMessage("No data is available for the Report");
      }
    });
  }

  setCellFormatOfAggregatedClaimsReportHeader(row: ExcelProper.Row) {
    row.findCell(11).value >= 0 ? row.findCell(11).numFmt = '£###,###,##0.00;' : row.findCell(11).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(12).value >= 0 ? row.findCell(12).numFmt = '£###,###,##0.00;' : row.findCell(12).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(13).value >= 0 ? row.findCell(13).numFmt = '£###,###,##0.00;' : row.findCell(13).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(14).value >= 0 ? row.findCell(14).numFmt = '£###,###,##0.00;' : row.findCell(14).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(4).alignment = { wrapText: true };
    row.findCell(5).alignment = { wrapText: true };
    row.findCell(6).alignment = { wrapText: true };
  }

  setBorderExcelCell(cell: any) {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    cell.alignment = { vertical: 'middle' };
  }

  fillBackGroundInExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00C4C4C4' },
      bgColor: { argb: 'FF0000FF' }
    }
  }

}

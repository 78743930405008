import { Injectable } from '@angular/core';

import { PaymentBordereauxService } from 'src/app/shared/services/payment-bordereaux.service'
import { Observable, of } from 'rxjs';
import { HandlingOrganisationService } from 'src/app/shared/services/handling-organisation.service'
import { PolicyService } from 'src/app/shared/services/policy.service'


@Injectable({
  providedIn: 'root'
})
export class SearchPaymentBordereauxService {

  constructor(private handlingOrganizationService: HandlingOrganisationService,
    private paymentBordereauxService: PaymentBordereauxService,
    private policyService: PolicyService) {

  }

  getHandlingOrganization(): Observable<any> {
    return this.handlingOrganizationService.getHandlingOrganization();
  }

  getPaymentBordereaux(filter: any): any {
    return this.paymentBordereauxService.getPaymentBordereaux(filter);
  }

  approvePayments(chequeNo: number, paymentIds, loggedInUserId): any {
    return this.paymentBordereauxService.approvePayments(chequeNo, paymentIds, loggedInUserId);
  }

  rejectPayments(paymentIds): any {
    return this.paymentBordereauxService.rejectPayments(paymentIds);
  }

  searchPolicyholderName(term: string) {
    if (!term || term.length < 3) { return of([]); }
    return this.policyService.getPolicyholderName(term);
  }

  searchPayeeName(term: string) {
    if (!term || term.length < 3) { return of([]); }
    return this.paymentBordereauxService.getPayeeName(term);
  }

  getFinanceEFileUrl() {
    return this.paymentBordereauxService.getFinanceEFileUrl();
  }

  updateUnderQueryPaymentBordereaux(modelQueries): any {
    return this.paymentBordereauxService.updateUnderQueryPaymentBordereaux(modelQueries);
  }

}

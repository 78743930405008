import { Component, OnInit, Input, OnDestroy, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PolicyholderService } from '../policyholder.service'
import { NgForm } from '@angular/forms';
import { ILimitsOfIndemnity } from './limits-of-indemnity.model';
import { NotificationService } from '../../../../shared/services/notification.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as _ from "lodash";
import { BaseComponentComponent } from '../../../base-component/base-component.component';
import { SecurityService } from '../../../../shared/services/security.service';

@Component({
  selector: 'app-limit-of-indemnity',
  templateUrl: './limit-of-indemnity.component.html',
  styleUrls: ['./limit-of-indemnity.component.css']
})

export class LimitOfIndemnityComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked {

  @ViewChild(NgForm, { static: false })
  public form: NgForm;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('limitOfIndemnityTable') limitOfIndemnityTable: ElementRef;
  dtOptions: DataTables.Settings = {};
  formData: ILimitsOfIndemnity;
  ItemIndex: number;
  @Input() isValid: boolean = true;
  customMessage: string;
  @Input() formPanel: boolean = false;
  isGridLoaded: boolean = false;
  dtTrigger = new Subject();
  tempDate: any[];
  isLIEdit: boolean = false;
  limitOfIndemnityLocalData: any[]; 

    constructor(
      private policyService: PolicyholderService,
      private notificationService: NotificationService, securityService: SecurityService) {
      super(securityService, 1135);
      this.ItemIndex = null;

      this.limitOfIndemnityLocalData = [];
  }
  
  ngOnInit() {
   this.limitOfIndemnityLocalData = this.policyService.limitofIndemnity;
   this.LoadFrm();
   this.dtOptions = {
     pagingType: 'full_numbers',
     pageLength: 10,
     searching: false,
     columnDefs: [
       { type: 'date-uk', "targets": 2 },
       { type: 'date-uk', "targets": 3 },
       { "orderable": false, "targets": 4 }
     ]
   };
  }

  refreshForm() {
    this.limitOfIndemnityLocalData = this.policyService.limitofIndemnity;
    this.refreshGrid();
    this.form.form.markAsPristine();
  }

  LoadFrm(): void {
    this.ItemIndex = null;
    this.isLIEdit = false;
    this.formData = {
      policyLimitsOfIndemnityId: null,
      policyId: this.policyService.formData.policyId,
      limitsOfIndemnityAmount: null,
      dateFrom: null,
      dateTo: null,
      isClientDeletd: null,
      isDirty: null,
      isNewAdded: null
    }
  }

  notDeletedLimitofIndemnity() {
    let c = _.filter(this.limitOfIndemnityLocalData, function (o) { return o.isClientDeletd == false; });
    return c;
  }

  onEditlimitsOfIndemnityItem(indexItem) {
    this.formData = Object.assign({}, this.limitOfIndemnityLocalData.filter(a => !a.isClientDeletd)[indexItem]);
    this.ItemIndex = indexItem;
    this.isLIEdit = true;
  }

  onDeletelimitsOfIndemnityItem(i: number) {
    this.limitOfIndemnityLocalData.filter(a => !a.isClientDeletd)[i].isClientDeletd = true;
    this.policyService.limitofIndemnity = this.limitOfIndemnityLocalData;
    this.form.form.markAsDirty();
    this.refreshGrid();
  }

  onSubmit(form: NgForm) {
    if (this.validateForm(form.value)) {
      if (this.ItemIndex == null) {
        form.value.isNewAdded = true;
        form.value.isClientDeletd = false;
        form.value.policyId = this.policyService.formData.policyId;
        this.limitOfIndemnityLocalData.push(form.value);
      }
      else {
        form.value.isDirty = true;
        this.isLIEdit = false;
        form.value.policyId = this.policyService.formData.policyId;
        let temp = this.limitOfIndemnityLocalData.filter(a => !a.isClientDeletd)[this.ItemIndex];
        let index = this.limitOfIndemnityLocalData.findIndex(a => a == temp);
        this.limitOfIndemnityLocalData[index] = form.value;

        this.ItemIndex = null;
      }
      this.policyService.limitofIndemnity = this.limitOfIndemnityLocalData;
      this.LoadFrm();
      this.refreshGrid();
      form.reset();
      this.formPanel = false;
    }
  }

  validateForm(formData: ILimitsOfIndemnity) {
    this.isValid = true;
    this.customMessage = null;

    if (formData.limitsOfIndemnityAmount == null) {
      this.isValid = false;
      this.policyService.activeTabName = "limitsOfIndemnityTab";
      return false;
    }

    if (formData.dateFrom == null) {
      this.isValid = false;
      this.policyService.activeTabName = "limitsOfIndemnityTab";
      return false;
    }

    if (formData.dateTo == null) {
      this.isValid = false;
      this.policyService.activeTabName = "limitsOfIndemnityTab";
      return false;
    }

    if (this.policyService.formData.coverFrom == null) {
      this.isValid = false;
      this.policyService.activeTabName = "limitsOfIndemnityTab";
      this.notificationService.printWarningMessage("Please enter Cover From");
      return false;
    }

    if (this.policyService.formData.coverTo == null) {
      this.isValid = false;
      this.policyService.activeTabName = "limitsOfIndemnityTab";
      this.notificationService.printWarningMessage("Please enter Cover To");
      return false;
    }

    if (this.getDateOnly(formData.dateFrom) < this.getDateOnly(this.policyService.formData.coverFrom)) {
      this.notificationService.printWarningMessage("Date From should be greater than/equal to Cover From");
      this.policyService.activeTabName = "limitsOfIndemnityTab";
      this.isValid = false;
      return false;
    }

    if (this.getDateOnly(formData.dateTo) > (this.getDateOnly(this.policyService.formData.coverTo))) {
      this.notificationService.printWarningMessage("Date To should be less than Cover To");
      this.policyService.activeTabName = "limitsOfIndemnityTab";
      this.isValid = false;
      return false;
    }

    if (this.getDateOnly(formData.dateFrom) >= this.getDateOnly(formData.dateTo)) {
      this.isValid = false;
      this.policyService.activeTabName = "limitsOfIndemnityTab";
      this.notificationService.printWarningMessage("Date To should be greater than Date From");
      return false;
    }

    if (this.isLIEdit && this.limitOfIndemnityLocalData.length == 1) {
      this.isValid = true;
      this.policyService.activeTabName = "limitsOfIndemnityTab";
      return true;
    }

    let limitofIndemnityGridData;
    let index = this.ItemIndex;

    if (this.isLIEdit && this.limitOfIndemnityLocalData.length > 1) {
      limitofIndemnityGridData = this.limitOfIndemnityLocalData.filter(function (place, i) {
        return index != i;
      });
    } else {
      limitofIndemnityGridData = this.limitOfIndemnityLocalData;
    }

    limitofIndemnityGridData.forEach(a => {
      if (this.isValid) {
        //if ((this.getDateOnly(new Date(a.dateFrom)) <= this.getDateOnly(formData.dateFrom) && this.getDateOnly(new Date(a.dateTo)) >= this.getDateOnly(formData.dateFrom) && !a.isClientDeletd) ||
        //  (this.getDateOnly(new Date(a.dateFrom)) <= this.getDateOnly(formData.dateTo) && this.getDateOnly(new Date(a.dateTo)) >= this.getDateOnly(formData.dateTo) && !a.isClientDeletd))

        if ((!(this.getDateOnly(formData.dateFrom) > this.getDateOnly(a.dateTo) ||
          (this.getDateOnly(formData.dateFrom) < this.getDateOnly(a.dateFrom) &&
            this.getDateOnly(formData.dateTo) < this.getDateOnly(a.dateFrom)))) && !a.isClientDeletd) {
          this.notificationService.printWarningMessage("Overlapping dates are not allowed");
          this.isValid = false;
          this.policyService.activeTabName = "limitsOfIndemnityTab";
        }
      }
    });

    return this.isValid;
  }

  getDateOnly(date: Date) {
    if (typeof date == 'string')
      var newDate = new Date(date);
    else
      var newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  refreshGrid() {
    if (this.limitOfIndemnityTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}



import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { AddHandlingOrganisationService } from './handlingorganisation.service';
import { NotificationService } from '../../../shared/services/notification.service'
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentCanDeactivate } from '../../../shared/guard/unsaved-changes.guard';
import { Observable, Subject } from 'rxjs';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { SecurityService } from '../../../shared/services/security.service';
import { enumPermissions } from '../../../shared/models/common.enum';
import { ClaimHandlerService } from '../../../shared/services/claim-handler.service'
import { createTrue } from 'typescript';

@Component({
  selector: 'app-handlingorganisation',
  templateUrl: './handlingorganisation.component.html',
  styleUrls: ['./handlingorganisation.component.css']
})
export class HandlingorganisationComponent extends BaseComponentComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild(NgForm, { static: false })
  private form: NgForm;
  isShowSearchButton: boolean = false;
  isInEditMode: boolean = false;
  isValid: boolean = true;
  title: string;
  isDisabledFields: boolean = false;
  public autoClaimsHandlers: any[];

  saveMethod: any;
  isFormSubmitted: boolean = true;
  public formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean { return false; };
  isSettledButtonClicked: boolean = false;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    this.saveMethod = this.onSubmit;
  
    let hasPendingChanges: boolean = !this.form.touched;
    return hasPendingChanges;
  }

  isMMIUser: boolean = false;
  canReadOnly: boolean = false;
  canEdit: boolean = false;
  canAdd: boolean = false;
  canDelete: boolean = false;

  constructor(
    private handlingOrganisationService: AddHandlingOrganisationService,
    private notificationService: NotificationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    securityService: SecurityService,
    private claimHandlerService: ClaimHandlerService
  ) {
    super(securityService, 1137);
  }


  ngOnInit(): void {
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
    }
    this.bindBACSDetailPermission();
    let id = this.activeRoute.snapshot.paramMap.get('handlingOrganisationId');
    let isSearch = Boolean(this.activeRoute.snapshot.queryParams.isSearchFlag);
    let isEditFlag = this.activeRoute.snapshot.queryParams.editFlag;

    if (isSearch) {
      this.isShowSearchButton = true;
    }
    else {
      this.isShowSearchButton = false;

    }
    if (isEditFlag == "true") {
      this.isDisabledFields = true;
    }
    else {
      this.isDisabledFields = false;
    }
    if (id == null) {
      this.resetForm();
      this.isInEditMode = false;
    }
    else {
      this.initializeForm(id)
    }
    if (this.isInEditMode) {
      this.title = "Edit";
    } else { this.title = "Add"; }
    this.getClaimHandlers(id);
  }

  bindBACSDetailPermission() {
    let userPermissionID: number = 1138;
    var userDetails = this.securityService.getUserData().userRolePermissionModels;
    var data = userDetails.filter(c => c.userPermissionsId == userPermissionID);
    this.permissionId = data[0].permission;
    if (this.permissionId == enumPermissions.Read) {
      this.canReadOnly = true;
    } else if (this.permissionId == enumPermissions.Edit) {
      this.canEdit = true;
    }
    else if (this.permissionId == enumPermissions.Add) {
      this.canAdd = true;
    }
    else if (this.permissionId == enumPermissions.Delete) {
      this.canDelete = true;
    }
  }

  onSubmit() {
    //if (this.form.invalid) {
    //  this.form.control.
    //}
    //this.form.onSubmit;
    //this.form.reset;
    //alert(this.form.submitted);
    
    if (this.validateForm()) {      
      if (this.handlingOrganisationService.formData.id > 0) {
        this.handlingOrganisationService.updateHandlingOrganisation().subscribe(res => {
          this.notificationService.printSuccessMessage('Handling Organisation details updated successfully');
          this.isInEditMode = true;
          this.isFormSubmitted = true;
          this.formValidityFlagLoaded.next();
          this.bindBACSDetailPermission();
          this.form.form.markAsUntouched();
        },
          error => {
            this.notificationService.printErrorMessage("Error occured  in saving handling Organisation Details");
          })

      }
      else {
        this.form.form.markAsUntouched();
        this.handlingOrganisationService.createHandlingOrganisation().subscribe(res => {
          this.notificationService.printSuccessMessage('Handling Organisation details saved successfully');
          this.router.navigate(['/handling-organisation/edit/' + res.id]);
        }),
          error => {
            this.notificationService.printErrorMessage("Error occured  in updating handling Organisation Details");
          }
      }

    }
  }

  validateForm() {    
    this.isValid = true;
    this.isFormSubmitted = true;
    if (this.handlingOrganisationService.formData.handlingOrganisationName == '' || this.handlingOrganisationService.formData.handlingOrganisationName == null ||
      this.handlingOrganisationService.formData.accountNumber == '' || this.handlingOrganisationService.formData.accountNumber == null ||
      this.handlingOrganisationService.formData.bankName == '' || this.handlingOrganisationService.formData.bankName == null ||
      this.handlingOrganisationService.formData.bacsAddressFirstline == '' || this.handlingOrganisationService.formData.bacsAddressFirstline == null||
      this.handlingOrganisationService.formData.bacsAddressPostCode == '' || this.handlingOrganisationService.formData.bacsAddressPostCode == null ||
      this.handlingOrganisationService.formData.keyContact == '' || this.handlingOrganisationService.formData.keyContact == null ||
      this.handlingOrganisationService.formData.bacsCountry == '' || this.handlingOrganisationService.formData.bacsCountry == null||
      this.handlingOrganisationService.formData.bacsAddressTown == '' || this.handlingOrganisationService.formData.bacsAddressTown == null||
       this.handlingOrganisationService.formData.country == '' || this.handlingOrganisationService.formData.country == null ||
      this.handlingOrganisationService.formData.firstline == '' || this.handlingOrganisationService.formData.firstline == null ||
      this.handlingOrganisationService.formData.sortCode == '' || this.handlingOrganisationService.formData.sortCode == null ||
      this.handlingOrganisationService.formData.town == '' || this.handlingOrganisationService.formData.town == null ||
      this.handlingOrganisationService.formData.postCode == '' || this.handlingOrganisationService.formData.postCode == null||
      !this.handlingOrganisationService.formData.bacsEmail || this.handlingOrganisationService.formData.bacsEmail == null)
     {
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      //&& !this.handlingOrganisationService.formData.autoEmail
      return this.isValid = false;
    }
    if (this.handlingOrganisationService.formData.accountNumber.length < 8) {
      this.notificationService.printWarningMessage("Please enter 8 number account number");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
    }
    if (this.handlingOrganisationService.formData.sortCode.length < 6) {
      this.notificationService.printWarningMessage("Please enter 6 number sort code in format");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
    }
    //this.form.controls[0].setErrors({incorrect:true})
    return this.isValid;
  }

  resetForm(form?: NgForm) {
    if (form = null)
      form.resetForm();
    this.handlingOrganisationService.formData = {
      id: null,
      handlingOrganisationName: '',
      keyContact: '',
      bankName: '',
      sortCode: null,
      accountNumber: null,
      bacsAddressFirstline: '',
      bacsAddressSecondine: '',
      bacsAddressTown: '',
      bacsAddressPostCode: '',
      firstline: '',
      secondLine: '',
      town: '',
      postCode: '',
      country: '',
      bacsCountry: '',
      autoEmail: '',
      bacsEmail: '',
      autoClaimsHandler: null
    };
  }

  initializeForm(id) {
    this.handlingOrganisationService.getHandlingOrganisationById(parseInt(id)).subscribe(res => {
      this.handlingOrganisationService.formData = res;
      this.isInEditMode = true;
      this.form.form.markAsUntouched();
    },
      error => {
        this.notificationService.printErrorMessage('Error in fetching Handling Organisation Details');
      }
    );
  }

  refresh(): void {
    //window.location.reload();
    let id = this.activeRoute.snapshot.paramMap.get('handlingOrganisationId');
    if (id == null) {
      this.resetForm();
      this.isInEditMode = false;
    }
    else {
      this.initializeForm(id);
      if (this.isInEditMode) {
        this.title = "Edit";
      } else { this.title = "Add"; }
      this.getClaimHandlers(id);
    }
    this.form.form.markAsUntouched();
  }
  navigateToSearch(): void {
    this.router.navigate(['/search-participant']);
  }

  getClaimHandlers(organisationId) {
    if (organisationId != null) {
      this.claimHandlerService.getClaimHandlers(organisationId)
        .subscribe((reponse: any) => {
          this.autoClaimsHandlers = reponse;
        });
    }
  }
}

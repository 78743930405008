
export class ClaimHandler {
  ID: number;
  handlerId: number;
  organisationId: number;  
  claimsHandlers: string;
  handlingAuthority: number;
  paymentAuthority: number;
  certifiedAsFitAndProper: number;
  superUser: number;
  //status: string;
  jobTitle: string;
  telephoneNumber: string;
  email: string;
  isDelete: boolean = false;

  userRoleId: number;
  userRoleName: string;
  claimsStage1ApprovalAuthority: number;
  financeApprovalAuthority: number;
}

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandlingOrganisationService {


  private handlingOrganisationUrl: string = '/api/handlingOrganisation/';

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings!=undefined)
      this.handlingOrganisationUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.handlingOrganisationUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.handlingOrganisationUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.handlingOrganisationUrl);
  }

  getHandlingOrganisationById(id: number): any {
    let url = this.handlingOrganisationUrl + "getHandlingOrganisationById";

    return this.dataService.get(url, { id: id });
  }

  createHandlingOrganisation(data):any {

    let url = this.handlingOrganisationUrl +"createHandlingOrganisation";

    return this.dataService.post(url, data);

  }

  updateHandlingOrganisation(data): any {

    let url = this.handlingOrganisationUrl  + "updateHandlingOrganisation";

    return this.dataService.putWithId(url, data);
    }
  getAllHandlingOrganisations() {
        let url = 'http://localhost:59328/api/handlingorganisation';

      return this.dataService.get(this.handlingOrganisationUrl);
  }

  getHandlingOrganization(): Observable<any> {
    return this.http.get(`${this.handlingOrganisationUrl}`);
  }
}


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClaimDetailNotes } from './claim-detail-notes.model';
import { ClaimDetailNotesService } from '../../../shared/services/claimdetail-notes.service';
import { ClaimHandlingReviewNotes } from '../claim-detail-notes/claim-detail-review-notes.model';

@Injectable({
  providedIn: 'root'
})
export class AddClaimDetailNotesService {
  formParentData: ClaimDetailNotes;
 // formData: ClaimHandlingReviewNotes;


  constructor(private claimDetailNotesService: ClaimDetailNotesService) {
    //this.formData.notesDate = new Date();
    //this.formData.handlerID = 101;
  }

  //createClaimHandlingNotes(): any {
  //  var body = {
  //    ...this.formParentData
  //  };
  //  return this.claimDetailNotesService.createClaimHandlingNotes(body);
  //}


  getClaimHandlingNoteById(claimId: number): any {
    return this.claimDetailNotesService.getClaimHandlingNoteById(claimId);
  }

  updateClaimHandlingNotes(): any {
    var body = {
      ...this.formParentData
    };
    return this.claimDetailNotesService.updateClaimHandlingNotes(body);
  }

  //createClaimHandlingReviewNotesComments(data): any {
  //  //var body = {
  //  // data
  //  //};
  //  return this.claimDetailNotesService.createClaimHandlingReviewNotesComments(data);
  //}

  //getClaimHandlingReviewNotesCommentsById(claimHandlingNotesId: number): any {
  //  return this.claimDetailNotesService.getClaimHandlingReviewNotesCommentsById(claimHandlingNotesId);
  //}

  //postClaimHandlerNotes(claimHandler: ClaimDetailNotes) {
  //  return this.claimHandlerService.postClaimHandler(claimHandler);
  //}
}

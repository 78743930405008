import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';

import { ClaimRoutingModule } from './claim-routing.module';
import { ApportionmentComponent } from './apportionment/apportionment.component';
import { LitigationComponent } from './litigation/litigation.component';
//import { ClaimLitigationService } from './litigation/claim.litigation.service'
import { ClaimDetailsComponent } from './claim-details/claim-details.component';
import { CalculateApportionmentService } from './apportionment/apportionment.service';
//import { DatepickerPopupComponent } from '../common-components/datepicker/datepicker-popup.component'
//import { NgbDateCustomParserFormatter } from '../common-components/datepicker/dateformat'
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskModule } from 'ngx-mask';
import { ReinsuranceComponent } from './reinsurance/reinsurance.component';
import { ClaimReinsuranceComponent } from './claim-reinsurance/claim-reinsurance.component';
import { AddWorkflowItemComponent } from './add-workflow-item/add-workflow-item.component';


@NgModule({
  declarations: [
    ApportionmentComponent,
    LitigationComponent,
    ClaimDetailsComponent,
    PaymentDetailComponent,
    ReinsuranceComponent,
    ClaimReinsuranceComponent
  ],
  imports: [
    CommonModule,
    ClaimRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskModule,
    NgbTooltipModule
  ],
  providers: [CalculateApportionmentService]
})
export class ClaimModule { }

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ValuationService {


  private valuationUrl: string = "/api/valuation/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.valuationUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.valuationUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.valuationUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.valuationUrl);
  }

  getValuationByClaimId(id: number): any {
    let url = this.valuationUrl + "getValuationByCalimId";

    return this.dataService.get(url, { id: id });
  }

  createValuation(data): any {

    let url = this.valuationUrl + "createValuation";

    return this.dataService.post(url, data);

  }

  updateValuation(data): any {

    let url = this.valuationUrl + "updateValuation";

    return this.dataService.putWithId(url, data);
  }

  checkClaimHandlerAssigned(claimId): any {
    let url = this.valuationUrl + "checkClaimHandlerAssigned";
    return this.dataService.get(url, { claimId: claimId })
  }

  getValuationExcessApplicable(claimId: any) {
    let url = this.valuationUrl + "getValuationExcessApplicable";
    return this.dataService.get(url, { claimId: claimId });
  }
}


import { Component, OnInit, ViewChild } from '@angular/core';
import { BroadcastMessageService } from './broadcast-message.service';
import { NgForm } from '@angular/forms';
import { LookupService } from '../../../shared/services/lookup.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NotificationService } from '../../../shared/services/notification.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.css']
})
export class BroadcastMessageComponent implements OnInit {
  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  handlingOrganisationData: any[];
  isValid: boolean = true;

  constructor(private broadcastMessageService: BroadcastMessageService,
    private lookupService: LookupService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.bindHandlingOrganisations();
  }

  initializeForm() {

    this.broadcastMessageService.formData = {
      handlingOrganisations: [{ "id": 0, "text": "All" }],
      handlingOrganisationValues: [],
      emailSubject: '',
      emailMessage: '',
    }
  }

  bindHandlingOrganisations() {
    this.broadcastMessageService.getHandlingOrganization().subscribe(response => {
      if (response != null) {
        this.handlingOrganisationData = response.map(item => {
          let container: any = {};
          container.id = item.id;
          container.text = item.handlingOrganisationName;
          return container;
        })
        this.handlingOrganisationData.unshift({ "id": 0, "text": "All" });
      }
    });
  }

  dropdownSettingsHO: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  broadcast() {
    this.broadcastMessageService.formData.handlingOrganisationValues = _.map(this.broadcastMessageService.formData.handlingOrganisations, function (i: any) { return i.id });
    
    if (this.validateForm()) {      
      this.broadcastMessageService.broadcastMessageToUsers().subscribe(res => {
        if (res.emailSendCount > 0) {
          this.notificationService.printSuccessMessage('Message Broadcast Successfully');
        }
        else {
          this.notificationService.printWarningMessage('Message does not Broadcast');
        }
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      );
    } else {
      this.notificationService.printWarningMessage("Please select all mandatory fields");
    }
  }

  validateForm(): boolean {
    this.isValid = true;
    if (this.broadcastMessageService.formData.handlingOrganisationValues == null || this.broadcastMessageService.formData.handlingOrganisationValues.length == 0 ||
      this.broadcastMessageService.formData.emailSubject == null || this.broadcastMessageService.formData.emailSubject.trim() == '' ||
      this.broadcastMessageService.formData.emailMessage == null || this.broadcastMessageService.formData.emailMessage.trim() == ''
    ) {
      this.isValid = false;
      return this.isValid;
    }

    return this.isValid;
  }

  resetform() {
    this.initializeForm();
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, debounce } from 'rxjs/operators';
import { SearchPolicyVM } from '../../../models/search-policy.model';
import { lineOfBusiness, policyType, enumYesNo, enumLitigatedType, enumReportsType } from '../../../shared/models/common.enum';
import { IPager } from '../../../shared/models/pager.model';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SecurityService } from '../../../shared/services/security.service';
import { ReportingPeriodPaymentsService } from './reporting-period-payments.service';


@Component({
  selector: 'app-reporting-period-payments',
  templateUrl: './reporting-period-payments.component.html',
  styleUrls: ['./reporting-period-payments.component.css']
})
export class ReportingPeriodPaymentsComponent implements OnInit {
  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  isValid: boolean;
  paymentRecoveryData: any[];
  allpaymentTypes: any[];
  paymentCodes: any[];
  perpetrators: any[];
  perpetratorsLookup: any[];
  locationsLookup: any[];

  constructor(private reportingPeriodPaymentsService: ReportingPeriodPaymentsService, private lookupService: LookupService,
    private NotificationService: NotificationService) { }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  ngOnInit() {
    this.InitializeData();
  }

  InitializeData() {
    this.initializeForm();
    this.loadLookupData();
  }

  initializeForm() {
    this.reportingPeriodPaymentsService.formDataInput = {
      mmiClaimsReference: null,
      paymentCodeId: [{ "id": 0, "text": "All" }],
      paymentRecovery: 0,
      paymentTypeId: [{ "id": 0, "text": "All" }],
      perpetratorId: [{ "id": 0, "text": "All" }],
      dateTo: null,
      dateFrom: null,
      paymentCodeValues: [],
      paymentTypeValues: [],
      perpetratorValues: [],
      locations: [{ "id": 0, "text": "All" }],
      locationsValues: [],
    }
  }

  generatePaymentsInPeriodReport() {
    this.setInputFields();
    if (this.validateSearch()) {
      this.reportingPeriodPaymentsService.generatePaymentsInPeriodReport();
    }
  }

  loadLookupData() {
    this.paymentRecoveryData = this.lookupService.getLookupsByTypeId(38);
    this.paymentCodes = this.lookupService.getLookupsByTypeId(37);
    this.paymentCodes.unshift({ "id": 0, "text": "All" });

    this.perpetratorsLookup = this.lookupService.getLookupsByTypeId(29).filter(x => { return x.booleanValue == true });
    this.perpetratorsLookup.unshift({ "id": 0, "text": "All" });

    this.locationsLookup = this.lookupService.getLookupsByTypeId(22).filter(x => { return x.booleanValue == true });
    this.locationsLookup.unshift({ "id": 0, "text": "All" });

    this.paymentRecoveryChange();
  }

  paymentRecoveryChange() {
    this.allpaymentTypes = [];
    if (this.reportingPeriodPaymentsService.formDataInput.paymentRecovery != 0) {
      this.allpaymentTypes = this.lookupService.getLookupByParentId(this.reportingPeriodPaymentsService.formDataInput.paymentRecovery);
      this.allpaymentTypes.unshift({ "id": 0, "text": "All" });
    }
    else {
      this.allpaymentTypes = this.lookupService.getLookupsByTypeId(39).concat(this.lookupService.getLookupsByTypeId(40));
      //this.allpaymentTypes.concat(this.lookupService.getLookupsByTypeId(40));
      this.allpaymentTypes.unshift({ "id": 0, "text": "All" });
    }
  }

  setInputFields() {
    this.reportingPeriodPaymentsService.formDataInput.paymentTypeValues = _.map(this.reportingPeriodPaymentsService.formDataInput.paymentCodeId, function (i: any) { return i.id });
    this.reportingPeriodPaymentsService.formDataInput.paymentCodeValues = _.map(this.reportingPeriodPaymentsService.formDataInput.paymentTypeId, function (i: any) { return i.id });
    this.reportingPeriodPaymentsService.formDataInput.perpetratorValues = _.map(this.reportingPeriodPaymentsService.formDataInput.perpetratorId, function (i: any) { return i.id });
    //this.reportingPeriodPaymentsService.formDataInput.claimHandlersIdValues = _.map(this.claimsReportService.formDataInput.claimHandlersId, function (i: any) { return i.id });
    this.getAndSetPerpetratorValueSearch();

    this.reportingPeriodPaymentsService.formDataInput.locationsValues =
      this.reportingPeriodPaymentsService.formDataInput.locations.map(x => x.id);
    this.getAndSetLocationValueSearch();
  }

  validateSearch() {
    this.isValid = true;
    if ((this.reportingPeriodPaymentsService.formDataInput.dateFrom != null && this.reportingPeriodPaymentsService.formDataInput.dateTo == null) ||
      (this.reportingPeriodPaymentsService.formDataInput.dateFrom == null && this.reportingPeriodPaymentsService.formDataInput.dateTo != null)) {
      this.isValid = false;
      this.NotificationService.printWarningMessage("Please input Date From and Date To Fields");
    }

    if (this.reportingPeriodPaymentsService.formDataInput.paymentCodeValues.length == 0 || this.reportingPeriodPaymentsService.formDataInput.paymentTypeValues.length == 0) {
      this.isValid = false;
      this.NotificationService.printWarningMessage("Please select all mandatory fields");
    }
    if (this.reportingPeriodPaymentsService.formDataInput.paymentCodeValues.length != 0 &&
      this.reportingPeriodPaymentsService.formDataInput.paymentTypeValues.length != 0 &&
      this.reportingPeriodPaymentsService.formDataInput.locations.length == 0 &&
      this.reportingPeriodPaymentsService.formDataInput.perpetratorId.length == 0) {
      this.isValid = false;
      this.NotificationService.printWarningMessage("Please select at least one location or perpetrator.");
      return false;
    }
    return this.isValid;
  }

  getAndSetPerpetratorValueSearch() {
    var perpetrator = this.lookupService.getLookupsByTypeId(29).filter(x => { return x.booleanValue == true });
    var selectedPerpetratorValues = this.reportingPeriodPaymentsService.formDataInput.perpetratorValues;
    if (selectedPerpetratorValues.indexOf(0) != -1) {
      this.reportingPeriodPaymentsService.formDataInput.perpetratorValues = [];
      let filteredPerpetrator =
        perpetrator.filter(result => { return result.id });
      this.reportingPeriodPaymentsService.formDataInput.perpetratorValues = filteredPerpetrator.map(x => x.id);
    }
  }

  getAndSetLocationValueSearch() {
    var location = this.lookupService.getLookupsByTypeId(22).filter(x => { return x.booleanValue == true });
    var selectedLocationValues = this.reportingPeriodPaymentsService.formDataInput.locationsValues;
    if (selectedLocationValues.indexOf(0) != -1) {
      this.reportingPeriodPaymentsService.formDataInput.locationsValues = [];
      let filteredLocation =
        location.filter(result => { return result.id });
      this.reportingPeriodPaymentsService.formDataInput.locationsValues = filteredLocation.map(x => x.id);
    }
  }
}

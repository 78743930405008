import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, AfterViewChecked } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PolicyholderService } from '../policyholder.service'
import { IExcessDetail } from './excess-details.model'
import { NgForm } from '@angular/forms';
import { enumYesNo, enumYesNoUnknownBlank } from '../../../../shared/models/common.enum';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap'
import { NotificationService } from '../../../../shared/services/notification.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as _ from "lodash";
import { SecurityService } from '../../../../shared/services/security.service';
import { BaseComponentComponent } from '../../../base-component/base-component.component';

@Component({
  selector: 'app-excess-detail',
  templateUrl: './excess-detail.component.html',
  styleUrls: ['./excess-detail.component.css']
})
export class ExcessDetailComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked {

  @ViewChild(NgForm, { static: false })
  public form: NgForm;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('excessDetailTable') excessDetailTable: ElementRef;
  dtOptions: DataTables.Settings = {};
  @Input() data;
  formData: IExcessDetail;
  excessDetailFrm: FormGroup;
  ItemIndex: number;
  @Input() isValid: boolean = true;
  @Input() formPanel: boolean = false;
  yesNoUnknownBlankkeys: any[];
  yesNoUnknownBlankEnum = enumYesNoUnknownBlank;
  isGridLoaded: boolean = false;
  dtTrigger = new Subject();
  isLDEdit: boolean = false;
  excessDetailLocalData: any[];

  constructor(private policyService: PolicyholderService,
    private notificationService: NotificationService, securityService: SecurityService) {
    super(securityService, 1135);
    this.yesNoUnknownBlankkeys = Object.values(this.yesNoUnknownBlankEnum).filter(Number);
    this.ItemIndex = null;

    this.excessDetailLocalData = [];
  }
  
  ngOnInit() {
    this.excessDetailLocalData = this.policyService.excessDetails;
    this.LoadFrm();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      columnDefs: [
        { type: 'date-uk', "targets": 2 },
        { type: 'date-uk', "targets": 3 },
        { "orderable": false, "targets": 10 }
      ]
    };

    this.dtTrigger.next();
    this.refreshGrid();
  }

  refreshForm() {
    this.excessDetailLocalData = this.policyService.excessDetails;
    this.refreshGrid();
    this.form.form.markAsPristine();
  }

  LoadFrm(): void {
    this.ItemIndex = null;
    this.isLDEdit = false;
    this.formData = {
      policyExcessId: null,
      policyId: this.policyService.formData.policyId,
      excessValueAmount: null,
      dateFrom: null,
      dateTo: null,
      stop: null,
      breached: null,
      paidBF: null,
      paidRecorded: null,
      remaining: null,
      limit: null,
      isClientDeletd: null,
      isDirty: null,
      isNewAdded: null
    }
  }

  notDeletedExcessDetail() {
    let c = _.filter(this.excessDetailLocalData, function (o) { return o.isClientDeletd == false; });
    return c;
  }

  remainingCalculation() {
    if (this.formData.limit > 0) {
      this.formData.remaining = this.formData.limit - (this.formData.paidBF + this.formData.paidRecorded)
    }
    else {
      this.formData.remaining = null;
    }
  }

  onEditExcessItem(indexItem) {
    this.formData = Object.assign({}, this.excessDetailLocalData.filter(a => !a.isClientDeletd)[indexItem]);
    this.ItemIndex = indexItem;
    this.isLDEdit = true;

  }

  onDeleteExcessItem(i: number) {
    this.excessDetailLocalData.filter(a => !a.isClientDeletd)[i].isClientDeletd = true;
    // this.excessDetailLocalData.splice(i, 1);

    this.policyService.excessDetails = this.excessDetailLocalData;
    this.ItemIndex = null;
    this.form.form.markAsDirty();
    this.refreshGrid();
  }

  onSubmit(form: NgForm) {
    if (this.validateForm(form.value)) {

      if (this.ItemIndex == null) {
        form.value.isNewAdded = true;
        form.value.isClientDeletd = false;
        form.value.policyId = this.policyService.formData.policyId;
        const newDetail: IExcessDetail = Object.assign({}, form.value);
        this.excessDetailLocalData.push(newDetail);
      }
      else {
        form.value.isDirty = true;
        this.isLDEdit = false;
        form.value.policyId = this.policyService.formData.policyId;

        let temp = this.excessDetailLocalData.filter(a => !a.isClientDeletd)[this.ItemIndex];
        let index = this.excessDetailLocalData.findIndex(a => a == temp);
        this.excessDetailLocalData[index] = form.value;

        this.ItemIndex = null;
      }

      this.policyService.excessDetails = this.excessDetailLocalData;

      this.refreshGrid();
      form.reset();
      this.LoadFrm();
      this.formPanel = false;
    }
  }

  getDateOnly(date: Date) {
    if (typeof date == 'string')
      var newDate = new Date(date);
    else
      var newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  validateForm(formData: IExcessDetail) {
    this.isValid = true;

    if (formData.excessValueAmount == null) {
      this.isValid = false;

      this.policyService.activeTabName = "excessTab";
      return false;
    }
    if (formData.dateFrom == null) {
      this.isValid = false;
      this.policyService.activeTabName = "excessTab";
      return false;
    }
    if (formData.dateTo == null) {
      this.isValid = false;
      this.policyService.activeTabName = "excessTab";
      return false;
    }
    if (this.policyService.formData.coverFrom == null) {
      this.isValid = false;
      this.policyService.activeTabName = "excessTab";
      this.notificationService.printWarningMessage("Please enter Cover From");
      return false;
    }
    if (this.policyService.formData.coverTo == null) {
      this.isValid = false;
      this.policyService.activeTabName = "excessTab";
      this.notificationService.printWarningMessage("Please enter Cover To");
      return false;
    }
    if (formData.stop == null) {
      this.isValid = false;
      this.policyService.activeTabName = "excessTab";
      return false;
    }
    if (formData.stop == 1 && formData.limit == null) {
      this.isValid = false;
      this.policyService.activeTabName = "excessTab";
      return false;
    }
    if (this.getDateOnly(formData.dateFrom) < this.getDateOnly(this.policyService.formData.coverFrom)) {
      this.notificationService.printWarningMessage("Date From should be greater than/equal to Cover From");
      this.policyService.activeTabName = "excessTab";
      this.isValid = false;
      return false;
    }
    if (this.getDateOnly(formData.dateTo) > (this.getDateOnly(this.policyService.formData.coverTo))) {
      this.notificationService.printWarningMessage("Date To should be less than Cover To");
      this.isValid = false;
      return false;
    }
    if (this.getDateOnly(formData.dateFrom) >= this.getDateOnly(formData.dateTo)) {
      this.isValid = false;
      this.notificationService.printWarningMessage("Date To should be greater than Date From");
      this.policyService.activeTabName = "excessTab";
      return false;
    }

    if (this.isLDEdit && this.excessDetailLocalData.length == 1) {
      this.isValid = true;
      this.policyService.activeTabName = "excessTab";
      return true;
    }

    let excessDetailsGridData;
    let index = this.ItemIndex;

    if (this.isLDEdit && this.excessDetailLocalData.length > 1) {
      excessDetailsGridData = this.excessDetailLocalData.filter(function (place, i) {
        return index != i;
      });
    } else {
      excessDetailsGridData = this.excessDetailLocalData;
    }

    excessDetailsGridData.forEach(a => {
      if (this.isValid) {
        //if ((this.getDateOnly(new Date(a.dateFrom)) <= this.getDateOnly(formData.dateFrom) && this.getDateOnly(new Date(a.dateTo)) >= this.getDateOnly(formData.dateFrom) && !a.isClientDeletd) ||
        //  (this.getDateOnly(new Date(a.dateFrom)) <= this.getDateOnly(formData.dateTo) && this.getDateOnly(new Date(a.dateTo)) >= this.getDateOnly(formData.dateTo) && !a.isClientDeletd))

        if ((!(this.getDateOnly(formData.dateFrom) > this.getDateOnly(a.dateTo) ||
          (this.getDateOnly(formData.dateFrom) < this.getDateOnly(a.dateFrom) &&
            this.getDateOnly(formData.dateTo) < this.getDateOnly(a.dateFrom)))) && !a.isClientDeletd) {
          this.notificationService.printWarningMessage("Overlapping dates are not allowed");
          this.isValid = false;
          this.policyService.activeTabName = "excessTab";
        }
      }
    });

    return this.isValid;
  }

  refreshGrid() {
    if (this.excessDetailTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}

import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../../shared/services/security.service';
import * as _ from "lodash";
import { enumPermissions } from '../../shared/models/common.enum';

@Component({
  selector: 'app-base-component',
  templateUrl: './base-component.component.html',
  styleUrls: ['./base-component.component.css']
})
export class BaseComponentComponent implements OnInit {
  protected permissionId: any;
  protected isReadOnlyMode: boolean = false;
  protected isEditMode: boolean = false;
  protected isAddMode: boolean = false;
  protected isDeleteMode: boolean = false;
  protected isMMIUser: boolean = false;

  constructor(protected securityService: SecurityService, UserPermissionID)
  {
    var userDetails = this.securityService.getUserData().userRolePermissionModels;
    var data = userDetails.filter(c => c.userPermissionsId == UserPermissionID);
    this.permissionId = data[0].permission;
    if (this.permissionId == enumPermissions.Read)
    {
      this.isReadOnlyMode = true;
    } else if (this.permissionId == enumPermissions.Edit)
    {
      this.isEditMode = true;
    }
    else if (this.permissionId == enumPermissions.Add)
    {
      this.isAddMode = true;
    }
    else if (this.permissionId == enumPermissions.Delete)
    {
      this.isDeleteMode = true;
    }
  }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { ManageDwpService } from '../../../shared/services/manage-dwp.service'
import { Observable } from 'rxjs';
import { IDwp } from './dwp.model'

@Injectable({
  providedIn: 'root'
})
export class DwpService {

  formData: IDwp;
  constructor(private manageDwpService: ManageDwpService) {
  }

  getDwpById(id: number): any {
    return this.manageDwpService.getDwpById(id);
  }

  createDwp(): any {
    var body = {
      ...this.formData
    };
    return this.manageDwpService.createDwp(body);
  }

  updateDwp(): any {
    var body = {
      ...this.formData
    };
    return this.manageDwpService.updateDwp(body);
  }
}

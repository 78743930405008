import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IClaims } from '../../models/claims.model';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { ISearchClaimVM } from '../../models/search-claim.model';
import { ClaimHandler } from '../../components/add-participant/claim-handler/participant-claim-handler.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchWorkflowRequestModel } from '../../components/search-workflow/search-workflow.model';
import { SearchWorkflowResponseModel } from '../models/search-workflow.response.model';

@Injectable()
export class ClaimWorkflowService {

  private workflowUrl: string = "/api/Workflow/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.workflowUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.workflowUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.workflowUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.workflowUrl);
  }

  getClaimById(id: number): any {
    //debugger;
    let url = this.workflowUrl + "getClaimData";

    return this.dataService.get(url, { claimId: id });
  }

  saveWorkflowItem(data): any {    
    //let url = this.workflowUrl + "getClaimData";

    return this.dataService.post(this.workflowUrl, data);
  }

  getWorkflowItem(Id:number): any {
    let url = this.workflowUrl + "getWorkflowData";
    return this.dataService.get(url, { workflowItemId:Id});
  }

  updateWorkflowItem(data): any {
    return this.dataService.putWithId(this.workflowUrl, data);
  }

  searchClaimWorkflow(data: any): any
  {
    let url = this.workflowUrl + 'searchWorkflow';
    return this.dataService.post(url, data).pipe<SearchWorkflowResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  updateWorkflowStatus(workflowStatusIds: any, Status:any) {
    var url = this.workflowUrl + "changeWorkflowStatus"
    var data = {
      workflowItemId: workflowStatusIds.toString(),
      workflowStatus: Status
    };
    return this.dataService.downloadFile(url, data);
  }

  getClaimDetails(MMIClaimsReference:string): any {
    let url = this.workflowUrl + "getClaimDetails";
    return this.dataService.get(url, { mmiClaimsReference: MMIClaimsReference });
  }
}

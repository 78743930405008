import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SearchPolicyVM } from '../../../models/search-policy.model';
import { lineOfBusiness, policyType, enumYesNo, enumLitigatedType, enumReportsType } from '../../../shared/models/common.enum';
import { IPager } from '../../../shared/models/pager.model';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import * as _ from 'lodash';
import { ClaimsReportService } from './claims-report.service';
import { claimsReport } from '../../../models/claims-report.model';
import { NgForm } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SecurityService } from '../../../shared/services/security.service';

@Component({
  selector: 'app-claims-report',
  templateUrl: './claims-report.component.html',
  styleUrls: ['./claims-report.component.css']
})
export class ClaimsReportComponent implements OnInit {

  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  claimTypeLookup: any[];
  handlingOrganisationData: any[];
  claimHandlersData: any[]=[];
  claimReportTypes: any[];

  newOrSettledClaimsFlag: boolean = false;
  openClaimsFlag: boolean = false;

  yesNoEnum = enumYesNo;
  LitigatedTypeEnum = enumLitigatedType;
  yesNokeys: any[];
  litigatedTypeKeys: any[];
  isValid: boolean= true;
  //claimsReportInputModel: claimsReport;
  public claimsReportInputModel: claimsReport = new claimsReport();
  isMMIUser: boolean = false;

  constructor(private lookupService: LookupService,
    private claimsReportService: ClaimsReportService,
    private notificationService: NotificationService,
    private securityService: SecurityService
  ) { }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 5,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  dropdownSettingsClaimHandlersData: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'claimsHandlers',
    enableCheckAll: true,
    itemsShowLimit: 5,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };


  ngOnInit(): void {
    this.InitializeData();
   
  }

  InitializeData() {
    this.initializeForm();
    this.claimTypeLookup = this.lookupService.getLookupsByTypeId(1);
    this.claimReportTypes = this.lookupService.getLookupsByTypeId(48);
    this.claimsReportService.getHandlingOrganization().subscribe(response => {this.handlingOrganisationData = response;});
    this.yesNokeys = Object.values(this.yesNoEnum).filter(Number);
    this.litigatedTypeKeys = Object.values(this.LitigatedTypeEnum).filter(Number);
    this.claimsReportInputModel.handlingOrganisationId = 0;
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
      if (!this.isMMIUser) {
        this.claimsReportService.formDataInput.handlingOrganisationId = this.securityService.getUserData().organisationId;
        this.onHandlingOrganisationChange();
      }
    }
  };

  generateClaimsReport() {
    this.claimsReportService.formDataInput.claimTypeValues = _.map(this.claimsReportService.formDataInput.claimTypes, function (i: any) { return i.id })
    this.claimsReportService.formDataInput.claimHandlersIdValues = _.map(this.claimsReportService.formDataInput.claimHandlersId, function (i: any) { return i.id })
   
    if (this.validateForm())
    {
      if (this.claimsReportService.formDataInput.reportType != enumReportsType.openClaims) {
        this.claimsReportService.formDataInput.dateFrom = (this.claimsReportService.formDataInput.dateFromCalander != null) ? !(this.isIsoDate(this.claimsReportService.formDataInput.dateFromCalander)) ? this.claimsReportService.formDataInput.dateFromCalander.toUTCString() : this.claimsReportService.formDataInput.dateFromCalander : null;
        this.claimsReportService.formDataInput.dateTo = (this.claimsReportService.formDataInput.dateToCalander != null) ? !(this.isIsoDate(this.claimsReportService.formDataInput.dateToCalander)) ? this.claimsReportService.formDataInput.dateToCalander.toUTCString() : this.claimsReportService.formDataInput.dateToCalander : null;
      }
      else
      {
       // this.claimsReportService.formDataInput.asAtDate = this.getDateOnly(this.claimsReportService.formDataInput.asAtDate);
        this.claimsReportService.formDataInput.asAtDate = (this.claimsReportService.formDataInput.asAtDateCalander != null) ? !(this.isIsoDate(this.claimsReportService.formDataInput.asAtDateCalander)) ? this.claimsReportService.formDataInput.asAtDateCalander.toUTCString() : this.claimsReportService.formDataInput.asAtDateCalander : null;
      }
      this.claimsReportService.generateClaimsReport();
    } else
    {
      this.notificationService.printWarningMessage("Please select all mandatory fields");
    }
  }

  initializeForm() {
    this.claimsReportService.formDataInput = {
      claimTypeValues: [],
      claimTypes: [],
      handlingOrganisationId: null,
      claimHandlersId: [],
      dateFrom: null,
      dateTo: null,
      asAtDate: null,
      reportType: null,
      litigatedTypeId: 3,
      excludeNilsId: 2,
      claimHandlersIdValues: [],
      asAtDateCalander: null,
      dateFromCalander: null,
      dateToCalander: null,

    }
  }

  onHandlingOrganisationChange() {
    if (this.claimsReportService.formDataInput.handlingOrganisationId == 0) {
      this.claimsReportService.formDataInput.claimHandlersIdValues = [];
      this.claimsReportService.formDataInput.claimHandlersId = [];
      this.claimHandlersData = [];
      this.claimHandlersData = [{ "id": 0, "claimsHandlers":"All"}];
    }
    else {
      this.claimsReportService.getClaimHandlersByHandlingOrganisation(this.claimsReportService.formDataInput.handlingOrganisationId).subscribe(
        res => {
          this.claimsReportService.formDataInput.claimHandlersIdValues = [];
          this.claimsReportService.formDataInput.claimHandlersId = [];
          this.claimHandlersData = [];
          this.claimHandlersData = _.map(res);
          this.claimHandlersData.unshift({ "id": 0, "claimsHandlers": "All" });
        });
    }
  }

  getDateOnly(date: Date) {
    if (typeof date == 'string')
      var newDate = new Date(date);
    else
      var newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  onReportTypeChange() {
    if (this.claimsReportService.formDataInput.reportType != enumReportsType.openClaims)
    {      
      this.newOrSettledClaimsFlag = true;
      this.openClaimsFlag = false;
    }
    else
    {
      this.newOrSettledClaimsFlag = false;
      this.openClaimsFlag = true;
    }
  }

  validateForm(): boolean {
    this.isValid = true;
    if (this.claimsReportService.formDataInput.claimHandlersIdValues == null || this.claimsReportService.formDataInput.claimHandlersIdValues.length == 0 || this.claimsReportService.formDataInput.claimTypeValues == null ||
      this.claimsReportService.formDataInput.claimTypeValues.length==0 || this.claimsReportService.formDataInput.handlingOrganisationId == null || this.claimsReportService.formDataInput.reportType == null)
    {
      this.isValid = false;
      return false;
    }
    if (this.claimsReportService.formDataInput.reportType == enumReportsType.newClaims || this.claimsReportService.formDataInput.reportType == enumReportsType.settledClaims)
    {
      if (this.claimsReportService.formDataInput.dateFromCalander == null || this.claimsReportService.formDataInput.dateToCalander == null)
      {
        this.isValid = false
        return false;
      }
    }
    if (this.claimsReportService.formDataInput.reportType == enumReportsType.openClaims && this.claimsReportService.formDataInput.asAtDateCalander == null) {
      this.isValid = false;
      return false;
    }
    return this.isValid;

  }

  isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str);
    return d.toISOString() === str;
  }

}





import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, AfterContentInit, AfterViewChecked, ElementRef, QueryList, ViewChildren, Inject, HostListener } from '@angular/core';
import { Apportionment } from './apportionment.model';
import { ApportionmentPolicy } from './apportionment-policy.model';
import { ApportionmentClaimPolicy } from './apportionment-claim-policy.model';
import { CalculateApportionmentService } from './apportionment.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { IPager } from '../../../shared/models/pager.model';
import { Subscription, Subject, Observable, empty } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { NgForm } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ApportionmentNotes } from './apportionment-notes.model';
import { ClaimComponent } from './../claim.component'
import { ComponentCanDeactivate } from '../../../shared/guard/unsaved-changes.guard';
import { cloneDeep } from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { SecurityService } from '../../../shared/services/security.service';
import { enumPermissions, enumApportionmentViewOptions } from '../../../shared/models/common.enum';
import { StorageService } from '../../../shared/services/storage.service';
import { ClaimDetailService } from '../claim-detail/claim-detail.service';
import { ValuationService } from '../../../shared/services/valuation.service';
import { error } from 'protractor';


@Component({
  selector: 'app-apportionment',
  templateUrl: './apportionment.component.html',
  styleUrls: ['./apportionment.component.css'],
})



export class ApportionmentComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked, ComponentCanDeactivate {
  //componentId = 1142;
  @ViewChild(NgForm, { static: false })
  private form: NgForm;
  @ViewChildren(DataTableDirective)
  private datatableElements: QueryList<DataTableDirective>;

  @ViewChild('apportionmentPoliciesTable') apportionmentPoliciesTable: ElementRef;
  @ViewChild('apportionmentNotesTable') apportionmentNotesTable: ElementRef;
  public dtOptions: any = {};
  public dtNotesOptions: any = {}

  public apportionmentModel: Apportionment = new Apportionment();
  public apportionmentPolicyModel: ApportionmentPolicy = new ApportionmentPolicy();
  public apportionmentPolicyResult: ApportionmentPolicy[];
  public apportionmentClaimPolicy: any[];
  public claimType;
  claimTypeValue: any;
  policies: any;
  errorReceived: boolean = false;
  public claimId: number;
  public mesoClaimTypeId: number = 5;
  isHidden: boolean = true;
  showApportionmentAddPanelFlag: boolean = false;
  isEditFlag = false;
  labelExposureOrAbuseFrom: any;
  labelExposureOrAbuseTo: any;
  insurerRepresentative: any;
  insurerRepresentativeDisabledFlag = false;
  labelAddOrUpdate: string = "ADD";
  btnAddOrUpdate: string = "ADD";
  isGridLoaded: boolean = false;
  isNotesGridLoaded: boolean = false;
  exposureDaysSum: number = 0;
  damagesSum: number = 0;
  cruSum: number = 0
  costsSum: number = 0;
  defenceCostsSum: number = 0;
  handlingOrganisationName: string;
  coverFrom: Date
  coverTo: Date
  isValid: boolean = true;
  isValidApportionmentNotes: boolean = true;
  showLOIExportButton: boolean = false;
  isDelete: boolean = false;
  dtTrigger = new Subject();
  dtNotesTrigger = new Subject();
  _apportionmentPolicy: ApportionmentPolicy;
  _apportionmentPolicies: ApportionmentPolicy[];
  isPolicyDateFlag: boolean = false;
  showMultiselectDropdown: boolean = true;
  viewOptionId: any;
  displayedColumns: any = [];
  isValidMMILeadExcessType: boolean = true;
  handlingAuthorityValue: number;
  isPolicyExcessFlag: boolean;
  hideExcess: boolean
  isLOIDefaultSettingsUpdated: boolean = false;
  public apportionmentNotes: ApportionmentNotes = new ApportionmentNotes();
  eFileURL: string;
  isNewClaim: boolean;
  isCompensationActApplicable: boolean = false;
  isMMIUser: boolean = false;
  hideWorkflowButton: boolean = true;
  onInitVersion: string = '';

  saveMethod: any;
  isFormSubmitted: boolean = true;
  public formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean { return false; };
  isSettledButtonClicked: boolean = false;
  reserveLargeLosslimitMessageShownCount: number = 0;
  reserveBreachedDALimitMessageShownCount: number = 0;
  @HostListener('window:beforeunload')
  // CR-18 Merge Request Changes
  //canDeactivate(component: Apportionment,
  //  currentRoute: ActivatedRouteSnapshot,
  //  currentState: RouterStateSnapshot,
  //  nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
  //  if (nextState.url.includes('valuation') || nextState.url.includes('claimdetail')) {
  //    if (this.form.dirty) {
  //      this.storageService.store("apportionmentData", this.apportionmentService.formData);
  //      this.storageService.store("showAddPanel", this.showApportionmentAddPanel);
  //      this.storageService.store("viewOptionId", this.viewOptionId);
  //      this.storageService.store("selectedItems", this.displayedColumns);
  //      if (this.apportionmentNotes.comments != undefined && this.apportionmentNotes.comments != null) {
  //        this.storageService.store("addComments", this.apportionmentNotes.comments);
  //      }
  //    }
  //    return true;
  //  }
  //  else {
  //    let hasPendingChanges: boolean = !this.form.touched;
  //    return hasPendingChanges;
  //  }
  canDeactivate(): Observable<boolean> | boolean {
    let hasPendingChanges: boolean = !this.form.touched;
    this.saveMethod = this.onSubmit;
    return hasPendingChanges;
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'data',
    textField: 'title',
    enableCheckAll: true,
    itemsShowLimit: -1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    closeDropDownOnSelection: false
  };


  //displayedColumns: any[] = [
  //  { isAlwaysVisible: true, title: 'Group', data: '1', visible: true, summaryView: false,checked: true },
  //  { isAlwaysVisible: true, title: 'Defendant Name', data: '2', visible: true, summaryView: true, checked: true },
  //  { isAlwaysVisible: true, title: 'MMI Insured', data: '3', visible: true, summaryView: true, checked: true},
  //  { isAlwaysVisible: true, title: 'Policy No', data: '4', visible: true, summaryView: false, checked: true },
  //  { isAlwaysVisible: true, title: 'Insured Title', data: '5', visible: true, summaryView: false, checked: true },
  //  { isAlwaysVisible: true, title: 'Insurer', data: '6', visible: true, summaryView: true, checked: true },
  //  { isAlwaysVisible: true, title: 'Exposure/Abuse From', data: '7', visible: true, summaryView: true, checked: true },
  //  { isAlwaysVisible: true, title: 'Exposure/Abuse To', data: '8', visible: true, summaryView: true, checked: true },
  //  { isAlwaysVisible: true, title: 'Days', data: '9', visible: true, summaryView: true, checked: true },
  //  { isAlwaysVisible: true, title: 'Contributes To Damages', data: '10', visible: true, summaryView: false, checked: true },
  //  { isAlwaysVisible: true, title: 'Contributes To Defence Costs', data: '11', visible: true, summaryView: false, checked: true },
  //  { isAlwaysVisible: true, title: 'FSCS Protected Days', data: '12', visible: true, summaryView: false, checked: true },
  //  { isAlwaysVisible: true, title: 'Damages %', data: '13', visible: true, summaryView: true, checked: true },
  //  { isAlwaysVisible: true, title: 'Costs %', data: '14', visible: true, summaryView: true, checked: true },
  //  { isAlwaysVisible: true, title: 'CRU %', data: '15', visible: true, summaryView: true, checked: true },
  //  { isAlwaysVisible: true, title: 'Defence Costs %', data: '16', visible: true, summaryView: false, checked: true }
  //];
  selectedItems = [];
  //displayedColumns = [];
  displayedDataList = [];
  policyId: number;
  constructor(private apportionmentService: CalculateApportionmentService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private lookupService: LookupService,
    private notificationService: NotificationService, @Inject(ClaimComponent) private parent: ClaimComponent, securityService: SecurityService, private storageService: StorageService, private valuationService: ValuationService,
    private claimDetailService: ClaimDetailService) {
    super(securityService, 1142);
    this.dtTrigger = new Subject();
    this.dtNotesTrigger = new Subject();
    this._apportionmentPolicy = new ApportionmentPolicy();
    this._apportionmentPolicies = [];

  }

  ngOnInit(): void {
    this.claimId = parseInt(this.activeRoute.parent.snapshot.paramMap.get('claimId'));
    this.policyId = parseInt(this.activeRoute.parent.snapshot.paramMap.get('policyId'));
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
      this.hideWorkflowButton = (this.isMMIUser) ? false : true
    }
    // CR-18 Merge Request Changes
    //this.showApportionmentAddPanelFlag = (this.storageService.retrieve("showAddPanel") != null) ? this.storageService.retrieve("showAddPanel") : false;
    //this.apportionmentNotes.comments = (this.storageService.retrieve("addComments") != null) ? this.storageService.retrieve("addComments") : "";
    //this.handlingAuthorityValue = this.securityService.getUserData().handlingAuthority;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      dom: 'Blfrtip',
      stateSave: true,
      rowCallback: function (nRow, aData, iDisplayIndex) {
        if (aData[16] == "true")
          $('td', nRow).each(function () {
            // $(this).html('<b>' + $(this).text() + '</b>');
            $(this).addClass('highlight')
          });
        return nRow;
      },
      columnDefs: [{ "targets": -1, bSortable: false },
      { type: 'date-uk', "targets": 6 },
      { type: 'date-uk', "targets": 7 },
  
      {
        targets: 15, render: function (data) {
          if (data == null) {
            return "";
          }
          else if (data.includes('%')) {
            return data;
          }
          else {
            return (((String(data).split('.').length === 1) && parseInt(data) > 0 && parseInt(data) < 100) ? (String(data).split('.')[0] + '.00') : ((String(data).split('.').length === 2) && (String(data).split('.')[1].length === 1)) ? (String(data).split('.')[0] + "." + String(data).split('.')[1] + "0") : data) + " " + "%"
          }
        }
      },
      {
        targets: 14, render: function (data) {
          if (data == null) {
            return "";
          }
          else if (data.includes('%')) {
            return data;
          }
          else {
            return (((String(data).split('.').length === 1) && parseInt(data) > 0 && parseInt(data) < 100) ? (String(data).split('.')[0] + '.00') : ((String(data).split('.').length === 2) && (String(data).split('.')[1].length === 1)) ? (String(data).split('.')[0] + "." + String(data).split('.')[1] + "0") : data) + " " + "%"
          }
        }
      },
      {
        targets: 13, render: function (data) {
          if (data == null) {
            return "";
          }
          else if (data.includes('%')) {
            return data;
          }
          else {
            return (((String(data).split('.').length === 1) && parseInt(data) > 0 && parseInt(data) < 100) ? (String(data).split('.')[0] + '.00') : ((String(data).split('.').length === 2) && (String(data).split('.')[1].length === 1)) ? (String(data).split('.')[0] + "." + String(data).split('.')[1] + "0") : data) + " " + "%"
          }
        }
      },
      {
        targets: 12, render: function (data) {
          if (data == null) {
            return "";
          }
          else if (data.includes('%')) {
            return data;
          }
          else {
            return (((String(data).split('.').length === 1) && parseInt(data) > 0 && parseInt(data) < 100) ? (String(data).split('.')[0] + '.00') : ((String(data).split('.').length === 2) && (String(data).split('.')[1].length === 1)) ? (String(data).split('.')[0] + "." + String(data).split('.')[1] + "0") : data) + " " + "%"
          }
        }
      },
      {
        targets: 3, render: function (data) {
          if (data == "" || data == null) {
            return "N/A";
          }
          else {
            return data;
          }
        }
      }
      ],
      order: [[6, 'asc']],
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          tag: '',
          title: "Apportionment Data",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
          },
          filename: function () { return "ApportionmentData" + Math.random(); },

        }

      ]
    };
    this.dtNotesOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      columnDefs: [{ type: 'datetime-uk', "targets": 0 }],
      order: [[0, 'desc']]
    };
    this.setSelectedItemsForDropdown();
    this.loadApportionmentData(this.claimId);
    this.setMultiSelectDropdownValues();
    var viewOptionId = this.storageService.retrieve("viewOptionId");
    if (viewOptionId) {
      this.onViewOptionChange(viewOptionId);
      this.viewOptionId = viewOptionId;
    }
    else {
      this.onViewOptionChange(enumApportionmentViewOptions.UserDefined);
      this.viewOptionId = enumApportionmentViewOptions.UserDefined;
    }
    if (this.isReadOnlyMode) {
      this.hideExcess = true;
    }
    if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
      this.isReadOnlyMode = true;
    }

    this.parent.claimStatusId$.subscribe(x => {
      if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
        this.isReadOnlyMode = true;
      }
    });
    this.checkForNewClaim(this.claimId);
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
    if (!this.isNotesGridLoaded) {
      this.refreshNotesGrid();
    }

  }


  setSelectedItemsForDropdown() {
    if (this.storageService.retrieve("selectedItems") != null) {
      this.displayedColumns = this.storageService.retrieve("selectedItems");
    }
    else {
      this.displayedColumns = [
        { isAlwaysVisible: true, title: 'Group', data: '1', visible: true, summaryView: false, checked: true },
        { isAlwaysVisible: true, title: 'Defendant Name', data: '2', visible: true, summaryView: true, checked: true },
        { isAlwaysVisible: true, title: 'MMI Insured', data: '3', visible: true, summaryView: true, checked: true },
        { isAlwaysVisible: true, title: 'Policy No', data: '4', visible: true, summaryView: false, checked: true },
        { isAlwaysVisible: true, title: 'Insured Title', data: '5', visible: true, summaryView: false, checked: true },
        { isAlwaysVisible: true, title: 'Insurer', data: '6', visible: true, summaryView: true, checked: true },
        { isAlwaysVisible: true, title: 'Exposure/Abuse From', data: '7', visible: true, summaryView: true, checked: true },
        { isAlwaysVisible: true, title: 'Exposure/Abuse To', data: '8', visible: true, summaryView: true, checked: true },
        { isAlwaysVisible: true, title: 'Days', data: '9', visible: true, summaryView: true, checked: true },
        { isAlwaysVisible: true, title: 'Contributes To Claim', data: '10', visible: true, summaryView: false, checked: true },
        { isAlwaysVisible: true, title: 'Contributes To Defence Costs', data: '11', visible: true, summaryView: false, checked: true },
        { isAlwaysVisible: true, title: 'FSCS Protected Days', data: '12', visible: true, summaryView: false, checked: true },
        { isAlwaysVisible: true, title: 'Damages %', data: '13', visible: true, summaryView: true, checked: true },
        { isAlwaysVisible: true, title: 'Costs %', data: '14', visible: true, summaryView: true, checked: true },
        { isAlwaysVisible: true, title: 'CRU %', data: '15', visible: true, summaryView: true, checked: true },
        { isAlwaysVisible: true, title: 'Defence Costs %', data: '16', visible: true, summaryView: false, checked: true }
      ]
    }

  }

  showApportionmentAddPanel(index) {
    this.showApportionmentAddPanelFlag = true;
    this.isValid = true;
    this.isEditFlag = false;
    if (index == null || index == undefined) {
      this.labelAddOrUpdate = 'ADD';
      this.btnAddOrUpdate = 'ADD';
      this.apportionmentService.formData.apportionmentPolicy = {
        abusefrom: null, abuseto: null, apportionmentSummaryID: null, defendantGroup: '', defendantName: '', mmiInsured: null,
        policyId: null, insuredTitle: '', insurerRepresentative: '', days: 0, contributesToDamages: null, contributesToDefenceCosts: null,
        fscsProtected: 0, damages: 0, cost: 0, cru: 0, defenceCosts: 0, fscs: 0, fscsDays: 0, groupName: '', id: null, policyNumber: '', index: 0, isPolicyDateOutRangeFlag: false, isPolicyExcessFlag: false
      }

    }
    else {
      this.labelAddOrUpdate = 'UPDATE';
      this.btnAddOrUpdate = 'UPDATE';
      this.isEditFlag = true;
      this.apportionmentService.formData.apportionmentPolicy = cloneDeep(this.apportionmentPolicyResult[index]);
      if (this.apportionmentService.formData.apportionmentPolicy.mmiInsured == 0) {
        this.insurerRepresentativeDisabledFlag = false;
      }
      else if (this.apportionmentService.formData.apportionmentPolicy.mmiInsured == 1) {
        this.insurerRepresentativeDisabledFlag = true;
      }
      // this.apportionmentService.formData.apportionmentPolicy.policyId = this.apportionmentPolicyResult[index].policyId;
      this.apportionmentService.formData.apportionmentPolicy.index = index;

    }
  }

  onSave() {
    
    if (this.validatePolicy()) {
      if (this.apportionmentPolicyResult.find(i => i.id == this.apportionmentService.formData.apportionmentPolicy.id && i.id !== null)) {
        let index = this.apportionmentService.formData.apportionmentPolicy.index;
        this.apportionmentService.formData.apportionmentPolicies[index] = this.apportionmentService.formData.apportionmentPolicy;
        this.refreshGrid();
      }

      else {
        this.apportionmentService.formData.apportionmentPolicy.apportionmentSummaryID = this.apportionmentService.formData.id;
        if (this.apportionmentPolicyResult !== undefined && this.apportionmentPolicyResult.length > 0) {
          //this.apportionmentService.formData.apportionmentPolicy.index = this.apportionmentService.formData.apportionmentPolicies.length + 1;
          this.apportionmentService.formData.apportionmentPolicy.index = this.apportionmentPolicyResult.length + 1;
        }
        this.apportionmentService.formData.apportionmentPolicies.push(this.apportionmentService.formData.apportionmentPolicy);

      }
      // CR-18 Merge Request Changes
      //this.parent.isApportionmentAvailble = true;
      //if (this.apportionmentService.formData.id !== null && this.apportionmentService.formData.id > 0) {
      //  this.apportionmentService.formData.
      //}
      this.apportionmentService.calculateApportionment().subscribe(res => {

        this.refreshNotesGrid();
        this.apportionmentService.formData = res;
        this.apportionmentService.formData.version = this.onInitVersion;
        //this.showFSCSProtectedOverridenValue();
        this.enableDisableCompensationActCheckbox();
        //this.overrideFSCSProtected(this.isCompensationActApplicable);
        //this.apportionmentService.formData.apportionmentPolicies = res.apportionmentPolicies;
        //this.apportionmentPolicyResult = res.apportionmentPolicyResult;
        this.clearFields();
        if (res.apportionmentPolicies != null) {
          this.apportionmentPolicyResult = res.apportionmentPolicies;
          if (this.apportionmentPolicyResult !== null) {

            this.calculateExposureSum();
            this.calculateDamagesSum();
            this.calculateDefenceCostsSum();
            this.calculateCRUSum();
            this.calculateCostSum();
            this.refreshGrid();
          }
          // this.refreshNotesGrid();

        }
        this.updatePolicyDatesOutRangeFlag();
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      )

    }
  }

  resetForm(claimId, form?: NgForm) {
    this.apportionmentService.formData = {
      id: null,
      claimId: parseInt(claimId),
      mmiLead: null,
      fscsProtected: 0,
      mmiDamages: 0,
      mmiCosts: 0,
      mmicru: 0,
      mmiDefenceCosts: 0,
      overwrite: false,
      overwriteMMIDamages: 0,
      overwriteMMICosts: 0,
      overwriteMMICRU: 0,
      overwriteMMIDefenceCosts: 0,
      isMesothelioma: false,
      excessType: null,
      isLOIAmountUpdatedInSettings: false,
      isAggregatedClaim: false,
      apportionmentPolicies: this._apportionmentPolicies,
      apportionmentPolicy: this._apportionmentPolicy,
      apportionmentNotes: [],
      isCompensationAct: false,
      showMessageForDA: false,
      showMessageForLLA: false,
      handlingOrganisationId: null,
      version: '',
      contributionClaim: 0,
    }

  };

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtNotesTrigger.unsubscribe();
  }

  onSubmit() {
    if (this.validateForm()) {
      // CR-18 Merge Request Changes
      //if (!this.checkHandlingAuthorityValidation()) {
      //  this.notificationService.printWarningMessage("The proposed valuation exceeds your Handling Authority. Please refer to a colleague with a sufficient Handling Authority to enter the new valuation.");
      //  return;
      //}
      this.apportionmentService.checkHandlingAuthorityAndReserveValidation().subscribe((response) => {        
        var returnValue = Number(response);
        if (returnValue == 1) {
          this.notificationService.printWarningMessage("The proposed valuation exceeds your Handling Authority. Please refer to a colleague with a sufficient Handling Authority to enter the new valuation.");
          this.isFormSubmitted = false;
          this.formValidityFlagLoaded.next();
          return;
        }
        if (returnValue == 2) {
          this.notificationService.printWarningMessage("LLA is now applicable please assign an MMI handler");
          this.isFormSubmitted = false;
          this.formValidityFlagLoaded.next();
          return;
        }
        if (returnValue == 3) {
          this.notificationService.printWarningMessage("LLA is now applicable please assign an MMI Key Contact");
          this.isFormSubmitted = false;
          this.formValidityFlagLoaded.next();
          return;
        }
        if (returnValue == 4) {
          this.notificationService.printWarningMessage("You are attempting to save the claim without an MMI key contact. If this is a disease claim, please select Chad Williams as the key contact and save the claim. if this is an abuse claim, please select Dan Heaton as the key contact and save the claim.");
          this.isFormSubmitted = false;
          this.formValidityFlagLoaded.next();
          return;
        }

        else {
          if (this.apportionmentNotes.comments != '' && this.apportionmentNotes.comments != undefined) {
            if (confirm("Comment / Note has been written but not Added. Do you want to save your Comment / Note ?")) {
              this.addApportionmentNotes();
            }
          }
          this.verifyMMIPercentageBeforeToSave();
          this.apportionmentService.checkMMIReserveValue().subscribe(isReservePositive => {            
            if (!isReservePositive) {
              if (confirm("This change has resulted in a negative MMI Reserve. Please review/amend the Apportionment and Full Liability Valuation as a matter of urgency.")) {
                this.saveApportionment();
              }
              else {
                return;
              }
            }
            else {
              this.saveApportionment();
            }
          });
          //this.loadApportionmentData(this.claimId);

        }
      });
    }
  }

  saveApportionment() {    
    this.apportionmentService.saveApportionment().subscribe(res => {      
      this.notificationService.printSuccessMessage('Apportionment saved Successfully');
      this.apportionmentService.formData = res;
      this.onInitVersion = this.apportionmentService.formData.version;
      //this.showFSCSProtectedOverridenValue();
      this.enableDisableCompensationActCheckbox();
      //this.overrideFSCSProtected(this.isCompensationActApplicable);
      console.log(this.apportionmentService.formData);
      this.clearFields();
      this.apportionmentService.formData.apportionmentPolicies = res.apportionmentPolicies;
      //this.apportionmentService.formData.apportionmentNotes = res.apportionmentNotes;
      this.apportionmentPolicyResult = res.apportionmentPolicies;

      if (this.apportionmentPolicyResult !== null) {
        this.calculateExposureSum();
        this.calculateDamagesSum();
        this.calculateDefenceCostsSum();
        this.calculateCRUSum();
        this.calculateCostSum();
      }
      this.checkPolicyExcessFlagAndSetExcessType();
      this.isLOIDefaultSettingsUpdated = false;
      this.refreshGrid();
      this.refreshNotesGrid();
      this.parent.isApportionmentAvailble = true;
      //this.onViewOptionChange(this.viewOptionId);
      this.storageService.store("viewOptionId", this.viewOptionId);
      this.storageService.store("selectedItems", this.displayedColumns);
      this.isFormSubmitted = true;
      this.formValidityFlagLoaded.next();
      // CR-18 Merge Request Changes
      //this.updateClaimsData();
      //this.saveOrUpdateValuationData()
      if (this.form != undefined)
        this.form.form.markAsUntouched();
      if (this.apportionmentService.formData.showMessageForLLA && this.reserveLargeLosslimitMessageShownCount == 0) {
        if (this.apportionmentService.formData.handlingOrganisationId == 1) {
          this.notificationService.printWarningMessage("The reserve set has reached the Large Loss limit. Please prepare a large loss advice within 5 working days.");
        }
        else {
          this.notificationService.printWarningMessage("The reserve set has reached the Large Loss limit. Please prepare a large loss advice and send to the MMI key contact within 5 working days.");
        }
        this.reserveLargeLosslimitMessageShownCount += 1;
      }
      else if (!this.apportionmentService.formData.showMessageForLLA) {
        this.reserveLargeLosslimitMessageShownCount == 0
      }
      if (this.apportionmentService.formData.showMessageForDA && this.reserveBreachedDALimitMessageShownCount == 0) {
        this.notificationService.printWarningMessage("The reserve has breached the D.A. limit. This claim is now Non-D.A. Please refer the claim under the SLA to the MMI key contact within 3 months. If you consider that this should remain as D.A. please email the MMI key contact.");
        this.reserveBreachedDALimitMessageShownCount += 1;
      }
      else if (!this.apportionmentService.formData.showMessageForDA) {
        this.reserveBreachedDALimitMessageShownCount = 0;
      }
    },
      error => {
        if (error.error.includes('You are about to save changes to this claim. Another user is also in the claim')) {
          this.notificationService.printHtmlErrorMessage(error.error);
        }
        else {
          this.notificationService.printErrorMessage(error.message);
        }
      }
    )
  }



  // CR-18 Merge Request Changes
  //checkHandlingAuthorityValidation(): boolean {
  //  if (this.storageService.retrieve("valuationData") != null) {
  //    return (this.handlingAuthorityValue < this.storageService.retrieve("valuationData").totaltotalIncurred) ? false : true;
  //  } else {
  //    return true;
  //  }
  //}
  //}
  loadPolicyClaimData(claimId) {
    this.apportionmentService.getClaimPoliciesByClaimId(claimId)
      .subscribe(response => {
        this.apportionmentClaimPolicy = response;
        this.claimTypeValue = this.lookupService.getLookupById(response[0].claimType)[0].name;
        this.insurerRepresentative = response[0].representative;
        if (response[0].claimType == this.mesoClaimTypeId) {
          this.apportionmentService.formData.isMesothelioma = true;

          this.displayedDataList = this.displayedColumns;
          //if (this.viewOptionId == enumApportionmentViewOptions.SummaryView) {
          this.displayedColumns[0].summaryView = true;
          //}
          if (this.viewOptionId == enumApportionmentViewOptions.UserDefined) {
            if (this.displayedColumns[0].checked) {
              this.displayedColumns[0].visible = true;
              this.displayedColumns[0].isAlwaysVisible = true;
            }
            else {
              this.displayedColumns[0].visible = false;
              this.displayedColumns[0].isAlwaysVisible = false;
            }
          }
          else {
            this.showHideGroupColumn(this.viewOptionId);
          }
        }
        else {
          this.apportionmentService.formData.isMesothelioma = false;
          this.displayedDataList = _.filter(this.displayedDataList, function (o) { return o.data != 1; });
          this.displayedColumns[0].visible = false;
          this.displayedColumns[0].isAlwaysVisible = false;
          this.displayedColumns[0].checked = false;
          this.displayedColumns[0].summaryView = false;
        }
        this.refreshGrid();
        if (response[0].claimType == 1 || response[0].claimType == 11 || response[0].claimType == 12) {
          this.labelExposureOrAbuseFrom = "Abuse From";
          this.labelExposureOrAbuseTo = "Abuse To";
        }
        else {
          this.labelExposureOrAbuseFrom = "Exposure From";
          this.labelExposureOrAbuseTo = "Exposure To";
        }
        this.showHideLOIExportButton(response[0].lineOfBusiness);
        //this.setExcessType();
        //this.setMultiSelectDropdownValues();
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      );
  }

  resetPolicy() {
    if (confirm("Are you sure you want to reset the Data")) {
      this.loadApportionmentData(this.claimId);
      this.refreshGrid();
      this.refreshNotesGrid();
      this.apportionmentNotes.comments = '';
      this.isValidApportionmentNotes = true;
      if (this.form != undefined)
        this.form.form.markAsUntouched();
    }
  }

  onPolicyChange() {
    this.apportionmentService.formData.apportionmentPolicy.insuredTitle = this.apportionmentClaimPolicy.find(x => x.policyId == this.apportionmentService.formData.apportionmentPolicy.policyId).insuredTitle
    this.apportionmentService.formData.apportionmentPolicy.policyNumber = this.apportionmentClaimPolicy.find(x => x.policyId == this.apportionmentService.formData.apportionmentPolicy.policyId).policyNumber
    this.coverFrom = this.apportionmentClaimPolicy.find(x => x.policyId == this.apportionmentService.formData.apportionmentPolicy.policyId).coverFrom;
    this.coverTo = this.apportionmentClaimPolicy.find(x => x.policyId == this.apportionmentService.formData.apportionmentPolicy.policyId).coverTo;
    this.apportionmentService.formData.apportionmentPolicy.isPolicyExcessFlag = this.apportionmentClaimPolicy.find(x => x.policyId == this.apportionmentService.formData.apportionmentPolicy.policyId).isPolicyExcessFlag;
  }

  loadApportionmentData(claimid) {

    // CR-18 Merge Request Changes
    //if (this.storageService.retrieve("apportionmentData") != null) {
    //  this.apportionmentService.formData = this.storageService.retrieve("apportionmentData");
    //  this.loadPolicyClaimData(claimid);
    //  if (this.apportionmentService.formData.apportionmentPolicies != null) {
    //    this.apportionmentPolicyResult = this.apportionmentService.formData.apportionmentPolicies;
    //    if (this.apportionmentPolicyResult !== null) {
    //      this.calculateExposureSum();
    //      this.calculateDamagesSum();
    //      this.calculateDefenceCostsSum();
    //      this.calculateCRUSum();
    //      this.calculateCostSum();
    //      this.refreshGrid();
    //      this.refreshNotesGrid();
    //    }
    //  }
    //}
    //else {
    this.apportionmentService.getApportionment(claimid)
      .subscribe(response => {
        if (response == null) {
          this.resetForm(claimid);
          this.apportionmentPolicyResult = [];
        } else {
          this.apportionmentService.formData = response;
          this.onInitVersion = this.apportionmentService.formData.version;
          this.apportionmentService.formData.apportionmentPolicy = {
            abusefrom: null, abuseto: null, apportionmentSummaryID: null, defendantGroup: '', defendantName: '', mmiInsured: null,
            policyId: null, insuredTitle: '', insurerRepresentative: '', days: 0, contributesToDamages: null, contributesToDefenceCosts: null,
            fscsProtected: 0, damages: 0.1, cost: 0.1, cru: 0.1, defenceCosts: 0.1, fscs: 0.1, fscsDays: 0, groupName: '', id: null, policyNumber: '', index: null, isPolicyDateOutRangeFlag: false, isPolicyExcessFlag: false
          };
          if (response.apportionmentPolicies != null) {
            this.apportionmentPolicyResult = response.apportionmentPolicies;
            if (this.apportionmentPolicyResult !== null) {
              this.calculateExposureSum();
              this.calculateDamagesSum();
              this.calculateDefenceCostsSum();
              this.calculateCRUSum();
              this.calculateCostSum();

            }
            this.updatePolicyDatesOutRangeFlag();
            this.checkLOIAmountUpdatedFlag();
          }

        }
        this.loadPolicyClaimData(claimid);
        this.checkPolicyExcessFlagAndSetExcessType();
        //this.showFSCSProtectedOverridenValue();
        this.enableDisableCompensationActCheckbox();
        //this.overrideFSCSProtected(this.isCompensationActApplicable);
        //this.setMultiSelectDropdownValues();
        this.refreshGrid();
        this.refreshNotesGrid();
        if (this.form != undefined)
          this.form.form.markAsUntouched();

      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        });

  }

  deleteApportionment(i) {
    if (confirm("Are you sure you want to delete this selected row from the Apportionment Table?")) {
      this.apportionmentService.formData.apportionmentPolicies = this.apportionmentService.formData.apportionmentPolicies.filter(function (place, index) {
        return index != i
      });
      this.form.form.markAsTouched();
      this.apportionmentService.calculateApportionment().subscribe(res => {

        this.refreshNotesGrid();
        this.apportionmentService.formData = res;
        this.apportionmentService.formData.version = this.onInitVersion;
        this.apportionmentPolicyResult = res.apportionmentPolicies;
        if (this.apportionmentPolicyResult !== null) {
          this.calculateExposureSum();
          this.calculateDamagesSum();
          this.calculateDefenceCostsSum();
          this.calculateCRUSum();
          this.calculateCostSum();
          //this.showFSCSProtectedOverridenValue();
          this.enableDisableCompensationActCheckbox();
          //this.overrideFSCSProtected(this.isCompensationActApplicable);
          this.refreshGrid();

        }
        this.updatePolicyDatesOutRangeFlag();
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      )
    }
  }


  private handleError(error: any) {
    this.errorReceived = true;
    return Observable.throw(error);
  }

  getApportionmentNotes() {
    this.apportionmentNotes = {
      id: null,
      apportionmentSummaryID: this.apportionmentService.formData.id,
      notesDate: new Date(),
      comments: this.apportionmentNotes.comments,
      updatedByUserName: this.securityService.getUserData().id.toString() + " - " + this.securityService.getUserData().claimsHandlers,
      isNewAdded: true
    };
  }


  validateForm(): boolean {
    this.isValid = true;
    this.isFormSubmitted = true;
    this.isValidMMILeadExcessType = true;
    if (this.apportionmentService.formData.id == null) {
      this.notificationService.printWarningMessage("Please enter at least one row to save apportionment.");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }
    if (this.apportionmentService.formData.id > 0 && (this.apportionmentService.formData.apportionmentPolicies == null || this.apportionmentService.formData.apportionmentPolicies.length == 0)) {
      this.notificationService.printWarningMessage("Please enter at least one row to save apportionment.");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }

    if (this.apportionmentService.formData.excessType == null) {
      this.notificationService.printWarningMessage("Please select value for Excess Type")
      this.isValid = false;
      this.isValidMMILeadExcessType = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }
    if (this.apportionmentService.formData.mmiLead == null) {
      this.notificationService.printWarningMessage("Please select value for MMI Lead")
      this.isValid = false;
      this.isValidMMILeadExcessType = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }
    return this.isValid;

  }

  validatePolicy(): boolean {
    this.isValid = true;
    this.isValidMMILeadExcessType = true;
    //if (this.apportionmentService.formData.excessType == null) {
    //  this.notificationService.printWarningMessage("Please select value for Excess Type")
    //  this.isValid = false;
    //  return false;
    //}
    //if (this.apportionmentService.formData.mmiLead == null) {
    //  this.notificationService.printWarningMessage("Please select value for MMI Lead")
    //  this.isValid = false;
    //  return false;
    //}
    if (this.apportionmentService.formData.apportionmentPolicy.mmiInsured == 1 && this.apportionmentService.formData.apportionmentPolicy.policyId != null) {
      this.coverFrom = this.apportionmentClaimPolicy.find(i => i.policyId == this.apportionmentService.formData.apportionmentPolicy.policyId).coverFrom
      this.coverTo = this.apportionmentClaimPolicy.find(i => i.policyId == this.apportionmentService.formData.apportionmentPolicy.policyId).coverTo
    }
    if (!this.apportionmentService.formData.apportionmentPolicy.defendantName
      || !this.apportionmentService.formData.apportionmentPolicy.insurerRepresentative
      || (this.apportionmentService.formData.apportionmentPolicy.mmiInsured == null)
      || this.apportionmentService.formData.apportionmentPolicy.abusefrom == null
      || this.apportionmentService.formData.apportionmentPolicy.abuseto == null || (this.apportionmentService.formData.apportionmentPolicy.contributesToDamages == null) ||
      (this.apportionmentService.formData.apportionmentPolicy.contributesToDefenceCosts == null)) {
      this.isValid = false;
      return false;
    }
    if (this.apportionmentService.formData.overwrite && (!this.apportionmentService.formData.overwriteMMIDamages || !this.apportionmentService.formData.overwriteMMIDamages)) {
      this.isValid = false;
      return false;
    }
    if (this.apportionmentService.formData.apportionmentPolicy.mmiInsured && !this.apportionmentService.formData.apportionmentPolicy.policyId) {
      this.isValid = false;
      return false;
    }

    if (this.apportionmentService.formData.apportionmentPolicy.mmiInsured && this.coverFrom != undefined && this.coverFrom != null) {
      if (this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abusefrom) < this.getDateOnly(this.coverFrom) || this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abusefrom) > this.getDateOnly(this.coverTo)) {
        this.notificationService.printWarningMessage("Abuse/Exposure from date should be between Policy cover start and end date");
        this.isValid = false;
        return false;
      }
    }
    if (this.apportionmentService.formData.apportionmentPolicy.mmiInsured && this.coverTo != undefined && this.coverTo != null) {
      if (this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abuseto) < this.getDateOnly(this.coverFrom) || this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abuseto) > this.getDateOnly(this.coverTo)) {
        this.notificationService.printWarningMessage("Abuse/Exposure to date should be between Policy cover start and end date");
        this.isValid = false;
        return false;
      }
    }
    if (this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abusefrom) > this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abuseto)) {
      this.notificationService.printWarningMessage("Exposure/Abuse from date should be less than Exposure/Abuse to Date");
      this.isValid = false;
      return false;
    }

    if (!this.checkOverlappingDates()) {
      this.isValid = false;
      return false;
    }


    //this.apportionmentService.formData.apportionmentPolicies.forEach(value => {

    //  //if (index != this.apportionmentService.formData.apportionmentPolicy.index)
    //  if (!(this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abusefrom) > this.getDateOnly(value.abuseto) ||
    //      (this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abusefrom) < this.getDateOnly(value.abusefrom) &&
    //      this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abuseto) < this.getDateOnly(value.abusefrom))))
    //    {
    //    this.notificationService.printWarningMessage("Overlapping dates are not allowed");
    //    this.isValid = false;
    //    return;
    //    //return false;

    //  }
    //});
    return this.isValid;
  }

  checkOverlappingDates(): boolean {
    if (this.isEditFlag && this.apportionmentPolicyResult.length == 1) {
      this.isValid = true;
      return true;
    }
    let index = this.apportionmentService.formData.apportionmentPolicy.index;
    let apportionmentGridData;
    if (this.isEditFlag && this.apportionmentPolicyResult.length > 1) {
      apportionmentGridData = this.apportionmentPolicyResult.filter(function (place, i) {
        return index != i;
      });
    } else {
      apportionmentGridData = this.apportionmentPolicyResult;
    }
    apportionmentGridData.forEach(value => {
      if (this.isValid)
        if (!(this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abusefrom) > this.getDateOnly(value.abuseto) ||
          (this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abusefrom) < this.getDateOnly(value.abusefrom) &&
            this.getDateOnly(this.apportionmentService.formData.apportionmentPolicy.abuseto) < this.getDateOnly(value.abusefrom)))) {
          this.notificationService.printWarningMessage("Overlapping dates are not allowed");
          this.isValid = false;
          return;
        }
    });

    return this.isValid;
  }


  calculateExposureSum() {
    this.exposureDaysSum = _.sumBy(this.apportionmentPolicyResult, function (apportionmentPolicyResult) {
      return apportionmentPolicyResult.days;
    });
  }

  calculateCostSum() {
    this.costsSum = _.sumBy(this.apportionmentPolicyResult, function (apportionmentPolicyResult) {
      return apportionmentPolicyResult.cost;
    });
  }
  calculateCRUSum() {
    this.cruSum = _.sumBy(this.apportionmentPolicyResult, function (apportionmentPolicyResult) {
      return apportionmentPolicyResult.cru;
    });
  }
  calculateDefenceCostsSum() {
    this.defenceCostsSum = _.sumBy(this.apportionmentPolicyResult, function (apportionmentPolicyResult) {
      return apportionmentPolicyResult.defenceCosts;
    });
  }
  calculateDamagesSum() {
    this.damagesSum = _.sumBy(this.apportionmentPolicyResult, function (apportionmentPolicyResult) {
      return apportionmentPolicyResult.damages;
    });
  }

  clearFields() {
    this.showApportionmentAddPanelFlag = false;
    this.isValid = true;
    this.apportionmentService.formData.apportionmentPolicy = {
      abusefrom: null, abuseto: null, apportionmentSummaryID: null, defendantGroup: '', defendantName: '', mmiInsured: null,
      policyId: null, insuredTitle: '', insurerRepresentative: '', days: 0, contributesToDamages: null, contributesToDefenceCosts: null,
      fscsProtected: 0, damages: 0.1, cost: 0.1, cru: 0.1, defenceCosts: 0.1, fscs: 0.1, fscsDays: 0, groupName: '', id: null, policyNumber: '', index: null, isPolicyDateOutRangeFlag: false, isPolicyExcessFlag: false
    };
    this.apportionmentNotes.comments = '';


  }

  refreshGrid() {
    if (this.apportionmentPoliciesTable) {
      if (this.datatableElements.toArray()[0] && this.datatableElements.toArray()[0].dtInstance) {
        this.datatableElements.toArray()[0].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  addApportionmentNotes() {
    this.getApportionmentNotes();

    if (this.validateNotes()) {
      this.apportionmentService.formData.apportionmentNotes.push(this.apportionmentNotes);
      this.apportionmentNotes = new ApportionmentNotes();
      this.refreshNotesGrid();
    }
  }

  validateNotes() {
    this.isValidApportionmentNotes = true;
    if (!this.apportionmentNotes.comments) {
      this.notificationService.printWarningMessage("Please add some comments");
      this.isValidApportionmentNotes = false;
      return false;
    }
    else {
      return true;
    }

  }

  refreshNotesGrid() {
    if (this.apportionmentNotesTable) {
      if (this.datatableElements.toArray()[1] && this.datatableElements.toArray()[1].dtInstance) {
        this.datatableElements.toArray()[1].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtNotesTrigger.next();
        });
      }
      else {
        this.dtNotesTrigger.next();
      }
      this.isNotesGridLoaded = true;
    }
  }


  //setExcessType() {
  //  if (this.apportionmentService.formData.isMesothelioma == false) {
  //    this.apportionmentService.formData.excessType = 1;

  //  }
  //}

  showHideLOIExportButton(lineOfBusiness: number) {
    if (lineOfBusiness == 146) {
      this.showLOIExportButton = false;
    } else { this.showLOIExportButton = true; }
  }

  onViewHideItemSelect(item: any) {
    //this.displayedColumns = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    //this.displayedColumns.find(i => i.data == item.data).visible = true;
    let c = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    c[0].visible = true;
    c[0].checked = true;
    this.refreshGrid();
    //c[0].visible = true;
  }

  onViewHideItemDeSelect(item: any) {
    let c = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    this.displayedColumns.find(i => i.data == item.data).visible = false;
    this.displayedColumns.find(i => i.data == item.data).checked = false;
    this.refreshGrid();
  }

  //columnsForMultiSelect() {
  //  let c = _.filter(this.displayedColumns, function (o) { return o.isAlwaysVisible == true; });
  //  return c;
  //}

  onViewOptionChange(optionId: any) {
    if (optionId == enumApportionmentViewOptions.FullView) {
      this.showMultiselectDropdown = false;
      this.displayedColumns.forEach((a) => {
        if (a.isAlwaysVisible == true) {
          a.visible = true;
        }
      });
    }
    else if (optionId == enumApportionmentViewOptions.SummaryView) {
      this.showMultiselectDropdown = false;
      this.displayedColumns.forEach((a) => {
        if (a.summaryView == false) {
          a.visible = false;
        }
        else {
          a.visible = true;
        }
      });
    }
    else {
      this.showMultiselectDropdown = true;
      this.displayedColumns.forEach((a) => {
        if (a.checked == true && a.isAlwaysVisible) {
          a.visible = true;
        }
        else {
          a.visible = false;
        }
      });
    }
    this.showHideGroupColumn(optionId);
    this.refreshGrid();
    this.viewOptionId = optionId
  }

  showHideGroupColumn(optionId: any) {
    if (this.apportionmentService.formData != null && this.apportionmentService.formData != undefined) {
      if (this.apportionmentService.formData.isMesothelioma != null && this.apportionmentService.formData.isMesothelioma != undefined) {
        if (optionId == enumApportionmentViewOptions.FullView || optionId == enumApportionmentViewOptions.SummaryView) {
          if (this.apportionmentService.formData.isMesothelioma) {
            this.displayedColumns.forEach((a) => {
              if (a.title == 'Group') {
                a.visible = true;
              }
            });
          }
          else if (!this.apportionmentService.formData.isMesothelioma) {
            this.displayedColumns.forEach((a) => {
              if (a.title == 'Group') {
                a.visible = false;
              }
            });
          }
        }
      }
    }
  }

  onViewHideSelectAll() {
    this.displayedColumns.forEach(a => {
      a.isAlwaysVisible ? a.visible = true : a.visible = false;
      a.checked = true;
    });

    this.refreshGrid();
  }

  onViewHideDeSelectAll() {
    this.displayedColumns.forEach(a => {
      a.visible = false;
      a.checked = false;
    });
    this.refreshGrid();
  }

  setMultiSelectDropdownValues() {

    this.displayedColumns.forEach(a => {
      if (a.isAlwaysVisible == true) {
        this.displayedDataList.push(a);
      }
    });
    this.displayedColumns.forEach(a => {
      if (a.checked == true && a.isAlwaysVisible == true) {
        this.selectedItems.push(a);
      }
    });
  }


  public newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    console.log("buttons");
    //console.log($.fn.dataTable.ext.buttons);
    dt.one('preXhr', function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 500;

      dt.one('preDraw', function (e, settings) {
        // Call the original action function

        oldExportAction(self, e, dt, button, config);

        dt.one('preXhr', function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    //  // Requery the server with the new one-time export settings
    dt.ajax.reload();

    function oldExportAction(self, e, dt, button, config) {
      if (button[0].className.indexOf('buttons-excel') >= 0) {
        //this.datatableElement.dtInstance.
        var buttons: any = ($.fn.dataTable.ext as any).buttons;
        if (buttons.excelHtml5.available(dt, config)) {
          buttons.excelHtml5.action.call(self, e, dt, button, config);
        }
        else {
          buttons.excelFlash.action.call(self, e, dt, button, config);
        }
      } else if (button[0].className.indexOf('buttons-print') >= 0) {
        buttons.print.action(e, dt, button, config);
      }
    }
  };
  onMMIInsuredChange() {

    if (this.apportionmentService.formData.apportionmentPolicy.mmiInsured == 0) {
      this.apportionmentService.formData.apportionmentPolicy.policyId = null,
        this.apportionmentService.formData.apportionmentPolicy.policyNumber = '',
        this.apportionmentService.formData.apportionmentPolicy.insurerRepresentative = '';
      this.insurerRepresentativeDisabledFlag = false;

    }
    else if (this.apportionmentService.formData.apportionmentPolicy.mmiInsured == 1) {
      this.apportionmentService.formData.apportionmentPolicy.policyId = null
      this.apportionmentService.formData.apportionmentPolicy.insuredTitle = '',
        this.apportionmentService.formData.apportionmentPolicy.insurerRepresentative = this.insurerRepresentative;
      this.insurerRepresentativeDisabledFlag = true;
    }
  }
  getDateOnly(date: Date) {
    if (typeof date == 'string')
      var newDate = new Date(date);
    else
      var newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  handleOverwriteMMIDamagesPercentageValue(event) {
    if (event.target.value != null) {
      let currentValue = parseInt(event.target.value) * 1;
      if (currentValue >= 100) {
        event.target.value = 100;
        this.apportionmentService.formData.overwriteMMIDamages = parseInt('100');
      }
      else if (currentValue <= 0 || isNaN(currentValue)) {
        event.target.value = 0;
        this.apportionmentService.formData.overwriteMMIDamages = parseInt('0');
      }
    }
    if (event.target.value == null || event.target.value == "") {
      event.target.value = 0;
      this.apportionmentService.formData.overwriteMMIDamages = parseInt('0');
    }
  }

  handleOverwriteMMICostsPercentageValue(event) {
    if (event.target.value != null) {
      let currentValue = parseInt(event.target.value) * 1;
      if (currentValue >= 100) {
        event.target.value = 100;
        this.apportionmentService.formData.overwriteMMICosts = parseInt('100');
      }
      else if (currentValue <= 0 || isNaN(currentValue)) {
        event.target.value = 0;
        this.apportionmentService.formData.overwriteMMICosts = parseInt('0');
      }
    }
    if (event.target.value == null || event.target.value == "") {
      event.target.value = 0;
      this.apportionmentService.formData.overwriteMMICosts = parseInt('0');
    }
  }

  handleOverwriteMMICRUPercentageValue(event) {
    if (event.target.value != null) {
      let currentValue = parseInt(event.target.value) * 1;
      if (currentValue >= 100) {
        event.target.value = 100;
        this.apportionmentService.formData.overwriteMMICRU = parseInt('100');
      }
      else if (currentValue <= 0 || isNaN(currentValue)) {
        event.target.value = 0;
        this.apportionmentService.formData.overwriteMMICRU = parseInt('0');
      }
    }
    if (event.target.value == null || event.target.value == "") {
      event.target.value = 0;
      this.apportionmentService.formData.overwriteMMICRU = parseInt('0');
    }
  }

  handleOverwriteMMIDefenceCostsPercentageValue(event) {
    if (event.target.value != null) {
      let currentValue = parseInt(event.target.value) * 1;
      if (currentValue >= 100) {
        event.target.value = 100;
        this.apportionmentService.formData.overwriteMMIDefenceCosts = parseInt('100');
      }
      else if (currentValue <= 0 || isNaN(currentValue)) {
        event.target.value = 0;
        this.apportionmentService.formData.overwriteMMIDefenceCosts = parseInt('0');
      }
    }
    if (event.target.value == null || event.target.value == "") {
      event.target.value = 0;
      this.apportionmentService.formData.overwriteMMIDefenceCosts = parseInt('0');
    }
  }

  verifyMMIPercentageBeforeToSave() {
    if (this.apportionmentService.formData.overwriteMMIDamages != null)
      this.apportionmentService.formData.overwriteMMIDamages = this.apportionmentService.formData.overwriteMMIDamages <= 0 ? 0 : this.apportionmentService.formData.overwriteMMIDamages > 100 ? 100 : this.apportionmentService.formData.overwriteMMIDamages;
    if (this.apportionmentService.formData.overwriteMMIDamages == null)
      this.apportionmentService.formData.overwriteMMIDamages = 0;
    if (String(this.apportionmentService.formData.overwriteMMIDamages).split('.').length == 2 && parseInt(String(this.apportionmentService.formData.overwriteMMIDamages).split('.')[0]) > 0 && parseInt(String(this.apportionmentService.formData.overwriteMMIDamages).split('.')[0]) < 100)
      this.apportionmentService.formData.overwriteMMIDamages = this.preciseToDecimal(this.apportionmentService.formData.overwriteMMIDamages);

    if (this.apportionmentService.formData.overwriteMMICosts != null)
      this.apportionmentService.formData.overwriteMMICosts = this.apportionmentService.formData.overwriteMMICosts <= 0 ? 0 : (this.apportionmentService.formData.overwriteMMICosts) > 100 ? 100 : this.apportionmentService.formData.overwriteMMICosts;
    if (this.apportionmentService.formData.overwriteMMICosts == null)
      this.apportionmentService.formData.overwriteMMICosts = 0;
    if (String(this.apportionmentService.formData.overwriteMMICosts).split('.').length == 2 && parseInt(String(this.apportionmentService.formData.overwriteMMICosts).split('.')[0]) > 0 && parseInt(String(this.apportionmentService.formData.overwriteMMICosts).split('.')[0]) < 100)
      this.apportionmentService.formData.overwriteMMICosts = this.preciseToDecimal(this.apportionmentService.formData.overwriteMMICosts);

    if (this.apportionmentService.formData.overwriteMMICRU != null)
      this.apportionmentService.formData.overwriteMMICRU = this.apportionmentService.formData.overwriteMMICRU <= 0 ? 0 : (this.apportionmentService.formData.overwriteMMICRU) > 100 ? 100 : this.apportionmentService.formData.overwriteMMICRU;
    if (this.apportionmentService.formData.overwriteMMICRU == null)
      this.apportionmentService.formData.overwriteMMICRU = 0;
    if (String(this.apportionmentService.formData.overwriteMMICRU).split('.').length == 2 && parseInt(String(this.apportionmentService.formData.overwriteMMICRU).split('.')[0]) > 0 && parseInt(String(this.apportionmentService.formData.overwriteMMICRU).split('.')[0]) < 100)
      this.apportionmentService.formData.overwriteMMICRU = this.preciseToDecimal(this.apportionmentService.formData.overwriteMMICRU);

    if (this.apportionmentService.formData.overwriteMMIDefenceCosts != null)
      this.apportionmentService.formData.overwriteMMIDefenceCosts = this.apportionmentService.formData.overwriteMMIDefenceCosts <= 0 ? 0 : (this.apportionmentService.formData.overwriteMMIDefenceCosts) > 100 ? 100 : this.apportionmentService.formData.overwriteMMIDefenceCosts;
    if (this.apportionmentService.formData.overwriteMMIDefenceCosts == null)
      this.apportionmentService.formData.overwriteMMIDefenceCosts = 0;
    if (String(this.apportionmentService.formData.overwriteMMIDefenceCosts).split('.').length == 2 && parseInt(String(this.apportionmentService.formData.overwriteMMIDefenceCosts).split('.')[0]) > 0 && parseInt(String(this.apportionmentService.formData.overwriteMMIDefenceCosts).split('.')[0]) < 100)
      this.apportionmentService.formData.overwriteMMIDefenceCosts = this.preciseToDecimal(this.apportionmentService.formData.overwriteMMIDefenceCosts);
  }

  preciseToDecimal(decimalNum) {
    if (String(decimalNum).split('.').join('').length >= 5) {
      let fifthNum = String(decimalNum).split('.').join('')[4];
      console.log(fifthNum);
      if (parseInt(fifthNum) >= 5) {
        decimalNum = String(decimalNum).split('.').join('').slice(0, 4);
        decimalNum = (parseInt(decimalNum) + 1) / 100;
      }
      else {
        decimalNum = parseInt(String(decimalNum).split('.').join('').slice(0, 4)) / 100;
      }
    }
    return decimalNum;
  }

  downloadExcessDetails() {
    this.apportionmentService.downloadExcessDetails(this.claimId, this.form.controls.excessType.value);
  }


  downloadLOIDetails() {
    this.apportionmentService.downloadLOIDetails(this.claimId);
  }

  updatePolicyDatesOutRangeFlag() {
    if (this.apportionmentService.formData.apportionmentPolicies.filter(i => i.isPolicyDateOutRangeFlag == true).length > 0) {
      this.isPolicyDateFlag = true;
    }
    else {
      this.isPolicyDateFlag = false;
    }
  }

  checkPolicyExcessFlagAndSetExcessType() {
    this.hideExcess = false;
    if (!(this.apportionmentService.formData.apportionmentPolicies.filter(i => i.isPolicyExcessFlag == true).length > 0)) {
      this.hideExcess = true;
      this.apportionmentService.formData.excessType = 3;
    }
    else {
      this.apportionmentService.formData.excessType = 1;
    }
  }

  checkLOIAmountUpdatedFlag() {
    if (this.apportionmentService.formData.isLOIAmountUpdatedInSettings)
      this.isLOIDefaultSettingsUpdated = true;
  }

  getClaimEFileUrl(claimId) {
    this.claimDetailService.GetClaimEFileUrl(claimId).subscribe((res: any) => {
      console.log(res);
      if (res != null) {
        this.eFileURL = res.eFileUrl.item1;
      }
    },
      error => {
        console.log(error);
      });
  }

  getEFile() {
    if (this.eFileURL != '') {
      this.goToLink(this.eFileURL);
    }
    else {
      if (confirm("Claim folder does not exist for this claim. Do you want to create.")) {
        this.createClaimFolder();
      }
    }
  }

  createClaimFolder() {
    let claimId = parseInt(this.activeRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimDetailService.createClaimFolder(claimId).subscribe(res => {
      if (res != null) {
        this.eFileURL = res.filepath;
        if (this.eFileURL != '') {
          this.goToLink(this.eFileURL);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  checkForNewClaim(claimId) {
    if (claimId > 0) {
      this.isNewClaim = false;
      this.getClaimEFileUrl(claimId);
    } else {
      this.isNewClaim = true;
    }
  }

  overrideFSCSProtected(isCompensationAct) {

    if (isCompensationAct) {
      this.apportionmentService.formData.fscsProtected = 100;
    }
    else {
      this.apportionmentService.calculateApportionment().subscribe(res => {
        this.apportionmentService.formData.fscsProtected = res.fscsProtected;
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      )
    }
  }

  //showFSCSProtectedOverridenValue() {
  //  if (this.apportionmentService.formData.isCompensationAct) {
  //    this.apportionmentService.formData.fscsProtected = 100; 
  //  }
  //}

  enableDisableCompensationActCheckbox() {
    if (this.apportionmentPolicyResult.length > 1 && this.apportionmentService.formData.fscsProtected > 0) {
      this.isCompensationActApplicable = (this.apportionmentPolicyResult.filter(i => i.mmiInsured == 1).length > 0) ? true : false;
      //this.apportionmentService.formData.isCompensationAct = true;
      //this.apportionmentService.formData.fscsProtected = 100; 
    }
    else {
      this.isCompensationActApplicable = false;
      this.apportionmentService.formData.isCompensationAct = false;

    }

    //this.showFSCSProtectedOverridenValue();

  }
  navigateToWorkflowComponent() {
    this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/add-workflow-item']);
  }

}


  //updateClaimsData() {
  //  if (this.storageService.retrieve("claimsData") != null) {
  //    this.claimDetailService.saveOrUpdateClaim(this.storageService.retrieve("claimsData")).subscribe(res => { },
  //      error => {
  //        this.notificationService.printErrorMessage(error.message);
  //      });
  //  }
  //}

  //saveOrUpdateValuationData() {
  //  if (this.storageService.retrieve("valuationData") != null) {
  //    var valuationData = this.storageService.retrieve("valuationData");
  //    var body = {
  //      ...valuationData,
  //      valuationNotes: []
  //    };
  //    if (valuationData.id > 0) {
  //      this.valuationService.updateValuation(body).subscribe(res => { },
  //        error => {
  //          this.notificationService.printErrorMessage(error.message);
  //        });
  //    }
  //    else {
  //      this.valuationService.createValuation(body).subscribe(res => { },
  //        error => {
  //          this.notificationService.printErrorMessage(error.message);
  //        });
  //    }
  //  }

  //}





  //getApportionment(claimId): Promise<any> {
  //  this.errorReceived = true;
  //  var promise = new Promise((resolve, reject) => {
  //    this.apportionmentService.getApportionment(claimId)
  //      .pipe(catchError((err) => this.handleError(err)))
  //      .subscribe(response => {
  //        this.apportionmentPolicyResult = response;
  //        resolve();
  //      });
  //  });
  //  return promise;
  //}
 //initialiseDataTable(): void {
  //  const that = this;
  //  this.dtOptions = {
  //    pagingType: 'full_numbers',
  //    pageLength: 50,
  //    columns: this.displayedColumns,
  //    fixedColumns: true,
  //    processing: true,
  //    searching: false,
  //    serverSide: true,
  //    // Declare the use of the extension in the dom parameter
  //    dom: 'Bfrtip',
  //    // Configure the buttons
  //    buttons: [
  //      {
  //        extend: 'excelHtml5',
  //        text: '<i class="fa fa-file-excel fa-lg"></i>',
  //        titleAttr: 'Excel',
  //        tag: ''

  //      }
  //    ],
  //    'columnDefs': [
  //      {
  //        render: function (data, type, row) {
  //          return '<button class="edit-button"><i class="fa fa-edit"></i></button>' + " " + '<button class="remove-button"><i class="fa fa-trash"></i></button>';
  //        },
  //        targets: 17
  //      }],
  //    ajax: (dataTablesParameters: any, callback) => {
  //      var self = this;
  //      this.getApportionment(1).then(resp => {
  //        callback({
  //          data: that.apportionmentPolicyResult
  //        });
  //      });
  //    }
  //  };

  //}

 //getApportionmentData(): void {
  //  //For LookUp Reference

  //  this.isHidden = false;
  //  if (this.datatableElement.dtInstance) {
  //    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //      dtInstance.destroy();
  //      this.dtTrigger.next();
  //    });
  //  }
  //  else {
  //    this.dtTrigger.next();
  //  }

  //}


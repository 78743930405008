import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BroadcastEmailMessageService {

  private broadcastMessageUrl: string = "/api/broadcastMessage/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.broadcastMessageUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.broadcastMessageUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.broadcastMessageUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.broadcastMessageUrl);
  }

  broadcastEmailMessageToUsers(broadcastMessageInput): any {
    let url = this.broadcastMessageUrl + 'broadcastMessageToUsers';
    return this.dataService.post(url, broadcastMessageInput);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentBordereauxService } from '../../../shared/services/payment-bordereaux.service'
import { IPaymentBordereaux } from '../../../shared/models/payment-bordereaux.model';
import { PaymentBordereaux } from '../../../shared/models/search-payment-bordereaux.model';


@Injectable({
  providedIn: 'root'
})
export class PayChecquesService {


  constructor(private paymentBordereauxService: PaymentBordereauxService) { }



  searchPayChequePayments(searchFilter: PaymentBordereaux): any {
    return this.paymentBordereauxService.searchChequePaymentsBordereaux(searchFilter);
  }

  approvePayChequePayments(paymentIds, loggedInUserId): any {
    return this.paymentBordereauxService.approveChequePaymentsBordereaux(paymentIds, loggedInUserId);
  }

  rejectPayChequePayments(paymentIds): any {
    return this.paymentBordereauxService.rejectChequePaymentsBordereaux(paymentIds);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//import { ReportsRoutingModule } from './reports-routing.module';
import { ClaimsReportComponent } from './claims-report/claims-report.component';
import { SharedModule } from '../../shared/shared.module';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CaseloadSummaryComponent } from './caseload-summary/caseload-summary.component';


@NgModule({
  declarations: [],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    SharedModule
    
  ]
})
export class ReportsModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { enumYesNo, enumLitigatedType } from '../../../shared/models/common.enum';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { LitigationReportService } from '../litigation-report/litigation-report.service';

import { LitigationReport } from '../../../models/litigation-report.model';
import * as _ from 'lodash';
import { SecurityService } from '../../../shared/services/security.service';


@Component({
  selector: 'app-litigation-report',
  templateUrl: './litigation-report.component.html',
  styleUrls: ['./litigation-report.component.css']
})
export class LitigationReportComponent implements OnInit {

  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  claimTypeLookup: any[];
  claimStatusLookup: any[];
  claimantSolicitorsLookup: any[];
  litigationCauseLookup: any[];
  handlingOrganisationData: any[];
  claimHandlersData: any[] = [{ "id": 0, "claimsHandlers": "All" }];
  claimReportTypes: any[];
  claimStatusId: number;

  newOrSettledClaimsFlag: boolean = false;
  openClaimsFlag: boolean = false;

  yesNoEnum = enumYesNo;
  avoidableLitigatedTypeEnum = enumLitigatedType;
  approvedForTrialLitigatedTypeEnum = enumLitigatedType;
  trialReviewCompleteLitigatedTypeEnum = enumLitigatedType;

  approvedForTrialLookup: any[];
  yesNokeys: any[];
  avoidableKeys: any[];
  trialReviewCompleteKeys: any[];
  isValid: boolean = true;

  //claimsReportInputModel: claimsReport;
  public litigationReportInputModel: LitigationReport = new LitigationReport();
  isMMIUser: boolean = false;

  constructor(private lookupService: LookupService,
    private litigationReportService: LitigationReportService,
    private notificationService: NotificationService,
    private securityService: SecurityService
  ) { }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 5,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  dropdownSettingsClaimHandlersData: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'claimsHandlers',
    enableCheckAll: true,
    itemsShowLimit: 5,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };


  ngOnInit(): void {
    this.InitializeData();
  }

  InitializeData() {
    this.initializeForm();
    this.claimTypeLookup = this.lookupService.getLookupsByTypeId(1);
    this.approvedForTrialLookup = this.lookupService.getLookupsByTypeId(45);

    this.claimStatusLookup = this.lookupService.getLookupsByTypeId(3);
    this.litigationCauseLookup = this.lookupService.getLookupsByTypeId(15);
    this.claimantSolicitorsLookup = this.lookupService.getLookupsByTypeId(44);
    this.claimTypeLookup.unshift({ "id": 0, "text": "All" });
    this.claimantSolicitorsLookup.unshift({ "id": 0, "text": "All" });
    this.litigationCauseLookup.unshift({ "id": 0, "text": "All" });
    this.approvedForTrialLookup.push({ "id": 0, "text": "All" });

    this.litigationReportService.getHandlingOrganization().subscribe(response => { this.handlingOrganisationData = response; });

    this.claimStatusLookup = this.claimStatusLookup.filter(result => {
      return result.text == 'Open' || result.text == 'Settled';
    })

    this.yesNokeys = Object.values(this.yesNoEnum).filter(Number);
    this.avoidableKeys = Object.values(this.avoidableLitigatedTypeEnum).filter(Number);
    //this.approvedForTrialKeys = Object.values(this.approvedForTrialLitigatedTypeEnum).filter(Number);
    this.trialReviewCompleteKeys = Object.values(this.trialReviewCompleteLitigatedTypeEnum).filter(Number);
    this.litigationReportInputModel.handlingOrganisationId = 0;
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
      if (!this.isMMIUser) {
        this.litigationReportService.formDataInput.handlingOrganisationId = this.securityService.getUserData().organisationId;
        this.onHandlingOrganisationChange();
      }
    }
  };

  generateLitigationReport() {
    
    this.litigationReportService.formDataInput.claimStatusValues = [];
    this.litigationReportService.formDataInput.claimTypeValues = _.map(this.litigationReportService.formDataInput.claimTypes, function (i: any) { return i.id });
    this.litigationReportService.formDataInput.litigationCauseValues = _.map(this.litigationReportService.formDataInput.litigationCauses, function (i: any) { return i.id });
    this.litigationReportService.formDataInput.claimantSolicitorIdValues = _.map(this.litigationReportService.formDataInput.claimantSolicitors, function (i: any) { return i.id });

    this.claimStatusId = this.litigationReportService.formDataInput.claimStatus;
    this.litigationReportService.formDataInput.claimHandlersIdValues = _.map(this.litigationReportService.formDataInput.claimHandlersId, function (i: any) { return i.id })

    if (this.validateForm()) {
      if (this.claimStatusId >= 0) {
        if (this.claimStatusId == 0) { this.litigationReportService.formDataInput.claimStatusValues.push(23, 24, 25, 26, 905, 1318) };
        if (this.claimStatusId == 23) { this.litigationReportService.formDataInput.claimStatusValues.push(23, 25) };
        if (this.claimStatusId == 24) { this.litigationReportService.formDataInput.claimStatusValues.push(24, 26, 905, 1318) };
      }

      //if (this.litigationReportService.formDataInput.reportType != enumReportsType.openClaims) {
      //  this.litigationReportService.formDataInput.dateFrom = this.getDateOnly(this.litigationReportService.formDataInput.dateFrom);
      //  this.litigationReportService.formDataInput.dateTo = this.getDateOnly(this.claimsReportService.formDataInput.dateTo);
      //}
      //else {
      //  this.litigationReportService.formDataInput.asAtDate = this.getDateOnly(this.litigationReportService.formDataInput.asAtDate);
      //}
      this.litigationReportService.getClaimsLitigationReport();
    } else {
      this.notificationService.printWarningMessage("Please select all mandatory fields");
    }
  }

  initializeForm() {
    this.litigationReportService.formDataInput = {
      handlingOrganisationId: null,
      claimTypes: [{ "id": 0, "text": "All" }],
      claimHandlersId: [{ "id": 0, "claimsHandlers": "All" }],
      claimHandlersIdValues: [],
      claimTypeValues: [],
      claimStatus: 0,
      claimStatusValues: [],
      claimantSolicitors: [{ "id": 0, "text": "All" }],
      claimantSolicitorIdValues: [],
      avoidable: 3,
      issueDate: null,
      serviceDate: null,
      trialDate: null,
      litigationCauses: [{ "id": 0, "text": "All" }],
      litigationCauseValues: [],
      trialReviewComplete: 3,
      approvedForTrial: 0,
      litigated:true
    }

  }

  onHandlingOrganisationChange() {
    if (this.litigationReportService.formDataInput.handlingOrganisationId == 0) {
      this.litigationReportService.formDataInput.claimHandlersIdValues = [];
      this.litigationReportService.formDataInput.claimHandlersId = [{ "id": 0, "claimsHandlers": "All" }];
      this.claimHandlersData = [];
      this.claimHandlersData = [{ "id": 0, "claimsHandlers": "All" }];
    }
    else {
      this.litigationReportService.getClaimHandlersByHandlingOrganisation(this.litigationReportService.formDataInput.handlingOrganisationId).subscribe(
        res => {
          this.litigationReportService.formDataInput.claimHandlersIdValues = [];
          this.litigationReportService.formDataInput.claimHandlersId = [{ "id": 0, "claimsHandlers": "All" }];
          this.claimHandlersData = [];
          this.claimHandlersData = _.map(res);
          this.claimHandlersData.unshift({ "id": 0, "claimsHandlers": "All" });
        });
    }
  }

  getDateOnly(date: Date) {
    if (typeof date == 'string')
      var newDate = new Date(date);
    else
      var newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  //onReportTypeChange() {
  //  if (this.litigationReportService.formDataInput.reportType != enumReportsType.openClaims) {
  //    this.newOrSettledClaimsFlag = true;
  //    this.openClaimsFlag = false;
  //  }
  //  else {
  //    this.newOrSettledClaimsFlag = false;
  //    this.openClaimsFlag = true;
  //  }
  //}

  validateForm(): boolean {
    this.isValid = true;
    if (this.litigationReportService.formDataInput.claimHandlersIdValues == null || this.litigationReportService.formDataInput.claimHandlersIdValues.length == 0 || this.litigationReportService.formDataInput.claimTypeValues == null ||
      this.litigationReportService.formDataInput.claimTypeValues.length == 0 || this.litigationReportService.formDataInput.handlingOrganisationId == null || this.litigationReportService.formDataInput.claimStatus == null ||
      this.litigationReportService.formDataInput.claimantSolicitorIdValues == null ||
      this.litigationReportService.formDataInput.claimantSolicitorIdValues.length == 0
      //|| this.litigationReportService.formDataInput.serviceDate == null
    ) {
      this.isValid = false;
      return false;
    }
    //if (this.litigationReportService.formDataInput.reportType == enumReportsType.newClaims || this.litigationReportService.formDataInput.reportType == enumReportsType.settledClaims) {
    //  if (this.litigationReportService.formDataInput.dateFrom == null || this.litigationReportService.formDataInput.dateTo == null) {
    //    this.isValid = false
    //    return false;
    //  }
    //}
    //if (this.litigationReportService.formDataInput.reportType == enumReportsType.openClaims && this.litigationReportService.formDataInput.asAtDate == null) {
    //  this.isValid = false;
    //  return false;
    //}
    return this.isValid;

  }
}

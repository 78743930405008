import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { NotificationService } from '../../../shared/services/notification.service';
import { DataService } from '../../../shared/services/data.service';
import { ReportsService } from '../../../shared/services/reports.service';
import { ELTOMatchingReport } from '../../../models/elto-matching-report.model';

@Injectable({
  providedIn: 'root'
})
export class EltoMatchingService {
  formDataInput: ELTOMatchingReport;

  constructor(private reportsService: ReportsService, private dataService: DataService, private notificationService: NotificationService, private http: HttpClient) { }

  getELTOMatchingReport(fileReceivedDate: string): any {
    return this.reportsService.getELTOMatchingReport(this.formDataInput, fileReceivedDate);
  }

  GetELTOWeeklyTracingReportsUrl(): any {
    return this.reportsService.GetELTOWeeklyTracingReportsUrl();
  }

}

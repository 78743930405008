import { Injectable } from '@angular/core';
import { AverageWeeklyWagesService } from '../../../shared/services/average-weekly-wages.service';
import { AverageWeeklyWage } from './weekly-wages.model';

@Injectable({
  providedIn: 'root'
})
export class WeeklyWagesService {

  formData: AverageWeeklyWage[] = [];

  constructor(private averageWeeklyWagesService: AverageWeeklyWagesService) { }

  getAverageWeeklyWages() {
    return this.averageWeeklyWagesService.getAverageWeeklyWages();
  }

  //getLookupsByTypeId(id: number) {
  //  return this.lookupDatasetupService.getLookupsByTypeId(id);
  //}

  postAverageWeeklyWage(averageWeeklyWage: AverageWeeklyWage) {
    return this.averageWeeklyWagesService.addAverageWeeklyWage(averageWeeklyWage);
  }

  updateAverageWeeklyWage(averageWeeklyWage: AverageWeeklyWage) {
    return this.averageWeeklyWagesService.updateAverageWeeklyWage(averageWeeklyWage);
  }

  deleteAverageWeeklyWage(averageWeeklyWage: AverageWeeklyWage) {
    return this.averageWeeklyWagesService.deleteAverageWeeklyWage(averageWeeklyWage);
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CancelChequesComponent } from './cancel-cheques/cancel-cheques.component';
import { PayBACSComponent } from './pay-bacs/pay-bacs.component';
import { PayChequesComponent } from './pay-cheques/pay-cheques.component';
import { SearchPaymentBordereauxComponent } from './search-payment-bordereaux/search-payment-bordereaux.component';
import { VerifyChequesComponent } from './verify-cheques/verify-cheques.component';
import { PaymentBordereauxComponent } from './payment-bordereaux.component';
import { RoleGuardService as RoleGuard } from '../../shared/services/role-guard.service';


const routes: Routes = [

  {
    path: 'payment-bordereaux', component: PaymentBordereauxComponent, children: [
      {
        path: '', component: SearchPaymentBordereauxComponent
      },
      {
        path: 'verify-cheques', component: VerifyChequesComponent
      },
      {
        path: 'cancel-cheques', component: CancelChequesComponent
      },
      {
        path: 'pay-cheques', component: PayChequesComponent
      },
      {
        path: 'pay-bacs', component: PayBACSComponent
      }
    ], canActivate: [RoleGuard], data: { userPermissionsId: '1152' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentBordereauxRoutingModule { }

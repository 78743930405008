import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPaymentDetail } from './payment-detail.model';
import { PaymentService } from '../../../shared/services/payment.service';
import { IBusinessRule } from './business-rule.model';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service'


@Injectable({
  providedIn: 'root'
})
export class PaymentDetailService {

  formData: IPaymentDetail;

  constructor(private paymentService: PaymentService,
    private handlingOrganisationService: HandlingOrganisationService) {
  }

  getPaymentByClaimId(id: number): any {
    return this.paymentService.getPaymentDetailById(id);
  }

  getTotalMMIReserveByClaimId(id: number, paymentCategoryId: number): any {
    return this.paymentService.getTotalMMIReserveById(id, paymentCategoryId);
  }

  getExcessByClaimId(id: number): Observable<IBusinessRule> {
    return this.paymentService.getExcessByClaimId(id);
  }

  getLOIByClaimId(id: number): any {
    return this.paymentService.getLOIByClaimId(id);
  }

  saveOrUpdatePayment(): any {
    var body = {
      ...this.formData
    };    
    if (this.formData.paymentDetail[0].id > 0 || this.formData.paymentDetail.length> 1)
      return this.paymentService.updatePaymentDetail(body);
    else
      return this.paymentService.createPaymentDetail(body);
  }

  getPaymentDetailInfoByPaymentId(claimId: number, paymentId: number): any {
    return this.paymentService.getPaymentDetailInformationByPaymentId(claimId, paymentId);
  }

  getLinkDocumentFolderUrl(claimId: number, folderName: string): any {
    return this.paymentService.getLinkDocumentFolderUrl(claimId, folderName);
  }

  getHandlingOrganisationById(id: number): any {
    return this.handlingOrganisationService.getHandlingOrganisationById(id);
  }

  checkIsPaymentsAvailable(claimId: number): any {
    return this.paymentService.checkIsPaymentsAvailable(claimId);
  }

}

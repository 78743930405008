import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import { IPager } from '../../../shared/models/pager.model';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { SecurityService } from '../../../shared/services/security.service';
import { LookupService } from 'src/app/shared/services/lookup.service'
import { SearchPaymentBordereauxService } from 'src/app/components/payment-bordereaux/search-payment-bordereaux/search-payment-bordereaux.service'
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { IPaymentBordereaux } from 'src/app/shared/models/payment-bordereaux.model'
import { IPaymentBordereauxFilter } from 'src/app/models/IPaymentBordereauxFilter'
import * as moment from 'moment';
import * as _ from "lodash";
import { NotificationService } from '../../../shared/services/notification.service';
import { enumPaymentRecovery, enumPaymentStatus, enumUnderQuery } from '../../../shared/models/common.enum';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../../shared/services/storage.service';
import { BaseComponentComponent } from '../../base-component/base-component.component';

@Component({
  selector: 'app-search-payment-bordereaux',
  templateUrl: './search-payment-bordereaux.component.html',
  styleUrls: ['./search-payment-bordereaux.component.css']
})
export class SearchPaymentBordereauxComponent extends BaseComponentComponent implements OnDestroy, OnInit {
  @ViewChild('paymentLinkedDocumentsInfo') paymentLinkedDocumentsModalContent: ElementRef;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  paymentBordereauxForm: FormGroup;
  public searchPaymentBordereaux: IPaymentBordereaux[];
  public searchFilter: any = {};
  public dtOptions: any = {};
  public paginationInfo: IPager;
  public dtTrigger: Subject<any>;
  errorReceived: boolean;
  isHidden: boolean = true;
  isValid: boolean;
  isSelectAllChecked: boolean;

  paymentRecoveryLookup: any[];
  payeeTypeLookup: any[];
  paymentCodeLookup: any[];
  paymentStatusLookup: any[];
  paymentTypeLookup: any[];
  handlingOrganisation: any[];
  loggedInUserPaymentAuthority: number;
  loggedInUserId: number;
  selectedListData: DataTables.Api;
  modalRef: NgbModalRef;
  underQueryEnum = enumUnderQuery;
  underQuerykeys: any[];
  claimTypeLookup: any[];
  selectedPaymentLinkedDocuments: any[];

  dropdownList = [];

  dropdownSettings = {};

    displayeDataList = [];
    displayedColumns: any[] = [
        { isAlwaysVisible: true, title: '<input type="checkbox" id="selectAll"/>', data: 'isSelected', visible: true, orderable: false },
        { isAlwaysVisible: true, title: 'Payment ID', data: 'paymentID', visible: true },
        { isAlwaysVisible: true, title: 'Linked Documents', data: 'paymentLinkedDocuments', visible: true },
        { isAlwaysVisible: true, title: 'Payment/Recovery', data: 'paymentRecovery', visible: true },
        { isAlwaysVisible: false, title: 'Handling Organisation ID', data: 'handlingOrganisationId', visible: false },
        { isAlwaysVisible: true, title: 'Handling Organisation', data: 'handlingOrganisationName', visible: true },
        { isAlwaysVisible: true, title: 'MMI Claim Reference', data: 'claimNumber', visible: true },
        { isAlwaysVisible: true, title: 'Claimant Name', data: 'claimant', visible: true },
        { isAlwaysVisible: true, title: 'Policyholder Name', data: 'policyHolder', visible: true },
        { isAlwaysVisible: true, title: 'Policyholder ID', data: 'policyHolderID', visible: true },
        { isAlwaysVisible: true, title: 'Policyholder Status', data: 'policyHolderStatus', visible: true },
        { isAlwaysVisible: true, title: 'Claim Type', data: 'claimType', visible: true },
        { isAlwaysVisible: true, title: 'FSCS % Protected', data: 'fscsProtected', visible: true, render: function (data) { return data == null ? "" : (((String(data).split('.').length === 1) && parseInt(data) > 0 && parseInt(data) < 100) ? (String(data).split('.')[0] + '.00') : ((String(data).split('.').length === 2) && (String(data).split('.')[1].length === 1)) ? (String(data).split('.')[0] + "." + String(data).split('.')[1] + "0") : data) + " " + "%" } },
        { isAlwaysVisible: true, title: 'Payment Code', data: 'paymentCode', visible: true },
        { isAlwaysVisible: true, title: 'Payment Type', data: 'paymentType', visible: true },
        { isAlwaysVisible: true, title: 'Payment Amount', data: 'paymentAmount', visible: true },
        { isAlwaysVisible: true, title: 'Payment Method', data: 'paymentMethod', visible: true },
        { isAlwaysVisible: true, title: 'Payee Type', data: 'payeeType', visible: true },
        { isAlwaysVisible: true, title: 'Payee Name', data: 'payeeName', visible: true },
        { isAlwaysVisible: true, title: 'Payee Override', data: 'payeeNameOverWrittenValue', visible: true },
        { isAlwaysVisible: true, title: 'Payee ID', data: 'payee', visible: true },
        { isAlwaysVisible: true, title: 'Payee/Invoice Ref.', data: 'invoiceRef', visible: true },
        { isAlwaysVisible: true, title: 'Addressee Name', data: 'addresseeName', visible: true },
        { isAlwaysVisible: true, title: 'Address Line 1', data: 'addressLine1', visible: true },
        { isAlwaysVisible: true, title: 'Address Line 2', data: 'addressLine2', visible: true },
        { isAlwaysVisible: true, title: 'Town', data: 'town', visible: true },
        { isAlwaysVisible: true, title: 'Country', data: 'county', visible: true },
        { isAlwaysVisible: true, title: 'Post Code', data: 'postCode', visible: true },
        { isAlwaysVisible: true, title: 'Address Override', data: 'addresseeOverWrittenValue', visible: true },
        { isAlwaysVisible: true, title: 'Sort Code', data: 'sortCode', visible: true },
        { isAlwaysVisible: true, title: 'Account Number', data: 'accountNumber', visible: true },
        //{ isAlwaysVisible: false, title: 'Payment Status', data: 'paymentStatusId', visible: false },
        { isAlwaysVisible: true, title: 'Payment Status', data: 'paymentStatus', visible: true },
        { isAlwaysVisible: true, title: 'Requested by', data: 'requestedby', visible: true },
        { isAlwaysVisible: true, title: 'Requested date', data: 'requestedDate', type: 'date', visible: true, render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { isAlwaysVisible: true, title: 'Cheque No.', data: 'chequeNumber', visible: true },
        //{ isAlwaysVisible: true, title: 'Approved (Stage 1)', data: 'approvedStage1', visible: true },
        { isAlwaysVisible: true, title: 'Approved By (Stage 1)', data: 'approvedByStage1', visible: true },
        { isAlwaysVisible: true, title: 'Approved Date (Stage 1)', data: 'approvedDateStage1', type: 'date', visible: true, render: function (data) { return data == null ? null : moment(data).format('DD/MM/YYYY') } },
        //{ isAlwaysVisible: false, title: 'Approved', data: 'approved', visible: false },
        //{ isAlwaysVisible: false, title: 'Approved By', data: 'approvedById', visible: false },
        //{ isAlwaysVisible: false, title: 'Approved Date', data: 'approvedDate', type: 'date', visible: false, render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { isAlwaysVisible: false, title: "overrideFlag", data: "isOverWritten", visible: false },
        { isAlwaysVisible: false, title: "overridePayeeNameFlag", data: "isPayeeNameOverWritten", visible: false },
        { isAlwaysVisible: false, title: "paymentOrRecovery", data: "paymentOrRecovery", visible: false },
        //{ isAlwaysVisible: false, title: "IsPaymentApproveAmountWithinAuthority", data: "isPaymentApproveAmountWithinAuthority", visible: false }
        { isAlwaysVisible: true, title: 'BACS Remittance Email', data: 'bacsRemittanceEmail', visible: true },
      { isAlwaysVisible: true, title: 'Under Query', data: 'underQuery', visible: true, orderable: false },
      { isAlwaysVisible: false, title: 'Policy Id', data: 'policyId', visible: false },
      { isAlwaysVisible: false, title: 'Claim Id', data: 'claimId', visible: false }
    ];

  selectedItems = [];

  public searching: boolean;
  handlingOrganisationData: any[];

  @ViewChild('btnSearchPaymentBordereaux') btnSearchPaymentBordereaux: ElementRef



  constructor(private formBuilder: FormBuilder,
    private lookupService: LookupService,
    private searchPaymentBordereauxService: SearchPaymentBordereauxService,
    private router: Router,
    private configurationService: ConfigurationService,
    securityService: SecurityService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private storageService: StorageService,
    private modalService: NgbModal) {
    super(securityService, 1152);
    this.dtTrigger = new Subject();

    this.createForm();
    this.loadLookups();
  }

  dropdownSettingsHO: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  ngOnInit() {
    if (this.isReadOnlyMode) {
      this.displayedColumns[0].visible = false;
    }

    this.loggedInUserPaymentAuthority = (this.securityService.getUserData().claimsStage1ApprovalAuthority != null) ? this.securityService.getUserData().claimsStage1ApprovalAuthority : 0;
    this.loggedInUserId = this.securityService.getUserData().id;
    this.initialiseDataTable();

    this.isSelectAllChecked = false;
    this.searchFilter;

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'data',
      textField: 'title',
      enableCheckAll: true,
      itemsShowLimit: 2,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false,
      closeDropDownOnSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All'
    };

    this.columnsForMultiSelect().forEach(a => {
      if (a.data != "isSelected") {
        this.displayeDataList.push(a);
      }
    });

    this.columnsForMultiSelect().forEach(a => {
      if (a.data != "isSelected") {
        this.selectedItems.push(a);
      }
    });
  }

  loadLookups() {
    this.paymentRecoveryLookup = this.lookupService.getLookupsByTypeId(38);
    this.payeeTypeLookup = this.lookupService.getLookupsByTypeId(41);
   // this.paymentCodeLookup = this.lookupService.getLookupsByTypeId(37);
    this.bindPaymentCodeLookupData();
    this.resetDefaultPaymentCodeValue();
    this.paymentStatusLookup = this.lookupService.getLookupsByTypeId(43);

    this.bindHandlingOrganisation();
    this.resetDefaultHandlingOrganizationValue();
    this.underQuerykeys = [1, 0];
    this.bindClaimTypeLookupData();
    this.resetDefaultClaimTypeValue();

    var searchCriteria = localStorage.getItem("pbSearchCriteria");
    if (searchCriteria != null) {
      try {
        //this.paymentBordereauxForm.setValue(JSON.parse(searchCriteria));
        this.setFormValueBySearchCriteria(JSON.parse(searchCriteria));
        this.changePaymentRecovery();
      }
      catch (error) {
        console.error('Here is the error message', error);
      }
    }
  }

  initialiseDataTable(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedColumns,
      serverSide: true,
      processing: true,
      searching: false,
      dom: 'Blfrtip',
      //stateSave: true,
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          tag: '',
          action: this.newExportAction,
          messageTop: "Search Payment Bordereaux Result",
          title: "SearchPaymentBordereaux",
          filename: function () { return "PaymentBordereaux" + Math.random(); },
          exportOptions: {
            columns: [1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 28, 29, 30, 31, 32, 33, 34, 35, 36, 40]
          }
        }
      ],
      select: {
        style: 'multi'
      },
      scrollY: '50vh',
      scrollX: true,
      //scrollCollapse: true,
      "initComplete": function (settings, json) {
        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });

            },
            drawCallback: function (settings, json) {
                $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
            },
            columnDefs: [{
                orderable: false,
                targets: 0,
                className: "sticky-column-left",
                render: function (data, type, full, meta: any) {
                    //debugger;
                    var html = "";//'<input type="checkbox" class="select-checkbox">';
                    if (full.paymentStatusId == enumPaymentStatus.Requested && full.createdBy != that.loggedInUserId && full.updatedBy != that.loggedInUserId) {
                        meta.settings.aoColumns[0].sClass = 'select-checkbox sticky-column-left';
                    }
                    else {
                        meta.settings.aoColumns[0].sClass = 'sticky-column-left';
                    }
                    return html;
                }
            },
            {
                orderable: false,
                className: "sticky-column-right dt-body-center",
                targets: 41,
                render: function (data, type, full, meta: any) {
                    var html = ""
                    if (full.paymentStatusId == enumPaymentStatus.Requested) {
                        html = '<input type="checkbox" class="select-checkbox sticky-column-right">';
                    }
                    return html;
                },
                'bSortable': false
            },
            { type: 'date-uk', "targets": 35 },
            { type: 'date-uk', "targets": 39 },
            {
              targets: 2,
              render: function (data, type, row) {                  
                var html = ""
                if (row.paymentLinkedDocuments.length > 0) {
                  html = '<a class="btn plus-button"><i class="fa fa-folder-open" style="font-weight: 900 !important;cursor: pointer !important;"></i></a>';
                }
                return html;
              },
              width: "5%"
            },
            {
              targets: 6,
              render: function (data, type, full, meta: any) {

                return that.bindMMiClaimRefrence(full);
              }
            },
            ],
            headerCallback: function (thead, data, start, end, display) {
                var selectAllCheckBox = $('#selectAll');
                selectAllCheckBox.click(function () { that.selectAll(this) });
            },
            rowCallback: function (nRow, aData, iDisplayIndex) {
                if (aData.paymentOrRecovery == 973) {
                    $('td:eq(14)', nRow).each(function () {
                        $(this).html("£" + Number($(this).text()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
                    });
                }
                else {
                    $('td:eq(14)', nRow).each(function () {
                        $(this).html("(£" + Number($(this).text()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ")");
                    });
                }

        if (aData.isPayeeNameOverWritten || aData.isOverWritten) {
          $('td', nRow).each(function () {
            $(this).html('<b>' + $(this).html() + '</b>');
          });
        }

        // Set the checked state of the checkbox in the table
        $('input.select-checkbox', nRow).prop('checked', aData["underQuery"] == true);
        // Unbind first in order to avoid any duplicate handler        
        $('.select-checkbox', nRow).unbind('change');
        $('.select-checkbox', nRow).bind('change', () => {
          that.checkboxClick(aData);
        });

        $('.plus-button', nRow).unbind('click');
        $('.plus-button', nRow).bind('click', () => {
          that.getPaymentLinkedDocumentsInfo(aData);
        });

        return nRow;
      },

      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        this.searchFilter.sortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
        this.searchFilter.sortOrder = dataTablesParameters.order[0].dir;
        this.searchFilter.pageIndex = dataTablesParameters.start > 0 ? parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) : 0;
        this.searchFilter.pageSize = dataTablesParameters.length;

        this.searchFilter.paymentRecovery = this.paymentBordereauxForm.controls["paymentRecovery"].value;
        this.searchFilter.payeeType = this.paymentBordereauxForm.controls["payeeType"].value;
        this.searchFilter.paymentCodeValues = this.paymentBordereauxForm.controls["paymentCodeValues"].value;
        this.searchFilter.handlingOrganisation = this.paymentBordereauxForm.controls["handlingOrganisation"].value;
        this.searchFilter.payeeName = this.paymentBordereauxForm.controls["payeeName"].value;
        this.searchFilter.policyholderName = this.paymentBordereauxForm.controls["policyholderName"].value;
        this.searchFilter.paymentStatus = this.paymentBordereauxForm.controls["paymentStatus"].value;
        this.searchFilter.createdDateFrom = this.paymentBordereauxForm.controls["createdDateFrom"].value;
        this.searchFilter.mmiClaimReference = this.paymentBordereauxForm.controls["mmiClaimReference"].value;
        this.searchFilter.paymentType = this.paymentBordereauxForm.controls["paymentType"].value;
        this.searchFilter.createdDateTo = this.paymentBordereauxForm.controls["createdDateTo"].value;
        this.searchFilter.paymentId = this.paymentBordereauxForm.controls["paymentId"].value;
        //this.resetHandlingOrganizationValueOnSearch();
        this.searchFilter.handlingOrganisationValues = this.paymentBordereauxForm.controls["handlingOrganisationValues"].value;
        this.searchFilter.underQuery = this.paymentBordereauxForm.controls["underQuery"].value;
        this.searchFilter.claimTypeValues = this.paymentBordereauxForm.controls["claimTypeValues"].value;

        this.getData(this.searchFilter).then(resp => {
          callback({
            page: that.paginationInfo.actualPage,
            pages: that.paginationInfo.totalPages,
            recordsTotal: that.paginationInfo.totalItems,
            recordsFiltered: that.paginationInfo.totalItems,
            data: that.searchPaymentBordereaux
          });
          that.isHidden = false;
          if (!this.isReadOnlyMode) {
            that.selectGridRows();
          }
          var dataTable: any = this.datatableElement;
          dataTable.dt.on('user-select', (e, dt, type, cell, originalEvent, event) => {
            if (dt.rows(cell.index()["row"]).data()[0].paymentStatusId != enumPaymentStatus.Requested || this.isReadOnlyMode || dt.rows(cell.index()["row"]).data()[0].updatedBy == that.loggedInUserId || dt.rows(cell.index()["row"]).data()[0].createdBy == that.loggedInUserId)
              return false;
          });
        });
      }
    };
  }

  columnsForMultiSelect() {

    let c = _.filter(this.displayedColumns, function (o) { return o.isAlwaysVisible == true && o.data != "underQuery"; });
    return c;
  }

  createForm() {
    this.paymentBordereauxForm = this.formBuilder.group({
      paymentRecovery: [0],
      payeeType: [0],
      paymentCode: [{ "id": 0, "text": "All" }],
      paymentCodeValues: [0],
      //handlingOrganisation: [0],
      policyholderName: [''],
      payeeName: [''],
      paymentStatus: [0],
      createdDateFrom: [null],
      mmiClaimReference: [''],
      paymentType: [0],
      createdDateTo: [null],
      paymentId: [null],
      handlingOrganisation: [{ "id": 0, "text": "All" }],
      handlingOrganisationValues: [[0]],
      underQuery: [null],
      claimType: [{ "id": 0, "text": "All" }],
      claimTypeValues: [0]
    });
  }

  changePaymentRecovery() {
    this.searchFilter.paymentRecovery = this.paymentBordereauxForm.controls["paymentRecovery"].value;
    if (this.searchFilter.paymentRecovery == enumPaymentRecovery.Payment) {
      this.paymentTypeLookup = this.lookupService.getLookupsByTypeId(39);
    }
    else if (this.searchFilter.paymentRecovery == enumPaymentRecovery.Recovery) {
      this.paymentTypeLookup = this.lookupService.getLookupsByTypeId(40);
    }
  }

  onViewHideItemSelect(item: any) {
    let c = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    c[0].visible = true;

    this.searchBordereaux();
  }

  onViewHideItemDeSelect(item: any) {
    let c = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    c[0].visible = false;

    this.searchBordereaux();
  }

  onViewHideSelectAll() {
    this.displayedColumns.forEach(a => {
      if (a.data != "isSelected") {
        a.isAlwaysVisible ? a.visible = true : a.visible = false;
      }
    });

    this.searchBordereaux();
  }

  onViewHideDeSelectAll() {
    this.displayedColumns.forEach(a => {
      if (a.data != "isSelected") {
        a.visible = false;
      }
    });

    this.searchBordereaux();
  }

  getData(filter: any): Promise<any> {
    this.errorReceived = false;
    var promise = new Promise((resolve, reject) => {
      this.searchPaymentBordereauxService.getPaymentBordereaux(this.searchFilter)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          this.searchPaymentBordereaux = res.serachPaymentBordereauxResultItems;
          this.paginationInfo = res.paginationInfo;
          resolve();
        });
    });
    return promise;
  }

  //onRowSelection(e, dt, type, cell, originalEvent) {
  //  if (dt.rows(cell.index()["row"]).data()[0].paymentStatusId != enumPaymentStatus.Requested) {
  //    return false;
  //  }
  //}

  selectAll(data) {
    this.isSelectAllChecked = data.checked;

    this.selectGridRows();
  }

  selectGridRows() {
    var dataTable: any = this.datatableElement;
    var checked = this.isSelectAllChecked;
    let handlerId = this.loggedInUserId;
    var selectChk: any = document.getElementById("selectAll");
    selectChk.checked = checked;

    dataTable.dt.rows().every(function (rowIdx, tableLoop, rowLoop) {
      var rowData = this.data();
      if (rowData.paymentStatusId == enumPaymentStatus.Requested && rowData.createdBy != handlerId && rowData.updatedBy != handlerId) {
        if (checked) {
          this.rows(rowIdx).select();
        }
        else {
          this.rows(rowIdx).deselect();
        }
      }
    });
  }

  openDialog(content) {
    var dataTable: any = this.datatableElement;
    var selectedrows: any[] = dataTable.dt.rows('.selected').data();
    var selectedPaymentIds: number[] = _.map(selectedrows, i => i.paymentID);
    if (selectedPaymentIds.length === 0) {
      this.notificationService.printWarningMessage('No payment(s) selected for action');
      return;
    }

    this.modalRef = this.modalService.open(content);
  }

  onApprovePaymentBordereaux() {
    this.modalRef.close();
    let loggedInUserAuthority = this.loggedInUserPaymentAuthority;
    var dataTable: any = this.datatableElement;
    var selectedrows: any[] = dataTable.dt.rows('.selected').data();
    //dataTable.dt.rows('.selected').every(function (rowIdx, tableLoop, rowLoop) {
    //  var rowData = this.data();
    //  if (rowData.paymentAmount > loggedInUserAuthority) {
    //    this.node().className = 'highlightData';
    //  }
    //});
    if (!this.checkApprovalAuthorityValidation()) {
      this.notificationService.printWarningMessage("This payment exceeds your Approval Stage 1 (Claims) Authority. Please refer to a colleague with a sufficient Approval Stage 1 (Claims) Authority to approve this payment.");
      return;
    }
    var selectedPaymentIds: number[] = _.map(selectedrows, i => i.paymentID);
    if (selectedPaymentIds.length === 0) {
      this.notificationService.printWarningMessage('No payment(s) selected for action');
      return;
    }
    this.searchPaymentBordereauxService.approvePayments(0, selectedPaymentIds, this.loggedInUserId).subscribe(res => {
      this.notificationService.printSuccessMessage('Selected payment(s) approved successfully');
      this.searchBordereaux();
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    )
  }

  onRejectPaymentBordereaux() {
    this.modalRef.close();

    var dataTable: any = this.datatableElement;
    var selectedrows: any[] = dataTable.dt.rows('.selected').data();
    if (!this.checkApprovalAuthorityValidation()) {
      this.notificationService.printWarningMessage("This payment exceeds your Approval Stage 1 (Claims) Authority. Please refer to a colleague with a sufficient Approval Stage 1 (Claims) Authority to approve this payment.");
      return;
    }
    var selectedPaymentIds: number[] = _.map(selectedrows, i => i.paymentID);

    this.searchPaymentBordereauxService.rejectPayments(selectedPaymentIds).subscribe(res => {
      this.notificationService.printSuccessMessage('Selected payment(s) rejected successfully');
      this.searchBordereaux();
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    )
  }

  refresh() {
    this.isHidden = true;
    this.paymentBordereauxForm.controls["paymentRecovery"].setValue(0);
    this.paymentBordereauxForm.controls["payeeType"].setValue(0);
    this.paymentBordereauxForm.controls["paymentCode"].setValue(0);
    this.paymentBordereauxForm.controls["paymentCodeValues"].setValue([0]);
    this.paymentBordereauxForm.controls["handlingOrganisation"].setValue(0);
    this.paymentBordereauxForm.controls["paymentType"].setValue(0);
    this.paymentBordereauxForm.controls["paymentStatus"].setValue(0);

    this.paymentBordereauxForm.controls["createdDateFrom"].setValue(null);
    this.paymentBordereauxForm.controls["createdDateTo"].setValue(null);
    this.paymentBordereauxForm.controls["paymentId"].setValue(null);

    this.paymentBordereauxForm.controls["policyholderName"].setValue('');
    this.paymentBordereauxForm.controls["payeeName"].setValue('');
    this.paymentBordereauxForm.controls["mmiClaimReference"].setValue('');
    this.paymentBordereauxForm.controls["handlingOrganisationValues"].setValue([0]);
    this.paymentBordereauxForm.controls["underQuery"].setValue(null);
    this.paymentBordereauxForm.controls["claimType"].setValue(0);
    this.paymentBordereauxForm.controls["claimTypeValues"].setValue([0]);
  }

  searchBordereaux(): void {
    this.validateSearch();

    if (!this.isValid) {
      return;
    }
    else {
      this.isHidden = false;
      localStorage.setItem("pbSearchCriteria", JSON.stringify(this.searchFilter));
    }

    if (this.datatableElement.dtInstance) {

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
    else {
      this.dtTrigger.next();
    }
    setTimeout(() => this.btnSearchPaymentBordereaux.nativeElement.focus(), 0);
  }

  validateSearch() {
    this.isValid = true;

    this.searchFilter.paymentRecovery = this.paymentBordereauxForm.controls["paymentRecovery"].value;
    this.searchFilter.payeeType = this.paymentBordereauxForm.controls["payeeType"].value;
    this.searchFilter.paymentCode = this.paymentBordereauxForm.controls["paymentCode"].value;
    this.searchFilter.paymentCodeValues = this.paymentBordereauxForm.controls["paymentCodeValues"].value;
    this.searchFilter.handlingOrganisation = this.paymentBordereauxForm.controls["handlingOrganisation"].value;
    this.searchFilter.payeeName = this.paymentBordereauxForm.controls["payeeName"].value;
    this.searchFilter.policyholderName = this.paymentBordereauxForm.controls["policyholderName"].value;
    this.searchFilter.paymentStatus = this.paymentBordereauxForm.controls["paymentStatus"].value;
    this.searchFilter.createdDateFrom = this.paymentBordereauxForm.controls["createdDateFrom"].value;
    this.searchFilter.mmiClaimReference = this.paymentBordereauxForm.controls["mmiClaimReference"].value;
    this.searchFilter.paymentType = this.paymentBordereauxForm.controls["paymentType"].value;
    this.searchFilter.createdDateTo = this.paymentBordereauxForm.controls["createdDateTo"].value;
    this.searchFilter.paymentId = this.paymentBordereauxForm.controls["paymentId"].value;
    this.searchFilter.handlingOrganisationValues = this.paymentBordereauxForm.controls["handlingOrganisationValues"].value;
    this.searchFilter.underQuery = this.paymentBordereauxForm.controls["underQuery"].value;
    this.searchFilter.claimType = this.paymentBordereauxForm.controls["claimType"].value;
    this.searchFilter.claimTypeValues = this.paymentBordereauxForm.controls["claimTypeValues"].value;
    
    if (this.searchFilter.paymentRecovery == 0 && this.searchFilter.payeeType == 0 && this.searchFilter.paymentCode == 0
      && this.searchFilter.handlingOrganisation == 0 && this.searchFilter.paymentType == 0
      && this.searchFilter.mmiClaimReference == "" && this.searchFilter.createdDateFrom == null
      && this.searchFilter.paymentStatus == 0 && this.searchFilter.createdDateTo == null && this.searchFilter.paymentId == null && this.searchFilter.claimType == 0
    ) {
      this.notificationService.printWarningMessage('Please select at least one search option');
      this.isValid = false;
    }
  }

  bindHandlingOrganisation() {
    this.searchPaymentBordereauxService.getHandlingOrganization().subscribe(
      result => {
        this.handlingOrganisation = result;
        if (result != null) {
          this.handlingOrganisationData = result.map(item => {
            let container: any = {};
            container.id = item.id;
            container.text = item.handlingOrganisationName;
            return container;
          })
          this.handlingOrganisationData.unshift({ "id": 0, "text": "All" });
        }
      },
      error => {
        console.log('this is error:' + error);
      });
  }

  bindPaymentCodeLookupData() {
    this.paymentCodeLookup = this.lookupService.getLookupsByTypeId(37).map(item => {
      let container: any = {};
      container.id = item.id;
      container.text = item.text;
      return container;
    })

    this.paymentCodeLookup.unshift({ "id": 0, "text": "All" });
  }

  bindClaimTypeLookupData() {
    this.claimTypeLookup = this.lookupService.getLookupsByTypeId(1).map(item => {
      let container: any = {};
      container.id = item.id;
      container.text = item.text;
      return container;
    })

    this.claimTypeLookup.unshift({ "id": 0, "text": "All" });
  }

  public newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    console.log("buttons");
    //console.log($.fn.dataTable.ext.buttons);
    dt.one('preXhr', function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 500;

      dt.one('preDraw', function (e, settings) {
        // Call the original action function
        oldExportAction(self, e, dt, button, config);

        dt.one('preXhr', function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    // Requery the server with the new one-time export settings
    dt.ajax.reload();

    function oldExportAction(self, e, dt, button, config) {
      if (button[0].className.indexOf('buttons-excel') >= 0) {
        //this.datatableElement.dtInstance.
        var buttons: any = ($.fn.dataTable.ext as any).buttons;
        if (buttons.excelHtml5.available(dt, config)) {
          buttons.excelHtml5.action.call(self, e, dt, button, config);
        }
        else {
          buttons.excelFlash.action.call(self, e, dt, button, config);
        }
      } else if (button[0].className.indexOf('buttons-print') >= 0) {
        buttons.print.action(e, dt, button, config);
      }
    }
  };

  public oldExportAction(self, e, dt, button, config) {
    if (button[0].className.indexOf('buttons-excel') >= 0) {
      //this.datatableElement.dtInstance.
      var buttons: any = ($.fn.dataTable.ext as any).buttons;
      if (buttons.excelHtml5.available(dt, config)) {
        buttons.excelHtml5.action.call(self, e, dt, button, config);
      }
      else {
        buttons.excelFlash.action.call(self, e, dt, button, config);
      }
    } else if (button[0].className.indexOf('buttons-print') >= 0) {
      buttons.print.action(e, dt, button, config);
    }
  }

  private handleError(error: any) {
    this.errorReceived = true;
    return Observable.throw(error);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  checkApprovalAuthorityValidation(): boolean {
    let isValidAuthority = true;
    let loggedInUserAuthority = this.loggedInUserPaymentAuthority;
    var dataTable: any = this.datatableElement;
    dataTable.dt.rows('.selected').every(function (rowIdx, tableLoop, rowLoop) {
      var rowData = this.data();
      if (rowData.paymentAmount > loggedInUserAuthority) {
        this.node().className = 'highlightData';
        isValidAuthority = false;
      }
    });
    return isValidAuthority;
  }

  searchPolicyholderName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((searchText) => this.searchPaymentBordereauxService.searchPolicyholderName(searchText)),
      tap(() => this.searching = false)
    )

  searchPayeeName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((searchText) => this.searchPaymentBordereauxService.searchPayeeName(searchText)),
      tap(() => this.searching = false)
    )

  enterKeydownSearch(event): void {
    event.preventDefault();
    this.searchBordereaux();
  };
   
  bindMMiClaimRefrence(full) {
    let result = "";
    result += "<a href='" + "claims/" + full.policyId + "/" + full.claimId + "/" + "claimdetail" + "' target = '_blank' > " + full.claimNumber + " </a></br>";
    return "<span>" + result + "</span>";
  }

  onHandlingOrganizationSelect(item: any) {
        let HOValues = (this.paymentBordereauxForm.controls['handlingOrganisation'].value).map(x => x.id);
        this.paymentBordereauxForm.controls['handlingOrganisationValues'].setValue(HOValues);
  }

  onPaymentCodeSelect(item: any) {
    let paymentCodeSelectedValues = (this.paymentBordereauxForm.controls['paymentCode'].value).map(x => x.id);
    this.paymentBordereauxForm.controls['paymentCodeValues'].setValue(paymentCodeSelectedValues);
  }

  onClaimTypeSelect(item: any) {
    let claimTypeSelectedValues = (this.paymentBordereauxForm.controls['claimType'].value).map(x => x.id);
    this.paymentBordereauxForm.controls['claimTypeValues'].setValue(claimTypeSelectedValues);
  }

  resetDefaultHandlingOrganizationValue() {
    this.paymentBordereauxForm.controls['handlingOrganisation'].setValue([{ "id": 0, "text": "All" }]);
    this.paymentBordereauxForm.controls['handlingOrganisationValues'].setValue([0]);
  }

  resetDefaultPaymentCodeValue() {
    this.paymentBordereauxForm.controls['paymentCode'].setValue([{ "id": 0, "text": "All" }]);
    this.paymentBordereauxForm.controls['paymentCodeValues'].setValue([0]);
  }

  resetDefaultClaimTypeValue() {
    this.paymentBordereauxForm.controls['claimType'].setValue([{ "id": 0, "text": "All" }]);
    this.paymentBordereauxForm.controls['claimTypeValues'].setValue([0]);
  }

  setFormValueBySearchCriteria(obj) {
    this.paymentBordereauxForm.controls["paymentRecovery"].setValue(obj.paymentRecovery);
    this.paymentBordereauxForm.controls["payeeType"].setValue(obj.payeeType);
    this.paymentBordereauxForm.controls["paymentCode"].setValue(obj.paymentCode);
    this.paymentBordereauxForm.controls["paymentCodeValues"].setValue(obj.paymentCodeValues);
    this.paymentBordereauxForm.controls["handlingOrganisation"].setValue(obj.handlingOrganisation);
    this.paymentBordereauxForm.controls["paymentType"].setValue(obj.paymentType);
    this.paymentBordereauxForm.controls["paymentStatus"].setValue(obj.paymentStatus);

    this.paymentBordereauxForm.controls["createdDateFrom"].setValue(obj.createdDateFrom);
    this.paymentBordereauxForm.controls["createdDateTo"].setValue(obj.createdDateTo);
    this.paymentBordereauxForm.controls["paymentId"].setValue(obj.paymentId);
    this.paymentBordereauxForm.controls["policyholderName"].setValue(obj.policyholderName);
    this.paymentBordereauxForm.controls["payeeName"].setValue(obj.payeeName);
    this.paymentBordereauxForm.controls["mmiClaimReference"].setValue(obj.mmiClaimReference);
    this.paymentBordereauxForm.controls["handlingOrganisationValues"].setValue(obj.handlingOrganisationValues);
    this.paymentBordereauxForm.controls["claimType"].setValue(obj.claimType);
    this.paymentBordereauxForm.controls["claimTypeValues"].setValue(obj.claimTypeValues);
  }

  checkboxClick(data) {
    data["underQuery"] = !data["underQuery"];
    console.log("Under Query Row Clicked ", data["underQuery"]);
    let underQueryModel = {
      paymentId: data["paymentID"],
      underQuery: data["underQuery"]
    };
    let listModel = [];
    listModel.push(underQueryModel);
    let models = {
      underQueryModel: listModel
    };
    this.searchPaymentBordereauxService.updateUnderQueryPaymentBordereaux(models).subscribe((reponse: any) => {
      this.unselectRowOnUnderQueryCheckUnCheck();
    },
      error => {
        this.notificationService.printErrorMessage('Error occured  in updating Under Query');
      }
    );
  }

  unselectRowOnUnderQueryCheckUnCheck() {    
    var dataTable: any = this.datatableElement;
    dataTable.dt.rows().every(function (rowIdx, tableLoop, rowLoop) {
      this.rows(rowIdx).deselect();
    });
  }

  getPaymentLinkedDocumentsInfo(data) {
   // this.unselectRowOnUnderQueryCheckUnCheck();
    let payment = data;
    let claimId = payment.claimId;
    let paymentId = payment.paymentID;

    this.selectedPaymentLinkedDocuments = [];
    if (claimId > 0 && paymentId > 0) {
      this.selectedPaymentLinkedDocuments = payment.paymentLinkedDocuments;
      this.modalRef = this.modalService.open(this.paymentLinkedDocumentsModalContent, { size: 'md', backdrop: 'static' });
    }    
  }

}

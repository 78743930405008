import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, AfterContentInit, AfterViewChecked, ElementRef, ViewChildren, Inject } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm, FormGroup, FormBuilder, Validators, FormControl, FormsModule } from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import { IPager } from '../../../shared/models/pager.model';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { SecurityService } from '../../../shared/services/security.service';
import { LookupService } from 'src/app/shared/services/lookup.service'
import { VerifyChequesService } from 'src/app/components/payment-bordereaux/verify-cheques/verify-cheques.service'
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { IPaymentBordereaux } from 'src/app/shared/models/payment-bordereaux.model'
import { IPaymentBordereauxFilter } from 'src/app/models/IPaymentBordereauxFilter'
import * as moment from 'moment';
import * as _ from "lodash";
import { NotificationService } from '../../../shared/services/notification.service';
import { StorageService } from '../../../shared/services/storage.service';
import { enumPaymentMethod, enumPaymentType, enumPaymentStatus } from '../../../shared/models/common.enum';
import { HttpEventType } from '@angular/common/http';
import { BaseComponentComponent } from '../../base-component/base-component.component';


@Component({
  selector: 'app-verify-cheques',
  templateUrl: './verify-cheques.component.html',
  styleUrls: ['./verify-cheques.component.css']
})
export class VerifyChequesComponent extends BaseComponentComponent implements OnInit, AfterViewChecked {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('verifyChecquesTable') verifyChecquesTable: ElementRef;
  paymentBordereauxForm: FormGroup;
  public searchPaymentBordereaux: IPaymentBordereaux[];
  public searchFilter: any = {};
  public dtOptions: any = {};
  chequeNumberForm: FormGroup;
  public paginationInfo: IPager;
  public dtTrigger: Subject<any>;
  isGridLoaded: boolean = false;
  errorReceived: boolean;
  isHidden: boolean;
  isReprintable: boolean;
  chequeNumber: number;
  chequeNumberError: string;
  isSelectAllChecked: boolean;

  paymentRecoveryLookup: any[];
  payeeTypeLookup: any[];
  paymentCodeLookup: any[];
  paymentStatusLookup: any[];
  paymentTypeLookup: any[];
  handlingOrganisation: any[];
  modalRef: NgbModalRef;
  loggedInUserPaymentAuthority: number;
  loggedInUserId: number;
  selectedListData: DataTables.Api;

  dropdownList = [];

  displayeDataList = [];
  dropdownSettings = {};

  displayedColumns: any[] = [
    { isAlwaysVisible: true, title: '<input type="checkbox" id="selectAll"/>', data: 'isSelected', visible: true },
    { isAlwaysVisible: true, title: 'Payment ID', data: 'paymentID', visible: true },
    { isAlwaysVisible: true, title: 'Linked Documents', data: 'paymentLinkedDocuments', visible: true },
    //{ isAlwaysVisible: true, title: 'Payment/Recovery', data: 'paymentRecovery', visible: true },
    //{ isAlwaysVisible: false, title: 'Handling Organisation ID', data: 'handlingOrganisationId', visible: false },
    { isAlwaysVisible: true, title: 'MMI Claim Reference', data: 'claimNumber', visible: true },
    { isAlwaysVisible: true, title: 'Claimant Name', data: 'claimant', visible: true },
    { isAlwaysVisible: true, title: 'Policyholder Name', data: 'policyHolder', visible: true },
    { isAlwaysVisible: true, title: 'Claim Type', data: 'claimType', visible: true },
    { isAlwaysVisible: true, title: 'Policyholder ID', data: 'policyHolderID', visible: true },
    { isAlwaysVisible: true, title: 'Payment Amount', data: 'paymentAmount', visible: true, render: $.fn.dataTable.render.number(',', '.', 2, '£') },
    { isAlwaysVisible: true, title: 'Cheque No.', data: 'chequeNumber', visible: true },
    { isAlwaysVisible: true, title: 'Payment Code', data: 'paymentCode', visible: true },
    { isAlwaysVisible: true, title: 'Payee Name', data: 'payeeName', visible: true },
    { isAlwaysVisible: true, title: 'Payee Override', data: 'payeeNameOverWrittenValue', visible: true },
    { isAlwaysVisible: true, title: 'Payee ID', data: 'payee', visible: false },
    { isAlwaysVisible: true, title: 'Payee/Invoice Ref.', data: 'invoiceRef', visible: false },
    { isAlwaysVisible: true, title: 'Addressee Name', data: 'addresseeName', visible: false },
    { isAlwaysVisible: true, title: 'Address Line 1', data: 'addressLine1', visible: false },
    { isAlwaysVisible: true, title: 'Address Line 2', data: 'addressLine2', visible: false },
    { isAlwaysVisible: true, title: 'Town', data: 'town', visible: false },
    { isAlwaysVisible: true, title: 'Country', data: 'county', visible: false },
    { isAlwaysVisible: true, title: 'Post Code', data: 'postCode', visible: false },
    { isAlwaysVisible: true, title: 'Address Override', data: 'addresseeOverWrittenValue', visible: false },
    { isAlwaysVisible: true, title: 'FSCS % Protected', data: 'fscsProtected', visible: false, render: function (data) { return data == null ? "" : (((String(data).split('.').length === 1) && parseInt(data) > 0 && parseInt(data) < 100) ? (String(data).split('.')[0] + '.00') : ((String(data).split('.').length === 2) && (String(data).split('.')[1].length === 1)) ? (String(data).split('.')[0] + "." + String(data).split('.')[1] + "0") : data) + " " + "%" } },
    { isAlwaysVisible: true, title: 'Policyholder Status', data: 'policyHolderStatus', visible: false },
    { isAlwaysVisible: true, title: 'Handling Organisation', data: 'handlingOrganisationName', visible: false },
    { isAlwaysVisible: true, title: 'Requested by', data: 'requestedby', visible: false },
    { isAlwaysVisible: true, title: 'Requested date', data: 'requestedDate', type: 'date', visible: false, render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
    //{ isAlwaysVisible: true, title: 'Approved (Stage 1)', data: 'approvedStage1', visible: false },
    { isAlwaysVisible: true, title: 'Approved By (Stage 1)', data: 'approvedByStage1', visible: false },
    { isAlwaysVisible: true, title: 'Approved Date (Stage 1)', data: 'approvedDateStage1', type: 'date', visible: false, render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },

    //{ isAlwaysVisible: false, title: 'Approved', data: 'approved', visible: false },
    //{ isAlwaysVisible: false, title: 'Approved By', data: 'approvedById', visible: false },
    //{ isAlwaysVisible: false, title: 'Approved Date', data: 'approvedDate', type: 'date', visible: false, render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
    { isAlwaysVisible: false, title: "overrideFlag", data: "isOverWritten", visible: false },
    { isAlwaysVisible: false, title: "overridePayeeNameFlag", data: "isPayeeNameOverWritten", visible: false }


  ];

  selectedItems = [
    { title: 'Payment ID', data: 'paymentID', visible: true },
    { title: 'MMI Claim Reference', data: 'claimNumber', visible: true },
    { title: 'Claimant Name', data: 'claimant', visible: true },
    { title: 'Policyholder Name', data: 'policyHolder', visible: true },
    { title: 'Claim Type', data: 'claimType', visible: true },
    { title: 'Policyholder ID', data: 'policyHolderID', visible: true },
    { title: 'Payment Amount', data: 'paymentAmount', visible: true },
    { title: 'Cheque No.', data: 'chequeNumber', visible: false },
    { title: 'Account Number', data: 'accountNumber', visible: true },
    { title: 'Payment Code', data: 'paymentCode', visible: true },
    { title: 'Payee Name', data: 'payeeName', visible: true },
    { title: 'Payee Override', data: 'payeeNameOverWrittenValue' }
  ];

  constructor(private formBuilder: FormBuilder,
    private form: FormsModule,
    private lookupService: LookupService,
    private verifyChequesService: VerifyChequesService,
    private router: Router,
    private configurationService: ConfigurationService,
    securityService: SecurityService,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private modalService: NgbModal) {
    super(securityService, 1150);
    this.dtTrigger = new Subject();
  }

  ngOnInit() {
    if (this.isReadOnlyMode) {
      this.displayedColumns[0].visible = false;
    }
    this.loggedInUserPaymentAuthority = (this.securityService.getUserData().financeApprovalAuthority != null) ? this.securityService.getUserData().financeApprovalAuthority : 0;
    this.loggedInUserId = this.securityService.getUserData().id;
    this.initialiseDataTable();
    this.searchFilter;
    this.refreshGrid();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'data',
      textField: 'title',
      enableCheckAll: true,
      itemsShowLimit: 2,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false,
      closeDropDownOnSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All'
    };

    this.columnsForMultiSelect().forEach(a => {
      if (a.data != "isSelected") {
        this.displayeDataList.push(a);
      }
    });
  }

  columnsForMultiSelect() {
    let c = _.filter(this.displayedColumns, function (o) { return o.isAlwaysVisible == true; });
    return c;
  }

  onViewHideItemSelect(item: any) {
    let c = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    c[0].visible = true;

    this.searchBordereaux();
  }

  onViewHideItemDeSelect(item: any) {
    let c = _.filter(this.displayedColumns, function (o) { return o.data == item.data; });
    c[0].visible = false;

    this.searchBordereaux();
  }

  onViewHideSelectAll() {
    this.displayedColumns.forEach(a => {
      if (a.data != "isSelected") {
        a.isAlwaysVisible ? a.visible = true : a.visible = false;
      }
    });

    this.searchBordereaux();
  }

  onViewHideDeSelectAll() {
    this.displayedColumns.forEach(a => {
      if (a.data != "isSelected") {
        a.visible = false;
      }
    });

    this.searchBordereaux();
  }

  initialiseDataTable(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedColumns,
      //fixedColumns: {
      //  leftColumns: 1,
      //  rightColumns: 1
      //},
      scrollY: '50vh',
      scrollX: true,
      "initComplete": function (settings, json) {
        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
      },
      serverSide: true,
      processing: true,
      searching: false,
      dom: 'Blfrtip',

      //scrollCollapse: true,

      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          tag: '',
          action: this.newExportAction,
          messageTop: "Verify Cheques Payment PaymentBordereaux Result",
          title: "Verify Cheques Payment PaymentBordereaux",
          filename: function () { return "VerifyChequesPaymentBordereaux" + Math.random(); },
          exportOptions: {
            columns: [1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28]
          }
        }
      ],
      select: {
        style: 'multi'
      },
      columnDefs: [{
        orderable: false,
        targets: 0,
        render: function (data, type, full, meta: any) {
          var html = "";//'<input type="checkbox" class="select-checkbox">';
          if (full.approvedByIdStage1 != that.loggedInUserId && full.createdBy != that.loggedInUserId) {
            meta.settings.aoColumns[0].sClass = 'select-checkbox';
          }
          else {
            meta.settings.aoColumns[0].sClass = '';
          }
          return html;
        }
      },
      {
        targets: 2,
        render: function (data, type, full, meta: any) {
          return that.bindPaymentLinkedDocuments(data);
        }
        },
        {
          targets: 3,          
          render: function (data, type, full, meta: any) {            
            return that.bindMMiClaimRefrence(full);
          }
        },
      ],
      headerCallback: function (thead, data, start, end, display) {
        var selectAllCheckBox = $('#selectAll');
        selectAllCheckBox.click(function () { that.selectAll(this) });
      },
      rowCallback: function (nRow, aData, iDisplayIndex) {
        if (aData.isPayeeNameOverWritten || aData.isOverWritten) {
          $('td', nRow).each(function () {
            $(this).html('<b>' + $(this).html() + '</b>');
          });
        }
        if (aData.paymentStatusId == enumPaymentStatus.Verified) {
          $('td', nRow).css('background-color', '#9ce995');
        }
        return nRow;
      },
      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        //debugger; //enumPaymentMethod, enumPaymentType, enumPaymentStatus
        this.searchFilter.paymentType = enumPaymentType.SchemePaymentByMMI;
        this.searchFilter.paymentMethod = enumPaymentMethod.Cheque;
        this.searchFilter.paymentStatus = enumPaymentStatus.ApprovedStage1;

        this.searchFilter.sortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
        this.searchFilter.sortOrder = dataTablesParameters.order[0].dir;
        this.searchFilter.pageIndex = dataTablesParameters.start > 0 ? parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) : 0;
        this.searchFilter.pageSize = dataTablesParameters.length;

        this.getData(this.searchFilter).then(resp => {
          //debugger;
          callback({
            page: that.paginationInfo.actualPage,
            pages: that.paginationInfo.totalPages,
            recordsTotal: that.paginationInfo.totalItems,
            recordsFiltered: that.paginationInfo.totalItems,
            data: that.searchPaymentBordereaux
          });
          if (!this.isReadOnlyMode) {
            that.selectGridRows();
          }
          var dataTable: any = this.datatableElement;
          dataTable.dt.on('user-select', (e, dt, type, cell, originalEvent, event) => {
            if (dt.rows(cell.index()["row"]).data()[0].approvedByIdStage1 == this.loggedInUserId || this.isReadOnlyMode  || dt.rows(cell.index()["row"]).data()[0].createdBy == that.loggedInUserId)
              return false;
          });
        });
      }
    };
  }

  getData(filter: any): Promise<any> {
    this.errorReceived = false;

    var promise = new Promise((resolve, reject) => {
      this.verifyChequesService.getChequesBordereaux(this.searchFilter)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(res => {
          //debugger;
          this.searchPaymentBordereaux = res.serachPaymentBordereauxResultItems;
          this.paginationInfo = res.paginationInfo;
          resolve();
        });
    });
    return promise;
  }

  private handleError(error: any) {
    this.errorReceived = true;
    return Observable.throw(error);
  }

  selectAll(data) {
    //debugger;
    this.isSelectAllChecked = data.checked;

    this.selectGridRows();
  }

  selectGridRows() {
    var dataTable: any = this.datatableElement;
    var checked = this.isSelectAllChecked;

    var selectChk: any = document.getElementById("selectAll");
    selectChk.checked = checked;
    let handlerId = this.loggedInUserId;
    dataTable.dt.rows().every(function (rowIdx, tableLoop, rowLoop) {
      var rowData = this.data();
      if (rowData.approvedByIdStage1 != handlerId && rowData.createdBy != handlerId) {
        if (checked) {
          this.rows(rowIdx).select();
        }
        else {
          this.rows(rowIdx).deselect();
        }
      }
    });

  }

  openDialog(content) {
    var dataTable: any = this.datatableElement;
    var selectedItems: any[] = dataTable.dt.rows('.selected').data();
    var selectedPaymentIds: number[] = _.map(selectedItems, i => i.paymentID);
    if (selectedPaymentIds.length === 0) {
      this.notificationService.printWarningMessage('No cheque(s) selected for action');
      return;
    }

    this.modalRef = this.modalService.open(content);
  }

  onPrintChecques(chequeNumber) {
    var dataTable: any = this.datatableElement;
    var selectedItems: any[] = dataTable.dt.rows('.selected').data();
    var selectedPaymentIds: number[] = _.map(selectedItems, i => i.paymentID);

    if (chequeNumber == 0) {
      this.chequeNumberError = 'Cheque number will be required to proceed';
      return;
    }
    else if (chequeNumber < 0 || chequeNumber > 99999999) {
      this.chequeNumberError = 'Invalid cheque number';
      return;
    }
    else {
      this.modalRef.close();
    }
    if (!this.checkApprovalAuthorityValidation()) {
      this.notificationService.printWarningMessage("This payment exceeds your Approval Stage 1 (Claims) Authority. Please refer to a colleague with a sufficient Approval Stage 1 (Claims) Authority to approve this payment.");
      return;
    }
    if (chequeNumber > 0) {
      this.verifyChequesService.approvePayments(parseInt(chequeNumber), selectedPaymentIds, 0).subscribe(res => {
        this.notificationService.printSuccessMessage('Selected cheque(s) verified successfully');
        this.searchBordereaux();

        this.downloadFile(selectedPaymentIds);
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      );
    }
  }

  onReprintChecques() {
    var dataTable: any = this.datatableElement;
    var selectedItems: any[] = dataTable.dt.rows('.selected').data();
    var selectedPaymentIds: number[] = _.map(selectedItems, i => i.paymentID);

    this.modalRef.close();
    this.notificationService.printWarningMessage('Only Verified cheque(s) will be printed');

    this.downloadFile(selectedPaymentIds);
  }

  downloadFile(selectedPaymentIds) {
    this.verifyChequesService.printPayments(selectedPaymentIds).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = this.getFileName(data.headers.get('Content-Disposition'));
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
        this.notificationService.printWarningMessage('No Verified cheque(s) selected for action');
      });
  }

  getFileName(disposition: string): string {
    var filename: any;
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }

    return filename;
  }

  onRejectChecques() {
    this.modalRef.close();

    var dataTable: any = this.datatableElement;
    var selectedItems: any[] = dataTable.dt.rows('.selected').data();
    var selectedPaymentIds: number[] = _.map(selectedItems, i => i.paymentID);
    if (!this.checkApprovalAuthorityValidation()) {
      this.notificationService.printWarningMessage("This payment exceeds your Approval Stage 1 (Claims) Authority. Please refer to a colleague with a sufficient Approval Stage 1 (Claims) Authority to approve this payment.");
      return;
    }
    this.verifyChequesService.rejectPayments(selectedPaymentIds).subscribe(res => {
      this.notificationService.printSuccessMessage('Selected cheque(s) rejected successfully');
      this.searchBordereaux();
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    )
  }

  ngAfterViewChecked(): void {

    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  searchBordereaux(): void {
    this.isHidden = false;

    if (this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
    else {
      this.dtTrigger.next();
    }
  }

  refreshGrid() {

    if (this.verifyChecquesTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }

  }

  public newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    console.log("buttons");
    //console.log($.fn.dataTable.ext.buttons);
    dt.one('preXhr', function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 500;

      dt.one('preDraw', function (e, settings) {
        // Call the original action function
        oldExportAction(self, e, dt, button, config);

        dt.one('preXhr', function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    // Requery the server with the new one-time export settings
    dt.ajax.reload();

    function oldExportAction(self, e, dt, button, config) {
      if (button[0].className.indexOf('buttons-excel') >= 0) {
        //this.datatableElement.dtInstance.
        var buttons: any = ($.fn.dataTable.ext as any).buttons;
        if (buttons.excelHtml5.available(dt, config)) {
          buttons.excelHtml5.action.call(self, e, dt, button, config);
        }
        else {
          buttons.excelFlash.action.call(self, e, dt, button, config);
        }
      } else if (button[0].className.indexOf('buttons-print') >= 0) {
        buttons.print.action(e, dt, button, config);
      }
    }
  };

  public oldExportAction(self, e, dt, button, config) {
    if (button[0].className.indexOf('buttons-excel') >= 0) {
      //this.datatableElement.dtInstance.
      var buttons: any = ($.fn.dataTable.ext as any).buttons;
      if (buttons.excelHtml5.available(dt, config)) {
        buttons.excelHtml5.action.call(self, e, dt, button, config);
      }
      else {
        buttons.excelFlash.action.call(self, e, dt, button, config);
      }
    } else if (button[0].className.indexOf('buttons-print') >= 0) {
      buttons.print.action(e, dt, button, config);
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  checkApprovalAuthorityValidation(): boolean {
    let isValidAuthority = true;
    let loggedInUserAuthority = this.loggedInUserPaymentAuthority;
    var dataTable: any = this.datatableElement;
    dataTable.dt.rows('.selected').every(function (rowIdx, tableLoop, rowLoop) {
      var rowData = this.data();
      if (rowData.paymentAmount > loggedInUserAuthority) {
        this.node().className = 'highlightData';
        isValidAuthority = false;
      }
    });
    return isValidAuthority;
  }

  bindPaymentLinkedDocuments(linkDocs) {
    let result = "";
    for (var i = 0; i < linkDocs.length; i++) {
      result += "<a href='" + linkDocs[i].linkedDocumentUrl + "' target = '_blank' > " + linkDocs[i].linkedDocumentName + " </a></br>";
    }
    return "<span>" + result + "</span>";
  }

  bindMMiClaimRefrence(full) {    
    let result = "";
    result += "<a href='" + "claims/" + full.policyId + "/" + full.claimId + "/" + "claimdetail" + "' target = '_blank' > " + full.claimNumber + " </a></br>";
    return "<span>" + result + "</span>";
  }
}

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { IPaymentBordereaux } from '../models/payment-bordereaux.model';
import { PaymentBordereaux } from '../models/search-payment-bordereaux.model';
import { claimsReport } from '../../models/claims-report.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private reportsUrl: string = "/api/Report/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.reportsUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.reportsUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.reportsUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.reportsUrl);
  }

  getClaimsReport(claimReportInput): any {
    var newurl = 'https://localhost:44305/api/Report/'
    let url = this.reportsUrl + 'getClaimReports';
    //console.log(claimReportInput);
    //return this.dataService.get(url, claimReportInput).pipe<string>(tap((response: any) => {
    //  return response;
    //}));
    return this.dataService.post(url, claimReportInput);

  }

  getClaimsCaseloadSummaryReport(claimReportInput): any {
    var newurl = 'https://localhost:44305/api/Report/'
    let url = this.reportsUrl + 'getClaimsCaseLoadSummaryReport';
    //console.log(claimReportInput);
    //return this.dataService.get(url, claimReportInput).pipe<string>(tap((response: any) => {
    //  return response;
    //}));
    return this.dataService.post(url, claimReportInput);

  }

  getClaimsLitigationReport(litigationReportInput): any {
    var newurl = 'https://localhost:44305/api/Report/'
    let url = this.reportsUrl + 'getClaimsLitigationReport';
    //console.log(claimReportInput);
    //return this.dataService.get(url, claimReportInput).pipe<string>(tap((response: any) => {
    //  return response;
    //}));
    return this.dataService.post(url, litigationReportInput);

  }

  getReinsuranceWorkflowReport(reinsuranceWorkflowRequestInput): any {
    let url = this.reportsUrl + 'getReinsuranceWorkflowReport';
    return this.dataService.post(url, reinsuranceWorkflowRequestInput);
  }

  getAllocatedClaimReport(allocatedClaimReportInput): any {
    let url = this.reportsUrl + 'getAllocatedClaimsReport';
    return this.dataService.post(url, allocatedClaimReportInput);
  }


  getELTOMatchingReport(eltoMatchingReportInput, fileReceivedDate): any {
    let url = this.reportsUrl + 'getELTOMatchingReport';
    const formData: FormData = new FormData();
    formData.append('fileName', eltoMatchingReportInput.fileToUpload.name)
    formData.append('formFile', eltoMatchingReportInput.fileToUpload)
    formData.append('fileReceivedDate', fileReceivedDate)
    return this.dataService.downloadFile(url, formData);
  }

  getReinsuranceListingReport(reinsuranceListingRequestInput): any {
    let url = this.reportsUrl + 'getReinsuranceListingReport';
    return this.dataService.post(url, reinsuranceListingRequestInput);
  }

  getReinsuranceCreditControlReport(reinsuranceCreditControlRequestInput): any {
    let url = this.reportsUrl + 'getReinsuranceCreditControlReport';
    return this.dataService.post(url, reinsuranceCreditControlRequestInput);
  }

  getAggregatedClaimsReport(aggregatedClaimsReportRequestInput): any {
    let url = this.reportsUrl + 'getAggregatedClaimsReport';
    return this.dataService.post(url, aggregatedClaimsReportRequestInput);
  }

  getPaymentsInPeriodReport(paymentsInPeriodReportInput): any {
    let url = this.reportsUrl + 'getPaymentsInPeriodReport';
    return this.dataService.post(url, paymentsInPeriodReportInput);
  }

  GetELTOWeeklyTracingReportsUrl(): any {
    let url = this.reportsUrl + "getELTOWeeklyTracingReportsUrl";
    return this.dataService.get(url);
  }

}







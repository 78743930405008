import { Injectable } from '@angular/core';
import { ReserveHistoryService } from '../../../shared/services/reservehistory.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimReserveHistoryService {
  constructor(private reserveHistoryService: ReserveHistoryService) {

  }

  getReserveHistoryByClaimId(claimId: number): any {
    return this.reserveHistoryService.getReserveHistoryByClaimId(claimId);
  }
}

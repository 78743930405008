import { Injectable } from '@angular/core';
import { IBroadcastMessage } from './broadcast-message.model';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service';
import { Observable } from 'rxjs';
import { BroadcastEmailMessageService } from '../../../shared/services/broadcast-email-message.service';

@Injectable({
  providedIn: 'root'
})
export class BroadcastMessageService {

  formData: IBroadcastMessage;

  constructor(private handlingOrganizationService: HandlingOrganisationService,
    private broadcastEmailMessageService: BroadcastEmailMessageService)
  { }

  getHandlingOrganization(): Observable<any> {
    return this.handlingOrganizationService.getHandlingOrganization();
  }

  broadcastMessageToUsers(): any {
    var body = {
      ...this.formData
    };
    return this.broadcastEmailMessageService.broadcastEmailMessageToUsers(body);
  }



}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserDetailsRoutingModule } from './user-details-routing.module';
import { UserDetailsComponent } from './user-details.component';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { MatSortModule } from '@angular/material/sort';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatTableModule } from '@angular/material/table';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MyColleaguesComponent } from './my-colleagues/my-colleagues.component';
import { MyHandlingOrganisationComponent } from './my-handling-organisation/my-handling-organisation.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  declarations: [UserDetailsComponent, MyProfileComponent, MyHandlingOrganisationComponent, MyColleaguesComponent],
  imports: [
    CommonModule,
    UserDetailsRoutingModule,
    FormsModule,
    DataTablesModule,
    MatTableModule,
    MatSortModule,
    NgbModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot()
  ]
})
export class UserDetailsModule { }

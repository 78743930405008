import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from './configuration.service';
import { StorageService } from './storage.service';
import { MsalService } from '@azure/msal-angular';
import { enumUserRole } from '../models/common.enum';

@Injectable()
export class SecurityService {

  private actionUrl: string;
  private headers: HttpHeaders;
  private storage: StorageService;
  private authenticationSource = new Subject<boolean>();
  authenticationChallenge$ = this.authenticationSource.asObservable();
  private userDataLoaded = new Subject<boolean>();
  userDataLoaded$ = this.userDataLoaded.asObservable();
  private authorityUrl = '';

  constructor(private _http: HttpClient, private _router: Router, private route: ActivatedRoute, private _configurationService: ConfigurationService, private _storageService: StorageService, private authService: MsalService) {
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('Accept', 'application/json');
    this.storage = _storageService;

    this._configurationService.settingsLoaded$.subscribe(x => {
      this.authorityUrl = this._configurationService.serverSettings.identityAgentUrl
      this.storage.store('IdentityUrl', this.authorityUrl);
    });

    if (this.storage.retrieve('IsAuthorized') !== '') {
      this.IsAuthorized = this.storage.retrieve('IsAuthorized');
      this.authenticationSource.next(true);
      this.UserData = this.storage.retrieve('userData');
    }
  }

  public IsAuthorized: boolean;

  public GetToken(): any {
    return this.storage.retrieve('authorizationDataIdToken');
  }

  public ResetAuthorizationData() {
    this.storage.clear();
    //this.storage.store('userData', '');
    //this.storage.store('authorizationDataIdToken', '');

    //this.IsAuthorized = false;
    //this.storage.store('IsAuthorized', false);
  }

  public UserData: any;

  public getUserData() {
    return this.storage.retrieve('userData');
  }
  public SetAuthorizationData(id_token: any) {
    //if (this.storage.retrieve('authorizationData') !== '') {
    //    this.storage.store('authorizationData', '');
    //}

    //this.storage.store('authorizationData', token);
    this.storage.store('authorizationDataIdToken', id_token);
    this.IsAuthorized = true;
    this.storage.store('IsAuthorized', true);
    this.authenticationSource.next(true);
    this.getUserDataFromServer()
      .subscribe(data => {
        this.UserData = data;
        this.storage.store('userData', data);
        // emit observable
        if (this.UserData == null) {
          this._router.navigate(['access-denied']);
        }
        this.userDataLoaded.next(true);
        this.authenticationSource.next(true);
        //window.location.href = location.origin;
      },
        error => this.HandleError(error),
        () => {
          console.log(this.UserData);
        });
  }

  public Authorize() {
    this.ResetAuthorizationData();

    let authorizationUrl = this.authorityUrl + '/connect/authorize';
    let client_id = 'js';
    let redirect_uri = location.origin + '/';
    let response_type = 'id_token token';
    let scope = 'openid profile orders basket marketing locations webshoppingagg orders.signalrhub';
    let nonce = 'N' + Math.random() + '' + Date.now();
    let state = Date.now() + '' + Math.random();

    this.storage.store('authStateControl', state);
    this.storage.store('authNonce', nonce);

    let url =
      authorizationUrl + '?' +
      'response_type=' + encodeURI(response_type) + '&' +
      'client_id=' + encodeURI(client_id) + '&' +
      'redirect_uri=' + encodeURI(redirect_uri) + '&' +
      'scope=' + encodeURI(scope) + '&' +
      'nonce=' + encodeURI(nonce) + '&' +
      'state=' + encodeURI(state);

    window.location.href = url;
  }

  public AuthorizedCallback() {
    this.ResetAuthorizationData();

    let hash = window.location.hash.substr(1);

    let result: any = hash.split('&').reduce(function (result: any, item: string) {
      let parts = item.split('=');
      result[parts[0]] = parts[1];
      return result;
    }, {});

    console.log(result);

    let token = '';
    let id_token = '';
    let authResponseIsValid = false;

    if (!result.error) {

      if (result.state !== this.storage.retrieve('authStateControl')) {
        console.log('AuthorizedCallback incorrect state');
      } else {

        token = result.access_token;
        id_token = result.id_token;

        let dataIdToken: any = this.getDataFromToken(id_token);

        // validate nonce
        if (dataIdToken.nonce !== this.storage.retrieve('authNonce')) {
          console.log('AuthorizedCallback incorrect nonce');
        } else {
          this.storage.store('authNonce', '');
          this.storage.store('authStateControl', '');

          authResponseIsValid = true;
          console.log('AuthorizedCallback state and nonce validated, returning access token');
        }
      }
    }

    if (authResponseIsValid) {
      this.SetAuthorizationData(id_token);
    }
  }

  //public Logoff() {
  //    let authorizationUrl = this.authorityUrl + '/connect/endsession';
  //    let id_token_hint = this.storage.retrieve('authorizationDataIdToken');
  //    let post_logout_redirect_uri = location.origin + '/';

  //    let url =
  //        authorizationUrl + '?' +
  //        'id_token_hint=' + encodeURI(id_token_hint) + '&' +
  //        'post_logout_redirect_uri=' + encodeURI(post_logout_redirect_uri);

  //    this.ResetAuthorizationData();

  //    // emit observable
  //    this.authenticationSource.next(false);
  //    window.location.href = url;
  //}

  public HandleError(error: any) {
    console.log(error);
    if (error.status == 403) {
      this._router.navigate(['/Forbidden']);
    }
    else if (error.status == 401) {
      // this.ResetAuthorizationData();
      this._router.navigate(['/Unauthorized']);
    }
  }

  private urlBase64Decode(str: string) {
    let output = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw 'Illegal base64url string!';
    }

    return window.atob(output);
  }

  private getDataFromToken(token: any) {
    let data = {};

    if (typeof token !== 'undefined') {
      let encoded = token.split('.')[1];

      data = JSON.parse(this.urlBase64Decode(encoded));
    }

    return data;
  }

  //private retrieve(key: string): any {
  //    let item = this.storage.getItem(key);

  //    if (item && item !== 'undefined') {
  //        return JSON.parse(this.storage.getItem(key));
  //    }

  //    return;
  //}

  //private store(key: string, value: any) {
  //    this.storage.setItem(key, JSON.stringify(value));
  //}

  private getUserDataFromServer = (): Observable<string[]> => {
    this.authorityUrl = this._configurationService.serverSettings.policyClaimsAgentUrl;


    const options = this.setHeaders();

    return this._http.get<string[]>(`${this.authorityUrl}/api/auth/getLoggedInUser`, options)
      .pipe<string[]>((info: any) => info);
  }

  private setHeaders(): any {
    const httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    httpOptions.headers = httpOptions.headers.set('Accept', 'application/json');

    const token = this.GetToken();

    if (token !== '') {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${token}`);
    }

    return httpOptions;
  }



  checkAccount() {
    return !!this.authService.getAccount();
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    //if (isIE) {
      this.authService.loginRedirect({
        extraScopesToConsent: ["api://f22dade7-465e-46b1-804c-11285119dcef/user_impersonation"]
      });
    //} else {
    //  this.authService.loginPopup({
    //    extraScopesToConsent: ["api://f22dade7-465e-46b1-804c-11285119dcef/user_impersonation"]
    //  });
    //}
  }

  logout() {
    this.authService.logout();
    this.ResetAuthorizationData();
  }

  getAccessOnPage(pagePermissionId: string): boolean {    
    let isaccessible: boolean = false;
    if (this.getUserData() != null) {
      let userRoleId = this.getUserData().userRoleId;
      let isMMIUser = this.getUserData().organisationId == 1 ? true : false;
      let superUser = this.getUserData().superUser;
      let userRolePermissionModels: any[] = this.getUserData().userRolePermissionModels;

      let userRolePermissions: any[] = [];

      userRolePermissions = userRolePermissionModels.filter(
        c => c.userPermissionsId == pagePermissionId && c.isMMIUser == isMMIUser && c.superUser == superUser);

      if (userRolePermissions != null && userRolePermissions.length > 0 && userRolePermissions[0].permission != 0) {
        isaccessible = true;
      }
    }

    return isaccessible;
  }

  getAccessAndPermissionOnPage(pagePermissionId: string, permittedAction: string): boolean {
    let isaccessible: boolean = false;
    if (this.getUserData() != null) {
    let userRoleId = this.getUserData().userRoleId;
    let isMMIUser = this.getUserData().organisationId == 1 ? true : false;
    let superUser = this.getUserData().superUser;
    let userRolePermissionModels: any[] = this.getUserData().userRolePermissionModels;

    let userRolePermissions: any[] = [];

    userRolePermissions = userRolePermissionModels.filter(
      c => c.userPermissionsId == pagePermissionId && c.isMMIUser == isMMIUser && c.superUser == superUser);

      if (userRolePermissions != null && userRolePermissions.length > 0 && userRolePermissions[0].permission != 0) {
        if (userRolePermissions.length == 1) {
          if (userRolePermissions[0].permission == permittedAction) {
            isaccessible = true;
          }
        }
        else {

        }
      }
    }

    return isaccessible;
  }

  accuireToken() {
    const accessTokenRequest = {
      scopes: ["api://f22dade7-465e-46b1-804c-11285119dcef/user_impersonation"]
    }

    const accessTokenPromptRequest = {
      scopes: ["api://f22dade7-465e-46b1-804c-11285119dcef/user_impersonation"],
      prompt: 'login'
    }
    let that = this;
    this.authService.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
      // Acquire token silent success
      // Call API with token
      let accessToken = accessTokenResponse.accessToken;
      that.SetAuthorizationData(accessTokenResponse.idToken);
    }).catch(function (error) {
      //Acquire token silent failure, and send an interactive request
      // if (error.errorMessage.indexOf("interaction_required") !== -1) {
      that.authService.acquireTokenPopup(accessTokenPromptRequest).then(function (accessTokenResponse) {
        that.SetAuthorizationData(accessTokenResponse.idToken);
      }).catch(function (error) {
        // Acquire token interactive failure
        console.log(error);
        that.authenticationSource.next(false);
        that.userDataLoaded.next(false);
      });
      //}
      //console.log(error);
    });
  }

  isUserRoleNEDOrAuditor() {
    let isUserRole: boolean = false;
    let userRoleId = this.getUserData().userRoleId;
    isUserRole = (userRoleId == enumUserRole.Auditor || userRoleId == enumUserRole.NED) ? true : false;
    return isUserRole;
  }
}

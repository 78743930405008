import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { IConfiguration } from '../models/configuration.model';
import { StorageService } from './storage.service';

import { Observable, Subject } from 'rxjs';

@Injectable()
export class ConfigurationService {

  serverSettings: IConfiguration;
  // observable that is fired when settings are loaded from server
  private settingsLoadedSource = new Subject();
  settingsLoaded$ = this.settingsLoadedSource.asObservable();
  isReady: boolean = false;

  constructor(private http: HttpClient, private storageService: StorageService) {

  }

  loadDefaultUrlsFromStorage() {
    this.serverSettings = {
      identityAgentUrl: this.storageService.retrieve('identityUrl'),
      administrationAgentnUrl: this.storageService.retrieve('administrationAgentUrl'),
      dmsAgentUrl: this.storageService.retrieve('dmsAgentUrl'),
      policyClaimsAgentUrl: "https://wa-mmi-uks-prod-be01.azurewebsites.net",
      reportingAgentUrl: this.storageService.retrieve('reportingAgentUrl')
    }
  }

  load() {
    this.loadDefaultUrlsFromStorage();
    const baseURI = document.baseURI.endsWith('/') ? document.baseURI : `${document.baseURI}/`;
    let url = `${baseURI}Home/Configuration`;
    return this.http.get(url).subscribe((response) => {
      console.log('server settings loaded');
      this.serverSettings = response as IConfiguration;
      console.log(this.serverSettings);
      this.storageService.store('identityUrl', this.serverSettings.identityAgentUrl);
      this.storageService.store('administrationAgentUrl', this.serverSettings.administrationAgentnUrl);
      this.storageService.store('dmsAgentUrl', this.serverSettings.dmsAgentUrl);
      this.storageService.store('policyClaimsAgentUrl', this.serverSettings.policyClaimsAgentUrl);
      this.storageService.store('reportingAgentUrl', this.serverSettings.reportingAgentUrl);
      this.isReady = true;
      this.settingsLoadedSource.next();
      return;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '../../../shared/services/security.service';


@Component({
  selector: 'app-my-handling-organisation',
  templateUrl: './my-handling-organisation.component.html',
  styleUrls: ['./my-handling-organisation.component.css']
})
export class MyHandlingOrganisationComponent implements OnInit {
  handlingOrganisationId: any
  isSuperUser:any
  constructor(private securityService: SecurityService, private activeRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.handlingOrganisationId = this.securityService.getUserData().organisationId;
   // this.isSuperUser = this.securityService.getUserData().superUser;
    //if (this.isSuperUser == 0) {
    //  debugger;
    //  this.router.navigate(['/handling-organisation/edit/' + this.handlingOrganisationId], { queryParams: { isSuperUser: false } });
    //  //this.router.navigate(['/handling-organisation/edit/' + data.participantId], { queryParams: { isSearchFlag: true } });

    //}
    //else {
    this.router.navigate(['/handling-organisation/edit/' + this.handlingOrganisationId], { queryParams: {editFlag : true } });
    //}
  }

}

import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { NotificationService } from '../../../shared/services/notification.service';
import { Subject } from 'rxjs';
import { IPager } from '../../../shared/models/pager.model';
import { DataTableDirective } from 'angular-datatables';
import { IndexationService } from './indexation.service';
import { IIndexation } from './indexation.model';

@Component({
  selector: 'app-indexation',
  templateUrl: './indexation.component.html',
  styleUrls: ['./indexation.component.css']
})
export class IndexationComponent implements  OnInit {

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  @ViewChild('indexationTable') indexationTable: ElementRef;


 // public paginationInfo: IPager;
 // public authenticated: boolean = false;
  public errorReceived: boolean;
  public dtTrigger: Subject<any>;
  isGridLoaded: boolean = false; 
  
  indexationResult: IIndexation[] = [];
  public dtOptions: any = {};  

  displayedColumns: any[] = [
    { isAlwaysVisible: false, title: 'ID', data: 'id', visible: false },
    { isAlwaysVisible: false, title: 'SettlementYearID', data: 'settlementYear', visible: false },
    { isAlwaysVisible: true, title: 'Cover Year', data: 'settlementYearText', visible: true },
    { isAlwaysVisible: false, title: 'LayerID', data: 'layerId', visible: false },
    { isAlwaysVisible: true, title: 'Layer', data: 'layerText', visible: true },
    { isAlwaysVisible: true, title: 'Excess', data: 'excess', visible: true, render: $.fn.dataTable.render.number(',', '.', 2, '£')  },
    { isAlwaysVisible: true, title: 'Limit', data: 'limit', visible: true, render: $.fn.dataTable.render.number(',', '.', 2, '£')  },
  ];

  constructor(private indexationService: IndexationService, private notificationService: NotificationService)
  {
    this.dtTrigger = new Subject();
  }

  ngOnInit(): void {
    this.initialiseDataTable();    
    this.refreshGrid();
  }

  initialiseDataTable(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedColumns,
      'columnDefs': [
        { width: "25%", targets: 0 },
        { width: "25%", targets: 1 },
        { width: "25%", targets: 2 },
        { width: "25%", targets: 3 },        
      ],
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [{}],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Set the checked state of the checkbox in the table
        
        // Unbind first in order to avoid any duplicate handler        
        

        return row;
      },
      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        this.indexationService.getReinsuranceIndexations().subscribe((response: any) => {
          this.indexationResult = response;
          callback({
            data: response
          });
        });
      }
    };
  }

  refreshGrid() {
    if (this.indexationTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../../../shared/services/security.service';


@Component({
  selector: 'app-my-colleagues',
  templateUrl: './my-colleagues.component.html',
  styleUrls: ['./my-colleagues.component.css']
})
export class MyColleaguesComponent implements OnInit {
  handlingOrganisationId: any;
  constructor(private activeRoute: ActivatedRoute,
    private router: Router, private securityService: SecurityService) { }

  ngOnInit(): void {
    this.handlingOrganisationId = this.securityService.getUserData().organisationId;
    //if (this.handlingOrganisationId == 1) {
    //  this.router.navigate(['/claim-handler'], { queryParams: { organisationid: this.handlingOrganisationId, isColleaguesSearch: true } });
    //}
    //else {
      this.router.navigate(['/manage-colleagues'], { queryParams: { organisationid: this.handlingOrganisationId, isColleaguesSearch: true } });
    //}
  }

}

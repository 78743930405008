import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimAssignmentService } from './claim-assignment.service';
import { ClaimAssignmentVM, ClaimRequestVM, ClaimResponseVM } from './claim-assignment.model';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../../shared/services/notification.service';

@Component({
  selector: 'app-claim-assignment',
  templateUrl: './claim-assignment.component.html',
  styleUrls: ['./claim-assignment.component.css']
})
export class ClaimAssignmentComponent implements OnInit {

  policyId: string;
  claimId: string;
  handlingOrganisationId: string;

  claimExternalRequest: ClaimRequestVM;
  claimAssignment: ClaimResponseVM;
  handlingStatusId: number;
  messageToUser: string;

  constructor(private claimAssignmentService: ClaimAssignmentService,
    private route: ActivatedRoute,
    private notificationService: NotificationService) {
    this.route.queryParams.subscribe(params => {
      this.policyId = params['PID'];
      this.claimId = params['CID'];
      this.handlingOrganisationId = params['HID'];
    });
  }

  ngOnInit(): void {
    this.createRequestParam();
    this.getClaimData();
  }

  createRequestParam() {
    this.claimExternalRequest = new ClaimRequestVM();
    this.claimExternalRequest.claimId = parseInt(this.claimId);
    this.claimExternalRequest.policyId = parseInt(this.policyId);
    this.claimExternalRequest.handlingOrganisationId = parseInt(this.handlingOrganisationId);    
  }

  getClaimData() {
    this.claimAssignmentService.getClaim(this.claimExternalRequest).subscribe(res => {
      this.claimAssignment = res;
      if (this.claimAssignment != null) {
        this.handlingStatusId = this.claimAssignment.handlingStatusId;
        if (this.handlingStatusId == 953) {
          this.messageToUser = "A claim has been allocated to your organisation by MMI";
        }
        else if (this.handlingStatusId == 954) {
          this.messageToUser = "Claim was accepted earlier";
        }
        else if (this.handlingStatusId == 955) {
          this.messageToUser = "Claim was rejected earlier";
        }
      }
      else {
        this.messageToUser = "This claim has been deallocated from you and reallocated by MMI. Please contact MMI for further details.";
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  acceptClaimAssignment() {
    const assignment = new ClaimAssignmentVM();
    assignment.policyId = parseInt(this.policyId);
    assignment.claimId = parseInt(this.claimId);
    assignment.handlingOrganisationId = parseInt(this.handlingOrganisationId);
    assignment.isAccepted = true;

    this.claimAssignmentService.updateClaimAssignment(assignment).subscribe(res => {
      this.claimAssignment = res;
      if (this.claimAssignment != null) {
        this.handlingStatusId = this.claimAssignment.handlingStatusId;
        if (this.handlingStatusId == 954) {
          this.messageToUser = "Claim is accepted";
          this.notificationService.printSuccessMessage('Claim accepted successfully');
        }
      }
      else {
        this.notificationService.printErrorMessage("No data found");
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );

  }

  rejectClaimAssignment() {
    const assignment = new ClaimAssignmentVM();
    assignment.policyId = parseInt(this.policyId);
    assignment.claimId = parseInt(this.claimId);
    assignment.handlingOrganisationId = parseInt(this.handlingOrganisationId);
    assignment.isAccepted = false;
    this.claimAssignmentService.updateClaimAssignment(assignment).subscribe(res => {      
      this.claimAssignment = res;
      if (this.claimAssignment != null) {
        this.handlingStatusId = this.claimAssignment.handlingStatusId;
        if (this.handlingStatusId == 955) {
          this.messageToUser = "Claim is rejected";
          this.notificationService.printSuccessMessage('Claim rejected successfully');
        }
      }
      else {
        this.notificationService.printErrorMessage("No data found");
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  

}

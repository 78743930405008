
export class ClaimRequestVM {
  policyId: number;
  claimId: number;
  handlingOrganisationId: number;
}

export class ClaimResponseVM {
  policyId: number;
  claimId: number;
  handlingOrganisationId: number;
  handlingStatusId: number;
  schemeCreditorId?: number;
}

export class ClaimAssignmentVM {
  policyId: number;
  claimId: number;
  handlingOrganisationId: number;
  isAccepted: boolean;
}

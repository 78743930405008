import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewChecked } from '@angular/core';
import { ELLayerService } from '../el-layer/el-layer.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { IELLayer } from './el-layer.model';

@Component({
  selector: 'app-el-layer',
  templateUrl: './el-layer.component.html',
  styleUrls: ['./el-layer.component.css']
})
export class ElLayerComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  public claimDetailNotesResult: any;
  @ViewChild('elLayerDetailsTable') elLayerDetailsTable: ElementRef;
  public dtOptions: any = {};

  public dtTrigger: Subject<any>;
  ELLayerResult: IELLayer[] = [];
  claimId: string;
  datahave: boolean = false;
  isGridLoaded: boolean = false;

  constructor(private elLayerService: ELLayerService) {
    this.dtTrigger = new Subject();
  }

  ngOnInit(): void {
    this.initialiseDataTable();
    this.bindELLayerDetails();
  }

  bindELLayerDetails() {
    this.elLayerService.getELLayerDetails().subscribe(res => {
      if (res == null) {
        this.ELLayerResult = [];
      }
      else if (res != null) {
        //debugger
        this.ELLayerResult = res;
      }
      this.refreshGrid();
    });
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    if (this.elLayerDetailsTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  initialiseDataTable(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [
      ],
      columnDefs: [
        {
          targets: 0,
          visible: false,
          searchable: false
        },

      ],
      order: [[0, 'asc']]
    };
  }



}

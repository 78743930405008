import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManageReinsurerService {

  private reinsurerUrl: string = "/api/reinsurer/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.reinsurerUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.reinsurerUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.reinsurerUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.reinsurerUrl);
  }

  getReinsurerById(id: number): any {
    let url = this.reinsurerUrl + "getReinsurerById";

    return this.dataService.get(url, { id: id });
  }

  getAllReinsurers(): any {
    let url = this.reinsurerUrl + "getAllReinsurers";
    return this.dataService.get(url);
  }

  searchReinsurerName(search: string) {
    let url = this.reinsurerUrl + "getReinsurerName";
    return this.dataService.get(url, { search: search });
  }

  getReinsurerByName(reinsurerName: string): any {
    let url = this.reinsurerUrl + "getReinsurerByName";
    return this.dataService.get(url, { reinsurerName: reinsurerName });
  }

  createReinsurer(data): any {
    let url = this.reinsurerUrl + "addReinsurer";
    return this.dataService.post(url, data);
  }

  updateReinsurer(data): any {
    let url = this.reinsurerUrl + "editReinsurer";
    return this.dataService.putWithId(url, data);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/common-components/nav-menu/nav-menu.component';
import { AppRoutingModule } from './app-routing.module';
import { NgForm } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { ClaimModule } from './components/claim/claim.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { ClaimService } from './shared/services/claim.service';
import { PolicyholderComponent } from './components/add-participant/policyholder/policyholder.component';
import { ExcessDetailComponent } from './components/add-participant/policyholder/excess-detail/excess-detail.component';
import { LimitOfIndemnityComponent } from './components/add-participant/policyholder/limit-of-indemnity/limit-of-indemnity.component';
import { LongTermAgreementComponent } from './components/add-participant/policyholder/long-term-agreement/long-term-agreement.component';
import { ClaimLevelSchemeCreditorComponent } from './components/add-participant/policyholder/claim-level-scheme-creditor/claim-level-scheme-creditor.component';
import { PolicyholderService } from './components/add-participant/policyholder/policyholder.service'
import { DataTablesModule } from 'angular-datatables';
import { LookupService } from './shared/services/lookup.service';
import { PolicyNoteComponent } from './components/add-participant/policyholder/policy-note/policy-note.component';
import { CreditorholderService } from './components/add-participant/creditorholder/creditorholder.service'
import { ParticipantService } from './shared/services/participant.service';
import { CreditorComponent } from './components/add-participant/creditorholder/creditor.component';
import { HandlingorganisationComponent } from './components/add-participant/handlingorganisation/handlingorganisation.component';
import { ClaimComponent } from './components/claim/claim.component';
import { ClaimValuationComponent } from 'src/app/components/claim/valuation/claim-valuation.component';
import { ClaimDetailNotesComponent } from './components/claim/claim-detail-notes/claim-detail-notes.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { ParticipantClaimHandlerComponent } from './components/add-participant/claim-handler/participant-claim-handler.component';
import { HandlingOrganisationService } from './shared/services/handling-organisation.service';
import { ClaimHandlerService } from './shared/services/claim-handler.service';
import { ParticipantClaimHandlerService } from './components/add-participant/claim-handler/participant-claim-handler.service';
import { ClaimDetailComponent } from './components/claim/claim-detail/claim-detail.component';
import { ApportionmentService } from './shared/services/apportionment.service';
import { AddParticipantModule } from './components/add-participant/add-participant.module';
import { ClaimRoutingModule } from './components/claim/claim-routing.module';
import { ClaimDetailNotesService } from './shared/services/claimdetail-notes.service'
import { PaymentBordereauxModule } from './components/payment-bordereaux/payment-bordereaux.module';
import { PaymentBordereauxRoutingModule } from './components/payment-bordereaux/payment-bordereaux-routing.module';
import { ReserveHistoryComponent } from 'src/app/components/claim/reserve-history/reserve-history.component';
import { UnsavedChangesGuard } from './shared/guard/unsaved-changes.guard';
import { DMSService } from './shared/services/dms.service';
import { ClaimsReportComponent } from './components/reports/claims-report/claims-report.component';
import { ReportsModule } from './components/reports/reports.module';
import { ReportsService } from './shared/services/reports.service';
import { CaseloadSummaryService } from './components/reports/caseload-summary/caseload-summary.service';
import { CaseloadSummaryComponent } from './components/reports/caseload-summary/caseload-summary.component';
import { AdministrationModule } from './components/administration/administration.module';
import { AdministrationRoutingModule } from './components/administration/administration-routing.module';
import { UserDetailsModule } from './components/user-details/user-details.module';
import { UserDetailsRoutingModule } from './components/user-details/user-details-routing.module';
import { MyProfileService } from './components/user-details/my-profile/my-profile-service';
import { LogOutComponent } from './components/log-out/log-out.component';
import { LitigationReportComponent } from './components/reports/litigation-report/litigation-report.component';
import { LitigationReportService } from './components/reports/litigation-report/litigation-report.service';
import { ClaimAssignmentComponent } from './components/claim/claim-assignment/claim-assignment.component';
import { RoleGuardService } from './shared/services/role-guard.service';
import { BaseComponentComponent } from './components/base-component/base-component.component';
import { ReinsuranceModule } from './components/reinsurance/reinsurance.module';
import { ReinsuranceRoutingModule } from './components/reinsurance/reinsurance-routing.module';
import { ReinsurerComponent } from './components/add-participant/reinsurer/reinsurer.component';
import { LoaderService } from './shared/services/loader.service';
import { LoaderInterceptor } from './shared/services/loader.interceptor';
import { NavigationService } from './shared/services/navigation.service';
import { ReinsuranceWorkflowComponent } from './components/reports/reinsurance-workflow/reinsurance-workflow.component';
import { AllocatedClaimComponent } from './components/reports/allocated-claim/allocated-claim.component';
import { EltoMatchingComponent } from './components/reports/elto-matching/elto-matching.component';
import { ReinsuranceListingComponent } from './components/reports/reinsurance-listing/reinsurance-listing.component';
import { ReinsuranceCreditControlComponent } from './components/reports/reinsurance-credit-control/reinsurance-credit-control.component';
import { AddWorkflowItemComponent } from './components/claim/add-workflow-item/add-workflow-item.component';
import { ClaimWorkflowService } from './shared/services/claim-workflow.service';
import { SearchWorkflowComponent } from './components/search-workflow/search-workflow.component';
import { AggregatedClaimsReportComponent } from './components/reports/aggregated-claims-report/aggregated-claims-report.component';
import { ReportingPeriodPaymentsComponent } from './components/reports/reporting-period-payments/reporting-period-payments.component';
import { DwpComponent } from './components/add-participant/dwp/dwp.component';
import { ManageDwpService } from './shared/services/manage-dwp.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    PolicyholderComponent,
    ExcessDetailComponent,
    LimitOfIndemnityComponent,
    LongTermAgreementComponent,
    ClaimLevelSchemeCreditorComponent,
    PolicyNoteComponent,
    CreditorComponent,
    HandlingorganisationComponent,
    ClaimComponent,
    ParticipantClaimHandlerComponent,
    ClaimDetailComponent,
    ClaimValuationComponent,
    ClaimDetailNotesComponent,
    ReserveHistoryComponent,
    ClaimsReportComponent,
    CaseloadSummaryComponent,
    LogOutComponent,
    LitigationReportComponent,
    ClaimAssignmentComponent,
    BaseComponentComponent,
    ReinsurerComponent,
    ReinsuranceWorkflowComponent,
    AllocatedClaimComponent,
    EltoMatchingComponent,
    ReinsuranceListingComponent,
    ReinsuranceCreditControlComponent,
    SearchWorkflowComponent,
    AggregatedClaimsReportComponent,
    ReportingPeriodPaymentsComponent,
    DwpComponent
  ],
  exports: [ExcessDetailComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    AppRoutingModule,
    MatTableModule,
    DataTablesModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxTypeaheadModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.25)',
      backdropBorderRadius: '0px',
      primaryColour: '#0395d9',
      secondaryColour: '#0395d9',
      tertiaryColour: '#0395d9'
    }),
    MsalModule.forRoot({
      auth: {
        clientId: '037bae23-e01f-4243-99ef-fe835ff5e2fb',
        authority: 'https://login.microsoftonline.com/c69ccde6-48e1-40a0-97b3-86a1d692a4e1',
        redirectUri: 'https://pierian.mminsurance.co.uk/',
        postLogoutRedirectUri: 'https://pierian.mminsurance.co.uk/log-out'
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
      {
        popUp: false,
        consentScopes: [
          'api://f22dade7-465e-46b1-804c-11285119dcef/user_impersonation'
        ],
        unprotectedResources: ['http://wa-mmi-uks-prod-be01.azurewebsites.net/api/claimassignment', 'https://wa-mmi-uks-prod-be01.azurewebsites.net/api/claimassignment'],
        protectedResourceMap: [
          ['https://wa-mmi-uks-prod-be01.azurewebsites.net', ['api://f22dade7-465e-46b1-804c-11285119dcef/user_impersonation']]
        ],
        extraQueryParameters: {}
      }),
    AddParticipantModule,
    ClaimModule,
    ClaimRoutingModule,
    PaymentBordereauxModule,
    PaymentBordereauxRoutingModule,
    ReportsModule,
    AdministrationModule,
    AdministrationRoutingModule,
    UserDetailsModule,
    UserDetailsRoutingModule,
    ReinsuranceModule,
    ReinsuranceRoutingModule,
    NgbTooltipModule
  ],

  providers: [NgForm, NavigationService, ClaimService, LookupService, ParticipantService, PolicyholderService,
    CreditorholderService,
    ParticipantClaimHandlerService, HandlingOrganisationService, ClaimHandlerService,
    ApportionmentService, ClaimDetailNotesService, UnsavedChangesGuard, DMSService, ReportsService,
    CaseloadSummaryService, MyProfileService, LitigationReportService, RoleGuardService,
    LoaderService, ClaimWorkflowService, ManageDwpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true
    }
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }


import { Injectable } from "@angular/core";
import { ParticipantClaimHandlerService } from "../../add-participant/claim-handler/participant-claim-handler.service";
import { ClaimHandler } from "../../add-participant/claim-handler/participant-claim-handler.model";
import { HandlingOrganisationService } from "../../../shared/services/handling-organisation.service";




@Injectable({
  providedIn: 'root'
})


export class MyProfileService {
  constructor(private participantClaimHandlerService: ParticipantClaimHandlerService, private handlingOrganisationService: HandlingOrganisationService) { }


  getClaimHandler(claimHandlerId: any) {
    return this.participantClaimHandlerService.getClaimHandler(claimHandlerId);
  }

  putClaimHandlers(claimHandler: ClaimHandler) {
    return this.participantClaimHandlerService.putClaimHandlers(claimHandler);
  }

  getAllHandlingOrganisations() {
    return this.handlingOrganisationService.getAllHandlingOrganisations();
  }
 
}



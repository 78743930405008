import { ApportionmentService } from '../../../shared/services/apportionment.service';
import { Observable } from 'rxjs';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { ReportsService } from '../../../shared/services/reports.service';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service';
import { CaseloadSummary } from '../../../models/caseload-summary.model';
import { ClaimHandlerService } from '../../../shared/services/claim-handler.service';
import { Injectable } from '@angular/core';
import { NotificationService } from '../../../shared/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class CaseloadSummaryService {
  formDataInput: CaseloadSummary;
  //claimsReportInputModel: claimsReport = new claimsReport();

  constructor(private reportsService: ReportsService, private handlingOrganizationService: HandlingOrganisationService,
    private claimHandlerService: ClaimHandlerService, private notificationService: NotificationService) { }



  getHandlingOrganization(): Observable<any> {
    return this.handlingOrganizationService.getHandlingOrganization();
  }

  getClaimHandlersByHandlingOrganisation(organisationId: any) {
    return this.claimHandlerService.getClaimHandlers(organisationId);
  }

  generateCaseloadSummaryReport(): any {
    var body = {
      ...this.formDataInput

    };

    this.reportsService.getClaimsCaseloadSummaryReport(this.formDataInput).subscribe((response: any) => {
      //debugger
      if (response != null ) {
        if (response.claimsCaseLoadSummaryReportResult != null && response.claimsCaseLoadSummaryReportResult.length > 0) {
          let fileName = "";
          let currentDate = moment(new Date().toDateString()).format('DD/MM/YYYY');
          let handlingOrganisationName = "";
          let claimHandlerName = "";
          let authorityReport = "";
          let fileFormat = "";
          //let paymentAuthority = "";

          if (this.formDataInput.handlingOrganisationId == 0 && this.formDataInput.claimHandlersIdValues.includes(0)) {
            handlingOrganisationName = "All-"
          }
          else {
            handlingOrganisationName = response.claimsCaseLoadSummaryReportResult[0].claimsCaseLoadSummaryReportList[0].handlingOrganisationName + "-";
            claimHandlerName = "Claim Handlers-";
          }

          if ((this.formDataInput.handlingOrganisationId != 0) && (response.claimsCaseLoadSummaryReportResult.length > 1) &&
            (this.formDataInput.aboveHandlingAuthorityId > 0 || this.formDataInput.abovePaymentAuthorityId > 0)) {
            authorityReport = "Caseload Summary Authority Report_";
          }


          let workbook: ExcelProper.Workbook = new Excel.Workbook();
          let worksheet = workbook.addWorksheet('CaseLoad Summary Report');
          let worksheet1 = workbook.addWorksheet('List of Claims- HO');
          let worksheet2 = workbook.addWorksheet('List of Claims- KC');

          if (authorityReport.length > 0) {
            fileFormat = authorityReport + handlingOrganisationName + claimHandlerName + currentDate;
          }
          else {
            fileFormat = "Caseload Summary Report_" + handlingOrganisationName + claimHandlerName + currentDate;
          }

          //let reportTypeHeaderName = ["Caseload Summary", "Claims above Handling Authority", "Claims above Payment Authority"];
          let abc = response;
          //debugger
          for (let j = 0; j < response.claimsCaseLoadSummaryReportResult.length; j++) {
            this.generateExcelData(worksheet, response.claimsCaseLoadSummaryReportResult[j].reportName, response.claimsCaseLoadSummaryReportResult[j]);
          }
          let distinctHandlingOrganisation = [...new Set(response.claimResultItems.map((o) => o.handlingOrganisation))];
          this.generateExcelForListOfClaimsByClaimHandler(worksheet1, "", response, distinctHandlingOrganisation);
          this.generateExcelForListOfClaimsByKeyContacts(worksheet2, "", response, distinctHandlingOrganisation);

          fileName = fileFormat;
          let date = new Date();
          let hours = date.getHours();
          let minutes = date.getMinutes();
          let seconds = date.getSeconds();
          let timestamp = hours + '' + minutes + '' + seconds;
          //let timestamp = Math.floor(new Date()/ 1000);
          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fileName + '_' + timestamp + '.xlsx');
          })
        }
        else {
          this.notificationService.printWarningMessage("No data is available for the Report");
        }
      }
      else {
        this.notificationService.printWarningMessage("No data is available for the Report");
      }

    })
  }

  generateExcelData(worksheet: ExcelProper.Worksheet, reportTypeHeader, response: any) {
    
    let claimHandlerHeader = ["Handling Organisation", "Claims Handler"];
    let exposureHeaderRow;
    let toprow = worksheet.addRow([reportTypeHeader]);
    toprow.findCell(1).font = { bold: true };
   
    let claimtypesarray = response.claimsCaseLoadSummaryReportList[0].claimTypes.split(',');
    
    if (claimtypesarray.length > 0) {
      claimtypesarray.forEach(item => {
        claimHandlerHeader.push(item);
      });
    }
    claimHandlerHeader.push("Total");

    exposureHeaderRow = worksheet.addRow(claimHandlerHeader);
    exposureHeaderRow.font = { bold: true };
    exposureHeaderRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });
    
    response.claimsCaseLoadSummaryReportList.forEach((item, index) => {
      if (item.claimsTypeList != null) {
        let data: any[] = [];
        data.push(item.handlingOrganisationName);
        data.push(item.claimHandlerName);
        item.claimsTypeList.forEach(i => {
          data.push(i["count"]);
        });
        data.push(item.total);
        let summaryRow1 = worksheet.addRow(data);
      } 
    });

    let claimTypewiseTotalCountData: any[] = ['Total Claims', ""];
    response.claimTypeWiseTotalCount.forEach(i => {
    //  i = i.toFixed(2);
      claimTypewiseTotalCountData.push(i);
    });

    let summaryRowTotalClaimCount = worksheet.addRow(claimTypewiseTotalCountData);
    
    summaryRowTotalClaimCount.font = { bold: true };
    summaryRowTotalClaimCount.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'right' }
      this.fillBackGroundInTotalRowExcellCell(cell);
      this.setBorderExcelCell(cell);
    });
   
    if (this.formDataInput.claimKeyContactValues.length > 0) {      
      worksheet.addRow("");
      let mmiKeyContactsHeader = ["Handling Organisation", "MMI Key Contact"];
      let delegatedAuthorityHeader = ["", ""];
      let firstExposureHeaderRow;
      let SecondExposureHeaderRow;
      if (claimtypesarray.length > 0) {
        claimtypesarray.forEach(item => {
          mmiKeyContactsHeader.push(item);
          mmiKeyContactsHeader.push("");
          mmiKeyContactsHeader.push("");
          mmiKeyContactsHeader.push("");
        });

      }
      mmiKeyContactsHeader.push("Total");

      if (claimtypesarray.length > 0) {
        claimtypesarray.forEach(item => {
          delegatedAuthorityHeader.push("DA = Yes");
          delegatedAuthorityHeader.push("DA = No");
          delegatedAuthorityHeader.push("DA = N/A");
          delegatedAuthorityHeader.push("DA = Total");
        });
      }
      delegatedAuthorityHeader.push("");
      firstExposureHeaderRow = worksheet.addRow(mmiKeyContactsHeader);
      SecondExposureHeaderRow = worksheet.addRow(delegatedAuthorityHeader);

      firstExposureHeaderRow.font = { bold: true };
      firstExposureHeaderRow.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'center' }
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      SecondExposureHeaderRow.font = { bold: true };
      SecondExposureHeaderRow.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'center' }
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      let claimsTypePercentage: any[] = [];
      let total = 0.0
      response.claimsCaseLoadSummaryReportList.forEach((item, index) => {

        if (item.keyContactsClaimTypeList != null) {
          let keyContactsData: any[] = [];

          // let claimsTypePercentage: any[] = [];
          keyContactsData.push(item.handlingOrganisationName);
          keyContactsData.push(item.keyContactHandlerName);
          item.keyContactsClaimTypeList.forEach(i => {            
            keyContactsData.push(i["delegatedAuthorityYesCount"]);
            keyContactsData.push(i["delegatedAuthorityNoCount"]);
            keyContactsData.push(i["delegatedAuthorityNACount"]);
            keyContactsData.push(i["count"]);

          });
          let totalPaidSum = _.sumBy(item.keyContactsClaimTypeList, (o: any) => { return o.count });
          keyContactsData.push(totalPaidSum);

          let summaryRow2 = worksheet.addRow(keyContactsData);


        }
      });
      let delegatedPercentagedata: any[] = ['Total Claims', ""];
      response.delegatedAuthorityTotalPercentageList.forEach(i => {
        //i.delegatedAuthorityYesPercentage = i.delegatedAuthorityYesPercentage.toFixed(2);
        //i.delegatedAuthorityNoPercentage = i.delegatedAuthorityNoPercentage.toFixed(2);
        //i.delegatedAuthorityNAPercentage = i.delegatedAuthorityNAPercentage.toFixed(2)
        //i.delegatedAuthorityTotalPercentage = i.delegatedAuthorityTotalPercentage.toFixed(2)
        delegatedPercentagedata.push(i.claimTypeWiseDelegatedAuthorityYesCount);
        delegatedPercentagedata.push(i.claimTypeWiseDelegatedAuthorityNoCount);
        delegatedPercentagedata.push(i.claimTypeWiseDelegatedAuthorityNACount);
        delegatedPercentagedata.push(i.totalClaimsCount);
      });
      var totalClaimsCount = _.sumBy(response.delegatedAuthorityTotalPercentageList, (o: any) => { return o.totalClaimsCount });
      delegatedPercentagedata.push(totalClaimsCount);
      let summaryRowPercentageClaimType = worksheet.addRow(delegatedPercentagedata);
      summaryRowPercentageClaimType.font = { bold: true };
      summaryRowPercentageClaimType.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'right' }
        this.fillBackGroundInTotalRowExcellCell(cell);
        this.setBorderExcelCell(cell);
      });
    }

    for (var i = 1; i <= worksheet.columnCount; i++) {
      worksheet.getColumn(i).width = 30;
    }

    worksheet.addRow([]);
  }

  generateExcelForListOfClaimsByClaimHandler(worksheet1: ExcelProper.Worksheet, reportTypeHeader, response: any, distinctHandlingOrganisation: any) {

    let header = ["MMI Claim Reference", "Claimant Name", "Insured Title", "Handling Organisation Claim Ref", "Policy Number", "Policyholder Name",
      "Policyholder ID", "Claim Type", "MMI Paid", "MMI Reserve", "MMI Incurred", "Reserve Last Reviewed", "Diary Date", "Claim Status", "Linked References",
      "Line Of Business", "Handling Organization", "Claim Handler", "Claimant DOB", "MMI Review Date", "MMI Key Contact", "Claimant NINO", "Location", "Perpetrator",
      "Open Date", "Settled Date", "Date Last Re-Opened", "DA Status", "Charging Rate", "Litigated Claim", "Addition Policy Number 1", "Addition Policy Number 2",
      "Addition Policy Number 3", "Addition Policy Number 4", "Addition Policy Number 5"];

    let headerRow = worksheet1.addRow(header);   
    //debugger
    distinctHandlingOrganisation.forEach((item) => {
      var handlingOrganisationData = response.claimResultItems.filter(c => c.handlingOrganisation == item);
      let claimsSortedByClaimHandlers = handlingOrganisationData.sort(function (a, b) { return (a.claimHandler > b.claimHandler) ? 1 : ((b.claimHandler > a.claimHandler) ? -1 : 0); });
      claimsSortedByClaimHandlers.forEach((item, index) => {
        let data: any[] = [];
        data.push(item.mmiClaimsReference);
        data.push(item.claimantName);
        data.push(item.insuredTitle);
        data.push(item.claimReference);
        data.push(item.policyNumber);
        data.push(item.creditorName);
        data.push(item.creditorId);
        data.push(item.claimType);
        data.push(item.totalPayments);
        data.push(item.totalReserve);
        data.push(item.totalIncurred);
        data.push(item.reserveLastReviewed != null ? (moment(new Date(item.reserveLastReviewed).toDateString()).format('DD/MM/YYYY')) : item.reserveLastReviewed);
        data.push(item.diaryDate != null ? (moment(new Date(item.diaryDate).toDateString()).format('DD/MM/YYYY')) : item.diaryDate);
        data.push(item.claimStatus);
        data.push(item.linkedReferences);
        data.push(item.lineofBusiness);
        data.push(item.handlingOrganisation);
        data.push(item.claimHandler);
        data.push(item.claimantDOB != null ? (moment(new Date(item.claimantDOB).toDateString()).format('DD/MM/YYYY')) : item.claimantDOB);
        data.push(item.mmiReviewDate != null ? (moment(new Date(item.mmiReviewDate).toDateString()).format('DD/MM/YYYY')) : item.mmiReviewDate);
        data.push(item.mmiKeyContact);
        data.push(item.claimantNINO);
        data.push(item.location);
        data.push(item.perpetrator);
        data.push(item.openDate != null ? (moment(new Date(item.openDate).toDateString()).format('DD/MM/YYYY')) : item.openDate);
        data.push(item.settledDate != null ? (moment(new Date(item.settledDate).toDateString()).format('DD/MM/YYYY')) : item.settledDate);
        data.push(item.dateLastReopened != null ? (moment(new Date(item.dateLastReopened).toDateString()).format('DD/MM/YYYY')) : item.dateLastReopened);
        data.push(item.daStatus);
        data.push(item.chargingRate);
        data.push(item.litigated);
        if (item.additionalPolicyNumberList != null) {
          if (item.additionalPolicyNumberList.length > 0) {
            data.push(item.additionalPolicyNumberList[0]);
          }
          if (item.additionalPolicyNumberList.length > 1) {
            data.push(item.additionalPolicyNumberList[1]);
          }
          if (item.additionalPolicyNumberList.length > 2) {
            data.push(item.additionalPolicyNumberList[2]);
          }
          if (item.additionalPolicyNumberList.length > 3) {
            data.push(item.additionalPolicyNumberList[3]);
          }
          if (item.additionalPolicyNumberList.length > 4) {
            data.push(item.additionalPolicyNumberList[4]);
          }
        }

        let row = worksheet1.addRow(data);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
        this.setCellFormatOfListOfClaims(row);

      });
    });
    headerRow.font = { bold: true };
    headerRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });
    for (var i = 1; i <= worksheet1.columnCount; i++) {
      worksheet1.getColumn(i).width = 30;
    }

    worksheet1.addRow([]);
  }

  generateExcelForListOfClaimsByKeyContacts(worksheet2: ExcelProper.Worksheet, reportTypeHeader, response: any, distinctHandlingOrganisation: any) {

    let header = ["MMI Claim Reference", "Claimant Name", "Insured Title", "Handling Organisation Claim Ref", "Policy Number", "Policyholder Name",
      "Policyholder ID", "Claim Type", "MMI Paid", "MMI Reserve", "MMI Incurred", "Reserve Last Reviewed", "Diary Date", "Claim Status", "Linked References",
      "Line Of Business", "Handling Organization", "Claim Handler", "Claimant DOB", "MMI Review Date", "MMI Key Contact", "Claimant NINO", "Location", "Perpetrator",
      "Open Date", "Settled Date", "Date Last Re-Opened", "DA Status", "Charging Rate", "Litigated Claim", "Addition Policy Number 1", "Addition Policy Number 2",
      "Addition Policy Number 3", "Addition Policy Number 4", "Addition Policy Number 5"];

    let headerRow = worksheet2.addRow(header);
    distinctHandlingOrganisation.forEach((item) => {
      var handlingOrganisationData = response.claimResultItems.filter(c => c.handlingOrganisation == item);
      let claimsSortedByMMIKeyContact = handlingOrganisationData.sort(function (a, b) { return (a.mmiKeyContact > b.mmiKeyContact) ? 1 : ((b.mmiKeyContact > a.mmiKeyContact) ? -1 : 0); });
      claimsSortedByMMIKeyContact.forEach((item, index) => {
        let data: any[] = [];
        data.push(item.mmiClaimsReference);
        data.push(item.claimantName);
        data.push(item.insuredTitle);
        data.push(item.claimReference);
        data.push(item.policyNumber);
        data.push(item.creditorName);
        data.push(item.creditorId);
        data.push(item.claimType);
        data.push(item.totalPayments);
        data.push(item.totalReserve);
        data.push(item.totalIncurred);
        data.push(item.reserveLastReviewed != null ? (moment(new Date(item.reserveLastReviewed).toDateString()).format('DD/MM/YYYY')) : item.reserveLastReviewed);
        data.push(item.diaryDate != null ? (moment(new Date(item.diaryDate).toDateString()).format('DD/MM/YYYY')) : item.diaryDate);
        data.push(item.claimStatus);
        data.push(item.linkedReferences);
        data.push(item.lineofBusiness);
        data.push(item.handlingOrganisation);
        data.push(item.claimHandler);
        data.push(item.claimantDOB != null ? (moment(new Date(item.claimantDOB).toDateString()).format('DD/MM/YYYY')) : item.claimantDOB);
        data.push(item.mmiReviewDate != null ? (moment(new Date(item.mmiReviewDate).toDateString()).format('DD/MM/YYYY')) : item.mmiReviewDate);
        data.push(item.mmiKeyContact);
        data.push(item.claimantNINO);
        data.push(item.location);
        data.push(item.perpetrator);
        data.push(item.openDate != null ? (moment(new Date(item.openDate).toDateString()).format('DD/MM/YYYY')) : item.openDate);
        data.push(item.settledDate != null ? (moment(new Date(item.settledDate).toDateString()).format('DD/MM/YYYY')) : item.settledDate);
        data.push(item.dateLastReopened != null ? (moment(new Date(item.dateLastReopened).toDateString()).format('DD/MM/YYYY')) : item.dateLastReopened);
        data.push(item.daStatus);
        data.push(item.chargingRate);
        data.push(item.litigated);
        if (item.additionalPolicyNumberList != null) {
          if (item.additionalPolicyNumberList.length > 0) {
            data.push(item.additionalPolicyNumberList[0]);
          }
          if (item.additionalPolicyNumberList.length > 1) {
            data.push(item.additionalPolicyNumberList[1]);
          }
          if (item.additionalPolicyNumberList.length > 2) {
            data.push(item.additionalPolicyNumberList[2]);
          }
          if (item.additionalPolicyNumberList.length > 3) {
            data.push(item.additionalPolicyNumberList[3]);
          }
          if (item.additionalPolicyNumberList.length > 4) {
            data.push(item.additionalPolicyNumberList[4]);
          }
        }

        let row = worksheet2.addRow(data);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
        this.setCellFormatOfListOfClaims(row);
      });

    });

    headerRow.font = { bold: true };
    headerRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });
    for (var i = 1; i <= worksheet2.columnCount; i++) {
      worksheet2.getColumn(i).width = 30;
    }

    worksheet2.addRow([]);
  }

  setCellFormatOfListOfClaims(row: ExcelProper.Row) {
    row.findCell(9).numFmt = '£###,###,##0.00;'
    row.findCell(10).numFmt = '£###,###,##0.00;'
    row.findCell(11).numFmt = '£###,###,##0.00;'
  }

  setBorderExcelCell(cell: any) {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
  }

  fillBackGroundInExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00C4C4C4' },
      bgColor: { argb: 'FF0000FF' }
    }
  }

  fillBackGroundInTotalRowExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '009FC5E8' },
      bgColor: { argb: '009FC5E8' }
    }
  }

  displayKeyContactHandler(worksheet: ExcelProper.Worksheet, reportTypeHeader, response: any) {
    let claimHandlerHeader = ["Handling Organisation", "KeyContact"];
    let exposureHeaderRow;
    //let toprow = worksheet.addRow([reportTypeHeader]);
    //toprow.findCell(1).font = { bold: true };
    //response.forEach((item, ) => {
    //  claimHandlerHeader.push(item.claimsType[index].claimType);
    //})
    let claimtypesarray = response.keyContactsClaimTypeList[0].claimType.split(',');
    if (claimtypesarray.length > 0) {
      claimtypesarray.forEach(item => {
        claimHandlerHeader.push(item);
      });
    }
    claimHandlerHeader.push("Total");
    exposureHeaderRow = worksheet.addRow(claimHandlerHeader);
    response.claimsCaseLoadSummaryReportList.forEach((item, index) => {
      let data: any[] = [];
      let keyContactData: any[] = [];
      data.push(item.handlingOrganisationName);
      data.push(item.claimHandlerName);

      keyContactData.push(item.handlingOrganisationName);
      keyContactData.push(item.claimHandlerName);

      item.claimsTypeList.forEach(i => {
        data.push(i["count"]);
      });
      data.push(item.total);
      let summaryRow1 = worksheet.addRow(data);
    });
    let percentagedata: any[] = ['% of Caseload', ""];
    response.totalPercentage.forEach(i => {
      i = i.toFixed(2);
      percentagedata.push(i);
    });
    let summaryRowPercentage = worksheet.addRow(percentagedata);
    exposureHeaderRow.font = { bold: true };
    exposureHeaderRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });
    summaryRowPercentage.font = { bold: true };
    summaryRowPercentage.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'right' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });

  }
}



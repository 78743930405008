import { Injectable } from '@angular/core';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service'
import { Observable } from 'rxjs';
import { IHandlingOrganisation } from './handlingorganisation.model'


@Injectable({
  providedIn: 'root'
})
export class AddHandlingOrganisationService {

  formData: IHandlingOrganisation;
  constructor(private handlingOrganisationService:HandlingOrganisationService) {
  }

  //addOrUpdateHandlingOrganisation(): any {

  //  var body = {
  //    ...this.formData
  //  };
  //  if (this.formData.id > 0) {
  //    return this.handlingOrganisationService.updateHandlingOrganisation(body);
  //  }
  //  else {
  //    return this.handlingOrganisationService.createHandlingOrganisation(body);
  //  }
  //}

  getHandlingOrganisationById(id: number): any {
    return this.handlingOrganisationService.getHandlingOrganisationById(id);
  }

  createHandlingOrganisation(): any {
    var body = {
      ...this.formData
    };
    return this.handlingOrganisationService.createHandlingOrganisation(body);
  }

  updateHandlingOrganisation(): any {
    var body = {
      ...this.formData
    };
    return this.handlingOrganisationService.updateHandlingOrganisation(body);
  }

}




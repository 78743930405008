import { Injectable } from '@angular/core';
import { IReinsurer } from './reinsurer.model';
import { ManageReinsurerService } from '../../../shared/services/manage-reinsurer.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReinsurerService {

  formData: IReinsurer;

  constructor(private manageReinsurerService: ManageReinsurerService) { }

  getReinsurerById(id: number): any {
    return this.manageReinsurerService.getReinsurerById(id);
  }

  searchReinsurerName(term: string) {
    if (!term || term.length < 3) { return of([]); }
    return this.manageReinsurerService.searchReinsurerName(term);
  }

  getReinsurerByName(reinsurerName: string): any {
    return this.manageReinsurerService.getReinsurerByName(reinsurerName);
  }

  saveOrUpdateReinsurer(): any {
    var body = {
      ...this.formData
    };
    if (this.formData.id > 0)
      return this.manageReinsurerService.updateReinsurer(body);
    else
      return this.manageReinsurerService.createReinsurer(body);
  }
}

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { IPaymentBordereaux } from '../models/payment-bordereaux.model';
import { PaymentBordereaux } from '../models/search-payment-bordereaux.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentBordereauxService {

  private paymentBordereauxUrl: string = "/api/PaymentBordereaux/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.paymentBordereauxUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.paymentBordereauxUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.paymentBordereauxUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.paymentBordereauxUrl);
  }

  getPaymentBordereauxDetails(): any {
    let url = this.paymentBordereauxUrl;

    return this.dataService.get(url);
  }

  updatePaymentBordereauxDetails(data): any {

    let url = this.paymentBordereauxUrl + "updatepaymentBordereaux";
    console.log(data);
    return this.dataService.putWithId(url, data);
  }

  getPaymentBordereaux(filter: any) {
    return this.dataService.post(this.paymentBordereauxUrl + 'search', filter).pipe<IPaymentBordereaux>(tap((response: any) => {
      return response;
    }));
  }

  getChequesBordereaux(filter: any) {
    return this.dataService.post(this.paymentBordereauxUrl + 'searchCheque', filter).pipe<IPaymentBordereaux>(tap((response: any) => {
      return response;
    }));
  }

  approvePayments(chequeNo: number, paymentIds: any, loggedInUserId: number) {
    var url = this.paymentBordereauxUrl + "ApprovePaymentBordereaux"
    var data = {
      chequeNumber: chequeNo,
      isApproved: true,
      approvedBy: loggedInUserId,
      paymentBordereauxIds: paymentIds.toString()
    };

    return this.dataService.post(url, data);
  }

  rejectPayments(paymentIds: any) {
    var url = this.paymentBordereauxUrl + "RejectPaymentBordereaux"
    var data = {
      isApproved: false,
      paymentBordereauxIds: paymentIds.toString()
    };
    return this.dataService.post(url, data);
  }

  printPayments(paymentIds: any) {
    var url = this.paymentBordereauxUrl + "printPaymentBorderaux"
    var data = {
      isApproved: false,
      paymentBordereauxIds: paymentIds.toString()
    };

    return this.dataService.downloadFile(url, data);
  }

  searchChequePaymentsBordereaux(searchFilter: PaymentBordereaux) {

    let url = this.paymentBordereauxUrl + 'searchChequePaymentsBordereaux';
    return this.dataService.get(url, searchFilter).pipe<IPaymentBordereaux>(tap((response: any) => {
      return response;
    }));
  }

  approveChequePaymentsBordereaux(paymentIds: any, loggedInUserId) {
    var url = this.paymentBordereauxUrl + "approveChequePaymentsBordereaux"
    var data = {
      isApproved: true,
      paymentBordereauxIds: paymentIds.toString(),
      approvedBy: loggedInUserId

    };
    return this.dataService.downloadFile(url, data);
  }

  approveCancelChequePaymentsBordereaux(paymentIds: any, loggedInUserId) {
    var url = this.paymentBordereauxUrl + "approveCancelChequePaymentsBordereaux"
    var data = {
      isApproved: true,
      paymentBordereauxIds: paymentIds.toString(),
      approvedBy: loggedInUserId
    };
    return this.dataService.post(url, data);
  }

  rejectChequePaymentsBordereaux(paymentIds: any) {
    var url = this.paymentBordereauxUrl + "rejectChequePaymentsBordereaux"
    var data = {
      isApproved: true,
      paymentBordereauxIds: paymentIds.toString()
    };
    return this.dataService.post(url, data);
  }

  approveBACSPaymentsBordereaux(paymentIds: any, loggedInUserId) {
    var url = this.paymentBordereauxUrl + "generateFiles"
    var data = {
      isApproved: true,
      paymentBordereauxIds: paymentIds.toString(),
      approvedBy: loggedInUserId
    };
    return this.dataService.downloadFile(url, data);
  }

  rejectBACSPaymentsBordereaux(paymentIds: any) {
    var url = this.paymentBordereauxUrl + "rejectBACSPaymentsBordereaux"
    var data = {
      isApproved: true,
      paymentBordereauxIds: paymentIds.toString()
    };
    return this.dataService.post(url, data);
  }

  getPayeeName(search: string) {
    let url = this.paymentBordereauxUrl + "getPayeeName";
    return this.dataService.get(url, { search: search });
  }

  getFinanceEFileUrl() {
    let url = this.paymentBordereauxUrl + "getFinanceEFileUrl";
    return this.dataService.get(url);
  }

  updateUnderQueryPaymentBordereaux(modelQueries: any) {
    var url = this.paymentBordereauxUrl + "updateUnderQueryPaymentBordereaux"
    return this.dataService.post(url, modelQueries);
  }
}



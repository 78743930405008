import { Injectable } from '@angular/core';
import { LookupDatasetupService } from '../../../shared/services/lookup-datasetup.service';
import { Lookup } from './data-setup.model';

@Injectable({
  providedIn: 'root'
})
export class DataSetupService { 

  formData: Lookup[] = [];

  constructor(private lookupDatasetupService: LookupDatasetupService) {
  }

  getAllLookupTypes(): any {
    return this.lookupDatasetupService.getAllLookupTypes();    
  }

  getLookupsByTypeId(id: number) {
    return this.lookupDatasetupService.getLookupsByTypeId(id);
  }

  postLookup(lookup: Lookup) {
    return this.lookupDatasetupService.postLookup(lookup);
  }

  putLookup(lookup: Lookup) {
    return this.lookupDatasetupService.putLookup(lookup);
  }

  deleteLookup(lookup: Lookup) {
    return this.lookupDatasetupService.deleteLookupItem(lookup);
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AggregatedClaimsReportService } from './aggregated-claims-report.service';
import { enumYesNo } from '../../../shared/models/common.enum';
import { SecurityService } from '../../../shared/services/security.service';
import { BaseComponentComponent } from '../../base-component/base-component.component';

@Component({
  selector: 'app-aggregated-claims-report',
  templateUrl: './aggregated-claims-report.component.html',
  styleUrls: ['./aggregated-claims-report.component.css']
})
export class AggregatedClaimsReportComponent extends BaseComponentComponent implements OnInit {

  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  claimStatusTypes: any[] = [
    { "id": 1, "text": "Open" },
    { "id": 2, "text": "Settled" },
  ]

  locationsLookup: any[];
  perpetratorsLookup: any[];
  isValid: boolean = true;
  isSelectedClaimStatusAll: boolean = true;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  constructor(
    private lookupService: LookupService,
    private aggregatedClaimsReportService: AggregatedClaimsReportService,
    private notificationService: NotificationService,
    securityService: SecurityService) {
    super(securityService, 1405);
  }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.initializeForm();
    this.perpetratorsLookup = this.lookupService.getLookupsByTypeId(29).filter(x => { return x.booleanValue == true });
    this.perpetratorsLookup.unshift({ "id": 0, "text": "All" });
    this.locationsLookup = this.lookupService.getLookupsByTypeId(22).filter(x => { return x.booleanValue == true });
    this.locationsLookup.unshift({ "id": 0, "text": "All" });
  }

  initializeForm() {
    this.aggregatedClaimsReportService.formDataInput = {
      locations: [{ "id": 0, "text": "All" }],
      locationsValues: [],
      perpetrators: [{ "id": 0, "text": "All" }],
      perpetratorsValues: [],
      claimStatusTypeId: null,
      dateFrom: null,
      dateTo: null
    }
  }

  generateAggregatedClaimsReport() {
    this.setValuesInRequestModel();
    if (this.validateForm()) {
      this.aggregatedClaimsReportService.getAggregatedClaimsReport();
    }
  }

  setValuesInRequestModel() {
    this.aggregatedClaimsReportService.formDataInput.locationsValues =
      this.aggregatedClaimsReportService.formDataInput.locations.map(x => x.id);

    this.getAndSetLocationValueSearch();

    this.aggregatedClaimsReportService.formDataInput.perpetratorsValues =
      this.aggregatedClaimsReportService.formDataInput.perpetrators.map(x => x.id);

    this.getAndSetPerpetratorValueSearch();
  }

  validateForm(): boolean {
    this.isValid = true;
    if ((this.aggregatedClaimsReportService.formDataInput.locations.length == 0 &&
      this.aggregatedClaimsReportService.formDataInput.perpetrators.length == 0) ||
      (this.aggregatedClaimsReportService.formDataInput.claimStatusTypeId != null &&
        this.aggregatedClaimsReportService.formDataInput.dateFrom != null &&
        this.aggregatedClaimsReportService.formDataInput.dateTo != null &&
        this.aggregatedClaimsReportService.formDataInput.locations.length == 0 &&
        this.aggregatedClaimsReportService.formDataInput.perpetrators.length == 0)) {
      this.isValid = false;
      this.notificationService.printWarningMessage("Please select at least one location or perpetrator.");
      return false;
    }
    if ((this.aggregatedClaimsReportService.formDataInput.claimStatusTypeId != null ||
      this.aggregatedClaimsReportService.formDataInput.dateFrom != null ||
      this.aggregatedClaimsReportService.formDataInput.dateTo != null) &&
      (this.aggregatedClaimsReportService.formDataInput.claimStatusTypeId == null ||
        this.aggregatedClaimsReportService.formDataInput.dateFrom == null ||
        this.aggregatedClaimsReportService.formDataInput.dateTo == null)) {
      this.isValid = false;
      this.notificationService.printWarningMessage("All madatory fields are required.");
      return false;
    }
    return this.isValid;
  }

  getAndSetLocationValueSearch() {
    var location = this.lookupService.getLookupsByTypeId(22).filter(x => { return x.booleanValue == true });
    var selectedLocationValues = this.aggregatedClaimsReportService.formDataInput.locationsValues;
    if (selectedLocationValues.indexOf(0) != -1) {
      this.aggregatedClaimsReportService.formDataInput.locationsValues = [];
      let filteredLocation =
        location.filter(result => { return result.id });
      this.aggregatedClaimsReportService.formDataInput.locationsValues = filteredLocation.map(x => x.id);
    }
  }

  getAndSetPerpetratorValueSearch() {
    var perpetrator = this.lookupService.getLookupsByTypeId(29).filter(x => { return x.booleanValue == true });
    var selectedPerpetratorValues = this.aggregatedClaimsReportService.formDataInput.perpetratorsValues;
    if (selectedPerpetratorValues.indexOf(0) != -1) {
      this.aggregatedClaimsReportService.formDataInput.perpetratorsValues = [];
      let filteredPerpetrator =
        perpetrator.filter(result => { return result.id });
      this.aggregatedClaimsReportService.formDataInput.perpetratorsValues = filteredPerpetrator.map(x => x.id);
    }
  }

  onClaimStatusChange() {
    if (this.aggregatedClaimsReportService.formDataInput.claimStatusTypeId == null) {
      this.aggregatedClaimsReportService.formDataInput.dateFrom = null;
      this.aggregatedClaimsReportService.formDataInput.dateTo = null;
      this.isSelectedClaimStatusAll = true;
    }
    else {
      this.isSelectedClaimStatusAll = false;
    }
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { WeeklyWagesService } from './weekly-wages.service';
import { DataTableDirective } from 'angular-datatables';
import { AverageWeeklyWage } from './weekly-wages.model';
import { Subject } from 'rxjs';
//import moment = require('moment');
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../../../shared/services/notification.service';

@Component({
  selector: 'app-weekly-wages',
  templateUrl: './weekly-wages.component.html',
  styleUrls: ['./weekly-wages.component.css']
})
export class WeeklyWagesComponent implements OnInit {
  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  @ViewChild('weeklyWagesTable') weeklyWagesTable: ElementRef;

  averageWeeklyWageResult: AverageWeeklyWage[] = [];

  public dtTrigger: Subject<any>;
  isGridLoaded: boolean = false;
  public dtOptions: any = {};

  displayedColumns: any[] = [
    { title: 'ID', data: 'id', visible: false },
    { title: 'Date', data: 'aweDate', visible: true, type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') }  },
    { title: 'Average Weekly Earning', data: 'awe', visible: true, render: $.fn.dataTable.render.number(',', '.', 2, '£') },
    { title: 'Action', data: null, visible: true, orderable: false }
  ];

  errorReceived: boolean;
  public averageWeeklyWageVM: AverageWeeklyWage = new AverageWeeklyWage();
  isValid: boolean = true;
  isEditMode: boolean = false;
  isShown: boolean = false;
  showAddButton: boolean = false;

  constructor(private notificationService: NotificationService,
              private weeklyWagesService: WeeklyWagesService)
  {
    this.dtTrigger = new Subject();
  }

  ngOnInit(): void {
    this.initialiseDataTable();
    this.refreshGrid();
    this.showAddButton = true;
  }

  initialiseDataTable(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      "order": [[1, "asc"]],
      columns: this.displayedColumns,
      'columnDefs': [
        { width: "10%", targets: 0 },
        { width: "50%", targets: 1 },
        { width: "42%", targets: 2 },
        {
          render: function (data, type, row) {
            return '<button class="btn remove-button"><i class="fa fa-trash-alt"></i></button><button class="btn edit-button"><i class="fa fa-edit"></i></button>';
          },
          width: "8%", targets: 3
        },
      ],
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [{}],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler        
        $('.edit-button', row).unbind('click');
        $('.edit-button', row).bind('click', () => {
          self.filterDataBasedOnEditButton(data);
        });
        $('.remove-button', row).unbind('click');
        $('.remove-button', row).bind('click', () => {
          self.removeButtonClick(data);
        });

        return row;
      },
      ajax: (dataTablesParameters: any, callback) => {
        var self = this;        
        this.weeklyWagesService.getAverageWeeklyWages().subscribe((response: any) => {
          this.averageWeeklyWageResult = response;
          callback({
            data: response
          });
        });
      }
    };
  }

  filterDataBasedOnEditButton(aweRowData) {
    let data = this.averageWeeklyWageResult.find(
      item => {
        return item.id == aweRowData.id
      }
    )
    this.averageWeeklyWageVM = {
      id: data.id,
      aweDate: data.aweDate,
      awe: data.awe,      
      isDelete: data.isDelete,
    }
    //alert(JSON.stringify(this.averageWeeklyWageVM));
    this.isEditMode = true;
    this.hideAddButton(false);
  }

  hideAddButton(showHide) {
    //this.LookupItemReset();
    this.showAddButton = showHide;
    this.isShown = true;
    //this.isEditMode = false;
  }

  hideform() {    
    this.form.resetForm();
    this.averageWeeklyWageItemReset();    
    this.showAddButton = true;
    this.isShown = false;
    this.isEditMode = false;
  }

  averageWeeklyWageItemReset() {    
    this.averageWeeklyWageVM = new AverageWeeklyWage();
    this.averageWeeklyWageVM.isDelete = false;
  }

  onSubmit(form: NgForm) {
    //debugger
    if (form.valid) {
      console.log('form', form);
      if (this.averageWeeklyWageVM.id > 0) {
        this.averageWeeklyWageVM.awe = Number(this.averageWeeklyWageVM.awe);
        this.putAverageWeeklyWageItem(this.averageWeeklyWageVM);
      }
      else {
        this.postAverageWeeklyWageItem();
      }
    }
  }

  postAverageWeeklyWageItem() {
    console.log("post Average Weekly Wage method called");
    this.averageWeeklyWageVM.awe = Number(this.averageWeeklyWageVM.awe);
    this.weeklyWagesService.postAverageWeeklyWage(this.averageWeeklyWageVM).subscribe(data => {
      this.notificationService.printSuccessMessage('Average weekly wage saved successfully');
      this.averageWeeklyWageItemReset();
      this.hideform();      
      this.refreshGrid();
    },
      error => {
        this.notificationService.printErrorMessage("Error occured  in saving Average weekly wage");
      }
    );
  }


  putAverageWeeklyWageItem(data) {
    this.weeklyWagesService.updateAverageWeeklyWage(data).subscribe(data => {
      this.notificationService.printSuccessMessage('Average weekly wage updated successfully');
      this.averageWeeklyWageItemReset();
      this.hideform();
      this.refreshGrid();
    },
      error => {
        this.notificationService.printErrorMessage('Error occured in updating Average weekly wage');
      }
    );
  }



  removeButtonClick(data) {
    if (confirm("Are you sure you want to delete this item?")) {
      data.isDelete = true;
      this.deleteAverageWeeklyWage(data);
      this.isEditMode = false;
      this.hideform();
      console.log("Remove button call", data);
    }
  }

  deleteAverageWeeklyWage(data) {
    this.weeklyWagesService.deleteAverageWeeklyWage(data).subscribe(data => {
      this.notificationService.printSuccessMessage('Average weekly wage deleted successfully');
      this.refreshGrid();
    },
      error => {
        this.notificationService.printErrorMessage('Error occured  in deleting Average weekly wage');
      }
    );
  }

  refreshGrid() {
    if (this.weeklyWagesTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { ReinsuranceCreditControlRequestModel } from '../../../models/reinsurance-credit-control-request.model';
import { ReportsService } from '../../../shared/services/reports.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { ManageReinsurerService } from '../../../shared/services/manage-reinsurer.service';

@Injectable({
  providedIn: 'root'
})
export class ReinsuranceCreditControlService {

  formDataInput: ReinsuranceCreditControlRequestModel;

  constructor(private reportsService: ReportsService,
    private notificationService: NotificationService,
    private manageReinsurerService: ManageReinsurerService, ) { }

  getAllReinsurers(): any {
    return this.manageReinsurerService.getAllReinsurers();
  }

  getReinsuranceCreditControlReport(): any {
    var body = {
      ...this.formDataInput

    };

    this.reportsService.getReinsuranceCreditControlReport(this.formDataInput).subscribe((response: any) => {
      if ((response.invoiceDetailsModel != null && response.invoiceDetailsModel.length > 0) ||
        (response.receiptsDetailsModel != null && response.receiptsDetailsModel.length > 0) ||
        (response.variationDetailsModel != null && response.variationDetailsModel.length > 0)) {
        //Create workbook and worksheet
        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        if (response.invoiceDetailsModel != null && response.invoiceDetailsModel.length > 0)
          this.invoiceDetailsReprot(workbook, response.invoiceDetailsModel);
        if (response.receiptsDetailsModel != null && response.receiptsDetailsModel.length > 0)
          this.receiptDetailsReport(workbook, response.receiptsDetailsModel);
        if (response.variationDetailsModel != null && response.variationDetailsModel.length > 0)
          this.variationDetailsReport(workbook, response.variationDetailsModel);

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, 'Reinsurance-Credit-Control-Report_' + Math.random() + '.xlsx');
        })
      }
      else {
        this.notificationService.printWarningMessage("No data is available for the Report");
      }

    });

  }

  invoiceDetailsReprot(workbook, invoiceDetails) {

    const invoiceDetailsReportHeader = [
      "MMI Claim Reference",
      "Claim Type",
      "Policyholder Name",
      "Claimant Surname",
      "Line Of Business",
      "Reinsurer Name",
      "Reinsurer ID",
      "Parent Reinsurer Name",
      "Parent Reinsurer ID",
      "Reinsurer's Reference",
      "Calculation Override",
      "AmountSubmitted",
      "Invoice Type",
      "Initial Invoice Number",
      "Settlement Presentation Date Submitted",
      "Settlement Presentation Date Created",
      "Agreed Date",
      "Amount Agreed",
      "Invoice/Credit",
      "Amount Adjusted",
      "Adjusted Invoice Number",
      "Adjusted Invoice Date Created",
      "Record Creation Date"
    ];

    let invoiceDetailsReportData: any[] = [];

    let worksheet = workbook.addWorksheet('Invoicing Details');

    invoiceDetails.forEach(item => {
      invoiceDetailsReportData.push(
        [
          item["mmiClaimReference"],
          item["claimType"],
          item["policyholderName"],
          item["claimantSurname"],
          item["lineOfBusiness"],
          item["reinsurerName"],
          item["reinsurerId"],
          item["parentReinsurerName"] == null ? "" : item["parentReinsurerName"],
          item["parentReinsurerId"] == null ? "" : item["parentReinsurerId"],
          item["reinsurersReference"],
          item["calculationOverride"],
          item["amountSubmitted"],
          item["invoiceType"],
          item["initialInvoiceNumber"],
          item["settlementPresentationDateSubmitted"] == null ? "" : moment(item["settlementPresentationDateSubmitted"]).format('DD/MM/YYYY'),
          item["settlementPresentationDateCreated"] == null ? "" : moment(item["settlementPresentationDateCreated"]).format('DD/MM/YYYY'),
          item["agreedDate"] == null ? "" : moment(item["agreedDate"]).format('DD/MM/YYYY'),
          item["amountAgreed"],
          item["invoiceOrCredit"],
          item["amountAdjusted"],
          item["adjustedInvoiceNumber"],
          item["adjustedInvoiceDateCreated"] == null ? "" : moment(item["adjustedInvoiceDateCreated"]).format('DD/MM/YYYY'),
          item["recordCreationDate"] == null ? "" : moment(item["recordCreationDate"]).format('DD/MM/YYYY')
        ]);
    });

    let invoiceDetailsReportHeaderRow = worksheet.addRow(invoiceDetailsReportHeader);
    // Cell Style : Fill and Border
    invoiceDetailsReportHeaderRow.eachCell((cell, number) => {
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);

    })
    invoiceDetailsReportHeaderRow.font = { bold: true };
    invoiceDetailsReportData.forEach(d => {
      let row = worksheet.addRow(d);
      row.eachCell((cell, number) => {
        this.setBorderExcelCell(cell);
      })
      this.setCellFormatOfInvoiceDetailsReport(row);
    });

    for (var i = 1; i <= worksheet.columnCount; i++) {
      worksheet.getColumn(i).width = 25;
    }
  }

  receiptDetailsReport(workbook, receiptDetails) {

    const receiptsDetailsReportHeader = [
      "MMI Claim Reference",
      "Claim Type",
      "Policyholder Name",
      "Claimant Surname",
      "Line Of Business",
      "Reinsurer Name",
      "Reinsurer ID",
      "Parent Reinsurer Name",
      "Parent Reinsurer ID",
      "Reinsurer's Reference",
      "Calculation Override",
      "Amount Submitted",
      "Settlement Presentation Date Submitted",
      "Settlement Presentation Date Created",
      "Amount Received",
      "Received Date",
      "Record Creation Date",
    ];

    let receiptsDetailsReportData: any[] = [];

    let worksheet = workbook.addWorksheet('Receipts Details');

    receiptDetails.forEach(item => {
      receiptsDetailsReportData.push(
        [
          item["mmiClaimReference"],
          item["claimType"],
          item["policyholderName"],
          item["claimantSurname"],
          item["lineOfBusiness"],
          item["reinsurerName"],
          item["reinsurerId"],
          item["parentReinsurerName"] == null ? "" : item["parentReinsurerName"],
          item["parentReinsurerId"] == null ? "" : item["parentReinsurerId"],
          item["reinsurersReference"],
          item["calculationOverride"],
          item["amountSubmitted"],
          item["settlementPresentationDateSubmitted"] == null ? "" : moment(item["settlementPresentationDateSubmitted"]).format('DD/MM/YYYY'),
          item["settlementPresentationDateCreated"] == null ? "" : moment(item["settlementPresentationDateCreated"]).format('DD/MM/YYYY'),
          item["amountReceived"],
          item["receivedDate"] == null ? "" : moment(item["receivedDate"]).format('DD/MM/YYYY'),
          item["recordCreationDate"] == null ? "" : moment(item["recordCreationDate"]).format('DD/MM/YYYY')
        ]);
    });

    let receiptsDetailsReportHeaderRow = worksheet.addRow(receiptsDetailsReportHeader);
    // Cell Style : Fill and Border
    receiptsDetailsReportHeaderRow.eachCell((cell, number) => {
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);

    })
    receiptsDetailsReportHeaderRow.font = { bold: true };
    receiptsDetailsReportData.forEach(d => {
      let row = worksheet.addRow(d);
      row.eachCell((cell, number) => {
        this.setBorderExcelCell(cell);
      })
      this.setCellFormatOfReceiptsDetailsReport(row);
    });

    for (var i = 1; i <= worksheet.columnCount; i++) {
      worksheet.getColumn(i).width = 25;
    }
  }

  variationDetailsReport(workbook, variationDetails) {
    const variationDetailsReportHeader = [
      "MMI Claim Reference",
      "Claim Type",
      "Policyholder Name",
      "Claimant Surname",
      "Line Of Business",
      "Reinsurer Name",
      "Reinsurer ID",
      "Parent Reinsurer Name",
      "Parent Reinsurer ID",
      "Reinsurer's Reference",
      "Calculation Override",
      "Total Amount Submitted",
      "Settlement Presentation Date Submitted",
      "Settlement Presentation Date Created",
      "Total Amount Agreed",
      "Total Adjusted Amount",
      "Total Amount Received",
      "Latest Received Date",
      "Debtor Balance",
      "Variation",
      "Lifecycle",
      "Under Query",
      "Notification Status",
      "Record Creation Date"
    ];

    let variationDetailsReportData: any[] = [];

    let worksheet = workbook.addWorksheet('Variation Details');

    variationDetails.forEach(item => {
      variationDetailsReportData.push(
        [
          item["mmiClaimReference"],
          item["claimType"],
          item["policyholderName"],
          item["claimantSurname"],
          item["lineOfBusiness"],
          item["reinsurerName"],
          item["reinsurerId"],
          item["parentReinsurerName"] == null ? "" : item["parentReinsurerName"],
          item["parentReinsurerId"] == null ? "" : item["parentReinsurerId"],
          item["reinsurersReference"],
          item["calculationOverride"],
          item["totalAmountSubmitted"],
          item["settlementPresentationDateSubmitted"] == null ? "" : moment(item["settlementPresentationDateSubmitted"]).format('DD/MM/YYYY'),
          item["settlementPresentationDateCreated"] == null ? "" : moment(item["settlementPresentationDateCreated"]).format('DD/MM/YYYY'),
          item["totalAmountAgreed"],
          item["totalAdjustedAmount"],
          item["totalAmountReceived"],
          item["latestReceivedDate"] == null ? "" : moment(item["latestReceivedDate"]).format('DD/MM/YYYY'),
          item["debtorBalance"],
          item["variation"],
          item["lifecycle"] == null ? "" : item["lifecycle"],
          item["underQuery"],
          item["notificationStatus"],
          item["recordCreationDate"] == null ? "" : moment(item["recordCreationDate"]).format('DD/MM/YYYY')
        ]);
    });

    let variationDetailsReportHeaderRow = worksheet.addRow(variationDetailsReportHeader);
    // Cell Style : Fill and Border
    variationDetailsReportHeaderRow.eachCell((cell, number) => {
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);

    })
    variationDetailsReportHeaderRow.font = { bold: true };
    variationDetailsReportData.forEach(d => {
      let row = worksheet.addRow(d);
      row.eachCell((cell, number) => {
        this.setBorderExcelCell(cell);
      })
      this.setCellFormatOfVariationDetailsReport(row);
    });

    for (var i = 1; i <= worksheet.columnCount; i++) {
      worksheet.getColumn(i).width = 25;
    }
  }

  setCellFormatOfInvoiceDetailsReport(row: ExcelProper.Row) {
    row.findCell(12).value >= 0 ? row.findCell(12).numFmt = '£###,###,##0.00;' : row.findCell(12).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(18).value >= 0 ? row.findCell(18).numFmt = '£###,###,##0.00;' : row.findCell(18).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(20).value >= 0 ? row.findCell(20).numFmt = '£###,###,##0.00;' : row.findCell(20).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(15).numFmt = 'DD/MM/YYYY';
    row.findCell(16).numFmt = 'DD/MM/YYYY';
    row.findCell(17).numFmt = 'DD/MM/YYYY';
    row.findCell(22).numFmt = 'DD/MM/YYYY';
    row.findCell(23).numFmt = 'DD/MM/YYYY';
  }

  setCellFormatOfReceiptsDetailsReport(row: ExcelProper.Row) {
    row.findCell(12).value >= 0 ? row.findCell(12).numFmt = '£###,###,##0.00;' : row.findCell(12).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(15).value >= 0 ? row.findCell(15).numFmt = '£###,###,##0.00;' : row.findCell(15).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(13).numFmt = 'DD/MM/YYYY';
    row.findCell(14).numFmt = 'DD/MM/YYYY';
    row.findCell(16).numFmt = 'DD/MM/YYYY';
    row.findCell(17).numFmt = 'DD/MM/YYYY';
  }

  setCellFormatOfVariationDetailsReport(row: ExcelProper.Row) {
    row.findCell(12).value >= 0 ? row.findCell(12).numFmt = '£###,###,##0.00;' : row.findCell(12).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(15).value >= 0 ? row.findCell(15).numFmt = '£###,###,##0.00;' : row.findCell(15).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(16).value >= 0 ? row.findCell(16).numFmt = '£###,###,##0.00;' : row.findCell(16).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(17).value >= 0 ? row.findCell(17).numFmt = '£###,###,##0.00;' : row.findCell(17).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(19).value >= 0 ? row.findCell(19).numFmt = '£###,###,##0.00;' : row.findCell(19).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(20).value >= 0 ? row.findCell(20).numFmt = '£###,###,##0.00;' : row.findCell(20).numFmt = '£###,###,##0.00;(£###,###,##0.00);'
    row.findCell(13).numFmt = 'DD/MM/YYYY';
    row.findCell(14).numFmt = 'DD/MM/YYYY';
    row.findCell(18).numFmt = 'DD/MM/YYYY';
    row.findCell(24).numFmt = 'DD/MM/YYYY';
  }

  setBorderExcelCell(cell: any) {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    cell.alignment = { vertical: 'middle' };
  }

  fillBackGroundInExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00C4C4C4' },
      bgColor: { argb: 'FF0000FF' }
    }
  }
}

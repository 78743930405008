import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AllocatedClaimService } from './allocated-claim.service';
import { SearchClaimService } from '../../home/search-claim/search-claim.service';



@Component({
  selector: 'app-allocated-claim',
  templateUrl: './allocated-claim.component.html',
  styleUrls: ['./allocated-claim.component.css']
})
export class AllocatedClaimComponent implements OnInit {

  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  claimTypeLookup: any[];
  handlingOrganisationData: any[];

  dateTypes: any[] = [{ "id": 1, "text": "Allocated Date" }, { "id": 2, "text": "Accepted Date" }, { "id": 3, "text": "Rejected Date" }, { "id": 4, "text": "Claim Handler Assigned Date" }];

  isValid: boolean = true;
  public searching: boolean;

  constructor(private service: SearchClaimService,
    private lookupService: LookupService,
    private allocatedClaimService: AllocatedClaimService,
    private notificationService: NotificationService) {

  }

  ngOnInit(): void {
    this.InitializeData();
  }

  InitializeData() {
    this.initializeForm();
    this.claimTypeLookup = this.lookupService.getLookupsByTypeId(1);
    this.claimTypeLookup.unshift({ "id": 0, "text": "All" });
    this.allocatedClaimService.getHandlingOrganization().subscribe(response => {
      if (response != null) {
        this.handlingOrganisationData = response.map(item => {
          let container: any = {};
          container.id = item.id;
          container.text = item.handlingOrganisationName;
          return container;
        })
        this.handlingOrganisationData.unshift({ "id": 0, "text": "All" });
      }
    });

  }


  initializeForm() {

    this.allocatedClaimService.formDataInput = {
      handlingOrganisations: [{ "id": 0, "text": "All" }],
      handlingOrganisationValues: [],
      insuredTitle: '',
      dateTypeId: null,
      dateFrom: null,
      dateTo: null,
      claimTypes: [{ "id": 0, "text": "All" }],
      claimTypeValues: []
    }
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  dropdownSettingsHO: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 2,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  generateAllocatedClaimsReport() {
    this.allocatedClaimService.formDataInput.handlingOrganisationValues = _.map(this.allocatedClaimService.formDataInput.handlingOrganisations, function (i: any) { return i.id });
    this.allocatedClaimService.formDataInput.claimTypeValues = _.map(this.allocatedClaimService.formDataInput.claimTypes, function (i: any) { return i.id });
    if (this.validateForm()) {
      this.allocatedClaimService.generateAllocatedClaimsReport();
    } else {
      this.notificationService.printWarningMessage("Please select all mandatory fields");
    }
  }

  validateForm(): boolean {
    this.isValid = true;
    if (this.allocatedClaimService.formDataInput.handlingOrganisationValues == null ||
      this.allocatedClaimService.formDataInput.handlingOrganisationValues.length == 0 ||
      this.allocatedClaimService.formDataInput.claimTypeValues == null ||
      this.allocatedClaimService.formDataInput.claimTypeValues.length == 0
    ) {
      this.isValid = false;
      return false;
    }
    if ((this.allocatedClaimService.formDataInput.dateTypeId != null ||
      this.allocatedClaimService.formDataInput.dateFrom != null ||
      this.allocatedClaimService.formDataInput.dateTo != null) &&
      (this.allocatedClaimService.formDataInput.dateTypeId == null ||
        this.allocatedClaimService.formDataInput.dateFrom == null ||
        this.allocatedClaimService.formDataInput.dateTo == null)) {
      this.isValid = false;
      return false;
    }
    return this.isValid;
  }

  searchTitle = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((searchText) => this.service.searchTitle(searchText)),
      tap(() => this.searching = false)
    )

}

import { Component, OnInit, ViewChild, OnDestroy, AfterViewChecked, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router, ActivatedRoute } from '@angular/router';
import { LookupService } from '../../../shared/services/lookup.service';
import { Observable } from 'rxjs/internal/Observable';
import { Subject, Subscription } from 'rxjs';
import * as _ from "lodash";
import { ReserveHistory } from '../../claim/reserve-history/reserve-history.model';
import * as moment from 'moment';
import { NotificationService } from '../../../shared/services/notification.service';
import { ClaimReserveHistoryService } from '../reserve-history/claim-reserve-history.service';
import { ClaimDetailService } from '../claim-detail/claim-detail.service';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { SecurityService } from '../../../shared/services/security.service';


@Component({
  selector: 'app-reserve-history',
  templateUrl: './reserve-history.component.html',
  styleUrls: ['./reserve-history.component.css']
})

export class ReserveHistoryComponent extends BaseComponentComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  public claimDetailNotesResult: any;
  @ViewChild('reservehistoryTable') reservehistoryTable: ElementRef;
  public dtOptions: any = {};

  public dtTrigger: Subject<any>;
  reserveHistorys: ReserveHistory[] = [];
  claimId: string;
  datahave: boolean = false;
  isGridLoaded: boolean = false;
  eFileURL: string;
  isNewClaim: boolean;
  policyId: number;
  isMMIUser: boolean = false;
  hideWorkflowButton: boolean = true;
  //displayedColumns: any[] = [{ title: 'User ID', data: 'updatedBy' }, { title: 'Date', data: 'updatedDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } } ,{ title: 'MMI Paid', data: 'netPaid' }, { title: 'Excess Paid', data: 'excessPaid' }, { title: 'MMI Reserve', data: 'mmiReserve' }, { title: 'MMI Incurred', data: 'totalIncurred' }];

  constructor(
    private claimReserveHistoryService: ClaimReserveHistoryService,
    private _Activatedroute: ActivatedRoute,
    private _router: Router,
    public lookUpService: LookupService,
    private notificationService: NotificationService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private claimDetailService: ClaimDetailService,
    securityService: SecurityService) {
    super(securityService, 1144);
    this.dtTrigger = new Subject();
  }
  ngOnInit() {

    this.claimId = this.activeRoute.parent.snapshot.paramMap.get('claimId');
    this.policyId = parseInt(this.activeRoute.parent.snapshot.paramMap.get('policyId'));
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
      this.hideWorkflowButton = (this.isMMIUser) ? false : true
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [
      ],
      'columnDefs': [

        {
          className: "dt-body-center",
          targets: [0, 1]
        },
        { type: 'datetime-uk', "targets": 0 },
        {
          //render: function (data, type, row) {
          //  return '&pound;' + parseFloat(data).toFixed(2);
          //},
          className: "dt-body-right",
          targets: [2, 3, 4, 5]
        },
        {
          className: "dt-head-center",
          targets: [0, 1, 2, 3, 4, 5]
        },
      ], order: [[0, 'desc']]
    };

    this.getReservehistorybyClaimId(parseInt(this.claimId));

    this.checkForNewClaim(parseInt(this.claimId));
    //this.initialiseDataTable();
  }

  //initialiseDataTable(): void {
  //  const that = this;
  //  this.dtOptions = {
  //    pagingType: 'full_numbers',
  //    pageLength: 10,
  //    columns: this.displayedColumns,
  //    searching: false,
  //    // Declare the use of the extension in the dom parameter
  //    dom: 'Blfrtip',
  //    // Configure the buttons
  //    buttons: [
  //    ],
  //    'columnDefs': [

  //      {
  //        className: "dt-body-center",
  //        targets: [0,1]
  //      },
  //      { type: 'date-uk', "targets": 1},
  //      {
  //        render: function (data, type, row) {
  //          return '&pound;'+ parseFloat(data).toFixed(2);
  //        },
  //        className: "dt-body-right",
  //        targets: [2,3,4,5]
  //      },
  //      {
  //        className: "dt-head-center",
  //        targets: [0, 1, 2, 3, 4,5]
  //      },
  //    ],
  //    //order: [[0, 'desc']],
  //    ajax: (dataTablesParameters: any, callback) => {
  //      var self = this;
  //      this.claimReserveHistoryService.getReserveHistoryByClaimId(parseInt(this.claimId)).subscribe(response => {
  //        this.reserveHistorys = response;
  //        callback({
  //          data: response
  //        });
  //      });
  //    }
  //  };

  //}
  //ngOnDestroy(): void {
  //  // Do not forget to unsubscribe the event
  //  this.dtTrigger.unsubscribe();
  //}  

  getReservehistorybyClaimId(claimId: number) {
    this.claimReserveHistoryService.getReserveHistoryByClaimId(claimId).subscribe(response => {
      this.reserveHistorys = response;
      if (response == null) {
        this.reserveHistorys = [];
      }
      else {
        this.reserveHistorys = response;
      }
      this.refreshGrid();
      //response.length > 0 ? this.datahave = true : this.datahave = false;
      //console.log("data", response);
    });
  }


  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    if (this.reservehistoryTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getClaimEFileUrl(claimId) {
    this.claimDetailService.GetClaimEFileUrl(claimId).subscribe((res: any) => {
      console.log(res);
      if (res != null) {
        this.eFileURL = res.eFileUrl.item1;
      }
    },
      error => {
        console.log(error);
      });
  }

  getEFile() {
    if (this.eFileURL != '') {
      this.goToLink(this.eFileURL);
    }
    else {
      if (confirm("Claim folder does not exist for this claim. Do you want to create.")) {
        this.createClaimFolder();
      }
    }
  }

  createClaimFolder() {
    let claimId = parseInt(this.activeRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimDetailService.createClaimFolder(claimId).subscribe(res => {
      if (res != null) {
        this.eFileURL = res.filepath;
        if (this.eFileURL != '') {
          this.goToLink(this.eFileURL);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  checkForNewClaim(claimId) {
    if (claimId > 0) {
      this.isNewClaim = false;
      this.getClaimEFileUrl(claimId);
    } else {
      this.isNewClaim = true;
    }
  }

  navigateToWorkflowComponent() {
    this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/add-workflow-item']);
  }
}

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';




@Injectable({
  providedIn: 'root'
})
export class ApportionmentService {
    
  private apportionmentUrl: string = "/api/apportionment";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.apportionmentUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.apportionmentUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.apportionmentUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.apportionmentUrl);

  }

  calculateApportionment(data): any {
    let url = this.apportionmentUrl+"/calculate";
    return this.dataService.post(url, data);
  }

  addApportionment(data): any {

    return this.dataService.post(this.apportionmentUrl, data);
  }

  getClaimPoliciesByClaimId(id: number): any {
    let url = this.apportionmentUrl + "/getClaimPolicies";


    return this.dataService.get(url, { claimId: id });
  }
  getApportionment(claimId: number): any {
    
    return this.dataService.get(this.apportionmentUrl, { claimId: claimId });
  }
  updateApportionment(data): any {

    return this.dataService.putWithId(this.apportionmentUrl, data);
  }
  deleteApportionment(data): any {

   return this.dataService.putWithId(this.apportionmentUrl, data);
    
  }

  getClaimExcesses(claimId: any) {
    let url = this.apportionmentUrl + "/getClaimExcesses";
    return this.dataService.get(url, { claimId: claimId });
  }

  getClaimLOIs(claimId: any) {
    let url = this.apportionmentUrl + "/getClaimLOIs";
    return this.dataService.get(url, { claimId: claimId });
  }

  checkHandlingAuthorityAndReserveValidation(data) {
    let url = this.apportionmentUrl + "/checkHandlingAuthorityAndReserve";
    return this.dataService.post(url, data);
  }
  checkPolicyUsedInApportionment(Id: number,claimId:number): any {
    let url = this.apportionmentUrl + "/policyCheckInApportionment";
    return this.dataService.get(url, { policyID: Id, claimID: claimId });
  }

  checkMMIReserveValue(data): any {
    let url = this.apportionmentUrl + "/checkMMIReserveValue";
    return this.dataService.post(url, data);
  }

}



import { ApportionmentService } from '../../../shared/services/apportionment.service';
import { Observable } from 'rxjs';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { ReportsService } from '../../../shared/services/reports.service';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service';
import { claimsReport } from '../../../models/claims-report.model';
import { ClaimHandlerService } from '../../../shared/services/claim-handler.service';
import { Injectable } from '@angular/core';
import { enumRecoveryType, enumReportsType, enumLitigatedType } from '../../../shared/models/common.enum';
import { NotificationService } from '../../../shared/services/notification.service';



@Injectable({
  providedIn: 'root'
})
export class ClaimsReportService {
  formDataInput: claimsReport;


  constructor(private reportsService: ReportsService, private handlingOrganizationService: HandlingOrganisationService,
    private claimHandlerService: ClaimHandlerService, private notificationService: NotificationService) { }



  getHandlingOrganization(): Observable<any> {
    return this.handlingOrganizationService.getHandlingOrganization();
  }

  getClaimHandlersByHandlingOrganisation(organisationId: any) {
    return this.claimHandlerService.getClaimHandlers(organisationId);
  }

  generateClaimsReport(): any {
    var body = {
      ...this.formDataInput

    };
    this.reportsService.getClaimsReport(this.formDataInput).subscribe((response: any) => {

      if (response != null) {
        if (this.formDataInput.reportType == enumReportsType.newClaims) {
          this.generateExcelForNewClaims(response);
        }
        else if (this.formDataInput.reportType == enumReportsType.openClaims) {
          this.generateExcelForOpenClaims(response);
        }
        else {
          this.generateExcelForSettleClaims(response);
        }
      }
      else {
        this.notificationService.printWarningMessage("No data is available for the Report");
      }
    });
  }

  generateExcelForNewClaims(response: any) {
    let currentDate = moment(new Date().toDateString()).format('DD/MM/YYYY');
    let handlingOrganisationName = "";
    let claimHandlerName = "";

    if (this.formDataInput.handlingOrganisationId == 0 && this.formDataInput.claimHandlersIdValues.includes(0)) {
      handlingOrganisationName = "All-"
    }
    else {
      handlingOrganisationName = response.handlingOrganisationName + "-";
      claimHandlerName = "Claim Handlers-";
    }

    let fileName = "";
    let fileFormat = handlingOrganisationName + claimHandlerName + currentDate
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Claims Report');
    if (response.claimsReportResultModelForTotal != null && response.claimsReportResultModelForAverage != null && response.claimsReportResultModelForTotal.length > 0 && response.claimsReportResultModelForAverage.length > 0) {
      let worksheet1 = workbook.addWorksheet('Full list of claims');
      const totalHeader = ["Claim Type", "Claim Count", "Percentage (claim type)", "MMI Valuation-Total", "MMI Paid-Total", "Damages Paid", "Costs Paid", "CRU Paid", "Defence Costs Paid", "Excess Paid-Total", "MMI Reserve-Total", "MMI Incurred-Total"];

      let summaryRow1 = worksheet.addRow(["Handling Organisation", response.handlingOrganisationName, "Totals"]);
      this.fillBackGroundInExcellCell(summaryRow1.findCell(1));
      summaryRow1.findCell(1).font = { bold: true };
      summaryRow1.findCell(3).font = { bold: true };
      this.setBorderExcelCell(summaryRow1.findCell(1));
      this.setBorderExcelCell(summaryRow1.findCell(2));
      this.setBorderExcelCell(summaryRow1.findCell(3));
      worksheet.addRow([]);

      let totalHeaderRow = worksheet.addRow(totalHeader);
      totalHeaderRow.font = { bold: true };
      // Cell Style : Fill and Border
      totalHeaderRow.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'center' }
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });


      response.claimsReportResultModelForTotal.forEach((item, index) => {
        let dataForTotal: any[] = [];
        dataForTotal.push(item["claimType"]);
        dataForTotal.push(item["count"]);
        dataForTotal.push(item["percentage"]);
        dataForTotal.push(item["mmiValuationSum"]);
        dataForTotal.push(item["paidSum"]);
        dataForTotal.push(item["damagesPaidSum"]);
        dataForTotal.push(item["costsPaidSum"]);
        dataForTotal.push(item["cruPaidSum"]);
        dataForTotal.push(item["defenceCostsPaidSum"]);
        dataForTotal.push(item["excessPaid"]);
        dataForTotal.push(item["reserveSum"]);
        dataForTotal.push(item["incurredSum"]);

        let row = worksheet.addRow(dataForTotal);
        this.setCellFormatForNewClaim(row);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
      });

      let totalCount = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.count });
      let totalCountPercentage = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.percentage });
      let totalmmiValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.mmiValuationSum });
      let totalMMIPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.paidSum });
      let totalDamagesPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.damagesPaidSum });
      let totalCostsPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.costsPaidSum });
      let totalCRUPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.cruPaidSum });
      let totalDefenceCostsPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.defenceCostsPaidSum });
      let totalExcessPaid = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.excessPaid });
      let totalReserveSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.reserveSum });
      let totalIncurredSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.incurredSum });

      let totalfooter = ['Total', totalCount, totalCountPercentage, totalmmiValuationSum, totalMMIPaidSum, totalDamagesPaidSum, totalCostsPaidSum, totalCRUPaidSum,
        totalDefenceCostsPaidSum, totalExcessPaid, totalReserveSum, totalIncurredSum];

      let totalfooterRow = worksheet.addRow(totalfooter);
      totalfooterRow.font = { bold: true };
      this.setCellFormatForNewClaim(totalfooterRow);
      totalfooterRow.eachCell((cell, number) => {
        // cell.alignment = { horizontal: 'right' }
        this.fillBackGroundInTotalRowExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      worksheet.addRow([]);
      worksheet.addRow([]);
      const averageHeader = ["Claim Type", "Claim Count", "Percentage (claim type)", "MMI Valuation-Average", "MMI Paid-Total", "Damages Paid - Average", "Costs Paid - Average",
        "CRU Paid - Average", "Defence Costs Paid - Average", "Excess Paid-Total", "MMI Reserve-Total", "MMI Incurred-Total"];

      let summaryRow2 = worksheet.addRow(["Handling Organisation", response.handlingOrganisationName, "Average"]);
      this.fillBackGroundInExcellCell(summaryRow2.findCell(1));
      summaryRow2.findCell(1).font = { bold: true };
      summaryRow2.findCell(3).font = { bold: true };
      this.setBorderExcelCell(summaryRow2.findCell(1));
      this.setBorderExcelCell(summaryRow2.findCell(2));
      this.setBorderExcelCell(summaryRow2.findCell(3));
      worksheet.addRow([]);

      let averageHeaderRow = worksheet.addRow(averageHeader);
      averageHeaderRow.font = { bold: true };
      // Cell Style : Fill and Border
      averageHeaderRow.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'center' }
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });


      response.claimsReportResultModelForAverage.forEach((item, index) => {
        let dataForAverage: any[] = [];
        dataForAverage.push(item["claimType"]);
        dataForAverage.push(item["count"]);
        dataForAverage.push(item["percentage"]);
        dataForAverage.push(item["mmiValuationSum"]);
        dataForAverage.push(item["paidSum"]);
        dataForAverage.push(item["damagesPaidSum"]);
        dataForAverage.push(item["costsPaidSum"]);
        dataForAverage.push(item["cruPaidSum"]);
        dataForAverage.push(item["defenceCostsPaidSum"]);
        dataForAverage.push(item["excessPaid"]);
        dataForAverage.push(item["reserveSum"]);
        dataForAverage.push(item["incurredSum"]);

        let row = worksheet.addRow(dataForAverage);
        this.setCellFormatForNewClaim(row);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
      });

      let totalCountForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.count });
      let totalCountPercentageForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.percentage });
      let totalmmiValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.mmiValuationSum });
      let totalMMIPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.paidSum });
      let totalDamagesPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.damagesPaidSum });
      let totalCostsPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.costsPaidSum });
      let totalCRUPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.cruPaidSum });
      let totalDefenceCostsPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.defenceCostsPaidSum });
      let totalExcessPaidForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.excessPaid });
      let totalReserveSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.reserveSum });
      let totalIncurredSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.incurredSum });

      let averagetablefooter = ['Total', totalCountForAverageTable, totalCountPercentageForAverageTable, totalmmiValuationSumForAverageTable, totalMMIPaidSumForAverageTable,
        totalDamagesPaidSumForAverageTable, totalCostsPaidSumForAverageTable, totalCRUPaidSumForAverageTable, totalDefenceCostsPaidSumForAverageTable, totalExcessPaidForAverageTable,
        totalReserveSumForAverageTable, totalIncurredSumForAverageTable];

      let averagefooterRow = worksheet.addRow(averagetablefooter);
      averagefooterRow.font = { bold: true };
      this.setCellFormatForNewClaim(averagefooterRow);
      averagefooterRow.eachCell((cell, number) => {
        // cell.alignment = { horizontal: 'right' }
        this.fillBackGroundInTotalRowExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      for (var i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 25;
      }

      this.generateExcelForListOfClaims(worksheet1, "", response);

    }

    if (this.formDataInput.reportType == enumReportsType.newClaims) {
      fileName = "New Claims Report:" + fileFormat;
    }

    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName + '_' + Math.random() + '.xlsx');
    })
  }

  generateExcelForOpenClaims(response: any) {
    let currentDate = moment(new Date().toDateString()).format('DD/MM/YYYY');
    let handlingOrganisationName = "";
    let claimHandlerName = "";

    if (this.formDataInput.handlingOrganisationId == 0 && this.formDataInput.claimHandlersIdValues.includes(0)) {
      handlingOrganisationName = "All-"
    }
    else {
      handlingOrganisationName = response.handlingOrganisationName + "-";
      claimHandlerName = "Claim Handlers-";
    }

    let fileName = "";
    let fileFormat = handlingOrganisationName + claimHandlerName + currentDate
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Claims Report');
    if (response.claimsReportResultModelForTotal != null && response.claimsReportResultModelForTotal.length > 0) {

      let summaryRowTotal = worksheet.addRow(["Handling Organisation", response.handlingOrganisationName]);
      this.fillBackGroundInExcellCell(summaryRowTotal.findCell(1));
      summaryRowTotal.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRowTotal.findCell(1));
      this.setBorderExcelCell(summaryRowTotal.findCell(2));

      worksheet.addRow([]);

      worksheet.addRow(["Average"]);

      const averageHeader = ["Claim Type", "Claim Count", "Percentage (claim type)", "MMI Valuation", "MMI Paid", "Damages Valuation", "Costs Valuation", "CRU Valuation", "Defence Costs Valuation", "Damages Paid", "Costs Paid", "CRU Paid", "Defence Costs Paid", "Excess Paid", "MMI Reserve", "MMI Incurred", "Litigated %", "Lifecycle"];
      let averageHeaderRow = worksheet.addRow(averageHeader);
      averageHeaderRow.font = { bold: true };
      // Cell Style : Fill and Border
      averageHeaderRow.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'center' }
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      response.claimsReportResultModelForAverage.forEach((item, index) => {
        let dataForAverage: any[] = [];
        dataForAverage.push(item["claimType"]);
        dataForAverage.push(item["count"]);
        dataForAverage.push(item["percentage"]);
        dataForAverage.push(item["mmiValuationSum"]);
        dataForAverage.push(item["paidSum"]);

        dataForAverage.push(item["damagesValuationSum"]);
        dataForAverage.push(item["costsValuationSum"]);
        dataForAverage.push(item["cruValuationSum"]);
        dataForAverage.push(item["defenceCostsValuationSum"]);

        dataForAverage.push(item["damagesPaidSum"]);
        dataForAverage.push(item["costsPaidSum"]);
        dataForAverage.push(item["cruPaidSum"]);
        dataForAverage.push(item["defenceCostsPaidSum"]);
        dataForAverage.push(item["excessPaid"]);
        dataForAverage.push(item["reserveSum"]);
        dataForAverage.push(item["incurredSum"]);
        dataForAverage.push(item["litigatedPercentage"]);
        dataForAverage.push(item["lifecycle"]);

        let row = worksheet.addRow(dataForAverage);
        this.setCellFormatForOpenClaim(row);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
      });

      let totalCountForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.count });
      let totalCountPercentageForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.percentage });
      let totalmmiValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.mmiValuationSum });
      let totalMMIPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.paidSum });

      let totalDamagesValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.damagesValuationSum });
      let totalCostsValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.costsValuationSum });
      let totalCRUValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.cruValuationSum });
      let totalDefenceCostsValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.defenceCostsValuationSum });


      let totalDamagesPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.damagesPaidSum });
      let totalCostsPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.costsPaidSum });
      let totalCRUPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.cruPaidSum });
      let totalDefenceCostsPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.defenceCostsPaidSum });
      let totalExcessPaidForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.excessPaid });
      let totalReserveSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.reserveSum });
      let totalIncurredSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.incurredSum });

      let totallitigatedPercentageForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.litigatedPercentage });
      let totallifecycleForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.lifecycle });

      let averagetablefooter = ['Average', totalCountForAverageTable, totalCountPercentageForAverageTable, totalmmiValuationSumForAverageTable, totalMMIPaidSumForAverageTable,
        totalDamagesValuationSumForAverageTable, totalCostsValuationSumForAverageTable, totalCRUValuationSumForAverageTable, totalDefenceCostsValuationSumForAverageTable,
        totalDamagesPaidSumForAverageTable, totalCostsPaidSumForAverageTable, totalCRUPaidSumForAverageTable, totalDefenceCostsPaidSumForAverageTable, totalExcessPaidForAverageTable,
        totalReserveSumForAverageTable, totalIncurredSumForAverageTable, totallitigatedPercentageForAverageTable, totallifecycleForAverageTable];

      let averagefooterRow = worksheet.addRow(averagetablefooter);
      averagefooterRow.font = { bold: true };
      this.setCellFormatForNewClaim(averagefooterRow);
      averagefooterRow.eachCell((cell, number) => {
        // cell.alignment = { horizontal: 'right' }
        this.fillBackGroundInTotalRowExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //for (var i = 1; i <= worksheet.columnCount; i++) {
      //  worksheet.getColumn(i).width = 25;
      //}

      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow(["Total"]);

      const totalHeader = ["Claim Type", "Claim Count", "Percentage (claim type)", "MMI Valuation", "MMI Paid", "Damages Valuation", "Costs Valuation", "CRU Valuation", "Defence Costs Valuation", "Damages Paid", "Costs Paid", "CRU Paid", "Defence Costs Paid", "Excess Paid", "MMI Reserve", "MMI Incurred", "Litigated %", "Lifecycle"];
      let totalHeaderRow = worksheet.addRow(totalHeader);
      totalHeaderRow.font = { bold: true };
      // Cell Style : Fill and Border
      totalHeaderRow.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'center' }
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      let dataForTotal: any[] = [];
      response.claimsReportResultModelForTotal.forEach((item, index) => {
        dataForTotal.push([item["claimType"]
          , item["count"]
          , item["percentage"]
          , item["mmiValuationSum"]
          , item["paidSum"]
          , item["damagesValuationSum"]
          , item["costsValuationSum"]
          , item["cruValuationSum"]
          , item["defenceCostsValuationSum"]
          , item["damagesPaidSum"]
          , item["costsPaidSum"]
          , item["cruPaidSum"]
          , item["defenceCostsPaidSum"]
          , item["excessPaid"]
          , item["reserveSum"]
          , item["incurredSum"]
          , item["litigatedPercentage"]
          , item["lifecycle"]
        ]);
      });

      dataForTotal.forEach(d => {
        let row = worksheet.addRow(d);
        this.setCellFormatForOpenClaim(row);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        });
      });

      let totalCount = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.count });
      let totalCountPercentage = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.percentage });
      let totalmmiValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.mmiValuationSum });
      let totalMMIPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.paidSum });
      let totalDamagesValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.damagesValuationSum });
      let totalCostsValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.costsValuationSum });
      let totalCRUValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.cruValuationSum });
      let totalDefenceCostsValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.defenceCostsValuationSum });
      let totalDamagesPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.damagesPaidSum });
      let totalCostsPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.costsPaidSum });
      let totalCRUPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.cruPaidSum });
      let totalDefenceCostsPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.defenceCostsPaidSum });
      let totalExcessPaid = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.excessPaid });
      let totalReserveSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.reserveSum });
      let totalIncurredSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.incurredSum });
      let totallitigatedPercentage = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.litigatedPercentage });
      let totallifecycle = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.lifecycle });

      let totalfooter = ['Total', totalCount, totalCountPercentage, totalmmiValuationSum, totalMMIPaidSum, totalDamagesValuationSum, totalCostsValuationSum, totalCRUValuationSum,
        totalDefenceCostsValuationSum, totalDamagesPaidSum, totalCostsPaidSum, totalCRUPaidSum, totalDefenceCostsPaidSum, totalExcessPaid, totalReserveSum, totalIncurredSum, totallitigatedPercentage, totallifecycle];
      let totalfooterRow = worksheet.addRow(totalfooter);
      totalfooterRow.font = { bold: true };
      this.setCellFormatForOpenClaim(totalfooterRow);
      totalfooterRow.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'right' }
        this.fillBackGroundInTotalRowExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      for (var i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 25;
      }

      let worksheet1 = workbook.addWorksheet('Full list of claims');
      this.generateExcelForListOfOpenClaims(worksheet1, "", response);
    }

    fileName = "Open Claims Report:" + fileFormat;

    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName + '_' + Math.random() + '.xlsx');
    })
  }

  generateExcelForSettleClaims(response: any) {
    let currentDate = moment(new Date().toDateString()).format('DD/MM/YYYY');
    let handlingOrganisationName = "";
    let claimHandlerName = "";

    if (this.formDataInput.handlingOrganisationId == 0 && this.formDataInput.claimHandlersIdValues.includes(0)) {
      handlingOrganisationName = "All-"
    }
    else {
      handlingOrganisationName = response.handlingOrganisationName + "-";
      claimHandlerName = "Claim Handlers-";
    }

    let fileName = "";
    let fileFormat = handlingOrganisationName + claimHandlerName + currentDate
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Claims Report');
    if (response.claimsReportResultModelForTotal != null && response.claimsReportResultModelForTotal.length > 0) {

      let summaryRowTotal = worksheet.addRow(["Handling Organisation", response.handlingOrganisationName]);
      this.fillBackGroundInExcellCell(summaryRowTotal.findCell(1));
      summaryRowTotal.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRowTotal.findCell(1));
      this.setBorderExcelCell(summaryRowTotal.findCell(2));

      worksheet.addRow([]);

      worksheet.addRow(["Average"]);

      const averageHeader = ["Claim Type", "Claim Count", "Percentage (claim type)", "MMI Valuation", "MMI Paid", "Damages Valuation", "Costs Valuation", "CRU Valuation", "Defence Costs Valuation", "Damages Paid", "Costs Paid", "CRU Paid", "Defence Costs Paid", "Excess Paid", "MMI Incurred", "Litigated %", "Lifecycle"];
      let averageHeaderRow = worksheet.addRow(averageHeader);
      averageHeaderRow.font = { bold: true };
      // Cell Style : Fill and Border
      averageHeaderRow.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'center' }
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      response.claimsReportResultModelForAverage.forEach((item, index) => {
        let dataForAverage: any[] = [];
        dataForAverage.push(item["claimType"]);
        dataForAverage.push(item["count"]);
        dataForAverage.push(item["percentage"]);
        dataForAverage.push(item["mmiValuationSum"]);
        dataForAverage.push(item["paidSum"]);

        dataForAverage.push(item["damagesValuationSum"]);
        dataForAverage.push(item["costsValuationSum"]);
        dataForAverage.push(item["cruValuationSum"]);
        dataForAverage.push(item["defenceCostsValuationSum"]);

        dataForAverage.push(item["damagesPaidSum"]);
        dataForAverage.push(item["costsPaidSum"]);
        dataForAverage.push(item["cruPaidSum"]);
        dataForAverage.push(item["defenceCostsPaidSum"]);
        dataForAverage.push(item["excessPaid"]);
        // dataForAverage.push(item["reserveSum"]);
        dataForAverage.push(item["incurredSum"]);
        dataForAverage.push(item["litigatedPercentage"]);
        dataForAverage.push(item["lifecycle"]);

        let row = worksheet.addRow(dataForAverage);
        this.setCellFormatForSettledClaim(row);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
      });

      let totalCountForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.count });
      let totalCountPercentageForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.percentage });
      let totalmmiValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.mmiValuationSum });
      let totalMMIPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.paidSum });

      let totalDamagesValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.damagesValuationSum });
      let totalCostsValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.costsValuationSum });
      let totalCRUValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.cruValuationSum });
      let totalDefenceCostsValuationSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.defenceCostsValuationSum });


      let totalDamagesPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.damagesPaidSum });
      let totalCostsPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.costsPaidSum });
      let totalCRUPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.cruPaidSum });
      let totalDefenceCostsPaidSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.defenceCostsPaidSum });
      let totalExcessPaidForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.excessPaid });
      //let totalReserveSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.reserveSum });
      let totalIncurredSumForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.incurredSum });

      let totallitigatedPercentageForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.litigatedPercentage });
      let totallifecycleForAverageTable = _.sumBy(response.claimsReportResultModelForAverage, (o: any) => { return o.lifecycle });

      let averagetablefooter = ['Average', totalCountForAverageTable, totalCountPercentageForAverageTable, totalmmiValuationSumForAverageTable, totalMMIPaidSumForAverageTable,
        totalDamagesValuationSumForAverageTable, totalCostsValuationSumForAverageTable, totalCRUValuationSumForAverageTable, totalDefenceCostsValuationSumForAverageTable,
        totalDamagesPaidSumForAverageTable, totalCostsPaidSumForAverageTable, totalCRUPaidSumForAverageTable, totalDefenceCostsPaidSumForAverageTable, totalExcessPaidForAverageTable,
        totalIncurredSumForAverageTable, totallitigatedPercentageForAverageTable, totallifecycleForAverageTable];

      let averagefooterRow = worksheet.addRow(averagetablefooter);
      averagefooterRow.font = { bold: true };
      this.setCellFormatForSettledClaim(averagefooterRow);
      averagefooterRow.eachCell((cell, number) => {
        // cell.alignment = { horizontal: 'right' }
        this.fillBackGroundInTotalRowExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //for (var i = 1; i <= worksheet.columnCount; i++) {
      //  worksheet.getColumn(i).width = 25;
      //}

      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow(["Total"]);

      const totalHeader = ["Claim Type", "Claim Count", "Percentage (claim type)", "MMI Valuation", "MMI Paid", "Damages Valuation", "Costs Valuation", "CRU Valuation", "Defence Costs Valuation", "Damages Paid", "Costs Paid", "CRU Paid", "Defence Costs Paid", "Excess Paid", "MMI Incurred", "Litigated %", "Lifecycle"];
      let totalHeaderRow = worksheet.addRow(totalHeader);
      totalHeaderRow.font = { bold: true };
      // Cell Style : Fill and Border
      totalHeaderRow.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'center' }
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      let dataForTotal: any[] = [];
      response.claimsReportResultModelForTotal.forEach((item, index) => {
        dataForTotal.push([item["claimType"]
          , item["count"]
          , item["percentage"]
          , item["mmiValuationSum"]
          , item["paidSum"]
          , item["damagesValuationSum"]
          , item["costsValuationSum"]
          , item["cruValuationSum"]
          , item["defenceCostsValuationSum"]
          , item["damagesPaidSum"]
          , item["costsPaidSum"]
          , item["cruPaidSum"]
          , item["defenceCostsPaidSum"]
          , item["excessPaid"]
          // , item["reserveSum"]
          , item["incurredSum"]
          , item["litigatedPercentage"]
          , item["lifecycle"]
        ]);
      });

      dataForTotal.forEach(d => {
        let row = worksheet.addRow(d);
        this.setCellFormatForSettledClaim(row);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        });
      });

      let totalCount = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.count });
      let totalCountPercentage = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.percentage });
      let totalmmiValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.mmiValuationSum });
      let totalMMIPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.paidSum });
      let totalDamagesValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.damagesValuationSum });
      let totalCostsValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.costsValuationSum });
      let totalCRUValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.cruValuationSum });
      let totalDefenceCostsValuationSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.defenceCostsValuationSum });
      let totalDamagesPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.damagesPaidSum });
      let totalCostsPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.costsPaidSum });
      let totalCRUPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.cruPaidSum });
      let totalDefenceCostsPaidSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.defenceCostsPaidSum });
      let totalExcessPaid = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.excessPaid });
      // let totalReserveSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.reserveSum });
      let totalIncurredSum = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.incurredSum });
      let totallitigatedPercentage = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.litigatedPercentage });
      let totallifecycle = _.sumBy(response.claimsReportResultModelForTotal, (o: any) => { return o.lifecycle });

      let totalfooter = ['Total', totalCount, totalCountPercentage, totalmmiValuationSum, totalMMIPaidSum, totalDamagesValuationSum, totalCostsValuationSum, totalCRUValuationSum,
        totalDefenceCostsValuationSum, totalDamagesPaidSum, totalCostsPaidSum, totalCRUPaidSum, totalDefenceCostsPaidSum, totalExcessPaid, totalIncurredSum, totallitigatedPercentage, totallifecycle];
      let totalfooterRow = worksheet.addRow(totalfooter);
      totalfooterRow.font = { bold: true };
      this.setCellFormatForSettledClaim(totalfooterRow);
      totalfooterRow.eachCell((cell, number) => {
        cell.alignment = { horizontal: 'right' }
        this.fillBackGroundInTotalRowExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      for (var i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 25;
      }

      let worksheet1 = workbook.addWorksheet('Full list of claims');
      this.generateExcelForListOfSettledClaims(worksheet1, "", response);

    }

    fileName = "Settled Claims Report:" + fileFormat;


    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName + '_' + Math.random() + '.xlsx');
    })

  }

  generateExcelForListOfClaims(worksheet1: ExcelProper.Worksheet, reportTypeHeader, response: any) {

    let header = ["MMI Claim Reference", "Claimant Name", "Insured Title", "Handling Organisation Claim Ref", "Policy Number", "Policyholder Name",
      "Policyholder ID", "Claim Type", "MMI Paid", "MMI Reserve", "MMI Incurred", "Reserve Last Reviewed", "Diary Date", "Claim Status", "Linked References",
      "Line Of Business", "Handling Organization", "Claim Handler", "Claimant DOB", "MMI Review Date", "MMI Key Contact", "Claimant NINO", "Location", "Perpetrator",
      "Open Date", "Settled Date", "Date Last Re-Opened", "DA Status", "Charging Rate", "Litigated Claim", "Addition Policy Number 1", "Addition Policy Number 2",
      "Addition Policy Number 3", "Addition Policy Number 4", "Addition Policy Number 5"];

    let headerRow = worksheet1.addRow(header);
    response.claimResultItems.forEach((item, index) => {
      let data: any[] = [];
      data.push(item.mmiClaimsReference);
      data.push(item.claimantName);
      data.push(item.insuredTitle);
      data.push(item.claimReference);
      data.push(item.policyNumber);
      data.push(item.creditorName);
      data.push(item.creditorId);
      data.push(item.claimType);
      data.push(item.totalPayments);
      data.push(item.totalReserve);
      data.push(item.totalIncurred);
      data.push(item.reserveLastReviewed != null ? (moment(new Date(item.reserveLastReviewed).toDateString()).format('DD/MM/YYYY')) : item.reserveLastReviewed);
      data.push(item.diaryDate != null ? (moment(new Date(item.diaryDate).toDateString()).format('DD/MM/YYYY')) : item.diaryDate);
      data.push(item.claimStatus);
      data.push(item.linkedReferences);
      data.push(item.lineofBusiness);
      data.push(item.handlingOrganisation);
      data.push(item.claimHandler);
      data.push(item.claimantDOB != null ? (moment(new Date(item.claimantDOB).toDateString()).format('DD/MM/YYYY')) : item.claimantDOB);
      data.push(item.mmiReviewDate != null ? (moment(new Date(item.mmiReviewDate).toDateString()).format('DD/MM/YYYY')) : item.mmiReviewDate);
      data.push(item.mmiKeyContact);
      data.push(item.claimantNINO);
      data.push(item.location);
      data.push(item.perpetrator);
      data.push(item.openDate != null ? (moment(new Date(item.openDate).toDateString()).format('DD/MM/YYYY')) : item.openDate);
      data.push(item.settledDate != null ? (moment(new Date(item.settledDate).toDateString()).format('DD/MM/YYYY')) : item.settledDate);
      data.push(item.dateLastReopened != null ? (moment(new Date(item.dateLastReopened).toDateString()).format('DD/MM/YYYY')) : item.dateLastReopened);
      data.push(item.daStatus);
      data.push(item.chargingRate);
      data.push(item.litigated);
      if (item.additionalPolicyNumberList != null) {
        if (item.additionalPolicyNumberList.length > 0) {
          data.push(item.additionalPolicyNumberList[0]);
        }
        if (item.additionalPolicyNumberList.length > 1) {
          data.push(item.additionalPolicyNumberList[1]);
        }
        if (item.additionalPolicyNumberList.length > 2) {
          data.push(item.additionalPolicyNumberList[2]);
        }
        if (item.additionalPolicyNumberList.length > 3) {
          data.push(item.additionalPolicyNumberList[3]);
        }
        if (item.additionalPolicyNumberList.length > 4) {
          data.push(item.additionalPolicyNumberList[4]);
        }
      }

      let row = worksheet1.addRow(data);
      row.eachCell((cell, number) => {
        this.setBorderExcelCell(cell);
      })
      this.setCellFormatOfListOfClaims(row);

    });

    headerRow.font = { bold: true };
    headerRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });
    for (var i = 1; i <= worksheet1.columnCount; i++) {
      worksheet1.getColumn(i).width = 30;
    }

    worksheet1.addRow([]);
  }

  generateExcelForListOfOpenClaims(worksheet1: ExcelProper.Worksheet, reportTypeHeader, response: any) {

    let header = ["MMI Claim Reference", "Claimant Name", "Insured Title", "Full Liability Valuation Total", "Full Liability Valuation Damages", "Full Liability Valuation Costs", "Full Liability Valuation CRU", "Full Liability Valuation Defence Costs", "Claim Type", "MMI Paid", "MMI Paid Damages", "MMI Paid Claimant Costs",
      "MMI Paid CRU", "MMI Paid Defence Costs", "MMI Reserve", "MMI Reserve Damages", "MMI Reserve Claimant Costs", "MMI Reserve CRU", "MMI Reserve Defence Costs",
      "MMI Incurred", "MMI Damages Incurred", "MMI Incurred Claimant Costs", "MMI Incurred CRU", "MMI Incurred Defence Costs", "Excess Paid",
      "Diary Date", "Handling Organization", "Open Date", "Settled Date", "Date Last Re-Opened", "Life Cycle", "DA Status", "Charging Rate", "Litigated Claim"];

    let headerRow = worksheet1.addRow(header);
    response.claimResultItems.forEach((item, index) => {
      let data: any[] = [];
      data.push(item.mmiClaimsReference);
      data.push(item.claimantName);
      data.push(item.insuredTitle);

      data.push(item.totalFullLiabilityVal);
      data.push(item.totalDamagesFullLiabilityVal);
      data.push(item.totalClaimantsCostsFullLiabilityVal);
      data.push(item.totalCRUFullLiabilityVal);
      data.push(item.totalDefenceCostsFullLiabilityVal);

      // data.push(item.claimReference);
      // data.push(item.policyNumber);
      // data.push(item.creditorName);
      // data.push(item.creditorId);
      data.push(item.claimType);
      data.push(item.totalPayments);
      data.push(item.totalDamagesPayments);
      data.push(item.totalClaimantsCostsPayments);
      data.push(item.totalCRUPayments);
      data.push(item.totalDefenceCostsPayments);
      data.push(item.totalReserve);
      data.push(item.totalDamagesReserve);
      data.push(item.totalClaimantsCostsReserve);
      data.push(item.totalCRUReserve);
      data.push(item.totalDefenceCostsReserve);
      data.push(item.totalIncurred);
      data.push(item.totalDamagesIncurred);
      data.push(item.totalClaimantsCostsIncurred);
      data.push(item.totalCRUIncurred);
      data.push(item.totalDefenceCostsIncurred);
      data.push(item.totalExcessPaid);
      // data.push(item.reserveLastReviewed != null ? (moment(new Date(item.reserveLastReviewed).toDateString()).format('DD/MM/YYYY')) : item.reserveLastReviewed);
      data.push(item.diaryDate != null ? (moment(new Date(item.diaryDate).toDateString()).format('DD/MM/YYYY')) : item.diaryDate);
      // data.push(item.claimStatus);
      // data.push(item.linkedReferences);
      // data.push(item.lineofBusiness);
      data.push(item.handlingOrganisation);
      //data.push(item.claimHandler);
      //data.push(item.claimantDOB != null ? (moment(new Date(item.claimantDOB).toDateString()).format('DD/MM/YYYY')) : item.claimantDOB);
      //data.push(item.mmiReviewDate != null ? (moment(new Date(item.mmiReviewDate).toDateString()).format('DD/MM/YYYY')) : item.mmiReviewDate);
      //data.push(item.mmiKeyContact);
      //data.push(item.claimantNINO);
      //data.push(item.location);
      //data.push(item.perpetrator);
      data.push(item.openDate != null ? (moment(new Date(item.openDate).toDateString()).format('DD/MM/YYYY')) : item.openDate);
      data.push(item.settledDate != null ? (moment(new Date(item.settledDate).toDateString()).format('DD/MM/YYYY')) : item.settledDate);
      data.push(item.dateLastReopened != null ? (moment(new Date(item.dateLastReopened).toDateString()).format('DD/MM/YYYY')) : item.dateLastReopened);
      data.push(item.lifecycle);
      data.push(item.daStatus);
      data.push(item.chargingRate);
      data.push(item.litigated);

      let row = worksheet1.addRow(data);
      row.eachCell((cell, number) => {
        this.setBorderExcelCell(cell);
      })
      this.setCellFormatOfListOfOpenClaims(row);

    });

    headerRow.font = { bold: true };
    headerRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });
    for (var i = 1; i <= worksheet1.columnCount; i++) {
      worksheet1.getColumn(i).width = 30;
    }

    worksheet1.addRow([]);
  }

  generateExcelForListOfSettledClaims(worksheet1: ExcelProper.Worksheet, reportTypeHeader, response: any) {

    let header = ["MMI Claim Reference", "Claimant Name", "Insured Title", "Full Liability Valuation Total", "Full Liability Valuation Damages", "Full Liability Valuation Costs", "Full Liability Valuation CRU", "Full Liability Valuation Defence Costs", "Claim Type", "MMI Paid", "MMI Paid Damages", "MMI Paid Claimant Costs",
      "MMI Paid CRU", "MMI Paid Defence Costs", "MMI Reserve", "MMI Reserve Damages", "MMI Reserve Claimant Costs", "MMI Reserve CRU", "MMI Reserve Defence Costs",
      "MMI Incurred", "MMI Damages Incurred", "MMI Incurred Claimant Costs", "MMI Incurred CRU", "MMI Incurred Defence Costs", "Excess Paid",
      "Diary Date", "Handling Organization", "Open Date", "Settled Date", "Date Last Re-Opened", "Life Cycle", "DA Status", "Charging Rate", "Litigated Claim"];

    let headerRow = worksheet1.addRow(header);
    response.claimResultItems.forEach((item, index) => {
      let data: any[] = [];
      data.push(item.mmiClaimsReference);
      data.push(item.claimantName);
      data.push(item.insuredTitle);

      data.push(item.totalFullLiabilityVal);
      data.push(item.totalDamagesFullLiabilityVal);
      data.push(item.totalClaimantsCostsFullLiabilityVal);
      data.push(item.totalCRUFullLiabilityVal);
      data.push(item.totalDefenceCostsFullLiabilityVal);

      // data.push(item.claimReference);
      // data.push(item.policyNumber);
      // data.push(item.creditorName);
      // data.push(item.creditorId);
      data.push(item.claimType);
      data.push(item.totalPayments);
      data.push(item.totalDamagesPayments);
      data.push(item.totalClaimantsCostsPayments);
      data.push(item.totalCRUPayments);
      data.push(item.totalDefenceCostsPayments);
      data.push(item.totalReserve);
      data.push(item.totalDamagesReserve);
      data.push(item.totalClaimantsCostsReserve);
      data.push(item.totalCRUReserve);
      data.push(item.totalDefenceCostsReserve);
      data.push(item.totalIncurred);
      data.push(item.totalDamagesIncurred);
      data.push(item.totalClaimantsCostsIncurred);
      data.push(item.totalCRUIncurred);
      data.push(item.totalDefenceCostsIncurred);
      data.push(item.totalExcessPaid);
      // data.push(item.reserveLastReviewed != null ? (moment(new Date(item.reserveLastReviewed).toDateString()).format('DD/MM/YYYY')) : item.reserveLastReviewed);
      data.push(item.diaryDate != null ? (moment(new Date(item.diaryDate).toDateString()).format('DD/MM/YYYY')) : item.diaryDate);
      // data.push(item.claimStatus);
      // data.push(item.linkedReferences);
      // data.push(item.lineofBusiness);
      data.push(item.handlingOrganisation);
      //data.push(item.claimHandler);
      //data.push(item.claimantDOB != null ? (moment(new Date(item.claimantDOB).toDateString()).format('DD/MM/YYYY')) : item.claimantDOB);
      //data.push(item.mmiReviewDate != null ? (moment(new Date(item.mmiReviewDate).toDateString()).format('DD/MM/YYYY')) : item.mmiReviewDate);
      //data.push(item.mmiKeyContact);
      //data.push(item.claimantNINO);
      //data.push(item.location);
      //data.push(item.perpetrator);
      data.push(item.openDate != null ? (moment(new Date(item.openDate).toDateString()).format('DD/MM/YYYY')) : item.openDate);
      data.push(item.settledDate != null ? (moment(new Date(item.settledDate).toDateString()).format('DD/MM/YYYY')) : item.settledDate);
      data.push(item.dateLastReopened != null ? (moment(new Date(item.dateLastReopened).toDateString()).format('DD/MM/YYYY')) : item.dateLastReopened);
      data.push(item.lifecycle);
      data.push(item.daStatus);
      data.push(item.chargingRate);
      data.push(item.litigated);

      let row = worksheet1.addRow(data);
      row.eachCell((cell, number) => {
        this.setBorderExcelCell(cell);
      })
      this.setCellFormatOfListOfSettledClaims(row);

    });

    headerRow.font = { bold: true };
    headerRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });
    for (var i = 1; i <= worksheet1.columnCount; i++) {
      worksheet1.getColumn(i).width = 30;
    }

    worksheet1.addRow([]);
  }

  setCellFormatOfListOfClaims(row: ExcelProper.Row) {
    row.findCell(9).numFmt = '£###,###,##0.00';
    row.findCell(10).numFmt = '£###,###,##0.00';
    row.findCell(11).numFmt = '£###,###,##0.00';
  }

  setBorderExcelCell(cell: any) {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
  }

  fillBackGroundInTotalRowExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '009FC5E8' },
      bgColor: { argb: '009FC5E8' }
    }
  }

  fillBackGroundInExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00C4C4C4' },
      bgColor: { argb: 'FF0000FF' }
    }
  }

  getDateOnly(date: Date) {
    if (typeof date == 'string')
      var newDate = new Date(date);
    else
      var newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  setCellFormatForNewClaim(row: ExcelProper.Row) {
    row.findCell(3).numFmt = '#0.00"%";'
    row.findCell(4).numFmt = '£###,###,##0.00';
    row.findCell(5).numFmt = '£###,###,##0.00';
    row.findCell(6).numFmt = '£###,###,##0.00';
    row.findCell(7).numFmt = '£###,###,##0.00';
    row.findCell(8).numFmt = '£###,###,##0.00';
    row.findCell(9).numFmt = '£###,###,##0.00';
    row.findCell(10).numFmt = '£###,###,##0.00';
    row.findCell(11).numFmt = '£###,###,##0.00';
    row.findCell(12).numFmt = '£###,###,##0.00';
  }

  setCellFormatForOpenClaim(row: ExcelProper.Row) {
    row.findCell(3).numFmt = '#0.00"%";'
    row.findCell(4).numFmt = '£###,###,##0.00';
    row.findCell(5).numFmt = '£###,###,##0.00';
    row.findCell(6).numFmt = '£###,###,##0.00';
    row.findCell(7).numFmt = '£###,###,##0.00';
    row.findCell(8).numFmt = '£###,###,##0.00';
    row.findCell(9).numFmt = '£###,###,##0.00';
    row.findCell(10).numFmt = '£###,###,##0.00';
    row.findCell(11).numFmt = '£###,###,##0.00';
    row.findCell(12).numFmt = '£###,###,##0.00';
    row.findCell(13).numFmt = '£###,###,##0.00';
    row.findCell(14).numFmt = '£###,###,##0.00';
    row.findCell(15).numFmt = '£###,###,##0.00';
    row.findCell(16).numFmt = '£###,###,##0.00';
    row.findCell(17).numFmt = '#0.00"%"';
  }

  setCellFormatForSettledClaim(row: ExcelProper.Row) {
    row.findCell(3).numFmt = '#0.00"%";'
    row.findCell(4).numFmt = '£###,###,##0.00';
    row.findCell(5).numFmt = '£###,###,##0.00';
    row.findCell(6).numFmt = '£###,###,##0.00';
    row.findCell(7).numFmt = '£###,###,##0.00';
    row.findCell(8).numFmt = '£###,###,##0.00';
    row.findCell(9).numFmt = '£###,###,##0.00';
    row.findCell(10).numFmt = '£###,###,##0.00';
    row.findCell(11).numFmt = '£###,###,##0.00';
    row.findCell(12).numFmt = '£###,###,##0.00';
    row.findCell(13).numFmt = '£###,###,##0.00';
    row.findCell(14).numFmt = '£###,###,##0.00';
    row.findCell(15).numFmt = '£###,###,##0.00';
    row.findCell(16).numFmt = '#0.00"%"';
  }

  setCellFormatOfListOfSettledClaims(row: ExcelProper.Row) {
    row.findCell(4).numFmt = '£###,###,##0.00';
    row.findCell(5).numFmt = '£###,###,##0.00';
    row.findCell(6).numFmt = '£###,###,##0.00';
    row.findCell(7).numFmt = '£###,###,##0.00';
    row.findCell(8).numFmt = '£###,###,##0.00';
    row.findCell(9).numFmt = '£###,###,##0.00';
    row.findCell(10).numFmt = '£###,###,##0.00';
    row.findCell(11).numFmt = '£###,###,##0.00';
    row.findCell(12).numFmt = '£###,###,##0.00';
    row.findCell(13).numFmt = '£###,###,##0.00';
    row.findCell(14).numFmt = '£###,###,##0.00';
    row.findCell(15).numFmt = '£###,###,##0.00';
    row.findCell(16).numFmt = '£###,###,##0.00';
    row.findCell(17).numFmt = '£###,###,##0.00';
    row.findCell(18).numFmt = '£###,###,##0.00';
    row.findCell(19).numFmt = '£###,###,##0.00';
    row.findCell(20).numFmt = '£###,###,##0.00';
    row.findCell(21).numFmt = '£###,###,##0.00';
    row.findCell(22).numFmt = '£###,###,##0.00';
    row.findCell(23).numFmt = '£###,###,##0.00';
    row.findCell(24).numFmt = '£###,###,##0.00';
    row.findCell(25).numFmt = '£###,###,##0.00';
  }

  setCellFormatOfListOfOpenClaims(row: ExcelProper.Row) {
    row.findCell(4).numFmt = '£###,###,##0.00';
    row.findCell(5).numFmt = '£###,###,##0.00';
    row.findCell(6).numFmt = '£###,###,##0.00';
    row.findCell(7).numFmt = '£###,###,##0.00';
    row.findCell(8).numFmt = '£###,###,##0.00';
   /* row.findCell(9).numFmt = '£###,###,##0.00';*/
    row.findCell(10).numFmt = '£###,###,##0.00';
    row.findCell(11).numFmt = '£###,###,##0.00';
    row.findCell(12).numFmt = '£###,###,##0.00';
    row.findCell(13).numFmt = '£###,###,##0.00';
    row.findCell(14).numFmt = '£###,###,##0.00';
    row.findCell(15).numFmt = '£###,###,##0.00';
    row.findCell(16).numFmt = '£###,###,##0.00';
    row.findCell(17).numFmt = '£###,###,##0.00';
    row.findCell(18).numFmt = '£###,###,##0.00';
    row.findCell(19).numFmt = '£###,###,##0.00';
    row.findCell(20).numFmt = '£###,###,##0.00';
    row.findCell(21).numFmt = '£###,###,##0.00';
    row.findCell(22).numFmt = '£###,###,##0.00';
    row.findCell(23).numFmt = '£###,###,##0.00';
    row.findCell(24).numFmt = '£###,###,##0.00';
    row.findCell(25).numFmt = '£###,###,##0.00';
  }

}

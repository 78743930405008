import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { enumYesNo } from '../../../shared/models/common.enum';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SecurityService } from '../../../shared/services/security.service';
import { ReinsuranceWorkflow } from '../../../models/reinsurance-workflow.model';
import { ReinsuranceWorkflowService } from './reinsurance-workflow.service';

@Component({
  selector: 'app-reinsurance-workflow',
  templateUrl: './reinsurance-workflow.component.html',
  styleUrls: ['./reinsurance-workflow.component.css']
})
export class ReinsuranceWorkflowComponent implements OnInit {
  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  claimStatusLookup: any[];
  claimStatusIds: any[];
  notificationStatusLookup: any[];
  notificationStatusIds: any[];
  yesNoEnum = enumYesNo;
  yesNokeys: any[];
  reinsurersData: any[];
  isValid: boolean = true;
  isMMIUser: boolean = false;

  public ReinsuranceWorkflowModel: ReinsuranceWorkflow = new ReinsuranceWorkflow();

  constructor(private lookupService: LookupService,
    private notificationService: NotificationService,
    private securityService: SecurityService,
    private reinsuranceWorkflowService: ReinsuranceWorkflowService) { }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 3,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  notificationDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  ngOnInit(): void {
    this.InitializeData();
  }

  InitializeData() {
    this.initializeForm();
    this.getAllReinsurers();
    this.claimStatusLookup = this.lookupService.getLookupsByTypeId(3);
    this.claimStatusLookup.unshift({ "id": 0, "text": "All" });
    this.notificationStatusLookup = this.lookupService.getLookupsByTypeId(57).filter(x => { return x.id != 1306 && x.id != 1309 });
    this.notificationStatusLookup.unshift({ "id": 0, "text": "All" });
    this.yesNokeys = Object.values(this.yesNoEnum).filter(Number);
  };

  initializeForm() {
    this.reinsuranceWorkflowService.formDataInput = {
      claimStatus: [{ "id": 0, "text": "All" }],
      claimStatusValues: [],
      notificationStatus: [{ "id": 0, "text": "All" }],
      notificationStatusValues: [],
      recoverableMovementValue: 0,
      reinsurerId: 0,
      calculationOverrideValue: 0,
      recoverableMovement: null,
      calculationOverride: null
    }
  }

  getAllReinsurers() {
    this.reinsuranceWorkflowService.getAllReinsurers().subscribe(res => {
      if (res) {
        this.reinsurersData = res;
      }
    },
      error => {
        this.notificationService.printErrorMessage(error);
      }
    );
  }


  generateReinsuranceWorkflowReport() {
    if (this.validateForm()) {
      this.reinsuranceWorkflowService.formDataInput.claimStatusValues
        = this.reinsuranceWorkflowService.formDataInput.claimStatus.map(x => x.id);

      this.reinsuranceWorkflowService.formDataInput.notificationStatusValues
        = this.reinsuranceWorkflowService.formDataInput.notificationStatus.map(x => x.id);
      this.reinsuranceWorkflowService.getReinsuranceWorkflowReport();

      let calculationOverrideValue = this.reinsuranceWorkflowService.formDataInput.calculationOverrideValue;
      this.reinsuranceWorkflowService.formDataInput.calculationOverride
        = calculationOverrideValue == 0 ? null : calculationOverrideValue == 1 ? true : false;

      let recoverableMovementValue = this.reinsuranceWorkflowService.formDataInput.recoverableMovementValue;
      this.reinsuranceWorkflowService.formDataInput.recoverableMovement
        = recoverableMovementValue == 0 ? null : recoverableMovementValue == 1 ? true : false;

    } else {
      this.notificationService.printWarningMessage("Please select all mandatory fields");
    }
  }

  validateForm(): boolean {
    this.isValid = true;
    if (this.reinsuranceWorkflowService.formDataInput.claimStatus == null
      || this.reinsuranceWorkflowService.formDataInput.claimStatus.length == 0
      || this.reinsuranceWorkflowService.formDataInput.notificationStatus == null
      || this.reinsuranceWorkflowService.formDataInput.notificationStatus.length == 0
      || this.reinsuranceWorkflowService.formDataInput.recoverableMovementValue == null
      || this.reinsuranceWorkflowService.formDataInput.reinsurerId == null
      || this.reinsuranceWorkflowService.formDataInput.calculationOverrideValue == 0) {
      this.isValid = false;
      return false;
    }
    return this.isValid;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { NavMenuService } from '../nav-menu/nav-menu.service';
import { SecurityService } from '../../../shared/services/security.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  isUserLoggedIn = false;
  policyCoverSheetURL: string = "";
  claimsHandlingGuidelinesURL: string = "";
  userName: string = "";
  handlingOrganisationId: any;
  isSuperUser: any;
  claimHandlerId: any;
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(private navMenuService: NavMenuService,
    private _router: Router, public lookUpService: LookupService, private notificationService: NotificationService, private securityService: SecurityService,
    private activeRoute: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.securityService.userDataLoaded$.subscribe(res => {
     
      this.isUserLoggedIn = true;
      this.navMenuService.getDMSURL().subscribe(response => {
        if (response) {
          this.policyCoverSheetURL = response.webUrl + '/' + response.documentLibraryName + '/Policy Cover Sheets';
          this.claimsHandlingGuidelinesURL = response.webUrl + '/' + response.documentLibraryName + '/Claims Handling Guidelines';
        }
      })
      if (this.securityService.getUserData().claimsHandlers != null && this.securityService.getUserData().claimsHandlers != '') {
        this.userName = this.securityService.getUserData().claimsHandlers;
      } else if (this.securityService.getUserData().email != null && this.securityService.getUserData().email != '') {
        this.userName = this.securityService.getUserData().email;
      }
      else {
        this.userName = "";
      }
      this.handlingOrganisationId = this.securityService.getUserData().organisationId;
      this.isSuperUser = this.securityService.getUserData().superUser;
      this.claimHandlerId = this.securityService.getUserData().id;
     
    });
    //if (this.policyCoverSheetURL.length > 0) {
    //  this.policyCoverSheetURL = this.policyCoverSheetURL +'/Policy Cover Sheets'
    //}
  }

  Logout() {
    this.securityService.logout();
    this.router.navigate(['/log-out']);
  }

  GoToMyHandlingOrganisation(): void
  {
   
    if (this.isSuperUser==0) {

      this.router.navigate(['/handling-organisation/edit/' + this.handlingOrganisationId], { queryParams: { isSuperUser: false } });
      //this.router.navigate(['/handling-organisation/edit/' + data.participantId], { queryParams: { isSearchFlag: true } });
      
    }
    else {
      this.router.navigate(['/handling-organisation/edit/' + this.handlingOrganisationId]);
    }
  }

  GoToMyProfile() {
    if (this.isSuperUser == 0) {
      this.router.navigate(['/my-profile/' + this.claimHandlerId], {queryParams: { isSuperUser: false } });
    } else {
      this.router.navigate(['/my-profile/' + this.claimHandlerId]);
    }
  }

  GoToMyColleagues() {
    this.router.navigate(['/claim-handler'], { queryParams: { organisationid: this.handlingOrganisationId,isColleaguesSearch:true } });
  }
}

export class UniqueExcessArrangement {
  PolicyNumber: string;
  Policyholder: string;
  IsExposurePeriod: Number;
  ClaimType: string;
  Location: string;
  Message: string;

  constructor(
    policyNumber: string,
    policyholder: string,
    isExposurePeriod: Number,
    claimType: string,
    location: string,
    message: string
  ) {
    this.PolicyNumber = policyNumber;
    this.Policyholder = policyholder;
    this.IsExposurePeriod = isExposurePeriod;
    this.ClaimType = claimType;
    this.Location = location;
    this.Message = message;
  }
}

export class UniqueExcessArrangements {
  static UniqueExcessArrangement: UniqueExcessArrangement[] =
    [
      new UniqueExcessArrangement(
        "03G0360023",
        "Leeds City Council",
        1,
        "",
        "",
        "A unique agreement has been reached with the Policyholder which determines how payment entries will be calculated. Please see the Policy Cover Sheet for Leeds City Council and ensure your payment entry meets the requirements outlined under the heading IMPORTANT INFORMATION."
      ),
      new UniqueExcessArrangement(
        "03G0360053",
        "Leeds City Council",
        1,
        "",
        "",
        "A unique agreement has been reached with the Policyholder which determines how payment entries will be calculated. Please see the Policy Cover Sheet for Leeds City Council and ensure your payment entry meets the requirements outlined under the heading IMPORTANT INFORMATION."
      ),
      new UniqueExcessArrangement(
        "03G0360023",
        "Leeds City Council",
        2,
        "",
        "",
        "A unique agreement has been reached with the Policyholder which determines how payment entries will be calculated. Please see the Policy Cover Sheet for Leeds City Council and ensure your payment entry meets the requirements outlined under the heading IMPORTANT INFORMATION."
      ),
      new UniqueExcessArrangement(
        "03G0360053",
        "Leeds City Council",
        2,
        "",
        "",
        "A unique agreement has been reached with the Policyholder which determines how payment entries will be calculated. Please see the Policy Cover Sheet for Leeds City Council and ensure your payment entry meets the requirements outlined under the heading IMPORTANT INFORMATION."
      ),
      new UniqueExcessArrangement(
        "07A0010013",
        "Lancashire County Council",
        1,
        "",
        "",
        "A unique agreement has been reached with the Policyholder which determines how payment entries will be calculated. Please see the Policy Cover Sheet for Lancashire County Council and ensure your payment entry meets the requirements outlined under the heading IMPORTANT INFORMATION."
      ),
      new UniqueExcessArrangement(
        "02A0020013",
        "Hereford & Worcester C C",
        0,
        "Abuse",
        "Rhydd Court School",
        "A unique agreement has been reached with the Policyholder which determines how payment entries will be calculated. Please see the Policy Cover Sheet for Hereford & Worcester County Council and ensure your payment entry meets the requirements outlined under the heading IMPORTANT INFORMATION."
      ),
    ]
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimExternalAssignmentService {
  private claimUrl: string = '/api/claimassignment/';

  constructor(private service: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.claimUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.claimUrl;
  }

  getClaim(data): Observable<any> {
    //let url = this.claimUrl;
    //return this.http.get(this.claimUrl + "GetClaim/" + claimId + "/" + policyId);
    let body = JSON.stringify(data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }

    let url = this.claimUrl + "getClaimInfo"
    return this.http.post<any>(url, body, options);
  }

  claimAssignment(data): Observable<any> {

    let body = JSON.stringify(data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }

    //console.log(body);

    let url = this.claimUrl;

    return this.http.post<any>(url, body, options);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSetupComponent } from './data-setup/data-setup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../shared/shared.module';
import { EmailSetupComponent } from './email-setup/email-setup.component';
import { BroadcastMessageComponent } from './broadcast-message/broadcast-message.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';



@NgModule({
  declarations: [DataSetupComponent, EmailSetupComponent, BroadcastMessageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: []
})
export class AdministrationModule { }

import { Injectable } from '@angular/core';
import { Apportionment } from './apportionment.model';
import { ApportionmentPolicy } from './apportionment-policy.model';
import { ApportionmentService } from '../../../shared/services/apportionment.service';
import { Observable } from 'rxjs';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";

@Injectable({
  providedIn: 'root'
})
export class CalculateApportionmentService {
  formData: Apportionment;

  constructor(private apportionmentService: ApportionmentService) { }


  calculateApportionment(): any {
    var body = {
      ...this.formData

    };
    return this.apportionmentService.calculateApportionment(body);
  }

  saveApportionment(): any {
    var body = {
      ...this.formData

    };
    if (this.formData.id > 0) {
      return this.apportionmentService.updateApportionment(body);
    }
    else {
    } return this.apportionmentService.addApportionment(body);
  }

  getClaimPoliciesByClaimId(id: number): any {
    return this.apportionmentService.getClaimPoliciesByClaimId(id);
  }
  getApportionment(claimId: number): any {
    return this.apportionmentService.getApportionment(claimId);
  }
  deleteApportionment(): any {
    var body = {
      ...this.formData
    };
    return this.apportionmentService.deleteApportionment(body);
  }
  //updateApportionment(): any {
  //  var body = {
  //    ...this.formData
  //  };
  //  return this.apportionmentService.updateApportionment(body);
  //}
  //addApportionment(): any{
  //  var body = {
  //    ...this.formData
  //  };
  //  return this.apportionmentService.addApportionment(body);
  //}

  downloadLOIDetails(claimId) {

    this.apportionmentService.getClaimLOIs(claimId).subscribe((response: any) => {
      const title = 'LOI Details of Claim ' + response.claimReference;
      const header = ["Policy ID", "Policy Number", "Exposure From", "Exposure To", "LOI From", "	LOI To", "LOI Amount (£)", "LTA", "LTA From", "LTA To", "Policy Days", "Exposure Days", "Share Percentage", "Share of Incurred (£)", "Available LOI (£)"]
      const exposureHeader = ["Period", "MMI Exposure From", "MMI Exposure To", "Policy Number"];
      let data: any[] = [];
      let exposureData: any[] = [];
      response.loiCalculations.forEach(item => {
        data.push([item["policyId"]
          , item["policyNumber"]
          , moment(item["exposureFrom"]).format('DD/MM/YYYY')
          , moment(item["exposureTo"]).format('DD/MM/YYYY')
          , moment(item["loiFrom"]).format('DD/MM/YYYY')
          , moment(item["loiTo"]).format('DD/MM/YYYY')
          , "£" + item["loiAmount"]
          , item["lta"] ? "Yes" : "No"
          , item["lta"] ? moment(item["ltaFrom"]).format('DD/MM/YYYY') : ""
          , item["lta"] ? moment(item["ltaTo"]).format('DD/MM/YYYY') : ""
          , item["policyLOIDays"]
          , item["exposureDays"]
          , item["sharePolicyPercentage"] + "%"
          , item["shareOfIncurred"]
          , item["availableLOI"]]);
      });

      response.policyExposures.forEach((item, index) => {
        exposureData.push([index + 1
          , moment(item["exposureFrom"]).format('DD/MM/YYYY')
          , moment(item["exposureTo"]).format('DD/MM/YYYY')
          , item["policyNumber"]]);
      });
      let availableLOI = _.sumBy(response.loiCalculations, (o: any) => { return o.availableLOI });
      //Create workbook and worksheet
      let workbook: ExcelProper.Workbook = new Excel.Workbook();
      let worksheet = workbook.addWorksheet('LOI Details');

      //Add Row and formatting
      let titleRow = worksheet.addRow([title]);
      titleRow.font = { bold: true, size: 16 };
      titleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });
      worksheet.mergeCells('A1:R2');
      //Blank Row 
      worksheet.addRow([]);

      let summaryRow1 = worksheet.addRow(["Claim Id", response.claimId]);
      this.fillBackGroundInExcellCell(summaryRow1.findCell(1));
      summaryRow1.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow1.findCell(1));
      this.setBorderExcelCell(summaryRow1.findCell(2));
      let summaryRow2 = worksheet.addRow(["Claim Reference", response.claimReference]);
      this.fillBackGroundInExcellCell(summaryRow2.findCell(1));
      summaryRow2.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow2.findCell(1));
      this.setBorderExcelCell(summaryRow2.findCell(2));
      let summaryRow3 = worksheet.addRow(["Claimant Name", response.claimantName]);
      this.fillBackGroundInExcellCell(summaryRow3.findCell(1));
      summaryRow3.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow3.findCell(1));
      this.setBorderExcelCell(summaryRow3.findCell(2));

      worksheet.addRow([]);

      let summaryRow4 = worksheet.addRow(["MMI Valuation", "£" + response.mmiValuation.toFixed(2)]);
      this.fillBackGroundInExcellCell(summaryRow4.findCell(1));
      summaryRow4.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow4.findCell(1));
      this.setBorderExcelCell(summaryRow4.findCell(2));
      let summaryRow5 = worksheet.addRow(["Excess Paid", "£" + response.excessPaid.toFixed(2)]);
      this.fillBackGroundInExcellCell(summaryRow5.findCell(1));
      summaryRow5.findCell(1).font = { bold: true, italic: true };
      let summaryRow12 = worksheet.addRow(["MMI Unapproved Payment", "£" + response.mmiRequested.toFixed(2)]);
      this.fillBackGroundInExcellCell(summaryRow12.findCell(1));
      summaryRow12.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow12.findCell(1));
      this.setBorderExcelCell(summaryRow12.findCell(2));
      let summaryRow6 = worksheet.addRow(["MMI Paid", "£" + response.netPaid.toFixed(2)]);
      this.fillBackGroundInExcellCell(summaryRow6.findCell(1));
      summaryRow6.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow6.findCell(1));
      this.setBorderExcelCell(summaryRow6.findCell(2));
      let summaryRow7 = worksheet.addRow(["MMI Reserve", "£" + response.outstandingAmount.toFixed(2)]);
      this.fillBackGroundInExcellCell(summaryRow7.findCell(1));
      summaryRow7.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow7.findCell(1));
      this.setBorderExcelCell(summaryRow7.findCell(2));
      let summaryRow8 = worksheet.addRow(["MMI Incurred", "£" + response.totalIncurred.toFixed(2)]);
      this.fillBackGroundInExcellCell(summaryRow8.findCell(1));
      summaryRow8.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow8.findCell(1));
      this.setBorderExcelCell(summaryRow8.findCell(2));
      let summaryRow9 = worksheet.addRow(["Available LOI", "£" + availableLOI.toFixed(2)]);
      this.fillBackGroundInExcellCell(summaryRow9.findCell(1));
      summaryRow9.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow9.findCell(1));
      this.setBorderExcelCell(summaryRow9.findCell(2));
      let summaryRow10 = worksheet.addRow(["Utilised LOI", "£" + (response.mmiRequested + response.netPaidExDefenceCost).toFixed(2)]);
      this.fillBackGroundInExcellCell(summaryRow10.findCell(1));
      summaryRow10.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow10.findCell(1));
      this.setBorderExcelCell(summaryRow10.findCell(2));
      let summaryRow11 = worksheet.addRow(["Remaining LOI", "£" + (availableLOI - (response.mmiRequested + response.netPaidExDefenceCost)).toFixed(2)]);
      this.fillBackGroundInExcellCell(summaryRow11.findCell(1));
      summaryRow11.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow11.findCell(1));
      this.setBorderExcelCell(summaryRow11.findCell(2));
      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Policy Exposures
      let exposureTitleRow = worksheet.addRow(["MMI Apportionment Summary"]);
      exposureTitleRow.font = { bold: true };
      exposureTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      worksheet.mergeCells('A19:D19');

      let exposureHeaderRow = worksheet.addRow(exposureHeader);
      // Cell Style : Fill and Border
      exposureHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      exposureHeaderRow.font = { bold: true };
      exposureData.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
      });
      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);
      //Add Header Row
      let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      })
      headerRow.font = { bold: true };
      //Add Data Rows
      data.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
      });

      let totalExposureDays = _.sumBy(response.loiCalculations, (o: any) => { return o.exposureDays });
      let sharePolicyPercentage = _.sumBy(response.loiCalculations, (o: any) => { return o.sharePolicyPercentage });
      let shareOfIncurred = _.sumBy(response.loiCalculations, (o: any) => { return o.shareOfIncurred });

      let utilisedLOI = _.sumBy(response.loiCalculations, (o: any) => { return o.utilisedLOI });
      let footer = ['Total', '', '', '', '', '', '', '', '', '', '',
        totalExposureDays,
        "100%",
        response.totalIncurred,
        availableLOI.toFixed(2)
      ]
      //Add Footer Row
      let footerRow = worksheet.addRow(footer);
      footerRow.font = { bold: true };
      // Cell Style : Fill and Border
      footerRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });


      for (var i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 25;
      }

      //Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'LOIDetails-Claim-' + claimId + '_' + Math.random() + '.xlsx');
      })
    });

  }
  getExcessDetailsForValuation(claimId) {    
    return this.apportionmentService.getClaimExcesses(claimId);    
  }
  downloadExcessDetails(claimId, excessType) {

    this.apportionmentService.getClaimExcesses(claimId).subscribe((response: any) => {
      const title = 'Excess/Deductible Details of Claim ' + response.claimReference;

      const proRataHeader = ["Policy ID", "Policy Number", "Exposure From", "Exposure To", "Excess From", "	Excess To", "Excess Amount (£)", "LTA", "LTA From", "LTA To", "Policy Days", "Exposure Days", "Share Percentage", "Share of Incurred (£)", "Available PRO RATA EXCESS (£)", "Utilised PRO RATA EXCESS (£)"];
      const equitableHeader = ["Policy ID", "Policy Number", "Exposure From", "Exposure To", "Excess From", "	Excess To", "Excess Amount (£)", "LTA", "LTA From", "LTA To", "Policy Days", "Exposure Days", "Share Percentage", "Share of Incurred (£)", "Available EQUITABLE EXCESS (£)", "Utilised EQUITABLE EXCESS (£)"];
      var header = excessType == 1 ? proRataHeader : equitableHeader;
      const exposureHeader = ["Period", "MMI Exposure From", "MMI Exposure To", "Policy Number"];
      let data: any[] = [];
      let exposureData: any[] = [];
      response.excessCalculations.forEach(item => {
        data.push([item["policyId"]
          , item["policyNumber"]
          , moment(item["exposureFrom"]).format('DD/MM/YYYY')
          , moment(item["exposureTo"]).format('DD/MM/YYYY')
          , moment(item["excessFrom"]).format('DD/MM/YYYY')
          , moment(item["excessTo"]).format('DD/MM/YYYY')
          , item["excessAmount"]
          , item["lta"] ? "Yes" : "No"
          , item["lta"] ? moment(item["ltaFrom"]).format('DD/MM/YYYY') : ""
          , item["lta"] ? moment(item["ltaTo"]).format('DD/MM/YYYY') : ""
          , item["policyExcessDays"]
          , item["exposureDays"]
          , item["sharePolicyPercentage"] + "%"
          , item["shareOfIncurred"]
          , (excessType == 1 ? item["availableProRataExcess"] : item["availableEquitableExcess"])
          , (excessType == 1 ? item["utilisedProRataExcess"] : item["utilisedEquitableExcess"])]);
      });

      response.policyExposures.forEach((item, index) => {
        exposureData.push([index + 1
          , moment(item["exposureFrom"]).format('DD/MM/YYYY')
          , moment(item["exposureTo"]).format('DD/MM/YYYY')
          , item["policyNumber"]]);
      });

      //Create workbook and worksheet
      let workbook: ExcelProper.Workbook = new Excel.Workbook();
      let worksheet = workbook.addWorksheet('Excess Details');

      //Add Row and formatting
      let titleRow = worksheet.addRow([title]);
      titleRow.font = { bold: true, size: 16 };
      titleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });
      worksheet.mergeCells('A1:P2');
      //Blank Row 
      worksheet.addRow([]);

      let summaryRow1 = worksheet.addRow(["Claim Id", response.claimId]);
      this.fillBackGroundInExcellCell(summaryRow1.findCell(1));
      summaryRow1.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow1.findCell(1));
      this.setBorderExcelCell(summaryRow1.findCell(2));
      let summaryRow2 = worksheet.addRow(["Claim Reference", response.claimReference]);
      this.fillBackGroundInExcellCell(summaryRow2.findCell(1));
      summaryRow2.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow2.findCell(1));
      this.setBorderExcelCell(summaryRow2.findCell(2));
      let summaryRow3 = worksheet.addRow(["Claimant Name", response.claimantName]);
      this.fillBackGroundInExcellCell(summaryRow3.findCell(1));
      summaryRow3.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow3.findCell(1));
      this.setBorderExcelCell(summaryRow3.findCell(2));

      worksheet.addRow([]);

      let summaryRow4 = worksheet.addRow(["MMI Valuation", "£ " + response.mmiValuation]);
      this.fillBackGroundInExcellCell(summaryRow4.findCell(1));
      summaryRow4.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow4.findCell(1));
      this.setBorderExcelCell(summaryRow4.findCell(2));
      let summaryRow5 = worksheet.addRow(["Excess Paid", "£ " + response.excessPaid]);
      this.fillBackGroundInExcellCell(summaryRow5.findCell(1));
      summaryRow5.findCell(1).font = { bold: true, italic: true };
      let summaryRow6 = worksheet.addRow(["MMI Paid", "£ " + response.netPaid]);
      this.fillBackGroundInExcellCell(summaryRow6.findCell(1));
      summaryRow6.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow6.findCell(1));
      this.setBorderExcelCell(summaryRow6.findCell(2));
      let summaryRow7 = worksheet.addRow(["MMI Reserve", "£ " + response.outstandingAmount]);
      this.fillBackGroundInExcellCell(summaryRow7.findCell(1));
      summaryRow7.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow7.findCell(1));
      this.setBorderExcelCell(summaryRow7.findCell(2));
      let summaryRow8 = worksheet.addRow(["MMI Incurred", "£ " + response.totalIncurred]);
      this.fillBackGroundInExcellCell(summaryRow8.findCell(1));
      summaryRow8.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow8.findCell(1));
      this.setBorderExcelCell(summaryRow8.findCell(2));
      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Policy Exposures
      let exposureTitleRow = worksheet.addRow(["MMI Apportionment Summary"]);
      exposureTitleRow.font = { bold: true };
      exposureTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      worksheet.mergeCells('A15:D15');

      let exposureHeaderRow = worksheet.addRow(exposureHeader);
      // Cell Style : Fill and Border
      exposureHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      exposureHeaderRow.font = { bold: true };
      exposureData.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
      });

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Header Row
      let headerRow = worksheet.addRow(header);
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      })
      headerRow.font = { bold: true };
      //Add Data Rows
      data.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
      });

      let totalExposureDays = _.sumBy(response.excessCalculations, (o: any) => { return o.exposureDays });
      let sharePolicyPercentage = _.sumBy(response.excessCalculations, (o: any) => { return o.sharePolicyPercentage });
      let shareOfIncurred = _.sumBy(response.excessCalculations, (o: any) => { return o.shareOfIncurred });
      let availableProRataExcess = _.sumBy(response.excessCalculations, (o: any) => { return o.availableProRataExcess });
      let availableEquitableExcess = _.sumBy(response.excessCalculations, (o: any) => { return o.availableEquitableExcess });
      let avaiableExcess = excessType == 1 ? availableProRataExcess.toFixed(2) : availableEquitableExcess.toFixed(2);
      let utilisedProRataExcess = _.sumBy(response.excessCalculations, (o: any) => { return o.utilisedProRataExcess });
      let utilisedEquitableExcess = _.sumBy(response.excessCalculations, (o: any) => { return o.utilisedEquitableExcess });
      let utilisedExcess = excessType == 1 ? utilisedProRataExcess.toFixed(2) : utilisedEquitableExcess.toFixed(2);
      let footer = ['Total', '', '', '', '', '', '', '', '', '', '',
        totalExposureDays,
        "100%",
        response.mmiValuation,
        avaiableExcess,
        utilisedExcess
      ]
      //Add Footer Row
      let footerRow = worksheet.addRow(footer);
      footerRow.font = { bold: true };
      // Cell Style : Fill and Border
      footerRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });


      for (var i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 25;
      }

      //Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'ExcessDetail-Claim-' + claimId + '_' + Math.random() + '.xlsx');
      })
    });

  }
  setBorderExcelCell(cell: any) {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
  }

  fillBackGroundInExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00C4C4C4' },
      bgColor: { argb: 'FF0000FF' }
    }
  }


  checkHandlingAuthorityAndReserveValidation() {
    var body = {
      ...this.formData

    };
    return this.apportionmentService.checkHandlingAuthorityAndReserveValidation(body);
  }

  checkMMIReserveValue() {
    var body = {
      ...this.formData

    };
    return this.apportionmentService.checkMMIReserveValue(body);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { enumYesNo } from '../../../shared/models/common.enum';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SecurityService } from '../../../shared/services/security.service';
import { ReinsuranceListing } from '../../../models/reinsurance-listing.model';
import { ReinsuranceListingService } from './reinsurance-listing.service';
import { BaseComponentComponent } from '../../base-component/base-component.component';

@Component({
  selector: 'app-reinsurance-listing',
  templateUrl: './reinsurance-listing.component.html',
  styleUrls: ['./reinsurance-listing.component.css']
})
export class ReinsuranceListingComponent extends BaseComponentComponent implements OnInit {
  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  claimStatusLookup: any[];
  claimStatusIds: any[];
  reinsurerStatusLookup: any[];
  reinsurerStatusIds: any[];
  claimTypeLookup: any[];
  claimTypeIds: any[];
  lineOfBusinessLookup: any[];
  yesNoEnum = enumYesNo;
  yesNokeys: any[];
  isValid: boolean = true;
  isMMIUser: boolean = false;
  isDisabledRIStatus: boolean = true;

  public ReinsuranceListingModel: ReinsuranceListing = new ReinsuranceListing();
  constructor(private lookupService: LookupService,
    private notificationService: NotificationService,
    securityService: SecurityService,
    private reinsuranceListingService: ReinsuranceListingService) {
    super(securityService, 1402);
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 3,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  riStatusDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  ngOnInit(): void {
    this.InitializeData();
  }

  InitializeData() {
    this.initializeForm();
    this.getAndSetClaimStatusLookupValues();
    this.getAndSetClaimTypeLookupValues();
    this.getAndSetLOBLookupValues();
    this.claimTypeLookup.unshift({ "id": 0, "text": "All" });
    this.claimStatusLookup.unshift({ "id": 0, "text": "All" });
    //this.reinsurerStatusLookup = this.lookupService.getLookupsByTypeId(50);
    //this.reinsurerStatusLookup.unshift({ "id": 0, "text": "All" });
    this.yesNokeys = Object.values(this.yesNoEnum).filter(Number);
  };

  initializeForm() {
    this.reinsuranceListingService.formDataInput = {
      claimStatus: [{ "id": 0, "text": "All" }],
      claimStatusValues: [],
      reinsurerStatus: [],
      reinsurerStatusValues: [],
      lineOfBusiness: 0,
      claimType: [{ "id": 0, "text": "All" }],
      claimTypeValues: [],
      aggregatedClaims: 0
    }
  }

  getAndSetClaimTypeLookupValues() {
    this.claimTypeLookup = [];
    var claimTypes = this.lookupService.getLookupsByTypeId(1);
    for (var i = 0; i < claimTypes.length; i++) {
      if (claimTypes[i].alternateText != '' && claimTypes[i].alternateText != null) {
        let keyValue = { id: claimTypes[i].id, text: claimTypes[i].alternateText };
        this.claimTypeLookup.push(keyValue);
      }
    }
  }

  getAndSetClaimStatusLookupValues() {
    this.claimStatusLookup = this.lookupService.getLookupsByTypeId(3);
    this.claimStatusLookup = this.claimStatusLookup.filter(result => {
      return result.text == 'Open' || result.text == 'Settled';
    })
  }

  getAndSetLOBLookupValues() {
    this.lineOfBusinessLookup = this.lookupService.getLookupsByTypeId(12);
    this.lineOfBusinessLookup = this.lineOfBusinessLookup.filter(result => {
      return result.text == 'EL' || result.text == 'TP';
    })
  }

  generateReinsuranceListingReport() {
    if (this.validateForm()) {
      this.reinsuranceListingService.formDataInput.claimStatusValues
        = this.reinsuranceListingService.formDataInput.claimStatus.map(x => x.id);

      this.getAndSetClaimStatusValueSearch();

      this.reinsuranceListingService.formDataInput.reinsurerStatusValues
        = this.reinsuranceListingService.formDataInput.reinsurerStatus.map(x => x.id);

      this.getAndSetReinsurerStatusValueSearch();

      this.reinsuranceListingService.formDataInput.claimTypeValues
        = this.reinsuranceListingService.formDataInput.claimType.map(x => x.id);

      this.manageFieldSelections();

      this.reinsuranceListingService.getReinsuranceListingReport();

    } else {
      this.notificationService.printWarningMessage("Please select at least one field.");
    }
  }

  manageFieldSelections() {
    if (this.reinsuranceListingService.formDataInput.claimStatus == null
      || this.reinsuranceListingService.formDataInput.claimStatus.length == 0) {
      this.reinsuranceListingService.formDataInput.claimStatusValues = [0];
    }
    if (this.reinsuranceListingService.formDataInput.reinsurerStatus == null
      || this.reinsuranceListingService.formDataInput.reinsurerStatus.length == 0) {
      this.reinsuranceListingService.formDataInput.reinsurerStatusValues = [0];
    }
    if (this.reinsuranceListingService.formDataInput.claimType == null
      || this.reinsuranceListingService.formDataInput.claimType.length == 0) {
      this.reinsuranceListingService.formDataInput.claimTypeValues = [0];
    }
  }

  validateForm(): boolean {
    this.isValid = true;
    if (
      (this.reinsuranceListingService.formDataInput.claimStatus == null
        || this.reinsuranceListingService.formDataInput.claimStatus.length == 0)
      &&
      (this.reinsuranceListingService.formDataInput.reinsurerStatus == null
        || this.reinsuranceListingService.formDataInput.reinsurerStatus.length == 0)
      &&
      (this.reinsuranceListingService.formDataInput.claimType == null
        || this.reinsuranceListingService.formDataInput.claimType.length == 0)
      &&
      (this.reinsuranceListingService.formDataInput.lineOfBusiness == null
        || this.reinsuranceListingService.formDataInput.lineOfBusiness == 0)) {
      this.isValid = false;
      return false;
    }
    return this.isValid;
  }

  onLOBChange() {
    this.reinsurerStatusLookup = [];
    this.reinsuranceListingService.formDataInput.reinsurerStatus = [];
    this.reinsuranceListingService.formDataInput.reinsurerStatus = [{ "id": 0, "text": "All" }];
    if (this.reinsuranceListingService.formDataInput.lineOfBusiness == 150) {
      this.reinsurerStatusLookup = this.lookupService.getLookupsByTypeId(51);
      this.reinsurerStatusLookup.unshift({ "id": 0, "text": "All" });
      this.isDisabledRIStatus = false;
    }
    else if (this.reinsuranceListingService.formDataInput.lineOfBusiness == 146) {
      this.reinsurerStatusLookup = this.lookupService.getLookupsByTypeId(50);
      this.reinsurerStatusLookup.unshift({ "id": 0, "text": "All" });
      this.isDisabledRIStatus = false;
    }
    else {
      this.reinsuranceListingService.formDataInput.reinsurerStatus = [];
      //this.reinsuranceListingService.formDataInput.reinsurerStatus = [{ "id": 0, "text": "All" }];
      this.isDisabledRIStatus = true;
    }
  }

  getAndSetClaimStatusValueSearch() {
    var claimStatus = this.lookupService.getLookupsByTypeId(3);
    var selectedClaimStatuValues = this.reinsuranceListingService.formDataInput.claimStatusValues;
    if (selectedClaimStatuValues.indexOf(0) != -1) {
      this.reinsuranceListingService.formDataInput.claimStatusValues = [];
      let filteredStatus =
        claimStatus.filter(result => { return result.id == 23 || result.id == 25 || result.id == 24 || result.id == 26 || result.id == 1318 });
      this.reinsuranceListingService.formDataInput.claimStatusValues = filteredStatus.map(x => x.id);
    }
    else if (selectedClaimStatuValues.length == 2) {
      this.reinsuranceListingService.formDataInput.claimStatusValues = [];
      let filteredStatus =
        claimStatus.filter(result => { return result.id == 23 || result.id == 25 || result.id == 24 || result.id == 26 || result.id == 1318 });
      this.reinsuranceListingService.formDataInput.claimStatusValues = filteredStatus.map(x => x.id);
    }
    else if (this.reinsuranceListingService.formDataInput.claimStatusValues.length == 1 &&
      this.reinsuranceListingService.formDataInput.claimStatusValues[0] == 23) {
      this.reinsuranceListingService.formDataInput.claimStatusValues = [];
      let filteredStatus = claimStatus.filter(result => { return result.id == 23 || result.id == 25 });
      this.reinsuranceListingService.formDataInput.claimStatusValues = filteredStatus.map(x => x.id);
    }
    else if (this.reinsuranceListingService.formDataInput.claimStatusValues.length == 1 &&
      this.reinsuranceListingService.formDataInput.claimStatusValues[0] == 24) {
      this.reinsuranceListingService.formDataInput.claimStatusValues = [];
      let filteredStatus = claimStatus.filter(result => { return result.id == 24 || result.id == 26 || result.id == 1318 });
      this.reinsuranceListingService.formDataInput.claimStatusValues = filteredStatus.map(x => x.id);
    }
  }

  getAndSetReinsurerStatusValueSearch() {
    if (this.reinsuranceListingService.formDataInput.lineOfBusiness == 146) {
      let riELStatusValues = this.lookupService.getLookupsByTypeId(50);
      var selectedRIELStatuValues = this.reinsuranceListingService.formDataInput.reinsurerStatusValues;
      if (selectedRIELStatuValues.indexOf(0) != -1) {
        this.reinsuranceListingService.formDataInput.reinsurerStatusValues = [];
        this.reinsuranceListingService.formDataInput.reinsurerStatusValues = riELStatusValues.map(x => x.id);
      }
    }
    else if (this.reinsuranceListingService.formDataInput.lineOfBusiness == 150) {
      let riTPStatusValues = this.lookupService.getLookupsByTypeId(51);
      var selectedRITPStatuValues = this.reinsuranceListingService.formDataInput.reinsurerStatusValues;
      if (selectedRITPStatuValues.indexOf(0) != -1) {
        this.reinsuranceListingService.formDataInput.reinsurerStatusValues = [];
        this.reinsuranceListingService.formDataInput.reinsurerStatusValues = riTPStatusValues.map(x => x.id);
      }
    }
  }

}

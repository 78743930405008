import { Injectable } from '@angular/core';
import { DataService } from "./data.service";
import { ConfigurationService } from "./configuration.service";
import { StorageService } from "./storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { Lookup } from '../../components/administration/data-setup/data-setup.model';

@Injectable({
  providedIn: 'root'
})
export class LookupDatasetupService {
  private lookuptypeUrl: string = '';

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
  //  if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
  //    this.claimHandlerUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.claimHandlerUrl;
  //  else
  //    this.configurationService.settingsLoaded$.subscribe(x => this.claimHandlerUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.claimHandlerUrl);
  } 

  getAllLookupTypes() {
    this.lookuptypeUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + '/api/lookupType';
    return this.dataService.get(this.lookuptypeUrl);
  }

  getLookupsByTypeId(id: number): any {
    let lookupUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + '/api/lookup/' + 'getLookupsByTypeId';
    return this.dataService.get(lookupUrl, { id: id });
  }

  postLookup(lookup: Lookup) {
    let url = this.configurationService.serverSettings.policyClaimsAgentUrl + '/api/lookup/' + "createLookup";
    let body = JSON.stringify(lookup);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }

    return this.http.post<any>(url, lookup, options);    
  }

  putLookup(lookup: Lookup) {

    let url = this.configurationService.serverSettings.policyClaimsAgentUrl + '/api/lookup/' + "updateLookup";
    let body = JSON.stringify(lookup);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }
    return this.http.put<any>(url, lookup, options);
    //  .subscribe(data => {
    //  return data;
    //});
  }

  deleteLookupItem(lookup: Lookup) {
    let url = this.configurationService.serverSettings.policyClaimsAgentUrl + '/api/lookup/' + "deleteLookupItem";
    let body = JSON.stringify(lookup);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }

    return this.http.post<any>(url, lookup, options);    
  }

}

import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewChecked } from '@angular/core';
import { DataSetupService } from './data-setup.service';
import { Lookup } from './data-setup.model';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { DataTableDirective } from 'angular-datatables';
import { NgForm } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { SecurityService } from '../../../shared/services/security.service';
import { BaseComponentComponent } from '../../base-component/base-component.component';

@Component({
  selector: 'app-data-setup',
  templateUrl: './data-setup.component.html',
  styleUrls: ['./data-setup.component.css']
})

export class DataSetupComponent extends BaseComponentComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })  private datatableElement: DataTableDirective;  @ViewChild('datasetupTable') datasetupTable: ElementRef;

  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  errorReceived: boolean;
  public lookupVM: Lookup = new Lookup();

  public lookupResult: any;
  public dtOptions: any = {};
  public authSubscription: Subscription;
  public dtTrigger: Subject<any>;
  public selfComponent: any = this;
  isShown: boolean = false;
  isAddButtonShow: boolean = false;
  id;
  isValid: boolean = true;
  isEditMode: boolean = false;
  isClientEditable: boolean = false;

 // lookupTypeId: number = null;
  public lookupTypes: any[];
  //public lookups: Lookup[] = [];

  displayedColumns: any[] = [
    { title: 'ID', data: 'id' },
    { title: 'Lookup Type', data: 'lookupTypeId' },
    { title: 'Name', data: 'name' },
    { title: 'Display Text', data: 'text' },
    { title: 'Value', data: 'value' },
    { title: 'Display Order', data: 'displayOrder' },
    { title: 'Alternate Text', data: 'alternateText' },
    { title: 'Parent Lookup Value', data: 'parentLookupValueId' },
    { title: 'Aggregated', data: 'booleanValue' },
    { title: 'Enable/ Disable', data: 'isDelete' },
    { title: 'Action', data: null, orderable: false }
  ];
    
  constructor(private router: Router,    private notificationService: NotificationService,
    private dataSetupService: DataSetupService, securityService: SecurityService ) {
    super(securityService, 1142);
    this.dtTrigger = new Subject();
  }

  ngOnInit(): void {
    this.bindAllLookupTypes();
    this.isEditMode = false;
    
    this.initialiseDataTable();
    this.lookupVM.lookupTypeId = null;
  }

  initialiseDataTable(): void {
    //const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,     
      columns: this.displayedColumns,
      'columnDefs': [
        { width: "10%", className: "dt-body-center", targets: 0 },
        { width: "8%", className: "dt-body-center", targets: 1 }, 
        { width: "20%", targets: 2 },
        { width: "20%", targets: 3 },
        { width: "5%", className: "dt-body-center", targets: 4 },
        { width: "5%", className: "dt-body-center", targets: 5 },
        { width: "16%", className: "dt-body-center", targets: 6 },
        { width: "8%", className: "dt-body-center", targets: 7 },
        {
          render: function (data, type, row) {
            return data == 1 ? "Yes" : "No"
          },
          width: "8%", targets: 8
        },
        {
          render: function (data, type, row) {
            return data == 0 ? "Yes" : "No"
          },
          width: "8%", targets: 9
        },
        {
          render: function (data, type, row) {
            return '<button class="btn edit-button"><i class="fa fa-edit"></i></button>';
          },
          width: "8%", targets: 10
        },
      ],
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [{}],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        $('.edit-button', row).unbind('click');
        $('.edit-button', row).bind('click', () => {
          self.filterDataBasedOnEditButton(data);
        });
        //$('.remove-button', row).unbind('click');
        //$('.remove-button', row).bind('click', () => {
        //  self.removeButtonClick(data);
        //});
        return row;
      },
      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        
        this.getLookupItems(this.lookupVM.lookupTypeId).subscribe(response => {
          this.lookupResult = response;
          callback({                       
            data: response
          });
        });
      }
    };

  }

  hideform() {
    let currentlookupTypeId = this.lookupVM.lookupTypeId;
    this.form.resetForm();
    this.LookupItemReset();
    this.lookupVM.lookupTypeId = currentlookupTypeId;
    this.isAddButtonShow = true;
    this.isShown = false;
    this.isEditMode = false;
  }

  LookupItemReset() {
    let lookupVMNew = this.lookupVM
    this.lookupVM = new Lookup();
    this.lookupVM.lookupTypeId = lookupVMNew.lookupTypeId;
   // this.lookupVM.lookupTypeId = null;
    this.lookupVM.isDelete = null;
    if (this.lookupVM.lookupTypeId == 22 || this.lookupVM.lookupTypeId == 29) {
      this.lookupVM.booleanValue = false;
    }
    else {
      this.lookupVM.booleanValue = null;
    }
  }

  removeButtonClick(data) {
    let isdeleted = true;
    data.isDelete = isdeleted;
    this.deleteLookupItem(data);
    this.isEditMode = false;
    this.hideform();
    console.log("Remove button call", data);

  }

  getLookupItems(lookupTypeId) {
    this.errorReceived = false;
    if (lookupTypeId > 0) {
      return this.dataSetupService.getLookupsByTypeId(lookupTypeId);
    }
    return;
  }

  getLookupItemById(lookupTypeId): Promise<any> {
    var promise = new Promise((resolve, reject) => {

      this.dataSetupService.getLookupsByTypeId(lookupTypeId).subscribe(lookup => {
        resolve(lookup);
      })
    })

    return promise;    
  }

  filterDataBasedOnEditButton(userdata) {
    let data = this.lookupResult.find(
      item => {
        return item.id == userdata.id
      }
    )
    this.lookupVM = {
      ID: data.id,
      lookupTypeId: data.lookupTypeId,
      name: data.name,
      text: data.text,
      value: data.value,
      displayOrder: data.displayOrder,
      alternateText: data.alternateText,
      parentLookupValueId: data.parentLookupValueId,
      isDelete: data.isDelete,
      booleanValue: data.booleanValue
    }
    console.log("filterd data", data);
    console.log("filterd data", this.lookupVM);
    this.isEditMode = true;
    this.hideAddButton(false);
  }

  bindAllLookupTypes() {
    this.dataSetupService.getAllLookupTypes().subscribe(response => {
      if (response) {
        this.lookupTypes = response;
      }
    });
  }

  hideAddButton(showHide) {
    //this.LookupItemReset();
    this.isAddButtonShow = showHide;
    this.isShown = true;
    //this.isEditMode = false;
  }

  onLookupTypeChange(): void {

    if (this.lookupVM.lookupTypeId > 0) {
      
      let lookupType = this.lookupTypes.filter(({ id }) => id == this.lookupVM.lookupTypeId);
      if (lookupType != null) {
        this.isClientEditable = lookupType[0].clientEditable;
      }
      if (!this.isClientEditable) {
        this.displayedColumns[9].visible = false;
        this.isAddButtonShow = false;
      }
      else {
        this.displayedColumns[9].visible = true;
        this.isAddButtonShow = true;
      }
      if (this.lookupVM.lookupTypeId == 22 || this.lookupVM.lookupTypeId == 29) {
        this.displayedColumns[8].visible = true;
      }
      else {
        this.displayedColumns[8].visible = false;
      }
      
      var lookup_TypeId = this.lookupVM.lookupTypeId;
      //show Add button if lookupTypeId selected
      
      this.isShown = false;
      this.LookupItemReset();
      this.lookupVM.lookupTypeId = lookup_TypeId;
      if (this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
    }
    else {
      //hide Add button if no LookupTypeId selected
      this.isAddButtonShow = false;
    }
  }
      onSubmit(form: NgForm) {
    console.log('form', form);
    this.saveLookupItem()
  }  saveLookupItem() {
    if (this.lookupVM.ID > 0) {
      this.lookupVM.value = Number(this.lookupVM.value);
      this.lookupVM.displayOrder = Number(this.lookupVM.displayOrder);
      this.putLookupItem(this.lookupVM);
    }
    else {
      this.postLookupItem();
    }
  }

  postLookupItem() {
    console.log("postLookupItem method called");
    this.lookupVM.value = Number(this.lookupVM.value);
    this.lookupVM.displayOrder = Number(this.lookupVM.displayOrder);
    this.dataSetupService.postLookup(this.lookupVM).subscribe(data => {
      this.notificationService.printSuccessMessage('Lookup saved Successfully');
      this.LookupItemReset();
      this.hideform();
      this.form.form.markAsPristine();
      this.onLookupTypeChange();
    },
      error => {        
          this.notificationService.printErrorMessage("Error occured  in saving Lookup");        
      }
    );
  }


  putLookupItem(data) {
    this.dataSetupService.putLookup(data).subscribe(data => {
      this.notificationService.printSuccessMessage('Lookup updated Successfully');
      this.LookupItemReset();
      this.hideform();
      this.onLookupTypeChange();
      this.form.form.markAsPristine();
    },
      error => {       
        this.notificationService.printErrorMessage('Error occured  in updating Lookup');        
      }
    );
  }

  deleteLookupItem(data) {
    this.dataSetupService.deleteLookup(data).subscribe(data => {
      this.notificationService.printSuccessMessage('Lookup deleted Successfully');
      this.onLookupTypeChange();
    },
      error => {
        this.notificationService.printErrorMessage('Error occured  in deleting Lookup');
      }
    );
  }  ngOnDestroy(): void {    // Do not forget to unsubscribe the event    this.dtTrigger.unsubscribe();  }


}

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { AddWorkflowItemService } from './add-workflow-item.service';
import { claimDataModel } from './claim-data.model';
import { addWorkflowDataModel } from './add-workflow-item.model';
import { ClaimComponent } from '../claim.component';
import { enumHandlingOrganization, enumWorkflowPriorityCode, enumWorkflowStatus, enumWorkFlowCategory, enumPaymentRecovery } from '../../../shared/models/common.enum';
import { LookupService } from '../../../shared/services/lookup.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AddHandlingOrganisationService } from '../../add-participant/handlingorganisation/handlingorganisation.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { parse } from 'url';
import { ClaimDetailService } from '../claim-detail/claim-detail.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AddWorkflowNotes } from './add-workflow-notes.model';
import { SecurityService } from '../../../shared/services/security.service';

@Component({
  selector: 'app-add-workflow-item',
  templateUrl: './add-workflow-item.component.html',
  styleUrls: ['./add-workflow-item.component.css']
})
export class AddWorkflowItemComponent implements OnInit {

  @ViewChild('form', null) form: NgForm;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('workItemNotesTable') workItemNotesTableReference: ElementRef;
  public dtOptions: any = {};

  dtTrigger = new Subject();

  claimData: claimDataModel;
  claimId: any = null;
  addWorkflowModel: addWorkflowDataModel
  mmiUserList: any
  priorityCodeEnumKeys: any[];
  priorityCodeEnum = enumWorkflowPriorityCode;
  statusEnumKeys: any[];
  statusEnum = enumWorkflowStatus;
  isValid: boolean = true;
  isEditMode: boolean = false;
  policyId: any;
  workflowId: any;
  title: string = "Add";
  emptyString: string = " ";
  workItemCategoryList: any
  priorityCodes: any;
  isGridLoaded: boolean = false;
  workflowNotesValidation: boolean = true;
  oldPriorityCode: any
  workItemNotes: AddWorkflowNotes
  //disableSuperVisorDropdown: boolean = true;
  showClaimDetails: boolean
  workItemStatus: any[]
  enableClaimSearch: boolean = true;
  disableMMISupervisorDropdown: boolean = true;
  disableWorkItemCategory: boolean
  showLinkDocument: boolean
  hideDeadlineDateField: boolean;
  disableReceivedDate: boolean
  disableControls: boolean;
  isMMISuperUser: boolean = false;
  isMMIUser: boolean = false;
  constructor(private addWorkFlowService: AddWorkflowItemService,
    //@Inject(ClaimComponent) private parent: ClaimComponent,
    private lookupService: LookupService,
    private notificationService: NotificationService,
    private activeRoute: ActivatedRoute,
    private router: Router, private currentRoute: ActivatedRoute, private claimDetailService: ClaimDetailService, private securityService: SecurityService) {

  }

  ngOnInit(): void {
    this.isMMISuperUser = false;
    this.resetForm();
    this.loadLookups();
    this.initializeNotes();
    //this.dtOptions = {
    //  pagingType: 'full_numbers',
    //  pageLength: 10,
    //  searching: false

    //};
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      columnDefs: [{ type: 'datetime-uk', "targets": 0 }],
      order: [[0, 'desc']]
    };
    this.getClaimData();
    this.workflowId = this.activeRoute.snapshot.paramMap.get('workflowItemId');
    if (this.workflowId != null) {
      this.initializeForm(this.workflowId);
    }
    else {
      this.setFields();
    }

    //this.dtTrigger.next();
    //this.refreshGrid();

    this.isMMIUser = false;
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  onSubmit() {
    if (this.validateForm()) {
      if (this.addWorkFlowService.formData.mmiClaimsReference == '' || this.addWorkFlowService.formData.mmiClaimsReference == null) {
        this.clearClaimFields();
        if (this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ExistingClaim) {
          this.addWorkFlowService.formData.workItemCategoryId = enumWorkFlowCategory.NewClaim;
        }
        this.saveWorkItems();
      }
      else {
        this.populateClaimFieldsAndSaveWorkItem();
      }
    }

  }

  saveWorkItems() {
    if (this.workItemNotes.comments != '' && this.workItemNotes.comments != undefined) {
      if (confirm("Comment / Note has been written but not Added. Do you want to save your Comment / Note ?")) {
        this.addWorkItemNotes();
      }
    }
    if (this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ExistingClaim) {
      this.checkDocumentsLinkedWithWorkflowOrNot(this.addWorkFlowService.formData.workflowLinkedDocuments);
    }

    this.addWorkFlowService.saveWorkflowItem().subscribe((response) => {
      if (this.addWorkFlowService.formData.claimId != null) {
        this.router.navigate(['/claims/' + this.policyId + '/' + response.claimId + '/add-workflow-item/edit/' + response.id]);
      }
      else {
        this.router.navigate(['/add-workflow-item/edit/' + response.id]);
      }
      this.addWorkFlowService.formData = response;
      this.setFields();
      this.disableWorkItemCategoryField();
      this.notificationService.printSuccessMessage('Workflow details saved successfully.')
      this.addWorkFlowService.formData.isDocumentDeleted = false;
      this.workItemNotes.comments = "";
      this.refreshGrid();
      this.disableEnableControls();
    });
  }

  loadLookups() {
    this.addWorkFlowService.getClaimHandlersByHandlingOrganisation(enumHandlingOrganization.MMI).subscribe(
      mmiClaimHandler => {
        this.mmiUserList = mmiClaimHandler;
      });
    this.statusEnumKeys = Object.values(this.statusEnum).filter(Number);
    this.workItemCategoryList = this.lookupService.getLookupsByTypeId(59).filter(i => i.id != enumWorkFlowCategory.ExistingClaim && i.id != enumWorkFlowCategory.ChaseForLLA && i.id != enumWorkFlowCategory.PrepareLLA && i.id != enumWorkFlowCategory.ConfirmDAStatus && i.id != enumWorkFlowCategory.ReviewNonDAReferral && i.id != enumWorkFlowCategory.ConfirmLitigationStatus);
    this.workItemStatus = this.lookupService.getLookupsByTypeId(61);
  }

  loadClaimDetails() {
    this.addWorkFlowService.getClaimDetails((this.claimId)).then(res => {
      this.addWorkFlowService.formData.mmiClaimsReference = res.mmiClaimsReference
      this.addWorkFlowService.formData.claimantName = res.claimantDetail.firstName + ' ' + res.claimantDetail.surname;
      this.addWorkFlowService.formData.insuredTitle = res.insuredTitle;
      this.addWorkFlowService.formData.handlingOrganisation = res.handlingOrganisationName;
      this.addWorkFlowService.formData.claimType = this.lookupService.getLookupById(res.claimTypeId)[0].name;
      this.addWorkFlowService.formData.eFileURL = res.eFileURL;
      if (this.addWorkFlowService.formData.id == null || this.addWorkFlowService.formData.id == 0) {
        this.addWorkFlowService.formData.mmiUserAllocated = res.handlingOrganisationId == 1 ? res.claimsHandlerId : res.mmiKeyContact;
      }


    });
  }

  validateForm(): boolean {
    this.isValid = true;
    if (this.addWorkFlowService.formData.mmiUserAllocated == null || this.addWorkFlowService.formData.priorityCodeAssigned == null || this.addWorkFlowService.formData.workflowCompletionStatus == null || this.addWorkFlowService.formData.workflowReceivedDate == null) {
      this.isValid = false;
    }
    if (this.addWorkFlowService.formData.priorityCodeAssigned == enumWorkflowPriorityCode.P6 && this.addWorkFlowService.formData.deadlineDate == null) {
      this.isValid = false;
    }
    if (this.addWorkFlowService.formData.workflowCompletionStatus == enumWorkflowStatus.CompletedStage1 && this.addWorkFlowService.formData.mmiSupervisorAllocated == null) {
      this.isValid = false;
    }
    if (this.addWorkFlowService.formData.priorityCodeAssigned == enumWorkflowPriorityCode.P6 && this.addWorkFlowService.formData.deadlineDate == null) {
      this.isValid = false;
    }
    if (this.getDateOnly(this.addWorkFlowService.formData.deadlineDate) < this.getDateOnly(this.addWorkFlowService.formData.workflowReceivedDate) && this.addWorkFlowService.formData.priorityCodeAssigned == enumWorkflowPriorityCode.P6) {
      this.notificationService.printWarningMessage("Workflow Received date should be less than Deadline Date");
      this.isValid = false;
    }
    return this.isValid;
  }

  initializeForm(id) {
    this.addWorkFlowService.getWorkflowItems(id).subscribe(response => {
      this.addWorkFlowService.formData = response;
      this.setFields();
      this.disableWorkItemCategoryField();
      this.disableEnableControls();
      this.isEditMode = true;
      this.refreshGrid();
      if (this.isEditMode)
        this.title = "Edit";
    },
      error => {
        this.notificationService.printErrorMessage('Error in loading claim Work flow details');
      }
    );

  }

  resetForm() {
    this.addWorkFlowService.formData =
    {
      claimId: null,
      workflowCompletionStatus: enumWorkflowStatus.Outstanding,
      id: 0,
      mmiUserAllocated: null,
      priorityCodeAssigned: enumWorkflowPriorityCode.P3NewClaim,
      workflowReceivedDate: null,
      insuredTitle: '',
      claimantName: '',
      mmiClaimsReference: '',
      claimType: '',
      handlingOrganisation: '',
      eFileURL: '',
      workflowLinkedDocuments: [],
      workItemNotes: [],
      isDocumentDeleted: false,
      workItemCategoryId: enumWorkFlowCategory.NewClaim,
      mmiSupervisorAllocated: null,
      deadlineDate: null
    }
    // this.setFields();
  }

  clearFields() {
    this.isValid = true;
    if (this.workflowId != null && this.workflowId != undefined) {
      this.initializeForm(this.workflowId);
    } else {
      this.resetForm();
      this.clearClaimFields();
      this.addWorkFlowService.formData.claimId = null;
      this.addWorkFlowService.formData.mmiClaimsReference = '';
      this.getClaimData();
      this.setFields();
      this.workItemNotes.comments = '';
      this.refreshGrid();
    }
  }

  openSharePointFolder() {
    if (this.workflowId == 0 || this.workflowId == null || this.addWorkFlowService.formData.isDocumentDeleted) {
      this.saveWorkflowItemBeforeOpenSharePointFolder();
    }
    else {
      this.getLinkDocumentFolderUrl();
    }
  }

  getLinkDocumentFolderUrl() {
    let url = this.addWorkFlowService.formData.eFileURL;
    if (url != '') {
      let reformatUrl = this.reformatUrlWithQueryString(url, this.workflowId);
      this.goToLink(reformatUrl);
    }
    else {
      // When Claim Folder Doen't Exist
      this.createClaimFolderByClaimID();

    }
  }

  createClaimFolderByClaimID() {
    this.claimDetailService.createClaimFolder(this.claimId).subscribe(res => {
      if (res != null) {
        this.addWorkFlowService.formData.eFileURL = res.filepath;
        if (res.filepath != '') {
          this.getLinkDocumentFolderUrl();
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  saveWorkflowItemBeforeOpenSharePointFolder() {
    if (this.validateForm()) {
      this.addWorkFlowService.formData.claimId = this.addWorkFlowService.formData.claimId;
      this.addWorkFlowService.saveWorkflowItem().subscribe((response) => {
        if (this.addWorkFlowService.formData.claimId != null) {
          this.router.navigate(['/claims/' + this.policyId + '/' + response.claimId + '/add-workflow-item/edit/' + response.id]);
        }
        else {
          this.router.navigate(['/add-workflow-item/edit/' + response.id]);
        }
        this.initializeForm(response.id);
        this.workflowId = response.id;
        this.getLinkDocumentFolderUrl();
        this.addWorkFlowService.formData.isDocumentDeleted = false;
      });
    }
  }

  reformatUrlWithQueryString(url, workflowId) {
    let pattern = new RegExp(/\?.+=.*/g);
    let isQueryStringFound = pattern.test(url);
    let resultedUrl = "";
    if (isQueryStringFound) {
      resultedUrl = url + "&workflowItemId=" + workflowId;
    }
    else {
      resultedUrl = url + "?workflowItemId=" + workflowId;
    }
    return resultedUrl;
  }

  removeLinkedDocuments(_index) {
    let isConfirmed = confirm('Are you sure to remove the linked document?');
    if (isConfirmed) {
      this.addWorkFlowService.formData.workflowLinkedDocuments = this.addWorkFlowService.formData.workflowLinkedDocuments.filter(function (value, index, arr) { return index != _index; });
      this.addWorkFlowService.formData.isDocumentDeleted = true;
    }
  }

  checkDocumentsLinkedWithWorkflowOrNot(workflowLinkedDocuments) {
    if (workflowLinkedDocuments.length == 0 && !this.isEditMode) {
      this.notificationService.printWarningMessage('You have created a workflow item entry without linking a supporting document. Please link a supporting document using the Link Documents option on the screen.');
    }
  }

  setFields() {
    this.isMMISuperUser = false;
    this.showClaimDetails = false;
    this.enableClaimSearch = false;
    if (this.addWorkFlowService.formData.id == 0) {
      this.addWorkFlowService.formData.workflowCompletionStatus = enumWorkflowStatus.Outstanding;
    }
    if (this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.NewClaim) {
      this.disableMMISupervisorDropdown = true;
      this.priorityCodes = this.lookupService.getLookupsByTypeId(60).filter(i => i.id == enumWorkflowPriorityCode.P3NewClaim);
      this.addWorkFlowService.formData.priorityCodeAssigned = enumWorkflowPriorityCode.P3NewClaim;
      if (this.addWorkFlowService.formData.workflowCompletionStatus == enumWorkflowStatus.Outstanding) {
        this.workItemStatus = this.lookupService.getLookupsByTypeId(61).filter(i => i.id != enumWorkflowStatus.Completed);
      }
      else if (this.addWorkFlowService.formData.workflowCompletionStatus == enumWorkflowStatus.Completed) {
        this.workItemStatus = this.lookupService.getLookupsByTypeId(61).filter(i => i.id != enumWorkflowStatus.Outstanding);
        //this.addWorkFlowService.formData.claimId == null;
      }
      else {
        //this.disableMMISupervisorDropdown = false;
        this.showClaimDetails = true;
        this.workItemStatus = this.lookupService.getLookupsByTypeId(61).filter(i => i.id != enumWorkflowStatus.Outstanding);
        this.enableClaimSearch = true;
      }

      this.addWorkFlowService.formData.priorityCodeAssigned = enumWorkflowPriorityCode.P3NewClaim;
    }
    else {
      this.disableMMISupervisorDropdown = true;
      this.priorityCodes = this.lookupService.getLookupsByTypeId(60).filter(i => i.id != enumWorkflowPriorityCode.P3NewClaim);
      this.workItemStatus = this.lookupService.getLookupsByTypeId(61).filter(i => i.id != enumWorkflowStatus.CompletedStage1);
      if (this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ExistingClaim || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ChaseForLLA || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.PrepareLLA || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ReviewNonDAReferral || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ConfirmDAStatus || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ConfirmLitigationStatus) {
        this.showClaimDetails = true;
        this.enableClaimSearch = false;
        this.workItemCategoryList = this.lookupService.getLookupsByTypeId(59);
        if (this.addWorkFlowService.formData.mmiSupervisorAllocated != null) {
          this.priorityCodes = this.lookupService.getLookupsByTypeId(60).filter(i => i.id == enumWorkflowPriorityCode.P3NewClaim);
          this.workItemStatus = this.lookupService.getLookupsByTypeId(61).filter(i => i.id != enumWorkflowStatus.Outstanding);
          this.enableClaimSearch = true;
        }

      }
    }

    //this.onChangeWorkItemStatus();
  }
  getClaimDetails() {

    this.clearClaimFields()
    if (this.addWorkFlowService.formData.mmiClaimsReference == "") {
      this.notificationService.printWarningMessage("Please input MMI Claim Reference");
      return;
    }
    if (this.addWorkFlowService.formData.mmiClaimsReference.length < 11) {
      this.notificationService.printWarningMessage("Please input valid MMI Claim Reference");
      return;
    }
    this.addWorkFlowService.getClaimData(this.addWorkFlowService.formData.mmiClaimsReference).subscribe((response) => {
      if (response == null) {
        this.notificationService.printWarningMessage("MMI Claims Reference entered is not present.Please input other claims Reference");
        return false;

      }
      else {
        this.addWorkFlowService.formData.claimantName = response.claimantName;
        this.addWorkFlowService.formData.insuredTitle = response.insuredTitle;
        this.addWorkFlowService.formData.claimType = response.claimType;
        this.addWorkFlowService.formData.claimId = response.claimId;
        this.addWorkFlowService.formData.handlingOrganisation = response.handlingOrganisation;
        this.addWorkFlowService.formData.mmiClaimsReference = response.mmiClaimsReference;
        this.policyId = response.policyId;

      }


    });
    //this.addWorkFlowService.getClaimData(this.addWorkFlowService.formData.mmiClaimsReference).subscribe((response) => {
    //  if (response == null) {
    //    this.notificationService.printWarningMessage("MMI Claims Reference entered is not present.Please input other claims Reference");
    //  }
    //  else {
    //    this.addWorkFlowService.formData.claimantName = response.claimantName;
    //    this.addWorkFlowService.formData.insuredTitle = response.insuredTitle;
    //    this.addWorkFlowService.formData.claimType = response.claimType;
    //    this.addWorkFlowService.formData.claimId = response.claimId;
    //    this.addWorkFlowService.formData.handlingOrganisation = response.handlingOrganisation;
    //    this.addWorkFlowService.formData.mmiClaimsReference = response.mmiClaimsReference;
    //    this.policyId = response.policyId;
    //  }

    //});
  }

  clearClaimFields() {
    this.addWorkFlowService.formData.claimantName = '';
    this.addWorkFlowService.formData.insuredTitle = ''
    this.addWorkFlowService.formData.claimType = ''
    this.addWorkFlowService.formData.claimId = null;
    this.addWorkFlowService.formData.handlingOrganisation = ''
    this.policyId = null;
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  refreshGrid() {
    if (this.workItemNotesTableReference) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  addWorkItemNotes() {
    //this.initializeNotes();
    if (this.validateNotes()) {
      this.workItemNotes = {
        id: null,
        WorkItemId: this.addWorkFlowService.formData.id,
        notesDate: new Date(),
        comments: this.workItemNotes.comments,
        updatedByUserName: this.securityService.getUserData().id.toString() + " - " + this.securityService.getUserData().claimsHandlers

      };
      this.addWorkFlowService.formData.workItemNotes.push(this.workItemNotes);
      this.initializeNotes();
    }

  }

  validateNotes() {
    this.workflowNotesValidation = true;
    if (!this.workItemNotes.comments) {
      this.notificationService.printWarningMessage("Please add some comments");
      this.workflowNotesValidation = false;
    }
    return this.workflowNotesValidation;
  }

  initializeNotes() {
    this.workItemNotes =
    {
      id: null, WorkItemId: null, notesDate: null, comments: '', updatedByUserName: null
    };
    this.refreshGrid();
  }

  onChangeWorkItemStatus() {
    this.enableClaimSearch = false;
    this.showClaimDetails = false;
    this.disableMMISupervisorDropdown = true;
    if ((this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.NewClaim && this.addWorkFlowService.formData.workflowCompletionStatus != enumWorkflowStatus.Outstanding)) {
      this.showClaimDetails = true;
      this.disableMMISupervisorDropdown = false;
      this.enableClaimSearch = true;
    }
    if (this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ExistingClaim || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ChaseForLLA || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.PrepareLLA || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ReviewNonDAReferral || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ConfirmDAStatus || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ConfirmLitigationStatus) {
      this.showClaimDetails = true;
    }
    else if (this.addWorkFlowService.formData.workflowCompletionStatus == enumWorkflowStatus.Outstanding && this.addWorkFlowService.formData.priorityCodeAssigned == enumWorkflowPriorityCode.P3NewClaim) {
      this.addWorkFlowService.formData.claimId = null;
      this.addWorkFlowService.formData.workItemCategoryId = enumWorkFlowCategory.NewClaim;
      this.addWorkFlowService.formData.mmiSupervisorAllocated = null;
      this.addWorkFlowService.formData.mmiClaimsReference = '';
    }
    else {
      this.clearClaimFields();
      this.addWorkFlowService.formData.claimId = null;
      this.addWorkFlowService.formData.mmiClaimsReference = '';
    }
  }

  disableWorkItemCategoryField() {
    this.disableWorkItemCategory = false;
    if (this.addWorkFlowService.formData.workflowCompletionStatus != enumWorkflowStatus.Outstanding || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ExistingClaim || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ChaseForLLA || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.PrepareLLA || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ReviewNonDAReferral || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ConfirmDAStatus || this.addWorkFlowService.formData.workItemCategoryId == enumWorkFlowCategory.ConfirmLitigationStatus) {
      this.disableWorkItemCategory = true;
    }

  }

  showFieldsForNewClaimsWithClaimReference() {
    if (this.addWorkFlowService.formData.mmiSupervisorAllocated != null) {
      this.priorityCodes = this.lookupService.getLookupsByTypeId(60).filter(i => i.id == enumWorkflowPriorityCode.P3NewClaim);
      this.showClaimDetails = true;
    }
  }

  onChangePriorityCode() {
    if (this.addWorkFlowService.formData.priorityCodeAssigned != enumWorkflowPriorityCode.P6) {
      this.addWorkFlowService.formData.deadlineDate = null;
    }
  }
  populateClaimFieldsAndSaveWorkItem() {
    let validMMIClaimReference = true;
    if (this.addWorkFlowService.formData.mmiClaimsReference.length < 11) {
      this.notificationService.printWarningMessage("Please input valid MMI Claim Reference");
      return;
    }
    this.addWorkFlowService.getClaimData(this.addWorkFlowService.formData.mmiClaimsReference).subscribe((response) => {
      if (response == null) {
        this.notificationService.printWarningMessage("MMI Claims Reference entered is not present.Please input other claims Reference");
        validMMIClaimReference = false;
        return;
      }
      else {
        this.addWorkFlowService.formData.claimantName = response.claimantName;
        this.addWorkFlowService.formData.insuredTitle = response.insuredTitle;
        this.addWorkFlowService.formData.claimType = response.claimType;
        this.addWorkFlowService.formData.claimId = response.claimId;
        this.addWorkFlowService.formData.handlingOrganisation = response.handlingOrganisation;
        this.addWorkFlowService.formData.mmiClaimsReference = response.mmiClaimsReference;
        this.policyId = response.policyId;
        this.saveWorkItems();
      }
    });
  }

  disableEnableControls() {
    var loggedInUser = this.securityService.getUserData().id;
    let userDetail = this.securityService.getUserData();
    if (userDetail != null) {
      this.isMMISuperUser = this.securityService.getUserData().superUser;
      if (this.isMMISuperUser) {
        this.isMMISuperUser = true;
      }

      if ((userDetail.userRoleId == 1049 || (userDetail.userRoleId == 1048 && userDetail.superUser == 1)) && userDetail.organisationId == 1) {
        this.isMMIUser = true;
      }
    }
    this.disableControls = false;
    if (this.addWorkFlowService.formData.workflowCompletionStatus == enumWorkflowStatus.CompletedStage1) {
      this.disableControls = (this.addWorkFlowService.formData.mmiSupervisorAllocated == loggedInUser) ? false : true;
    }
    else if (this.addWorkFlowService.formData.workflowCompletionStatus == enumWorkflowStatus.Completed) {
      this.disableControls = true;
    }
    else {
      this.disableControls = (this.addWorkFlowService.formData.mmiUserAllocated == loggedInUser) ? false : true;
    }
  }
  onWorkItemCategoryChange() {
    if (this.addWorkFlowService.formData.workItemCategoryId != enumWorkFlowCategory.NewClaim) {
      this.addWorkFlowService.formData.priorityCodeAssigned = null;
      this.addWorkFlowService.formData.mmiSupervisorAllocated = null;
    }

    this.setFields();
  }

  getClaimData() {
    if (this.currentRoute.parent.parent != null) {
      this.claimId = parseInt(this.currentRoute.parent.parent.snapshot.paramMap.get('claimId'));
      this.policyId = parseInt(this.currentRoute.parent.parent.snapshot.paramMap.get('policyId'));
      this.addWorkFlowService.formData.claimId = this.claimId;
      this.showClaimDetails = true;
      this.disableWorkItemCategory = true;
      this.addWorkFlowService.formData.priorityCodeAssigned = null;
      this.loadClaimDetails();
      this.addWorkFlowService.formData.workItemCategoryId = enumWorkFlowCategory.ExistingClaim;
      //this.showFieldsForNewClaimsWithClaimReference();
      //this.addWorkFlowService.formData.priorityCodeAssigned = null;
      //this.showClaimDetails = true;
      this.showLinkDocument = true;
      //if (this.addWorkFlowService.formData.priorityCodeAssigned != enumWorkflowPriorityCode.P3NewClaim) {
      //    this.enableClaimSearch = false;
      //}
      //this.workItemCategoryList = this.lookupService.getLookupsByTypeId(59);
    }
  }

  getDateOnly(date: Date) {
    if (typeof date == 'string')
      var newDate = new Date(date);
    else
      var newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }
}

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { SearchPolicyVM } from '../../models/search-policy.model';
import { IPolicy } from '../../models/policy.model';
import { AuthService } from './auth.service';

@Injectable()
export class PolicyService {


  private policyUrl: string = "/api/policy/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient, private authService:AuthService) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.policyUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.policyUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.policyUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.policyUrl);
  }

  getPolicyById(id: number): any {
    let url = this.policyUrl + "getPolicyById";

    return this.dataService.get(url, { id: id });
  }

  createPolicyFolder(id: number): any {
    let url = this.policyUrl + "createPolicyFolder";
    return this.dataService.get(url, { id: id });
  }

  createPolicy(data): any {

    let url = this.policyUrl + "createPolicy";

    return this.dataService.post(url, data);

  }

  updatePolicy(data): any {

    let url = this.policyUrl + "updatePolicy";

    return this.dataService.putWithId(url, data);
  }
  getPolicies(filterCriteria: SearchPolicyVM) {
    let url = this.policyUrl + "search";

    return this.dataService.get(url, filterCriteria).pipe<IPolicy>(tap((response: any) => {
      return response;
    }));
  }
  getPolicyInsuredTitle(search: string) {
    let url = this.policyUrl + "getInsuredTitle";
     return this.dataService.get(url, { search: search });

  }

  getPolicyByNumber(search: string) {
    let url = this.policyUrl + "getPolicyByNumber";
    return this.dataService.get(url, { search: search }).toPromise();
  }

  checkPolicyAccessiblity(policyId: number, organisationId: number): any {
    return this.authService.checkPolicyAccessiblity(policyId, organisationId);
  }

  getPolicyholderName(search: string) {
    let url = this.policyUrl + "getPolicyholderName";
    return this.dataService.get(url, { search: search });

  }

  getSearchedPerpetrators(search: string) {
    let url = this.policyUrl + "getSearchedPerpetrators";
    return this.dataService.get(url, { search: search });

  }
}


import { NotificationService } from '../../../shared/services/notification.service';
import { NgForm, FormsModule } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { LookupService } from '../../../shared/services/lookup.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild, HostListener, AfterViewChecked, ElementRef, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ClaimDetailNotes } from '../claim-detail-notes/claim-detail-notes.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap'
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddClaimDetailNotesService } from '../claim-detail-notes/claim-detail-notes.service';
import { ClaimHandlingReviewNotes } from '../claim-detail-notes/claim-detail-review-notes.model';
import * as moment from 'moment';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ComponentCanDeactivate } from '../../../shared/guard/unsaved-changes.guard';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { SecurityService } from '../../../shared/services/security.service';
import { ClaimDetailService } from '../claim-detail/claim-detail.service';
import { ToastrService } from 'ngx-toastr';
import { enumHandlingOrganization, enumUserRole, claimType } from '../../../shared/models/common.enum';
import { ClaimComponent } from '../claim.component';

@Component({
  selector: 'app-claim-detail-notes',
  templateUrl: './claim-detail-notes.component.html',
  styleUrls: ['./claim-detail-notes.component.css']
})
export class ClaimDetailNotesComponent extends BaseComponentComponent implements OnInit, OnDestroy, ComponentCanDeactivate, AfterViewChecked {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;


  @ViewChild(NgForm, { static: false })
  private formParent: NgForm;

  //@ViewChild(NgForm, { static: false })
  //private form: NgForm;

  public claimDetailNotesVM: ClaimDetailNotes = new ClaimDetailNotes();
  public claimDetailNotesResult: any;

  @ViewChild('claimHandlingReviewNotesTable') claimHandlingReviewNotesTable: ElementRef;
  public dtOptions: any = {};

  public authenticated: boolean = false;
  public authSubscription: Subscription;
  public dtTrigger: Subject<any>;
  data: ClaimHandlingReviewNotes;
  formData: ClaimHandlingReviewNotes;
  reviewNotes: ClaimHandlingReviewNotes;
  formParentData: ClaimDetailNotes;
  claimHandlingReviewNotes: ClaimHandlingReviewNotes[] = [];
  claimHandlingNoteId: number;
  claimId: string;
  isformValid: boolean = true;
  isMesoType: boolean = false;
  isValid: boolean = true;
  minDate: any;
  now: Date = new Date();
  capeProducts: any[];

  nextKeyDates: any[];

  sub: Subscription;

  isGridLoaded: boolean = false;
  isSelectedMMI: boolean = true;
  isMMIUser: boolean = false;
  isFinanceUser: boolean = false;
  isClaimTypeAbuse: boolean = true;
  hideWorkflowButton: boolean = true;

  saveMethod: any;
  isFormSubmitted: boolean = true;
  formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean { return false; }
  policyId: number;
  isSettledButtonClicked: boolean = false;
  initialDiaryDate: Date;
  initialMMIReviewDate: Date;

  initialLiabilityLastReviewedDate: Date;
  initialCausationLastReviewedDate: Date;
  initialQuantumLastReviewedDate: Date;
  initialLimitationLastReviewedDate: Date;
  initialnextKeyDateDiary: Date;
  isDelegatedAuthorityMMIOnly: boolean = false;

  nonMMIHandlingOrg: boolean = false;
  nonDelegatedAuthority: boolean = false;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    this.saveMethod = this.onSubmitParent;
    let hasPendingChanges: boolean = !this.formParent.dirty;
    return hasPendingChanges;
  }

  constructor(private claimDetailNotesService: AddClaimDetailNotesService, private _Activatedroute: ActivatedRoute,
    private _router: Router, public lookUpService: LookupService, private notificationService: NotificationService, private activeRoute: ActivatedRoute,
    private router: Router, private config: NgbDatepickerConfig, private lookupService: LookupService, securityService: SecurityService,
    private claimDetailService: ClaimDetailService,
    private toastr: ToastrService, @Inject(ClaimComponent) private parent: ClaimComponent) {

    super(securityService, 1145);
    this.config.minDate = {
      year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()
    };
    this.config.outsideDays = 'hidden';
    this.dtTrigger = new Subject();
  }

  ngOnInit(): void {
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
      this.hideWorkflowButton = (this.isMMIUser) ? false : true
      this.isFinanceUser = this.securityService.getUserData().userRoleId == enumUserRole.Finance;
    }
    // this.claimHandlingNoteId = parseInt(this.activeRoute.snapshot.paramMap.get('claimHandlingNoteId'));
    //this.claimId = this.activeRoute.snapshot.paramMap.get('claimId');
    this.capeProducts = this.lookupService.getLookupsByTypeId(8);
    this.nextKeyDates = this.lookupService.getLookupsByTypeId(67);

    this.config.minDate = {
      year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()
    };
    this.config.outsideDays = 'hidden';

    this.claimId = this.activeRoute.parent.snapshot.paramMap.get('claimId');
    this.policyId = parseInt(this.activeRoute.parent.snapshot.paramMap.get('policyId'));
    this.LoadFrm();
    this.formData = new ClaimHandlingReviewNotes();
    //this.initialiseDataTable();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [
      ],
      'columnDefs': [
        {
          className: "textcenter",
          width: '95px',
          targets: [0, 2]
        },
        { type: 'datetime-uk', "targets": 0 }
      ],
      order: [[0, 'desc']],
    };

    if (this.claimId == null) {
      //this.resetForm();
      //this.isEditMode = false;
    }
    else {
      this.checkHandlingOrganizations(this.claimId);
      // this.getClaimHandlingReviewNotesCommentsById(parseInt(this.claimId));
      this.initializeForm(parseInt(this.claimId))
    }
    if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
      this.isReadOnlyMode = true;
    }
    //this.InitialBindTable();
  }

  LoadFrm(): void {

    this.claimDetailNotesService.formParentData = new ClaimDetailNotes();

    this.claimDetailNotesService.formParentData = {
      capeProductID: null,

      handlerId: "101",
      id: null,
      claimId: parseInt(this.claimId),
      liabilityDescription: '',
      quantumDescription: '',
      causationDescription: '',
      limitationDescription: '',
      diaryComment: '',
      diaryDate: new Date(),
      overallCoordinator: null,
      claimReviewedDate: null,
      claimTypeId: null,
      eFileURL: null,
      claimHandlingReviewNotes: [],
      mmiReviewDate: null,
      mmiReviewComment: '',
      version: '',

      liabilityLastReviewedDate: new Date(),
      causationLastReviewedDate: new Date(),
      quantumLastReviewedDate: new Date(),
      limitationLastReviewedDate: new Date(),
      nextKeyDateID: null,
      nextKeyDateDiary: new Date()

    }
    // this.LoadCommentForm();
    if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
      this.isReadOnlyMode = true;
    }

    this.parent.claimStatusId$.subscribe(x => {
      if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
        this.isReadOnlyMode = true;
      }
    });
  }

  LoadCommentForm(): void {


    this.formData = {
      notesComment: '',
      notesDate: new Date(),
      handlerID: "101",
      claimHandlingNotesId: this.claimHandlingNoteId,
      id: null,
      updatedByUserName: this.securityService.getUserData().id.toString() + " - " + this.securityService.getUserData().claimsHandlers
    }
  }

  checkHandlingOrganizations(claimId) {
    this.claimDetailService.getClaimById(parseInt(claimId)).then(res => {
      if (res != null) {
        this.isSelectedMMI = res.handlingOrganisationId == enumHandlingOrganization.MMI ? true : false;
        this.checkSelectedClaimType(res.claimTypeId);
        this.isDelegatedAuthorityMMIOnly = (res.delegatedAuthority == 785 || res.delegatedAuthority == 787) ? true : false;

        if (this.isReadOnlyMode != true) {
          if (this.isMMIUser != true) {
            this.nonDelegatedAuthority = (res.delegatedAuthority == 785 || res.delegatedAuthority == 787) ? true : false;
            this.nonMMIHandlingOrg = res.handlingOrganisationId != enumHandlingOrganization.MMI ? true : false;
          }
          else {
            this.nonDelegatedAuthority = false;
            this.nonMMIHandlingOrg = false;
          }
        }
        else {
          this.nonDelegatedAuthority = true;
          this.nonMMIHandlingOrg = true;
        }
      }
    });
  }

  addCommentValidation() {
    this.isValid = true;
    this.isFormSubmitted = true;
    if (this.formData.notesComment == '') {
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }
    return this.isValid;
  }


  initializeForm(claimId) {
    this.sub = this.claimDetailNotesService.getClaimHandlingNoteById(claimId).subscribe(res => {
      this.claimDetailNotesService.formParentData = res;
      this.initialDiaryDate = res.diaryDate;
      this.initialMMIReviewDate = res.mmiReviewDate;

      this.initialLiabilityLastReviewedDate = res.liabilityLastReviewedDate;
      this.initialCausationLastReviewedDate = res.causationLastReviewedDate;
      this.initialQuantumLastReviewedDate = res.quantumLastReviewedDate;
      this.initialLimitationLastReviewedDate = res.limitationLastReviewedDate;
      this.initialnextKeyDateDiary = res.nextKeyDateDiary;


      this.claimHandlingNoteId = this.claimDetailNotesService.formParentData.id;
      this.claimHandlingReviewNotes = res.claimHandlingReviewNotes;
      this.LoadCommentForm();
      this.isMesoType = this.claimDetailNotesService.formParentData.claimTypeId == 5 ? true : false;
      // this.formParent.form.markAsPristine();
      this.refreshGrid();
      // this.InitialBindTable();
      //this.claimDetailNotesService.getClaimHandlingReviewNotesCommentsById(id).subscribe(response => {
      //  this.formData = response;
      //}, error => {
      //  this.notificationService.printErrorMessage('Error in fetching Claim Handling Review Notes Comments');
      //});
      //this.isEditMode = true;     
    },
      error => {
        this.notificationService.printErrorMessage('Error in fetching Claim Handling Notes ');
      }
    );
  }

  //initialiseDataTable(): void {
  //  const that = this;
  //  this.dtOptions = {
  //    pagingType: 'full_numbers',
  //    pageLength: 10,      
  //    searching: false,
  //    // Declare the use of the extension in the dom parameter
  //    dom: 'Blfrtip',
  //    // Configure the buttons
  //    buttons: [
  //    ],
  //    'columnDefs': [
  //      {
  //        className: "textcenter",
  //        width:'95px',
  //        targets: [0,2]
  //      },
  //      { type: 'datetime-uk', "targets": 0 }
  //    ],
  //    //order: [[0, 'desc']],
  //    ajax: (dataTablesParameters: any, callback) => {
  //      var self = this;
  //      this.claimDetailNotesService.getClaimHandlingReviewNotesCommentsById(this.claimHandlingNoteId).subscribe(response => {
  //        this.claimHandlingReviewNotes = response//.claimHandlingReviewNotes;
  //        callback({
  //          data: response //.claimHandlingReviewNotes
  //        });
  //      });
  //    }
  //  };

  //}

  //InitialBindTable(): void {
  //  if (this.claimHandlingReviewNotes) {
  //    if ((this.datatableElement && this.datatableElement.dtInstance)) {
  //      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //        dtInstance.destroy();
  //        this.dtTrigger.next();
  //      });
  //    }
  //    else {
  //      this.dtTrigger.next();
  //    }
  //  }
  //}

  addNotes() {
    if (this.addCommentValidation()) {
      // this.claimDetailNotesService.formParentData.claimHandlingReviewNotes.push(this.formData);
      this.claimHandlingReviewNotes.push(this.formData)
      this.LoadCommentForm();
      this.refreshGrid();
    }
  }

  //onSubmit(form: NgForm) {
  //  this.formData = {
  //    notesComment: form.value.notesComment,
  //    ID: null,
  //    notesDate: new Date(),
  //    claimHandlingNotesId: this.claimHandlingNoteId,
  //    handlerID: "101",
  //    updatedByUserName: ''
  //  }
  //  if (this.addCommentValidation()) {
  //    this.claimDetailNotesService.createClaimHandlingReviewNotesComments(this.formData).subscribe(res => {
  //      this.notificationService.printSuccessMessage('Claim Handling Note Comments saved successfully');
  //      // this.router.navigate(['/handling-organisation/edit/' + res.id]);
  //      this.claimDetailNotesService.getClaimHandlingReviewNotesCommentsById(this.claimHandlingNoteId).subscribe(response => {
  //        this.claimHandlingReviewNotes = response;
  //        this.LoadCommentForm();
  //        this.InitialBindTable();
  //        this.isFormSubmitted = true;
  //        this.formValidityFlagLoaded.next();
  //      }, error => {
  //        this.notificationService.printErrorMessage('Error in fetching Claim Handling Review Notes Comments');
  //      });

  //    }),
  //      error => {
  //        this.notificationService.printErrorMessage("Error occured  in saving Claim Handling Note Comments");
  //      }
  //  }
  //  //this.data = {
  //  //  notesComment: form.value.notesComment,
  //  //  notesDate: new Date(),
  //  //  handlerID: 101,
  //  //  claimHandlingNotesId: this.claimHandlingNoteId,
  //  //  claimHandlingReviewNotesId: null
  //  //};
  //  //this.claimHandlingReviewNotes.push(this.data);
  //  //this.formData.notesComment = '';
  //}

  validateForm() {
    this.isformValid = true;
    this.isFormSubmitted = true;
    if ((this.claimDetailNotesService.formParentData.capeProductID == null || this.claimDetailNotesService.formParentData.capeProductID == 0) && this.isMesoType) {
      this.toastr.warning("Cape Product field is requied", 'Claim Handler Notes', { positionClass: 'toast-top-right' });
      this.isformValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }
    if (!this.isSelectedMMI && this.claimDetailNotesService.formParentData.mmiReviewDate == null && !this.isFinanceUser && this.isMMIUser) {
      this.toastr.warning("If handling organisation is other than MMI, MMI Review Date would be the mandatory", 'Claim Handler Notes', { positionClass: 'toast-top-right' });
      this.isformValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }

    if (this.claimDetailNotesService.formParentData.diaryDate != null) {
      let currentDiaryDate = moment(this.claimDetailNotesService.formParentData.diaryDate).format('YYYY-MM-DD');
      let initialDiaryDate = moment(this.initialDiaryDate).format('YYYY-MM-DD');
      var isDiaryDateSameasEarlier = moment(currentDiaryDate).isSame(initialDiaryDate);
      if (!isDiaryDateSameasEarlier) {
        var newEntry = this.claimDetailNotesService.formParentData.claimHandlingReviewNotes.filter(i => i.id == null).length > 0;
        if (!(this.claimDetailNotesService.formParentData.claimHandlingReviewNotes.filter(i => i.id == null).length > 0)) {
          this.notificationService.printWarningMessage("When completing your Diary Review, please ensure you have made a comment in the Claims Handling Notes "
            + "regarding actions taken as part of your Diary Review before moving to a different screen.");
          this.isformValid = false;
          this.isFormSubmitted = false;
          this.formValidityFlagLoaded.next();
          return false;
        }
      }
    }

    if (this.claimDetailNotesService.formParentData.mmiReviewDate != null) {
      let currentMMIReviewDate = moment(this.claimDetailNotesService.formParentData.mmiReviewDate).format('YYYY-MM-DD');
      let initialMMIReviewDate = moment(this.initialMMIReviewDate).format('YYYY-MM-DD');
      var isMMIReviewDateSameasEarlier = moment(currentMMIReviewDate).isSame(initialMMIReviewDate);
      if (!isMMIReviewDateSameasEarlier) {
        var newEntry = this.claimDetailNotesService.formParentData.claimHandlingReviewNotes.filter(i => i.id == null).length > 0;
        if (!(this.claimDetailNotesService.formParentData.claimHandlingReviewNotes.filter(i => i.id == null).length > 0)) {
          this.notificationService.printWarningMessage("When completing your MMI Diary Review, please ensure you have made a comment in the Claims Handling Notes "
            + "regarding actions taken as part of your Diary Review before moving to a different screen.");
          this.isformValid = false;
          this.isFormSubmitted = false;
          this.formValidityFlagLoaded.next();
          return false;
        }
      }
    }


    if (this.claimDetailNotesService.formParentData.liabilityLastReviewedDate != null) {
      let currentLiabilityLastReviewedDate = moment(this.claimDetailNotesService.formParentData.liabilityLastReviewedDate).format('YYYY-MM-DD');
      let initialliabilityLastReviewedDate = moment(this.initialLiabilityLastReviewedDate).format('YYYY-MM-DD');
      var isLiabilityLastReviewedDateSameasEarlier = moment(currentLiabilityLastReviewedDate).isSame(initialliabilityLastReviewedDate);

      var liabilityDesc = this.claimDetailNotesService.formParentData.liabilityDescription;
      if (liabilityDesc == null) {
        liabilityDesc = "";
      }


      if (!isLiabilityLastReviewedDateSameasEarlier) {
        if (!(liabilityDesc != "")) {
          this.notificationService.printWarningMessage("When completing your Liability Review, please ensure you have made a comment in the Liability "
            + "regarding actions taken as part of your Liability Review before moving to a different screen.");
          this.isformValid = false;
          this.isFormSubmitted = false;
          this.formValidityFlagLoaded.next();
          return false;
        }
      }
    }

    if (this.claimDetailNotesService.formParentData.causationLastReviewedDate != null) {
      let currentCausationLastReviewedDate = moment(this.claimDetailNotesService.formParentData.causationLastReviewedDate).format('YYYY-MM-DD');
      let initialCausationLastReviewedDate = moment(this.initialCausationLastReviewedDate).format('YYYY-MM-DD');
      var isCausationLastReviewedDateSameasEarlier = moment(currentCausationLastReviewedDate).isSame(initialCausationLastReviewedDate);

      var causationDesc = this.claimDetailNotesService.formParentData.causationDescription;
      if (causationDesc == null) {
        causationDesc = "";
      }

      if (!isCausationLastReviewedDateSameasEarlier) {
        if (!(causationDesc != "")) {
          this.notificationService.printWarningMessage("When completing your Causation Review, please ensure you have made a comment in the Causation "
            + "regarding actions taken as part of your Causation Review before moving to a different screen.");
          this.isformValid = false;
          this.isFormSubmitted = false;
          this.formValidityFlagLoaded.next();
          return false;
        }
      }
    }


    if (this.claimDetailNotesService.formParentData.quantumLastReviewedDate != null) {
      let currentQuantumLastReviewedDate = moment(this.claimDetailNotesService.formParentData.quantumLastReviewedDate).format('YYYY-MM-DD');
      let initialQuantumLastReviewedDate = moment(this.initialQuantumLastReviewedDate).format('YYYY-MM-DD');
      var isQuantumLastReviewedDateSameasEarlier = moment(currentQuantumLastReviewedDate).isSame(initialQuantumLastReviewedDate);

      var quantumDesc = this.claimDetailNotesService.formParentData.quantumDescription;
      if (quantumDesc == null) {
        quantumDesc = "";
      }

      if (!isQuantumLastReviewedDateSameasEarlier) {
        if (!(quantumDesc != "")) {
          this.notificationService.printWarningMessage("When completing your Quantum Review, please ensure you have made a comment in the Quantum "
            + "regarding actions taken as part of your Quantum Review before moving to a different screen.");
          this.isformValid = false;
          this.isFormSubmitted = false;
          this.formValidityFlagLoaded.next();
          return false;
        }
      }
    }

    if (this.claimDetailNotesService.formParentData.limitationLastReviewedDate != null) {
      let currentLimitationLastReviewedDate = moment(this.claimDetailNotesService.formParentData.limitationLastReviewedDate).format('YYYY-MM-DD');
      let initialLimitationLastReviewedDate = moment(this.initialLimitationLastReviewedDate).format('YYYY-MM-DD');
      var isLimitationLastReviewedDateSameasEarlier = moment(currentLimitationLastReviewedDate).isSame(initialLimitationLastReviewedDate);

      var limitationDesc = this.claimDetailNotesService.formParentData.limitationDescription;
      if (limitationDesc == null) {
        limitationDesc = "";
      }

      if (!isLimitationLastReviewedDateSameasEarlier) {
        if (!(limitationDesc != "")) {
          this.notificationService.printWarningMessage("When completing your Limitation Review, please ensure you have made a comment in the Limitation "
            + "regarding actions taken as part of your Limitation Review before moving to a different screen.");
          this.isformValid = false;
          this.isFormSubmitted = false;
          this.formValidityFlagLoaded.next();
          return false;
        }
      }
    }


    return this.isformValid;
  }

  onSubmitParent() {
    this.claimDetailNotesService.formParentData.claimId = parseInt(this.claimId);
    if (this.claimDetailNotesService.formParentData.id > 0) {
      if (this.validateForm()) {
        if (this.claimHandlingReviewNotes != null && this.claimHandlingReviewNotes.length > 0) {
          this.claimDetailNotesService.formParentData.claimHandlingReviewNotes = this.claimHandlingReviewNotes;
        }
        if (this.formData.notesComment.trim() != '' && this.formData.notesComment != null) {
          // this.updateNotesComment();
          if (confirm("Comment / Note has been written but not Added. Do you want to save your Comment / Note ?")) {
            this.claimDetailNotesService.formParentData.claimHandlingReviewNotes.push(this.formData);
          }
        }
        if (this.isSelectedMMI) {
          this.claimDetailNotesService.formParentData.mmiReviewDate = null;
          this.claimDetailNotesService.formParentData.mmiReviewComment = null;
        }
        this.claimDetailNotesService.updateClaimHandlingNotes().subscribe(res => {
          this.notificationService.printSuccessMessage('Claim Handling Note updated successfully');
          // this.claimDetailNotesService.formParentData = res;
          this.initializeForm(parseInt(this.claimId));

          //this.isEditMode = true;
          this.isFormSubmitted = true;
          this.formValidityFlagLoaded.next();
          this.formParent.form.markAsPristine();
        },
          error => {
            if (error.error.includes('You are about to save changes to this claim. Another user is also in the claim')) {
              this.notificationService.printHtmlErrorMessage(error.error);
            }
            else {
              this.notificationService.printErrorMessage("Error occured  in updating Claim Handling Note");
            }
          })
      }
    }
    //else {
    //  this.claimDetailNotesService.createClaimHandlingNotes().subscribe(res => {
    //    if (this.formData.notesComment != '' && this.formData.notesComment != null) {
    //      this.updateNotesComment();
    //    }
    //    this.notificationService.printSuccessMessage('Claim Handling Note saved successfully');
    //    // this.router.navigate(['/handling-organisation/edit/' + res.id]);
    //    this.formParent.form.markAsPristine();
    //  }),
    //    error => {
    //      this.notificationService.printErrorMessage("Error occured  in saving Claim Handling Note");
    //    }
    //}
  }

  //getClaimHandlingReviewNotesCommentsById(claimId: number) {
  //  this.claimDetailNotesService.getClaimHandlingReviewNotesCommentsById(this.claimHandlingNoteId).subscribe(response => {
  //    this.claimHandlingReviewNotes = response
  //    if (response == null) {
  //      this.claimHandlingReviewNotes = [];
  //    }
  //    else {
  //      this.claimHandlingReviewNotes = response;
  //    }
  //    this.refreshGrid();
  //    //response.length > 0 ? this.datahave = true : this.datahave = false;
  //    //console.log("data", response);
  //  });
  //}


  reset(): void {
    this.initializeForm(parseInt(this.claimId));
  }

  refresh(): void {
    if (confirm('Are you sure want to reset this page?')) {
      window.location.reload();
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.sub.unsubscribe();
    this.dtTrigger.unsubscribe();
  }

  refreshGrid() {
    if (this.claimHandlingReviewNotesTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  //updateNotesComment() {
  //  if (confirm("Comment / Note has been written but not Added. Do you want to save your Comment / Note ?")) {
  //    this.formData = {
  //      notesComment: this.formData.notesComment,
  //      ID: null,
  //      notesDate: new Date(),
  //      claimHandlingNotesId: this.claimHandlingNoteId,
  //      handlerID: "101",
  //      updatedByUserName: ''
  //    }
  //    if (this.addCommentValidation()) {
  //      this.claimDetailNotesService.createClaimHandlingReviewNotesComments(this.formData).subscribe(res => {
  //        // this.router.navigate(['/handling-organisation/edit/' + res.id]);
  //        this.claimDetailNotesService.getClaimHandlingReviewNotesCommentsById(this.claimHandlingNoteId).subscribe(response => {
  //          this.claimHandlingReviewNotes = response;
  //          this.LoadCommentForm();
  //          this.refreshGrid();
  //         // this.InitialBindTable();
  //          this.isFormSubmitted = true;
  //          this.formValidityFlagLoaded.next();
  //        }, error => {
  //          this.notificationService.printErrorMessage('Error in fetching Claim Handling Review Notes Comments');
  //        });

  //      }),
  //        error => {
  //          this.notificationService.printErrorMessage("Error occured  in saving Claim Handling Note Comments");
  //        }
  //    }
  //  }
  //  else {
  //    this.formData.notesComment = '';
  //  }
  //}

  getEFile() {
    if (this.claimDetailNotesService.formParentData.eFileURL != '') {
      this.goToLink(this.claimDetailNotesService.formParentData.eFileURL);
    }
    else {
      if (confirm("Claim folder does not exist for this claim. Do you want to create.")) {
        this.createClaimFolder();
      }
    }
  }

  createClaimFolder() {
    let claimId = parseInt(this.activeRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimDetailService.createClaimFolder(claimId).subscribe(res => {
      if (res != null) {
        this.claimDetailNotesService.formParentData.eFileURL = res.filepath;
        if (this.claimDetailNotesService.formParentData.eFileURL != '') {
          this.goToLink(this.claimDetailNotesService.formParentData.eFileURL);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  checkSelectedClaimType(id) {
    if (id == claimType.Abuse || id == claimType.SocialCareFailureToRemove || id == claimType.SocialCareOther) {
      this.isClaimTypeAbuse = true;
    }
    else {
      this.isClaimTypeAbuse = false;
    }
  }

  navigateToWorkflowComponent() {
    this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/add-workflow-item']);
  }

}

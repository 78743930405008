import { Component, OnInit } from '@angular/core';
import { MyProfileService } from './my-profile-service';
import { Router, ActivatedRoute } from '@angular/router';

import { NgForm } from '@angular/forms';
import { ClaimHandler } from '../../add-participant/claim-handler/participant-claim-handler.model';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { SecurityService } from '../../../shared/services/security.service';



@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  public claimHandlerVM: ClaimHandler = new ClaimHandler();
  public userRoles: any[];
  handlingOrganisations: any[];
  statuskeys: any[];
  private form: NgForm;
  claimHandlerId: any
  isDisabledFields: boolean = false;

  constructor(private myProfileService: MyProfileService, private activeRoute: ActivatedRoute,
    private router: Router, public lookUpService: LookupService, private notificationService: NotificationService, private securityService:SecurityService) { }

  ngOnInit(): void {
    this.claimHandlerId = this.securityService.getUserData().id;
    this.LoadLookups();
    this.getHandlingOrganisation();
    this.getClaimHandlersData();

  }
  getClaimHandlerById(claimHandlerId): Promise<any> {
    var promise = new Promise((resolve, reject) => {

      this.myProfileService.getClaimHandler(claimHandlerId).subscribe(claimhandler => {
        resolve(claimhandler);
      })
    })
    return promise;
    //if (claimHandlerId > 0) {
    //  return this.participantClaimHandlerService.getClaimHandler(claimHandlerId);
    //}
  }

  LoadLookups() {
    this.userRoles = this.lookUpService.getLookupsByTypeId(47);
  }

  getClaimHandlersData() {
    this.getClaimHandlerById(this.claimHandlerId).then(response => {
      let data: any = response;
      this.claimHandlerVM = {
        ID: data.id,
        claimsHandlers: data.claimsHandlers,
        handlingAuthority: data.handlingAuthority,
        organisationId: data.organisationId,
        paymentAuthority: data.paymentAuthority,
        handlerId: data.handlerId,
        certifiedAsFitAndProper: data.certifiedAsFitAndProper,
        superUser: data.superUser,
       // status: data.status,
        jobTitle: data.jobTitle,
        telephoneNumber: data.telephoneNumber,
        email: data.email,
        isDelete: data.isDelete,
        userRoleId: data.userRoleId,
        userRoleName: data.userRoleName,
        claimsStage1ApprovalAuthority: data.claimsStage1ApprovalAuthority,
        financeApprovalAuthority: data.financeApprovalAuthority,
      }

    });
  }

  onSubmit(form: NgForm) {

    this.myProfileService.putClaimHandlers(this.claimHandlerVM).subscribe(data => {
      this.notificationService.printSuccessMessage('My Profile updated successfully');
      this.form.form.markAsPristine();
    },
      error => {
        if (error.error == "Email already exist") {
          this.notificationService.printWarningMessage(error.error);
        }
        else {
          this.notificationService.printErrorMessage('Error occured  in updating Claim Handler');
        }
      }
    );

  }

  resetform() {
    this.getClaimHandlersData();
  }

  getHandlingOrganisation() {
    this.myProfileService.getAllHandlingOrganisations()
      .subscribe((reponse: any) => {
        this.handlingOrganisations = reponse;
      });
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subscription, Subject } from 'rxjs';
import { EmailSetupService } from './email-setup.service';
import { IPager } from '../../../shared/models/pager.model';
import { HandlingOrganisationSetting } from './email-setup.model';
import { NotificationService } from '../../../shared/services/notification.service';

@Component({
  selector: 'app-email-setup',
  templateUrl: './email-setup.component.html',
  styleUrls: ['./email-setup.component.css']
})
export class EmailSetupComponent implements OnInit {

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  @ViewChild('hOSettingTable') hOSettingTable: ElementRef;
  

  public paginationInfo: IPager;
  public authenticated: boolean = false;
  public authSubscription: Subscription;
  public errorReceived: boolean;
  public dtTrigger: Subject<any>;
  isGridLoaded: boolean = false;
  //public searchPayBACSVM: HandlingOrganisationSetting = new HandlingOrganisationSetting();

  //public searchPayBACSResult: IHandlingOrganisationSetting[];
  handlingOrganisationsSettingResult: any[];
  public dtOptions: any = {};

  isSelectAllChecked: boolean;

  displayedColumns: any[] = [    
    { isAlwaysVisible: false, title: 'ID', data: 'id', visible: false },
    { isAlwaysVisible: false, title: 'HandlingOrganisation ID', data: 'organisationID', visible: false },
    { isAlwaysVisible: true, title: 'Handling Organisation', data: 'handlingOrganisationName', visible: true },
    { isAlwaysVisible: true, title: 'Enable / Disable', data: 'isAutoEmailEnable', visible: true },
  ];

  constructor(private emailSetupService: EmailSetupService, private notificationService: NotificationService) {
    this.dtTrigger = new Subject();
  }

  ngOnInit(): void {
    this.initialiseDataTable();
    this.isSelectAllChecked = false;
    this.refreshGrid();
  }

  initialiseDataTable(): void {
    const that = this;
    //const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedColumns,
      'columnDefs': [        
        { width: "90%", targets: 2 },
        {
          className: "dt-body-center",targets: 3,
          render: function (data, type, full, meta: any) {
            return '<input type="checkbox" class="select-checkbox">'; 
          },
          'bSortable': false
        },
      ],
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [{}],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Set the checked state of the checkbox in the table
        $('input.select-checkbox', row).prop('checked', data["isAutoEmailEnable"] == true);
        // Unbind first in order to avoid any duplicate handler        
        $('.select-checkbox', row).unbind('change');
        $('.select-checkbox', row).bind('change', () => {
          self.checkboxClick(data);
        });
        
        return row;
      },
      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        this.emailSetupService.getAllHandlingOrganisationsSetting().subscribe((response: any) => {
          this.handlingOrganisationsSettingResult = response;
          callback({
            data: response
          });
        });
      }
    };
  }

  //bindHandlingOrganisations() {
  //  this.emailSetupService.getAllHandlingOrganisationsSetting()
  //    .subscribe((reponse: any) => {
  //      this.handlingOrganisationsSettingResult = reponse;       
  //    })

  //}
  
  checkboxClick(data) {
    data["isAutoEmailEnable"] = !data["isAutoEmailEnable"];

    this.emailSetupService.putHandlingOrganisationsSetting(data).subscribe((reponse: any) => {
      this.notificationService.printSuccessMessage('Auto Email Setting updated successfully');
      this.refreshGrid();
    },
      error => {
        this.notificationService.printErrorMessage('Error occured  in updating Auto Email Setting');
      }
    );
  }

  refreshGrid() {
    if (this.hOSettingTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}

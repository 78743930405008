import { ClaimDetailNotes } from "./claim-detail-notes.model";

export class ClaimHandlingReviewNotes {
  //claimHandlingReviewNotesId  :number;
  id: number;
  claimHandlingNotesId: number;
  notesDate: Date;
  notesComment: string;
  handlerID: string;
  //ClaimHandlingNotes  : ClaimDetailNotes
  updatedByUserName: string;
}

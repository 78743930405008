import { Injectable } from '@angular/core';
import { IClaimReinsurance, RecoverableReceivable } from './claim-reinsurance.model';
import { ReinsuranceService } from '../../../shared/services/reinsurance.service';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { forEach } from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class ClaimReinsuranceService {

  formData: IClaimReinsurance;

  constructor(private reinsuranceService: ReinsuranceService) { }

  getClaimReinsuranceByClaimId(id: number): any {
    return this.reinsuranceService.getClaimReinsuranceById(id);
  }

  UpdateClaimReinsurance(): any {
    var body = {
      ...this.formData
    };
    if (this.formData.id > 0) {
      return this.reinsuranceService.updateClaimReinsurance(body);
    }
    else {
      return this.reinsuranceService.updateClaimReinsurance(body);
    }
  }

  //getRecoverableReceivable(riRecoverableId: number): any {
  //  return this.reinsuranceService.getRecoverableReceivable(riRecoverableId);
  //}

  //saveRecoverableReceivable(data: RecoverableReceivable): any {
  //  return this.reinsuranceService.saveRecoverableReceivable(data);
  //}

  //updateRecoverableReceivable(data: RecoverableReceivable): any {
  //  return this.reinsuranceService.updateRecoverableReceivable(data);
  //}

  //deleteRecoverableReceivable(Id: number): any {
  //  return this.reinsuranceService.deleteRecoverableReceivable(Id);
  //}

  downloadReinsuranceDetails(claimId) {
    this.reinsuranceService.getClaimReinsuranceDetailForDownload(claimId).subscribe((response: any) => {

      var proRataReinsurer = [4, 19, 35, 40, 50, 74];
      const title = 'Reinsurance Details of Claim ' + response.claimReference;

      const allocationOfIncurredCostHeader = ["Year", "Exposure Days", "Share Exp", "Share Incurred (£)"];
      const reinsuraneceByBasisHeader = (response.isMesoClaim && response.lineOfBusiness == 146) ? ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)", "PRO RATA Plus (£)"] : ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)"];

      const reinsuranceByReinsurerHeader = (response.isMesoClaim && response.lineOfBusiness == 146) ? ["Year", "Layer", "Excess (£)", "Limit (£)", "RI ID", "Reinsurer Name", "Status", "RI %", "PRO RATA (£)", "PRO RATA PLUS (£)"]
        : ["Year", "Layer", "Excess (£)", "Limit (£)", "RI ID", "Reinsurer Name", "Status", "RI %", "PRO RATA (£)"];
      const reinsuranceByReinsurerSummaryHeader = (response.isMesoClaim && response.lineOfBusiness == 146) ? ["RI ID", "Reinsurer Name", "RI Status", "PRO RATA (£)", "PRO RATA PLUS (£)", "Amount Submitted (£)", "Amount Agreed (£)", "Amount Received (£)", "Variation (£)", "Invoice Number", "Notification Status"]
        : ["RI ID", "Reinsurer Name", "RI Status", "PRO RATA (£)", "Amount Submitted (£)", "Amount Agreed (£)", "Amount Received (£)", "Variation (£)", "Invoice Number", "Notification Status"];


      //const equitableHeader = ["Policy ID", "Policy Number", "Exposure From", "Exposure To", "Excess From", "	Excess To", "Excess Amount (Ã‚£)", "LTA", "LTA From", "LTA To", "Policy Days", "Exposure Days", "Share Percentage", "Share of Incurred (Ã‚£)", "Available EQUITABLE EXCESS (Ã‚£)", "Utilised EQUITABLE EXCESS (Ã‚£)"];
      //var header = proRataHeader;
      //const exposureHeader = ["Period", "MMI Exposure From", "MMI Exposure To", "Policy Number"];
      let allocationOfIncurredCostData: any[] = [];
      let reinsuraneceByBasisdata: any[] = [];
      let reinsuranceByReinsurerData: any[] = [];
      let reinsuranceByReinsurerSummaryData: any[] = [];
      response.allocationOfIncurredCosts.forEach(item => {
        allocationOfIncurredCostData.push([item["exposureYear"]
          , item["exposureDays"]
          , item["shareExposurePct"]
          , item["shareIncurred"]]);
      });

      response.reinsuranceByBasis.forEach(item => {
        if (response.isMesoClaim && response.lineOfBusiness == 146) {
          reinsuraneceByBasisdata.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]
            , item["proRataPlus"]]);
        } else {
          reinsuraneceByBasisdata.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]]);
        }

      });

      response.reinsuranceByReinsurer.forEach(item => {
        if (response.isMesoClaim && response.lineOfBusiness == 146) {
          reinsuranceByReinsurerData.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["riId"]
            , item["reinsurerName"]
            , item["status"]
            , item["riPercent"]
            , item["proRata"]
            , proRataReinsurer.some(r => r == item["riId"]) ? item["proRataPlus"] : '']);
        } else {
          reinsuranceByReinsurerData.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["riId"]
            , item["reinsurerName"]
            , item["status"]
            , item["riPercent"]
            , item["proRata"]]);
        }

      });

      response.reinsuranceByReinsurerSummary.forEach(item => {
        if (response.isMesoClaim && response.lineOfBusiness == 146) {
          reinsuranceByReinsurerSummaryData.push([item["riId"]
            , item["reinsurerName"]
            , item["riStatus"]
            , item["proRataCalc"]
            , proRataReinsurer.some(r => r == item["riId"]) ? item["proRataPlus"] : ''
            , item["amountSubmitted"]
            , item["totalAmountAgreed"]
            //  , item["dateAgreed"] == null ? "" : moment(item["dateAgreed"]).format('DD/MM/YYYY')
            , item["totalAmountReceived"]
            // , item["dateReceived"] == null ? "" : moment(item["dateReceived"]).format('DD/MM/YYYY')
            , item["variation"]
            , item["invoiceNo"] == null ? "" : item["invoiceNo"]
            , item["notificationStatus"] == null ? "" : item["notificationStatus"]]);
        } else {
          reinsuranceByReinsurerSummaryData.push([item["riId"]
            , item["reinsurerName"]
            , item["riStatus"]
            , item["proRataCalc"]
            , item["amountSubmitted"]
            , item["totalAmountAgreed"]
            //  , item["dateAgreed"] == null ? "" : moment(item["dateAgreed"]).format('DD/MM/YYYY')
            , item["totalAmountReceived"]
            // , item["dateReceived"] == null ? "" : moment(item["dateReceived"]).format('DD/MM/YYYY')
            , item["variation"]
            , item["invoiceNo"] == null ? "" : item["invoiceNo"]
            , item["notificationStatus"] == null ? "" : item["notificationStatus"]]);
        }
      });

      //response.policyExposures.forEach((item, index) => {
      //  exposureData.push([index + 1
      //    , moment(item["exposureFrom"]).format('DD/MM/YYYY')
      //    , moment(item["exposureTo"]).format('DD/MM/YYYY')
      //    , item["policyNumber"]]);
      //});

      //Create workbook and worksheet
      let workbook: ExcelProper.Workbook = new Excel.Workbook();
      let worksheet = workbook.addWorksheet('Reinsurance Details');

      //Add Row and formatting
      let titleRow = worksheet.addRow([title]);
      titleRow.font = { bold: true, size: 16 };
      titleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });
      worksheet.mergeCells('A1:L2');
      //Blank Row 
      worksheet.addRow([]);

      let summaryRow1 = worksheet.addRow(["Claim Id", response.claimId]);
      this.fillBackGroundInExcellCell(summaryRow1.findCell(1));
      summaryRow1.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow1.findCell(1));
      this.setBorderExcelCell(summaryRow1.findCell(2));
      let summaryRow2 = worksheet.addRow(["Claim Reference", response.claimReference]);
      this.fillBackGroundInExcellCell(summaryRow2.findCell(1));
      summaryRow2.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow2.findCell(1));
      this.setBorderExcelCell(summaryRow2.findCell(2));
      let summaryRow3 = worksheet.addRow(["Insured Title", response.insuredTitle]);
      this.fillBackGroundInExcellCell(summaryRow3.findCell(1));
      summaryRow3.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow3.findCell(1));
      this.setBorderExcelCell(summaryRow3.findCell(2));
      let summaryRowX = worksheet.addRow(["Claimant Name", response.claimantName]);
      this.fillBackGroundInExcellCell(summaryRowX.findCell(1));
      summaryRowX.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRowX.findCell(1));
      this.setBorderExcelCell(summaryRowX.findCell(2));

      worksheet.addRow([]);

      let summaryRow4 = worksheet.addRow(["MMI Valuation", response.mmiValuation]);
      this.fillBackGroundInExcellCell(summaryRow4.findCell(1));
      summaryRow4.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow4.findCell(1));
      this.setBorderExcelCell(summaryRow4.findCell(2));
      summaryRow4.findCell(2).numFmt = '£###,###,##0.00;'
      let summaryRow5 = worksheet.addRow(["Excess Paid", response.excessPaid]);
      this.fillBackGroundInExcellCell(summaryRow5.findCell(1));
      summaryRow5.findCell(1).font = { bold: true, italic: true };
      summaryRow5.findCell(2).numFmt = '£###,###,##0.00;'
      let summaryRow6 = worksheet.addRow(["MMI Paid", response.netPaid]);
      this.fillBackGroundInExcellCell(summaryRow6.findCell(1));
      summaryRow6.findCell(1).font = { bold: true, italic: true };
      summaryRow6.findCell(2).numFmt = '£###,###,##0.00;'
      this.setBorderExcelCell(summaryRow6.findCell(1));
      this.setBorderExcelCell(summaryRow6.findCell(2));
      let summaryRow7 = worksheet.addRow(["MMI Reserve", response.outstandingAmount]);
      this.fillBackGroundInExcellCell(summaryRow7.findCell(1));
      summaryRow7.findCell(1).font = { bold: true, italic: true };
      summaryRow7.findCell(2).numFmt = '£###,###,##0.00;'
      this.setBorderExcelCell(summaryRow7.findCell(1));
      this.setBorderExcelCell(summaryRow7.findCell(2));
      let summaryRow8 = worksheet.addRow(["MMI Incurred", response.totalIncurred]);
      this.fillBackGroundInExcellCell(summaryRow8.findCell(1));
      summaryRow8.findCell(2).numFmt = '£###,###,##0.00;'
      summaryRow8.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow8.findCell(1));
      this.setBorderExcelCell(summaryRow8.findCell(2));
      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      let exposureTitleRow = worksheet.addRow(["Allocation Of Incurred Costs"]);
      exposureTitleRow.font = { bold: true };
      exposureTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      worksheet.mergeCells('A16:D16');

      let allocationOfIncurredHeaderRow = worksheet.addRow(allocationOfIncurredCostHeader);
      // Cell Style : Fill and Border
      allocationOfIncurredHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      allocationOfIncurredHeaderRow.font = { bold: true };
      allocationOfIncurredCostData.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
        this.setCellFormatOfAllocationOfIncurred(row);
      });

      let allocationOfIncurredFooter = ['Grand Total',
        _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.exposureDays }),
        100.00,
        _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.shareIncurred })
      ]
      //Add Footer Row
      let allocationOfIncurredFooterRow = worksheet.addRow(allocationOfIncurredFooter);
      allocationOfIncurredFooterRow.font = { bold: true };
      this.setCellFormatOfAllocationOfIncurred(allocationOfIncurredFooterRow);
      // Cell Style : Fill and Border
      allocationOfIncurredFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });



      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);


      //Add Reinsurance By Basis
      let reinsuranceByBasisTitleRow = worksheet.addRow(["Reinsurance By Basis"]);
      reinsuranceByBasisTitleRow.font = { bold: true };
      reinsuranceByBasisTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      console.log(reinsuranceByBasisTitleRow);

      worksheet.mergeCells('A' + reinsuranceByBasisTitleRow.number + ((response.isMesoClaim && response.lineOfBusiness == 146) ? ':F' : ':E') + reinsuranceByBasisTitleRow.number);
      let reinsuranceByBasisHeaderRow = worksheet.addRow(reinsuraneceByBasisHeader);
      // Cell Style : Fill and Border
      reinsuranceByBasisHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByBasisHeaderRow.font = { bold: true };
      var yearIndex = 0;
      reinsuraneceByBasisdata.forEach((d, i, array) => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
        this.setCellFormatOfReinsuranceByBasisFirst(row, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuraneceByBasisdata.length - 1 || d[0] != reinsuraneceByBasisdata[i + 1][0]) {

          let footer = (response.isMesoClaim && response.lineOfBusiness == 146) ? [d[0] + ' Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRata : 0 }), _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRataPlus : 0 })]
            : [d[0] + ' Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRata : 0 })];
          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);
          });
          this.setCellFormatOfReinsuranceByBasisFirst(footerRow, response.isMesoClaim, response.lineOfBusiness);
          worksheet.mergeCells('A' + (row.number - yearIndex) + ':A' + row.number);
          yearIndex = -1;
        }
        yearIndex++;
      });
      let footer = (response.isMesoClaim && response.lineOfBusiness == 146) ? ['Grand Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata }), _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRataPlus })]
        : ['Grand Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata })];
      //Add Footer Row
      let footerRow = worksheet.addRow(footer);
      footerRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByBasisFirst(footerRow, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      footerRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Reinsurance By Reinsurer
      let reinsuranceByReinsurerTitleRow = worksheet.addRow(["Reinsurance By Reinsurer"]);
      reinsuranceByReinsurerTitleRow.font = { bold: true };
      reinsuranceByReinsurerTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      // console.log(reinsuranceByBasisTitleRow);

      worksheet.mergeCells('A' + reinsuranceByReinsurerTitleRow.number + ((response.isMesoClaim && response.lineOfBusiness == 146) ? ':J' : ':I') + reinsuranceByReinsurerTitleRow.number);
      let reinsuranceByReinsurerHeaderRow = worksheet.addRow(reinsuranceByReinsurerHeader);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByReinsurerHeaderRow.font = { bold: true };
      var layerIndex = 0;
      var yearIndex = 0;
      reinsuranceByReinsurerData.forEach((d, i, array) => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
        this.setCellFormatOfReinsuranceByReinsurerFirst(row, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuranceByReinsurerData.length - 1 || d[1] != reinsuranceByReinsurerData[i + 1][1] || d[0] != reinsuranceByReinsurerData[i + 1][0]) {
          let footer = (response.isMesoClaim && response.lineOfBusiness == 146) ? ['', 'Total', '', '', '', '', '', '', _.sumBy(response.reinsuranceByReinsurer, (o: any) => { return (o.year == d[0] && o.layer == d[1]) ? o.proRata : 0 }), _.sumBy(response.reinsuranceByReinsurer, (o: any) => { return (o.year == d[0] && o.layer == d[1] && proRataReinsurer.some(r => r == o.riId)) ? o.proRataPlus : 0 })]
            : ['', 'Total', '', '', '', '', '', '', _.sumBy(response.reinsuranceByReinsurer, (o: any) => { return (o.year == d[0] && o.layer == d[1]) ? o.proRata : 0 })];
          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          this.setCellFormatOfReinsuranceByReinsurerFirst(footerRow, response.isMesoClaim, response.lineOfBusiness);
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            if (number != 1) {
              this.fillBackGroundInExcellCell(cell);
            }
            this.setBorderExcelCell(cell);

          });
          worksheet.mergeCells('B' + (row.number - layerIndex) + ':B' + row.number);
          worksheet.mergeCells('C' + (row.number - layerIndex) + ':C' + row.number);
          worksheet.mergeCells('D' + (row.number - layerIndex) + ':D' + row.number);
          //worksheet.mergeCells('E' + (row.number - layerIndex) + ':E' + row.number);
          //worksheet.mergeCells('F' + (row.number - layerIndex) + ':F' + row.number);
          layerIndex = -1;
          yearIndex++;
        }
        if (i == reinsuranceByReinsurerData.length - 1 || d[0] != reinsuranceByReinsurerData[i + 1][0]) {
          let footer = (response.isMesoClaim && response.lineOfBusiness == 146) ? [d[0] + ' Total', '', '', '', '', '', '', '', _.sumBy(response.reinsuranceByReinsurer, (o: any) => { return o.year == d[0] ? o.proRata : 0 }), _.sumBy(response.reinsuranceByReinsurer, (o: any) => { return (o.year == d[0] && proRataReinsurer.some(r => r == o.riId)) ? o.proRataPlus : 0 })]
            : [d[0] + ' Total', '', '', '', '', '', '', '', _.sumBy(response.reinsuranceByReinsurer, (o: any) => { return o.year == d[0] ? o.proRata : 0 })];
          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          this.setCellFormatOfReinsuranceByReinsurerFirst(footerRow, response.isMesoClaim, response.lineOfBusiness);
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

          });
          worksheet.mergeCells('A' + (row.number - yearIndex + 1) + ':A' + (row.number + 1));
          yearIndex = -1;
        }
        layerIndex++;
        yearIndex++;
      });

      let reinsuranceByReinsurerFooter = (response.isMesoClaim && response.lineOfBusiness == 146) ? ['Grand Total', '', '', '', '', '', '', '', _.sumBy(response.reinsuranceByReinsurer, (o: any) => { return o.proRata }), _.sumBy(response.reinsuranceByReinsurer, (o: any) => { return proRataReinsurer.some(r => r == o.riId) ? o.proRataPlus : 0 })]
        : ['Grand Total', '', '', '', '', '', '', '', _.sumBy(response.reinsuranceByReinsurer, (o: any) => { return o.proRata })];
      //Add Footer Row
      let reinsuranceByReinsurerFooterRow = worksheet.addRow(reinsuranceByReinsurerFooter);
      reinsuranceByReinsurerFooterRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByReinsurerFirst(reinsuranceByReinsurerFooterRow, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });


      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Reinsurance By Reinsurer Summary
      let reinsuranceByReinsurerSummaryTitleRow = worksheet.addRow(["Reinsurance By Reinsurer: Summary"]);
      reinsuranceByReinsurerSummaryTitleRow.font = { bold: true };
      reinsuranceByReinsurerSummaryTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      // console.log(reinsuranceByBasisTitleRow);
      worksheet.mergeCells('A' + reinsuranceByReinsurerSummaryTitleRow.number + ((response.isMesoClaim && response.lineOfBusiness == 146) ? ':K' : ':J') + reinsuranceByReinsurerSummaryTitleRow.number);
      let reinsuranceByReinsurerSummaryHeaderRow = worksheet.addRow(reinsuranceByReinsurerSummaryHeader);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerSummaryHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByReinsurerSummaryHeaderRow.font = { bold: true };
      reinsuranceByReinsurerSummaryData.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);
        })
        this.setCellFormatOfReinsuranceByReinsurerSummarFirst(row, response.isMesoClaim, response.lineOfBusiness);
      });

      let reinsuranceByReinsurerSummaryFooter = (response.isMesoClaim && response.lineOfBusiness == 146) ? ['Grand Total', '', '',
        _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return o.proRataCalc }),
        _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return proRataReinsurer.some(r => r == o.riId) ? o.proRataPlus : 0 }),
        _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return o.amountSubmitted }),
        _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return o.totalAmountAgreed }),
        _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return o.totalAmountReceived }),
        _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return o.variation }),
        '', '']

        : ['Grand Total', '', '',
          _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return o.proRataCalc }),
          _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return o.amountSubmitted }),
          _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return o.totalAmountAgreed }),
          _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return o.totalAmountReceived }),
          _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return o.variation }),
          '', ''];

      //Add Footer Row
      let reinsuranceByReinsurerSummaryFooterRow = worksheet.addRow(reinsuranceByReinsurerSummaryFooter);
      reinsuranceByReinsurerSummaryFooterRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByReinsurerSummarFirst(reinsuranceByReinsurerSummaryFooterRow, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerSummaryFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      for (var i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 25;
      }

      //Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'Reinsurance-Detail-Claim-' + claimId + '_' + Math.random() + '.xlsx');
      })
    });
  }
  setCellFormatOfAllocationOfIncurred(row: ExcelProper.Row) {
    row.findCell(3).numFmt = '#0.00"%";'
    row.findCell(4).numFmt = '£###,###,##0.00;'
  }

  //setCellFormatOfReinsuranceByBasis(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
  //  row.findCell(3).numFmt = '£###,###,##0.00;'
  //  row.findCell(4).numFmt = '£###,###,##0.00;'
  //  row.findCell(5).numFmt = '£###,###,##0.00;'
  //  if (isMesoClaim && lineOfBusiness == 146) {
  //    row.findCell(6).numFmt = '£###,###,##0.00;'
  //  }
  //}
  setCellFormatOfReinsuranceByBasis(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(3).numFmt = '£###,###,##0.00;'
    row.findCell(4).numFmt = '£###,###,##0.00;'
    row.findCell(5).numFmt = '£###,###,##0.00;'
    //if (isMesoClaim && lineOfBusiness == 146) {
    //  row.findCell(6).numFmt = '£###,###,##0.00;'
    //}
  }

  setCellFormatOfReinsuranceByBasisFirst(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(3).numFmt = '£###,###,##0.00;'
    row.findCell(4).numFmt = '£###,###,##0.00;'
    row.findCell(5).numFmt = '£###,###,##0.00;'
    if (isMesoClaim && lineOfBusiness == 146) {
      row.findCell(6).numFmt = '£###,###,##0.00;'
    }
  }

  setCellFormatOfReinsuranceByBasisProPlus(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(3).numFmt = '£###,###,##0.00;'
    row.findCell(4).numFmt = '£###,###,##0.00;'
    row.findCell(5).numFmt = '£###,###,##0.00;'
    row.findCell(6).numFmt = '£###,###,##0.00;'
  }

  setCellFormatOfReinsuranceByBasisYears(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(3).numFmt = '£###,###,##0.00;'
    row.findCell(4).numFmt = '£###,###,##0.00;'
    row.findCell(5).numFmt = '£###,###,##0.00;'
    //if (isMesoClaim && lineOfBusiness == 146) {
    //  row.findCell(6).numFmt = '£###,###,##0.00;'
    //}
  }

  setCellFormatOfReinsuranceByBasisTotal(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(2).numFmt = '£###,###,##0.00;'
    /*row.findCell(3).numFmt = '£###,###,##0.00;'*/
    /*row.findCell(4).numFmt = '£###,###,##0.00;'*/
    /*row.findCell(5).numFmt = '£###,###,##0.00;'*/
    //if (isMesoClaim && lineOfBusiness == 146) {
    //  row.findCell(5).numFmt = '£###,###,##0.00;'
  }

  //setCellFormatOfReinsuranceByBasisYearsProPlus(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
  //  row.findCell(3).numFmt = '£###,###,##0.00;'
  //  row.findCell(4).numFmt = '£###,###,##0.00;'
  //  row.findCell(5).numFmt = '£###,###,##0.00;'
  //  //if (isMesoClaim && lineOfBusiness == 146) {
  //  //  row.findCell(6).numFmt = '£###,###,##0.00;'
  //  //}
  //}

  setCellFormatOfReinsuranceByBasisYearsProPlus(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(3).numFmt = '£###,###,##0.00;'
    row.findCell(4).numFmt = '£###,###,##0.00;'
    row.findCell(5).numFmt = '£###,###,##0.00;'
    row.findCell(6).numFmt = '£###,###,##0.00;'
  }

  setCellFormatOfReinsuranceByBasisTotalProPlus(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(2).numFmt = '£###,###,##0.00;'
    row.findCell(3).numFmt = '£###,###,##0.00;'
    //row.findCell(3).numFmt = '£###,###,##0.00;'
    //row.findCell(4).numFmt = '£###,###,##0.00;'
    /*row.findCell(5).numFmt = '£###,###,##0.00;'*/
    //row.findCell(5).numFmt = '£###,###,##0.00;'
  }

  //setCellFormatOfReinsuranceByReinsurer(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
  //  row.findCell(3).numFmt = '£###,###,##0.00;'
  //  row.findCell(4).numFmt = '£###,###,##0.00;'
  //  row.findCell(8).numFmt = '#0.000"%";'
  //  row.findCell(9).numFmt = '£###,###,##0.00;'
  //  if (isMesoClaim && lineOfBusiness == 146) {
  //    row.findCell(10).numFmt = '£###,###,##0.00;'
  //  }
  //}

  setCellFormatOfReinsuranceByReinsurerProPlus(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(3).numFmt = '£###,###,##0.00;'
    row.findCell(4).numFmt = '£###,###,##0.00;'
    row.findCell(8).numFmt = '#0.000"%";'
    row.findCell(9).numFmt = '£###,###,##0.00;'
    row.findCell(10).numFmt = '£###,###,##0.00;'
  }

  setCellFormatOfReinsuranceByReinsurer(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(3).numFmt = '£###,###,##0.00;'
    row.findCell(4).numFmt = '£###,###,##0.00;'
    row.findCell(8).numFmt = '#0.000"%";'
    row.findCell(9).numFmt = '£###,###,##0.00;'
  }

  setCellFormatOfReinsuranceByReinsurerFirst(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(3).numFmt = '£###,###,##0.00;'
    row.findCell(4).numFmt = '£###,###,##0.00;'
    row.findCell(8).numFmt = '#0.000"%";'
    row.findCell(9).numFmt = '£###,###,##0.00;'
    if (isMesoClaim && lineOfBusiness == 146) {
      row.findCell(10).numFmt = '£###,###,##0.00;'
    }
  }


  //setCellFormatOfReinsuranceByReinsurerSummar(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
  //  row.findCell(4).numFmt = '£###,###,##0.00;'
  //  if (isMesoClaim && lineOfBusiness == 146) {
  //    row.findCell(5).numFmt = '£###,###,##0.00;'
  //    row.findCell(6).numFmt = '£###,###,##0.00;'
  //    row.findCell(7).numFmt = '£###,###,##0.00';
  //    row.findCell(8).numFmt = '£###,###,##0.00';
  //    row.findCell(9).numFmt = '£###,###,##0.00';
  //  } else {
  //    row.findCell(5).numFmt = '£###,###,##0.00;'
  //    row.findCell(6).numFmt = '£###,###,##0.00;'
  //    row.findCell(8).numFmt = '£###,###,##0.00;'
  //    // row.findCell(10).numFmt = '£###,###,##0.00;'
  //  }
  //}
  setCellFormatOfReinsuranceByReinsurerSummar(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(5).numFmt = '£###,###,##0.00;'
    row.findCell(6).numFmt = '£###,###,##0.00;'
    row.findCell(7).numFmt = '£###,###,##0.00;'
    row.findCell(8).numFmt = '£###,###,##0.00';
    row.findCell(9).numFmt = '£###,###,##0.00';
    //row.findCell(9).numFmt = '£###,###,##0.00';
  }

  setCellFormatOfReinsuranceByReinsurerSummarFirst(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(4).numFmt = '£###,###,##0.00;'
    row.findCell(5).numFmt = '£###,###,##0.00;'
    row.findCell(6).numFmt = '£###,###,##0.00;'
    row.findCell(7).numFmt = '£###,###,##0.00';
    row.findCell(8).numFmt = '£###,###,##0.00';
    row.findCell(9).numFmt = '£###,###,##0.00';
  }

  setCellFormatOfReinsuranceByReinsurerSummarProPlus(row: ExcelProper.Row, isMesoClaim: boolean, lineOfBusiness: number) {
    row.findCell(5).numFmt = '£###,###,##0.00;'
    row.findCell(6).numFmt = '£###,###,##0.00;'
    row.findCell(7).numFmt = '£###,###,##0.00;'
    row.findCell(8).numFmt = '£###,###,##0.00';
    row.findCell(9).numFmt = '£###,###,##0.00';
    row.findCell(10).numFmt = '£###,###,##0.00';
  }

  setBorderExcelCell(cell: any) {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    cell.alignment = { vertical: 'middle' };
  }

  fillBackGroundInExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00C4C4C4' },
      bgColor: { argb: 'FF0000FF' }
    }
  }

  downloadRIExportProRataPlus(claimId) {
    this.reinsuranceService.getClaimReinsuranceDetailForDownload(claimId).subscribe((response: any) => {
      //debugger;
      var proRataReinsurer = [4, 19, 35, 40, 50, 74];
      const title = 'Reinsurance Details of Claim ' + response.claimReference;

      const allocationOfIncurredCostHeader = ["Year", "Exposure Days", "Share Exp", "Share Incurred (£)"];
      const reinsuraneceByBasisHeader = (response.isMesoClaim) ? ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)", "PRO RATA Plus (£)"] : ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)", "PRO RATA Plus (£)"];

      const reinsuranceByReinsurerHeader = (response.isMesoClaim) ? ["Year", "Layer", "Excess (£)", "Limit (£)", "RI ID", "Reinsurer Name", "Status", "RI %", "PRO RATA (£)", "PRO RATA PLUS (£)"]
        : ["Year", "Layer", "Excess (£)", "Limit (£)", "RI ID", "Reinsurer Name", "Status", "RI %", "PRO RATA (£)", "PRO RATA PLUS (£)"];

      const reinsuranceByReinsurerSummaryHeader = (response.isMesoClaim) ? ["RI ID", "Reinsurer Name", "Parent Name", "RI Status", "PRO RATA (£)", "PRO RATA PLUS (£)", "Amount Submitted (£)", "Amount Agreed (£)", "Amount Received (£)", "Variation (£)", "Invoice Number", "Notification Status"]
        : ["RI ID", "Reinsurer Name", "Parent Name", "RI Status", "PRO RATA (£)", "PRO RATA PLUS (£)", "Amount Submitted (£)", "Amount Agreed (£)", "Amount Received (£)", "Variation (£)", "Invoice Number", "Notification Status"];

      const reinsuraneceByBasisYearsHeader = ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)", "PRO RATA Plus (£)"];

      /* const reinsuraneceByBasisTotalHeader = ["Year", "Excess (£)", "Limit (£)", "PRO RATA (£)", "PRO RATA Plus (£)"];*/
      const reinsuraneceByBasisTotalHeader = ["Year", "PRO RATA (£)", "PRO RATA Plus (£)"];


      //const equitableHeader = ["Policy ID", "Policy Number", "Exposure From", "Exposure To", "Excess From", "	Excess To", "Excess Amount (Ã‚£)", "LTA", "LTA From", "LTA To", "Policy Days", "Exposure Days", "Share Percentage", "Share of Incurred (Ã‚£)", "Available EQUITABLE EXCESS (Ã‚£)", "Utilised EQUITABLE EXCESS (Ã‚£)"];
      //var header = proRataHeader;
      //const exposureHeader = ["Period", "MMI Exposure From", "MMI Exposure To", "Policy Number"];
      let allocationOfIncurredCostData: any[] = [];
      let reinsuraneceByBasisdata: any[] = [];
      let reinsuranceByReinsurerDataProRataPlus: any[] = [];
      let reinsuranceByReinsurerSummaryData: any[] = [];
      let reinsuraneceByBasisYearsdata: any[] = [];
      let reinsuraneceByBasisTotaldata: any[] = [];
      let reinsuranceByReinsurerDataNonZeroProRataPlus: any[] = [];
      response.allocationOfIncurredCosts.forEach(item => {
        if (response.isMesoClaim) {
          allocationOfIncurredCostData.push([item["exposureYear"]
            , item["exposureDays"]
            , item["shareExposurePct"]
            , item["shareIncurred"]]);
        }
      });

      //reinsuraneceByBasisdata
      response.reinsuranceByBasis.forEach(item => {
        if (response.isMesoClaim) {
          reinsuraneceByBasisdata.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]
            , item["proRataPlus"]
          ]);
        }

      });

      //reinsuraneceByBasisYearsdata
      response.reinsuranceByBasis.forEach(item => {
        if (response.isMesoClaim) {
          reinsuraneceByBasisYearsdata.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]
            , item["proRataPlus"]]);
        }
      });

      //reinsuraneceByBasisTotaldata
      response.reinsuranceByBasis.forEach(item => {
        if (response.isMesoClaim) {
          reinsuraneceByBasisTotaldata.push([item["year"]
            /* , item["layer"]*/
            //, item["excess"]
            //, item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]
            , item["proRataPlus"]]);
        }

      });

      var FilteredReinsuranceByReinsurerProRataPlus = response.reinsuranceByReinsurer.filter(
        r => r.status == "A (Active)" && r.parentReinsurerId == 25);

      FilteredReinsuranceByReinsurerProRataPlus.forEach(item => {
        if ((response.isMesoClaim)) {
          // if (proRataReinsurer.some(r => r == item["riId"]) == true) {
          reinsuranceByReinsurerDataProRataPlus.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["riId"]
            , item["reinsurerName"]
            , item["status"]
            , item["riPercent"]
            , item["proRata"]
            , proRataReinsurer.some(r => r == item["riId"]) ? item["proRataPlus"] : ""]);
          // }
        }
      });

      //response.reinsuranceByReinsurer.forEach(item => {
      //  if ((response.isMesoClaim) && (item["status"] == "A (Active)")) {
      //    if (proRataReinsurer.some(r => r == item["riId"]) == true) {
      //      reinsuranceByReinsurerData.push([item["year"]
      //        , item["layer"]
      //        , item["excess"]
      //        , item["limit"]
      //        //, item["shareExcess"]
      //        //, item["shareLimit"]
      //        , item["riId"]
      //        , item["reinsurerName"]
      //        , item["status"]
      //        , item["riPercent"]
      //        , item["proRata"]
      //        , proRataReinsurer.filter(r => r == item["riId"]) ? item["proRataPlus"] : '']);
      //    }
      //  }
      //});


      var FilteredReinsuranceByReinsurerSummary = response.reinsuranceByReinsurerSummary.filter(
        r => r.riStatus == "A (Active)" && r.parentReinsurerName == "Resolute");

      FilteredReinsuranceByReinsurerSummary.forEach(item => {
        if ((response.isMesoClaim)) {
          //debugger;
          //var itemVlaue = proRataReinsurer.some(r => r == item["riId"]);
          if (proRataReinsurer.some(r => r == item["riId"]) == true) {

            reinsuranceByReinsurerSummaryData.push([item["riId"]
              , item["reinsurerName"]
              , item["parentReinsurerName"]
              , item["riStatus"]
              , item["proRataCalc"]
              //, proRataReinsurer.filter(r => r == item["riId"]) ? item["proRataPlus"] : ''
              , item["proRataPlus"]
              , item["amountSubmitted"]
              , item["totalAmountAgreed"]
              //  , item["dateAgreed"] == null ? "" : moment(item["dateAgreed"]).format('DD/MM/YYYY')
              , item["totalAmountReceived"]
              // , item["dateReceived"] == null ? "" : moment(item["dateReceived"]).format('DD/MM/YYYY')
              , item["variation"]
              , item["invoiceNo"] == null ? "" : item["invoiceNo"]
              , item["notificationStatus"] == null ? "" : item["notificationStatus"]]);
          }
        }
      });

      //response.reinsuranceByReinsurerSummary.forEach(item => {
      //  if ((response.isMesoClaim) && (item["riStatus"] == "A (Active)") && (item["parentReinsurerName"] == "Resolute")) {
      //    //debugger;
      //    //var itemVlaue = proRataReinsurer.some(r => r == item["riId"]);
      //    if (proRataReinsurer.some(r => r == item["riId"]) == true) {

      //      reinsuranceByReinsurerSummaryData.push([item["riId"]
      //        , item["reinsurerName"]
      //        , item["parentReinsurerName"]
      //        , item["riStatus"]
      //        , item["proRataCalc"]
      //        //, proRataReinsurer.filter(r => r == item["riId"]) ? item["proRataPlus"] : ''
      //        , item["proRataPlus"]
      //        , item["amountSubmitted"]
      //        , item["totalAmountAgreed"]
      //        //  , item["dateAgreed"] == null ? "" : moment(item["dateAgreed"]).format('DD/MM/YYYY')
      //        , item["totalAmountReceived"]
      //        // , item["dateReceived"] == null ? "" : moment(item["dateReceived"]).format('DD/MM/YYYY')
      //        , item["variation"]
      //        , item["invoiceNo"] == null ? "" : item["invoiceNo"]
      //        , item["notificationStatus"] == null ? "" : item["notificationStatus"]]);
      //    }
      //  }
      //});

      //response.policyExposures.forEach((item, index) => {
      //  exposureData.push([index + 1
      //    , moment(item["exposureFrom"]).format('DD/MM/YYYY')
      //    , moment(item["exposureTo"]).format('DD/MM/YYYY')
      //    , item["policyNumber"]]);
      //});

      //Create workbook and worksheet
      let workbook: ExcelProper.Workbook = new Excel.Workbook();
      let worksheet = workbook.addWorksheet('Pro-Rata Plus');

      //Add Row and formatting
      let titleRow = worksheet.addRow([title]);
      titleRow.font = { bold: true, size: 16 };
      titleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });
      worksheet.mergeCells('A1:L2');
      //Blank Row 
      worksheet.addRow([]);

      let summaryRow1 = worksheet.addRow(["Claim Id", response.claimId]);
      this.fillBackGroundInExcellCell(summaryRow1.findCell(1));
      summaryRow1.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow1.findCell(1));
      this.setBorderExcelCell(summaryRow1.findCell(2));
      let summaryRow2 = worksheet.addRow(["Claim Reference", response.claimReference]);
      this.fillBackGroundInExcellCell(summaryRow2.findCell(1));
      summaryRow2.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow2.findCell(1));
      this.setBorderExcelCell(summaryRow2.findCell(2));
      let summaryRow3 = worksheet.addRow(["Insured Title", response.insuredTitle]);
      this.fillBackGroundInExcellCell(summaryRow3.findCell(1));
      summaryRow3.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow3.findCell(1));
      this.setBorderExcelCell(summaryRow3.findCell(2));
      let summaryRowX = worksheet.addRow(["Claimant Name", response.claimantName]);
      this.fillBackGroundInExcellCell(summaryRowX.findCell(1));
      summaryRowX.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRowX.findCell(1));
      this.setBorderExcelCell(summaryRowX.findCell(2));

      worksheet.addRow([]);

      let summaryRow4 = worksheet.addRow(["MMI Valuation", response.mmiValuation]);
      this.fillBackGroundInExcellCell(summaryRow4.findCell(1));
      summaryRow4.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow4.findCell(1));
      this.setBorderExcelCell(summaryRow4.findCell(2));
      summaryRow4.findCell(2).numFmt = '£###,###,##0.00;'
      let summaryRow5 = worksheet.addRow(["Excess Paid", response.excessPaid]);
      this.fillBackGroundInExcellCell(summaryRow5.findCell(1));
      summaryRow5.findCell(1).font = { bold: true, italic: true };
      summaryRow5.findCell(2).numFmt = '£###,###,##0.00;'
      let summaryRow6 = worksheet.addRow(["MMI Paid", response.netPaid]);
      this.fillBackGroundInExcellCell(summaryRow6.findCell(1));
      summaryRow6.findCell(1).font = { bold: true, italic: true };
      summaryRow6.findCell(2).numFmt = '£###,###,##0.00;'
      this.setBorderExcelCell(summaryRow6.findCell(1));
      this.setBorderExcelCell(summaryRow6.findCell(2));
      let summaryRow7 = worksheet.addRow(["MMI Reserve", response.outstandingAmount]);
      this.fillBackGroundInExcellCell(summaryRow7.findCell(1));
      summaryRow7.findCell(1).font = { bold: true, italic: true };
      summaryRow7.findCell(2).numFmt = '£###,###,##0.00;'
      this.setBorderExcelCell(summaryRow7.findCell(1));
      this.setBorderExcelCell(summaryRow7.findCell(2));
      let summaryRow8 = worksheet.addRow(["MMI Incurred", response.totalIncurred]);
      this.fillBackGroundInExcellCell(summaryRow8.findCell(1));
      summaryRow8.findCell(2).numFmt = '£###,###,##0.00;'
      summaryRow8.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow8.findCell(1));
      this.setBorderExcelCell(summaryRow8.findCell(2));
      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      let exposureTitleRow = worksheet.addRow(["Allocation Of Incurred Costs"]);
      exposureTitleRow.font = { bold: true };
      exposureTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      worksheet.mergeCells('A16:D16');

      let allocationOfIncurredHeaderRow = worksheet.addRow(allocationOfIncurredCostHeader);
      // Cell Style : Fill and Border
      allocationOfIncurredHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })

      let CellValallocationOfIncurredTotal = "";

      allocationOfIncurredHeaderRow.font = { bold: true };
      allocationOfIncurredCostData.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell D to show the formula on excel sheet.
          if (row.cellCount > 0) {
            if (cell.address.startsWith('D')) {
              CellValallocationOfIncurredTotal = CellValallocationOfIncurredTotal + cell.address + ',';
            }
          }

        })
        this.setCellFormatOfAllocationOfIncurred(row);
      });

      //let allocationOfIncurredFooter = (response.isMesoClaim) ? ['Grand Total',
      //  _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.exposureDays }),
      //  100.00,
      //  _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.shareIncurred })
      //] : ['Grand Total',
      //  '',
      //  '',
      //  ''
      //];

      let CellDisplayallocationOfIncurredTotal = CellValallocationOfIncurredTotal.replace(/,*$/, '');
      const firstCellallocationOfIncurredTotal = CellDisplayallocationOfIncurredTotal.split(',').shift();
      const lastCellallocationOfIncurredTotal = CellDisplayallocationOfIncurredTotal.split(',').pop();

      let allocationOfIncurredFooter = (response.isMesoClaim) ? ['Grand Total',
        _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.exposureDays }),
        100.00,
        { formula: '=SUM(' + firstCellallocationOfIncurredTotal + ':' + lastCellallocationOfIncurredTotal + ')', date1904: false }
      ] : ['Grand Total',
        '',
        '',
        ''
      ];
      //Add Footer Row
      let allocationOfIncurredFooterRow = worksheet.addRow(allocationOfIncurredFooter);
      allocationOfIncurredFooterRow.font = { bold: true };
      this.setCellFormatOfAllocationOfIncurred(allocationOfIncurredFooterRow);
      // Cell Style : Fill and Border
      allocationOfIncurredFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });



      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);


      //Add Reinsurance By Basis
      let reinsuranceByBasisTitleRow = worksheet.addRow(["Reinsurance By Basis"]);
      reinsuranceByBasisTitleRow.font = { bold: true };
      reinsuranceByBasisTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      console.log(reinsuranceByBasisTitleRow);

      worksheet.mergeCells('A' + reinsuranceByBasisTitleRow.number + ((response.isMesoClaim) ? ':F' : ':F') + reinsuranceByBasisTitleRow.number);
      let reinsuranceByBasisHeaderRow = worksheet.addRow(reinsuraneceByBasisHeader);
      // Cell Style : Fill and Border
      reinsuranceByBasisHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByBasisHeaderRow.font = { bold: true };
      var yearIndex = 0;

      let CellValReinsuraneceByBasis = "";
      let CellValReinsuraneceByBasisGT = "";
      let CellValReinsuraneceByBasis_F = "";
      let CellValReinsuraneceByBasisGT_F = "";

      reinsuraneceByBasisdata.forEach((d, i, array) => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);


          if (row.cellCount > 0) {
            //Extracting values for cell E to show the formula on excel sheet.
            if (cell.address.startsWith('E')) {
              CellValReinsuraneceByBasis = CellValReinsuraneceByBasis + cell.address + ',';
            }
            //Extracting values for cell F to show the formula on excel sheet.
            if (cell.address.startsWith('F')) {
              CellValReinsuraneceByBasis_F = CellValReinsuraneceByBasis_F + cell.address + ',';
            }
          }


        })
        this.setCellFormatOfReinsuranceByBasisProPlus(row, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuraneceByBasisdata.length - 1 || d[0] != reinsuraneceByBasisdata[i + 1][0]) {

          //let footer = (response.isMesoClaim) ? [d[0] + ' Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRata : 0 }), _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRataPlus : 0 })]
          //  : [d[0] + ' Total', '', '', '', '', ''];

          let CellDisplayReinsuraneceByBasis = CellValReinsuraneceByBasis.replace(/,*$/, '');
          const firstCellReinsuraneceByBasis = CellDisplayReinsuraneceByBasis.split(',').shift();
          const lastCellReinsuraneceByBasis = CellDisplayReinsuraneceByBasis.split(',').pop();

          let CellDisplayReinsuraneceByBasis_F = CellValReinsuraneceByBasis_F.replace(/,*$/, '');
          const firstCellReinsuraneceByBasis_F = CellDisplayReinsuraneceByBasis_F.split(',').shift();
          const lastCellReinsuraneceByBasis_F = CellDisplayReinsuraneceByBasis_F.split(',').pop();

          let footer = (response.isMesoClaim) ? [d[0] + ' Total', '', '', '', { formula: '=SUM(' + firstCellReinsuraneceByBasis + ':' + lastCellReinsuraneceByBasis + ')', date1904: false }, { formula: '=SUM(' + firstCellReinsuraneceByBasis_F + ':' + lastCellReinsuraneceByBasis_F + ')', date1904: false }]
            : [d[0] + ' Total', '', '', '', '', ''];

          CellValReinsuraneceByBasis = "";
          CellValReinsuraneceByBasis_F = "";

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

            if (footerRow.cellCount > 0) {
              //Extracting values for cell E to show the formula on excel sheet.
              if (cell.address.startsWith('E')) {
                CellValReinsuraneceByBasisGT = CellValReinsuraneceByBasisGT + cell.address + ',';
              }

              //Extracting values for cell F to show the formula on excel sheet.
              if (cell.address.startsWith('F')) {
                CellValReinsuraneceByBasisGT_F = CellValReinsuraneceByBasisGT_F + cell.address + ',';
              }
            }

          });
          this.setCellFormatOfReinsuranceByBasisProPlus(footerRow, response.isMesoClaim, response.lineOfBusiness);
          worksheet.mergeCells('A' + (row.number - yearIndex) + ':A' + row.number);
          yearIndex = -1;
        }
        yearIndex++;
      });

      //let footer = (response.isMesoClaim) ? ['Grand Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata }), _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRataPlus })]
      //  : ['Grand Total', '', '', '', '', ''];

      let CellDisplayReinsuraneceByBasisGT = CellValReinsuraneceByBasisGT.replace(/,*$/, '');
      let CellDisplayReinsuraneceByBasisGT_F = CellValReinsuraneceByBasisGT_F.replace(/,*$/, '');

      let footer = (response.isMesoClaim) ? ['Grand Total', '', '', '', { formula: '=SUM(' + CellDisplayReinsuraneceByBasisGT + ')', date1904: false }, { formula: '=SUM(' + CellDisplayReinsuraneceByBasisGT_F + ')', date1904: false }]
        : ['Grand Total', '', '', '', '', ''];

      //Add Footer Row
      let footerRow = worksheet.addRow(footer);
      footerRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByBasisProPlus(footerRow, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      footerRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //***************Start*********************Add Reinsurance By Basis Years*********************************************
      let reinsuranceByBasisYearsTitleRow = worksheet.addRow(["Reinsurance By Basis"]);
      reinsuranceByBasisYearsTitleRow.font = { bold: true };
      reinsuranceByBasisYearsTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      console.log(reinsuranceByBasisYearsTitleRow);

      worksheet.mergeCells('A' + reinsuranceByBasisYearsTitleRow.number + ':F' + reinsuranceByBasisYearsTitleRow.number);
      //worksheet.mergeCells('H27:L27');
      let reinsuranceByBasisYearsHeaderRow = worksheet.addRow(reinsuraneceByBasisYearsHeader);
      // Cell Style : Fill and Border
      reinsuranceByBasisYearsHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByBasisYearsHeaderRow.font = { bold: true };
      var yearIndex = 0;

      let CellValReinsuraneceByBasisYear = "";
      let CellValReinsuraneceByBasisYear_F = "";

      reinsuraneceByBasisYearsdata.forEach((d, i, array) => {
        let rowYear = worksheet.addRow(d);
        rowYear.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell E & F to show the formula on excel sheet.
          if (rowYear.cellCount > 0) {
            if (cell.address.startsWith('E')) {
              CellValReinsuraneceByBasisYear = CellValReinsuraneceByBasisYear + cell.address + ',';
            }

            if (cell.address.startsWith('F')) {
              CellValReinsuraneceByBasisYear_F = CellValReinsuraneceByBasisYear_F + cell.address + ',';
            }
          }

        })
        this.setCellFormatOfReinsuranceByBasisYearsProPlus(rowYear, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuraneceByBasisYearsdata.length - 1 || d[0] != reinsuraneceByBasisYearsdata[i + 1][0]) {
          worksheet.mergeCells('A' + (rowYear.number - yearIndex) + ':A' + rowYear.number);
          yearIndex = -1;
        }
        yearIndex++;
      });
      //let footerYears = (response.isMesoClaim) ? ['Grand Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata }), _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRataPlus })]
      //  : ['Grand Total', '', '', '', '', ''];

      let CellDisplayReinsuraneceByBasisYear = CellValReinsuraneceByBasisYear.replace(/,*$/, '');
      const firstCellReinsuraneceByBasisYear = CellDisplayReinsuraneceByBasisYear.split(',').shift();
      const lastCellReinsuraneceByBasisYear = CellDisplayReinsuraneceByBasisYear.split(',').pop();

      let CellDisplayReinsuraneceByBasisYear_F = CellValReinsuraneceByBasisYear_F.replace(/,*$/, '');
      const firstCellReinsuraneceByBasisYear_F = CellDisplayReinsuraneceByBasisYear_F.split(',').shift();
      const lastCellReinsuraneceByBasisYear_F = CellDisplayReinsuraneceByBasisYear_F.split(',').pop();


      let footerYears = (response.isMesoClaim) ? ['Grand Total', '', '', '', { formula: '=SUM(' + firstCellReinsuraneceByBasisYear + ':' + lastCellReinsuraneceByBasisYear + ')', date1904: false }, { formula: '=SUM(' + firstCellReinsuraneceByBasisYear_F + ':' + lastCellReinsuraneceByBasisYear_F + ')', date1904: false }]
        : ['Grand Total', '', '', '', '', ''];

      //Add Footer Row
      let footerRowYears = worksheet.addRow(footerYears);
      footerRowYears.font = { bold: true };
      this.setCellFormatOfReinsuranceByBasisYearsProPlus(footerRowYears, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      footerRowYears.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //***************End*********************Add Reinsurance By Basis Years*********************************************

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //***************Start*********************Add Reinsurance By Basis Total*********************************************
      let reinsuranceByBasisTotalTitleRow = worksheet.addRow(["Reinsurance By Basis"]);
      reinsuranceByBasisTotalTitleRow.font = { bold: true };
      reinsuranceByBasisTotalTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      console.log(reinsuranceByBasisTotalTitleRow);

      worksheet.mergeCells('A' + reinsuranceByBasisTotalTitleRow.number + ':C' + reinsuranceByBasisTotalTitleRow.number);
      //worksheet.mergeCells('H27:L27');
      let reinsuranceByBasisTotalHeaderRow = worksheet.addRow(reinsuraneceByBasisTotalHeader);
      // Cell Style : Fill and Border
      reinsuranceByBasisTotalHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByBasisTotalHeaderRow.font = { bold: true };
      var yearIndex = 0;

      let CellValReinsuraneceByBasisTotal = "";
      let CellValReinsuraneceByBasisTotal_C = "";

      reinsuraneceByBasisTotaldata.forEach((d, i, array) => {
        //let rowTotal = worksheet.addRow(d);
        //rowTotal.eachCell((cell, number) => {
        //  this.setBorderExcelCell(cell);
        //})
        //this.setCellFormatOfReinsuranceByBasisTotal(rowTotal, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuraneceByBasisTotaldata.length - 1 || d[0] != reinsuraneceByBasisTotaldata[i + 1][0]) {

          let footer = (response.isMesoClaim) ? [d[0] + ' Total', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRata : 0 }), _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRataPlus : 0 })]
            : [d[0] + ' Total', '', ''];


          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: false };
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            //this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

            //Extracting values for cell B & C to show the formula on excel sheet.
            if (footerRow.cellCount > 0) {
              if (cell.address.startsWith('B')) {
                CellValReinsuraneceByBasisTotal = CellValReinsuraneceByBasisTotal + cell.address + ',';
              }

              if (cell.address.startsWith('C')) {
                CellValReinsuraneceByBasisTotal_C = CellValReinsuraneceByBasisTotal_C + cell.address + ',';
              }
            }

          });
          this.setCellFormatOfReinsuranceByBasisTotalProPlus(footerRow, response.isMesoClaim, response.lineOfBusiness);
          //worksheet.mergeCells('S' + (rowTotal.number - yearIndex) + ':S' + rowTotal.number);
          yearIndex = -1;
        }
        yearIndex++;
      });
      //let footerTotal = (response.isMesoClaim) ? ['Grand Total', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata }), _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRataPlus })]
      //  : ['Grand Total', '', '', '', ''];

      let CellDisplayReinsuraneceByBasisTotal = CellValReinsuraneceByBasisTotal.replace(/,*$/, '');
      const firstCellReinsuraneceByBasisTotal = CellDisplayReinsuraneceByBasisTotal.split(',').shift();
      const lastCellReinsuraneceByBasisTotal = CellDisplayReinsuraneceByBasisTotal.split(',').pop();

      let CellDisplayReinsuraneceByBasisTotal_C = CellValReinsuraneceByBasisTotal_C.replace(/,*$/, '');
      const firstCellReinsuraneceByBasisTotal_C = CellDisplayReinsuraneceByBasisTotal_C.split(',').shift();
      const lastCellReinsuraneceByBasisTotal_C = CellDisplayReinsuraneceByBasisTotal_C.split(',').pop();

      let footerTotal = (response.isMesoClaim) ? ['Grand Total', { formula: '=SUM(' + firstCellReinsuraneceByBasisTotal + ':' + lastCellReinsuraneceByBasisTotal + ')', date1904: false }, { formula: '=SUM(' + firstCellReinsuraneceByBasisTotal_C + ':' + lastCellReinsuraneceByBasisTotal_C + ')', date1904: false }]
        : ['Grand Total', '', ''];

      //Add Footer Row
      let footerRowTotal = worksheet.addRow(footerTotal);
      footerRowTotal.font = { bold: true };
      this.setCellFormatOfReinsuranceByBasisTotalProPlus(footerRowTotal, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      footerRowTotal.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //***************End*********************Add Reinsurance By Basis Total*********************************************

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Reinsurance By Reinsurer
      let reinsuranceByReinsurerTitleRow = worksheet.addRow(["Reinsurance By Reinsurer"]);
      reinsuranceByReinsurerTitleRow.font = { bold: true };
      reinsuranceByReinsurerTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      // console.log(reinsuranceByBasisTitleRow);

      worksheet.mergeCells('A' + reinsuranceByReinsurerTitleRow.number + ((response.isMesoClaim) ? ':J' : ':J') + reinsuranceByReinsurerTitleRow.number);
      let reinsuranceByReinsurerHeaderRow = worksheet.addRow(reinsuranceByReinsurerHeader);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByReinsurerHeaderRow.font = { bold: true };
      var layerIndex = 0;
      var yearIndex = 0;

      let CellValReinsuraneceByReinsurerTotal = "";
      let CellValReinsuraneceByReinsurerSubTotal = "";
      let CellValReinsuraneceByReinsurerGT = "";

      let CellValReinsuraneceByReinsurerTotal_J = "";
      let CellValReinsuraneceByReinsurerSubTotal_J = "";
      let CellValReinsuraneceByReinsurerGT_J = "";

      reinsuranceByReinsurerDataProRataPlus.forEach((d, i, array) => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell I & J to show the formula on excel sheet.
          if (row.cellCount > 0) {
            if (cell.address.startsWith('I')) {
              CellValReinsuraneceByReinsurerTotal = CellValReinsuraneceByReinsurerTotal + cell.address + ',';
            }

            if (cell.address.startsWith('J')) {
              CellValReinsuraneceByReinsurerTotal_J = CellValReinsuraneceByReinsurerTotal_J + cell.address + ',';
            }
          }

        })
        this.setCellFormatOfReinsuranceByReinsurerProPlus(row, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuranceByReinsurerDataProRataPlus.length - 1 || d[1] != reinsuranceByReinsurerDataProRataPlus[i + 1][1] || d[0] != reinsuranceByReinsurerDataProRataPlus[i + 1][0]) {
          //let footer = (response.isMesoClaim) ? ['', 'Total', '', '', '', '', '', '', _.sumBy(FilteredReinsuranceByReinsurerProRataPlus, (o: any) => { return (o.year == d[0] && o.layer == d[1]) ? o.proRata : 0 }), _.sumBy(FilteredReinsuranceByReinsurerProRataPlus, (o: any) => { return (o.year == d[0] && o.layer == d[1] && proRataReinsurer.some(r => r == o.riId)) ? o.proRataPlus : 0 })]
          //  : ['', 'Total', '', '', '', '', '', '', '', ''];


          let CellDisplayReinsuraneceByReinsurerTotal = CellValReinsuraneceByReinsurerTotal.replace(/,*$/, '');
          const firstCellReinsuraneceByReinsurerTotal = CellDisplayReinsuraneceByReinsurerTotal.split(',').shift();
          const lastCellReinsuraneceByReinsurerTotal = CellDisplayReinsuraneceByReinsurerTotal.split(',').pop();

          let CellDisplayReinsuraneceByReinsurerTotal_J = CellValReinsuraneceByReinsurerTotal_J.replace(/,*$/, '');
          const firstCellReinsuraneceByReinsurerTotal_J = CellDisplayReinsuraneceByReinsurerTotal_J.split(',').shift();
          const lastCellReinsuraneceByReinsurerTotal_J = CellDisplayReinsuraneceByReinsurerTotal_J.split(',').pop();

          let footer = (response.isMesoClaim) ? ['', 'Total', '', '', '', '', '', '', { formula: '=SUM(' + firstCellReinsuraneceByReinsurerTotal + ":" + lastCellReinsuraneceByReinsurerTotal + ')', date1904: false }, { formula: '=SUM(' + firstCellReinsuraneceByReinsurerTotal_J + ":" + lastCellReinsuraneceByReinsurerTotal_J + ')', date1904: false }]
            : ['', 'Total', '', '', '', '', '', '', '', ''];

          CellValReinsuraneceByReinsurerTotal = "";
          CellValReinsuraneceByReinsurerTotal_J = "";

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          this.setCellFormatOfReinsuranceByReinsurerProPlus(footerRow, response.isMesoClaim, response.lineOfBusiness);
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            if (number != 1) {
              this.fillBackGroundInExcellCell(cell);

              //Extracting values for cell I & J to show the formula on excel sheet.
              if (row.cellCount > 0) {
                if (cell.address.startsWith('I')) {
                  CellValReinsuraneceByReinsurerSubTotal = CellValReinsuraneceByReinsurerSubTotal + cell.address + ',';
                }

                if (cell.address.startsWith('J')) {
                  CellValReinsuraneceByReinsurerSubTotal_J = CellValReinsuraneceByReinsurerSubTotal_J + cell.address + ',';
                }
              }

            }
            this.setBorderExcelCell(cell);

          });
          worksheet.mergeCells('B' + (row.number - layerIndex) + ':B' + row.number);
          worksheet.mergeCells('C' + (row.number - layerIndex) + ':C' + row.number);
          worksheet.mergeCells('D' + (row.number - layerIndex) + ':D' + row.number);
          //worksheet.mergeCells('E' + (row.number - layerIndex) + ':E' + row.number);
          //worksheet.mergeCells('F' + (row.number - layerIndex) + ':F' + row.number);

          layerIndex = -1;
          yearIndex++;
        }
        if (i == reinsuranceByReinsurerDataProRataPlus.length - 1 || d[0] != reinsuranceByReinsurerDataProRataPlus[i + 1][0]) {
          //let footer = (response.isMesoClaim) ? [d[0] + ' Total', '', '', '', '', '', '', '', _.sumBy(FilteredReinsuranceByReinsurerProRataPlus, (o: any) => { return o.year == d[0] ? o.proRata : 0 }), _.sumBy(FilteredReinsuranceByReinsurerProRataPlus, (o: any) => { return (o.year == d[0] && proRataReinsurer.some(r => r == o.riId)) ? o.proRataPlus : 0 })]
          //  : [d[0] + ' Total', '', '', '', '', '', '', '', ''];

          let CellDisplayReinsuraneceByReinsurerSubTotal = CellValReinsuraneceByReinsurerSubTotal.replace(/,*$/, '');
          let CellDisplayReinsuraneceByReinsurerSubTotal_J = CellValReinsuraneceByReinsurerSubTotal_J.replace(/,*$/, '');

          let footer = (response.isMesoClaim) ? [d[0] + ' Total', '', '', '', '', '', '', '', { formula: '=SUM(' + CellDisplayReinsuraneceByReinsurerSubTotal + ')', date1904: false }, { formula: '=SUM(' + CellDisplayReinsuraneceByReinsurerSubTotal_J + ')', date1904: false }]
            : [d[0] + ' Total', '', '', '', '', '', '', '', ''];

          CellValReinsuraneceByReinsurerSubTotal = "";
          CellValReinsuraneceByReinsurerSubTotal_J = "";

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          this.setCellFormatOfReinsuranceByReinsurerProPlus(footerRow, response.isMesoClaim, response.lineOfBusiness);
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

            //Extracting values for cell I & J to show the formula on excel sheet.
            if (row.cellCount > 0) {
              if (cell.address.startsWith('I')) {
                CellValReinsuraneceByReinsurerGT = CellValReinsuraneceByReinsurerGT + cell.address + ',';
              }

              if (cell.address.startsWith('J')) {
                CellValReinsuraneceByReinsurerGT_J = CellValReinsuraneceByReinsurerGT_J + cell.address + ',';
              }
            }


          });
          worksheet.mergeCells('A' + (row.number - yearIndex + 1) + ':A' + (row.number + 1));
          yearIndex = -1;
        }
        layerIndex++;
        yearIndex++;
      });

      //let reinsuranceByReinsurerFooter = (response.isMesoClaim) ? ['Grand Total', '', '', '', '', '', '', '', _.sumBy(FilteredReinsuranceByReinsurerProRataPlus, (o: any) => { return o.proRata }), _.sumBy(FilteredReinsuranceByReinsurerProRataPlus, (o: any) => { return proRataReinsurer.some(r => r == o.riId) ? o.proRataPlus : 0 })]
      //  : ['Grand Total', '', '', '', '', '', '', '', '', ''];

      let CellDisplayReinsuraneceByReinsurerGT = CellValReinsuraneceByReinsurerGT.replace(/,*$/, '');
      let CellDisplayReinsuraneceByReinsurerGT_J = CellValReinsuraneceByReinsurerGT_J.replace(/,*$/, '');

      let reinsuranceByReinsurerFooter = (response.isMesoClaim) ? ['Grand Total', '', '', '', '', '', '', '', { formula: '=SUM(' + CellDisplayReinsuraneceByReinsurerGT + ')', date1904: false }, { formula: '=SUM(' + CellDisplayReinsuraneceByReinsurerGT_J + ')', date1904: false }]
        : ['Grand Total', '', '', '', '', '', '', '', '', ''];

      //Add Footer Row
      let reinsuranceByReinsurerFooterRow = worksheet.addRow(reinsuranceByReinsurerFooter);
      reinsuranceByReinsurerFooterRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByReinsurerProPlus(reinsuranceByReinsurerFooterRow, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });


      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Reinsurance By Reinsurer Summary
      let reinsuranceByReinsurerSummaryTitleRow = worksheet.addRow(["Reinsurance By Reinsurer: Summary"]);
      reinsuranceByReinsurerSummaryTitleRow.font = { bold: true };
      reinsuranceByReinsurerSummaryTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      // console.log(reinsuranceByBasisTitleRow);
      //worksheet.mergeCells('A' + reinsuranceByReinsurerSummaryTitleRow.number + ((response.isMesoClaim && response.lineOfBusiness == 146) ? ':K' : ':J') + reinsuranceByReinsurerSummaryTitleRow.number);
      worksheet.mergeCells('A' + reinsuranceByReinsurerSummaryTitleRow.number + ((response.isMesoClaim && response.lineOfBusiness == 146) ? ':L' : ':L') + reinsuranceByReinsurerSummaryTitleRow.number);
      let reinsuranceByReinsurerSummaryHeaderRow = worksheet.addRow(reinsuranceByReinsurerSummaryHeader);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerSummaryHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })

      let CellValReinsuraneceByReinsurerSummarTotal_E = "";
      let CellValReinsuraneceByReinsurerSummarTotal_F = "";
      let CellValReinsuraneceByReinsurerSummarTotal_G = "";
      let CellValReinsuraneceByReinsurerSummarTotal_H = "";
      let CellValReinsuraneceByReinsurerSummarTotal_I = "";
      let CellValReinsuraneceByReinsurerSummarTotal_J = "";

      reinsuranceByReinsurerSummaryHeaderRow.font = { bold: true };
      reinsuranceByReinsurerSummaryData.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          if (footerRow.cellCount > 0) {
            //Extracting values for cell E to show the formula on excel sheet.
            if (cell.address.startsWith('E')) {
              CellValReinsuraneceByReinsurerSummarTotal_E = CellValReinsuraneceByReinsurerSummarTotal_E + cell.address + ',';
            }

            //Extracting values for cell F to show the formula on excel sheet.
            if (cell.address.startsWith('F')) {
              CellValReinsuraneceByReinsurerSummarTotal_F = CellValReinsuraneceByReinsurerSummarTotal_F + cell.address + ',';
            }

            //Extracting values for cell G to show the formula on excel sheet.
            if (cell.address.startsWith('G')) {
              CellValReinsuraneceByReinsurerSummarTotal_G = CellValReinsuraneceByReinsurerSummarTotal_G + cell.address + ',';
            }

            //Extracting values for cell H to show the formula on excel sheet.
            if (cell.address.startsWith('H')) {
              CellValReinsuraneceByReinsurerSummarTotal_H = CellValReinsuraneceByReinsurerSummarTotal_H + cell.address + ',';
            }

            //Extracting values for cell I to show the formula on excel sheet.
            if (cell.address.startsWith('I')) {
              CellValReinsuraneceByReinsurerSummarTotal_I = CellValReinsuraneceByReinsurerSummarTotal_I + cell.address + ',';
            }

            //Extracting values for cell J to show the formula on excel sheet.
            if (cell.address.startsWith('J')) {
              CellValReinsuraneceByReinsurerSummarTotal_J = CellValReinsuraneceByReinsurerSummarTotal_J + cell.address + ',';
            }
          }

        })
        this.setCellFormatOfReinsuranceByReinsurerSummarProPlus(row, response.isMesoClaim, response.lineOfBusiness);
      });

      //let reinsuranceByReinsurerSummaryFooter = (response.isMesoClaim && response.lineOfBusiness == 146) ? ['Grand Total', '', '', '',
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.proRataCalc }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return proRataReinsurer.some(r => r == o.riId) ? o.proRataPlus : 0 }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.amountSubmitted }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.totalAmountAgreed }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.totalAmountReceived }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.variation }),
      //  '', '']

      //  : ['Grand Total', '', '', '', '', '', '', '', '', '',

      //    '', ''];

      let CellDisplayReinsuraneceByReinsurerSummarTotal_E = CellValReinsuraneceByReinsurerSummarTotal_E.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_E = CellDisplayReinsuraneceByReinsurerSummarTotal_E.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_E = CellDisplayReinsuraneceByReinsurerSummarTotal_E.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_F = CellValReinsuraneceByReinsurerSummarTotal_F.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_F = CellDisplayReinsuraneceByReinsurerSummarTotal_F.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_F = CellDisplayReinsuraneceByReinsurerSummarTotal_F.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_G = CellValReinsuraneceByReinsurerSummarTotal_G.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_G = CellDisplayReinsuraneceByReinsurerSummarTotal_G.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_G = CellDisplayReinsuraneceByReinsurerSummarTotal_G.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_H = CellValReinsuraneceByReinsurerSummarTotal_H.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_H = CellDisplayReinsuraneceByReinsurerSummarTotal_H.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_H = CellDisplayReinsuraneceByReinsurerSummarTotal_H.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_I = CellValReinsuraneceByReinsurerSummarTotal_I.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_I = CellDisplayReinsuraneceByReinsurerSummarTotal_I.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_I = CellDisplayReinsuraneceByReinsurerSummarTotal_I.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_J = CellValReinsuraneceByReinsurerSummarTotal_J.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_J = CellDisplayReinsuraneceByReinsurerSummarTotal_J.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_J = CellDisplayReinsuraneceByReinsurerSummarTotal_J.split(',').pop();

      let reinsuranceByReinsurerSummaryFooter = (response.isMesoClaim && response.lineOfBusiness == 146) ? ['Grand Total', '', '', '',
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_E + ':' + lastCellReinsuraneceBySummarTotal_E + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_F + ':' + lastCellReinsuraneceBySummarTotal_F + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_G + ':' + lastCellReinsuraneceBySummarTotal_G + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_H + ':' + lastCellReinsuraneceBySummarTotal_H + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_I + ':' + lastCellReinsuraneceBySummarTotal_I + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_J + ':' + lastCellReinsuraneceBySummarTotal_J + ')', date1904: false },
        '', '']

        : ['Grand Total', '', '', '', '', '', '', '', '', '',

          '', ''];


      //Add Footer Row
      let reinsuranceByReinsurerSummaryFooterRow = worksheet.addRow(reinsuranceByReinsurerSummaryFooter);
      reinsuranceByReinsurerSummaryFooterRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByReinsurerSummarProPlus(reinsuranceByReinsurerSummaryFooterRow, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerSummaryFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      for (var i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 25;
      }

      //Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //fs.saveAs(blob, 'Reinsurance-Detail-Claim-' + claimId + '_' + Math.random() + '.xlsx');
        fs.saveAs(blob, 'Pro-Rata-Plus-Claim-' + claimId + '_' + Math.random() + '.xlsx');

      })
    });
  }

  downloadRIExportProRata(claimId) {
    this.reinsuranceService.getClaimReinsuranceDetailForDownload(claimId).subscribe((response: any) => {
      //debugger;
      var proRataReinsurer = [4, 19, 35, 40, 50, 74];
      const title = 'Reinsurance Details of Claim ' + response.claimReference;

      const allocationOfIncurredCostHeader = ["Year", "Exposure Days", "Share Exp", "Share Incurred (£)"];
      const reinsuraneceByBasisHeader = !(response.isMesoClaim) ? ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)"] : ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)"];

      const reinsuranceByReinsurerHeader = !(response.isMesoClaim) ? ["Year", "Layer", "Excess (£)", "Limit (£)", "RI ID", "Reinsurer Name", "Status", "RI %", "PRO RATA (£)"]
        : ["Year", "Layer", "Excess (£)", "Limit (£)", "RI ID", "Reinsurer Name", "Status", "RI %", "PRO RATA (£)"];

      const reinsuranceByReinsurerSummaryHeader = !(response.isMesoClaim) ? ["RI ID", "Reinsurer Name", "Parent Name", "RI Status", "PRO RATA (£)", "Amount Submitted (£)", "Amount Agreed (£)", "Amount Received (£)", "Variation (£)", "Invoice Number", "Notification Status"]
        : ["RI ID", "Reinsurer Name", "Parent Name", "RI Status", "PRO RATA (£)", "Amount Submitted (£)", "Amount Agreed (£)", "Amount Received (£)", "Variation (£)", "Invoice Number", "Notification Status"];

      const reinsuraneceByBasisYearsHeader = ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)"];

      //const reinsuraneceByBasisTotalHeader = ["Year", "Excess (£)", "Limit (£)", "PRO RATA (£)"];
      const reinsuraneceByBasisTotalHeader = ["Year", "PRO RATA (£)"];

      //const equitableHeader = ["Policy ID", "Policy Number", "Exposure From", "Exposure To", "Excess From", "	Excess To", "Excess Amount (Ã‚£)", "LTA", "LTA From", "LTA To", "Policy Days", "Exposure Days", "Share Percentage", "Share of Incurred (Ã‚£)", "Available EQUITABLE EXCESS (Ã‚£)", "Utilised EQUITABLE EXCESS (Ã‚£)"];
      //var header = proRataHeader;
      //const exposureHeader = ["Period", "MMI Exposure From", "MMI Exposure To", "Policy Number"];
      let allocationOfIncurredCostData: any[] = [];
      let reinsuraneceByBasisdata: any[] = [];
      let reinsuranceByReinsurerData: any[] = [];
      let reinsuranceByReinsurerSummaryData: any[] = [];
      let reinsuraneceByBasisYearsdata: any[] = [];
      let reinsuraneceByBasisTotaldata: any[] = [];
      response.allocationOfIncurredCosts.forEach(item => {
        if (!response.isMesoClaim) {
          allocationOfIncurredCostData.push([item["exposureYear"]
            , item["exposureDays"]
            , item["shareExposurePct"]
            , item["shareIncurred"]]);
        }
      });

      //reinsuraneceByBasisdata
      response.reinsuranceByBasis.forEach(item => {
        if (!response.isMesoClaim) {
          reinsuraneceByBasisdata.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]
            // , item["proRataPlus"]
          ]);
        }
      });

      //reinsuraneceByBasisYearsdata
      response.reinsuranceByBasis.forEach(item => {
        if (!response.isMesoClaim) {
          reinsuraneceByBasisYearsdata.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]
          ]);
        }
      });

      //reinsuraneceByBasisTotaldata
      response.reinsuranceByBasis.forEach(item => {
        if (!response.isMesoClaim) {
          reinsuraneceByBasisTotaldata.push([item["year"]
            // , item["layer"]
            //, item["excess"]
            //, item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]
            //, item["proRataPlus"]
          ]);
        }

      });


      var FilteredReinsuranceByReinsurer = response.reinsuranceByReinsurer.filter(
        r => r.status == "A (Active)");

      FilteredReinsuranceByReinsurer.forEach(item => {
        if ((!response.isMesoClaim)) {
          reinsuranceByReinsurerData.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["riId"]
            , item["reinsurerName"]
            , item["status"]
            , item["riPercent"]
            , item["proRata"]
            //, proRataReinsurer.some(r => r == item["riId"]) ? item["proRataPlus"] : ''

          ]);
        }
      });

      var FilteredReinsuranceByReinsurerSummary = response.reinsuranceByReinsurerSummary.filter(
        r => r.riStatus == "A (Active)");

      FilteredReinsuranceByReinsurerSummary.forEach(item => {
        if ((!response.isMesoClaim)) {
          //debugger;
          reinsuranceByReinsurerSummaryData.push([item["riId"]
            , item["reinsurerName"]
            , item["parentReinsurerName"]
            , item["riStatus"]
            , item["proRataCalc"]
            // , proRataReinsurer.some(r => r == item["riId"]) ? item["proRataPlus"] : ''
            , item["amountSubmitted"]
            , item["totalAmountAgreed"]
            //  , item["dateAgreed"] == null ? "" : moment(item["dateAgreed"]).format('DD/MM/YYYY')
            , item["totalAmountReceived"]
            // , item["dateReceived"] == null ? "" : moment(item["dateReceived"]).format('DD/MM/YYYY')
            , item["variation"]
            , item["invoiceNo"] == null ? "" : item["invoiceNo"]
            , item["notificationStatus"] == null ? "" : item["notificationStatus"]]);
        }
      });

      //response.policyExposures.forEach((item, index) => {
      //  exposureData.push([index + 1
      //    , moment(item["exposureFrom"]).format('DD/MM/YYYY')
      //    , moment(item["exposureTo"]).format('DD/MM/YYYY')
      //    , item["policyNumber"]]);
      //});

      //Create workbook and worksheet
      let workbook: ExcelProper.Workbook = new Excel.Workbook();
      let worksheet = workbook.addWorksheet('Pro Rata');

      //Add Row and formatting
      let titleRow = worksheet.addRow([title]);
      titleRow.font = { bold: true, size: 16 };
      titleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });
      worksheet.mergeCells('A1:L2');
      //Blank Row 
      worksheet.addRow([]);

      let summaryRow1 = worksheet.addRow(["Claim Id", response.claimId]);
      this.fillBackGroundInExcellCell(summaryRow1.findCell(1));
      summaryRow1.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow1.findCell(1));
      this.setBorderExcelCell(summaryRow1.findCell(2));
      let summaryRow2 = worksheet.addRow(["Claim Reference", response.claimReference]);
      this.fillBackGroundInExcellCell(summaryRow2.findCell(1));
      summaryRow2.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow2.findCell(1));
      this.setBorderExcelCell(summaryRow2.findCell(2));
      let summaryRow3 = worksheet.addRow(["Insured Title", response.insuredTitle]);
      this.fillBackGroundInExcellCell(summaryRow3.findCell(1));
      summaryRow3.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow3.findCell(1));
      this.setBorderExcelCell(summaryRow3.findCell(2));
      let summaryRowX = worksheet.addRow(["Claimant Name", response.claimantName]);
      this.fillBackGroundInExcellCell(summaryRowX.findCell(1));
      summaryRowX.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRowX.findCell(1));
      this.setBorderExcelCell(summaryRowX.findCell(2));

      worksheet.addRow([]);

      let summaryRow4 = worksheet.addRow(["MMI Valuation", response.mmiValuation]);
      this.fillBackGroundInExcellCell(summaryRow4.findCell(1));
      summaryRow4.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow4.findCell(1));
      this.setBorderExcelCell(summaryRow4.findCell(2));
      summaryRow4.findCell(2).numFmt = '£###,###,##0.00;'
      let summaryRow5 = worksheet.addRow(["Excess Paid", response.excessPaid]);
      this.fillBackGroundInExcellCell(summaryRow5.findCell(1));
      summaryRow5.findCell(1).font = { bold: true, italic: true };
      summaryRow5.findCell(2).numFmt = '£###,###,##0.00;'
      let summaryRow6 = worksheet.addRow(["MMI Paid", response.netPaid]);
      this.fillBackGroundInExcellCell(summaryRow6.findCell(1));
      summaryRow6.findCell(1).font = { bold: true, italic: true };
      summaryRow6.findCell(2).numFmt = '£###,###,##0.00;'
      this.setBorderExcelCell(summaryRow6.findCell(1));
      this.setBorderExcelCell(summaryRow6.findCell(2));
      let summaryRow7 = worksheet.addRow(["MMI Reserve", response.outstandingAmount]);
      this.fillBackGroundInExcellCell(summaryRow7.findCell(1));
      summaryRow7.findCell(1).font = { bold: true, italic: true };
      summaryRow7.findCell(2).numFmt = '£###,###,##0.00;'
      this.setBorderExcelCell(summaryRow7.findCell(1));
      this.setBorderExcelCell(summaryRow7.findCell(2));
      let summaryRow8 = worksheet.addRow(["MMI Incurred", response.totalIncurred]);
      this.fillBackGroundInExcellCell(summaryRow8.findCell(1));
      summaryRow8.findCell(2).numFmt = '£###,###,##0.00;'
      summaryRow8.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow8.findCell(1));
      this.setBorderExcelCell(summaryRow8.findCell(2));
      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      let exposureTitleRow = worksheet.addRow(["Allocation Of Incurred Costs"]);
      exposureTitleRow.font = { bold: true };
      exposureTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      worksheet.mergeCells('A16:D16');

      let allocationOfIncurredHeaderRow = worksheet.addRow(allocationOfIncurredCostHeader);
      // Cell Style : Fill and Border
      allocationOfIncurredHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })

      let CellValallocationOfIncurredTotal = "";

      allocationOfIncurredHeaderRow.font = { bold: true };
      allocationOfIncurredCostData.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell D to show the formula on excel sheet.
          if (row.cellCount > 0) {
            if (cell.address.startsWith('D')) {
              CellValallocationOfIncurredTotal = CellValallocationOfIncurredTotal + cell.address + ',';
            }
          }

        })
        this.setCellFormatOfAllocationOfIncurred(row);
      });

      //let allocationOfIncurredFooter = (!response.isMesoClaim) ? ['Grand Total',
      //  _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.exposureDays }),
      //  100.00,
      //  _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.shareIncurred })
      //] : ['Grand Total',
      //  '',
      //  '',
      //  ''
      //];

      let CellDisplayallocationOfIncurredTotal = CellValallocationOfIncurredTotal.replace(/,*$/, '');
      const firstCellallocationOfIncurredTotal = CellDisplayallocationOfIncurredTotal.split(',').shift();
      const lastCellallocationOfIncurredTotal = CellDisplayallocationOfIncurredTotal.split(',').pop();

      let allocationOfIncurredFooter = (!response.isMesoClaim) ? ['Grand Total',
        _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.exposureDays }),
        100.00,
        { formula: '=SUM(' + firstCellallocationOfIncurredTotal + ':' + lastCellallocationOfIncurredTotal + ')', date1904: false }
      ] : ['Grand Total',
        '',
        '',
        ''
      ];


      //Add Footer Row
      let allocationOfIncurredFooterRow = worksheet.addRow(allocationOfIncurredFooter);
      allocationOfIncurredFooterRow.font = { bold: true };
      this.setCellFormatOfAllocationOfIncurred(allocationOfIncurredFooterRow);
      // Cell Style : Fill and Border
      allocationOfIncurredFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });



      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);


      //Add Reinsurance By Basis
      let reinsuranceByBasisTitleRow = worksheet.addRow(["Reinsurance By Basis"]);
      reinsuranceByBasisTitleRow.font = { bold: true };
      reinsuranceByBasisTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      console.log(reinsuranceByBasisTitleRow);

      worksheet.mergeCells('A' + reinsuranceByBasisTitleRow.number + ':E' + reinsuranceByBasisTitleRow.number);
      let reinsuranceByBasisHeaderRow = worksheet.addRow(reinsuraneceByBasisHeader);
      // Cell Style : Fill and Border
      reinsuranceByBasisHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByBasisHeaderRow.font = { bold: true };
      var yearIndex = 0;
      let CellValReinsuraneceByBasis = "";
      let CellValReinsuraneceByBasisGT = "";
      reinsuraneceByBasisdata.forEach((d, i, array) => {
        let row = worksheet.addRow(d);

        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell E to show the formula on excel sheet.
          if (row.cellCount > 0) {
            if (cell.address.startsWith('E')) {
              CellValReinsuraneceByBasis = CellValReinsuraneceByBasis + cell.address + ',';
            }
          }

        })


        this.setCellFormatOfReinsuranceByBasis(row, !response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuraneceByBasisdata.length - 1 || d[0] != reinsuraneceByBasisdata[i + 1][0]) {

          //let footer = (!response.isMesoClaim) ? [d[0] + ' Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRata : 0 })]
          //  : [d[0] + ' Total', '', '', '', ''];

          let CellDisplayReinsuraneceByBasis = CellValReinsuraneceByBasis.replace(/,*$/, '');
          const firstCellReinsuraneceByBasis = CellDisplayReinsuraneceByBasis.split(',').shift();
          const lastCellReinsuraneceByBasis = CellDisplayReinsuraneceByBasis.split(',').pop();

          let footer = (!response.isMesoClaim) ? [d[0] + ' Total', '', '', '', { formula: '=SUM(' + firstCellReinsuraneceByBasis + ':' + lastCellReinsuraneceByBasis + ')', date1904: false }]
            : [d[0] + ' Total', '', '', '', ''];

          CellValReinsuraneceByBasis = "";

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

            //Extracting values for cell E to show the formula on excel sheet.
            if (footerRow.cellCount > 0) {
              if (cell.address.startsWith('E')) {
                CellValReinsuraneceByBasisGT = CellValReinsuraneceByBasisGT + cell.address + ',';
              }
            }

          });
          this.setCellFormatOfReinsuranceByBasis(footerRow, !response.isMesoClaim, response.lineOfBusiness);
          worksheet.mergeCells('A' + (row.number - yearIndex) + ':A' + row.number);
          yearIndex = -1;
        }
        yearIndex++;
      });
      //let footer = (!response.isMesoClaim) ? ['Grand Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata })]
      //  : ['Grand Total', '', '', '', ''];

      let CellDisplayReinsuraneceByBasisGT = CellValReinsuraneceByBasisGT.replace(/,*$/, '');

      let footer = (!response.isMesoClaim) ? ['Grand Total', '', '', '', { formula: '=SUM(' + CellDisplayReinsuraneceByBasisGT + ')', date1904: false }]
        : ['Grand Total', '', '', '', ''];

      //Add Footer Row
      let footerRow = worksheet.addRow(footer);
      footerRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByBasis(footerRow, !response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      footerRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //***************Start*********************Add Reinsurance By Basis Years*********************************************
      let reinsuranceByBasisYearsTitleRow = worksheet.addRow(["Reinsurance By Basis"]);
      reinsuranceByBasisYearsTitleRow.font = { bold: true };
      reinsuranceByBasisYearsTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      console.log(reinsuranceByBasisYearsTitleRow);

      worksheet.mergeCells('A' + reinsuranceByBasisYearsTitleRow.number + ':E' + reinsuranceByBasisYearsTitleRow.number);
      //worksheet.mergeCells('H27:L27');
      let reinsuranceByBasisYearsHeaderRow = worksheet.addRow(reinsuraneceByBasisYearsHeader);
      // Cell Style : Fill and Border
      reinsuranceByBasisYearsHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByBasisYearsHeaderRow.font = { bold: true };
      var yearIndex = 0;
      let CellValReinsuraneceByBasisYear = "";

      reinsuraneceByBasisYearsdata.forEach((d, i, array) => {
        let rowYear = worksheet.addRow(d);
        rowYear.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell D to show the formula on excel sheet.
          if (rowYear.cellCount > 0) {
            if (cell.address.startsWith('E')) {
              CellValReinsuraneceByBasisYear = CellValReinsuraneceByBasisYear + cell.address + ',';
            }
          }

        })
        this.setCellFormatOfReinsuranceByBasisYears(rowYear, !response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuraneceByBasisYearsdata.length - 1 || d[0] != reinsuraneceByBasisYearsdata[i + 1][0]) {

          worksheet.mergeCells('A' + (rowYear.number - yearIndex) + ':A' + rowYear.number);
          yearIndex = -1;
        }
        yearIndex++;
      });
      //let footerYears = (!response.isMesoClaim) ? ['Grand Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata })]
      //  : ['Grand Total', '', '', '', ''];

      let CellDisplayReinsuraneceByBasisYear = CellValReinsuraneceByBasisYear.replace(/,*$/, '');
      const firstCellReinsuraneceByBasisYear = CellDisplayReinsuraneceByBasisYear.split(',').shift();
      const lastCellReinsuraneceByBasisYear = CellDisplayReinsuraneceByBasisYear.split(',').pop();

      let footerYears = (!response.isMesoClaim) ? ['Grand Total', '', '', '', { formula: '=SUM(' + firstCellReinsuraneceByBasisYear + ':' + lastCellReinsuraneceByBasisYear + ')', date1904: false }]
        : ['Grand Total', '', '', '', ''];

      //Add Footer Row
      let footerRowYears = worksheet.addRow(footerYears);
      footerRowYears.font = { bold: true };
      this.setCellFormatOfReinsuranceByBasisYears(footerRowYears, !response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      footerRowYears.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //***************End*********************Add Reinsurance By Basis Years*********************************************

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //***************Start*********************Add Reinsurance By Basis Total*********************************************
      let reinsuranceByBasisTotalTitleRow = worksheet.addRow(["Reinsurance By Basis"]);
      reinsuranceByBasisTotalTitleRow.font = { bold: true };
      reinsuranceByBasisTotalTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      console.log(reinsuranceByBasisTotalTitleRow);

      worksheet.mergeCells('A' + reinsuranceByBasisTotalTitleRow.number + ':B' + reinsuranceByBasisTotalTitleRow.number);
      //worksheet.mergeCells('H27:L27');
      let reinsuranceByBasisTotalHeaderRow = worksheet.addRow(reinsuraneceByBasisTotalHeader);
      // Cell Style : Fill and Border
      reinsuranceByBasisTotalHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByBasisTotalHeaderRow.font = { bold: true };
      var yearIndex = 0;
      let CellValReinsuraneceByBasisTotal = "";
      reinsuraneceByBasisTotaldata.forEach((d, i, array) => {
        //let rowTotal = worksheet.addRow(d);
        //rowTotal.eachCell((cell, number) => {
        //  this.setBorderExcelCell(cell);
        //})
        //this.setCellFormatOfReinsuranceByBasisYears(rowTotal, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuraneceByBasisTotaldata.length - 1 || d[0] != reinsuraneceByBasisTotaldata[i + 1][0]) {

          let footer = (!response.isMesoClaim) ? [d[0] + ' Total', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRata : 0 })] : [d[0] + ' Total', ''];

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: false };
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            //this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

            //Extracting values for cell D to show the formula on excel sheet.
            if (footerRow.cellCount > 0) {
              if (cell.address.startsWith('B')) {
                CellValReinsuraneceByBasisTotal = CellValReinsuraneceByBasisTotal + cell.address + ',';
              }
            }

          });
          this.setCellFormatOfReinsuranceByBasisTotal(footerRow, !response.isMesoClaim, response.lineOfBusiness);
          //worksheet.mergeCells('S' + (rowTotal.number - yearIndex) + ':S' + rowTotal.number);

          yearIndex = -1;
        }
        yearIndex++;
      });

      //let footerTotal = (!response.isMesoClaim) ? ['Grand Total', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata })]
      //  : ['Grand Total', '', '', ''];

      let CellDisplayReinsuraneceByBasisTotal = CellValReinsuraneceByBasisTotal.replace(/,*$/, '');
      const firstCellReinsuraneceByBasisTotal = CellDisplayReinsuraneceByBasisTotal.split(',').shift();
      const lastCellReinsuraneceByBasisTotal = CellDisplayReinsuraneceByBasisTotal.split(',').pop();

      let footerTotal = (!response.isMesoClaim) ? ['Grand Total', { formula: '=SUM(' + firstCellReinsuraneceByBasisTotal + ':' + lastCellReinsuraneceByBasisTotal + ')', date1904: false }]
        : ['Grand Total', ''];

      //Add Footer Row
      let footerRowTotal = worksheet.addRow(footerTotal);
      footerRowTotal.font = { bold: true };
      this.setCellFormatOfReinsuranceByBasisTotal(footerRowTotal, !response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      footerRowTotal.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //***************End*********************Add Reinsurance By Basis Total*********************************************

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Reinsurance By Reinsurer
      let reinsuranceByReinsurerTitleRow = worksheet.addRow(["Reinsurance By Reinsurer"]);
      reinsuranceByReinsurerTitleRow.font = { bold: true };
      reinsuranceByReinsurerTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      // console.log(reinsuranceByBasisTitleRow);

      worksheet.mergeCells('A' + reinsuranceByReinsurerTitleRow.number + ':I' + reinsuranceByReinsurerTitleRow.number);
      let reinsuranceByReinsurerHeaderRow = worksheet.addRow(reinsuranceByReinsurerHeader);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByReinsurerHeaderRow.font = { bold: true };
      var layerIndex = 0;
      var yearIndex = 0;

      let CellValReinsuraneceByReinsurerTotal = "";
      let CellValReinsuraneceByReinsurerSubTotal = "";
      let CellValReinsuraneceByReinsurerGT = "";

      reinsuranceByReinsurerData.forEach((d, i, array) => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell I to show the formula on excel sheet.
          if (row.cellCount > 0) {
            if (cell.address.startsWith('I')) {
              CellValReinsuraneceByReinsurerTotal = CellValReinsuraneceByReinsurerTotal + cell.address + ',';
            }
          }

        })
        this.setCellFormatOfReinsuranceByReinsurer(row, !response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuranceByReinsurerData.length - 1 || d[1] != reinsuranceByReinsurerData[i + 1][1] || d[0] != reinsuranceByReinsurerData[i + 1][0]) {

          //let footer = (!response.isMesoClaim) ? ['', 'Total', '', '', '', '', '', '', _.sumBy(FilteredReinsuranceByReinsurer, (o: any) => { return (o.year == d[0] && o.layer == d[1]) ? o.proRata : 0 })]
          //  : ['', 'Total', '', '', '', '', '', '', ''];
          let CellDisplayReinsuraneceByReinsurerTotal = CellValReinsuraneceByReinsurerTotal.replace(/,*$/, '');
          const firstCellReinsuraneceByReinsurerTotal = CellDisplayReinsuraneceByReinsurerTotal.split(',').shift();
          const lastCellReinsuraneceByReinsurerTotal = CellDisplayReinsuraneceByReinsurerTotal.split(',').pop();

          let footer = (!response.isMesoClaim) ? ['', 'Total', '', '', '', '', '', '', { formula: '=SUM(' + firstCellReinsuraneceByReinsurerTotal + ":" + lastCellReinsuraneceByReinsurerTotal + ')', date1904: false }]
            : ['', 'Total', '', '', '', '', '', '', ''];

          CellValReinsuraneceByReinsurerTotal = "";

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          this.setCellFormatOfReinsuranceByReinsurer(footerRow, !response.isMesoClaim, response.lineOfBusiness);
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            if (number != 1) {
              this.fillBackGroundInExcellCell(cell);

              //Extracting values for cell I to show the formula on excel sheet.
              if (row.cellCount > 0) {
                if (cell.address.startsWith('I')) {
                  CellValReinsuraneceByReinsurerSubTotal = CellValReinsuraneceByReinsurerSubTotal + cell.address + ',';
                }
              }
            }
            this.setBorderExcelCell(cell);

          });
          worksheet.mergeCells('B' + (row.number - layerIndex) + ':B' + row.number);
          worksheet.mergeCells('C' + (row.number - layerIndex) + ':C' + row.number);
          worksheet.mergeCells('D' + (row.number - layerIndex) + ':D' + row.number);
          //worksheet.mergeCells('E' + (row.number - layerIndex) + ':E' + row.number);
          //worksheet.mergeCells('F' + (row.number - layerIndex) + ':F' + row.number);
          layerIndex = -1;
          yearIndex++;
        }
        if (i == reinsuranceByReinsurerData.length - 1 || d[0] != reinsuranceByReinsurerData[i + 1][0]) {

          //let footer = (!response.isMesoClaim) ? [d[0] + ' Total', '', '', '', '', '', '', '', _.sumBy(FilteredReinsuranceByReinsurer, (o: any) => { return o.year == d[0] ? o.proRata : 0 })]
          //  : [d[0] + ' Total', '', '', '', '', '', '', '', ''];

          let CellDisplayReinsuraneceByReinsurerSubTotal = CellValReinsuraneceByReinsurerSubTotal.replace(/,*$/, '');
          //const firstCellReinsuraneceByReinsurerSubTotal = CellDisplayReinsuraneceByReinsurerSubTotal.split(',').shift();
          //const lastCellReinsuraneceByReinsurerSubTotal = CellDisplayReinsuraneceByReinsurerSubTotal.split(',').pop();

          let footer = (!response.isMesoClaim) ? [d[0] + ' Total', '', '', '', '', '', '', '', { formula: '=SUM(' + CellDisplayReinsuraneceByReinsurerSubTotal + ')', date1904: false }]
            : [d[0] + ' Total', '', '', '', '', '', '', '', ''];

          CellValReinsuraneceByReinsurerSubTotal = "";

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          this.setCellFormatOfReinsuranceByReinsurer(footerRow, !response.isMesoClaim, response.lineOfBusiness);
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

            //Extracting values for cell I to show the formula on excel sheet.
            if (row.cellCount > 0) {
              if (cell.address.startsWith('I')) {
                CellValReinsuraneceByReinsurerGT = CellValReinsuraneceByReinsurerGT + cell.address + ',';
              }
            }


          });
          worksheet.mergeCells('A' + (row.number - yearIndex + 1) + ':A' + (row.number + 1));
          yearIndex = -1;
        }
        layerIndex++;
        yearIndex++;
      });

      //let reinsuranceByReinsurerFooter = (!response.isMesoClaim) ? ['Grand Total', '', '', '', '', '', '', '', _.sumBy(FilteredReinsuranceByReinsurer, (o: any) => { return o.proRata })]
      //  : ['Grand Total', '', '', '', '', '', '', '', ''];

      let CellDisplayReinsuraneceByReinsurerGT = CellValReinsuraneceByReinsurerGT.replace(/,*$/, '');
      //const firstCellReinsuraneceByReinsurerGT = CellDisplayReinsuraneceByReinsurerGT.split(',').shift();
      //const lastCellReinsuraneceByReinsurerGT = CellDisplayReinsuraneceByReinsurerGT.split(',').pop();

      let reinsuranceByReinsurerFooter = (!response.isMesoClaim) ? ['Grand Total', '', '', '', '', '', '', '', { formula: '=SUM(' + CellDisplayReinsuraneceByReinsurerGT + ')', date1904: false }]
        : ['Grand Total', '', '', '', '', '', '', '', ''];

      //Add Footer Row
      let reinsuranceByReinsurerFooterRow = worksheet.addRow(reinsuranceByReinsurerFooter);
      reinsuranceByReinsurerFooterRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByReinsurer(reinsuranceByReinsurerFooterRow, !response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });


      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Reinsurance By Reinsurer Summary
      let reinsuranceByReinsurerSummaryTitleRow = worksheet.addRow(["Reinsurance By Reinsurer: Summary"]);
      reinsuranceByReinsurerSummaryTitleRow.font = { bold: true };
      reinsuranceByReinsurerSummaryTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      // console.log(reinsuranceByBasisTitleRow);
      //worksheet.mergeCells('A' + reinsuranceByReinsurerSummaryTitleRow.number + ((response.isMesoClaim && response.lineOfBusiness == 146) ? ':K' : ':J') + reinsuranceByReinsurerSummaryTitleRow.number);
      worksheet.mergeCells('A' + reinsuranceByReinsurerSummaryTitleRow.number + ':K' + reinsuranceByReinsurerSummaryTitleRow.number);
      let reinsuranceByReinsurerSummaryHeaderRow = worksheet.addRow(reinsuranceByReinsurerSummaryHeader);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerSummaryHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })

      let CellValReinsuraneceByReinsurerSummarTotal_E = "";
      let CellValReinsuraneceByReinsurerSummarTotal_F = "";
      let CellValReinsuraneceByReinsurerSummarTotal_G = "";
      let CellValReinsuraneceByReinsurerSummarTotal_H = "";
      let CellValReinsuraneceByReinsurerSummarTotal_I = "";

      reinsuranceByReinsurerSummaryHeaderRow.font = { bold: true };
      reinsuranceByReinsurerSummaryData.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);


          if (footerRow.cellCount > 0) {
            //Extracting values for cell E to show the formula on excel sheet.
            if (cell.address.startsWith('E')) {
              CellValReinsuraneceByReinsurerSummarTotal_E = CellValReinsuraneceByReinsurerSummarTotal_E + cell.address + ',';
            }

            //Extracting values for cell F to show the formula on excel sheet.
            if (cell.address.startsWith('F')) {
              CellValReinsuraneceByReinsurerSummarTotal_F = CellValReinsuraneceByReinsurerSummarTotal_F + cell.address + ',';
            }

            //Extracting values for cell G to show the formula on excel sheet.
            if (cell.address.startsWith('G')) {
              CellValReinsuraneceByReinsurerSummarTotal_G = CellValReinsuraneceByReinsurerSummarTotal_G + cell.address + ',';
            }

            //Extracting values for cell H to show the formula on excel sheet.
            if (cell.address.startsWith('H')) {
              CellValReinsuraneceByReinsurerSummarTotal_H = CellValReinsuraneceByReinsurerSummarTotal_H + cell.address + ',';
            }

            //Extracting values for cell I to show the formula on excel sheet.
            if (cell.address.startsWith('I')) {
              CellValReinsuraneceByReinsurerSummarTotal_I = CellValReinsuraneceByReinsurerSummarTotal_I + cell.address + ',';
            }

          }
        })
        this.setCellFormatOfReinsuranceByReinsurerSummar(row, !response.isMesoClaim, response.lineOfBusiness);
      });

      //let reinsuranceByReinsurerSummaryFooter = (!response.isMesoClaim) ? ['Grand Total', '', '', '',
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.proRataCalc }),
      //  // _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return proRataReinsurer.some(r => r == o.riId) ? o.proRataPlus : 0 }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.amountSubmitted }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.totalAmountAgreed }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.totalAmountReceived }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.variation }),
      //  '', '']

      //  : ['Grand Total', '', '', '',
      //    '',
      //    '',
      //    '',
      //    '',
      //    '',
      //    '', ''];

      let CellDisplayReinsuraneceByReinsurerSummarTotal_E = CellValReinsuraneceByReinsurerSummarTotal_E.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_E = CellDisplayReinsuraneceByReinsurerSummarTotal_E.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_E = CellDisplayReinsuraneceByReinsurerSummarTotal_E.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_F = CellValReinsuraneceByReinsurerSummarTotal_F.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_F = CellDisplayReinsuraneceByReinsurerSummarTotal_F.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_F = CellDisplayReinsuraneceByReinsurerSummarTotal_F.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_G = CellValReinsuraneceByReinsurerSummarTotal_G.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_G = CellDisplayReinsuraneceByReinsurerSummarTotal_G.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_G = CellDisplayReinsuraneceByReinsurerSummarTotal_G.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_H = CellValReinsuraneceByReinsurerSummarTotal_H.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_H = CellDisplayReinsuraneceByReinsurerSummarTotal_H.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_H = CellDisplayReinsuraneceByReinsurerSummarTotal_H.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_I = CellValReinsuraneceByReinsurerSummarTotal_I.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_I = CellDisplayReinsuraneceByReinsurerSummarTotal_I.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_I = CellDisplayReinsuraneceByReinsurerSummarTotal_I.split(',').pop();

      let reinsuranceByReinsurerSummaryFooter = (!response.isMesoClaim) ? ['Grand Total', '', '', '',
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_E + ':' + lastCellReinsuraneceBySummarTotal_E + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_F + ':' + lastCellReinsuraneceBySummarTotal_F + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_G + ':' + lastCellReinsuraneceBySummarTotal_G + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_H + ':' + lastCellReinsuraneceBySummarTotal_H + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_I + ':' + lastCellReinsuraneceBySummarTotal_I + ')', date1904: false },
        '', '']

        : ['Grand Total', '', '', '',
          '',
          '',
          '',
          '',
          '',
          '', ''];


      //Add Footer Row
      let reinsuranceByReinsurerSummaryFooterRow = worksheet.addRow(reinsuranceByReinsurerSummaryFooter);
      reinsuranceByReinsurerSummaryFooterRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByReinsurerSummar(reinsuranceByReinsurerSummaryFooterRow, !response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerSummaryFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      for (var i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 25;
      }

      //Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //fs.saveAs(blob, 'Reinsurance-Detail-Claim-' + claimId + '_' + Math.random() + '.xlsx');
        fs.saveAs(blob, 'Pro-Rata-Claim-' + claimId + '_' + Math.random() + '.xlsx');
      })
    });
  }

  downloadRIExportProRataMeso(claimId) {
    this.reinsuranceService.getClaimReinsuranceDetailForDownload(claimId).subscribe((response: any) => {
      //debugger;
      var proRataReinsurer = [4, 19, 35, 40, 50, 74];
      const title = 'Reinsurance Details of Claim ' + response.claimReference;

      const allocationOfIncurredCostHeader = ["Year", "Exposure Days", "Share Exp", "Share Incurred (£)"];
      const reinsuraneceByBasisHeader = (response.isMesoClaim) ? ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)"] : ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)"];

      const reinsuranceByReinsurerHeader = (response.isMesoClaim) ? ["Year", "Layer", "Excess (£)", "Limit (£)", "RI ID", "Reinsurer Name", "Status", "RI %", "PRO RATA (£)"]
        : ["Year", "Layer", "Excess (£)", "Limit (£)", "RI ID", "Reinsurer Name", "Status", "RI %", "PRO RATA (£)"];

      const reinsuranceByReinsurerSummaryHeader = (response.isMesoClaim) ? ["RI ID", "Reinsurer Name", "Parent Name", "RI Status", "PRO RATA (£)", "Amount Submitted (£)", "Amount Agreed (£)", "Amount Received (£)", "Variation (£)", "Invoice Number", "Notification Status"]
        : ["RI ID", "Reinsurer Name", "Parent Name", "RI Status", "PRO RATA (£)", "Amount Submitted (£)", "Amount Agreed (£)", "Amount Received (£)", "Variation (£)", "Invoice Number", "Notification Status"];

      const reinsuraneceByBasisYearsHeader = ["Year", "Layer", "Excess (£)", "Limit (£)", "PRO RATA (£)"];

      /* const reinsuraneceByBasisTotalHeader = ["Year", "Excess (£)", "Limit (£)", "PRO RATA (£)"];*/
      const reinsuraneceByBasisTotalHeader = ["Year", "PRO RATA (£)"];


      //const equitableHeader = ["Policy ID", "Policy Number", "Exposure From", "Exposure To", "Excess From", "	Excess To", "Excess Amount (Ã‚£)", "LTA", "LTA From", "LTA To", "Policy Days", "Exposure Days", "Share Percentage", "Share of Incurred (Ã‚£)", "Available EQUITABLE EXCESS (Ã‚£)", "Utilised EQUITABLE EXCESS (Ã‚£)"];
      //var header = proRataHeader;
      //const exposureHeader = ["Period", "MMI Exposure From", "MMI Exposure To", "Policy Number"];
      let allocationOfIncurredCostData: any[] = [];
      let reinsuraneceByBasisdata: any[] = [];
      let reinsuranceByReinsurerData: any[] = [];
      let reinsuranceByReinsurerSummaryData: any[] = [];
      let reinsuraneceByBasisYearsdata: any[] = [];
      let reinsuraneceByBasisTotaldata: any[] = [];
      response.allocationOfIncurredCosts.forEach(item => {
        if (response.isMesoClaim) {
          allocationOfIncurredCostData.push([item["exposureYear"]
            , item["exposureDays"]
            , item["shareExposurePct"]
            , item["shareIncurred"]]);
        }
      });

      //reinsuraneceByBasisdata
      response.reinsuranceByBasis.forEach(item => {
        if (response.isMesoClaim) {
          reinsuraneceByBasisdata.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]
            // , item["proRataPlus"]
          ]);
        }
      });

      //reinsuraneceByBasisYearsdata
      response.reinsuranceByBasis.forEach(item => {
        if (response.isMesoClaim) {
          reinsuraneceByBasisYearsdata.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]
          ]);
        }
      });

      //reinsuraneceByBasisTotaldata
      response.reinsuranceByBasis.forEach(item => {
        if (response.isMesoClaim) {
          reinsuraneceByBasisTotaldata.push([item["year"]
            // , item["layer"]
            //, item["excess"]
            // , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["proRata"]
            //, item["proRataPlus"]
          ]);
        }

      });

      var FilteredReinsuranceByReinsurer = response.reinsuranceByReinsurer.filter(
        r => r.status == "A (Active)" && r.parentReinsurerId != 25);

      FilteredReinsuranceByReinsurer.forEach(item => {
        if ((response.isMesoClaim)) {
          reinsuranceByReinsurerData.push([item["year"]
            , item["layer"]
            , item["excess"]
            , item["limit"]
            //, item["shareExcess"]
            //, item["shareLimit"]
            , item["riId"]
            , item["reinsurerName"]
            , item["status"]
            , item["riPercent"]
            , item["proRata"]
            //, proRataReinsurer.some(r => r == item["riId"]) ? item["proRataPlus"] : ''

          ]);
        }
      });




      //response.reinsuranceByReinsurer.forEach(item => {
      //  if ((response.isMesoClaim) && (item["status"] == "A (Active)")) {
      //    reinsuranceByReinsurerData.push([item["year"]
      //      , item["layer"]
      //      , item["excess"]
      //      , item["limit"]
      //      //, item["shareExcess"]
      //      //, item["shareLimit"]
      //      , item["riId"]
      //      , item["reinsurerName"]
      //      , item["status"]
      //      , item["riPercent"]
      //      , item["proRata"]
      //      //, proRataReinsurer.some(r => r == item["riId"]) ? item["proRataPlus"] : ''

      //    ]);
      //  }
      //});


      var FilteredReinsuranceByReinsurerSummary = response.reinsuranceByReinsurerSummary.filter(
        r => r.riStatus == "A (Active)" && r.parentReinsurerName != "Resolute");

      FilteredReinsuranceByReinsurerSummary.forEach(item => {
        if ((response.isMesoClaim)) {
          //debugger;
          reinsuranceByReinsurerSummaryData.push([item["riId"]
            , item["reinsurerName"]
            , item["parentReinsurerName"]
            , item["riStatus"]
            , item["proRataCalc"]
            // , proRataReinsurer.some(r => r == item["riId"]) ? item["proRataPlus"] : ''
            , item["amountSubmitted"]
            , item["totalAmountAgreed"]
            //  , item["dateAgreed"] == null ? "" : moment(item["dateAgreed"]).format('DD/MM/YYYY')
            , item["totalAmountReceived"]
            // , item["dateReceived"] == null ? "" : moment(item["dateReceived"]).format('DD/MM/YYYY')
            , item["variation"]
            , item["invoiceNo"] == null ? "" : item["invoiceNo"]
            , item["notificationStatus"] == null ? "" : item["notificationStatus"]]);
        }
      });


      //response.reinsuranceByReinsurerSummary.forEach(item => {
      //  if ((response.isMesoClaim) && (item["riStatus"] == "A (Active)") && (item["parentReinsurerName"] != "Resolute")) {
      //    //debugger;
      //    reinsuranceByReinsurerSummaryData.push([item["riId"]
      //      , item["reinsurerName"]
      //      , item["parentReinsurerName"]
      //      , item["riStatus"]
      //      , item["proRataCalc"]
      //      // , proRataReinsurer.some(r => r == item["riId"]) ? item["proRataPlus"] : ''
      //      , item["amountSubmitted"]
      //      , item["totalAmountAgreed"]
      //      //  , item["dateAgreed"] == null ? "" : moment(item["dateAgreed"]).format('DD/MM/YYYY')
      //      , item["totalAmountReceived"]
      //      // , item["dateReceived"] == null ? "" : moment(item["dateReceived"]).format('DD/MM/YYYY')
      //      , item["variation"]
      //      , item["invoiceNo"] == null ? "" : item["invoiceNo"]
      //      , item["notificationStatus"] == null ? "" : item["notificationStatus"]]);
      //  }
      //});

      //response.policyExposures.forEach((item, index) => {
      //  exposureData.push([index + 1
      //    , moment(item["exposureFrom"]).format('DD/MM/YYYY')
      //    , moment(item["exposureTo"]).format('DD/MM/YYYY')
      //    , item["policyNumber"]]);
      //});

      //Create workbook and worksheet
      let workbook: ExcelProper.Workbook = new Excel.Workbook();
      let worksheet = workbook.addWorksheet('Pro-Rata-Meso');

      //Add Row and formatting
      let titleRow = worksheet.addRow([title]);
      titleRow.font = { bold: true, size: 16 };
      titleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });
      worksheet.mergeCells('A1:L2');
      //Blank Row 
      worksheet.addRow([]);

      let summaryRow1 = worksheet.addRow(["Claim Id", response.claimId]);
      this.fillBackGroundInExcellCell(summaryRow1.findCell(1));
      summaryRow1.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow1.findCell(1));
      this.setBorderExcelCell(summaryRow1.findCell(2));
      let summaryRow2 = worksheet.addRow(["Claim Reference", response.claimReference]);
      this.fillBackGroundInExcellCell(summaryRow2.findCell(1));
      summaryRow2.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow2.findCell(1));
      this.setBorderExcelCell(summaryRow2.findCell(2));
      let summaryRow3 = worksheet.addRow(["Insured Title", response.insuredTitle]);
      this.fillBackGroundInExcellCell(summaryRow3.findCell(1));
      summaryRow3.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow3.findCell(1));
      this.setBorderExcelCell(summaryRow3.findCell(2));
      let summaryRowX = worksheet.addRow(["Claimant Name", response.claimantName]);
      this.fillBackGroundInExcellCell(summaryRowX.findCell(1));
      summaryRowX.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRowX.findCell(1));
      this.setBorderExcelCell(summaryRowX.findCell(2));

      worksheet.addRow([]);

      let summaryRow4 = worksheet.addRow(["MMI Valuation", response.mmiValuation]);
      this.fillBackGroundInExcellCell(summaryRow4.findCell(1));
      summaryRow4.findCell(1).font = { bold: true, italic: true };
      this.setBorderExcelCell(summaryRow4.findCell(1));
      this.setBorderExcelCell(summaryRow4.findCell(2));
      summaryRow4.findCell(2).numFmt = '£###,###,##0.00;'
      let summaryRow5 = worksheet.addRow(["Excess Paid", response.excessPaid]);
      this.fillBackGroundInExcellCell(summaryRow5.findCell(1));
      summaryRow5.findCell(1).font = { bold: true, italic: true };
      summaryRow5.findCell(2).numFmt = '£###,###,##0.00;'
      let summaryRow6 = worksheet.addRow(["MMI Paid", response.netPaid]);
      this.fillBackGroundInExcellCell(summaryRow6.findCell(1));
      summaryRow6.findCell(1).font = { bold: true, italic: true };
      summaryRow6.findCell(2).numFmt = '£###,###,##0.00;'
      this.setBorderExcelCell(summaryRow6.findCell(1));
      this.setBorderExcelCell(summaryRow6.findCell(2));
      let summaryRow7 = worksheet.addRow(["MMI Reserve", response.outstandingAmount]);
      this.fillBackGroundInExcellCell(summaryRow7.findCell(1));
      summaryRow7.findCell(1).font = { bold: true, italic: true };
      summaryRow7.findCell(2).numFmt = '£###,###,##0.00;'
      this.setBorderExcelCell(summaryRow7.findCell(1));
      this.setBorderExcelCell(summaryRow7.findCell(2));
      let summaryRow8 = worksheet.addRow(["MMI Incurred", response.totalIncurred]);
      this.fillBackGroundInExcellCell(summaryRow8.findCell(1));
      summaryRow8.findCell(2).numFmt = '£###,###,##0.00;'
      summaryRow8.findCell(1).font = { bold: true };
      this.setBorderExcelCell(summaryRow8.findCell(1));
      this.setBorderExcelCell(summaryRow8.findCell(2));
      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      let exposureTitleRow = worksheet.addRow(["Allocation Of Incurred Costs"]);
      exposureTitleRow.font = { bold: true };
      exposureTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      worksheet.mergeCells('A16:D16');

      let allocationOfIncurredHeaderRow = worksheet.addRow(allocationOfIncurredCostHeader);
      // Cell Style : Fill and Border
      allocationOfIncurredHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })

      let CellValallocationOfIncurredTotal = "";

      allocationOfIncurredHeaderRow.font = { bold: true };
      allocationOfIncurredCostData.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell D to show the formula on excel sheet.
          if (row.cellCount > 0) {
            if (cell.address.startsWith('D')) {
              CellValallocationOfIncurredTotal = CellValallocationOfIncurredTotal + cell.address + ',';
            }
          }
        })
        this.setCellFormatOfAllocationOfIncurred(row);
      });

      //let allocationOfIncurredFooter = (response.isMesoClaim) ? ['Grand Total',
      //  _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.exposureDays }),
      //  100.00,
      //  _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.shareIncurred })
      //] : ['Grand Total',
      //  '',
      //  '',
      //  ''
      //];

      let CellDisplayallocationOfIncurredTotal = CellValallocationOfIncurredTotal.replace(/,*$/, '');
      const firstCellallocationOfIncurredTotal = CellDisplayallocationOfIncurredTotal.split(',').shift();
      const lastCellallocationOfIncurredTotal = CellDisplayallocationOfIncurredTotal.split(',').pop();

      let allocationOfIncurredFooter = (response.isMesoClaim) ? ['Grand Total',
        _.sumBy(response.allocationOfIncurredCosts, (o: any) => { return o.exposureDays }),
        100.00,
        { formula: '=SUM(' + firstCellallocationOfIncurredTotal + ':' + lastCellallocationOfIncurredTotal + ')', date1904: false }
      ] : ['Grand Total',
        '',
        '',
        ''
      ];

      //Add Footer Row
      let allocationOfIncurredFooterRow = worksheet.addRow(allocationOfIncurredFooter);
      allocationOfIncurredFooterRow.font = { bold: true };
      this.setCellFormatOfAllocationOfIncurred(allocationOfIncurredFooterRow);
      // Cell Style : Fill and Border
      allocationOfIncurredFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });



      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);


      //Add Reinsurance By Basis
      let reinsuranceByBasisTitleRow = worksheet.addRow(["Reinsurance By Basis"]);
      reinsuranceByBasisTitleRow.font = { bold: true };
      reinsuranceByBasisTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      console.log(reinsuranceByBasisTitleRow);

      worksheet.mergeCells('A' + reinsuranceByBasisTitleRow.number + ':E' + reinsuranceByBasisTitleRow.number);
      let reinsuranceByBasisHeaderRow = worksheet.addRow(reinsuraneceByBasisHeader);
      // Cell Style : Fill and Border
      reinsuranceByBasisHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByBasisHeaderRow.font = { bold: true };
      var yearIndex = 0;

      let CellValReinsuraneceByBasis = "";
      let CellValReinsuraneceByBasisGT = "";

      reinsuraneceByBasisdata.forEach((d, i, array) => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell E to show the formula on excel sheet.
          if (row.cellCount > 0) {
            if (cell.address.startsWith('E')) {
              CellValReinsuraneceByBasis = CellValReinsuraneceByBasis + cell.address + ',';
            }
          }

        })
        this.setCellFormatOfReinsuranceByBasis(row, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuraneceByBasisdata.length - 1 || d[0] != reinsuraneceByBasisdata[i + 1][0]) {

          //let footer = (response.isMesoClaim) ? [d[0] + ' Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRata : 0 })]
          //  : [d[0] + ' Total', '', '', '', ''];

          let CellDisplayReinsuraneceByBasis = CellValReinsuraneceByBasis.replace(/,*$/, '');
          const firstCellReinsuraneceByBasis = CellDisplayReinsuraneceByBasis.split(',').shift();
          const lastCellReinsuraneceByBasis = CellDisplayReinsuraneceByBasis.split(',').pop();

          let footer = (response.isMesoClaim) ? [d[0] + ' Total', '', '', '', { formula: '=SUM(' + firstCellReinsuraneceByBasis + ':' + lastCellReinsuraneceByBasis + ')', date1904: false }]
            : [d[0] + ' Total', '', '', '', ''];

          CellValReinsuraneceByBasis = "";

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

            //Extracting values for cell E to show the formula on excel sheet.
            if (footerRow.cellCount > 0) {
              if (cell.address.startsWith('E')) {
                CellValReinsuraneceByBasisGT = CellValReinsuraneceByBasisGT + cell.address + ',';
              }
            }

          });
          this.setCellFormatOfReinsuranceByBasis(footerRow, response.isMesoClaim, response.lineOfBusiness);
          worksheet.mergeCells('A' + (row.number - yearIndex) + ':A' + row.number);
          yearIndex = -1;
        }
        yearIndex++;
      });
      //let footer = (response.isMesoClaim) ? ['Grand Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata })]
      //  : ['Grand Total', '', '', '', ''];
      let CellDisplayReinsuraneceByBasisGT = CellValReinsuraneceByBasisGT.replace(/,*$/, '');

      let footer = (response.isMesoClaim) ? ['Grand Total', '', '', '', { formula: '=SUM(' + CellDisplayReinsuraneceByBasisGT + ')', date1904: false }]
        : ['Grand Total', '', '', '', ''];

      //Add Footer Row
      let footerRow = worksheet.addRow(footer);
      footerRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByBasis(footerRow, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      footerRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //***************Start*********************Add Reinsurance By Basis Years*********************************************
      let reinsuranceByBasisYearsTitleRow = worksheet.addRow(["Reinsurance By Basis"]);
      reinsuranceByBasisYearsTitleRow.font = { bold: true };
      reinsuranceByBasisYearsTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      console.log(reinsuranceByBasisYearsTitleRow);

      worksheet.mergeCells('A' + reinsuranceByBasisYearsTitleRow.number + ':E' + reinsuranceByBasisYearsTitleRow.number);
      //worksheet.mergeCells('H27:L27');
      let reinsuranceByBasisYearsHeaderRow = worksheet.addRow(reinsuraneceByBasisYearsHeader);
      // Cell Style : Fill and Border
      reinsuranceByBasisYearsHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByBasisYearsHeaderRow.font = { bold: true };
      var yearIndex = 0;

      let CellValReinsuraneceByBasisYear = "";

      reinsuraneceByBasisYearsdata.forEach((d, i, array) => {
        let rowYear = worksheet.addRow(d);
        rowYear.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell D to show the formula on excel sheet.
          if (rowYear.cellCount > 0) {
            if (cell.address.startsWith('E')) {
              CellValReinsuraneceByBasisYear = CellValReinsuraneceByBasisYear + cell.address + ',';
            }
          }

        })
        this.setCellFormatOfReinsuranceByBasisYears(rowYear, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuraneceByBasisYearsdata.length - 1 || d[0] != reinsuraneceByBasisYearsdata[i + 1][0]) {

          worksheet.mergeCells('A' + (rowYear.number - yearIndex) + ':A' + rowYear.number);
          yearIndex = -1;
        }
        yearIndex++;
      });
      //let footerYears = (response.isMesoClaim) ? ['Grand Total', '', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata })]
      //  : ['Grand Total', '', '', '', ''];

      let CellDisplayReinsuraneceByBasisYear = CellValReinsuraneceByBasisYear.replace(/,*$/, '');
      const firstCellReinsuraneceByBasisYear = CellDisplayReinsuraneceByBasisYear.split(',').shift();
      const lastCellReinsuraneceByBasisYear = CellDisplayReinsuraneceByBasisYear.split(',').pop();

      let footerYears = (response.isMesoClaim) ? ['Grand Total', '', '', '', { formula: '=SUM(' + firstCellReinsuraneceByBasisYear + ':' + lastCellReinsuraneceByBasisYear + ')', date1904: false }]
        : ['Grand Total', '', '', '', ''];


      //Add Footer Row
      let footerRowYears = worksheet.addRow(footerYears);
      footerRowYears.font = { bold: true };
      this.setCellFormatOfReinsuranceByBasisYears(footerRowYears, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      footerRowYears.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //***************End*********************Add Reinsurance By Basis Years*********************************************

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //***************Start*********************Add Reinsurance By Basis Total*********************************************
      let reinsuranceByBasisTotalTitleRow = worksheet.addRow(["Reinsurance By Basis"]);
      reinsuranceByBasisTotalTitleRow.font = { bold: true };
      reinsuranceByBasisTotalTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      console.log(reinsuranceByBasisTotalTitleRow);

      worksheet.mergeCells('A' + reinsuranceByBasisTotalTitleRow.number + ':B' + reinsuranceByBasisTotalTitleRow.number);
      //worksheet.mergeCells('H27:L27');
      let reinsuranceByBasisTotalHeaderRow = worksheet.addRow(reinsuraneceByBasisTotalHeader);
      // Cell Style : Fill and Border
      reinsuranceByBasisTotalHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByBasisTotalHeaderRow.font = { bold: true };
      var yearIndex = 0;

      let CellValReinsuraneceByBasisTotal = "";

      reinsuraneceByBasisTotaldata.forEach((d, i, array) => {
        //let rowTotal = worksheet.addRow(d);
        //rowTotal.eachCell((cell, number) => {
        //  this.setBorderExcelCell(cell);
        //})
        //this.setCellFormatOfReinsuranceByBasisYears(rowTotal, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuraneceByBasisTotaldata.length - 1 || d[0] != reinsuraneceByBasisTotaldata[i + 1][0]) {

          let footer = (response.isMesoClaim) ? [d[0] + ' Total', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.year == d[0] ? o.proRata : 0 })] : [d[0] + ' Total', ''];

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: false };
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            //this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

            //Extracting values for cell D to show the formula on excel sheet.
            if (footerRow.cellCount > 0) {
              if (cell.address.startsWith('B')) {
                CellValReinsuraneceByBasisTotal = CellValReinsuraneceByBasisTotal + cell.address + ',';
              }
            }

          });
          this.setCellFormatOfReinsuranceByBasisTotal(footerRow, response.isMesoClaim, response.lineOfBusiness);
          //worksheet.mergeCells('S' + (rowTotal.number - yearIndex) + ':S' + rowTotal.number);
          yearIndex = -1;
        }
        yearIndex++;
      });
      //let footerTotal = (response.isMesoClaim) ? ['Grand Total', '', '', _.sumBy(response.reinsuranceByBasis, (o: any) => { return o.proRata })]
      //  : ['Grand Total', '', '', ''];

      let CellDisplayReinsuraneceByBasisTotal = CellValReinsuraneceByBasisTotal.replace(/,*$/, '');
      const firstCellReinsuraneceByBasisTotal = CellDisplayReinsuraneceByBasisTotal.split(',').shift();
      const lastCellReinsuraneceByBasisTotal = CellDisplayReinsuraneceByBasisTotal.split(',').pop();

      let footerTotal = (response.isMesoClaim) ? ['Grand Total', { formula: '=SUM(' + firstCellReinsuraneceByBasisTotal + ':' + lastCellReinsuraneceByBasisTotal + ')', date1904: false }]
        : ['Grand Total', ''];

      //Add Footer Row
      let footerRowTotal = worksheet.addRow(footerTotal);
      footerRowTotal.font = { bold: true };
      this.setCellFormatOfReinsuranceByBasisTotal(footerRowTotal, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      footerRowTotal.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      //***************End*********************Add Reinsurance By Basis Total*********************************************

      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Reinsurance By Reinsurer
      let reinsuranceByReinsurerTitleRow = worksheet.addRow(["Reinsurance By Reinsurer"]);
      reinsuranceByReinsurerTitleRow.font = { bold: true };
      reinsuranceByReinsurerTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      // console.log(reinsuranceByBasisTitleRow);

      worksheet.mergeCells('A' + reinsuranceByReinsurerTitleRow.number + ':I' + reinsuranceByReinsurerTitleRow.number);
      let reinsuranceByReinsurerHeaderRow = worksheet.addRow(reinsuranceByReinsurerHeader);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })
      reinsuranceByReinsurerHeaderRow.font = { bold: true };
      var layerIndex = 0;
      var yearIndex = 0;

      let CellValReinsuraneceByReinsurerTotal = "";
      let CellValReinsuraneceByReinsurerSubTotal = "";
      let CellValReinsuraneceByReinsurerGT = "";

      reinsuranceByReinsurerData.forEach((d, i, array) => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          //Extracting values for cell I to show the formula on excel sheet.
          if (row.cellCount > 0) {
            if (cell.address.startsWith('I')) {
              CellValReinsuraneceByReinsurerTotal = CellValReinsuraneceByReinsurerTotal + cell.address + ',';
            }
          }
        })
        this.setCellFormatOfReinsuranceByReinsurer(row, response.isMesoClaim, response.lineOfBusiness);
        if (i == reinsuranceByReinsurerData.length - 1 || d[1] != reinsuranceByReinsurerData[i + 1][1] || d[0] != reinsuranceByReinsurerData[i + 1][0]) {

          //let footer = (response.isMesoClaim) ? ['', 'Total', '', '', '', '', '', '', _.sumBy(FilteredReinsuranceByReinsurer, (o: any) => { return (o.year == d[0] && o.layer == d[1]) ? o.proRata : 0 })]
          //  : ['', 'Total', '', '', '', '', '', '', ''];

          let CellDisplayReinsuraneceByReinsurerTotal = CellValReinsuraneceByReinsurerTotal.replace(/,*$/, '');
          const firstCellReinsuraneceByReinsurerTotal = CellDisplayReinsuraneceByReinsurerTotal.split(',').shift();
          const lastCellReinsuraneceByReinsurerTotal = CellDisplayReinsuraneceByReinsurerTotal.split(',').pop();

          let footer = (response.isMesoClaim) ? ['', 'Total', '', '', '', '', '', '', { formula: '=SUM(' + firstCellReinsuraneceByReinsurerTotal + ":" + lastCellReinsuraneceByReinsurerTotal + ')', date1904: false }]
            : ['', 'Total', '', '', '', '', '', '', ''];

          CellValReinsuraneceByReinsurerTotal = "";

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          this.setCellFormatOfReinsuranceByReinsurer(footerRow, response.isMesoClaim, response.lineOfBusiness);
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            if (number != 1) {
              this.fillBackGroundInExcellCell(cell);

              //Extracting values for cell I to show the formula on excel sheet.
              if (footerRow.cellCount > 0) {
                if (cell.address.startsWith('I')) {
                  CellValReinsuraneceByReinsurerSubTotal = CellValReinsuraneceByReinsurerSubTotal + cell.address + ',';
                }
              }

            }
            this.setBorderExcelCell(cell);

          });
          worksheet.mergeCells('B' + (row.number - layerIndex) + ':B' + row.number);
          worksheet.mergeCells('C' + (row.number - layerIndex) + ':C' + row.number);
          worksheet.mergeCells('D' + (row.number - layerIndex) + ':D' + row.number);
          //worksheet.mergeCells('E' + (row.number - layerIndex) + ':E' + row.number);
          //worksheet.mergeCells('F' + (row.number - layerIndex) + ':F' + row.number);
          layerIndex = -1;
          yearIndex++;
        }
        if (i == reinsuranceByReinsurerData.length - 1 || d[0] != reinsuranceByReinsurerData[i + 1][0]) {
          //let footer = (response.isMesoClaim) ? [d[0] + ' Total', '', '', '', '', '', '', '', _.sumBy(FilteredReinsuranceByReinsurer, (o: any) => { return (o.year == d[0]) ? o.proRata : 0 })]
          //  : [d[0] + ' Total', '', '', '', '', '', '', '', ''];

          let CellDisplayReinsuraneceByReinsurerSubTotal = CellValReinsuraneceByReinsurerSubTotal.replace(/,*$/, '');

          let footer = (response.isMesoClaim) ? [d[0] + ' Total', '', '', '', '', '', '', '', { formula: '=SUM(' + CellDisplayReinsuraneceByReinsurerSubTotal + ')', date1904: false }]
            : [d[0] + ' Total', '', '', '', '', '', '', '', ''];

          CellValReinsuraneceByReinsurerSubTotal = "";

          //Add Footer Row
          let footerRow = worksheet.addRow(footer);
          footerRow.font = { bold: true };
          this.setCellFormatOfReinsuranceByReinsurer(footerRow, response.isMesoClaim, response.lineOfBusiness);
          // Cell Style : Fill and Border
          footerRow.eachCell((cell, number) => {
            this.fillBackGroundInExcellCell(cell);
            this.setBorderExcelCell(cell);

            //Extracting values for cell I to show the formula on excel sheet.
            if (footerRow.cellCount > 0) {
              if (cell.address.startsWith('I')) {
                CellValReinsuraneceByReinsurerGT = CellValReinsuraneceByReinsurerGT + cell.address + ',';
              }
            }

          });
          worksheet.mergeCells('A' + (row.number - yearIndex + 1) + ':A' + (row.number + 1));
          yearIndex = -1;
        }
        layerIndex++;
        yearIndex++;
      });

      //let reinsuranceByReinsurerFooter = (response.isMesoClaim) ? ['Grand Total', '', '', '', '', '', '', '', _.sumBy(FilteredReinsuranceByReinsurer, (o: any) => { return o.proRata })]
      //  : ['Grand Total', '', '', '', '', '', '', '', ''];

      let CellDisplayReinsuraneceByReinsurerGT = CellValReinsuraneceByReinsurerGT.replace(/,*$/, '');

      let reinsuranceByReinsurerFooter = (response.isMesoClaim) ? ['Grand Total', '', '', '', '', '', '', '', { formula: '=SUM(' + CellDisplayReinsuraneceByReinsurerGT + ')', date1904: false }]
        : ['Grand Total', '', '', '', '', '', '', '', ''];


      //Add Footer Row
      let reinsuranceByReinsurerFooterRow = worksheet.addRow(reinsuranceByReinsurerFooter);
      reinsuranceByReinsurerFooterRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByReinsurer(reinsuranceByReinsurerFooterRow, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });


      //Blank Row 
      worksheet.addRow([]);
      worksheet.addRow([]);

      //Add Reinsurance By Reinsurer Summary
      let reinsuranceByReinsurerSummaryTitleRow = worksheet.addRow(["Reinsurance By Reinsurer: Summary"]);
      reinsuranceByReinsurerSummaryTitleRow.font = { bold: true };
      reinsuranceByReinsurerSummaryTitleRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
        cell.alignment = { horizontal: 'center' };
      });
      // console.log(reinsuranceByBasisTitleRow);
      //worksheet.mergeCells('A' + reinsuranceByReinsurerSummaryTitleRow.number + ((response.isMesoClaim && response.lineOfBusiness == 146) ? ':K' : ':J') + reinsuranceByReinsurerSummaryTitleRow.number);
      worksheet.mergeCells('A' + reinsuranceByReinsurerSummaryTitleRow.number + ':K' + reinsuranceByReinsurerSummaryTitleRow.number);
      let reinsuranceByReinsurerSummaryHeaderRow = worksheet.addRow(reinsuranceByReinsurerSummaryHeader);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerSummaryHeaderRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);

      })

      let CellValReinsuraneceByReinsurerSummarTotal_E = "";
      let CellValReinsuraneceByReinsurerSummarTotal_F = "";
      let CellValReinsuraneceByReinsurerSummarTotal_G = "";
      let CellValReinsuraneceByReinsurerSummarTotal_H = "";
      let CellValReinsuraneceByReinsurerSummarTotal_I = "";

      reinsuranceByReinsurerSummaryHeaderRow.font = { bold: true };
      reinsuranceByReinsurerSummaryData.forEach(d => {
        let row = worksheet.addRow(d);
        row.eachCell((cell, number) => {
          this.setBorderExcelCell(cell);

          if (row.cellCount > 0) {
            //Extracting values for cell E to show the formula on excel sheet.
            if (cell.address.startsWith('E')) {
              CellValReinsuraneceByReinsurerSummarTotal_E = CellValReinsuraneceByReinsurerSummarTotal_E + cell.address + ',';
            }

            //Extracting values for cell F to show the formula on excel sheet.
            if (cell.address.startsWith('F')) {
              CellValReinsuraneceByReinsurerSummarTotal_F = CellValReinsuraneceByReinsurerSummarTotal_F + cell.address + ',';
            }

            //Extracting values for cell G to show the formula on excel sheet.
            if (cell.address.startsWith('G')) {
              CellValReinsuraneceByReinsurerSummarTotal_G = CellValReinsuraneceByReinsurerSummarTotal_G + cell.address + ',';
            }

            //Extracting values for cell H to show the formula on excel sheet.
            if (cell.address.startsWith('H')) {
              CellValReinsuraneceByReinsurerSummarTotal_H = CellValReinsuraneceByReinsurerSummarTotal_H + cell.address + ',';
            }

            //Extracting values for cell I to show the formula on excel sheet.
            if (cell.address.startsWith('I')) {
              CellValReinsuraneceByReinsurerSummarTotal_I = CellValReinsuraneceByReinsurerSummarTotal_I + cell.address + ',';
            }
          }

        })
        this.setCellFormatOfReinsuranceByReinsurerSummar(row, response.isMesoClaim, response.lineOfBusiness);
      });

      //let reinsuranceByReinsurerSummaryFooter = (response.isMesoClaim) ? ['Grand Total', '', '', '',
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.proRataCalc }),
      //  // _.sumBy(response.reinsuranceByReinsurerSummary, (o: any) => { return proRataReinsurer.some(r => r == o.riId) ? o.proRataPlus : 0 }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.amountSubmitted }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.totalAmountAgreed }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.totalAmountReceived }),
      //  _.sumBy(FilteredReinsuranceByReinsurerSummary, (o: any) => { return o.variation }),
      //  '', '']

      //  : ['Grand Total', '', '', '',
      //    '',
      //    '',
      //    '',
      //    '',
      //    '',
      //    '', ''];

      let CellDisplayReinsuraneceByReinsurerSummarTotal_E = CellValReinsuraneceByReinsurerSummarTotal_E.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_E = CellDisplayReinsuraneceByReinsurerSummarTotal_E.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_E = CellDisplayReinsuraneceByReinsurerSummarTotal_E.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_F = CellValReinsuraneceByReinsurerSummarTotal_F.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_F = CellDisplayReinsuraneceByReinsurerSummarTotal_F.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_F = CellDisplayReinsuraneceByReinsurerSummarTotal_F.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_G = CellValReinsuraneceByReinsurerSummarTotal_G.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_G = CellDisplayReinsuraneceByReinsurerSummarTotal_G.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_G = CellDisplayReinsuraneceByReinsurerSummarTotal_G.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_H = CellValReinsuraneceByReinsurerSummarTotal_H.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_H = CellDisplayReinsuraneceByReinsurerSummarTotal_H.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_H = CellDisplayReinsuraneceByReinsurerSummarTotal_H.split(',').pop();

      let CellDisplayReinsuraneceByReinsurerSummarTotal_I = CellValReinsuraneceByReinsurerSummarTotal_I.replace(/,*$/, '');
      const firstCellReinsuraneceBySummarTotal_I = CellDisplayReinsuraneceByReinsurerSummarTotal_I.split(',').shift();
      const lastCellReinsuraneceBySummarTotal_I = CellDisplayReinsuraneceByReinsurerSummarTotal_I.split(',').pop();

      let reinsuranceByReinsurerSummaryFooter = (response.isMesoClaim) ? ['Grand Total', '', '', '',
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_E + ':' + lastCellReinsuraneceBySummarTotal_E + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_F + ':' + lastCellReinsuraneceBySummarTotal_F + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_G + ':' + lastCellReinsuraneceBySummarTotal_G + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_H + ':' + lastCellReinsuraneceBySummarTotal_H + ')', date1904: false },
        { formula: '=SUM(' + firstCellReinsuraneceBySummarTotal_I + ':' + lastCellReinsuraneceBySummarTotal_I + ')', date1904: false },
        '', '']

        : ['Grand Total', '', '', '',
          '',
          '',
          '',
          '',
          '',
          '', ''];


      //Add Footer Row
      let reinsuranceByReinsurerSummaryFooterRow = worksheet.addRow(reinsuranceByReinsurerSummaryFooter);
      reinsuranceByReinsurerSummaryFooterRow.font = { bold: true };
      this.setCellFormatOfReinsuranceByReinsurerSummar(reinsuranceByReinsurerSummaryFooterRow, response.isMesoClaim, response.lineOfBusiness);
      // Cell Style : Fill and Border
      reinsuranceByReinsurerSummaryFooterRow.eachCell((cell, number) => {
        this.fillBackGroundInExcellCell(cell);
        this.setBorderExcelCell(cell);
      });

      for (var i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 25;
      }

      //Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //fs.saveAs(blob, 'Reinsurance-Detail-Claim-' + claimId + '_' + Math.random() + '.xlsx');
        fs.saveAs(blob, 'Pro-Rata-Meso-Claim-' + claimId + '_' + Math.random() + '.xlsx');
      })
    });
  }

  getReinsuranceBreakDownByYearByClaimId(claimId: any): any {
    return this.reinsuranceService.getClaimReinsuranceDetailForDownload(claimId);
  }

}

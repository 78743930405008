import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, AfterViewChecked, ElementRef, HostListener, Output, EventEmitter, Inject } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { NgForm, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IPager } from '../../../shared/models/pager.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ClaimDetailService } from './claim-detail.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { Observable } from 'rxjs/internal/Observable';
import { Subject, Subscription } from 'rxjs';
import * as _ from "lodash";
import { ToastrService } from 'ngx-toastr';
import { claimAdditionalPoliciesVM } from '../../../models/claimAdditionalPolicies';
import { claimCreditorModel } from './claimCreditorModel';
import {
  claimType, enumYesNoTBC, enumAggregationLevel, enumDeceased, enumAgregatedClaim, enumClaimStatus,
  enumHandlingOrganization, enumHandlingStatus, enumChargingStatus, enumPermissions, enumUserRole, enumDelegatedAuthority
} from '../../../shared/models/common.enum';
import { ComponentCanDeactivate } from '../../../shared/guard/unsaved-changes.guard';
import { SecurityService } from '../../../shared/services/security.service';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { parse } from 'path';
import { NotificationService } from '../../../shared/services/notification.service';
import { StorageService } from '../../../shared/services/storage.service';
import { ApportionmentService } from '../../../shared/services/apportionment.service';
import { ValuationService } from '../../../shared/services/valuation.service';
import { error } from 'protractor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClaimComponent } from '../claim.component';
import { PaymentDetailService } from '../payment-detail/payment-detail.service';
import { IPayment } from '../payment-detail/payment.model';

@Component({
  selector: 'app-claim-detail',
  templateUrl: './claim-detail.component.html',
  styleUrls: ['./claim-detail.component.css']
})
export class ClaimDetailComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked, ComponentCanDeactivate {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('additionalPoliciesTable') additionalPoliciesTable: ElementRef;
  public dtOptions: any = {};
  @ViewChild('claimTabset') claimTabset;
  @Output() updateHeaderPanel: EventEmitter<any> = new EventEmitter();

  public claimAdditionalPoliciesVM: claimAdditionalPoliciesVM = new claimAdditionalPoliciesVM();
  public searchPolicyResult: claimAdditionalPoliciesVM[];
  //private datatableElement: DataTableDirective;
  public paginationInfo: IPager;
  public authenticated: boolean = false;
  public authSubscription: Subscription;
  public errorReceived: boolean;
  public dtTrigger: Subject<any>;
  searching: boolean;
  backButton: boolean = false;
  showSettlementReason3: boolean = false;
  isSettledInPierian: boolean = false;
  isLitigationServiceDateAvailble: boolean = false;
  isNonApprovedOrRejectedPaymentAvailble: boolean = false;
  isReadOnly: boolean = false;

  CreditorId: string;

  currentUrl: string;
  isValid: boolean = true;
  isNewClaim: boolean;
  newClaimId: number;
  showAbuseType: boolean;
  policyId: number;
  disabelDateofDeath: boolean;
  claimID: number;
  isGridLoaded: boolean = false;
  claimDetailForm: FormGroup;
  policyForm: FormGroup;
  submitted = false;
  policyData: any;
  additionalPolicies: any;
  claimantFullMatchList: any;
  claimantPartialMatchList: any;
  additionalPolicyDetailsList: claimAdditionalPoliciesVM[];
  
  claimTypeLookup: any[];
  claimStatusLookup: any[];
  genderLookup: any[];
  occupationTypeLookup: any[];
  titleTypeLookup: any[];
  chargingBasisTypeLookup: any[];
  riskTypeLookup: any[];
  abuserTypeLookup: any[];
  aggregationLevelLookup: any[];
  locationLookup: any[];
  allegedPerpetratorLookup: any[];
  abuseCodeLookup: any[];
  allegationsLookup: any[];
  settlementReason1: any[];
  settlementReason2: any[];
  settlementReason3: any[];
  jurisdictionLookup: any[];
  ClaimantSolicitorsLookup: any[];
  delegatedAuthorityLookup: any[];
  deceasedLookup: any[];
  yesNoLookup: any[];
  lineofBusinessLookup: any[];
  handlingStatusLookup: any[];

  agregatedClaimkeys: any[];
  agregatedClaimEnum = enumAgregatedClaim;

  deceasedkeys: any[];
  deceasedEnum = enumDeceased;

  creditor: any;

  handlingOrganisation: any[];
  claimHandlerName: any[];
  claimLevelSchemeCreditors: claimCreditorModel[];

  chargingBasisError: string;
  notificationDateError: string;
  openDateError: string;
  dateofDeathError: string;
  deceasedError: string;
  claimStatusError: string;
  aggregatedClaimError: string;
  aggregatedLavelError: string;
  locationError: string;
  allegedPerpetratorError: string;
  handlingStatusError: string;
  eFileURL: string;
  currentTab: string;
  creditorNo: string;

  aggregatedPolicyholderName: string;
  aggregatedPolicyholderID: string;
  aggregatedPolicyholderStatus: string;
  hideSettleClaimButton: boolean;
  hideReopenClaimButton: boolean;
  isNonMMIFinanceUser: boolean = false;
  oldClaimHandlerID: number = null;
  oldOrganizationID: number = null;
  hideWorkflowButton: boolean = true;
  saveMethod: any;
  isFormSubmitted: boolean = true;
  public formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean { return false; }
  handlingOrganisationName: string;
  claimHandlerMandatoryFlag: boolean;
  isOnload: boolean = false;
  selectedItemOnLoad: number = 0;
  isSettledButtonClicked: boolean = false;
  reserveLargeLosslimitMessageShownCount: number = 0;
  reserveBreachedDALimitMessageShownCount: number = 0;
  modalRef: NgbModalRef;
  fullMatchingClaimantsList: any;
  partialMatchingClaimantsList: any;
  hideSaveButton: boolean;
  oldClaimTypeId: number;
  oldClaimantFirstName: string;
  oldClaimantLastName: string;
  oldClaimantDateofBirth: Date;
  oldClaimantNationalInsuranceNumber: string
  bgColorForClaimantMatch: string = 'transparent';
  showWarningMessageForMatchingClaimants: boolean = false;
  warningMessageForMatchingClaimantShownCount: number = 0;
  settlementReasonsLookup: any[];
  yesNoAndNALookup: any[];
  nonRIRecoveriesStageLookup: any[];
  nonRIRecoveriesStageError: string;
  isMMIUserWithClaimSuperUserRole: boolean = false;
  isMMIUserWithClaimsAndFinanceSuperUserRole: boolean = false;

  public customPatterns = { 'S': { pattern: new RegExp('\[A-Z\]') }, '0': { pattern: new RegExp('\[0-9\]') } };

  @HostListener('window:beforeunload')

  //canDeactivate(): Observable<boolean> | boolean {
  // CR-18 Merge Request Changes
  canDeactivate(): Observable<boolean> | boolean {
    //if (nextState.url.includes('apportionment') || nextState.url.includes('valuation')) {
    //  if (this.claimDetailForm.dirty) {
    //    this.storageService.store("claimsData", this.claimDetailForm.value);
    //  }
    //  return true;
    //}
    //  let hasPendingChanges: boolean = !this.claimDetailForm.dirty;
    //  return hasPendingChanges;

    this.saveMethod = this.onSubmit;
    let hasPendingChanges: boolean = !this.claimDetailForm.touched;
    return hasPendingChanges;
  }
 // private nonMMIPaymentData: IPayment[];
  isPaymentAvailable: boolean = false;
  isMMIUser: boolean = false;
  canReadOnly: boolean = false;
  canEdit: boolean = false;
  canAdd: boolean = false;
  canDelete: boolean = false;
  mmiClaimHandlerName: any[];
  isFinanceUser: boolean = false;

  //message = 'Hola Mundo!';
  constructor(private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private lookupService: LookupService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private claimDetailService: ClaimDetailService,
    private paymentDetailService: PaymentDetailService,
    private toastr: ToastrService,
    private notificationService: NotificationService,
    securityService: SecurityService,
    private storageService: StorageService,
    private apportionmentService: ApportionmentService,
    private valuationService: ValuationService,
    @Inject(ClaimComponent) private parent: ClaimComponent) {
    super(securityService, 1140);
    this.dtTrigger = new Subject();

    this.createForm();
    this.loadLookups();
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: false,
    selectAllText: "All",
    itemsShowLimit: 1,
    allowSearchFilter: true,
    maxHeight: 150,
  };

  ngOnInit() {
    //this.FistSelected = [];
    if (this.securityService.getUserData() != null) {
      let userDetail = this.securityService.getUserData();
      this.isMMIUser = userDetail.organisationId == 1 ? true : false;
      this.isFinanceUser = userDetail.userRoleId == enumUserRole.Finance;
      this.isNonMMIFinanceUser = (userDetail.userRoleId == enumUserRole.Finance && userDetail.organisationId != 1) ? true : false
      this.isMMIUserWithClaimSuperUserRole = ((userDetail.organisationId == 1) && (userDetail.superUser) && (userDetail.userRoleId == enumUserRole.Claims));
      this.isMMIUserWithClaimsAndFinanceSuperUserRole = ((userDetail.organisationId == 1) && (userDetail.superUser) && (userDetail.userRoleId == enumUserRole.ClaimsAndFinance));
    }
    this.hideSaveButton = true;
    this.hideReopenClaimButton = true;
    this.hideSettleClaimButton = true;
    //alert(this.claimDetailForm.value.totalMMIReserve);
    this.bindAdditionalPolicyPermission();
    this.bindHandlingOrganisation();
    this.bindMMIClaimHandlers(enumHandlingOrganization.MMI);
    this.showAbuseType = true;
    this.currentTab = "tabBasicDetails";

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      dom: 'Blfrtip',
      buttons: [],
      columnDefs: [
        { type: 'date-uk', "targets": 4 },
        { type: 'date-uk', "targets": 5 },
        { "orderable": false, "targets": 6 }
      ]
    };

    this.bindClaimData();
    //this.setClaimAggregatedBasedOnLocationAndPerpetrator();

  }

  bindAdditionalPolicyPermission() {
    let userPermissionID: number = 1141;
    var userDetails = this.securityService.getUserData().userRolePermissionModels;
    var data = userDetails.filter(c => c.userPermissionsId == userPermissionID);
    this.permissionId = data[0].permission;
    if (this.permissionId == enumPermissions.Read) {
      this.canReadOnly = true;
    } else if (this.permissionId == enumPermissions.Edit) {
      this.canEdit = true;
    }
    else if (this.permissionId == enumPermissions.Add) {
      this.canAdd = true;
    }
    else if (this.permissionId == enumPermissions.Delete) {
      this.canDelete = true;
    }
  }

  bindClaimData() {
    let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimID = claimId;
    this.policyId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('policyId'));
    if (claimId > 0) {
      this.isNewClaim = false;
      this.hideSaveButton = false;
      this.backButton = true;
      this.hideWorkflowButton = (this.isMMIUser) ? false : true
      this.claimDetailService.getPolicyById(this.policyId).subscribe(res => {
        this.bindCreditors(res.claimLevelSchemeCreditors);
        //this.lineofBusinessLookup = res.policyLineOfBusiness;
        this.loadLOB(res.policyLineOfBusiness);
      });
      // CR-18 Merge Request Changes
      //if (this.storageService.retrieve("claimsData") != null) {
      //  var claimsLocalStorageData = this.storageService.retrieve("claimsData");
      //  this.claimDetailForm.setValue(claimsLocalStorageData);
      //  this.bindClaimDetailForm(claimsLocalStorageData);
      //} else {
      this.claimDetailService.getClaimById(claimId).then(res => {
        try {
          this.claimDetailForm.setValue(res);
          this.bindClaimDetailForm(res);
          this.bindClaimLocations(res.claimLocations, res.location);
          this.bindClaimPerpetrators(res.claimPerpetrators, res.allegedPerpetrator);
          this.showHideSettleOrReopenButton(res.claimStatus);
          //alert(this.claimDetailForm.controls['totalMMIReserve'].value);
          //this.claimDetailForm.controls["claimStatus"].disable();
          this.bindClaimRiskType(res.claimRiskType, res.riskType);
          this.bindClaimAbuserType(res.claimAbuserType, res.abuserType);
          this.bindClaimAllegations(res.claimAllegation, res.allegations);
        }
        catch (Error) {
          console.log(Error.message);
        }
      });
      //}
    }
    else {
      this.isNewClaim = true;

      this.claimDetailForm.controls['claimStatus'].setValue(23);

      this.claimDetailService.getPolicyById(this.policyId).subscribe(res => {
        this.claimDetailForm.controls['regionCode'].setValue(res.regionCode);
        this.claimDetailForm.controls['insuredTitle'].setValue(res.insuredTitle);
        this.claimDetailForm.controls['policyId'].setValue(res.policyId);
        this.claimDetailForm.controls['primaryPolicyNumber'].setValue(res.policyNumber);
        this.claimDetailForm.controls['policyType'].setValue(res.policyType);
        this.claimDetailForm.controls['policyTypeName'].setValue(res.policyTypeName);
        this.claimDetailForm.controls['coverFrom'].setValue(res.coverFrom);
        this.claimDetailForm.controls['coverTo'].setValue(res.coverTo);

        this.additionalPolicyDetailsList = [];
        //this.lineofBusinessLookup = res.policyLineOfBusiness;
        this.loadLOB(res.policyLineOfBusiness);
        this.bindCreditors(res.claimLevelSchemeCreditors);
        this.bindClaimLocations([], null);
        this.bindClaimPerpetrators([], null);
        this.hideReopenClaimButton = true;
        this.hideSettleClaimButton = true;
        this.bindClaimRiskType([], null);
        this.bindClaimAbuserType([], null);
        this.bindClaimAllegations([], null);
      });
    }
  }

  bindCreditors(data) {
    this.claimLevelSchemeCreditors = [];

    data.forEach(a => {
      
      let sc = _.filter(this.claimLevelSchemeCreditors, function (o) { return o.schemeCreditorId == a.schemeCreditorId; });

      //if (sc == null || sc.length == 0) {
      //  this.claimLevelSchemeCreditors.push(new claimCreditorModel(a.schemeCreditorId, a.creditorName, a.creditorStatus));
      //}      

      //if (a.aggregatedCreditorID != null && a.aggregatedCreditorID > 0) {
      //  let ac = _.filter(this.claimLevelSchemeCreditors, function (o) { return o.schemeCreditorId == a.aggregatedCreditorID; });

      //  if (ac == null || ac.length == 0) {
      //    this.claimLevelSchemeCreditors.push(new claimCreditorModel(a.aggregatedCreditorID, a.aggregatedCreditorName, a.aggregatedCreditorStatus, a.aggregatedCreditorNo));
      //  }
      //}
      //else {
      this.claimLevelSchemeCreditors.push(new claimCreditorModel(a.schemeCreditorId, a.creditorName, a.creditorStatus, a.creditorNo, a.aggregatedCreditorName, a.aggregatedCreditorNo, a.aggregatedCreditorStatus));
      //}
    });
  }

  get f() { return this.claimDetailForm.controls; }
  get cdf() { return this.claimDetailForm.controls.claimantDetail; }

  createForm() {
    this.policyForm = this.formBuilder.group({
      apId: [0],
      apPolicyId: [''],
      apPolicyNumber: [''],
      apPolicyType: [''],
      apInsuredTitle: [''],
      apCoverFrom: [''],
      apCoverTo: [''],
      apCreditorName: [''],
      isDelete: [false]
    });

    this.claimDetailForm = this.formBuilder.group({
      claimDetailId: [0],
      policyId: [''],
      updatedByUserName: [''],
      updatedDate: [null],
      isDelete: [false],
      migratedClaim: [''],
      mmiClaimsReference: [''],
      regionCode: [0],
      insuredTitle: [''],
      claimantName: [''],
      primaryPolicyNumber: [0],
      policyType: [0],
      policyTypeName: [''],
      linkedReferences: [''],
      claimTypeId: [0, Validators.required],
      lineOfBusiness: [0, Validators.required],
      coverFrom: [null],
      coverTo: [null],

      isApportionmentAvailble: [false],
      schemeCreditorId: [0, Validators.required],
      schemeCreditorName: [''],
      schemeCreditorStatus: [''],
      schemeCreditorReference: [''],
      schemeCreditorHandler: [''],
      schemeCreditorEmail: [''],
      letterOfClaimDate: [null],
      claimStatus: [23, Validators.required],
      oldClaimStatus: [0],
      notificationDate: [null, Validators.required],
      settledDate: [null],
      openDate: [null],
      datelastReOpened: [null],

      handlingOrganisationId: [0, Validators.required],
      handlingStatusText: [''],
      handlingStatusId: [0],
      chargingBasis: [0, Validators.required],
      claimReference: [''],
      claimsHandlerId: [null],
      delegatedAuthority: [0, Validators.required],

      ri: ['Yes'],
      nonRIrecoveries: [0, Validators.required],
      nonRIRecoveriesStage: [0],
      riskType: [0],
      abuserType: [0],
      aggregatedClaim: [0],
      aggregationLevel: [0],
      location: [0],
      allegedPerpetrator: [0],
      abuseCode: [0],
      allegations: [0],

      jurisdiction: [0, Validators.required],

      mmiKeyContact: [null],
      claimLocations: [],
      claimPerpetrators: [],

      settlementReason1: [0],
      settlementReason2: [0],
      settlementReason3: [0],
      isSettledInPierian: [''],
      claimLevelSchemeCreditors: [''],
      additionalPolicyDetailsList: [''],
      totalMMIPaid: [0],
      totalMMIReserve: [0],
      totalMMIIncurred: [0],
      totalMMIValuation: [0],
      eFileURL: [''],
      claimRiskType: [],
      claimAbuserType: [],
      claimAllegation: [],
      handlingOrganisationName: '',
      litigated: [false],
      isLitigationServiceDateAvailble: [false],
      version: '',
      isNonApprovedOrRejectedPaymentAvailble: [false],
      settlementReason: [0],
      valuationScreenCorrect: [0],
      hoeFileUploaded: [0],
      provisionalDamagesSettlement: [0],
      claimantDetail: this.formBuilder.group({
        claimantDetailId: [0],
        title: [0],
        firstName: ['', Validators.required],
        surname: ['', Validators.required],
        dateofBirth: [null],
        gender: [0],
        occupation: [0],
        nationalInsuranceNumber: [''],

        addressFirstLine: [''],
        addressSecondLine: [''],
        town: [''],
        county: [''],
        postCode: [''],

        deceased: [null],
        dateofDeath: [null],
        dateofDiagnosis: [null],
        claimantSolicitor: [null],
        claimantSolicitorRef: [''],
        claimantFullMatch: [''],
        claimantPartialMatch: [''],
        isClaimantMatchReviewed: [false],
      })
    });
  }

  loadLookups() {
    this.genderLookup = this.lookupService.getLookupsByTypeId(27);
    this.claimTypeLookup = this.lookupService.getLookupsByTypeId(1);
    this.claimStatusLookup = this.lookupService.getLookupsByTypeId(3);
    this.titleTypeLookup = this.lookupService.getLookupsByTypeId(28);
    this.occupationTypeLookup = this.lookupService.getLookupsByTypeId(18);
    this.chargingBasisTypeLookup = this.lookupService.getLookupsByTypeId(19);
    this.riskTypeLookup = this.lookupService.getLookupsByTypeId(5).sort(this.sortByName);
    this.abuserTypeLookup = this.lookupService.getLookupsByTypeId(6).sort(this.sortByName);
    this.aggregationLevelLookup = this.lookupService.getLookupsByTypeId(21);
    this.locationLookup = this.lookupService.getLookupsByTypeId(22);
    this.allegedPerpetratorLookup = this.lookupService.getLookupsByTypeId(29);
    this.abuseCodeLookup = this.lookupService.getLookupsByTypeId(20);
    this.allegationsLookup = this.lookupService.getLookupsByTypeId(7);
    this.settlementReason1 = this.lookupService.getLookupsByTypeId(24);
    this.settlementReason2 = this.lookupService.getLookupsByTypeId(25);
    this.settlementReason3 = this.lookupService.getLookupsByTypeId(26);
    this.jurisdictionLookup = this.lookupService.getLookupsByTypeId(23);
    this.ClaimantSolicitorsLookup = this.lookupService.getLookupsByTypeId(44);
    this.delegatedAuthorityLookup = this.lookupService.getLookupsByTypeId(30);
    this.deceasedkeys = this.lookupService.getLookupsByTypeId(32);
    // this.deceasedkeys = Object.values(this.deceasedEnum).filter(Number)
    this.agregatedClaimkeys = Object.values(this.agregatedClaimEnum).filter(Number.isFinite);
    this.yesNoLookup = this.lookupService.getLookupsByTypeId(31);
    //this.lineofBusinessLookup = this.lookupService.getLookupsByTypeId(12);
    this.loadLOB(null);
    this.handlingStatusLookup = this.lookupService.getLookupsByTypeId(35);
    this.settlementReasonsLookup = this.lookupService.getLookupsByTypeId(62);
    this.nonRIRecoveriesStageLookup = this.lookupService.getLookupsByTypeId(63);
    if (this.securityService.getUserData().organisationId == 1) {
      this.yesNoAndNALookup = this.lookupService.getLookupsByTypeId(64);
    }
    else {
      this.yesNoAndNALookup = this.lookupService.getLookupsByTypeId(64).filter(({ id }) => id != 1558);
    }
    
  }

  sortByName(a, b) {
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();
    return (x !== y ? x < y ? -1 : 1 : 0)
  }

  bindHandlingOrganisation() {
    this.claimDetailService.getHandlingOrganization().subscribe(
      result => {
        
        this.handlingOrganisation = result;
      },
      error => {
        console.log('this is error:' + error);
      });
  }

  bindClaimHandlers(handlingOrganisationId) {
    this.claimDetailService.getClaimHandlers(handlingOrganisationId).subscribe(
      result => {
        this.claimHandlerName = result;
        if (handlingOrganisationId == this.oldOrganizationID) {
          this.claimDetailForm.controls['claimsHandlerId'].setValue(this.oldClaimHandlerID);
        }
        else {
          this.claimDetailForm.controls['claimsHandlerId'].setValue(null);
        }
      },
      error => {
        console.log('this is error:' + error);
      });
  }

  bindMMIClaimHandlers(handlingOrganisationId) {
    this.claimDetailService.getClaimHandlers(handlingOrganisationId).subscribe(
      result => {
        this.mmiClaimHandlerName = result;
      },
      error => {
        console.log('this is error:' + error);
      });
  }

  changeHandlingOrganisation(e) {
    let orgId = this.claimDetailForm.controls['handlingOrganisationId'].value;
    this.bindClaimHandlers(orgId);
    if (orgId == enumHandlingOrganization.MMI || orgId == enumHandlingOrganization.Zurich) {
      this.claimDetailForm.controls['chargingBasis'].setValidators(null);

      this.claimDetailForm.controls['chargingBasis'].setValue(enumChargingStatus.NA);
    }
    else {
      this.claimDetailForm.controls['chargingBasis'].setValidators([Validators.required]);
    }

    this.chargingBasisError = "";
    this.aggregatedClaimError = "";
  }

  changeClaimType() {

    let id = this.claimDetailForm.controls['claimTypeId'].value;

    //Abuse and Social Care Claims section will only be displayed if claim type = "Abuse" / "Social care - Failure to Remove" / "Social Care - Other"
    if (id == claimType.Abuse || id == claimType.SocialCareFailureToRemove || id == claimType.SocialCareOther) {
      this.showAbuseType = true;
    }
    else {
      this.showAbuseType = false;
    }

    if (id == claimType.Mesothelioma) {
      this.claimDetailForm.controls['ri'].setValue('Yes');
    }
    else {
      this.claimDetailForm.controls['ri'].setValue('No');
    }

    if (id != this.oldClaimTypeId) {
      this.claimDetailForm.get('claimantDetail.isClaimantMatchReviewed').patchValue(false);
    }
  }

  changeAggregationLevel() {
    this.aggregatedLavelError = "";

    //if (this.claimDetailForm.value.aggregationLevel == enumAggregationLevel.Perpetrator) {
    //  this.claimDetailForm.controls['location'].disable();
    //  this.claimDetailForm.controls['allegedPerpetrator'].enable();
    //}
    //else if (this.claimDetailForm.value.aggregationLevel == enumAggregationLevel.Location) {
    //  this.claimDetailForm.controls['location'].enable();
    //  this.claimDetailForm.controls['allegedPerpetrator'].disable();
    //}

    this.locationError = "";
    this.allegedPerpetratorError = "";
  }

  //changeSchemeCreditor() {
  //  let id = this.claimDetailForm.controls['schemeCreditorId'].value;
  //  this.creditor = _.filter(this.claimLevelSchemeCreditors, function (o) { return o.schemeCreditorId == id; });

  //  if (this.creditor != null && this.creditor.length > 0) {
  //    this.claimDetailForm.controls['schemeCreditorName'].setValue(this.creditor[0].creditorName);
  //    this.claimDetailForm.controls['schemeCreditorStatus'].setValue(this.creditor[0].creditorStatus);
  //    this.creditorNo = this.creditor[0].creditorNo
  //    this.CreditorId = id;
  //    this.aggregatedPolicyholderID = this.creditor[0].aggregatedCreditorNo;
  //    this.aggregatedPolicyholderName = this.creditor[0].aggregatedCreditorName;
  //    this.aggregatedPolicyholderStatus = this.creditor[0].aggregatedCreditorStatus;

  //  }
  //  else {
  //    this.claimDetailForm.controls['schemeCreditorName'].setValue('');
  //    this.claimDetailForm.controls['schemeCreditorStatus'].setValue('');

  //    this.CreditorId = '';
  //  }

  //  //txtCreditorId
  //  this.updateHeaderPanel.emit(this.claimDetailForm);
  //}
  changeSchemeCreditor(item: any) {
    if (item.type === "load") {
      this.isOnload = true;
      this.selectedItemOnLoad = this.claimDetailForm.controls['schemeCreditorId'].value;
    }
    else if (item.type === "change") {
      this.isOnload = false;
      if (this.selectedItemOnLoad == this.claimDetailForm.controls['schemeCreditorId'].value) {
        this.isOnload = true;
      }
    }
    let id = this.claimDetailForm.controls['schemeCreditorId'].value;
    //this.FistSelected.push({ item: id, index: this.policyHolderId++ });
    this.creditor = _.filter(this.claimLevelSchemeCreditors, function (o) { return o.schemeCreditorId == id; });

    if (this.creditor != null && this.creditor.length > 0) {
      this.claimDetailForm.controls['schemeCreditorName'].setValue(this.creditor[0].creditorName);
      this.claimDetailForm.controls['schemeCreditorStatus'].setValue(this.creditor[0].creditorStatus);
      this.creditorNo = this.creditor[0].creditorNo
      this.CreditorId = id;
      this.aggregatedPolicyholderID = this.creditor[0].aggregatedCreditorNo;
      this.aggregatedPolicyholderName = this.creditor[0].aggregatedCreditorName;
      this.aggregatedPolicyholderStatus = this.creditor[0].aggregatedCreditorStatus;

    }
    else {
      this.claimDetailForm.controls['schemeCreditorName'].setValue('');
      this.claimDetailForm.controls['schemeCreditorStatus'].setValue('');

      this.CreditorId = '';
    }
    
    if (this.claimLevelSchemeCreditors != null && this.claimLevelSchemeCreditors.length > 1) {
      this.claimDetailForm.controls['additionalPolicyDetailsList'].setValue(this.additionalPolicyDetailsList);
      var claimDetailId = this.claimDetailForm.controls.claimDetailId.value;
      this.paymentDetailService.checkIsPaymentsAvailable(parseInt(claimDetailId)).subscribe(res => {
        
        this.isPaymentAvailable = res;
       // this.paymentDetailService.formData = res;
       // const paymentDetailFetchedData = JSON.parse(JSON.stringify(this.paymentDetailService.formData.paymentDetail));
       // this.nonMMIPaymentData = { ...paymentDetailFetchedData };
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        }
      );
    }
    //txtCreditorId
    this.updateHeaderPanel.emit(this.claimDetailForm);
  }
  changeDeceased() {
    let deceasedId = this.claimDetailForm.value.claimantDetail.deceased;
    if (deceasedId == enumDeceased.No) {
      this.disabelDateofDeath = true;
    }
    else {
      this.disabelDateofDeath = false
    }

    this.deceasedError = "";
    this.dateofDeathError = "";
  }

  dateofDeathChangeCallback() {
    if (this.claimDetailForm.value.claimantDetail.dateofDeath != null) {
      this.claimDetailForm.get('claimantDetail.deceased').patchValue(enumDeceased.Yes);

      this.dateofDeathError = "";
    }
  }

  refresh(): void {
    window.location.reload();
    this.backButton = false;
  }

  searchPolicy(policyNumber: string) {
    if (policyNumber.trim() == "") {
      this.toastr.warning("Please enter policy number");
      return;
    }
    this.claimDetailService.getPolicydata(policyNumber).then(res => {
      
      if (res == null) {
        this.toastr.warning("Policy not found");
      }
      else {
        this.policyForm.setValue(res);
      }

    });
  }

  addPolicy(policyId) {

    let c = _.filter(this.additionalPolicyDetailsList, function (o) { return o.apPolicyId == policyId; });
    if (policyId == this.claimDetailForm.controls['policyId'].value) {
      this.toastr.warning("Primary policy can not be added as additional policy");
      return;
    }

    if (c.length > 0) {
      c[0].isDelete = false;
    }
    else {
      if (this.additionalPolicyDetailsList == null) {
        this.additionalPolicyDetailsList = [];
      }

      if (this.policyForm.controls["apPolicyId"].value > 0) {
        this.additionalPolicyDetailsList.push(this.policyForm.value);
      }
      else {
        this.toastr.warning("Invalid policy");
      }
    }
    this.policyForm.reset();
    this.refreshGrid();
  }

  notDeletedPolicy() {
    let c = _.filter(this.additionalPolicyDetailsList, function (o) { return o.isDelete == false; });
    return c;
  }

  removePolicy(id) {
    let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimDetailService.checkPolicyUsedInApportionment(id, claimId).subscribe(res => {
      if (res) {
        
        this.notificationService.printWarningMessage("You cannot delete an Additional Policy when it is being used in the Apportionment table. If you feel this policy is no longer relevant to the claim, please remove any associated exposure attached to this policy in the Apportionment table and save your changes before removing the policy from this screen.");
        return;
      }
      else {
        let c = _.filter(this.additionalPolicyDetailsList, function (o) { return o.apPolicyId == id; });
        c[0].isDelete = true;
        this.claimDetailForm.markAsTouched();
        this.refreshGrid();
      }
    }, error => {
      this.notificationService.printErrorMessage(error.message);
    }
    );
  }

  resetPolicy(policyId) {
    this.policyForm.reset();

    document.getElementById("txtPolicyNumber").innerHTML = "";
  }

  viewPolicy(policyId) {
    this.router.navigate(['policy-holder/edit/' + policyId.toString()]);
  }

  changeClaimStatus() {
    this.claimStatusError = "";
    let oldCalimStatus = this.claimDetailForm.controls['oldClaimStatus'].value;
    let calimStatus = this.claimDetailForm.controls['claimStatus'].value;

    if (oldCalimStatus == enumClaimStatus.ReOpened || oldCalimStatus == enumClaimStatus.ReSettled) {
      if (calimStatus == enumClaimStatus.Open || calimStatus == enumClaimStatus.Settled) {

        this.toastr.warning("Re-Opened or Re-Settled should not be possible to move those back to Open or Settled.", 'Claim Detail', { positionClass: 'toast-top-right' });

        this.claimDetailForm.controls['claimStatus'].setValue(oldCalimStatus);

        return;
      }
    }

    //this.enableDisableFormControl(calimStatus);

  }

  enableDisableFormControl(calimStatus) {
    if (calimStatus == enumClaimStatus.Settled || calimStatus == enumClaimStatus.ReSettled) {
      this.disableDropDown();
      this.isReadOnly = true;
    }
    else if (this.isReadOnlyMode) {
      this.disableDropDown();
      this.isReadOnly = true;
    }
    else {
      this.enableDropDown();
      this.isReadOnly = false;
    }

    //debugger;
    //As date of death have some additional logic
    if (this.isReadOnly) {
      this.disabelDateofDeath = true;
    }
    else {
      if (this.claimDetailForm.value.claimantDetail.deceased == enumDeceased.No) {
        this.disabelDateofDeath = true;
      }
      else {
        this.disabelDateofDeath = false
      }
    }
  }

  disableDropDown() {
    this.claimDetailForm.controls["claimTypeId"].disable();
    this.claimDetailForm.controls["lineOfBusiness"].disable();
    this.claimDetailForm.controls["schemeCreditorId"].disable();
    this.claimDetailForm.controls["claimStatus"].disable();

    this.claimDetailForm.get("claimantDetail.title").disable();
    this.claimDetailForm.get("claimantDetail.gender").disable();
    this.claimDetailForm.get("claimantDetail.occupation").disable();
    this.claimDetailForm.get("claimantDetail.deceased").disable();
    this.claimDetailForm.get("claimantDetail.claimantSolicitor").disable();
    this.claimDetailForm.get("claimantDetail.isClaimantMatchReviewed").disable();

    this.claimDetailForm.controls["handlingOrganisationId"].disable();
    this.claimDetailForm.controls["chargingBasis"].disable();
    this.claimDetailForm.controls["claimsHandlerId"].disable();
    this.claimDetailForm.controls["delegatedAuthority"].disable();
    this.claimDetailForm.controls["jurisdiction"].disable();

    this.claimDetailForm.controls["nonRIrecoveries"].disable();
    this.claimDetailForm.controls["nonRIRecoveriesStage"].disable();
    this.claimDetailForm.controls["riskType"].disable();
    this.claimDetailForm.controls["abuserType"].disable();
    //this.claimDetailForm.controls["aggregatedClaim"].disable();
    this.claimDetailForm.controls["aggregationLevel"].disable();
    this.claimDetailForm.controls["allegedPerpetrator"].disable();
    this.claimDetailForm.controls["abuseCode"].disable();
    this.claimDetailForm.controls["allegations"].disable();
    this.claimDetailForm.controls["settlementReason1"].disable();
    this.claimDetailForm.controls["settlementReason2"].disable();
    this.claimDetailForm.controls["settlementReason3"].disable();
    //this.claimDetailForm.controls["claimStatus"].disable();
    
    this.claimDetailForm.controls["settlementReason"].disable();
    this.claimDetailForm.controls["valuationScreenCorrect"].disable();
    this.claimDetailForm.controls["hoeFileUploaded"].disable();
    this.claimDetailForm.controls["provisionalDamagesSettlement"].disable();
  }

  enableDropDown() {
    this.claimDetailForm.controls["claimTypeId"].enable();
    this.claimDetailForm.controls["lineOfBusiness"].enable();
    this.claimDetailForm.controls["schemeCreditorId"].enable();
    //this.claimDetailForm.controls["claimStatus"].enable();

    this.claimDetailForm.get("claimantDetail.title").enable();
    this.claimDetailForm.get("claimantDetail.gender").enable();
    this.claimDetailForm.get("claimantDetail.occupation").enable();
    this.claimDetailForm.get("claimantDetail.deceased").enable();
    this.claimDetailForm.get("claimantDetail.claimantSolicitor").enable();
    this.claimDetailForm.get("claimantDetail.isClaimantMatchReviewed").enable();

    this.claimDetailForm.controls["handlingOrganisationId"].enable();
    this.claimDetailForm.controls["chargingBasis"].enable();
    this.claimDetailForm.controls["claimsHandlerId"].enable();
    this.claimDetailForm.controls["delegatedAuthority"].enable();
    this.claimDetailForm.controls["jurisdiction"].enable();

    this.claimDetailForm.controls["nonRIrecoveries"].enable();
    this.claimDetailForm.controls["nonRIRecoveriesStage"].enable();
    this.claimDetailForm.controls["riskType"].enable();
    this.claimDetailForm.controls["abuserType"].enable();
    // this.claimDetailForm.controls["aggregatedClaim"].enable();
    //this.claimDetailForm.controls["aggregationLevel"].enable();
    this.claimDetailForm.controls["allegedPerpetrator"].enable();
    this.claimDetailForm.controls["abuseCode"].enable();
    this.claimDetailForm.controls["allegations"].enable();
    this.claimDetailForm.controls["settlementReason1"].enable();
    this.claimDetailForm.controls["settlementReason2"].enable();
    this.claimDetailForm.controls["settlementReason3"].enable();
    
    this.claimDetailForm.controls["settlementReason"].enable();
    this.claimDetailForm.controls["valuationScreenCorrect"].enable();
    this.claimDetailForm.controls["hoeFileUploaded"].enable();
    this.claimDetailForm.controls["provisionalDamagesSettlement"].enable();

  }

  changeChargingBasis() {
    this.chargingBasisError = "";
  }

  changeMmiKeyContact() {
    this.chargingBasisError = "";
  }

  changeAggregatedClaim() {
    this.aggregatedClaimError = "";
    //if (this.claimDetailForm.controls["aggregatedClaim"].value == enumAgregatedClaim.No) {
    //  this.claimDetailForm.controls["aggregationLevel"].disable();
    //  this.claimDetailForm.controls["aggregationLevel"].setValue(0);
    //}
    //else if (this.isReadOnlyMode) {
    //  this.claimDetailForm.controls["aggregationLevel"].disable();
    //}
    //else {
    //  this.claimDetailForm.controls["aggregationLevel"].enable();
    //}

  }

  changeLocation() {
    this.locationError = "";
  }

  changeAllegedPerpetrator() {
    this.allegedPerpetratorError = "";
  }

  getEFile() {
    if (this.eFileURL != '') {
      this.goToLink(this.eFileURL);
    }
    else {
      if (confirm("Claim folder does not exist for this claim. Do you want to create.")) {
        this.createClaimFolder();
      }
    }
  }

  createClaimFolder() {
    let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimDetailService.createClaimFolder(claimId).subscribe(res => {
      if (res != null) {
        this.eFileURL = res.filepath;
        if (this.eFileURL != '') {
          this.goToLink(this.eFileURL);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

 
  
  SaveFormData() {    
    this.submitted = true;
    this.claimHandlerMandatoryFlag = false;
    if (this.isReadOnly) {
      this.enableDropDown();
    }
    this.claimDetailForm.controls['additionalPolicyDetailsList'].setValue(this.additionalPolicyDetailsList);
    this.validateForm();    

    if (this.claimDetailForm.invalid || !this.isValid) {
      //this.toastr.warning('Page validation error', 'Claim Detail', { positionClass: 'toast-top-right' });
      return;
    }    

    if (this.isNewClaim) {
      this.saveClaimDetailData();
    }
    else {
      this.checkClaimantDetailsUpdated();
    }
  }

  saveClaimDetailData() {
    if (this.isNewClaim) {
      this.claimDetailForm.controls['handlingStatusId'].setValue(enumHandlingStatus.Allocated);
    }
    if (this.claimDetailForm.controls['handlingOrganisationId'].value == enumHandlingOrganization.MMI) {
      this.claimDetailForm.controls['mmiKeyContact'].setValue(null);
    }
    
    if (!this.isNewClaim) {
      if (this.claimDetailForm.value.handlingOrganisationId != 1) {
        var claimtypeDALimit = this.lookupService.getLookupById(this.claimDetailForm.value.claimTypeId)[0].value;
        if (this.claimDetailForm.value.totalMMIValuation > claimtypeDALimit) {
          var delegateAuthority = (this.claimDetailForm.value.delegatedAuthority == enumDelegatedAuthority.Yes) ? enumDelegatedAuthority.No : this.claimDetailForm.value.delegatedAuthority;
          this.claimDetailForm.controls['delegatedAuthority'].setValue(delegateAuthority);
        }
      }
    }

    this.claimDetailForm.controls["litigated"].enable();

    console.log(this.claimDetailForm.value);
    this.claimDetailService.saveOrUpdateClaim(this.claimDetailForm.value).subscribe(res => {
      this.newClaimId = res;
      this.toastr.success("Claim saved", 'Claim Detail', { positionClass: 'toast-top-right' });
      if (this.isNewClaim && this.newClaimId > 0) {
        this.router.navigate(['claims/' + this.policyId.toString() + '/' + this.newClaimId.toString()]);
      }
      else {
        this.bindClaimData();
        this.claimDetailForm.reset(this.claimDetailForm.value);
        this.showWarningMessageForMMIReserveAndValuation();
      }

      // CR-18 Merge Request Changes
      //this.saveOrUpdateApportionmentData();
      //this.saveOrUpdateValuationData();
      this.submitted = false;
      this.isFormSubmitted = true;
      this.formValidityFlagLoaded.next();
      this.claimDetailForm.markAsUntouched();
    },
      error => {
        if (error.error.includes('You are about to save changes to this claim. Another user is also in the claim')) {
          this.notificationService.printHtmlErrorMessage(error.error);
        }
        else {
          this.toastr.error(error.message, 'Claim Detail', { positionClass: 'toast-top-right' });
        }
      }
    );

    if (this.isReadOnly) {
      this.disableDropDown();
    }
  }

  checkClaimantDetailsUpdated() {
    
    let claimTypeId = this.claimDetailForm.controls['claimTypeId'].value;
    let isClaimantMatchReviewed = this.claimDetailForm.controls['claimantDetail'].value.isClaimantMatchReviewed;

    let claimantDetailId = this.claimDetailForm.controls['claimantDetail'].value.claimantDetailId;
    let firstName = this.claimDetailForm.controls['claimantDetail'].value.firstName;
    let surname = this.claimDetailForm.controls['claimantDetail'].value.surname;
    let dateofBirth = this.claimDetailForm.controls['claimantDetail'].value.dateofBirth;
    let nationalInsuranceNumber = this.claimDetailForm.controls['claimantDetail'].value.nationalInsuranceNumber;
    
    if (claimTypeId != this.oldClaimTypeId || firstName != this.oldClaimantFirstName || surname != this.oldClaimantLastName || dateofBirth != this.oldClaimantDateofBirth
      || nationalInsuranceNumber != this.oldClaimantNationalInsuranceNumber)
    {
      if (isClaimantMatchReviewed) {
        // If Claimant Match is Reviewed by user then continue to save Claim Detail Data
        this.bgColorForClaimantMatch = 'transparent';
        this.saveClaimDetailData();
      }
      else {
        let model = { claimTypeId: claimTypeId, claimantDetailId: claimantDetailId, firstName: firstName, surname: surname, dateofBirth: dateofBirth, nationalInsuranceNumber: nationalInsuranceNumber };
        try {
          this.claimDetailService.getMatchingClaimantsData(model).subscribe(response => {
            if (response) {
              if (response.claimantFullMatch.length > 0 || response.claimantPartialMatch.length > 0) {
                this.claimantFullMatchList = response.claimantFullMatch;
                this.claimantPartialMatchList = response.claimantPartialMatch;
                this.notificationService.printHtmlWarningMessage("<p>There is a full or partial match on the Claimant's ID.</br> Please review and leave a comment in the Claims Handling screen. </br> If you consider this a match, please email the MMI key contact.</br> Please select Yes in Claimant Match Reviewed to confirm you have reviewed this full / partial match</p>");
                this.bgColorForClaimantMatch = 'red';
                this.currentTab = "tabClaimantDetails";
                this.changeTab();
              }
              else {
                //If no full or partial match claimant found then continue to save Claim Detail Data
                this.bgColorForClaimantMatch = 'transparent';
                this.saveClaimDetailData();
              }
            }
          },
            error => {
              this.notificationService.printWarningMessage(error.error);
            })
        } catch (Error) {
          this.notificationService.printErrorMessage(Error.message);
        }
        
      }
    }
    else {
      this.bgColorForClaimantMatch = 'transparent';
      this.saveClaimDetailData();
    }

  }

  onSubmit() {
    
    if (!this.isOnload) {
      this.claimDetailForm.controls['additionalPolicyDetailsList'].setValue(this.additionalPolicyDetailsList);
      if (this.claimLevelSchemeCreditors != null && this.claimLevelSchemeCreditors.length > 1) {
        if (!this.isMMIUser) {
          //if (Object.keys(this.nonMMIPaymentData).length > 0) {
          if (this.isPaymentAvailable) {
            this.notificationService.printErrorMessage("You are unable to update the Policyholder on this claim because payments have already been made and associated to the existing Policyholder. Adjustments will therefore be required to MMI's Scheme of Arrangement records. If you feel the Policyholder needs to be amended, please refer to MMI with details of your investigations.");
            return;
          }
          else {
            this.notificationService.printWarningMessage("You are updating the Policyholder, please provide MMI with details of your investigations in order that MMI may confirm the identity of the Policyholder.");
            this.SaveFormData();
          }
        } else {
          this.SaveFormData();
        }
      } else {
        this.SaveFormData();
      }
    } else {
      this.SaveFormData();
    }
  }

  validateForm() {
    this.isValid = true;
    this.isFormSubmitted = true;
    //Basic Detail Error
    let claimStatusId = this.claimDetailForm.controls['claimStatus'].value;
    let oldClaimStatus = this.claimDetailForm.controls['oldClaimStatus'].value;

    this.checkValidity(this.claimDetailForm.get('policyTypeName'), "", "tabBasicDetails");

    //if (oldClaimStatus == claimStatusId && (claimStatusId == enumClaimStatus.Settled || claimStatusId == enumClaimStatus.ReSettled)) {
    //  this.isValid = false;
    //  this.isFormSubmitted = false;
    //  this.formValidityFlagLoaded.next();
    //  this.currentTab = "tabBasicDetails";
    //  this.toastr.warning("Settled or Re-Settled claims can not be updated", 'Claim Detail', { positionClass: 'toast-top-right' });

    //  return;
    //}
    
    //validate dropdowns
    if (this.claimDetailForm.value.claimTypeId == 0 || this.claimDetailForm.value.lineOfBusiness == 0 || this.claimDetailForm.value.claimStatus == 0
      || this.claimDetailForm.value.notificationDate == null || this.claimDetailForm.value.schemeCreditorId == 0) {
      
      this.isValid = false;
      this.isFormSubmitted = false;
      //this.isValidFlag = this.isValid
      this.formValidityFlagLoaded.next();
      this.currentTab = "tabBasicDetails";
      this.toastr.warning("Missing required field values", 'Claim Detail', { positionClass: 'toast-top-right' });
    }

    this.notificationDateError = "";
    var lclaimDate = new Date(this.claimDetailForm.value.letterOfClaimDate);
    var ndate = new Date(this.claimDetailForm.value.notificationDate);
    var opdate = new Date(this.claimDetailForm.value.openDate);

    if (lclaimDate.setHours(0, 0, 0, 0) > ndate.setHours(0, 0, 0, 0)) {
      this.notificationDateError = "Notification Date should be greater than or equal to Letter of Claim Date";
      this.toastr.warning(this.notificationDateError, 'Claim Detail', { positionClass: 'toast-top-right' });
      this.isValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      this.currentTab = "tabBasicDetails";
    }

    this.openDateError = "";
    if (!this.isNewClaim && (lclaimDate > opdate)) {
      this.openDateError = "Open Date should be greater than Letter of Claim Date";
      this.toastr.warning(this.openDateError, 'Claim Detail', { positionClass: 'toast-top-right' });
      this.isValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      this.currentTab = "tabBasicDetails";
    }

    this.claimStatusError = "";

    if (this.claimDetailForm.value.totalMMIReserve >= 250000 && this.claimDetailForm.value.handlingOrganisationId == 1 && this.claimDetailForm.value.claimsHandlerId == null) {
      //this.openDateError = "LLA is now applicable please assign an MMI handler";
      this.toastr.warning("LLA is now applicable please assign an MMI handler", 'Claim Detail', { positionClass: 'toast-top-right' });
      this.isValid = false;
      this.claimHandlerMandatoryFlag = true;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      this.currentTab = "tabAdditionalClaimDetails";
    }
    //if (this.isNewClaim) {
    //  if (claimStatusId != enumClaimStatus.Open) {
    //    this.claimStatusError = "new claim status should must be Open claim";
    //    this.toastr.warning(this.claimStatusError, 'Claim Detail', { positionClass: 'toast-top-right' });
    //    this.isValid = false;
    //    this.isFormSubmitted = false;
    //    this.formValidityFlagLoaded.next();
    //    this.currentTab = "tabBasicDetails";
    //  }
    //}
    //else {
    //if (oldClaimStatus != claimStatusId) {
    //  if (claimStatusId == enumClaimStatus.Settled || claimStatusId == enumClaimStatus.SettledRI) {
    //    if (this.claimDetailForm.value.settlementReason1 == 0 || this.claimDetailForm.value.settlementReason2 == 0) {
    //      this.claimStatusError = "Settlement Reasons 1 & Settlement Reasons 2 are required for Settle claim";
    //      this.toastr.warning(this.claimStatusError, 'Claim Detail', { positionClass: 'toast-top-right' });
    //      this.isValid = false;
    //      this.currentTab = "tabBasicDetails";
    //    }
    //  }

    //  if (oldClaimStatus == enumClaimStatus.Open) {
    //    if (claimStatusId != enumClaimStatus.Settled && claimStatusId != enumClaimStatus.SettledRI) {
    //      this.claimStatusError = "User can only update an Open claim to Settle or Settle-RI";
    //      this.toastr.warning(this.claimStatusError, 'Claim Detail', { positionClass: 'toast-top-right' });
    //      this.isValid = false;
    //      this.currentTab = "tabBasicDetails";
    //    }
    //  }
    //  else if (oldClaimStatus == enumClaimStatus.Settled || oldClaimStatus == enumClaimStatus.SettledRI) {
    //    if (claimStatusId != enumClaimStatus.ReOpened) {
    //      this.claimStatusError = "User can only update a Settled claim to Re-Opened";
    //      this.toastr.warning(this.claimStatusError, 'Claim Detail', { positionClass: 'toast-top-right' });
    //      this.isValid = false;
    //    }
    //  }
    //  else if (oldClaimStatus == enumClaimStatus.ReOpened && claimStatusId != enumClaimStatus.ReSettled) {
    //    this.claimStatusError = "User can only update a Re-opened claim to Re-settled";
    //    this.toastr.warning(this.claimStatusError, 'Claim Detail', { positionClass: 'toast-top-right' });
    //    this.isValid = false;
    //    this.currentTab = "tabBasicDetails";
    //  }

    //  //this.claimDetailForm.value.ddlSettlementReasons1 == 0 || this.claimDetailForm.value.settlementReason2 == 0
    //}//oldClaimStatus != claimStatusId
    //}

    if (!this.isValid) {
      this.changeTab();
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid;

    }

    //Claimant Details
    this.checkValidity(this.claimDetailForm.get('claimantDetail.firstName'), "", "tabClaimantDetails");
    this.checkValidity(this.claimDetailForm.get('claimantDetail.surname'), "", "tabClaimantDetails");
    
    if (this.claimDetailForm.value.claimantDetail.nationalInsuranceNumber != null && this.claimDetailForm.value.claimantDetail.nationalInsuranceNumber != '') {
      if (this.claimDetailForm.value.claimantDetail.nationalInsuranceNumber.length != 9) {
        this.isValid = false;
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        this.currentTab = "tabClaimantDetails";
        this.toastr.warning("National Insurance Number (NINO) should be nine characters long and in correct format. For Ex. TT005849F", 'Claim Detail', { positionClass: 'toast-top-right' });
      }
    }

    //If Desceased = "Yes" then Date of Death should be not null.
    var l = this.claimDetailForm.value.claimantDetail.deceased;

    this.deceasedError = "";
    if (l == enumDeceased.Yes && this.claimDetailForm.value.claimantDetail.dateofDeath == null) {
      this.deceasedError = "If Desceased is yes then Date of Death is mandatory";
      this.toastr.warning(this.deceasedError, 'Claim Detail', { positionClass: 'toast-top-right' });
      this.isValid = false;
      this.formValidityFlagLoaded.next();
      this.currentTab = "tabClaimantDetails";
    }

    this.dateofDeathError = "";
    //If Date of Death = Not Null then Desceased should be "Yes"
    if (l != enumDeceased.Yes && this.claimDetailForm.value.claimantDetail.dateofDeath != null) {
      this.dateofDeathError = "If Date of Death is not empty then Desceased should be yes";
      this.toastr.warning(this.dateofDeathError, 'Claim Detail', { positionClass: 'toast-top-right' });
      this.isValid = false;
      this.formValidityFlagLoaded.next();
      this.currentTab = "tabClaimantDetails";
    }

    if (!this.isValid) {
      this.changeTab();
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid;
    }
    //Additional Claim Details
    if (this.claimDetailForm.value.handlingOrganisationId == 0 //|| this.claimDetailForm.value.claimsHandlerId == 0
      || this.claimDetailForm.value.delegatedAuthority == 0 || this.claimDetailForm.value.jurisdiction == 0 || this.claimDetailForm.value.nonRIrecoveries == 0) {
      this.isValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      this.currentTab = "tabAdditionalClaimDetails";
      this.toastr.warning("Missing required field values", 'Claim Detail', { positionClass: 'toast-top-right' });
    }
    if ((this.claimDetailForm.value.handlingOrganisationId != 0 && this.claimDetailForm.value.handlingOrganisationId != enumHandlingOrganization.MMI
      && this.claimDetailForm.value.handlingOrganisationId != enumHandlingOrganization.Zurich) && this.claimDetailForm.value.chargingBasis == 0) {
      this.isValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      this.currentTab = "tabAdditionalClaimDetails";
      this.chargingBasisError = "If handling organisation is other than MMI or Zurich then hourly rate or fixed fee would be the mandatory";
      this.toastr.warning(this.chargingBasisError, 'Claim Detail', { positionClass: 'toast-top-right' });
    }
    else {
      this.chargingBasisError = "";
    }
    if ((this.claimDetailForm.value.handlingOrganisationId > enumHandlingOrganization.MMI) && (this.claimDetailForm.value.mmiKeyContact == null) && !this.isFinanceUser && this.isMMIUser) {
      this.isValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      this.currentTab = "tabAdditionalClaimDetails";
      this.toastr.warning("If handling organisation is other than MMI, MMI Key Contact would be the mandatory", 'Claim Detail', { positionClass: 'toast-top-right' });
    }
    this.aggregatedClaimError = "";
    this.aggregatedLavelError = "";
    this.locationError = "";
    this.allegedPerpetratorError = "";
    if (this.showAbuseType) {
      //If handling organisation is other than MMI or Zurich then Yes or No would be the mandatory values for NR claims.
      if ((this.claimDetailForm.value.handlingOrganisationId != enumHandlingOrganization.MMI && this.claimDetailForm.value.handlingOrganisationId != enumHandlingOrganization.Zurich && this.claimDetailForm.value.handlingOrganisationId != 0)
        && (this.claimDetailForm.value.aggregatedClaim == null)) {
        this.aggregatedClaimError = "If handling organisation is other than MMI or Zurich then Yes or No would be the mandatory for Aggregated Claim";
        this.toastr.warning(this.aggregatedClaimError, 'Claim Detail', { positionClass: 'toast-top-right' });
        this.isValid = false;
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        this.currentTab = "tabAdditionalClaimDetails";
      }

      if (this.claimDetailForm.value.aggregatedClaim == enumYesNoTBC.Yes && this.claimDetailForm.value.aggregationLevel == 0) {
        this.aggregatedLavelError = "If Agregated Claim = Yes then Aggregation Level should be Not Null";
        this.toastr.warning(this.aggregatedLavelError, 'Claim Detail', { positionClass: 'toast-top-right' });
        this.isValid = false;
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        this.currentTab = "tabAdditionalClaimDetails";
      }

      //If Aggregation Level = "Location" then this field should be enabled and Not Null else disabled with Null value
      if (this.claimDetailForm.value.aggregationLevel == enumAggregationLevel.Location && this.claimDetailForm.value.location == null) {
        this.locationError = "If Aggregation Level = Location then this field should be Not Null";
        this.toastr.warning(this.locationError, 'Claim Detail', { positionClass: 'toast-top-right' });
        this.isValid = false;
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        this.currentTab = "tabAdditionalClaimDetails";
      }

      //If Aggregation Level = "Location" then this field should be enabled and Not Null else disabled with Null value
      if (this.claimDetailForm.value.aggregationLevel == enumAggregationLevel.Perpetrator && this.claimDetailForm.value.allegedPerpetrator == null) {
        this.allegedPerpetratorError = "If Aggregation Level = Perpetrator then this field should be Not Null";
        this.toastr.warning(this.allegedPerpetratorError, 'Claim Detail', { positionClass: 'toast-top-right' });
        this.isValid = false;
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        this.currentTab = "tabAdditionalClaimDetails";
      }      
    }

    if (this.claimDetailForm.value.nonRIrecoveries == 786 && this.claimDetailForm.value.nonRIRecoveriesStage == 0) {
      this.isValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      this.currentTab = "tabAdditionalClaimDetails";
      this.nonRIRecoveriesStageError = "If Non-RI Recoveries value is Yes then Non-RI Recoveries Stage is mandatory";
      this.toastr.warning(this.nonRIRecoveriesStageError, 'Claim Detail', { positionClass: 'toast-top-right' });
    }

    this.changeTab();
  }

  checkValidity(control: any, errorValue: string, tabName: string) {
    if (control.value.trim() == errorValue) {
      this.currentTab = tabName;
    }
  }

  ngAfterViewInit() {
    this.changeTab();

  }

  changeTab() {
    this.claimTabset.select(this.currentTab);
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    if (this.additionalPoliciesTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  //saveOrUpdateApportionmentData() {
  //  if (this.storageService.retrieve("apportionmentData") != null && this.storageService.retrieve("apportionmentData") != undefined) {
  //    var apportionmentData = this.storageService.retrieve("apportionmentData");
  //    var body = {
  //      ...apportionmentData
  //    };
  //    if (apportionmentData.apportionmentPolicies.length > 0) {
  //      if (apportionmentData.id > 0) {
  //        this.apportionmentService.updateApportionment(body).subscribe(res => { },
  //          error => {
  //            this.notificationService.printErrorMessage(error.message);
  //          });
  //      }
  //      else {
  //        this.apportionmentService.addApportionment(body).subscribe(res => { },
  //          error => {
  //            this.notificationService.printErrorMessage(error.message);
  //          });
  //      }
  //    }
  //  }

  //}


  //saveOrUpdateValuationData() {
  //  if (this.storageService.retrieve("valuationData") != null) {
  //    var valuationData = this.storageService.retrieve("valuationData");
  //    var body = {
  //      ...valuationData,
  //      valuationNotes: []
  //    };
  //    if (valuationData.id > 0) {
  //      this.valuationService.updateValuation(body).subscribe(res => { },
  //        error => {
  //          this.notificationService.printErrorMessage(error.message);
  //        });
  //    }
  //    else {
  //      this.valuationService.createValuation(body).subscribe(res => { },
  //        error => {
  //          this.notificationService.printErrorMessage(error.message);
  //        });
  //    }
  //  }

  //}



  bindClaimDetailForm(res) {
    this.oldClaimTypeId = res.claimTypeId;
    this.oldClaimantFirstName = res.claimantDetail.firstName;
    this.oldClaimantLastName = res.claimantDetail.surname;
    this.oldClaimantDateofBirth = res.claimantDetail.dateofBirth;
    this.oldClaimantNationalInsuranceNumber = res.claimantDetail.nationalInsuranceNumber;
    this.claimDetailForm.controls['claimantName'].setValue(res.claimantDetail.firstName + ' ' + res.claimantDetail.surname);
    this.claimDetailForm.controls['oldClaimStatus'].setValue(res.claimStatus);
    this.claimDetailForm.controls['mmiKeyContact'].setValue(res.mmiKeyContact != null ? parseInt(res.mmiKeyContact) : res.mmiKeyContact);
    this.claimantFullMatchList = res.claimantDetail.claimantFullMatch;
    this.claimantPartialMatchList = res.claimantDetail.claimantPartialMatch;
    if ((res.claimantDetail.claimantFullMatch.length > 0 || res.claimantDetail.claimantPartialMatch.length > 0) && res.claimantDetail.isClaimantMatchReviewed == false && this.warningMessageForMatchingClaimantShownCount == 0) {
      this.showWarningMessageForMatchingClaimants = true;
      this.warningMessageForMatchingClaimantShownCount += 1;
    }
    else {
      this.showWarningMessageForMatchingClaimants = false;
    }
    this.eFileURL = res.eFileURL;
    if (res.additionalPolicyDetailsList.length == 0) {
      this.additionalPolicyDetailsList = [];
    } else {
      this.additionalPolicyDetailsList = res.additionalPolicyDetailsList;
    }

    this.CreditorId = res.schemeCreditorId;
    this.changeSchemeCreditor(event);
    this.changeAggregationLevel();
    this.changeClaimType();
    this.refreshGrid();
    this.oldOrganizationID = this.claimDetailForm.controls['handlingOrganisationId'].value;
    this.oldClaimHandlerID = this.claimDetailForm.controls['claimsHandlerId'].value;
    this.bindClaimHandlers(this.claimDetailForm.controls['handlingOrganisationId'].value);
    this.isSettledInPierian = res.isSettledInPierian;
    this.enableDisableFormControl(res.claimStatus);
    if (res.migratedClaim == "Yes") {
      this.showSettlementReason3 = true;
    }
    else {
      this.showSettlementReason3 = false;
    }
    this.changeAggregatedClaim();
    this.isLitigationServiceDateAvailble = res.isLitigationServiceDateAvailble;
    if (this.isLitigationServiceDateAvailble || this.isReadOnly) {
      this.claimDetailForm.controls["litigated"].disable();
    } else {
      this.claimDetailForm.controls["litigated"].enable();
    }
    this.setClaimAggregatedBasedOnLocationAndPerpetrator();
    this.isNonApprovedOrRejectedPaymentAvailble = res.isNonApprovedOrRejectedPaymentAvailble;
    //var aggregatedClaim = res.aggregatedClaim
    //if (aggregatedClaim == 0) {
    //  this.claimDetailForm.controls["aggregationLevel"].patchValue(enumAggregationLevel.NA);
    //}
  }

  changeClaimantName() {
    this.claimDetailForm.controls['claimantName'].setValue(this.claimDetailForm.controls['claimantDetail'].value.firstName + ' ' + this.claimDetailForm.controls['claimantDetail'].value.surname);
    this.updateHeaderPanel.emit(this.claimDetailForm);
    this.onClaimantDataChange();
  }

  onClaimantDataChange() {
    let claimTypeId = this.claimDetailForm.controls['claimTypeId'].value;
    let firstName = this.claimDetailForm.controls['claimantDetail'].value.firstName;
    let surname = this.claimDetailForm.controls['claimantDetail'].value.surname;
    let dateofBirth = this.claimDetailForm.controls['claimantDetail'].value.dateofBirth;
    let nationalInsuranceNumber = this.claimDetailForm.controls['claimantDetail'].value.nationalInsuranceNumber;
    if (firstName != this.oldClaimantFirstName || surname != this.oldClaimantLastName || dateofBirth != this.oldClaimantDateofBirth
      || nationalInsuranceNumber != this.oldClaimantNationalInsuranceNumber) {
      this.claimDetailForm.get('claimantDetail.isClaimantMatchReviewed').patchValue(false);
    }
  }

  onLocationSelect(item: any) {
    this.locationError = "";
    //let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimDetailForm.controls['claimLocations'].setValue(null);
    let locations = (this.claimDetailForm.controls['location'].value).map(x => x.id);
    if (locations.length > 0) {
      let claimLocations = [];
      for (var i = 0; i < locations.length; i++) {
        //claimLocations.push({ claimId: claimId, locationsId: locations[i] });
        claimLocations.push({ locationsId: locations[i] });
      }
      this.claimDetailForm.controls['claimLocations'].setValue(claimLocations);
    }
    else {
      this.claimDetailForm.controls['location'].setValue(null);
    }
    this.setClaimAggregatedBasedOnLocationAndPerpetrator();
  }

  onAllegedPerpetratorSelect(item: any) {
    this.allegedPerpetratorError = "";
    //let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimDetailForm.controls['claimPerpetrators'].setValue(null);
    let perpetrators = (this.claimDetailForm.controls['allegedPerpetrator'].value).map(x => x.id);
    if (perpetrators.length > 0) {
      let claimPerpetrators = [];
      for (var i = 0; i < perpetrators.length; i++) {
        //claimPerpetrators.push({ claimId: claimId, perpetratorsId: perpetrators[i] });
        claimPerpetrators.push({ perpetratorsId: perpetrators[i] });
      }
      this.claimDetailForm.controls['claimPerpetrators'].setValue(claimPerpetrators);
    }
    else {
      this.claimDetailForm.controls['allegedPerpetrator'].setValue(null);
    }
    this.setClaimAggregatedBasedOnLocationAndPerpetrator();
  }

  bindClaimLocations(locationsList, singleLocation) {
    let locations = [];
    let claimLocations = [];
    if (locationsList.length > 0) {
      for (var i = 0; i < locationsList.length; i++) {
        let locationValue = this.locationLookup.filter(x => x.id == locationsList[i].locationsId);
        if (locationValue.length > 0)
          locations.push({ id: locationValue[0].id, text: locationValue[0].text });
      }
    }
    else {
      if (singleLocation != null) {
        let locationValue = this.locationLookup.filter(x => x.id == singleLocation);
        if (locationValue.length > 0) {
          locations.push({ id: locationValue[0].id, text: locationValue[0].text });
          claimLocations.push({ locationsId: locationValue[0].id });
          this.claimDetailForm.controls['claimLocations'].setValue(claimLocations);
        }
      }
    }
    if (locations.length > 0)
      this.claimDetailForm.controls['location'].setValue(locations);
    else {
      this.claimDetailForm.controls['claimLocations'].setValue(null);
      this.claimDetailForm.controls['location'].setValue(null);
    }
  }

  bindClaimPerpetrators(perpetratorsList, singlePerpetrator) {
    let perpetrators = [];
    let claimPerpetrators = [];
    if (perpetratorsList.length > 0) {
      for (var i = 0; i < perpetratorsList.length; i++) {
        let perpetratorValue = this.allegedPerpetratorLookup.filter(x => x.id == perpetratorsList[i].perpetratorsId);
        if (perpetratorValue.length > 0)
          perpetrators.push({ id: perpetratorValue[0].id, text: perpetratorValue[0].text });
      }
    }
    else {
      if (singlePerpetrator != null) {
        let perpetratorValue = this.allegedPerpetratorLookup.filter(x => x.id == singlePerpetrator);
        if (perpetratorValue.length > 0) {
          perpetrators.push({ id: perpetratorValue[0].id, text: perpetratorValue[0].text });
          claimPerpetrators.push({ perpetratorsId: perpetratorValue[0].id });
          this.claimDetailForm.controls['claimPerpetrators'].setValue(claimPerpetrators);
        }
      }
    }
    if (perpetrators.length > 0)
      this.claimDetailForm.controls['allegedPerpetrator'].setValue(perpetrators);
    else {
      this.claimDetailForm.controls['claimPerpetrators'].setValue(null);
      this.claimDetailForm.controls['allegedPerpetrator'].setValue(null);
    }
  }

  showHideSettleOrReopenButton(claimStatusId) {
    this.hideReopenClaimButton = true;
    this.hideSettleClaimButton = true;
    if (!this.canReadOnly) {
      if (!this.isNewClaim)
        //var claimStatusId = this.claimDetailForm.controls['claimStatus'].value;
        if (claimStatusId == enumClaimStatus.Open || claimStatusId == enumClaimStatus.ReOpened) {
          this.hideSettleClaimButton = false;
        }
      if (claimStatusId == enumClaimStatus.Settled || claimStatusId == enumClaimStatus.ReSettled || claimStatusId == enumClaimStatus.SettledRIOS) {
        if (this.isMMIUser) {
          this.hideReopenClaimButton = false;
        }
        this.isReadOnlyMode = true;
        this.isReadOnly = true;
      }
      //if (claimStatusId == enumClaimStatus.SettledRIOS) {
      //  this.isReadOnlyMode = true;
      //  this.isReadOnly = true;
      //}
    }
  }

  validateAndSettleClaim() {
    if (!this.isNonApprovedOrRejectedPaymentAvailble) {
      if (this.claimDetailForm.value.totalMMIReserve != 0) {
        this.notificationService.printWarningMessage("MMI Reserve is not zero, please review and amend the Full Liability Valuations on the Valuation screen accordingly.");
        return;
      }

      let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
      this.valuationService.getValuationExcessApplicable(claimId).subscribe((response: any) => {
        debugger
        let claimExcess = response;
        console.log(claimExcess);        
        let remainingExcess = Number(response.remainingExcess.toFixed(2));
        if (remainingExcess > 0) {
          if (this.isMMIUser && (this.isMMIUserWithClaimSuperUserRole || this.isMMIUserWithClaimsAndFinanceSuperUserRole)) {
            this.settleClaim();
          }
          else {  //For Non MMI User
            this.notificationService.printErrorMessage("You are trying to close this claim.There is a positive excess remaining, please refer the claim to the MMI key contact.");
          }
        }
        else if (remainingExcess < 0) {
          if (this.isMMIUser && (this.isMMIUserWithClaimSuperUserRole || this.isMMIUserWithClaimsAndFinanceSuperUserRole)) {
            this.settleClaim();
          }
          else {  //For Non MMI User
            this.notificationService.printErrorMessage("You are trying to close this claim. There is a negative paid excess, please refer the claim to the MMI key contact.");
          }
        }
        else {
          this.settleClaim();
        }
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        });

    }
    else {
      this.notificationService.printWarningMessage("Unapproved payments are available, firstly approve or reject them and then try to settle.");
    }
  }


  settleClaim() {

    this.isSettledButtonClicked = true;
    if (this.claimDetailForm.value.totalMMIReserve != 0) {
      this.notificationService.printWarningMessage("MMI Reserve is not zero, please review and amend the Full Liability Valuations on the Valuation screen accordingly.");
      return;
    }
    //if ((this.claimDetailForm.value.migratedClaim == "Yes") && (this.claimDetailForm.value.settlementReason1 == 0 || this.claimDetailForm.value.settlementReason2 == 0 || this.claimDetailForm.value.settlementReason3 == 0)) {
    //  this.currentTab = "tabAdditionalClaimDetails";
    //  this.claimStatusError = "Settlement Reasons 1, Settlement Reasons 2 & Settlement Reasons 3  are required for Settle claim";
    //  this.toastr.warning(this.claimStatusError, 'Claim Detail', { positionClass: 'toast-top-right' });
    //  this.changeTab();
    //  return;
    //}
    //if (this.claimDetailForm.value.settlementReason1 == 0 || this.claimDetailForm.value.settlementReason2 == 0) {
    //  this.currentTab = "tabAdditionalClaimDetails";
    //  this.claimStatusError = "Settlement Reasons 1 & Settlement Reasons 2 are required for Settle claim";
    //  this.toastr.warning(this.claimStatusError, 'Claim Detail', { positionClass: 'toast-top-right' });
    //  this.changeTab();
    //  return;
    //}

    if (this.claimDetailForm.value.settlementReason == 0 || this.claimDetailForm.value.valuationScreenCorrect == 0 || this.claimDetailForm.value.hoeFileUploaded == 0 || this.claimDetailForm.value.provisionalDamagesSettlement == 0) {
      this.currentTab = "tabAdditionalClaimDetails";
      this.claimStatusError = "Settlement Reasons, Valuation Screen Correct?,  HO E.File Uploaded? and Provisional Damages Settlement? are required to Settle claim";
      this.toastr.warning(this.claimStatusError, 'Claim Detail', { positionClass: 'toast-top-right' });
      this.changeTab();
      return;
    }

    //}
    let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    let claimStatus;
    this.claimDetailService.checkRIApplicable(claimId).subscribe(
      result => {
        if (result) {
          if (this.showSettleConfirmationPopup()) {
            this.claimDetailForm.controls['claimStatus'].setValue(enumClaimStatus.SettledRIOS);
            this.updateSettleClaimStatus(enumClaimStatus.SettledRIOS);
            this.isSettledButtonClicked = false;
          }
        }
        else {
          if (this.showSettleConfirmationPopup()) {
            if (this.claimDetailForm.controls['claimStatus'].value == enumClaimStatus.Open) {
              this.claimDetailForm.controls['claimStatus'].setValue(enumClaimStatus.Settled);
            }
            else if (this.claimDetailForm.controls['claimStatus'].value == enumClaimStatus.ReOpened) {
              this.claimDetailForm.controls['claimStatus'].setValue(enumClaimStatus.ReSettled);
            }

            this.updateSettleClaimStatus(this.claimDetailForm.controls['claimStatus'].value);
            this.isSettledButtonClicked = false;
          }
        }

      },
      error => {
        console.log('this is error:' + error);
      });
  }  
  

 

  reopenClaim() {
    if (confirm("Do you want to re-open this claim?")) {
      this.enableDropDown();
      this.claimDetailForm.controls['settlementReason1'].setValue(0);
      this.claimDetailForm.controls['settlementReason2'].setValue(0);
      this.claimDetailForm.controls['settlementReason3'].setValue(0);
      this.claimDetailForm.controls["settlementReason"].setValue(0);
      this.claimDetailForm.controls["valuationScreenCorrect"].setValue(0);
      this.claimDetailForm.controls["hoeFileUploaded"].setValue(0);
      this.claimDetailForm.controls["provisionalDamagesSettlement"].setValue(0);
      this.isReadOnly = false;
      this.isReadOnlyMode = false;
      this.claimDetailForm.controls['claimStatus'].setValue(enumClaimStatus.ReOpened);
      this.hideReopenClaimButton = true;
      this.hideSettleClaimButton = false;
      this.parent.isClaimSettled = false;
      this.parent.isClaimSettledRIOS = false;
      
      let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
      var version = this.claimDetailForm.controls.version.value;
      this.claimDetailService.updateClaimStatus(claimId, enumClaimStatus.ReOpened, 0, 0, 0, version).subscribe(
        response => {
          this.claimDetailForm.controls["datelastReOpened"].setValue(response.reopenedDate);
          this.claimDetailForm.controls["version"].patchValue(response.version);
          this.notificationService.printSuccessMessage("Claim has been re-opened successfully.");
          this.claimDetailForm.markAsUntouched();
        },
        error => {
          if (error.error.includes('You are about to save changes to this claim. Another user is also in the claim')) {
            this.notificationService.printHtmlErrorMessage(error.error);
          }
          else {
            this.notificationService.printErrorMessage('something went wrong.');
          }
        }
      );

    }
  }

  showSettleConfirmationPopup(): boolean {
    return (confirm("Do you want to settle this claim?"));
  }

  updateSettleClaimStatus(claimStatusID) {    
    this.validateForm();
    if (this.claimDetailForm.invalid || !this.isValid) {
      //this.toastr.warning('Page validation error', 'Claim Detail', { positionClass: 'toast-top-right' });
      return;
    }
    else {
      this.claimDetailForm.controls["claimStatus"].enable();
      this.claimDetailForm.controls["litigated"].enable();
      this.claimDetailService.saveOrUpdateClaim(this.claimDetailForm.value).subscribe(
        response => {
          if (claimStatusID == enumClaimStatus.SettledRIOS) {
            this.notificationService.printWarningMessage("Reinsurance recoveries are outstanding, please ensure all relevant documents are saved to the Electronic File.");
            this.parent.isClaimSettledRIOS = true;
            this.disableFieldsAndDisplaySettleMessage();
          }
          if (claimStatusID == enumClaimStatus.Settled || claimStatusID == enumClaimStatus.ReSettled) {
            this.parent.isClaimSettled = true;
            this.disableFieldsAndDisplaySettleMessage();
          }
        },
        error => {
          if (error.error.includes('You are about to save changes to this claim. Another user is also in the claim')) {
            this.notificationService.printHtmlErrorMessage(error.error);
          }
          else {
            this.notificationService.printErrorMessage('something went wrong.');
          }
        }
      );
    }
  }

  disableFieldsAndDisplaySettleMessage() {
    this.notificationService.printSuccessMessage("Claim has been settled successfully.");
    this.bindClaimData();
    this.hideSettleClaimButton = true;
    this.hideReopenClaimButton = false;
    this.isReadOnlyMode = true;
    this.isReadOnly = true;
    this.claimDetailForm.reset(this.claimDetailForm.value);
    this.claimDetailForm.controls["claimStatus"].disable();
    this.claimDetailForm.markAsUntouched();
  }

  onRiskTypeSelect(item: any) {
    let riskType = (this.claimDetailForm.controls['riskType'].value).map(x => x.id);
    if (riskType.length > 0) {
      let claimRiskType = [];
      for (var i = 0; i < riskType.length; i++) {
        claimRiskType.push({ riskTypeId: parseInt(riskType[i]) });
      }
      this.claimDetailForm.controls['claimRiskType'].setValue(claimRiskType);
    }
    else {
      this.claimDetailForm.controls['claimRiskType'].setValue(null);
      this.claimDetailForm.controls['riskType'].setValue(null);
    }
  }

  bindClaimRiskType(riskTypeList, singleRiskType) {
    let riskType = [];
    if (riskTypeList.length > 0) {
      for (var i = 0; i < riskTypeList.length; i++) {
        let riskTypeValue = this.riskTypeLookup.filter(x => x.id == riskTypeList[i].riskTypeId);
        if (riskTypeValue.length > 0)
          riskType.push({ id: riskTypeValue[0].id, text: riskTypeValue[0].text });
      }
    }
    else {
      if (singleRiskType != null) {
        let riskTypeValue = this.riskTypeLookup.filter(x => x.id == singleRiskType);
        if (riskTypeValue.length > 0)
          riskType.push({ id: riskTypeValue[0].id, text: riskTypeValue[0].text });
      }
    }
    if (riskType.length > 0)
      this.claimDetailForm.controls['riskType'].setValue(riskType);
    else {
      this.claimDetailForm.controls['claimRiskType'].setValue(null);
      this.claimDetailForm.controls['riskType'].setValue(null);
    }
  }

  onAbuserTypeSelect(item: any) {
    let abuserType = (this.claimDetailForm.controls['abuserType'].value).map(x => x.id);
    if (abuserType.length > 0) {
      let claimAbuserType = [];
      for (var i = 0; i < abuserType.length; i++) {
        claimAbuserType.push({ abuserTypeId: parseInt(abuserType[i]) });
      }
      this.claimDetailForm.controls['claimAbuserType'].setValue(claimAbuserType);
    }
    else {
      this.claimDetailForm.controls['claimAbuserType'].setValue(null);
      this.claimDetailForm.controls['abuserType'].setValue(null);
    }
  }

  bindClaimAbuserType(abuserTypeList, singleAbuserType) {
    let abuserType = [];
    if (abuserTypeList.length > 0) {
      for (var i = 0; i < abuserTypeList.length; i++) {
        let abuserTypeValue = this.abuserTypeLookup.filter(x => x.id == abuserTypeList[i].abuserTypeId);
        if (abuserTypeValue.length > 0)
          abuserType.push({ id: abuserTypeValue[0].id, text: abuserTypeValue[0].text });
      }
    }
    else {
      if (singleAbuserType != null) {
        let abuserTypeValue = this.abuserTypeLookup.filter(x => x.id == singleAbuserType);
        if (abuserTypeValue.length > 0)
          abuserType.push({ id: abuserTypeValue[0].id, text: abuserTypeValue[0].text });
      }
    }
    if (abuserType.length > 0)
      this.claimDetailForm.controls['abuserType'].setValue(abuserType);
    else {
      this.claimDetailForm.controls['claimAbuserType'].setValue(null);
      this.claimDetailForm.controls['abuserType'].setValue(null);
    }
  }

  onAllegationsSelect(item: any) {
    let allegations = (this.claimDetailForm.controls['allegations'].value).map(x => x.id);
    if (allegations.length > 0) {
      let claimAllegation = [];
      for (var i = 0; i < allegations.length; i++) {
        claimAllegation.push({ allegationId: parseInt(allegations[i]) });
      }
      this.claimDetailForm.controls['claimAllegation'].setValue(claimAllegation);
    }
    else {
      this.claimDetailForm.controls['claimAllegation'].setValue(null);
      this.claimDetailForm.controls['allegations'].setValue(null);
    }
  }

  bindClaimAllegations(allegationsList, singleAllegation) {
    let allegations = [];
    if (allegationsList.length > 0) {
      for (var i = 0; i < allegationsList.length; i++) {
        let allegationValue = this.allegationsLookup.filter(x => x.id == allegationsList[i].allegationId);
        if (allegationValue.length > 0)
          allegations.push({ id: allegationValue[0].id, text: allegationValue[0].text });
      }
    }
    else {
      if (singleAllegation != null) {
        let allegationValue = this.allegationsLookup.filter(x => x.id == singleAllegation);
        if (allegationValue.length > 0)
          allegations.push({ id: allegationValue[0].id, text: allegationValue[0].text });
      }
    }
    if (allegations.length > 0)
      this.claimDetailForm.controls['allegations'].setValue(allegations);
    else {
      this.claimDetailForm.controls['claimAllegation'].setValue(null);
      this.claimDetailForm.controls['allegations'].setValue(null);
    }
  }


  navigateToWorkflowComponent() {
    this.router.navigate(['/claims/' + this.policyId + '/' + this.claimID + '/add-workflow-item']);
  }

  setClaimAggregatedBasedOnLocationAndPerpetrator() {
    let isClaimAggregated: boolean = false;
    let claimAggregatedTrueOnLocation: boolean = false;
    let claimAggregatedTrueOnPerpetrators: boolean = false;
    var selectedlocations = this.claimDetailForm.controls['claimLocations'].value;
    if (selectedlocations != null && selectedlocations.length > 0) {
      let locationsIds = (this.claimDetailForm.controls['claimLocations'].value).map(x => x.locationsId);
      for (var i = 0; i < locationsIds.length; i++) {
        var locationId = locationsIds[i];
        let aggregatedLocation = this.locationLookup.filter(x => x.id == locationId && x.booleanValue == true);
        if (aggregatedLocation.length > 0) {
          isClaimAggregated = true;
          claimAggregatedTrueOnLocation = true;
        }
      }
    }
    let selectedPerpetrators = this.claimDetailForm.controls['claimPerpetrators'].value;
    if (selectedPerpetrators != null && selectedPerpetrators.length > 0) {
      let perpetratorsIds = (this.claimDetailForm.controls['claimPerpetrators'].value).map(x => x.perpetratorsId);
      for (var i = 0; i < perpetratorsIds.length; i++) {
        var perpetratorId = perpetratorsIds[i];
        let allegedPerpetrator = this.allegedPerpetratorLookup.filter(x => x.id == perpetratorId && x.booleanValue == true);
        if (allegedPerpetrator.length > 0) {
          isClaimAggregated = true;
          claimAggregatedTrueOnPerpetrators = true;
        }
      }
    }
    if (claimAggregatedTrueOnLocation && claimAggregatedTrueOnPerpetrators) {
      this.claimDetailForm.controls["aggregationLevel"].patchValue(enumAggregationLevel.LocationPerpetrator);
    }
    else if (claimAggregatedTrueOnLocation) {
      this.claimDetailForm.controls["aggregationLevel"].patchValue(enumAggregationLevel.Location);
    } else if (claimAggregatedTrueOnPerpetrators) {
      this.claimDetailForm.controls["aggregationLevel"].patchValue(enumAggregationLevel.Perpetrator);
    } else {
      this.claimDetailForm.controls["aggregationLevel"].patchValue(enumAggregationLevel.NA);
    }
    if (isClaimAggregated) {
      this.claimDetailForm.controls["aggregatedClaim"].patchValue(enumAgregatedClaim.Yes);
    }
    else {
      this.claimDetailForm.controls["aggregatedClaim"].patchValue(enumAgregatedClaim.No);
    }
  }

  get getLocationItems() {
    return this.locationLookup.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  }

  get getPerpetratorItems() {
    return this.allegedPerpetratorLookup.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  }

  showWarningMessageForMMIReserveAndValuation() {
    if (this.claimDetailForm.value.totalMMIReserve >= 250000 && this.reserveLargeLosslimitMessageShownCount == 0) {
      if (this.claimDetailForm.value.handlingOrganisationId == 1) {
        this.notificationService.printWarningMessage("The reserve set has reached the Large Loss limit. Please prepare a large loss advice within 5 working days.");
      }
      else {
        this.notificationService.printWarningMessage("The reserve set has reached the Large Loss limit. Please prepare a large loss advice and send to the MMI key contact within 5 working days.");
      }
      this.reserveLargeLosslimitMessageShownCount += 1;
    }
    else if (this.claimDetailForm.value.totalMMIReserve < 250000) {
      this.reserveLargeLosslimitMessageShownCount = 0;
    }
    if (this.claimDetailForm.value.handlingOrganisationId != 1) {
      var claimtypeDALimit = this.lookupService.getLookupById(this.claimDetailForm.value.claimTypeId)[0].value;
      if (this.claimDetailForm.value.totalMMIValuation > claimtypeDALimit && this.reserveBreachedDALimitMessageShownCount == 0) {
        this.notificationService.printWarningMessage("The reserve has breached the D.A. limit. This claim is now Non-D.A. Please refer the claim under the SLA to the MMI key contact within 3 months. If you consider that this should remain as D.A. please email the MMI key contact.");
        this.reserveBreachedDALimitMessageShownCount += 1;
        this.claimDetailForm.value.delegatedAuthority = (this.claimDetailForm.value.delegatedAuthority == enumDelegatedAuthority.Yes) ? enumDelegatedAuthority.No : this.claimDetailForm.value.delegatedAuthority;
      }
      else if (this.claimDetailForm.value.totalMMIValuation < claimtypeDALimit) {
        this.reserveBreachedDALimitMessageShownCount = 0;
      }
    }
  }

  loadLOB(lob) {
    if (lob != null) {
      let ELandTP = lob.filter(item => { return item.text.toUpperCase() == "EL" || item.text.toUpperCase() == "TP" });
      this.lineofBusinessLookup = lob.filter(item => { return item.text.toUpperCase() != "EL" && item.text.toUpperCase() != "TP" });
      if (ELandTP.length == 2)
        this.lineofBusinessLookup.unshift(ELandTP[0], ELandTP[1]);
      else if (ELandTP.length == 1)
        this.lineofBusinessLookup.unshift(ELandTP[0]);
    } else {
      let ELandTP = this.lookupService.getLookupsByTypeId(12).filter(item => { return item.text.toUpperCase() == "EL" || item.text.toUpperCase() == "TP" });
      this.lineofBusinessLookup = this.lookupService.getLookupsByTypeId(12).filter(item => { return item.text.toUpperCase() != "EL" && item.text.toUpperCase() != "TP" });
      this.lineofBusinessLookup.unshift(ELandTP[0], ELandTP[1]);
    }
  }

  checkMatchingClaimants(content) {
    let isValid: boolean = true;
    if (this.claimDetailForm.value.claimTypeId == 0) {
      isValid = false;
      this.currentTab = "tabBasicDetails";
      this.toastr.warning("Claim Type is required field", 'Claim Detail', { positionClass: 'toast-top-right' });
      this.changeTab();
      return;
    }
    //Claimant Details
    if (this.claimDetailForm.controls['claimantDetail'].value.firstName.trim() == '') {
      isValid = false;
      this.currentTab = "tabClaimantDetails";
      this.toastr.warning("First Name is required field", 'Claimant Detail', { positionClass: 'toast-top-right' });
      this.changeTab();
      return;
    }
    if (this.claimDetailForm.controls['claimantDetail'].value.surname.trim() == '') {
      isValid = false;
      this.currentTab = "tabClaimantDetails";
      this.toastr.warning("Surname is required field", 'Claimant Detail', { positionClass: 'toast-top-right' });
      this.changeTab();
      return;
    } 

    if (isValid) {
      this.hideSaveButton = true;
      let claimTypeId = this.claimDetailForm.controls['claimTypeId'].value;
      let firstName = this.claimDetailForm.controls['claimantDetail'].value.firstName;
      let surname = this.claimDetailForm.controls['claimantDetail'].value.surname;
      let dateofBirth = this.claimDetailForm.controls['claimantDetail'].value.dateofBirth;
      let nationalInsuranceNumber = this.claimDetailForm.controls['claimantDetail'].value.nationalInsuranceNumber;

      let model = { claimTypeId: claimTypeId, firstName: firstName, surname: surname, dateofBirth: dateofBirth, nationalInsuranceNumber: nationalInsuranceNumber };
      try {
        this.claimDetailService.getMatchingClaimantsData(model).subscribe(response => {
          if (response != null) {
            this.fullMatchingClaimantsList = response.claimantFullMatch;
            this.partialMatchingClaimantsList = response.claimantPartialMatch;
            this.modalRef = this.modalService.open(content, { size: 'xl', backdrop: 'static' });
          }
        },
          error => {
            this.notificationService.printWarningMessage(error.error);
          })
      } catch (Error) {
        this.notificationService.printErrorMessage(Error.message);
      }
    }
  }

  proceedToSaveNewClaim() {
    this.hideSaveButton = false;
    this.onSubmit();
  }

  changeNonRIrecoveries() {
    if (this.claimDetailForm.value.nonRIrecoveries != 0) {
      this.nonRIRecoveriesStageError = "";
      this.submitted = false;
    }
  }



}




import { Injectable } from '@angular/core';
import { ReinsuranceService } from '../../../shared/services/reinsurance.service';



@Injectable({
  providedIn: 'root'
})
export class TpCoverService {
 
  constructor(private reinsuranceService: ReinsuranceService) { }

  getTPCoverData(): any {
    return this.reinsuranceService.getTPCoverData();
  }

}

import { Component, OnInit } from '@angular/core';
import { SecurityService } from './shared/services/security.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { LookupService } from './shared/services/lookup.service';
import { LoaderService } from './shared/services/loader.service';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { StorageService } from './shared/services/storage.service';
import { setTimeout } from 'timers';
import { Subject } from "rxjs";
import { Router } from '@angular/router';
import { NavigationService } from './shared/services/navigation.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  settingsLoaded=false;
  isIframe = false;
  loggedIn = false;
  loggedout = false;
  subscription: any;
  message: string;
  //modalRef: NgbModalRef;

  anonymousPages: any[] = ['log-out', 'claim-assignment'];
  constructor(private securityService: SecurityService,
    private configurationService: ConfigurationService,
    private lookupService: LookupService,
    public loaderService: LoaderService,
    private broadcastService: BroadcastService,
    private storageService: StorageService,
    private authService: MsalService,
    private router: Router, private navigationService: NavigationService) {

  }

  ngOnInit() {
    console.log('app on init');
    let that = this;
    if (!this.IsAnonymousPage()) {
    //if (window.location.href.indexOf('log-out') == -1) {
        this.isIframe = window !== window.parent && !window.opener;
        this.loaderService.show();
        this.loggedIn = this.securityService.checkAccount();
        if (!this.loggedIn) {
          this.securityService.login();
        } else {
          this.securityService.accuireToken();
        }
        this.initializeAuth();
    }
    else
    {
        this.loggedout = true;
    }
   
    //Get configuration from server environment variables:
    
    this.subscription = this.securityService.authenticationChallenge$.subscribe(res => {
      if (res) {
        this.loaderService.exclusiveRun = true;
        this.loaderService.show();
        this.loggedout = false;
        console.log('configuration');
        this.configurationService.load();
        this.lookupService.load();
        this.lookupService.settingsLoaded$.subscribe(x => {
          this.settingsLoaded = true;
          this.loaderService.exclusiveRun = false;
          this.loaderService.hide();

        });
      } else {
        this.loaderService.hide();
      }
     
    });
    this.navigationService.getMessage().subscribe(message => {
      this.message = message;
    });
  }

  IsAnonymousPage(): boolean {
    var anonPage = false;
    this.anonymousPages.forEach((Object, index) => {
      if (!(window.location.href.indexOf(Object) == -1)) {
        anonPage = true;
      }      
    });

    return anonPage;
  }

  private initializeAuth() {
    this.broadcastService.subscribe('msal:loginSuccess', () => {
      
      this.loggedIn = this.securityService.checkAccount();
      if (this.loggedIn) {
        this.securityService.accuireToken();
 
      }
    });
    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
      console.log('Redirect Success: ', response.accessToken);
    });
    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));
  }

  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

import { Component, OnInit, Input, ElementRef, AfterViewChecked, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PolicyholderService } from '../policyholder.service'
import { NgForm } from '@angular/forms';
import { ILongTermAgreement } from './long-term-agreement.model';
import { NotificationService } from '../../../../shared/services/notification.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as _ from "lodash";
import { BaseComponentComponent } from '../../../base-component/base-component.component';
import { SecurityService } from '../../../../shared/services/security.service';

@Component({
  selector: 'app-long-term-agreement',
  templateUrl: './long-term-agreement.component.html',
  styleUrls: ['./long-term-agreement.component.css']
})
export class LongTermAgreementComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked {

  @ViewChild(NgForm, { static: false })
  public form: NgForm;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('longTermAgreementTable') longTermAgreementTable: ElementRef;
  dtOptions: DataTables.Settings = {};
  formData: ILongTermAgreement;
  ItemIndex: number;
  @Input() isValid: boolean = true;
  @Input() formPanel: boolean = false;
  isGridLoaded: boolean = false;
  dtTrigger = new Subject();
  tempDate: any[];
  isLTGEdit: boolean = false;
  longTermAgreementLocalData: any[];

  constructor(private policyService: PolicyholderService,
    private notificationService: NotificationService, securityService: SecurityService) {
    super(securityService, 1135);
    this.ItemIndex = null;
   
    this.longTermAgreementLocalData = [];
  }
  
  ngOnInit() {
    this.longTermAgreementLocalData = this.policyService.longTermAgreement;
    this.LoadFrm();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      columnDefs: [
        { type: 'date-uk', "targets": 1 },
        { type: 'date-uk', "targets": 2 },
        { "orderable": false, "targets": 3 }
      ]
    };
  }

  refreshForm() {
    this.longTermAgreementLocalData = this.policyService.longTermAgreement;
    this.refreshGrid();
    this.form.form.markAsPristine();
  }

  LoadFrm(): void {
    this.ItemIndex = null;
    this.isLTGEdit = false;
    this.formData = {
      longTermAgreementId: null,
      policyId: this.policyService.formData.policyId,
      dateFrom: null,
      dateTo: null,
      isClientDeletd: null,
      isDirty: null,
      isNewAdded: null
    }
  }

  notDeletedLongTermAgreement() {
    let c = _.filter(this.longTermAgreementLocalData, function (o) { return o.isClientDeletd == false; });
    return c;
  }

  onEditlongTermAgreementItem(indexItem) {
    this.formData = Object.assign({}, this.longTermAgreementLocalData.filter(a => !a.isClientDeletd)[indexItem]);
    this.ItemIndex = indexItem;
    this.isLTGEdit = true;

  }

  onDeletelongTermAgreementItem(i: number) {
    this.longTermAgreementLocalData.filter(a => !a.isClientDeletd)[i].isClientDeletd = true;
    this.ItemIndex = null;
    this.policyService.longTermAgreement = this.longTermAgreementLocalData;
    this.form.form.markAsDirty();
    this.refreshGrid();
  }

  onSubmit(form: NgForm) {
    if (this.validateForm(form.value)) {
      if (this.ItemIndex == null) {
        form.value.isNewAdded = true;
        form.value.isClientDeletd = false;
        form.value.policyId = this.policyService.formData.policyId;
        this.longTermAgreementLocalData.push(form.value);
      }
      else {
        form.value.isDirty = true;
        this.isLTGEdit = false;
        form.value.policyId = this.policyService.formData.policyId;
        let temp = this.longTermAgreementLocalData.filter(a => !a.isClientDeletd)[this.ItemIndex];
        let index = this.longTermAgreementLocalData.findIndex(a => a == temp);
        this.longTermAgreementLocalData[index] = form.value;

        this.ItemIndex = null;
      }

      this.policyService.longTermAgreement = this.longTermAgreementLocalData;
      this.refreshGrid();
      form.reset();
      this.LoadFrm();
      this.formPanel = false;
    }
  }

  validateForm(formData: ILongTermAgreement) {
    this.isValid = true;

    if (this.policyService.formData.coverFrom == null) {
      this.isValid = false;
      this.policyService.activeTabName = "longTermAgreementsTab";
      this.notificationService.printWarningMessage("Please enter Cover From");
      return false;
    }

    if (this.policyService.formData.coverTo == null) {
      this.isValid = false;
      this.policyService.activeTabName = "longTermAgreementsTab";
      this.notificationService.printWarningMessage("Please enter Cover To");
      return false;
    }

    if (formData.dateFrom == null) {
      this.isValid = false;
      this.policyService.activeTabName = "longTermAgreementsTab";
      return false;
    }

    if (formData.dateTo == null) {
      this.isValid = false;
      return false;
    }

    if (this.getDateOnly(formData.dateFrom) < this.getDateOnly(this.policyService.formData.coverFrom)) {
      this.notificationService.printWarningMessage("Date From should be greater than/equal to Cover From");
      this.policyService.activeTabName = "longTermAgreementsTab";
      this.isValid = false;
      return false;
    }

    if (this.getDateOnly(formData.dateTo) > (this.getDateOnly(this.policyService.formData.coverTo))) {
      this.notificationService.printWarningMessage("Date To should be less than Cover To");
      this.policyService.activeTabName = "longTermAgreementsTab";
      this.isValid = false;
      return false;
    }

    if (this.getDateOnly(formData.dateFrom) >= this.getDateOnly(formData.dateTo)) {
      this.isValid = false;
      this.policyService.activeTabName = "longTermAgreementsTab";
      this.notificationService.printWarningMessage("Date To should be greater than Date From");
      return false;
    }

    if (this.isLTGEdit && this.longTermAgreementLocalData.length == 1) {
      this.isValid = true;
      //this.policyService.activeTabName = "longTermAgreementsTab";
      return true;
    }

    let longTermAgreementGridData;
    let index = this.ItemIndex;

    if (this.isLTGEdit && this.longTermAgreementLocalData.length > 1) {
      longTermAgreementGridData = this.longTermAgreementLocalData.filter(function (place, i) {
        return index != i;
      });
    } else {
      longTermAgreementGridData = this.longTermAgreementLocalData;
    }

    longTermAgreementGridData.forEach(a => {
      if (this.isValid) {
        //if ((this.getDateOnly(new Date(a.dateFrom)) <= this.getDateOnly(formData.dateFrom)
        //  && this.getDateOnly(new Date(a.dateTo)) >= this.getDateOnly(formData.dateFrom) && !a.isClientDeletd)
        //  || (this.getDateOnly(new Date(a.dateFrom)) <= this.getDateOnly(formData.dateTo)
        //    && this.getDateOnly(new Date(a.dateTo)) >= this.getDateOnly(formData.dateTo) && !a.isClientDeletd))

        if ((!(this.getDateOnly(formData.dateFrom) > this.getDateOnly(a.dateTo) ||
          (this.getDateOnly(formData.dateFrom) < this.getDateOnly(a.dateFrom) &&
            this.getDateOnly(formData.dateTo) < this.getDateOnly(a.dateFrom)))) && !a.isClientDeletd) {
          this.notificationService.printWarningMessage("Overlapping dates are not allowed");
          this.policyService.activeTabName = "longTermAgreementsTab";
          this.isValid = false;
        }
      }
    });

    return this.isValid;
  }

  getDateOnly(date: Date) {
    if (typeof date == 'string')
      var newDate = new Date(date);
    else
      var newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  refreshGrid() {
    if (this.longTermAgreementTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}


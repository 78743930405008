import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { CreditorholderService } from './creditorholder.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { ComponentCanDeactivate } from '../../../shared/guard/unsaved-changes.guard';
import { Observable, Subject } from 'rxjs';
import { SecurityService } from '../../../shared/services/security.service';
import { BaseComponentComponent } from '../../base-component/base-component.component';

@Component({
  selector: 'app-creditor',
  templateUrl: './creditor.component.html',
  styleUrls: ['./creditor.component.css']
})
export class CreditorComponent extends BaseComponentComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild(NgForm, { static: false })
  private form: NgForm;
  isValid: boolean = true;
  creditorFound: string;
  statuskeys: any[];
  loading: boolean = false;
  isShowSearchButton: boolean = false;
  // aggregatedPolicyholderValue: any = "No";
  isReadOnlyPermission: boolean = false;
  isAggregatedCreditor: boolean = false;
  fetchedAggregatedCreditorFlag: boolean = false;

  saveMethod: any;
  isFormSubmitted: boolean = true;
  formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean { return false; };
  isSettledButtonClicked: boolean = false;
  eFileURL: string;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    this.saveMethod = this.onSubmit;
    let hasPendingChanges: boolean = !this.form.dirty;
    return hasPendingChanges;
  }

  constructor(private creditorService: CreditorholderService,
    private currentRoute: ActivatedRoute,
    private lookupService: LookupService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private notificationService: NotificationService,
    securityService: SecurityService) {
    super(securityService, 1136);
    this.loadLookups();
  }

  ngOnInit(): void {
    this.checkPermission();
    this.loading = true;
    let id = this.currentRoute.snapshot.paramMap.get('creditorId');
    let isSearch = Boolean(this.activeRoute.snapshot.queryParams.isSearchFlag);
    if (isSearch) {
      this.isShowSearchButton = true;
    }
    else {
      this.isShowSearchButton = false;

    }

    if (id == null)
      this.resetForm();
    else
      this.setLoadData(id);

    this.loading = false;
  }

  checkPermission() {
    this.isReadOnlyPermission = this.permissionId == 1 ? true : false;
  }

  resetForm(form?: NgForm) {
    if (form = null)
      form.resetForm();
    this.creditorService.formData = {
      id: null,
      creditorName: '',
      statusID: null,
      firstLineAddress: '',
      secondLineAddress: '',
      country: '',
      town: '',
      postcode: '',
      aggregatedCreditorFlag: false,
      aggregatedCreditorID: null,
      aggregatedCreditorName: '',
      aggregatedCreditorStatusID: null,
      aggregatedCreditorFirstLineAddress: '',
      aggregatedCreditorSecondLineAddress: '',
      aggregatedCreditorTown: '',
      aggregatedCreditorCountry: '',
      aggregatedCreditorPostcode: '',
      aggregatedCreditorNo: '',
      creditorNo: '',
      showAggregatedCreditorPanel: false,

    };
  }

  loadLookups() {

    this.statuskeys = this.lookupService.getLookupsByTypeId(4);
  }

  setLoadData(creditorId) {
    this.creditorService.getCreditorById(parseInt(creditorId)).subscribe(res => {

      this.creditorService.formData = res;
      this.creditorService.formData.aggregatedCreditorStatusID = this.getSatusById(res.aggregatedCreditorStatusID);
      this.fetchedAggregatedCreditorFlag = this.creditorService.formData.aggregatedCreditorFlag;
      this.isAggregatedCreditor = this.creditorService.formData.aggregatedCreditorFlag;
      this.creditorService.formData.showAggregatedCreditorPanel = this.creditorService.formData.aggregatedCreditorID > 0 ? true : false;
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  getSatusById(statusID): string {
    let statusType = this.statuskeys.find(a => a.id == statusID);
    if (typeof statusType !== 'undefined') {
      return statusType.text;
    }
    return '';
  }

  getCreditorDetail() {
    this.creditorFound = null;
    this.creditorService.getPolicyHolderDetails(this.creditorService.formData.aggregatedCreditorNo).subscribe(res => {
      if (res != null && res.isPolicyholderAssignedToPolicy) {
        let aggregatedPolicyholderName = res.creditorName;
        this.notificationService.printWarningMessage("You cannot aggregate " + aggregatedPolicyholderName + " to another policyholder as " + aggregatedPolicyholderName + " is a policyholder in its own right with associations with policy(ies). You must create a new policyholder record and aggregate both policholders to the new policyholder.");
        this.resetAggregatedPolicyholder();
      }
      else if (res != null && res.aggregatedCreditorFlag == 0) {
        this.notificationService.printWarningMessage("Please search a aggregated policyholder.");
        this.resetAggregatedPolicyholder();
      }
      else if (res != null && res.aggregatedCreditorFlag == 1) {
        this.creditorService.formData.aggregatedCreditorName = res.creditorName
        this.creditorService.formData.aggregatedCreditorID = Number(res.creditorId);
        this.creditorService.formData.aggregatedCreditorStatusID = this.getSatusById(res.statusID);
        this.creditorService.formData.aggregatedCreditorFirstLineAddress = res.firstLineAddress
        this.creditorService.formData.aggregatedCreditorSecondLineAddress = res.secondLineAddress
        this.creditorService.formData.aggregatedCreditorTown = res.town
        this.creditorService.formData.aggregatedCreditorCountry = res.country
        this.creditorService.formData.aggregatedCreditorPostcode = res.postcode
      }
      else {
        this.creditorService.formData.aggregatedCreditorName = ''
        this.creditorService.formData.aggregatedCreditorStatusID = null
        this.creditorService.formData.aggregatedCreditorFirstLineAddress = ''
        this.creditorService.formData.aggregatedCreditorSecondLineAddress = ''
        this.creditorService.formData.aggregatedCreditorTown = ''
        this.creditorService.formData.aggregatedCreditorCountry = ''
        this.creditorService.formData.aggregatedCreditorPostcode = ''
        this.creditorFound = "No Data Found";
      }
    },
      error => this.creditorFound = 'Problem with the service. Please try again after sometime'
    );
  }

  setAggregatedStatus() {
    if (this.creditorService.formData.aggregatedCreditorStatusID != null) {
      let statusType = this.statuskeys.find(a => a.text == this.creditorService.formData.aggregatedCreditorStatusID);
      if (typeof statusType !== 'undefined') {
        this.creditorService.formData.aggregatedCreditorStatusID = statusType.value;
      }
    };
  }

  validateForm() {
    this.isValid = true;
    this.creditorFound = null;
    this.isFormSubmitted = true;
    if (this.creditorService.formData.aggregatedCreditorNo != null && this.creditorService.formData.aggregatedCreditorNo != ''
      && (this.creditorService.formData.aggregatedCreditorID == null || this.creditorService.formData.aggregatedCreditorName == '')) {
      this.creditorFound = 'Please select a valid Policy Holder.';
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }
    if (this.creditorService.formData.aggregatedCreditorID != null && (this.creditorService.formData.aggregatedCreditorName == '' ||
      this.creditorService.formData.aggregatedCreditorName == null)) {
      this.creditorFound = 'Please select a valid Policy Holder';
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }
    if (this.creditorService.formData.creditorName == null || this.creditorService.formData.creditorName.trim().length == 0) {
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }
    if (this.creditorService.formData.statusID == null) {
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }
    return this.isValid;
  }

  onSubmit() {
    try {
      if (this.validateForm()) {
        this.loading = true;
        this.setAggregatedStatus();
        this.creditorService.saveOrUpdatePolicy().subscribe(res => {
          this.notificationService.printSuccessMessage('Saved Successfully. Now you may upload the files using EFILE button');
          this.form.form.markAsPristine();
          let creditorId = this.creditorService.formData.id;
          if (creditorId > 0) {
            this.setLoadData(creditorId);
          }
          if (this.form.submitted)
            this.router.navigate(['/creditor-holder/edit/' + res.id]);

          this.loading = false;
          this.isFormSubmitted = true;
          this.formValidityFlagLoaded.next();
        },
          error => {
            this.notificationService.printErrorMessage(error.message);
            this.loading = false;
          }
        )
      }
    }
    catch (Error) {
      this.notificationService.printErrorMessage(Error.message);
      this.loading = false;
    }
  }
  refresh(): void {
    if (confirm('Are you sure want to reset this page?')) {
      this.creditorFound = null;
      let id = this.currentRoute.snapshot.paramMap.get('creditorId');
      if (id == null)
        this.resetForm();
      else
        this.setLoadData(id);
    }
  }

  navigateToSearch(): void {
    this.router.navigate(['/search-participant']);
  }

  resetAggregatedPolicyholder() {
    this.creditorService.formData.aggregatedCreditorID = null;
    this.creditorService.formData.aggregatedCreditorName = '',
      this.creditorService.formData.aggregatedCreditorStatusID = null,
      this.creditorService.formData.aggregatedCreditorFirstLineAddress = '',
      this.creditorService.formData.aggregatedCreditorSecondLineAddress = '',
      this.creditorService.formData.aggregatedCreditorTown = '',
      this.creditorService.formData.aggregatedCreditorCountry = '',
      this.creditorService.formData.aggregatedCreditorPostcode = '',
      this.creditorService.formData.aggregatedCreditorNo = null;

    this.eFileURL = '';
  }

  changeAggregatedCreditorFlag() {
    if (this.creditorService.formData.id > 0) {
      if (this.creditorService.formData.aggregatedCreditorFlag != null) {
        var selectedAggregatedCreditorFlag = this.creditorService.formData.aggregatedCreditorFlag;
        if (this.fetchedAggregatedCreditorFlag != selectedAggregatedCreditorFlag) {
          if (this.fetchedAggregatedCreditorFlag == true && selectedAggregatedCreditorFlag == false) {
            this.checkPolicyHolderIsParentOfAnotherPolicyHolder();  // Earlier Aggregated Policyholder is Yes
          }
          else if (this.fetchedAggregatedCreditorFlag == false && selectedAggregatedCreditorFlag == true) {
            this.checkPolicyHolderAssignedToAnyPolicy();   // Earlier Aggregated Policyholder is No
          }
        }
        this.notificationService.printWarningMessage('Please save the policy details then try to upload the files using EFILE button.');
      }
    }
    else {
      if (this.creditorService.formData.aggregatedCreditorFlag != null) {
        this.isAggregatedCreditor = this.creditorService.formData.aggregatedCreditorFlag;
        if (this.creditorService.formData.aggregatedCreditorFlag) {
          this.creditorService.formData.showAggregatedCreditorPanel = false;
          this.resetAggregatedPolicyholder();
        }
      }
    }
  }

  checkPolicyHolderIsParentOfAnotherPolicyHolder() {
    // Changing to Aggregated Policyholder No
    this.creditorService.checkPolicyHolderIsParentOfAnother(this.creditorService.formData.id).subscribe(res => {
      if (res != null) {
        let policyholderName = res.creditorName;
        if (res.isParentOfAnotherCreditor) {
          this.notificationService.printWarningMessage(policyholderName + " is associated with other policyholders. Hence Aggregated Policyholder of " + policyholderName + " should not be set to No.");
        }
        else if (!res.isParentOfAnotherCreditor) {
          this.isAggregatedCreditor = false;
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  checkPolicyHolderAssignedToAnyPolicy() {
    // Changing to Aggregated Policyholder Yes
    this.creditorService.checkPolicyHolderIsParentOfAnother(this.creditorService.formData.id).subscribe(res => {
      if (res != null) {
        let policyholderName = res.creditorName;
        if (res.isCreditorAssignedToAnyPolicy) {
          this.notificationService.printWarningMessage(policyholderName + " is assigned on policy. Hence Aggregated Policyholder of " + policyholderName + " should not be set to Yes.");
        }
        else if (!res.isCreditorAssignedToAnyPolicy) {
          this.isAggregatedCreditor = true;
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  getEFile() {


    if (this.eFileURL == '' || this.eFileURL == undefined) {
      this.getCreditorEFileUrl(this.creditorService.formData.aggregatedCreditorID, this.creditorService.formData.aggregatedCreditorNo, this.creditorService.formData.aggregatedCreditorName);

      if (this.eFileURL != '' && this.eFileURL != undefined) {
        this.goToLink(this.eFileURL);
      }
    }
    else if (this.eFileURL != '' && this.eFileURL != undefined) {
      this.goToLink(this.eFileURL);
    }
    else {
      if (this.creditorService.formData.id > 0 && this.creditorService.formData.id != undefined) {
        if (this.validateForm()) {

          if (confirm("Policy holder folder does not exist. Do you want to create.")) {
            this.createCreditorHolderFolder(this.creditorService.formData.id, this.creditorService.formData.creditorNo, this.creditorService.formData.creditorName);
          }
        }
      }
      else if (this.creditorService.formData.aggregatedCreditorID > 0 && this.creditorService.formData.aggregatedCreditorNo != "" && this.creditorService.formData.aggregatedCreditorNo != null) {
        if (confirm("Policy holder folder does not exist. Do you want to create.")) {
          this.createCreditorHolderFolder(this.creditorService.formData.aggregatedCreditorID, this.creditorService.formData.aggregatedCreditorNo, this.creditorService.formData.aggregatedCreditorName);
        }
      }
      else {
        this.notificationService.printWarningMessage("Policyholder ID can not be blank. First save the policy holder details then try to upload the file.");
      }
    }
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  createCreditorHolderFolder(id, CreditorNo, CreditorName): any {
    this.creditorService.createCreditorHolderFolder(id, CreditorNo, CreditorName).subscribe(res => {
      if (res != null) {
        this.eFileURL = res.filepath;
        if (this.eFileURL != '') {
          this.goToLink(this.eFileURL);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  getCreditorEFileUrl(id, CreditorNo, CreditorName): any {
    this.creditorService.getCreditorEFileUrl(id, CreditorNo, CreditorName).subscribe(res => {
      if (res != null) {
        this.eFileURL = res.filepath;
        if (this.eFileURL != '') {
          this.goToLink(this.eFileURL);
        }
        else {
          if (this.creditorService.formData.id > 0 && this.creditorService.formData.id != undefined) {
            if (this.validateForm()) {

              if (confirm("Policy holder folder does not exist. Do you want to create.")) {
                this.createCreditorHolderFolder(this.creditorService.formData.id, this.creditorService.formData.creditorNo, this.creditorService.formData.creditorName);
              }
            }
          }
          else if (this.creditorService.formData.aggregatedCreditorID > 0 && this.creditorService.formData.aggregatedCreditorNo != "" && this.creditorService.formData.aggregatedCreditorNo != null) {
            if (confirm("Policy holder folder does not exist. Do you want to create.")) {
              this.createCreditorHolderFolder(this.creditorService.formData.aggregatedCreditorID, this.creditorService.formData.aggregatedCreditorNo, this.creditorService.formData.aggregatedCreditorName);
            }
          }
          else {
            this.notificationService.printWarningMessage("Policyholder ID can not be blank. First save the policy holder details then try to upload the file.");
          }
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }


}

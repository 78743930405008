import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {
  constructor(private toastr: ToastrService) {
  }
  

  printSuccessMessage(message: string) {
    this.toastr.success(message);
  }

  printErrorMessage(message: string) {
    this.toastr.error(message);
  }
  printWarningMessage(message: string) {
    this.toastr.warning(message);
  }

  printStickyWarningMessage(message: string) {
    this.toastr.warning(message, '', { timeOut: 0, extendedTimeOut: 0, closeButton: true, tapToDismiss: true, enableHtml: true, }, );
  }
  printHtmlErrorMessage(message: string) {
    this.toastr.error(message, '', { timeOut: 10000, enableHtml: true });
  }

  printHtmlWarningMessage(message: string) {
    this.toastr.warning(message, '', { timeOut: 10000, enableHtml: true });
  }
}

export class LitigationReport {
  handlingOrganisationId: number;
  claimTypes: any[];
  claimHandlersId: any[];
  claimHandlersIdValues: any[];
  claimTypeValues: any[];
  claimStatus: number;
  claimStatusValues : any[];  
  claimantSolicitorIdValues: any[];
  claimantSolicitors: any[];
  avoidable: number;
  issueDate: Date;
  serviceDate: Date;
  trialDate: Date;
  litigationCauses: any[];
  litigationCauseValues: any[];
  trialReviewComplete: number;
  approvedForTrial: number;
  litigated: boolean;
}


export class SearchPolicyVM {
  insuredTitle: string;
  policyType: Number;
  policyNumber: Number;
  policyTypeValue: string;
  coverFrom: Date;
  coverTo: Date;
  creditorName: string;
  creditorStatus: string;
  aggregatedCreditorName: string;
  aggregatedCreditorStatus: string;
  lineOfBusiness: Number;
  regionCode: Number;
  creditorId: string;
  pageIndex: number;
  pageSize: number;
  sortBy?: string;
  sortOrder?: string;
  policyId: Number;

  constructor() {
    this.policyType = 0;
    this.lineOfBusiness = 0;
    this.regionCode = 0;
  }
}

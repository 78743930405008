import { Injectable } from '@angular/core';
import { ReserveHistoryService } from '../../../shared/services/reservehistory.service';
import { DMSService } from '../../../shared/services/dms.service';

@Injectable({
  providedIn: 'root'
})
export class NavMenuService {
  constructor(private dMSService: DMSService) {

  }

  getDMSURL(): any {
    return this.dMSService.getDMSURL();
  }
}

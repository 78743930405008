import { Injectable } from '@angular/core';
import { ReinsuranceService } from '../../../shared/services/reinsurance.service';
import { ITPLayer } from './tp-layer.model';

@Injectable({
  providedIn: 'root'
})
export class TPLayerService {

  formData: ITPLayer[] = [];

  constructor(private reinsuranceService: ReinsuranceService) { }

  getTPLayerDetails() {
    return this.reinsuranceService.getTPLayerDetails();
  }

}

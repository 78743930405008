export enum enumYesNo {
  Yes = 1,
  No = 2

}
export enum enumYesNoUnknown {
  Yes = 1,
  No = 2,
  Unknown = 3
}
export enum enumYesNoUnknownBlank {
  Yes = 1,
  No = 2,
  Unknown = 3,
  Blank = 4
}
export enum enumYesNoUnknownBlankTBC {
  Yes = 1,
  No = 2,
  Unknown = 3,
  Blank = 4,
  TBC = 5
}
export enum enumYesNoUnknownTBC {
  Yes = 1,
  No = 2,
  Unknown = 3,
  TBC = 4
}

export enum enumYesNoTBC {
  Yes = 1,
  No = 0,
  // TBC = 3
}

export enum policyType {
  EL = 1,
  TP = 2,
  RMP = 3
}
export enum lineOfBusiness {
  EL = 1,
  TP = 2,
  ME = 3
}
export enum regionCode {
  a = 1,
  b = 2
}
export enum fscsProtected {
  Yes = 1,
  No = 2,
  Part = 3
}
export enum lookupType {
  ClaimType = 1,
  HandlingOrganisation = 2,
  ClaimStatus = 3,
  CreditorStatus = 4,
  RiskType = 5,
  AbuserType = 6,
  AllegationTypes = 7,
  CapeProducts = 8,
  LitigationCauses = 9,
  PaymentTypes = 10
}
export enum creditorStatus {
  Sover = 1,
  Sound = 2,
  Snon = 3,
  SPRT = 4
}


export enum claimType {
  Abuse = 1,
  Asbestosis = 2,
  CummulativeBack = 3,
  LungCancer = 4,
  Mesothelioma = 5,
  NIHL = 6,
  Other = 14,
  PleuralPlaques = 7,
  PleuralThickening = 8,
  RSI = 9,
  Silicosos = 10,
  SocialCareFailureToRemove = 11,
  SocialCareOther = 12,
  VWF = 13,
}

export enum enumAgregatedClaim {
  Yes = 1,
  No = 0
  // TBC = 3
}

export enum enumAggregationLevel {
  Location = 406,
  Perpetrator = 407,
  LocationPerpetrator = 1495,
  NA = 1496
}

export enum enumDeceased {
  Yes = 791,
  No = 792
}

export enum enumClaimStatus {
  Open = 23,
  ReOpened = 25,
  ReSettled = 26,
  Settled = 24,
  SettledRI = 905,
  SettledRIOS = 1318
}

export enum enumHandlingOrganization {
  MMI = 1,
  Zurich = 2
}

export enum enumHandlingStatus {
  Allocated = 953,
  Accepted = 954,
  Rejected = 955
}

export enum enumChargingStatus {
  NA = 956,
  Hourlyrate = 404,
  FixedFee = 405
}

export enum enumPaymentRecovery {
  Payment = 973,
  Recovery = 974
}

export enum enumPaymentMethod {
  BACS = 989,
  Cheque = 990
}

export enum enumPaymentType {
  SchemePaymentByCreditor = 975,
  SchemePaymentByMMI = 976,
  AdjustmentEntry = 977,
  ExcessPaymentByCreditor = 978

}

export enum enumPaymentStatus {
  Requested = 991,
  ApprovedStage1 = 992,
  Verified = 993,
  Approved = 994,
  Rejected = 995,
}

export enum enumRecoveryType {
  cancelledCheque = 983
}

export enum enumReportsType {
  newClaims = 1092,
  settledClaims = 1091,
  openClaims = 1090,
}

export enum enumLitigatedType {
  Yes = 1,
  No = 2,
  All = 3,
}

export enum enumPermissions {
  NoAccess = 0,
  Read = 1,
  Add = 2,
  Edit = 3,
  Delete = 4
}

export enum enumApportionmentViewOptions {
  UserDefined = 1,
  FullView = 2,
  SummaryView = 3
}

export enum enumUserRole {
  ClaimsAndFinance = 1048,
  Claims = 1049,
  Finance = 1050,
  NED = 1051,
  Auditor = 1052
}

export enum enumWorkflowPriorityCode {
  P1 = 1471,
  P2 = 1472,
  P3 = 1473,
  P4 = 1474,
  P5 = 1475,
  P3NewClaim = 1476,
  P6 = 1497,
  P7 = 1498

}

export enum enumWorkflowStatus {
  Outstanding = 1478,
  Completed = 1479,
  CompletedStage1=1480
}

export enum enumSLAStatus {
  ToBeCompletedToday = 1,
  OutsideSLA = 2,
  InsideSLA = 3,
  Completed = 4
}

export enum enumWorkFlowCategory {
  NewClaim = 1463,
  ExistingClaim = 1477,
  ChaseForLLA = 1481,
  ConfirmDAStatus = 1488,
  ReviewNonDAReferral = 1489,
  ConfirmLitigationStatus = 1494,
  PrepareLLA = 1503
}

export enum enumDelegatedAuthority {
  Yes = 786,
  No=787
}

export enum enumUnderQuery {
  Yes = 1,
  No = 0
}

export enum enumYesNoAndNotApplicable {
  Yes = 1,
  No = 0,
  NA = 2
}

export enum enumYesAndNo {
  Yes = 1,
  No = 0  
}







import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ElLayerComponent } from './el-layer/el-layer.component';
import { TpLayerComponent } from './tp-layer/tp-layer.component';
import { ElCoverComponent } from './el-cover/el-cover.component';
import { TpCoverComponent } from './tp-cover/tp-cover.component';
import { IndexationComponent } from './indexation/indexation.component';
import { WeeklyWagesComponent } from './weekly-wages/weekly-wages.component';

const routes: Routes = [
  { path: 'el-layer', component: ElLayerComponent },
  { path: 'tp-layer', component: TpLayerComponent },
  { path: 'el-cover', component: ElCoverComponent },
  { path: 'tp-cover', component: TpCoverComponent },
  { path: 'indexation', component: IndexationComponent },
  { path: 'weekly-wages', component: WeeklyWagesComponent },
];

@NgModule({
  //declarations: [],
  //imports: [
  //  CommonModule
  //]

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReinsuranceRoutingModule { }

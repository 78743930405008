import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { NotificationService } from '../../../shared/services/notification.service';
import { ReinsuranceListing } from '../../../models/reinsurance-listing.model';
import { ManageReinsurerService } from '../../../shared/services/manage-reinsurer.service';
import { ReportsService } from '../../../shared/services/reports.service';

@Injectable({
  providedIn: 'root'
})
export class ReinsuranceListingService {

  formDataInput: ReinsuranceListing;

  constructor(
    private notificationService: NotificationService,
    private manageReinsurerService: ManageReinsurerService,
    private reportsService: ReportsService
  ) { }

  getReinsuranceListingReport(): any {
    var body = {
      ...this.formDataInput

    };

    this.reportsService.getReinsuranceListingReport(this.formDataInput).subscribe((response: any) => {
      if (response != null && response.length > 0) {

        var proRataReinsurer = [4, 19, 35, 40, 50, 74];
        const title = 'Reinsurance Listing Report';

        const reinsuranceListingReportHeader = [
          "MMI Claim Reference",
          "Aggregated Claim",
          "Line of Business",
          "Cause",
          "Policyholder",
          "Policyholder ID",
          "Claimant",
          "RI ID",
          "Reinsurer Name",
          "Parent ID",
          "Parent Name",
          "RI Status",
          "MMI Paid",
          "MMI Reserve",
          "MMI Incurred",
          "Claim Status",
          "Date Of Settlement(Claim)",
          "Cover Year",
          "Share Incurred",
          "Layer",
          "Excess",
          "Limit",
          "RI Cover",
          "Pro-Rata Calc",
          "Uplift",
          "Adjusted Pro-Rata",
          "Notification Status"
        ];

        let reinsuranceListingReportData: any[] = [];
        //Create workbook and worksheet
        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Reinsurance Listing Report');

        //Add Row and formatting
        let titleRow = worksheet.addRow([title]);
        titleRow.font = { bold: true, size: 16 };
        titleRow.eachCell((cell, number) => {
          this.fillBackGroundInExcellCell(cell);
          this.setBorderExcelCell(cell);
        });
        worksheet.mergeCells('A1:Z2');
        //Blank Row 
        worksheet.addRow([]);

        response.forEach(item => {
          item["aggregatedClaims"] == "No" ?
            reinsuranceListingReportData.push(
              [
                item["mmiClaimReference"],
                item["aggregatedClaims"],
                item["lineOfBusiness"],
                item["cause"],
                item["policyholderName"],
                item["policyholderID"],
                item["claimantSurname"],
                item["reinsurerId"],
                item["reinsurerName"],
                item["parentReinsurerId"],
                item["parentReinsurerName"],
                item["reinsurerStatus"],
                item["mmiPaid"],
                item["mmiReserve"],
                item["mmiIncurred"],
                item["claimStatus"],
                item["dateOfSettlementClaim"] == null ? "" : moment(item["dateOfSettlementClaim"]).format('DD/MM/YYYY'),
                item["coverYear"],
                item["shareIncurred"],
                item["layer"],
                item["excess"],
                item["limit"],
                item["reinsurerCover"],
                item["proRataCalc"],
                item["uplift"] == null ? "" : item["uplift"],
                item["adjustedProRata"],
                item["nofiticationStatus"],
              ])
            :
            reinsuranceListingReportData.push(
              [
                item["mmiClaimReference"],
                item["aggregatedClaims"],
                item["lineOfBusiness"],
                item["cause"],
                item["policyholderName"],
                item["policyholderID"],
                item["claimantSurname"],
                "",
                "",
                "",
                "",
                "",
                item["mmiPaid"],
                item["mmiReserve"],
                item["mmiIncurred"],
                item["claimStatus"],
                item["dateOfSettlementClaim"] == null ? "" : moment(item["dateOfSettlementClaim"]).format('DD/MM/YYYY'),
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                item["nofiticationStatus"] == null ? "" : item["nofiticationStatus"],
              ]);
        });

        let reinsuranceListingReportHeaderRow = worksheet.addRow(reinsuranceListingReportHeader);
        // Cell Style : Fill and Border
        reinsuranceListingReportHeaderRow.eachCell((cell, number) => {
          this.fillBackGroundInExcellCell(cell);
          this.setBorderExcelCell(cell);

        })
        reinsuranceListingReportHeaderRow.font = { bold: true };
        reinsuranceListingReportData.forEach(d => {
          let row = worksheet.addRow(d);
          row.eachCell((cell, number) => {
            this.setBorderExcelCell(cell);
          })
          this.setCellFormatOfReinsuranceListingReport(row);
        });

        let reinsuranceListingReportFooter =
          [
            '', '', '', '', '', '', '', '', '', '', '', '',
            '', '', '', '', '', '', '', '', '', '',
            'Grand Total',
            _.sumBy(response, (o: any) => { return o.proRataCalc }),
            '',
            _.sumBy(response, (o: any) => { return o.adjustedProRata }),
            ''
          ];

        //Add Footer Row
        let reinsuranceListingReportFooterRow = worksheet.addRow(reinsuranceListingReportFooter);
        reinsuranceListingReportFooterRow.font = { bold: true };
        this.setCellFormatOfReinsuranceListingReport(reinsuranceListingReportFooterRow);
        // Cell Style : Fill and Border
        reinsuranceListingReportFooterRow.eachCell((cell, number) => {
          this.fillBackGroundInExcellCell(cell);
          this.setBorderExcelCell(cell);
        });

        for (var i = 1; i <= worksheet.columnCount; i++) {
          worksheet.getColumn(i).width = 25;
        }

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, 'Reinsurance-Listing-Report_' + Math.random() + '.xlsx');
        })
      }
      else {
        this.notificationService.printWarningMessage("No data is available for the Report");
      }

    })

  }

  setCellFormatOfReinsuranceListingReport(row: ExcelProper.Row) {
    row.findCell(13).numFmt = '£###,###,##0.00;'
    row.findCell(14).numFmt = '£###,###,##0.00;'
    row.findCell(15).numFmt = '£###,###,##0.00;'
    row.findCell(19).numFmt = '£###,###,##0.00;'
    row.findCell(17).numFmt = 'DD/MM/YYYY';
    row.findCell(21).numFmt = '£###,###,##0.00;'
    row.findCell(22).numFmt = '£###,###,##0.00;'
    row.findCell(23).numFmt = '#0.000"%";'
    row.findCell(24).numFmt = '£###,###,##0.00;'
    row.findCell(25).numFmt = '#0"%";'
    row.findCell(26).numFmt = '£###,###,##0.00;'
  }

  setBorderExcelCell(cell: any) {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    cell.alignment = { vertical: 'middle' };
  }

  fillBackGroundInExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00C4C4C4' },
      bgColor: { argb: 'FF0000FF' }
    }
  }

}

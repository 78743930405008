export class CaseloadSummary {
  claimTypes: any[];
  handlingOrganisationId: number;
  //claimHandlersId: number;
  claimHandlersId: any[];
  claimHandlersIdValues: any[];
  claimStatus: any[];
  claimStatusValues: any[];
  aboveHandlingAuthorityId: number;
  abovePaymentAuthorityId: number;  
  claimTypeValues: any[];
  claimTypeNames: any[];
  claimKeyContactId: any[];
  claimKeyContactValues: any[];
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { ReportsService } from '../../../shared/services/reports.service';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service';
import { ClaimHandlerService } from '../../../shared/services/claim-handler.service';
import { enumRecoveryType, enumReportsType, enumLitigatedType } from '../../../shared/models/common.enum';
import { NotificationService } from '../../../shared/services/notification.service';
import { LitigationReport } from '../../../models/litigation-report.model';

@Injectable({
  providedIn: 'root'
})
export class LitigationReportService {
  formDataInput: LitigationReport;

  constructor(private reportsService: ReportsService, private handlingOrganizationService: HandlingOrganisationService,
    private claimHandlerService: ClaimHandlerService, private notificationService: NotificationService) { }



  getHandlingOrganization(): Observable<any> {
    return this.handlingOrganizationService.getHandlingOrganization();
  }

  getClaimHandlersByHandlingOrganisation(organisationId: any) {
    return this.claimHandlerService.getClaimHandlers(organisationId);
  }

  getClaimsLitigationReport(): any {
    var body = {
      ...this.formDataInput

    };

    this.reportsService.getClaimsLitigationReport(this.formDataInput).subscribe((response: any) => {
      if (response != null && response.litigationReportResponseModel != null && response.litigationReportResponseModel.length > 0) {
        let fileName = "";
        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Litigation Report');
        let currentDate = moment(new Date().toDateString()).format('DD/MM/YYYY');

        let handlingOrganisationName = "";
        let claimHandlerName = "";

        let headercolumnName: any;
        let headercolumnNameForCause: any;
        let litigaionReportVolumeTable: any;
        let litigaionReportCauseTable: any;
        let litigaionReportSolicitorTable: any;
        let LitigationPerHandlingOrganisationReport: any;
        litigaionReportSolicitorTable = ["Litigation Volumes (By Claimant Solicitor)"]
        if (this.formDataInput.handlingOrganisationId == 0) {
          headercolumnName = [];
          headercolumnNameForCause = [];
          headercolumnName = ["Handling Organisation", "Claim Type", "Volume", "Litigation Rate(%)"];
          headercolumnNameForCause = ["Handling Organisation", "Claim Type", "Cause", "Volume", "Cause Rate(%)"];
          litigaionReportVolumeTable = ["Litigation Volumes (By Handling Organisation)"];
          litigaionReportCauseTable = ["Litigation Cause (By Handling Organisation)"];
          LitigationPerHandlingOrganisationReport = ["Litigation Per Handling Organisation"];
        }
        else {
          headercolumnName = [];
          headercolumnNameForCause = [];
          headercolumnName = ["Claim Handler", "Claim Type", "Volume", "Litigation Rate(%)"];
          headercolumnNameForCause = ["Claim Handler", "Claim Type", "Cause", "Volume", "Litigation Rate(%)"];
          litigaionReportVolumeTable = ["Litigation Volumes (By Claim Handler)"];
          litigaionReportCauseTable = ["Litigation Cause (By Claim Handler)"];
          LitigationPerHandlingOrganisationReport = ["Litigation Per Handling Organisation"];
        }

        let fileFormat = "";
        if (this.formDataInput.handlingOrganisationId == 0 && this.formDataInput.claimHandlersIdValues.includes(0)) {
          fileFormat = "Litigation Report_Handling Organisation_All_" + currentDate;
        }
        else if (this.formDataInput.handlingOrganisationId != 0 && this.formDataInput.claimHandlersIdValues.includes(0)) {
          handlingOrganisationName = response.litigationReportResponseModel[0].handlingOrganisation;
          fileFormat = "Litigation Report_" + handlingOrganisationName + "_Claim Handlers" + "_All_" + currentDate;

        }

        else {
          fileFormat = "";
          handlingOrganisationName = response.litigationReportResponseModel[0].handlingOrganisation;
          fileFormat = "Litigation Report_" + handlingOrganisationName + "_Claim Handlers_" + currentDate;
        }
        fileName = fileFormat;

        //for (let j = 0; j < response.length; j++) {
        this.generateExcelData(worksheet, "", response.litigationReportResponseModel);
        this.generateExcelLitigationReportVolume(worksheet, "", response.litigationReportVolumeResult, headercolumnName, litigaionReportVolumeTable);
        this.generateExcelLitigationReportCause(worksheet, "", response.litigationReportCauseResult, headercolumnNameForCause, litigaionReportCauseTable);
        this.generateExcelLitigationReportSolicitorVolume(worksheet, "", response.litigationReportVolumeBySolicitorResult, litigaionReportSolicitorTable);
        this.generateExcelLitigationPerHandlingOrganisationReport(worksheet, "", response.litigationPerHandlingOrganisationResult, LitigationPerHandlingOrganisationReport);

        //}


        let date = new Date();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let timestamp = hours + '' + minutes + '' + seconds;

        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fileName + '_' + timestamp + '.xlsx');
        })
      }
      else {
        this.notificationService.printWarningMessage("No data is available for the Report");
      }

    })

  }


  generateExcelData(worksheet: ExcelProper.Worksheet, reportTypeHeader, response: any) {
    let claimHandlerHeader = ["Handling Organisation", "Solicitor on Record", "Claims Handler", "MMI Handler", "Claim Type", "Claimant Name", "MMI Claim Reference", "Claimant Solicitor",
      "Issue Date", "Service Date", "Avoidable", "Litigation Cause", "Trial Date", "Trial Review Complete", "Approved For Trial", "Outcome Code"];

    let reportTableName = ["Litigation Summary"];
    let reportTableNameRow = worksheet.addRow(reportTableName);
    reportTableNameRow.findCell(1).font = { bold: true };


    let exposureHeaderRow;
    let toprow = worksheet.addRow([reportTypeHeader]);
    toprow.findCell(1).font = { bold: true };

    //let claimtypesarray = response.claimsCaseLoadSummaryReportList[0].claimTypes.split(',');
    //if (claimtypesarray.length > 0) {
    //  claimtypesarray.forEach(item => {
    //    claimHandlerHeader.push(item);
    //  });
    //}    
    exposureHeaderRow = worksheet.addRow(claimHandlerHeader);
    response.forEach((item, index) => {
      let data: any[] = [];
      data.push(item.handlingOrganisation);
      data.push(item.solicitorInstructed);
      data.push(item.claimHandler);
      data.push(item.mmiKeyContact);
      data.push(item.claimType);
      data.push(item.claimantName);//added
      data.push(item.mmiClaimReference);
      data.push(item.claimantSolicitor);
      data.push(item.issueDate != null ? (moment(new Date(item.issueDate).toDateString()).format('DD/MM/YYYY')) : item.issueDate);

      data.push(item.serviceDate != null ? (moment(new Date(item.serviceDate).toDateString()).format('DD/MM/YYYY')) : item.serviceDate);

      data.push(item.avoidable);
      data.push(item.litigationCause);
      data.push(item.trialDate != null ? (moment(new Date(item.trialDate).toDateString()).format('DD/MM/YYYY')) : item.trialDate);
      data.push(item.trialReviewComplete);
      data.push(item.approvedForTrial);
      data.push(item.outcomeCode);

      let summaryRow1 = worksheet.addRow(data);
      summaryRow1.eachCell((cell, number) => {
        summaryRow1.findCell(7).alignment = { horizontal: 'center' };
        summaryRow1.findCell(8).alignment = { horizontal: 'center' };
        summaryRow1.findCell(9).alignment = { horizontal: 'center' };
        summaryRow1.findCell(10).alignment = { horizontal: 'center' };
        summaryRow1.findCell(11).alignment = { horizontal: 'center' };
        summaryRow1.findCell(13).alignment = { horizontal: 'center' };
        summaryRow1.findCell(14).alignment = { horizontal: 'center' };
        summaryRow1.findCell(15).alignment = { horizontal: 'center' };
        summaryRow1.findCell(16).alignment = { horizontal: 'center' };
      })
    });

    exposureHeaderRow.font = { bold: true };
    exposureHeaderRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });
    //summaryRowPercentage.font = { bold: true };
    //summaryRowPercentage.eachCell((cell, number) => {
    //  cell.alignment = { horizontal: 'right' }
    //  this.fillBackGroundInExcellCell(cell);
    //  this.setBorderExcelCell(cell);
    //});
    for (var i = 1; i <= worksheet.columnCount; i++) {
      worksheet.getColumn(i).width = 30;
    }

    worksheet.addRow([]);
  }


  generateExcelLitigationReportVolume(worksheet: ExcelProper.Worksheet, reportTypeHeader, response: any, headercolumnName: any, litigaionReportVolumeTable: any) {

    let reportTableNameRow = worksheet.addRow(litigaionReportVolumeTable);
    reportTableNameRow.findCell(1).font = { bold: true };


    let exposureHeaderRow;
    let toprow = worksheet.addRow([reportTypeHeader]);
    toprow.findCell(1).font = { bold: true };

    let totalCount = _.sumBy(response, (o: any) => { return o.volume });

    exposureHeaderRow = worksheet.addRow(headercolumnName);
    if (this.formDataInput.handlingOrganisationId == 0) {
      response.forEach((item, index) => {
        let data: any[] = [];
        data.push(item.handlingOrganisation);
        data.push(item.claimType);
        data.push(item.volume);
        data.push(item.litigationRate.toFixed(2) + "%");

        let summaryRow1 = worksheet.addRow(data);
        summaryRow1.eachCell((cell, number) => {
          summaryRow1.findCell(4).alignment = { horizontal: 'right' };

        })
      });
    }
    else {
      response.forEach((item, index) => {
        let data: any[] = [];
        data.push(item.claimHandler);
        data.push(item.claimType);
        data.push(item.volume);
        data.push(item.litigationRate.toFixed(2) + "%");

        let summaryRow1 = worksheet.addRow(data);
        summaryRow1.eachCell((cell, number) => {
          summaryRow1.findCell(4).alignment = { horizontal: 'right' };

        })
      });
    }
    exposureHeaderRow.font = { bold: true };
    exposureHeaderRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });

    let totalReowdata: any[] = ['Total', "", totalCount, ""];

    let summaryRowTotal = worksheet.addRow(totalReowdata);

    summaryRowTotal.font = { bold: true };
    summaryRowTotal.eachCell((cell, number) => {

      if (number == 1) {
        cell.alignment = { horizontal: 'center' }
      }
      else {
        cell.alignment = { horizontal: 'right' }
      }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });


    for (var i = 1; i <= worksheet.columnCount; i++) {
      worksheet.getColumn(i).width = 30;
    }




    worksheet.addRow([]);
  }


  generateExcelLitigationReportSolicitorVolume(worksheet: ExcelProper.Worksheet, reportTypeHeader, response: any, litigaionReportSolicitorTable: any) {
    let reportTableNameRow = worksheet.addRow(litigaionReportSolicitorTable);
    reportTableNameRow.findCell(1).font = { bold: true };

    let exposureHeaderRow;
    let toprow = worksheet.addRow([reportTypeHeader]);
    toprow.findCell(1).font = { bold: true };
    let headercolumnName = ["Claimant Solicitor", "Claim Type", "Volume", "Litigation Rate(%)"];

    let totalCount = _.sumBy(response, (o: any) => { return o.volume });

    exposureHeaderRow = worksheet.addRow(headercolumnName);
    if (this.formDataInput.handlingOrganisationId == 0) {
      response.forEach((item, index) => {
        let data: any[] = [];
        data.push(item.claimantSolicitor);
        data.push(item.claimType);
        data.push(item.volume);
        data.push(item.litigationRate.toFixed(2) + "%");

        let summaryRow1 = worksheet.addRow(data);
        summaryRow1.eachCell((cell, number) => {
          summaryRow1.findCell(4).alignment = { horizontal: 'right' };

        })

      });
    }
    else {
      response.forEach((item, index) => {
        let data: any[] = [];
        data.push(item.claimantSolicitor);
        data.push(item.claimType);
        data.push(item.volume);
        data.push(item.litigationRate.toFixed(2) + "%");

        let summaryRow1 = worksheet.addRow(data);
        summaryRow1.eachCell((cell, number) => {
          summaryRow1.findCell(4).alignment = { horizontal: 'right' };

        })

      });
    }
    exposureHeaderRow.font = { bold: true };
    exposureHeaderRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });

    let totalReowdata: any[] = ['Total', "", totalCount, ""];

    let summaryRowTotal = worksheet.addRow(totalReowdata);

    summaryRowTotal.font = { bold: true };
    summaryRowTotal.eachCell((cell, number) => {

      if (number == 1) {
        cell.alignment = { horizontal: 'center' }
      }
      else {
        cell.alignment = { horizontal: 'right' }
      }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });



    for (var i = 1; i <= worksheet.columnCount; i++) {
      worksheet.getColumn(i).width = 30;
    }


    worksheet.addRow([]);
  }

  generateExcelLitigationReportCause(worksheet: ExcelProper.Worksheet, reportTypeHeader, response: any, headercolumnNameForCause: any, litigaionReportCauseTable: any) {

    let reportTableNameRow = worksheet.addRow(litigaionReportCauseTable);
    reportTableNameRow.findCell(1).font = { bold: true };


    let exposureHeaderRow;
    let toprow = worksheet.addRow([reportTypeHeader]);
    toprow.findCell(1).font = { bold: true };

    let totalCount = _.sumBy(response, (o: any) => { return o.volume });

    exposureHeaderRow = worksheet.addRow(headercolumnNameForCause);
    if (this.formDataInput.handlingOrganisationId == 0) {
      response.forEach((item, index) => {
        let data: any[] = [];
        data.push(item.handlingOrganisation);
        data.push(item.claimType);
        data.push(item.cause);
        data.push(item.volume);
        data.push(item.litigationRate.toFixed(2) + "%");
        let summaryRow1 = worksheet.addRow(data);

        summaryRow1.eachCell((cell, number) => {
          summaryRow1.findCell(5).alignment = { horizontal: 'right' };
        })
      });
    }
    else {
      response.forEach((item, index) => {
        let data: any[] = [];
        data.push(item.claimHandler);
        data.push(item.claimType);
        data.push(item.cause);
        data.push(item.volume);
        data.push(item.litigationRate.toFixed(2) + "%");
        let summaryRow1 = worksheet.addRow(data);

        summaryRow1.eachCell((cell, number) => {
          summaryRow1.findCell(5).alignment = { horizontal: 'right' };
        })

      });
    }

    exposureHeaderRow.font = { bold: true };
    exposureHeaderRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });

    let totalReowdata: any[] = ['Total', "", "", totalCount, ""];

    let summaryRowTotal = worksheet.addRow(totalReowdata);

    summaryRowTotal.font = { bold: true };
    summaryRowTotal.eachCell((cell, number) => {

      if (number == 1) {
        cell.alignment = { horizontal: 'center' }
      }
      else {
        cell.alignment = { horizontal: 'right' }
      }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });

    for (var i = 1; i <= worksheet.columnCount; i++) {
      worksheet.getColumn(i).width = 30;
    }

    worksheet.addRow([]);
  }

  generateExcelLitigationPerHandlingOrganisationReport(worksheet: ExcelProper.Worksheet, reportTypeHeader, response: any, LitigationPerHandlingOrganisationReport: any) {
    let reportTableNameRow = worksheet.addRow(LitigationPerHandlingOrganisationReport);
    reportTableNameRow.findCell(1).font = { bold: true };

    let exposureHeaderRow;
    let toprow = worksheet.addRow([reportTypeHeader]);
    toprow.findCell(1).font = { bold: true };
    let headercolumnName = ["Handling Organisation", "EL", "TP", "Total"];

    let elGrandTotal = _.sumBy(response, (o: any) => { return o.elCount });
    let tpGrandTotal =  _.sumBy(response, (o: any) => { return o.tpCount });
    let eltpGrandTotal = elGrandTotal + tpGrandTotal;

    exposureHeaderRow = worksheet.addRow(headercolumnName);
    if (this.formDataInput.handlingOrganisationId == 0) {
      response.forEach((item, index) => {
        let data: any[] = [];
        data.push(item.handlingOrganisation);
        data.push(item.elCount == 0 ? 'n/a' : item.elCount);
        data.push(item.tpCount == 0 ? 'n/a' : item.tpCount);
        data.push(item.eltpTotalCount == 0 ? 'n/a' : item.eltpTotalCount);

        let summaryRow1 = worksheet.addRow(data);
        summaryRow1.eachCell((cell, number) => {
          summaryRow1.findCell(4).alignment = { horizontal: 'right' };

        })

      });
    }
    else {
      response.forEach((item, index) => {
        let data: any[] = [];
        data.push(item.handlingOrganisation);
        data.push(item.elCount == 0 ? 'n/a' : item.elCount);
        data.push(item.tpCount == 0 ? 'n/a' : item.tpCount);
        data.push(item.eltpTotalCount == 0 ? 'n/a' : item.eltpTotalCount);

        let summaryRow1 = worksheet.addRow(data);
        summaryRow1.eachCell((cell, number) => {
          summaryRow1.findCell(4).alignment = { horizontal: 'right' };

        })

      });
    }
    exposureHeaderRow.font = { bold: true };
    exposureHeaderRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });


    let totalReowdata: any[] = ['Total', elGrandTotal > 0 ? elGrandTotal : 'n/a', tpGrandTotal > 0 ? tpGrandTotal : 'n/a', eltpGrandTotal > 0 ? eltpGrandTotal : 'n/a'];

    let summaryRowTotal = worksheet.addRow(totalReowdata);

    summaryRowTotal.font = { bold: true };
    summaryRowTotal.eachCell((cell, number) => {

      if (number == 1) {
        cell.alignment = { horizontal: 'center' }
      }
      else {
        cell.alignment = { horizontal: 'right' }
      }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });

    for (var i = 1; i <= worksheet.columnCount; i++) {
      worksheet.getColumn(i).width = 30;
    }


    worksheet.addRow([]);
  }

  setBorderExcelCell(cell: any) {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
  }

  fillBackGroundInExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00C4C4C4' },
      bgColor: { argb: 'FF0000FF' }
    }
  }


}

export class claimsReport {
  claimTypes: any[];
  handlingOrganisationId: number;
  claimHandlersId: any[];
  claimHandlersIdValues: any[];
  dateFrom: any;
  dateTo: any;
  asAtDate: any;
  reportType: number;
  litigatedTypeId: number;
  excludeNilsId: number;
  claimTypeValues: any[];
  dateFromCalander: Date;
  dateToCalander: Date;
  asAtDateCalander: Date;

}


import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask'
// Services
import { DataService } from './services/data.service';
import { SecurityService } from './services/security.service';
import { ConfigurationService } from './services/configuration.service';
import { StorageService } from './services/storage.service';
import { PolicyService } from './services/policy.service';
import { CreditorService } from './services/creditor.service'
import { NotificationService } from './services/notification.service';
import { LitigationService } from './services/litigation.service';
import { ValuationService } from './services/valuation.service';
import { PaymentService } from './services/payment.service';
import { ReserveHistoryService } from './services/reservehistory.service';
import { AuthService } from './services/auth.service';


// Components:
//import { Pager } from './components/pager/pager';
//import { Header } from './components/header/header';
//import { Identity } from './components/identity/identity';
//import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';


// Pipes:
import { UppercasePipe } from './pipes/uppercase.pipe';
import { FilterDeleted } from './pipes/filterdeleted.pipe';
import { SafeUrl } from './pipes/safeUrl.pipe';

//directives
import { OnlynumberDirective } from './directives/onlynumber.directive';
import { SelectRequiredValidatorDirective } from './directives/select-required-validator.directive';
import { HandlingOrganisationService } from './services/handling-organisation.service';
import { DatepickerPopupComponent } from './components/datepicker/datepicker-popup.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './services/loader.interceptor';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { ManageReinsurerService } from './services/manage-reinsurer.service';
import { ManageDwpService } from './services/manage-dwp.service';
import { from } from 'rxjs';
import { AddWorkflowItemComponent } from '../components/claim/add-workflow-item/add-workflow-item.component';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  imports: [
    CommonModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    // No need to export as these modules don't expose any components/directive etc'
    HttpClientModule,
    HttpClientJsonpModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    AddWorkflowItemComponent,
    DatepickerPopupComponent,
    //Pager,
    //Header,
    //Identity,
    //PageNotFoundComponent,
    UppercasePipe,
    OnlynumberDirective,
    SelectRequiredValidatorDirective,
    FilterDeleted,
    AccessDeniedComponent,
    SafeUrl
  ],
  exports: [
    // Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    // Providers, Components, directive, pipes
    //Pager,
    //Header,
    //Identity,
    //PageNotFoundComponent,
    UppercasePipe,
    FilterDeleted,
    DatepickerPopupComponent,
    SelectRequiredValidatorDirective,
    SafeUrl,
    AddWorkflowItemComponent
  ]
})


export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        // Providers
        DataService,
        SecurityService,
        ConfigurationService, { provide: APP_INITIALIZER, useFactory: init_app, deps: [ConfigurationService], multi: true },
        LoaderService, { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        StorageService,
        CreditorService,
        NotificationService,
        LitigationService,
        ValuationService,
        PolicyService,
        HandlingOrganisationService,
        PaymentService,
        ReserveHistoryService,
        AuthService,
        ManageReinsurerService,
        ManageDwpService,
        AddWorkflowItemComponent
      ]
    };
  }
}

export function init_app(configurationService: ConfigurationService) {
  configurationService.load();
  return () => configurationService.settingsLoaded$.subscribe();
}

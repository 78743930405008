import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IClaims } from '../../models/claims.model';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClaimDetailNotes } from '../../components/claim/claim-detail-notes/claim-detail-notes.model';
import { ClaimHandlingReviewNotes } from '../../components/claim/claim-detail-notes/claim-detail-review-notes.model';

@Injectable({
  providedIn: 'root'
})
export class ClaimDetailNotesService {

  private claimDetailNoteUrl: string = "/api/claimhandlingnotes/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.claimDetailNoteUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.claimDetailNoteUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.claimDetailNoteUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.claimDetailNoteUrl);

  }

  //createClaimHandlingNotes(claimDetailNotes: ClaimDetailNotes): any {

  //  let url = this.claimDetailNoteUrl + "createClaimHandlingNotes";
  //  let body = JSON.stringify(claimDetailNotes);
  //  let headers = new HttpHeaders({
  //    'Content-Type': 'application/json'
  //  });
  //  let options = {
  //    headers: headers
  //  }

  //  return this.http.post<any>(url, claimDetailNotes, options);
  //  //return this.dataService.post(url, claimDetailNotes);

  //}

  updateClaimHandlingNotes(data): any {

    let url = this.claimDetailNoteUrl + "updateClaimHandlingNotes";

    return this.dataService.putWithId(url, data);
  }

  getClaimHandlingNoteById(claimId: number): any {
    let url = this.claimDetailNoteUrl + "getClaimHandlingNotesById";
    
    return this.dataService.get(url, { claimId: claimId });
  }



  //getClaimHandlingReviewNotesCommentsById(claimHandlingNotesId: number): any {
  //  let url = this.claimDetailNoteUrl + "getClaimHandlingReviewNotesCommentsById";

  //  return this.dataService.get(url, { claimHandlingNotesId: claimHandlingNotesId });
  //}

  //createClaimHandlingReviewNotesComments(claimHandlingReviewNotes): any {

  //  let url = this.claimDetailNoteUrl + "createClaimHandlingReviewNotesComments";
  //  //let body = JSON.stringify(claimHandlingReviewNotes);
  //  //let headers = new HttpHeaders({
  //  //  'Content-Type': 'application/json'
  //  //});
  //  //let options = {
  //  //  headers: headers
  //  //}

  //  //return this.http.post<any>(url, claimHandlingReviewNotes, options);
  //  //return this.dataService.post(url, claimDetailNotes);
  //  return this.dataService.post(url, claimHandlingReviewNotes);

  //}

}

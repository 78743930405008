import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SearchPolicyVM } from '../../../models/search-policy.model';
import { lineOfBusiness, policyType, enumYesNo, enumLitigatedType, enumHandlingOrganization } from '../../../shared/models/common.enum';
import { IPager } from '../../../shared/models/pager.model';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import * as _ from 'lodash';
import { CaseloadSummaryService } from './caseload-summary.service';
import { CaseloadSummary } from '../../../models/caseload-summary.model';
import { NgForm } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SecurityService } from '../../../shared/services/security.service';


@Component({
  selector: 'app-caseload-summary',
  templateUrl: './caseload-summary.component.html',
  styleUrls: ['./caseload-summary.component.css']
})
export class CaseloadSummaryComponent implements OnInit {

  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  claimTypeLookup: any[];
  claimStatusLookup: any[];
  handlingOrganisationData: any[];
  claimHandlersData: any;
  claimKeyContactsData: any
  claimReportTypes: any[];
  claimStatusIds: any[];

  newOrSettledClaimsFlag: boolean = true;
  openClaimsFlag: boolean = true;

  yesNoEnum = enumYesNo;

  yesNokeys: any[];
  //litigatedTypeKeys: any[];
  claimStatusTypeKeys: any[];
  disableKeyContactDropDown: boolean=false;

  public caseloadSummaryReportInputModel: CaseloadSummary = new CaseloadSummary();
  isValid: boolean = true;
  isMMIUser: boolean = false;

  constructor(private lookupService: LookupService,
    private caseloadSummaryService: CaseloadSummaryService,
    private notificationService: NotificationService,
    private securityService: SecurityService
  ) { }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: true,
    itemsShowLimit: 5,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  dropdownSettingsClaimHandlersData: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'claimsHandlers',
    enableCheckAll: true,
    itemsShowLimit: 5,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  settingsMMIContacts: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'claimsHandlers',
    enableCheckAll: true,
    itemsShowLimit: 5,
    allowSearchFilter: true,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All'
  };

  ngOnInit(): void {
    this.InitializeData();
  }

  InitializeData() {
    this.initializeForm();
    this.claimTypeLookup = this.lookupService.getLookupsByTypeId(1);
    this.claimStatusLookup = this.lookupService.getLookupsByTypeId(3);

    this.caseloadSummaryService.getHandlingOrganization().subscribe(
      response => {
        this.handlingOrganisationData = response;
      });

    this.claimStatusLookup = this.claimStatusLookup.filter(result => {
      return result.text == 'Open' || result.text == 'Settled';
    })

    this.yesNokeys = Object.values(this.yesNoEnum).filter(Number);
    this.caseloadSummaryReportInputModel.handlingOrganisationId = 0;
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
      if (!this.isMMIUser) {
        this.caseloadSummaryService.formDataInput.handlingOrganisationId = this.securityService.getUserData().organisationId;
        this.onHandlingOrganisationChange();
      }
    }
  };
  caseLoadValidation(): boolean {
    this.isValid = true;
    if (this.caseloadSummaryService.formDataInput.handlingOrganisationId == null ||
      this.caseloadSummaryService.formDataInput.claimHandlersIdValues == null ||
      this.caseloadSummaryService.formDataInput.claimHandlersIdValues.length == 0 ||
      this.caseloadSummaryService.formDataInput.claimTypeValues == null ||
      this.caseloadSummaryService.formDataInput.claimTypeValues.length == 0 ||
      this.caseloadSummaryService.formDataInput.claimStatus == null ||
      this.caseloadSummaryService.formDataInput.claimStatus.length == 0
    ) {
      return this.isValid = false;
    }
    return this.isValid;
  }

  generateCaseloadSummaryReport() {

    this.caseloadSummaryService.formDataInput.claimStatusValues = [];
    this.claimStatusIds = [];
    this.caseloadSummaryService.formDataInput.claimTypeValues = _.map(this.caseloadSummaryService.formDataInput.claimTypes, function (i: any) { return i.id })
    this.claimStatusIds = _.map(this.caseloadSummaryService.formDataInput.claimStatus, function (i: any) { return i.id });
    this.caseloadSummaryService.formDataInput.claimHandlersIdValues = _.map(this.caseloadSummaryService.formDataInput.claimHandlersId, function (i: any) { return i.id })
    this.caseloadSummaryService.formDataInput.claimKeyContactValues = _.map(this.caseloadSummaryService.formDataInput.claimKeyContactId, function (i: any) { return i.id })
    this.caseloadSummaryService.formDataInput.claimTypeNames = _.map(this.caseloadSummaryService.formDataInput.claimTypes, function (i: any) { return i.text })


    if (this.caseLoadValidation()) {
      if (this.claimStatusIds.length > 0) {
        var opned = this.claimStatusIds.find(id => id == 23);
        var setteled = this.claimStatusIds.find(id => id == 24);
        if (opned == 23 && setteled == 24) {
          opned == 23 && setteled == 24 ? this.caseloadSummaryService.formDataInput.claimStatusValues.push(23, 24, 25, 26, 905) : this.caseloadSummaryService.formDataInput.claimStatusValues;
        } else {
          opned == 23 ? this.caseloadSummaryService.formDataInput.claimStatusValues.push(23, 25) : this.caseloadSummaryService.formDataInput.claimStatusValues;
          setteled == 24 ? this.caseloadSummaryService.formDataInput.claimStatusValues.push(24, 26, 905) : this.caseloadSummaryService.formDataInput.claimStatusValues;
        }
      }
      this.caseloadSummaryService.generateCaseloadSummaryReport();
    }
    else {
      this.notificationService.printWarningMessage("Please select all mandatory fields");
    }
  }


  initializeForm() {
    this.caseloadSummaryService.formDataInput = {
      claimTypes: [],
      handlingOrganisationId: null,
      claimStatus: [],
      aboveHandlingAuthorityId: 0,
      abovePaymentAuthorityId: 0,
      claimStatusValues: [],
      claimTypeValues: [],
      claimHandlersId: [],
      claimHandlersIdValues: [],
      claimTypeNames: [],
      claimKeyContactValues: [],
      claimKeyContactId: []
    }
  }

  onHandlingOrganisationChange() {
    if (this.caseloadSummaryService.formDataInput.handlingOrganisationId == 0) {
      this.caseloadSummaryService.formDataInput.claimHandlersIdValues = [];
      this.caseloadSummaryService.formDataInput.claimHandlersId = [];
      this.claimHandlersData = [];
      this.claimHandlersData = [{ "id": 0, "claimsHandlers": "All" }];

      this.caseloadSummaryService.formDataInput.claimKeyContactValues = [];
      this.caseloadSummaryService.formDataInput.claimKeyContactId = [];
      this.claimKeyContactsData = [];
      this.claimKeyContactsData = [{ "id": 0, "claimsHandlers": "All" }];
      this.disableKeyContactDropDown = false;
    }
    else {
      this.caseloadSummaryService.getClaimHandlersByHandlingOrganisation(this.caseloadSummaryService.formDataInput.handlingOrganisationId).subscribe(
        res => {
          
          this.caseloadSummaryService.formDataInput.claimHandlersIdValues = [];
          this.caseloadSummaryService.formDataInput.claimHandlersId = [];
          this.claimHandlersData = [];
          this.claimHandlersData = res;
          this.claimHandlersData.unshift({ "id": 0, "claimsHandlers": "All" });
        });
       this.loadMMIKeyContactValues();
     
    }

  
    //multiselect 
    //onHandlingOrganisationChange() {
    //  this.caseloadSummaryService.formDataInput.claimHandlers = [];
    //  if (this.caseloadSummaryService.formDataInput.handlingOrganisationId == 0) {
    //    this.claimHandlersData = [];
    //  }
    //  else {
    //    this.caseloadSummaryService.getClaimHandlersByHandlingOrganisation(this.caseloadSummaryService.formDataInput.handlingOrganisationId).subscribe(
    //      res => {
    //        var result = res;

    //        this.claimHandlersData = _.map(result, function (i: any) { return Text = i.claimsHandlers });
    //      });
    //  }
    //}
  }

  loadMMIKeyContactValues() {
    this.caseloadSummaryService.formDataInput.claimKeyContactId = [];
    this.caseloadSummaryService.formDataInput.claimKeyContactValues = [];
    this.claimKeyContactsData = [];
    if (this.caseloadSummaryService.formDataInput.handlingOrganisationId != 1) {
      this.caseloadSummaryService.getClaimHandlersByHandlingOrganisation(enumHandlingOrganization.MMI).subscribe(
        res => {
          this.claimKeyContactsData = res;
          this.claimKeyContactsData.unshift({ "id": 0, "claimsHandlers": "All" });
          this.disableKeyContactDropDown = false;
        });
    } else {
      this.disableKeyContactDropDown = true;
    }
  }
}

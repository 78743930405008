import { Injectable } from '@angular/core';
import { ReinsuranceIndexationService } from '../../../shared/services/reinsurance-indexation.service';
import { IIndexation } from './indexation.model';

@Injectable({
  providedIn: 'root'
})
export class IndexationService {

  formData: IIndexation[] = [];

  constructor(private reinsuranceIndexationService: ReinsuranceIndexationService) { }

  getReinsuranceIndexations() {
    return this.reinsuranceIndexationService.getReinsuranceIndexations();
  }

}

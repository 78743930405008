import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Workbook, Cell } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { allocatedClaimsReportRequestModel, allocatedClaimsReportResponseModel } from '../../../models/allocated-claims-report.model';
import { ReportsService } from '../../../shared/services/reports.service';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service';
import { NotificationService } from '../../../shared/services/notification.service';


@Injectable({
  providedIn: 'root'
})
export class AllocatedClaimService {

  formDataInput: allocatedClaimsReportRequestModel;
  responseModel: allocatedClaimsReportResponseModel[] = [];

  constructor(private reportsService: ReportsService, private handlingOrganizationService: HandlingOrganisationService,
    private notificationService: NotificationService) { }



  getHandlingOrganization(): Observable<any> {
    return this.handlingOrganizationService.getHandlingOrganization();
  }
  
  generateAllocatedClaimsReport(): any {
    var body = {
      ...this.formDataInput

    };
    
    this.reportsService.getAllocatedClaimReport(this.formDataInput).subscribe((response: any[]) => {
      if (response != null && response.length > 0) {
        this.responseModel = response;
        let fileName = "";
        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Allocated Claims Report');
        let currentDate = moment(new Date().toDateString()).format('DD/MM/YYYY');

        let fileFormat = "";                
        fileFormat = "Allocated Claims Report_" + currentDate;        
        
        fileName = fileFormat;
        this.generateExcelData(worksheet, "", this.responseModel);

        let date = new Date();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let timestamp = hours + '' + minutes + '' + seconds;

        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fileName + '_' + timestamp + '.xlsx');
        })
      }
      else {
        this.notificationService.printWarningMessage("No data is available for the Report");
      }
    });
  }

  generateExcelData(worksheet: ExcelProper.Worksheet, reportTypeHeader, response: any) {
    let claimHandlerHeader = ["MMI Claim Ref.", "Claim Type", "Insured Title", "Allocated On", "Handling Organisation", "Allocation Status",
      "HO Response On", "Claim Handler Assigned On", "Claim Handler"];

    let reportTableName = ["Allocated Claims Summary"];
    let reportTableNameRow = worksheet.addRow(reportTableName);
    reportTableNameRow.findCell(1).font = { bold: true };


    let exposureHeaderRow;
    let toprow = worksheet.addRow([reportTypeHeader]);
    toprow.findCell(1).font = { bold: true };
        
    exposureHeaderRow = worksheet.addRow(claimHandlerHeader);
    response.forEach((item, index) => {
      let data: any[] = [];
      data.push(item.mmiClaimsReference);
      data.push(item.claimType);
      data.push(item.insuredTitle);
      data.push(item.allocatedDate != null ? (moment(new Date(item.allocatedDate).toDateString()).format('DD/MM/YYYY')) : item.allocatedDate);
      data.push(item.handlingOrganisationName);
      data.push(item.handlingStatusText);
      data.push(item.acceptedOrRejectedDate != null ? (moment(new Date(item.acceptedOrRejectedDate).toDateString()).format('DD/MM/YYYY')) : item.acceptedOrRejectedDate);      
      data.push(item.claimHandlerAssignedDate != null ? (moment(new Date(item.claimHandlerAssignedDate).toDateString()).format('DD/MM/YYYY')) : item.claimHandlerAssignedDate);
      data.push(item.claimHandlerName);
      
      let summaryRow1 = worksheet.addRow(data);      

    });

    exposureHeaderRow.font = { bold: true };
    exposureHeaderRow.eachCell((cell, number) => {
      cell.alignment = { horizontal: 'center' }
      this.fillBackGroundInExcellCell(cell);
      this.setBorderExcelCell(cell);
    });
    for (var i = 1; i <= worksheet.columnCount; i++) {
      worksheet.getColumn(i).width = 30;
    }

    worksheet.addRow([]);
  }

  setBorderExcelCell(cell: any) {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
  }

  fillBackGroundInExcellCell(cell: any) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00C4C4C4' },
      bgColor: { argb: 'FF0000FF' }
    }
  }


}

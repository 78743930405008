import { SearchWorkflowRequestModel } from "./search-workflow.model";
import { ClaimWorkflowService } from "../../shared/services/claim-workflow.service";
import { ClaimService } from "../../shared/services/claim.service";
import { ClaimHandlerService } from "../../shared/services/claim-handler.service";
import { Injectable } from "@angular/core";
import { HandlingOrganisationService } from "../../shared/services/handling-organisation.service";

@Injectable({
  providedIn: 'root'
})
export class SearchflowItemService {

  formData: SearchWorkflowRequestModel;

  constructor(private claimWorkflowService: ClaimWorkflowService,
    private claimService: ClaimService, private claimHandlerService: ClaimHandlerService, private handlingOrganisationService: HandlingOrganisationService) {
  }


  getClaimWorkflows(): any {
    var body = {
      ...this.formData

    };
    return this.claimWorkflowService.searchClaimWorkflow(body);
  }

  getClaimHandlersByHandlingOrganisation(organisationId: any) {
    return this.claimHandlerService.getClaimHandlers(organisationId);
  }

  getHandlingOrganisation() {
    return this.handlingOrganisationService.getAllHandlingOrganisations();
  }


  updateWorkflowStatus(workflowItemIds, status): any {
    return this.claimWorkflowService.updateWorkflowStatus(workflowItemIds, status);
  }


}

import { ClaimHandlingReviewNotes } from '../claim-detail-notes/claim-detail-review-notes.model';

export class ClaimDetailNotes {
  liabilityDescription: string;
  causationDescription: string;
  limitationDescription: string;
  quantumDescription: string;
  diaryDate: Date;
  diaryComment: string;
  overallCoordinator: string;
  capeProductID: number;
  claimId: number;
  id: number;
  //reviewNotes                    :number;
  claimReviewedDate: Date;
  //notesDate: Date;
  //notesComment: string;
  handlerId: string;
  claimTypeId: number;
  eFileURL: string;
  claimHandlingReviewNotes:ClaimHandlingReviewNotes[];
  mmiReviewDate: null;
  mmiReviewComment: string;
  version: string;
  liabilityLastReviewedDate: Date;
  causationLastReviewedDate: Date;
  quantumLastReviewedDate: Date;
  limitationLastReviewedDate: Date;
  nextKeyDateID: number;
  nextKeyDateDiary: Date;
}



import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageDwpService {


  private dwpUrl: string = '/api/manageDWP/';

  constructor(
    private dataService: DataService,
    private configurationService: ConfigurationService,
    private http: HttpClient
  ) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.dwpUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.dwpUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.dwpUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.dwpUrl);
  }

  getDwpById(id: number): any {
    let url = this.dwpUrl + "getDwpById";

    return this.dataService.get(url, { id: id });
  }

  createDwp(data): any {

    let url = this.dwpUrl + "createDwp";

    return this.dataService.post(url, data);

  }

  updateDwp(data): any {

    let url = this.dwpUrl + "updateDwp";

    return this.dataService.putWithId(url, data);
  }

  getAllDwp() {
    return this.dataService.get(this.dwpUrl);
  }

  getDwp(): Observable<any> {
    return this.http.get(`${this.dwpUrl}`);
  }

}

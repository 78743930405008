import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClaimWorkflowService } from '../../../shared/services/claim-workflow.service';
import { ClaimService } from '../../../shared/services/claim.service';
import { claimDataModel } from './claim-data.model';
import { addWorkflowDataModel } from './add-workflow-item.model';
import { ClaimHandlerService } from '../../../shared/services/claim-handler.service';



@Injectable({
  providedIn: 'root'
})
export class AddWorkflowItemService {

  formData: addWorkflowDataModel;

  constructor(private claimWorkflowService: ClaimWorkflowService,
    private claimService: ClaimService, private claimHandlerService: ClaimHandlerService) {
  }


  getClaimById(id: number): any {
    return this.claimWorkflowService.getClaimById(id);
  }

  saveWorkflowItem(): any {
   
    var body = {
      ...this.formData

    };
    if (this.formData.id > 0) {
      return this.claimWorkflowService.updateWorkflowItem(body);
    }
    else {
      return this.claimWorkflowService.saveWorkflowItem(body);
    }
   
  }

  getClaimHandlersByHandlingOrganisation(organisationId: any) {
    return this.claimHandlerService.getClaimHandlers(organisationId);
  }

  getWorkflowItems(workflowItemId: number): any {    
    return this.claimWorkflowService.getWorkflowItem(workflowItemId);
  }

  getClaimDetails(claimId: number): any {    
    return this.claimService.getClaimById(claimId);
  }

  getClaimData(mmiClaimsReference: string): any {    
    return this.claimWorkflowService.getClaimDetails(mmiClaimsReference);
  }

}

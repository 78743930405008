import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { IClaims } from '../../models/claims.model';
import { ISearchClaimVM } from '../../models/search-claim.model';
import { Observable } from 'rxjs';
import { SecurityService } from './security.service';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';

@Injectable()
export class ClaimService {
  private claimUrl: string = '/api/claim/';
  private authUrl: string ='/api/auth/'

  constructor(private service: DataService, private configurationService: ConfigurationService, private http: HttpClient, private authService: AuthService, private storageService: StorageService) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.claimUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.claimUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.claimUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.claimUrl);
  }

  getClaims(filterCriteria: ISearchClaimVM) {
    let url = this.claimUrl + "search";
    
    return this.service.get(url, filterCriteria ).pipe<IClaims>(tap((response: any) => {
      return response;
    }));
  }

  createClaim(data): Observable<any> {

    let body = JSON.stringify(data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = {
      headers: headers
    }

    //console.log(body);

    let url = this.claimUrl;

    return this.http.post<any>(url, body, options);
  }

  getClaimById(id: number): any {
    let url = this.claimUrl;
    return this.http.get(this.claimUrl + '?claimId=' + id.toString()).toPromise();
  }

  getNewClaimByPolicyId(id: number): any {
    let url = this.claimUrl;
    return this.http.get(this.claimUrl + '?policyid=' + id.toString()).toPromise();
  }

  getClaimAdditionlPolices(id: number): any {
    let url = this.claimUrl;
    return this.http.get(this.claimUrl + 'GetClaimAdditionlPolices?id=' + id.toString()).toPromise();
  }

  getMatchedClaimant(id: number, matched: boolean): any {
    let url = this.claimUrl;
    return this.http.get(this.claimUrl + 'getMatchedClaimant?claimantId=' + id.toString() + '&matchType=' + matched).toPromise();
    //return this.http.get(this.claimUrl + 'getMatchedClaimant', { claimantId: id, matchType: matched }).toPromise();
  }

  addPolicy(claimId: number, policyId: number): any {
    let url = this.claimUrl;
    return this.http.get(this.claimUrl + 'addPolicy?claimId=' + claimId.toString() + '&policyId=' + policyId.toString()).toPromise();
  }

  removePolicy(additionalPolicyId: number): any {
    let url = this.claimUrl;
    return this.http.get(this.claimUrl + 'removePolicy?additionalPolicyId=' + additionalPolicyId.toString() ).toPromise();
  }

  updateClaim(data): Observable<any> {
    if (this.configurationService.isReady) {

      let body = JSON.stringify(data);
      //console.log(body);

      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      let options = {
        headers: headers
      }

      let url = this.claimUrl;
      //debugger;
      return this.http.put<any>(url, body, options)
    }
  }
  isApportionmentAvailble(id: number): any {
    let url = this.claimUrl;
    return this.http.get(this.claimUrl +'isApportionmentAvailble?claimId=' + id.toString()).toPromise();
  }
  checkValuationAvailble(id: number): any {
    let url = this.claimUrl;
    return this.http.get(this.claimUrl + 'checkValuationAvailble?claimId=' + id.toString()).toPromise();
  }
  checkClaimAccessiblity(claimId: number, organisationId: number): any {
    return this.authService.checkClaimAccessiblity(claimId, organisationId);
  }

  createClaimFolder(id: number): any {
    let url = this.claimUrl + "createClaimFolder";
    return this.service.get(url, { claimId: id });     
  }

  clearLocalStorage() {
    this.storageService.removeItem("showAddPanel");
    this.storageService.removeItem("addComments");
    this.storageService.removeItem("apportionmentData");
    this.storageService.removeItem("valuationData");
    this.storageService.removeItem("valuationCalculations");
    this.storageService.removeItem("valuationCalculation");
    this.storageService.removeItem("cruLiabilities");
    this.storageService.removeItem("cruLiability");
  }

  checkRIApplicable(id: number): any {
    let url = this.claimUrl + "checkRIApplicable";
    return this.service.get(url, { claimId: id });
  }

  updateClaimStatus(id: number, claimStatus: number, settlementReason1: number, settlementReason2: number, settlementReason3: number, version: string): any {
    let url = this.claimUrl + "updateClaimStatus";
    return this.service.get(url, { claimId: id, claimStatusID: claimStatus, settlementReasonId1: settlementReason1, settlementReasonId2: settlementReason2, settlementReasonId3: settlementReason3, version: version });
  }

  GetClaimEFileUrl(id: number): any {
    let url = this.claimUrl + "getClaimEFileUrl";
    return this.service.get(url, { claimId: id });
  }

  getMatchingClaimants(claimTypeId: number, firstName: string, surname: string, dateofBirth: Date, nationalInsuranceNumber: string): any {
    let url = this.claimUrl + "getMatchingClaimants";
    return this.service.get(url, { claimTypeId: claimTypeId, firstName: firstName, surname: surname, dateofBirth: dateofBirth, nationalInsuranceNumber: nationalInsuranceNumber });
  }

  getMatchingClaimantsData(data): any {
    let url = this.claimUrl + "getMatchingClaimantsData";
    return this.service.post(url, data);

  }
 
}
 


import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PolicyService } from '../../../shared/services/policy.service';
import { SearchPolicyVM } from '../../../models/search-policy.model';
import { IClaims } from '../../../models/claims.model';

//import { List } from 'lodash';

@Injectable()
export class SearchPolicyService {

  constructor(private policyService: PolicyService) {

  }

  getPolicy(filterCriteria: SearchPolicyVM): any {
    return this.policyService.getPolicies(filterCriteria)
  }
  //getPolicyInsuredTitle(searchText: string): any {
  //  return this.policyService.getPolicyInsuredTitle(searchText);
  //}
  search(term: string) {
    if (!term || term.length < 3 || term.toLowerCase() =='cou') { return of([]); }
    return this.policyService.getPolicyInsuredTitle(term);
  }
  checkPolicyAccessiblity(policyId: number, organisationId: number): any {
    return this.policyService.checkPolicyAccessiblity(policyId, organisationId);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { EltoMatchingService } from './elto-matching.service';
import { HttpEventType } from '@angular/common/http';
import { NotificationService } from '../../../shared/services/notification.service';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-elto-matching',
  templateUrl: './elto-matching.component.html',
  styleUrls: ['./elto-matching.component.css']
})
export class EltoMatchingComponent implements OnInit {
  @ViewChild('paymentDetailForm', null) paymentDetailForm: NgForm;

  //  fileToUpload: File = null;
  //  eltoFileReceivedDate: Date;
  isValid: boolean = true;
  eFileURL: string = "";

  constructor(private eltomatchingService: EltoMatchingService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.InitializeData();
  }

  InitializeData() {
    this.initializeForm();
    this.getELTOWeeklyTracingReportsUrl();
  }

  initializeForm() {
    this.eltomatchingService.formDataInput = {
      fileToUpload: null,
      eltoFileReceivedDate: null
    }
  }

  handleFileInput(files: FileList) {
    this.eltomatchingService.formDataInput.fileToUpload = files.item(0);
  }

  validForm(): boolean {
    this.isValid = true;
    if (this.eltomatchingService.formDataInput.fileToUpload == null || this.eltomatchingService.formDataInput.eltoFileReceivedDate == null) {
      this.isValid = false;
      return false;
    }

    return this.isValid;
  }

  uploadCSVFile() {
    if (this.validForm()) {
      let fileReceivedDate = moment(new Date(this.eltomatchingService.formDataInput.eltoFileReceivedDate).toDateString()).format('DD/MM/YYYY')
      this.eltomatchingService.getELTOMatchingReport(fileReceivedDate).subscribe(res => {
        switch (res.type) {
          case HttpEventType.Response:


            const downloadedFile = new Blob([res.body], { type: res.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            var FileName = res.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
            a.download = FileName;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            break;
        }
      },
        error => {
          //debugger
          if (error.error == "Uploaded csv file is not valid ELTO file.") {
            this.notificationService.printWarningMessage(error.error);
          }
          else if (error.error == "Column names missing in uploaded csv file.") {
            this.notificationService.printErrorMessage(error.error);
          }
          else if (error.error == "Uploaded csv file is empty.") {
            this.notificationService.printErrorMessage(error.error);
          }
          else if (error.error == "Uploaded file is not .csv file.") {
            this.notificationService.printErrorMessage(error.error);
          }
          else {
            this.notificationService.printErrorMessage("Error occured in ELTO matching report");
          }
        }
      )
    }
  }

  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, ['Reference', 'Employer', 'Company Designation', 'Company No', 'Address', 'City', 'Postcode', 'Nature of Business',
      'Ceased Trading Date', 'Exposure From', 'Exposure To', 'Insurance Info', 'Other Infomation', 'Organisation', 'Employee', 'Batch Type']);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSV(objArray, headerList) {
    //debugger
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    for (let i = 0; i < 7; i++) {
      str += ' \r\n';
    }
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  jsonData = [];

  download() {
    // this.jsonData.push({ name: "Ken Rosenberg", location: "Vice City" });
    this.downloadFile(this.jsonData, 'ELTOMatchingTemplate');
  }

  getEFile() {
    if (this.eFileURL != '') {
      this.goToLink(this.eFileURL);
    }
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getELTOWeeklyTracingReportsUrl() {
    this.eltomatchingService.GetELTOWeeklyTracingReportsUrl().subscribe((res: any) => {
      console.log(res);
      if (res != null) {
        this.eFileURL = res.eFileUrl;
      }
    },
      error => {
        console.log(error);
      });
  }

}

import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef, OnDestroy, AfterViewChecked, HostListener } from '@angular/core';
import { ClaimReinsuranceService } from './claim-reinsurance.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { SecurityService } from '../../../shared/services/security.service';
import { enumYesNo } from '../../../shared/models/common.enum';
import { NgForm } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { ReinsuranceBreakDownByYear, ReinsuranceRecoverable, ReinsuranceNotes, IClaimReinsurance, RecoverableInvoice, RecoverableReceivable, ReinsuranceAggregatedNotes } from './claim-reinsurance.model';
import { Subject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimService } from '../../../shared/services/claim.service';

@Component({
  selector: 'app-claim-reinsurance',
  templateUrl: './claim-reinsurance.component.html',
  styleUrls: ['./claim-reinsurance.component.css']
})
export class ClaimReinsuranceComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked {

  @ViewChild('reinsuranceForm', null) reinsuranceForm: NgForm;
  @ViewChildren(DataTableDirective)
  private datatableElements: QueryList<DataTableDirective>;

  @ViewChild('breakdownByYearTable') breakdownByYearTable: ElementRef;
  @ViewChild('recoverableTable') recoverableTable: ElementRef;
  @ViewChild('reinsuranceNotesTable') reinsuranceNotesTable: ElementRef;
  @ViewChild('recoverableInvoicesTable') recoverableInvoicesTable: ElementRef;
  @ViewChild('recoverableReceivablesTable') recoverableReceivablesTable: ElementRef;
  @ViewChild('aggregatedNotesTable') aggregatedNotesTable: ElementRef;

  public dtbreakdownByYearOptions: any = {};
  public dtRecoverableOptions: any = {};
  public dtNotesOptions: any = {};
  public dtInvoicesOptions: any = {};
  public dtReceivablesOptions: any = {};
  public dtaggregatedNotesOptions: any = {};

  modalRef: NgbModalRef;

  dtRecoverableTrigger: Subject<any> = new Subject();
  // dtNotesTrigger: Subject<any> = new Subject();

  isRecoverableGridLoaded: boolean = false;
  // isNotesGridLoaded: boolean = false;

  isAggregated: boolean;

  yesNoEnum = enumYesNo;
  yesNokeys: any[];
  reinsuranceStatusKeys: any[];
  firstNotificationStatusKeys: any[];
  claimStatusLookup: any[];
  calculationTypeKeys: any[];
  aggregatedNotificationStatusKeys: any[];




  isMesoClaim: boolean;
  notificationDate: Date;
  openDate: Date;
  lineOfBusiness: number;

  exposureDaysSum: number;
  shareExposurePctSum: number;
  shareIncurredSum: number;
  reinsuranceRecoverables: ReinsuranceRecoverable[] = [];
  notes: ReinsuranceNotes[] = [];
  recoverableInvoices: RecoverableInvoice[] = [];
  recoverableReceivables: RecoverableReceivable[] = [];
  aggregatedNotes: ReinsuranceAggregatedNotes[] = [];

  public allocationOfIncurredCostData: any[] = [];

  showRecoverableEditPanelFlag: boolean = false;

  recoverable: ReinsuranceRecoverable;
  proRataCalcSum: number;
  proRataPlusSum: number;
  totalCalcProRataSum: number;
  totalCalcProRataPlusSum: number;
  amountSubmittedSum: number;
  amountAgreedSum: number;
  amountReceivedSum: number;
  variationSum: number;
  totalCalcSum: number;
  proRataCalcproRataPlusArr: any[] = [];

  itemIndex: number = null;
  selectedReinsurerId: number = null;
  disableFirstNotificationControls: boolean = true;
  disableSettlementPresentationControls: boolean = true;
  disableAgreementControls: boolean = true;
  vaildRecoverable: boolean = true;

  reinsuranceNote: ReinsuranceNotes;
  isNoteValid: boolean = true;
  id: string;

  aggregatedNote: ReinsuranceAggregatedNotes;
  disableAggregatedNotificationStatusControl: boolean = false;
  disableAggregatedControls: boolean = true;
  isAggregatedNoteValid: boolean = true;

  notificationStatusIds: any[] = [1311, 1312, 1313, 1314];
  isClaimSettled: boolean = false;
  isNotificationStatusCompletedAndAmountAgreedPresent: boolean = false;
  private originalClaimReinsuranceData: IClaimReinsurance;
  policyId: number

  recoverableInvoice: RecoverableInvoice;
  isValidAgreed: boolean = true;
  recoverableInvoicesinvoiceAmountSum: number;

  recoverableReceivable: RecoverableReceivable;
  isValidReceivable: boolean = true;
  receivableAmountReceivedSum: number;
  receivableItemId: number = null;
  isMMIUser: boolean = false;
  hideWorkflowButton: boolean = true;
  //itemIndexReceivable: number = null;

  vaildAggregatedRecord: boolean = true;
  isNewClaim: boolean = true;
  eFileURL: string;

  saveMethod: any;
  isFormSubmitted: boolean = true;
  private formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean {
    let isDataSame = true;
    let message = '';
    if (this.recoverable != undefined && this.recoverable != null) {
      if (this.recoverable.id == null) {

      }
      else if (this.recoverable.id > 0) {

        if (this.reinsuranceNote.comments.length > 0) {
          isDataSame = false;
          message = "You have unsaved comments on Form. Please add them to Notes Grid using Add Notes button.";
          this.showFormDirtyWarningMessage(message);
        }
        else {
          let recoverableRecord = Object.assign({}, this.reinsuranceRecoverables.find(a => a.id == this.recoverable.id));
          isDataSame = _.isEqual(this.notes, recoverableRecord.notes);
          if (!isDataSame) {
            message = "You have unsaved comments in comments grid. Please update them to reinsurance recoverables Grid using update button. Or Discard them using cancel button.";
            this.showFormDirtyWarningMessage(message);
          }
          if (isDataSame) {
            isDataSame = _.isEqual(this.recoverable, recoverableRecord);
            if (!isDataSame) {
              this.businessRules();
              let vaildRecoverable: boolean = true;
              vaildRecoverable = this.validateReinsurarerRecoverable();
              if (vaildRecoverable) {
                message = "You have unsaved changes on Form. Please update them to reinsurance recoverables Grid using update button. Or Discard them using cancel button and Proceed.";
                this.showFormDirtyWarningMessage(message);
              }
            }
          }
        }
      }
    }

    return !isDataSame;
  }

  showFormDirtyWarningMessage(message: string): void {    this.notificationService.printWarningMessage(message);
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    this.saveMethod = this.onSubmit;
    let canNavigate: boolean = this.canNavigateWOSaving();    return canNavigate;
  }

  canNavigateWOSaving(): boolean {

    let currentReinsuranceRecoverables = { ...this.claimReinsuranceService.formData };
    let isDataSame = _.isEqual(currentReinsuranceRecoverables, this.originalClaimReinsuranceData);
    return isDataSame;
  }

  constructor(private claimReinsuranceService: ClaimReinsuranceService,
    private currentRoute: ActivatedRoute,
    private lookupService: LookupService,
    private router: Router,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    securityService: SecurityService,
    private claimDetailService: ClaimService) {
    super(securityService, 1148);
    this.loadLookups();
  }

  ngOnInit(): void {

    this.id = this.currentRoute.parent.snapshot.paramMap.get('claimId');
    this.policyId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('policyId'));
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
      this.hideWorkflowButton = (this.isMMIUser) ? false : true
    }
    this.showRecoverableEditPanelFlag = false;
    this.dtbreakdownByYearOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      "order": [[0, "asc"]]
    };

    //debugger;
    this.dtRecoverableOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      /*"order": [[3, "desc"]]*/
      "order": [[3, "desc"]]
    };
    this.dtNotesOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false
    };
    this.dtInvoicesOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      "order": [[3, "asc"]]
    };
    this.dtReceivablesOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false
    };
    this.dtaggregatedNotesOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      "order": [[1, "desc"]]
    };
    if (this.id != null)
      this.setLoadData(this.id);
  }

  loadLookups() {
    this.yesNokeys = Object.values(this.yesNoEnum).filter(Number);
    this.reinsuranceStatusKeys = this.lookupService.getLookupsByTypeId(56);
    // this.firstNotificationStatusKeys = this.lookupService.getLookupsByTypeId(57);
    this.calculationTypeKeys = this.lookupService.getLookupsByTypeId(58);
  }

  setLoadData(claimId) {
    this.claimReinsuranceService.getClaimReinsuranceByClaimId(parseInt(claimId)).subscribe(res => {
      this.reinsuranceResetForm(claimId, res, false);
      this.isNewClaim = false;
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  reinsuranceResetForm(claimId, response: any, reload: boolean) {
    if (response == null || response.id == null) {
      this.resetReinsuranceForm(claimId);
    }
    else {
      this.claimReinsuranceService.formData = response;
      this.isAggregated = response.isAggregated;
      this.isMesoClaim = response.isMesoClaim;
      this.notificationDate = response.notificationDate;
      this.openDate = response.openDate;
      this.lineOfBusiness = response.lineOfBusiness;
      this.eFileURL = response.eFileURL;

      if (this.isAggregated == true) {

        let allNotificationStatusKeys = this.lookupService.getLookupsByTypeId(57);
        // this.aggregatedNotificationStatusKeys = allNotificationStatusKeys.filter(({ id }) => id != 1313);
        if (this.claimReinsuranceService.formData.claimStatusId == 23) {  //ClaimStatus- Open
          this.aggregatedNotificationStatusKeys = allNotificationStatusKeys.filter(({ id }) => id != 1309 && id != 1310 && id != 1311 && id != 1312 && id != 1313 && id != 1314);
          if (this.claimReinsuranceService.formData.aggregatedNotificationStatusId == null) {
            this.claimReinsuranceService.formData.aggregatedNotificationStatusId = 1307;
          }
        }
        else if (this.claimReinsuranceService.formData.claimStatusId == 25 || this.claimReinsuranceService.formData.claimStatusId == 1318) { //ClaimStatus- Re-Open OR Settled RI-OS
          this.aggregatedNotificationStatusKeys = allNotificationStatusKeys.filter(({ id }) => id != 1313);
          if (this.claimReinsuranceService.formData.aggregatedNotificationStatusId == null) {
            this.claimReinsuranceService.formData.aggregatedNotificationStatusId = 1307;
          }
        }
        else if (this.claimReinsuranceService.formData.claimStatusId == 24 || this.claimReinsuranceService.formData.claimStatusId == 26) {
          this.aggregatedNotificationStatusKeys = allNotificationStatusKeys.filter(({ id }) => id != 1313);
          if (this.claimReinsuranceService.formData.aggregatedNotificationStatusId == null) {
            this.claimReinsuranceService.formData.aggregatedNotificationStatusId = 1307;
          }
          if (this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1306 || this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1309 || this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1314) {
            this.disableAggregatedNotificationStatusControl = true;
          }
        }
        /**First Notification – N/A Or Settlement Presentation – N/A Or Completed */
        if (this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1306 || this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1309 || this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1314) {
          this.disableAggregatedControls = true;
        }
        else {
          this.disableAggregatedControls = false;
        }
        if (this.claimReinsuranceService.formData.aggregatedNotes != null) {          this.aggregatedNotes = this.claimReinsuranceService.formData.aggregatedNotes;        }
        else {
          this.aggregatedNotes = [];
        }
        this.initialAggregatedNote();
      }

      if (this.claimReinsuranceService.formData.claimStatusId == 24 || this.claimReinsuranceService.formData.claimStatusId == 26) {
        this.isClaimSettled = true;
      }

      if (this.claimReinsuranceService.formData.reinsuranceBreakDownByYear == null) {
        this.claimReinsuranceService.formData.reinsuranceBreakDownByYear = [];
      }
      else {
        //this.claimReinsuranceService.formData.reinsuranceBreakDownByYear = response.reinsuranceBreakDownByYear;
        this.loadReinsuranceBreakDownByYearData();
      }


      //if (this.claimReinsuranceService.formData.reinsuranceBreakDownByYear.length > 0) {
      //  this.TotalExposureDays();
      //  this.TotalShareExposurePct();
      //  this.TotalShareIncurred();
      //}

      if (response.reinsuranceRecoverables == null) {
        this.claimReinsuranceService.formData.reinsuranceRecoverables = [];
        this.reinsuranceRecoverables = [];
      }
      else {
        this.claimReinsuranceService.formData.reinsuranceRecoverables = response.reinsuranceRecoverables;
        this.reinsuranceRecoverables = response.reinsuranceRecoverables;
      }

      const ClaimReinsuranceFetchedData = JSON.parse(JSON.stringify(this.claimReinsuranceService.formData));
      this.originalClaimReinsuranceData = { ...ClaimReinsuranceFetchedData };

      this.refreshRecoverablesGrid();

      if (this.claimReinsuranceService.formData.reinsuranceRecoverables.length > 0) {
        this.TotalproRataCal();
        this.TotalproRataPlus();
        this.CalculateTotalOfRecoverableFields();
        this.ProRataCalcProRataPlusRows();
        this.TotalOfTotalCalc();
      }
    }
  }

  loadReinsuranceBreakDownByYearData() {
   // debugger;
    this.claimReinsuranceService.getReinsuranceBreakDownByYearByClaimId(this.id)
      .subscribe(response => {
        this.claimReinsuranceService.formData.reinsuranceBreakDownByYear = [];
        this.claimReinsuranceService.formData.reinsuranceBreakDownByYear = response.reinsuranceBreakDownByYear;
        //debugger;

        if (this.claimReinsuranceService.formData.reinsuranceBreakDownByYear.length > 0) {
          this.TotalExposureDays();
          this.TotalShareExposurePct();
          this.TotalShareIncurred();
        }
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        });
  }

  TotalExposureDays() {
    this.exposureDaysSum = _.sumBy(this.claimReinsuranceService.formData.reinsuranceBreakDownByYear, function (reinsuranceBreakDownByYear) {
      return reinsuranceBreakDownByYear.exposureDays;
    });
  }
  TotalShareExposurePct() {
    this.shareExposurePctSum = _.sumBy(this.claimReinsuranceService.formData.reinsuranceBreakDownByYear, function (reinsuranceBreakDownByYear) {
      return reinsuranceBreakDownByYear.shareExposurePct;
    });
    this.shareExposurePctSum = Math.round(this.shareExposurePctSum);
  }
  TotalShareIncurred() {
    this.shareIncurredSum = _.sumBy(this.claimReinsuranceService.formData.reinsuranceBreakDownByYear, function (reinsuranceBreakDownByYear) {
      return reinsuranceBreakDownByYear.shareIncurred;
    });
  }

  TotalproRataCal() {
    this.proRataCalcSum = _.sumBy(this.claimReinsuranceService.formData.reinsuranceRecoverables, function (reinsuranceRecoverables) {
      return reinsuranceRecoverables.proRataCalc;
    });
  }

  TotalproRataPlus() {
    if (this.isMesoClaim && this.lineOfBusiness == 146) {
      this.proRataPlusSum = _.sumBy(this.claimReinsuranceService.formData.reinsuranceRecoverables, function (reinsuranceRecoverables) {
        if (reinsuranceRecoverables.reinsurerId == 4 || reinsuranceRecoverables.reinsurerId == 19 || reinsuranceRecoverables.reinsurerId == 35
          || reinsuranceRecoverables.reinsurerId == 40 || reinsuranceRecoverables.reinsurerId == 50 || reinsuranceRecoverables.reinsurerId == 74) {
          return reinsuranceRecoverables.proRataPlus;
        }
      });
    }
  }

  TotalOfTotalCalc() {

    //var isMesoClaim1 = this.isMesoClaim;
    //var lineOfBusiness1 = this.lineOfBusiness;

    //this.totalCalcProRataSum = 0;
    //this.claimReinsuranceService.formData.reinsuranceRecoverables.forEach(item => {
    //  if (!((isMesoClaim1 && lineOfBusiness1 == 146) && (item.reinsurerId == 4 || item.reinsurerId == 19 || item.reinsurerId == 35 || item.reinsurerId == 40 || item.reinsurerId == 50 || item.reinsurerId == 74))) {
    //    return this.totalCalcProRataSum = this.totalCalcProRataSum + item.proRataCalc;
    //  }

    //});

    //this.totalCalcProRataPlusSum = 0;
    //this.claimReinsuranceService.formData.reinsuranceRecoverables.forEach(item => {
    //  if ((isMesoClaim1 && lineOfBusiness1 == 146) && (item.reinsurerId == 4 || item.reinsurerId == 19 || item.reinsurerId == 35 || item.reinsurerId == 40 || item.reinsurerId == 50 || item.reinsurerId == 74)) {
    //    return this.totalCalcProRataPlusSum = this.totalCalcProRataPlusSum + item.proRataPlus;
    //  }

    //});

    //this.totalCalcSum = 0;
    //this.totalCalcSum = (this.totalCalcProRataSum == undefined ? 0 : this.totalCalcProRataSum) + (this.totalCalcProRataPlusSum == undefined ? 0 : this.totalCalcProRataPlusSum);


    this.totalCalcSum = _.sumBy(this.claimReinsuranceService.formData.reinsuranceRecoverables, function (reinsuranceRecoverables) {
      return reinsuranceRecoverables.proRataCalcproRataPlus;
    });

  }

  ProRataCalcProRataPlusRows() {
    //debugger;
    var isMesoClaim1 = this.isMesoClaim;
    var lineOfBusiness1 = this.lineOfBusiness;

    this.claimReinsuranceService.formData.reinsuranceRecoverables.forEach((item, i, array) => {
      if (!((isMesoClaim1 && lineOfBusiness1 == 146) && (item.reinsurerId == 4 || item.reinsurerId == 19 || item.reinsurerId == 35 || item.reinsurerId == 40 || item.reinsurerId == 50 || item.reinsurerId == 74))) {
        this.reinsuranceRecoverables[i].proRataCalcproRataPlus = (item.proRataCalc);
      }
    });

    this.claimReinsuranceService.formData.reinsuranceRecoverables.forEach((item, i, array) => {
      if ((isMesoClaim1 && lineOfBusiness1 == 146) && (item.reinsurerId == 4 || item.reinsurerId == 19 || item.reinsurerId == 35 || item.reinsurerId == 40 || item.reinsurerId == 50 || item.reinsurerId == 74)) {
        this.reinsuranceRecoverables[i].proRataCalcproRataPlus = (item.proRataPlus);
      }
    });

  }

  CalculateTotalOfRecoverableFields() {
    this.TotalAmountSubmitted();
    this.TotalAmountAgreed();
    this.TotalAmountReceived();
    this.TotalVariation();
  }

  TotalAmountSubmitted() {
    this.amountSubmittedSum = _.sumBy(this.reinsuranceRecoverables, function (reinsuranceRecoverables) {
      return reinsuranceRecoverables.amountSubmitted;
    });
  }

  TotalAmountAgreed() {
    this.amountAgreedSum = _.sumBy(this.reinsuranceRecoverables, function (reinsuranceRecoverables) {
      return reinsuranceRecoverables.totalAmountAgreed;
    });
  }

  TotalAmountReceived() {
    this.amountReceivedSum = _.sumBy(this.reinsuranceRecoverables, function (reinsuranceRecoverables) {
      return reinsuranceRecoverables.totalAmountReceived;
    });
  }

  TotalVariation() {
    this.variationSum = _.sumBy(this.reinsuranceRecoverables, function (reinsuranceRecoverables) {
      return reinsuranceRecoverables.variation;
    });
  }

  resetReinsuranceForm(id, reinsuranceForm?: NgForm) {
    if (reinsuranceForm = null)
      reinsuranceForm.resetForm();
    this.claimReinsuranceService.formData = {
      id: null,
      claimId: parseInt(id),
      riApplicable: false,
      reinsuranceStatusId: 0,
      reinsuranceStatuss: '',
      dateOfSettlement: null,
      claimStatusId: 0,
      claimStatuss: '',
      riExcluded: null,
      riCompleted: null,
      mmiPaid: null,
      mmiIncurred: null,
      mmiReserve: null,
      isMesoClaim: false,
      notificationDate: null,
      openDate: null,
      lineOfBusiness: 0,
      version: '',

      isAggregated: null,
      aggregatedNotificationStatusId: null,
      aggregatedNotificationStatusText: '',
      aggregatedFirstNotificationDate: null,
      aggregatedSettlementPresentationDateSubmitted: null,
      aggregatedDateAgreed: null,
      aggregatedUnderQuery: null,

      reinsuranceBreakDownByYear: [],
      reinsuranceRecoverables: [],
      aggregatedNotes: []
    };
  }

  editRecoverableDetail(reinsurerId, Index) {    this.vaildRecoverable = true;    this.disableFields();    this.isNotificationStatusCompletedAndAmountAgreedPresent = false;    this.showRecoverableEditPanelFlag = true;    this.itemIndex = Index;    this.recoverable = Object.assign({}, this.reinsuranceRecoverables.find(a => a.reinsurerId == reinsurerId));    this.selectedReinsurerId = reinsurerId;    if (this.claimReinsuranceService.formData.claimStatusId == 24 || this.claimReinsuranceService.formData.claimStatusId == 26) {
    }    else if (this.claimReinsuranceService.formData.claimStatusId == 25) {
      if (this.recoverable.notificationStatusId == 1314 && this.recoverable.totalAmountAgreed == null) {
        this.isNotificationStatusCompletedAndAmountAgreedPresent = false;
        this.enableFields();
      }
      else if (this.recoverable.notificationStatusId == 1314 && this.recoverable.totalAmountAgreed != null && this.isMMIUser == false) {
        this.isNotificationStatusCompletedAndAmountAgreedPresent = true;
        this.disableFields();
      }
      else if (this.recoverable.notificationStatusId == 1309) {        this.disableSettlementPresentationControls = true;
        this.disableAgreementControls = true;
      }
      else {
        this.enableFields();
      }
    }    else if (this.claimReinsuranceService.formData.claimStatusId == 23) {
      if (this.recoverable.firstNotificationStatusId == 1303) {
        this.disableFirstNotificationControls = true;
      }
      else if (this.recoverable.firstNotificationStatusId == 1304 || this.recoverable.firstNotificationStatusId == 1305) {
        this.disableFirstNotificationControls = false;
      }
    }    else if (this.claimReinsuranceService.formData.claimStatusId == 1318) {      if (this.recoverable.firstNotificationStatusId == 1304 || this.recoverable.firstNotificationStatusId == 1305) {        this.disableFirstNotificationControls = false;      }      if (this.recoverable.settlementPresentationStatusId == 1303) {        this.disableSettlementPresentationControls = true;
        this.disableAgreementControls = true;
      }      else if (this.recoverable.settlementPresentationStatusId == 1304) {        this.disableSettlementPresentationControls = false;
        this.disableAgreementControls = true;
      }      else if (this.recoverable.settlementPresentationStatusId == 1305) {        this.disableSettlementPresentationControls = false;
        this.disableAgreementControls = false;
      }    }    if (this.recoverable.recoverableInvoices != null) {      if (this.recoverable.recoverableInvoices.length == 0) {        this.recoverableInvoices = [];
      }      else {        this.recoverableInvoices = [...this.recoverable.recoverableInvoices];
      }      this.TotalRecoverableInvoicesinvoiceAmount();    }    if (this.recoverable.recoverableReceivables != null) {      if (this.recoverable.recoverableReceivables.length == 0) {        this.recoverableReceivables = [];
      }      else {        this.recoverableReceivables = [...this.recoverable.recoverableReceivables];
      }      this.TotalReceivableAmountReceived();    }    if (this.recoverable.notes != null) {      if (this.recoverable.notes.length == 0) {        this.notes = [];
      }      else {        //this.notes = this.recoverable.notes;
        this.notes = [...this.recoverable.notes];
      }    }    this.initialRecoverableNote();  }

  firstNotificationStatusChange() {
    if (this.recoverable.firstNotificationStatusId == 1303) {
      this.disableFirstNotificationControls = true;
      this.recoverable.firstNotificationDate = null;
      this.recoverable.reinsurerReference = '';
      //if (this.claimReinsuranceService.formData.claimStatusId == 1318) {
      //  this.recoverable.settlementPresentationStatusId = 1303;
      //  this.settlementPresentationStatusChange();
      //}
    }
    else if (this.recoverable.firstNotificationStatusId == 1304) {
      this.disableFirstNotificationControls = false;
      this.recoverable.firstNotificationDate = null;
      this.recoverable.reinsurerReference = '';
    }
    else if (this.recoverable.firstNotificationStatusId == 1305) {
      this.disableFirstNotificationControls = false;
    }
  }

  resetRecoverableFields() {
    this.recoverable.settlementPresentationDateSubmitted = null;
    if (this.isMesoClaim && this.lineOfBusiness == 146) {
      this.recoverable.calculationTypeId = null;
    }
    else {
      this.recoverable.calculationOverride = null;
    }
    this.recoverable.amountSubmitted = null;
    this.recoverable.settlementPresentationDateCreated = null;
    this.recoverable.dateAgreed = null;
    this.recoverable.totalAmountAgreed = null;
    this.recoverable.invoiceNo = '';
    // this.recoverable.dateReceived = null;
    this.recoverable.totalAmountReceived = null;
    this.recoverable.variation = null;
    this.recoverable.underQuery = false;
  }

  settlementPresentationStatusChange() {
    if (this.claimReinsuranceService.formData.claimStatusId == 1318 || this.claimReinsuranceService.formData.claimStatusId == 25) {
      if (this.recoverable.settlementPresentationStatusId == 1303 || this.recoverable.settlementPresentationStatusId == null) {
        this.disableFirstNotificationControls = true;
        this.recoverable.firstNotificationStatusId = 1303;
        this.recoverable.firstNotificationDate = null;
        this.disableSettlementPresentationControls = true;
        this.disableAgreementControls = true;
        this.resetRecoverableFields();
      }
      else if (this.recoverable.settlementPresentationStatusId == 1304) {
        this.disableSettlementPresentationControls = false;
        this.disableAgreementControls = true;
        this.resetRecoverableFields();
        if (this.recoverable.firstNotificationStatusId == 1303) {
          this.disableFirstNotificationControls = false;
          this.recoverable.firstNotificationStatusId = 1304;
        }
      }
      else if (this.recoverable.settlementPresentationStatusId == 1305) {
        this.disableSettlementPresentationControls = false;
        this.disableAgreementControls = false;
        if (this.recoverable.firstNotificationStatusId == 1303 || this.recoverable.firstNotificationStatusId == 1304) {
          this.disableFirstNotificationControls = false;
          this.recoverable.firstNotificationStatusId = 1305;
        }
      }
    }
  }

  cancelButton() {    // this.clearFields();
    this.showRecoverableEditPanelFlag = false;
    // this.itemIndex = null;
    this.selectedReinsurerId = null;
    this.initialRecoverable();
    this.refreshRecoverablesGrid();
    this.initialRecoverableNote();
    this.notes = [];
    this.destroyCreditNotesAndNotesGrid();
    // this.destroyNotesGrid();
    // this.refreshNotesGrid();
    this.recoverable = null;
  }

  clearFields() {    this.showRecoverableEditPanelFlag = false;    // this.initialRecoverable();  }

  disableFields() {
    this.disableFirstNotificationControls = true;
    this.disableSettlementPresentationControls = true;
    this.disableAgreementControls = true;
  }

  enableFields() {
    this.disableFirstNotificationControls = false;
    this.disableSettlementPresentationControls = false;
    this.disableAgreementControls = false;
  }

  initialRecoverable() {    this.recoverable = {      id: null,      reinsurerId: null,      reinsurerName: '',      proRataCalc: null,      proRataPlus: null,      notificationStatusId: null,      notificationStatusText: '',      firstNotificationStatusId: null,      firstNotificationDate: null,      reinsurerReference: '',      settlementPresentationStatusId: null,      settlementPresentationDateSubmitted: null,      calculationTypeId: null,      amountSubmitted: null,      settlementPresentationDateCreated: null,      amountAgreed: null,      dateAgreed: null,      totalAmountAgreed: null,      invoiceNo: '',      // dateReceived: null,      totalAmountReceived: null,      variation: null,      calculationOverride: false,
      underQuery: false,
      manuallySetToNA: false,      notes: [],      recoverableInvoices: [],      recoverableReceivables: [],      proRataCalcproRataPlus: null    };  }

  addNotes() {
    if (this.addNoteValidation()) {
      this.notes.push(this.reinsuranceNote);
      this.initialRecoverableNote();
    }
  }

  initialRecoverableNote() {
    this.reinsuranceNote = {
      id: null,
      riRecoverableId: this.recoverable.id,
      userId: this.securityService.getUserData().id.toString() + " - " + this.securityService.getUserData().claimsHandlers,
      isNewAdded: true,
      comments: '',
      notesDate: new Date()
    };
  }

  addNoteValidation() {
    this.isNoteValid = true;
    if (this.reinsuranceNote.comments == '') {
      return this.isNoteValid = false;
    }
    return this.isNoteValid;
  }

  calculationTypeChange() {    if (this.recoverable.calculationTypeId != null) {      if (this.recoverable.calculationTypeId == 1315) {        this.recoverable.amountSubmitted = this.recoverable.proRataCalc;      }      else if (this.recoverable.calculationTypeId == 1316) {        this.recoverable.amountSubmitted = this.recoverable.proRataPlus;      }      else if (this.recoverable.calculationTypeId == 1317) {        this.recoverable.amountSubmitted = 0;      }    }    else {      this.recoverable.amountSubmitted = 0;    }  }

  calculationOverrideChange() {
    if (this.recoverable.calculationOverride != null) {
      if (this.recoverable.calculationOverride.toString() == "true") {        this.recoverable.amountSubmitted = null;      }      else if (this.recoverable.calculationOverride.toString() == "false") {        this.recoverable.amountSubmitted = this.recoverable.proRataCalc;      }
    }
    else {      this.recoverable.amountSubmitted = null;    }
  }
  validateFirstNotificationStatusAndDate() {    this.vaildRecoverable = true;
    //if (this.recoverable.firstNotificationStatusId == 1303) {
    //  this.notificationService.printWarningMessage("First Notification Status can not be set to N/A as Recoverables are present.");
    //  this.vaildRecoverable = false;
    //}    
    if (this.recoverable.firstNotificationStatusId == 1305 && this.recoverable.firstNotificationDate == null) {
      this.notificationService.printWarningMessage("Please enter First Notification Date");
      this.vaildRecoverable = false;
    }
    else if (this.recoverable.firstNotificationStatusId == 1305 && this.recoverable.firstNotificationDate != null) {
      let lFirstNotificationDate = new Date(this.recoverable.firstNotificationDate);
      let lNotificationDate = new Date(this.notificationDate);
      let lOpenDate = new Date(this.openDate);
      if ((lFirstNotificationDate.setHours(0, 0, 0, 0) >= lNotificationDate.setHours(0, 0, 0, 0)) && (lFirstNotificationDate.setHours(0, 0, 0, 0) >= lOpenDate.setHours(0, 0, 0, 0))) {
      }
      else {
        this.notificationService.printWarningMessage("'First Notification Date' should be equal to or greater than the greater of 'Open Date' or 'Notification Date'");
        this.vaildRecoverable = false;
      }
    }
    return this.vaildRecoverable;
  }  validateSettlementPresentationStatusAndDate() {    this.vaildRecoverable = true;
    if (this.recoverable.settlementPresentationStatusId != null && this.recoverable.settlementPresentationStatusId == 1305) {
      if (this.recoverable.settlementPresentationStatusId == 1305 && this.recoverable.settlementPresentationDateSubmitted == null) {
        this.notificationService.printWarningMessage("Please enter Settlement Presentation Date Submitted");
        return this.vaildRecoverable = false;
      }
      if (this.recoverable.settlementPresentationStatusId == 1305 && this.recoverable.settlementPresentationDateSubmitted != null) {
        let lSettlementPresentationDateSubmitted = new Date(this.recoverable.settlementPresentationDateSubmitted);
        let lNotificationDate = new Date(this.notificationDate);
        if (lSettlementPresentationDateSubmitted.setHours(0, 0, 0, 0) >= lNotificationDate.setHours(0, 0, 0, 0)) {
          this.recoverable.firstNotificationStatusId = 1305;
          if (this.recoverable.firstNotificationDate == null) {
            this.recoverable.firstNotificationDate = this.recoverable.settlementPresentationDateSubmitted;
          }
        }
        else {
          this.notificationService.printWarningMessage("'Settlement Presentation Date Submitted' should be equal to or greater than 'Settled RI O/S Date'");
          return this.vaildRecoverable = false;
        }
      }
      if (this.recoverable.settlementPresentationStatusId == 1305 && this.recoverable.settlementPresentationDateSubmitted != null && this.vaildRecoverable) {
        if (this.isMesoClaim && this.lineOfBusiness == 146 && ((this.recoverable.calculationTypeId == null) || (this.recoverable.amountSubmitted == null) || (this.recoverable.amountSubmitted <= 0))) {
          return this.vaildRecoverable = false;
        }
        if (!this.isMesoClaim && ((this.recoverable.calculationOverride == null) || (this.recoverable.amountSubmitted == null) || (this.recoverable.amountSubmitted <= 0))) {
          return this.vaildRecoverable = false;
        }

        //if (this.recoverable.dateAgreed != null && ((this.recoverable.amountAgreed == null) || (this.recoverable.amountAgreed <= 0))) {
        //  return this.vaildRecoverable = false;
        //}
        //if (this.recoverable.totalAmountAgreed == null && this.recoverable.totalAmountAgreed > 0) {
        //  return this.vaildRecoverable = false;
        //}
        //else if (this.recoverable.dateAgreed != null && this.recoverable.amountAgreed > 0) {
        //  let lSettlementPresentationDateSubmitted = new Date(this.recoverable.settlementPresentationDateSubmitted);
        //  let lDateAgreed = new Date(this.recoverable.dateAgreed);
        //  if (lDateAgreed.setHours(0, 0, 0, 0) < lSettlementPresentationDateSubmitted.setHours(0, 0, 0, 0)) {
        //    this.notificationService.printWarningMessage("'Date Agreed' should be equal to or greater than 'Settlement Presentation Date Submitted'");
        //    return this.vaildRecoverable = false;
        //  }
        //}

        //if ((this.recoverable.totalAmountReceived == null) || (this.recoverable.totalAmountReceived <= 0)) {
        //  return this.vaildRecoverable = false;
        //}
        //else if (this.recoverable.dateReceived == null && this.recoverable.amountReceived > 0) {
        //  return this.vaildRecoverable = false;
        //}
        //else if (this.recoverable.dateReceived != null && this.recoverable.amountReceived > 0 && this.recoverable.dateAgreed != null) {
        //  let lDateReceived = new Date(this.recoverable.dateReceived);
        //  let lDateAgreed = new Date(this.recoverable.dateAgreed);
        //  if (lDateReceived.setHours(0, 0, 0, 0) < lDateAgreed.setHours(0, 0, 0, 0)) {
        //    this.notificationService.printWarningMessage("'Date Received' should be equal to or greater than 'Date Agreed'");
        //    return this.vaildRecoverable = false;
        //  }
        //}
        //else if (this.recoverable.dateReceived != null && this.recoverable.amountReceived > 0 && this.recoverable.dateAgreed == null) {
        //  let lSettlementPresentationDateSubmitted = new Date(this.recoverable.settlementPresentationDateSubmitted);
        //  let lDateReceived = new Date(this.recoverable.dateReceived);
        //  if (lDateReceived.setHours(0, 0, 0, 0) < lSettlementPresentationDateSubmitted.setHours(0, 0, 0, 0)) {
        //    this.notificationService.printWarningMessage("'Date Received' should be equal to or greater than 'Settlement Presentation Date Submitted'");
        //    return this.vaildRecoverable = false;
        //  }
        //}

        if (this.claimReinsuranceService.formData.dateOfSettlement == null) {
          this.notificationService.printWarningMessage("'Date of Settlement (Claim)' is blank. Please save date of settlement (claim) first then update the Settlement Presentation Status to 'Completed'");
          return this.vaildRecoverable = false;
        }

      }

    }
    else {
      this.vaildRecoverable = this.validateFirstNotificationStatusAndDate();
    }
    return this.vaildRecoverable;
  }  validateReinsurarerRecoverable() {    this.vaildRecoverable = true;
    if (this.claimReinsuranceService.formData.claimStatusId == 23) {
      this.vaildRecoverable = this.validateFirstNotificationStatusAndDate();
    }
    if (this.claimReinsuranceService.formData.claimStatusId == 1318 || this.claimReinsuranceService.formData.claimStatusId == 25) {
      this.vaildRecoverable = this.validateSettlementPresentationStatusAndDate();
    }

    return this.vaildRecoverable;
  }

  businessRules() {
    if (this.recoverable.firstNotificationDate != null) {
      this.recoverable.firstNotificationStatusId = 1305;
    }
    if (this.claimReinsuranceService.formData.claimStatusId == 1318 || this.claimReinsuranceService.formData.claimStatusId == 25) {
      if (this.recoverable.amountSubmitted > 0 && ((this.recoverable.firstNotificationStatusId != 1305) || (this.recoverable.settlementPresentationStatusId != 1305))) {
        this.recoverable.firstNotificationStatusId = 1305;
        this.recoverable.settlementPresentationStatusId = 1305;
      }
      if (this.recoverable.settlementPresentationDateSubmitted != null) {
        this.recoverable.settlementPresentationStatusId = 1305;
      }
    }
  }

  updateRecoverableRecord() {
    this.businessRules();
    if (this.validateReinsurarerRecoverable()) {
      if (this.claimReinsuranceService.formData.claimStatusId == 23) {
        if (this.recoverable.firstNotificationStatusId == 1303) {
          this.recoverable.manuallySetToNA = true;
        }
        else {
          this.recoverable.manuallySetToNA = false;
        }
      }
      if (this.claimReinsuranceService.formData.claimStatusId != 23) {
        if (this.recoverable.settlementPresentationStatusId == 1303) {
          this.recoverable.manuallySetToNA = true;
        }
        else {
          this.recoverable.manuallySetToNA = false;
        }
      }
      if (this.recoverableInvoices != null && this.recoverableInvoices.length > 0) {
        this.recoverable.recoverableInvoices = this.recoverableInvoices;
      }
      debugger
      if (this.recoverableReceivables != null && this.recoverableReceivables.length >= 0) {
        this.recoverable.recoverableReceivables = this.recoverableReceivables;
      }
      this.recoverable.notes = this.notes;
      //if (this.itemIndex == null) {      //  alert('itemIndex is null. something is wrong.')
      //}      if (this.selectedReinsurerId == null) {        alert('something is wrong.')
      }      else {        // this.reinsuranceRecoverables[this.itemIndex] = this.recoverable;        // this.itemIndex = null;        let ItemIndex = this.reinsuranceRecoverables.findIndex(a => a.reinsurerId == this.selectedReinsurerId);        this.reinsuranceRecoverables[ItemIndex] = this.recoverable;        this.selectedReinsurerId = null;      }
      this.showRecoverableEditPanelFlag = false;
      this.selectedReinsurerId = null;
      this.initialRecoverable();
      this.refreshRecoverablesGrid();
      this.recoverableInvoices = [];
      // this.destroyCreditNotesGrid();

      this.initialRecoverableNote();
      this.notes = [];
      // this.destroyNotesGrid();
      this.destroyCreditNotesAndNotesGrid();
      this.CalculateTotalOfRecoverableFields();
      this.recoverable = null;
    }
  }

  destroyCreditNotesAndNotesGrid() {
    if (this.recoverableInvoicesTable || this.reinsuranceNotesTable) {
      if (this.datatableElements.toArray()[2] && this.datatableElements.toArray()[2].dtInstance) {
        this.datatableElements.toArray()[2].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      }
      if (this.datatableElements.toArray()[3] && this.datatableElements.toArray()[3].dtInstance) {
        this.datatableElements.toArray()[3].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      }
    }
  }


  destroyCreditNotesGrid() {
    if (this.recoverableInvoicesTable) {
      var abc = this.datatableElements.toArray();
      if (this.datatableElements.toArray()[2] && this.datatableElements.toArray()[2].dtInstance) {
        this.datatableElements.toArray()[2].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      }
    }
  }

  destroyNotesGrid() {
    if (this.reinsuranceNotesTable) {
      if (this.datatableElements.toArray()[2] && this.datatableElements.toArray()[2].dtInstance) {
        this.datatableElements.toArray()[2].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
      }
    }
  }

  refreshRecoverablesGrid() {    if (this.recoverableTable) {
      if (this.datatableElements.toArray()[1] && this.datatableElements.toArray()[1].dtInstance) {
        this.datatableElements.toArray()[1].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtRecoverableTrigger.next();
        });
      }
      else {
        this.dtRecoverableTrigger.next();
      }
      this.isRecoverableGridLoaded = true;
    }  }

  getReinsurerInvoiceRecords(content) {
    this.initializeRecoverableInvoice();
    this.modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
  }

  focusOutFunction(totalAmountAgreed: number): void {
    totalAmountAgreed = +totalAmountAgreed;
    if (totalAmountAgreed != null && totalAmountAgreed > 0 && this.recoverable != null) {
      if (this.recoverableInvoices.length == 0) {
        if (totalAmountAgreed == this.recoverable.amountSubmitted) {
          this.recoverable.amountAgreed = totalAmountAgreed;
          this.recoverable.dateAgreed = new Date();
        }
        else if (totalAmountAgreed != this.recoverable.amountSubmitted) {
          if (this.recoverable.amountSubmitted == this.recoverable.amountAgreed) {
            this.recoverable.amountAgreed = null;
            this.recoverable.dateAgreed = null;
          }
          this.initializeRecoverableInvoice();
          this.recoverableInvoice.amountAgreed = totalAmountAgreed;
          this.recoverableInvoice.invoiceAmount = totalAmountAgreed - this.recoverable.amountSubmitted;
          this.recoverableInvoices.push(this.recoverableInvoice);
        }
      }
      else if (this.recoverableInvoices.length == 1 && this.recoverableInvoices[0].id == null) {
        var unsavedRecoverableInvoice = this.recoverableInvoices.find(a => a.id == null);
        if (totalAmountAgreed == this.recoverable.amountSubmitted) {
          this.recoverable.amountAgreed = totalAmountAgreed;
          this.recoverable.dateAgreed = new Date();
          this.recoverableInvoices = [];
        }
        else {
          if (this.recoverable.amountSubmitted != this.recoverable.amountAgreed) {
            this.recoverable.amountAgreed = null;
            this.recoverable.dateAgreed = null;
          }
          unsavedRecoverableInvoice.amountAgreed = totalAmountAgreed;
          unsavedRecoverableInvoice.invoiceAmount = totalAmountAgreed - this.recoverable.amountSubmitted;
        }
      }
      else {
        var latestRecoverableInvoice = this.recoverableInvoices.filter(i => i.id > 0).reduce(function (r, a) {
          return r.agreedDate > a.agreedDate ? r : a;
        });
        var unsavedRecoverableInvoice = this.recoverableInvoices.find(a => a.id == null);
        if (unsavedRecoverableInvoice == undefined) {
          if (totalAmountAgreed != latestRecoverableInvoice.amountAgreed) {
            this.initializeRecoverableInvoice();
            this.recoverableInvoice.amountAgreed = totalAmountAgreed;
            this.recoverableInvoice.invoiceAmount = totalAmountAgreed - latestRecoverableInvoice.amountAgreed;
            this.recoverableInvoices.push(this.recoverableInvoice);
          }
        }
        else {
          unsavedRecoverableInvoice.amountAgreed = totalAmountAgreed;
          unsavedRecoverableInvoice.invoiceAmount = totalAmountAgreed - latestRecoverableInvoice.amountAgreed;
        }
      }

      this.TotalRecoverableInvoicesinvoiceAmount();
    }
    else {
      // alert('something went wrong');
    }
  }

  TotalRecoverableInvoicesinvoiceAmount() {
    this.recoverableInvoicesinvoiceAmountSum = _.sumBy(this.recoverableInvoices, function (Invoices) {
      return Invoices.invoiceAmount;
    });
  }

  initializeRecoverableInvoice() {
    this.recoverableInvoice = {
      id: null,
      riRecoverableId: this.recoverable.id,
      amountAgreed: null,
      invoiceAmount: null,
      invoiceNo: '',
      agreedDate: new Date(),
      isNewAdded: true,
    }
  }

  getReinsurerReceivablesRecords(content) {
    if (this.recoverable.id > 0) {
      //this.claimReinsuranceService.getRecoverableReceivable(this.recoverable.id).subscribe(response => {
      //  this.recoverableReceivables = response;
      //  if (response != null) {
      //    this.TotalReceivableAmountReceived();
      //    this.initialReceivable();
      //    this.modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
      //  }
      //},
      //  error => {
      //    this.notificationService.printErrorMessage(error.message);
      //  }
      //)
      this.TotalReceivableAmountReceived();
      this.initialReceivable();
      this.modalRef = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
    }
  }

  addReceivable() {
    if (this.validateReceivable()) {
      //this.claimReinsuranceService.saveRecoverableReceivable(this.recoverableReceivable).subscribe(response => {
      //  this.recoverableReceivables = response;
      //  if (response != null) {
      //      this.TotalReceivableAmountReceived();
      //      this.initialReceivable();            
      //    }
      //  },
      //    error => {
      //      this.notificationService.printErrorMessage(error.message);
      //    }
      //  )

      this.recoverableReceivables.push(this.recoverableReceivable);
      this.TotalReceivableAmountReceived();
      this.initialReceivable();
    }
  }

  //editReceivableDetail(indexItem) {
  //  //this.receivableItemId = null;
  //  //this.recoverableReceivable = Object.assign({}, this.recoverableReceivables.find(a => a.id == id));
  //  //this.receivableItemId = this.recoverableReceivable.id;

  //  this.recoverableReceivable = Object.assign({}, this.recoverableReceivables[indexItem]);
  //  this.itemIndexReceivable = indexItem;
  //}

  cancelReceivableUpdate() {
    this.initialReceivable();
    this.isValidReceivable = true;
    // this.receivableItemId = null;
    // this.itemIndexReceivable = null;
  }

  //updateReceivable() {
  //  if (this.validateReceivable()) {
  //    //this.claimReinsuranceService.updateRecoverableReceivable(this.recoverableReceivable).subscribe(response => {
  //    //  this.recoverableReceivables = response;
  //    //  if (response != null) {
  //    //    this.TotalReceivableAmountReceived();
  //    //    this.initialReceivable();
  //    //    this.receivableItemId = null;
  //    //  }
  //    //},
  //    //  error => {
  //    //    this.notificationService.printErrorMessage(error.message);
  //    //  }
  //    //)

  //    this.recoverableReceivable.isRecordUpdated = true;
  //    this.recoverableReceivables[this.itemIndexReceivable] = this.recoverableReceivable;
  //    this.itemIndexReceivable = null;
  //    this.TotalReceivableAmountReceived();
  //    this.initialReceivable();
  //  }
  //}

  //deleteReceivableDetail(indexItem) {
  //  if (confirm("Are you sure you want to delete this item?")) {
  //    //this.claimReinsuranceService.deleteRecoverableReceivable(id).subscribe(response => {
  //    //  this.recoverableReceivables = response;
  //    //  if (response != null) {
  //    //    this.TotalReceivableAmountReceived();
  //    //    this.initialReceivable();
  //    //  }
  //    //},
  //    //  error => {
  //    //    this.notificationService.printErrorMessage(error.message);
  //    //  }
  //    //)

  //    this.recoverableReceivables = this.recoverableReceivables.filter(function (place, index) {
  //      return index != indexItem
  //    });


  //    this.itemIndexReceivable = null;
  //    if (this.recoverableReceivables.length > 0) {
  //      this.TotalReceivableAmountReceived();
  //    }
  //    else {
  //      this.recoverable.totalAmountReceived = 0;
  //    }
  //  }
  //}

  initialReceivable() {
    this.recoverableReceivable = {
      id: null,
      riRecoverableId: this.recoverable.id,
      amountReceived: null,
      receivedDate: null,
      isNewAdded: true,
      isRecordUpdated: false
    };
  }

  validateReceivable() {
    this.isValidReceivable = true;
    if (this.recoverableReceivable.amountReceived == null || this.recoverableReceivable.amountReceived == 0 || this.recoverableReceivable.receivedDate == null) {
      return this.isValidReceivable = false;
    }
    return this.isValidReceivable;
  }

  TotalReceivableAmountReceived() {
    this.receivableAmountReceivedSum = _.sumBy(this.recoverableReceivables, function (receivables) {
      return receivables.amountReceived;
    });
    if (this.recoverableReceivables.length > 0) {
      this.setTotalAmountReceived();
    }
  }

  setTotalAmountReceived() {
    // if (this.receivableAmountReceivedSum != 0) {
    this.recoverable.totalAmountReceived = this.receivableAmountReceivedSum;
    // }
  }
  validateAggregatedReinsurance() {
    this.vaildAggregatedRecord = true;
    //this.isFormSubmitted = true;
    if (this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1308 && this.claimReinsuranceService.formData.aggregatedFirstNotificationDate == null) {
      this.notificationService.printWarningMessage("Please enter First Notification Date");
      this.vaildAggregatedRecord = false;
    }
    else if ((this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1311 || this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1314) &&
      (this.claimReinsuranceService.formData.aggregatedFirstNotificationDate == null ||
        this.claimReinsuranceService.formData.aggregatedSettlementPresentationDateSubmitted == null ||
        this.claimReinsuranceService.formData.dateOfSettlement == null)) {
      if (this.claimReinsuranceService.formData.aggregatedFirstNotificationDate == null) {
        this.notificationService.printWarningMessage("Please enter First Notification Date");
      }
      else if (this.claimReinsuranceService.formData.aggregatedSettlementPresentationDateSubmitted == null) {
        this.notificationService.printWarningMessage("Please enter Settlement Presentation Date Submitted");
      }
      else if (this.claimReinsuranceService.formData.dateOfSettlement == null) {
        this.notificationService.printWarningMessage("Please enter Date of Settlement (Claim)");
      }

      this.vaildAggregatedRecord = false;
    }
    else if (this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1312 &&
      (this.claimReinsuranceService.formData.aggregatedFirstNotificationDate == null || this.claimReinsuranceService.formData.aggregatedSettlementPresentationDateSubmitted == null ||
        this.claimReinsuranceService.formData.aggregatedDateAgreed == null || this.claimReinsuranceService.formData.dateOfSettlement == null)) {
      if (this.claimReinsuranceService.formData.aggregatedFirstNotificationDate == null) {
        this.notificationService.printWarningMessage("Please enter First Notification Date");
      }
      else if (this.claimReinsuranceService.formData.aggregatedSettlementPresentationDateSubmitted == null) {
        this.notificationService.printWarningMessage("Please enter Settlement Presentation Date Submitted");
      }
      else if (this.claimReinsuranceService.formData.aggregatedDateAgreed == null) {
        this.notificationService.printWarningMessage("Please enter Date Agreed");
      }
      else if (this.claimReinsuranceService.formData.dateOfSettlement == null) {
        this.notificationService.printWarningMessage("Please enter Date of Settlement (Claim)");
      }

      this.vaildAggregatedRecord = false;
    }
    else if ((this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1311 ||
      this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1310 ||
      this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1309 ||
      this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1308 ||
      this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1307 ||
      this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1306)
      && this.claimReinsuranceService.formData.aggregatedDateAgreed != null) {
      this.notificationService.printWarningMessage("Please remove Date Agreed");
      this.vaildAggregatedRecord = false;
    }
    else if ((this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1310 ||
      this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1309 ||
      this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1308 ||
      this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1307 ||
      this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1306)
      && this.claimReinsuranceService.formData.aggregatedSettlementPresentationDateSubmitted != null) {
      this.notificationService.printWarningMessage("Please remove Settlement Presentation Date Submitted");
      this.vaildAggregatedRecord = false;
    }
    else if ((this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1307 ||
      this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1306)
      && this.claimReinsuranceService.formData.aggregatedFirstNotificationDate != null) {
      this.notificationService.printWarningMessage("Please remove First Notification Date");
      this.vaildAggregatedRecord = false;
    }

    return this.vaildAggregatedRecord;
  }

  validateReinsurance() {
    let isValid: boolean = true;
    this.isFormSubmitted = true;

    let checkAnyRecoverableHasSettlementCompletedStatus: boolean = false;
    checkAnyRecoverableHasSettlementCompletedStatus = this.reinsuranceRecoverables.some(r => r.settlementPresentationStatusId === 1305);
    if (checkAnyRecoverableHasSettlementCompletedStatus && this.claimReinsuranceService.formData.dateOfSettlement == null) {
      this.notificationService.printWarningMessage("You cannot delete Date of Settlement (Claim) as one of the reinsurance recoverable record has a date present in 'Settlement Presentation Date Submitted' field. If you need to delete this date than please assure that all reinsurance recoverable records have got settlement presentation status other than 'Completed'.");
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return isValid = false;
    }

    if (this.claimReinsuranceService.formData.dateOfSettlement != null) {
      let lDateOfSettlement = new Date(this.claimReinsuranceService.formData.dateOfSettlement);
      let lNotificationDate = new Date(this.notificationDate);
      let lOpenDate = new Date(this.openDate);
      if ((lDateOfSettlement.setHours(0, 0, 0, 0) >= lNotificationDate.setHours(0, 0, 0, 0)) && (lDateOfSettlement.setHours(0, 0, 0, 0) >= lOpenDate.setHours(0, 0, 0, 0))) {
        return isValid = true;
      }
      else {
        this.notificationService.printWarningMessage("Date of Settlement (Claim) should be equal to or greater than the greater of Open Date or Notification Date");
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        return isValid = false;
      }
    }

    return isValid;
  }

  onSubmit(form) {
    try {

      if (this.isAggregated) {
        if (this.validateAggregatedReinsurance()) {
          //alert('call the service to save aggregated reinsurance');

          this.claimReinsuranceService.UpdateClaimReinsurance().subscribe(res => {
            this.notificationService.printSuccessMessage('Saved Successfully');
            this.setLoadData(this.id);
            this.isFormSubmitted = true;
            this.formValidityFlagLoaded.next();
          },
            error => {
              if (error.error.includes('You are about to save changes to this claim. Another user is also in the claim')) {
                this.notificationService.printHtmlErrorMessage(error.error);
              }
              else {
                this.notificationService.printErrorMessage(error.message);
              }
            })
        }
      }
      else {
        if (this.recoverable != undefined && this.recoverable != null) {
          let message = "You have unsaved changes on Form. Please update them to reinsurance recoverables Grid using update button. Or discard them using cancel button and then save.";
          this.showFormDirtyWarningMessage(message);
          return false;
        }

        this.destroyCreditNotesAndNotesGrid();
        if (this.validateReinsurance()) {
          this.claimReinsuranceService.formData.reinsuranceRecoverables = this.reinsuranceRecoverables;

          this.claimReinsuranceService.UpdateClaimReinsurance().subscribe(res => {
            this.notificationService.printSuccessMessage('Saved Successfully');
            this.setLoadData(this.id);
            this.itemIndex = null;
            this.isFormSubmitted = true;
            this.formValidityFlagLoaded.next();
          },
            error => {
              if (error.error.includes('You are about to save changes to this claim. Another user is also in the claim')) {
                this.notificationService.printHtmlErrorMessage(error.error);
              }
              else {
                this.notificationService.printErrorMessage(error.message);
              }
            })

        }
      }

    }
    catch (Error) {
      this.notificationService.printErrorMessage(Error.message);
    }
  }

  ngAfterViewChecked(): void {    if (!this.isRecoverableGridLoaded) {      this.refreshRecoverablesGrid();    }    //if (!this.isNotesGridLoaded) {    //  this.refreshNotesGrid();
    //}  }  ngOnDestroy(): void {    // Do not forget to unsubscribe the event    this.dtRecoverableTrigger.unsubscribe();    // this.dtNotesTrigger.unsubscribe();  }

  downloadReinsuranceDetails() {
    this.claimReinsuranceService.downloadReinsuranceDetails(this.id);
  }

  //downloadRIExportAll() {
  //  this.claimReinsuranceService.downloadRIExportAll(this.id);
  //}

  downloadRIExportProRataPlus() {
    this.claimReinsuranceService.downloadRIExportProRataPlus(this.id);
  }

  downloadRIExportProRata() {
    this.claimReinsuranceService.downloadRIExportProRata(this.id);
  }

  downloadRIExportProRataMeso() {
    this.claimReinsuranceService.downloadRIExportProRataMeso(this.id);
  }

  navigateToWorkflowComponent() {
    this.router.navigate(['/claims/' + this.policyId + '/' + this.id + '/add-workflow-item']);
  }
  transform(val: number): number {
    return Math.abs(val);
  }

  aggregatedNotificationStatusChange() {
    if (this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1309 && this.claimReinsuranceService.formData.aggregatedFirstNotificationDate != null) {
      this.disableAggregatedControls = true;
    }
    else if (this.claimReinsuranceService.formData.aggregatedNotificationStatusId == 1314 && this.claimReinsuranceService.formData.aggregatedFirstNotificationDate != null
      && this.claimReinsuranceService.formData.aggregatedSettlementPresentationDateSubmitted != null) {
      this.disableAggregatedControls = true;
    }
    else {
      this.disableAggregatedControls = false;
    }
  }

  addAggregatedNotes() {
    if (this.aggregatedNoteValidation()) {
      this.aggregatedNotes.push(this.aggregatedNote);
      this.claimReinsuranceService.formData.aggregatedNotes = this.aggregatedNotes;
      this.initialAggregatedNote();
    }
  }

  initialAggregatedNote() {
    this.aggregatedNote = {
      id: null,
      reinsuranceId: this.claimReinsuranceService.formData.id,
      userId: this.securityService.getUserData().id.toString() + " - " + this.securityService.getUserData().claimsHandlers,
      isNewAdded: true,
      comments: '',
      notesDate: new Date()
    };
  }

  aggregatedNoteValidation() {
    this.isAggregatedNoteValid = true;
    if (this.aggregatedNote.comments == '') {
      return this.isAggregatedNoteValid = false;
    }
    return this.isAggregatedNoteValid;
  }

  getEFile() {
    if (this.eFileURL != '') {
      this.goToLink(this.eFileURL);
    }
    else {
      if (confirm("Claim folder does not exist for this claim. Do you want to create.")) {
        this.createClaimFolder();
      }
    }
  }

  createClaimFolder() {
    let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimDetailService.createClaimFolder(claimId).subscribe(res => {
      if (res != null) {
        this.eFileURL = res.filepath;
        if (this.eFileURL != '') {
          this.goToLink(this.eFileURL);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  refresh(): void {
    if (confirm('Are you sure want to reset this page?')) {
      window.location.reload();
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DataSetupComponent } from './data-setup/data-setup.component';
import { EmailSetupComponent } from './email-setup/email-setup.component';
import { RoleGuardService as RoleGuard } from '../../shared/services/role-guard.service'; //BroadcastMessage
import { BroadcastMessageComponent } from './broadcast-message/broadcast-message.component';

const routes: Routes = [
  { path: 'data-setup', component: DataSetupComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1160' }},
  { path: 'email-setup', component: EmailSetupComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1162' } },
  { path: 'broadcast-message', component: BroadcastMessageComponent, canActivate: [RoleGuard], data: { userPermissionsId: '1160' } },
];

@NgModule({
  //declarations: [],
  //imports: [
  //  CommonModule
  //]
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }

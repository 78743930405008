import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CreditorService {


  private creditorUrl: string = "/api/creditor/";

  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.creditorUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.creditorUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.creditorUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.creditorUrl);
  }

  getCreditorById(id: number): any {
    let url = this.creditorUrl + "getCreditorById";

    return this.dataService.get(url, { id: id });
  }

  createCreditor(data): any {

    let url = this.creditorUrl + "createCreditor";

    return this.dataService.post(url, data);

  }

  updateCreditor(data): any {

    let url = this.creditorUrl + "updateCreditor";
    console.log(JSON.stringify(data));
    return this.dataService.putWithId(url, data);
  }

  getPolicyholderDetails(creditorNumber) {
    let url = this.creditorUrl + "getPolicyholderDetails";
    return this.dataService.get(url, { creditorNumber: creditorNumber });
  }

  checkPolicyHolderIsParentOfAnother(creditorId) {
    let url = this.creditorUrl + "getPolicyholderAssociationDetails";
    return this.dataService.get(url, { creditorId: creditorId });
  }

  createCreditorHolderFolder(creditorID,policyHolderID,policyHolderName) : any{
    let url = this.creditorUrl + "createCreditorHolderFolder";
    return this.dataService.get(url, { creditorID: creditorID, policyHolderID: policyHolderID, policyHolderName: policyHolderName });
  }

  getCreditorEFileUrl(creditorID, policyHolderID, policyHolderName): any {
    let url = this.creditorUrl + "getCreditorEFileUrl";
    return this.dataService.get(url, { creditorID: creditorID, policyHolderID: policyHolderID, policyHolderName: policyHolderName });
  }
  
}


import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TPLayerService } from '../tp-layer/tp-layer.service';
import { DataTableDirective } from 'angular-datatables';
import { ITPLayer } from './tp-layer.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tp-layer',
  templateUrl: './tp-layer.component.html',
  styleUrls: ['./tp-layer.component.css']
})
export class TpLayerComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('tpLayerDetailsTable') tpLayerDetailsTable: ElementRef;
  public dtOptions: any = {};

  public dtTrigger: Subject<any>;
  TPLayerResult: ITPLayer[] = [];
  claimId: string;
  datahave: boolean = false;
  isGridLoaded: boolean = false;

  constructor(private tpLayerService: TPLayerService) {
    this.dtTrigger = new Subject();
  }

  ngOnInit(): void {
    this.initialiseDataTable();
    this.bindTPLayerData();
  }

  bindTPLayerData() {
    this.tpLayerService.getTPLayerDetails().subscribe(res => {
      if (res == null) {
        this.TPLayerResult = [];
      }
      else if (res != null) {
        //debugger
        this.TPLayerResult = res;
      }
      this.refreshGrid();
    });
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    if (this.tpLayerDetailsTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  initialiseDataTable(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [
      ],
      columnDefs: [
        {
          targets: 0,
          visible: false,
          searchable: false
        },

      ], order: [[0, 'asc']]
    };
  }

}

import { Component, OnDestroy, OnInit, ViewChild, AfterViewChecked, ElementRef, HostListener, Inject } from '@angular/core';
import { ClaimLitigationService } from './claim.litigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LookupService } from '../../../shared/services/lookup.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { enumYesNo } from '../../../shared/models/common.enum';
import { IlitigationNote } from './litigation-note.model';
import { Ilitigation } from './litigation.model'
import { Subject, Observable } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { resolveAny } from 'dns';
import { createTrue } from 'typescript';
import { NotificationService } from '../../../shared/services/notification.service';
import { ComponentCanDeactivate } from '../../../shared/guard/unsaved-changes.guard';
import { NgbDateCustomParserFormatter, CustomAdapter } from '../../../shared/components/datepicker/dateformat';
import { NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { SecurityService } from '../../../shared/services/security.service';
import { ClaimDetailService } from '../claim-detail/claim-detail.service';
import { ClaimComponent } from '../claim.component';

@Component({
  selector: 'app-litigation',
  templateUrl: './litigation.component.html',
  styleUrls: ['./litigation.component.css'],
  //providers: [{ provide: NgbDateAdapter, useClass: CustomAdapter },
  //{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }]
})
export class LitigationComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked, ComponentCanDeactivate {

  @ViewChild('form', null) form: NgForm;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('litigationTable') apportionmentPoliciesTable: ElementRef;
  dtOptions: DataTables.Settings = {};
  isValid: boolean = true;
  isformValid: boolean = true;
  yesNokeys: any[];
  yesNoEnum = enumYesNo;
  approvalTrialKeys: any[];
  trialCausekeys: any[];
  litigationCausekeys: any[];
  solicitorInstructedkeys: any[];
  approcedTrialkeys: any[];
  outcomeCodekeys: any[];
  loading: boolean = false;
  litigationNote: IlitigationNote;
  isGridLoaded: boolean = false;
  id: string;
  isMMIUser: boolean = false;
  hideWorkflowButton: boolean = true;

  dtTrigger = new Subject();

  saveMethod: any;
  isFormSubmitted: boolean = true;
  formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean { return false; }
  policyId: number;
  isSettledButtonClicked: boolean = false;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    this.saveMethod = this.onSubmit;
    let hasPendingChanges: boolean = !this.form.dirty;
    return hasPendingChanges;
  }

  constructor(private litigationService: ClaimLitigationService,
    private currentRoute: ActivatedRoute,
    private lookupService: LookupService,
    private router: Router,
    private notificationService: NotificationService,
    private claimDetailService: ClaimDetailService,
    securityService: SecurityService, @Inject(ClaimComponent) private parent: ClaimComponent) {
    super(securityService, 1147);
    this.loadLookups();
  }
  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.id = this.currentRoute.parent.snapshot.paramMap.get('claimId');
    this.policyId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('policyId'));
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
      this.hideWorkflowButton = (this.isMMIUser) ? false : true
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      columnDefs: [
        { type: 'datetime-uk', "targets": 0 }
      ],
      order: [[0, 'desc']]
    };

    if (this.id != null)
      this.setLoadData(this.id);

    this.loading = false;
    this.dtTrigger.next();
    this.refreshGrid();
    if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
      this.isReadOnlyMode = true;
    }

    this.parent.claimStatusId$.subscribe(x => {
      if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
        this.isReadOnlyMode = true;
      }
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  initialLitigationNote() {
    this.litigationNote = {
      id: null,
      litigationID: this.litigationService.formData.id,
      handlerID: this.securityService.getUserData().id.toString() + " - " + this.securityService.getUserData().claimsHandlers,
      isNewAdded: true,
      notesComment: '',
      notesDate: new Date()
    };
  }

  resetForm(id, eFileURL, form?: NgForm) {
    if (form = null)
      form.resetForm();
    this.litigationService.formData = {
      id: null,
      claimId: parseInt(id),
      issueDate: null,
      trialDate: null,
      serviceDate: null,
      avoidable: null,
      trialReviewCompleteDate: null,
      causeCodeID: null,
      litigationCauseID: null,
      trialApproved: null,
      instructedSolicitorID: null,
      outcomeCodeId: null,
      courtsCaseReference: '',
      litigationNotes: [],
      eFileURL: eFileURL,
      handlingOrganisationId: null,
      mmiKeyContactId: null,
      version: ''
    };
  }
  loadLookups() {

    this.yesNokeys = Object.values(this.yesNoEnum).filter(Number);
    this.approvalTrialKeys = this.lookupService.getLookupsByTypeId(45);
    this.trialCausekeys = this.lookupService.getLookupsByTypeId(15);
    this.litigationCausekeys = this.lookupService.getLookupsByTypeId(9);
    this.solicitorInstructedkeys = this.lookupService.getLookupsByTypeId(16);
    this.outcomeCodekeys = this.lookupService.getLookupsByTypeId(17);
  }

  setLoadData(claimId) {
    this.litigationService.getLitigationByClaimId(parseInt(claimId)).subscribe(res => {
      if (res == null || res.id == null) {
        let eFileURL = res == null ? null : res.eFileURL;
        this.resetForm(claimId, eFileURL);
      }
      else
        this.litigationService.formData = res;
      this.initialLitigationNote();
      this.refreshGrid();
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }
  getDateOnly(date: Date) {
    if (typeof date == 'string')
      var newDate = new Date(date);
    else
      var newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }
  validateForm(formData: Ilitigation) {
    //debugger;
    this.isformValid = true;
    this.isFormSubmitted = true;
    //if (formData.issueDate == null && formData.trialDate == null && formData.serviceDate == null && formData.avoidable == null
    //  && formData.trialReviewCompleteDate == null && formData.causeCodeID == null && formData.litigationCauseID == null && formData.trialApproved == null
    //  && formData.instructedSolicitorID == null && formData.outcomeCodeId == null && this.litigationService.formData.litigationNotes.length == 0) {

    //  this.notificationService.printWarningMessage("Please provide at least one field"); 
    //  this.isformValid = false;
    //  return false;
    //}
    let letterOfclaimDate = this.litigationService.formData.letterOfClaimDate;
    if (formData.issueDate != null) {
      if ((this.getDateOnly(formData.issueDate) > this.getDateOnly(new Date()))
        || (letterOfclaimDate != null && this.getDateOnly(formData.issueDate) < this.getDateOnly(letterOfclaimDate))) {
        this.notificationService.printWarningMessage("Issue date equal to or earlier than current date. Must be equal to or greater than ‘Letter of Claim Date");
        this.isformValid = false;
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        return false;
      }
    }
    if (formData.serviceDate != null) {
      if (this.getDateOnly(formData.serviceDate) > this.getDateOnly(new Date())
        || (letterOfclaimDate != null && this.getDateOnly(formData.serviceDate) < this.getDateOnly(letterOfclaimDate))
        || (formData.issueDate != null && this.getDateOnly(formData.serviceDate) < this.getDateOnly(formData.issueDate))) {
        this.notificationService.printWarningMessage("Service date equal to or earlier than current date. Must be equal to or greater than ‘Letter of Claim Date and Issue Date");
        this.isformValid = false;
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        return false;
      }
    }
    if (formData.serviceDate != null && formData.trialDate != null) {
      if (this.getDateOnly(formData.trialDate) <= this.getDateOnly(formData.serviceDate)) {
        this.notificationService.printWarningMessage("Trial date must be after service date");
        this.isformValid = false;
        this.isFormSubmitted = false;
        this.formValidityFlagLoaded.next();
        return false;
      }
    }
    if (formData.serviceDate != null && (formData.avoidable == null || formData.causeCodeID == null || formData.instructedSolicitorID == null)) {
      this.isformValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }
    if (formData.trialReviewCompleteDate != null && (formData.litigationCauseID == null || formData.trialApproved == null)) {
      this.isformValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }
    if ((formData.courtsCaseReference == '' || formData.courtsCaseReference == null) && formData.serviceDate != null) {
      this.notificationService.printErrorMessage("Please enter Court's Case Reference.");
      this.isformValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }

    if (formData.handlingOrganisationId != 1 && formData.mmiKeyContactId == 0 && formData.serviceDate!=null) {
      this.notificationService.printWarningMessage("You are attempting to save the claim without an MMI key contact. If this is a disease claim, please select Chad Williams as the key contact and save the claim. if this is an abuse claim, please select Dan Heaton as the key contact and save the claim.");
      this.isformValid = false;
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return false;
    }
    return this.isformValid;
  }
  onSubmit() {
    if (this.validateForm(this.form.value)) {
      if (this.litigationNote.notesComment != '') {
        if (confirm("Comment / Note has been written but not Added. Do you want to save your Comment / Note ?")) {
          this.litigationNote.handlerID = '';
          this.litigationService.formData.litigationNotes.push(this.litigationNote);
        }
      }
      this.litigationService.saveOrUpdatePolicy().subscribe(res => {
        this.notificationService.printSuccessMessage('Saved Successfully');
        this.loading = false;
        this.setLoadData(this.id);
        this.isFormSubmitted = true;
        this.formValidityFlagLoaded.next();
        this.form.form.markAsPristine();
      },
        error => {
          if (error.error.includes('You are about to save changes to this claim. Another user is also in the claim')) {
            this.notificationService.printHtmlErrorMessage(error.error);
          }
          else {
            this.notificationService.printErrorMessage(error.message);
          }
        }
      )
    }
  }
  addNoteValidation() {
    this.isValid = true;
    this.isFormSubmitted = true;

    if (this.litigationNote.notesComment == '') {
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return this.isValid = false;
    }
    return this.isValid;
  }


  addNotes() {
    if (this.addNoteValidation()) {
      this.litigationService.formData.litigationNotes.push(this.litigationNote);
      this.initialLitigationNote();
      this.refreshGrid();
    }
  }
  refreshGrid() {
    if (this.apportionmentPoliciesTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }
  refresh(): void {
    if (confirm('Are you sure want to reset this page?')) {
      window.location.reload();
    }
  }

  getEFile() {
    if (this.litigationService.formData.eFileURL != '') {
      this.goToLink(this.litigationService.formData.eFileURL);
    }
    else {
      if (confirm("Claim folder does not exist for this claim. Do you want to create.")) {
        this.createClaimFolder();
      }
    }
  }

  createClaimFolder() {
    let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimDetailService.createClaimFolder(claimId).subscribe(res => {
      if (res != null) {
        this.litigationService.formData.eFileURL = res.filepath;
        if (this.litigationService.formData.eFileURL != '') {
          this.goToLink(this.litigationService.formData.eFileURL);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  navigateToWorkflowComponent() {
    this.router.navigate(['/claims/' + this.policyId + '/' + this.id + '/add-workflow-item']);
  }

}

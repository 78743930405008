import { Component, OnInit, OnDestroy, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { IPolicyNote } from './policy-note.model';
import { NgForm } from '@angular/forms';
import { PolicyholderService } from '../policyholder.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { SecurityService } from '../../../../shared/services/security.service';
import { BaseComponentComponent } from '../../../base-component/base-component.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-policy-note',
  templateUrl: './policy-note.component.html',
  styleUrls: ['./policy-note.component.css']
})
export class PolicyNoteComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked {

  @ViewChild(NgForm, { static: false })
  public form: NgForm;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild('policyNoteTable') policyNoteTable: ElementRef;
  dtOptions: DataTables.Settings = {};
  formData: IPolicyNote;
  data: IPolicyNote;
  formPanel: boolean = false;
  isGridLoaded: boolean = false;
  dtTrigger = new Subject();
  isValid: boolean = true;
  notesLocalData: any[];
  policyId: any;
  userId: number;

  users = [6, 82, 217, 5, 145, 149, 91, 147]; //Prod
  usersEditNotes = [5, 145, 149, 91, 147];//Prod

  //users = [6, 12, 63, 5, 141, 46, 61, 62]; //UAT
  //usersEditNotes = [5, 141, 46, 61, 62]; //UAT

  //users = [34, 36, 140]; //DEV - Edit rights for policy notes with ADD Notes button
  //usersEditNotes = [34, 36, 140]; //DEV- Edit rights for policy notes only

  private isNoteEditable = false;

  constructor(
    securityService: SecurityService,
    private policyService: PolicyholderService,
    private currentRoute: ActivatedRoute) {
    super(securityService, 1135);
    this.notesLocalData = [];
  }

  ngOnInit(): void {
    this.policyId = this.currentRoute.snapshot.paramMap.get('policyId');
    let userDetail = this.securityService.getUserData();
    if (userDetail != null) {
      this.userId = userDetail.id;
    }
    this.notesLocalData = this.policyService.policyNotes;
    this.LoadFrm();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      columnDefs: [
        { type: 'datetime-uk', "targets": 0 }
      ],
      order: [[0, 'desc']]
    };

    if (this.policyService.formData.policyType != null && (this.policyService.formData.policyType == 116 || this.policyService.formData.policyType == 142 || this.policyService.formData.policyType == 138)) {
      if (!this.users.includes(this.userId)) {
        this.isReadOnlyMode = true;
      }
    }

    if (this.policyService.formData.policyType != null && (this.policyService.formData.policyType == 116 || this.policyService.formData.policyType == 142 || this.policyService.formData.policyType == 138)) {
      if (this.usersEditNotes.includes(this.userId)) {
        this.isNoteEditable = true;
      }
    }

  }

  refreshForm() {
    this.notesLocalData = this.policyService.policyNotes;
    this.refreshGrid();
    this.form.form.markAsPristine();
  }

  LoadFrm(): void {
    this.formData = {
      policyNoteId: null,
      policyId: this.policyService.formData.policyId,
      sytemDate: new Date(),
      comment: '',
      handlerId: 1001,
      isNewAdded: null,
      updatedByUserName: ''
    }
  }

  addCommentValidation() {
    this.isValid = true;
    if (this.formData.comment == '') {
      return this.isValid = false;
    }
    return this.isValid;
  }

  onSubmit(form: NgForm) {
    if (this.addCommentValidation()) {
      let currentUser: string = this.securityService.getUserData().id.toString() + " - " + this.securityService.getUserData().claimsHandlers;
      this.data = {
        policyNoteId: form.value.policyNoteId,
        comment: form.value.comment,
        policyId: this.policyService.formData.policyId,
        sytemDate: new Date(),
        handlerId: 1001,
        isNewAdded: true,
        updatedByUserName: currentUser
      };
      //debugger;
      this.notesLocalData.push(this.data);
      this.policyService.policyNotes = this.notesLocalData;
      this.LoadFrm();
      this.refreshGrid();
      //this.LoadFrm();
      this.formPanel = false;
    }
  }

  refreshGrid() {
    if (this.policyNoteTable) {
      if (this.datatableElement && this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  saveNotes() {
    if (this.addCommentValidation()) {

      this.data = {
        policyNoteId: this.formData.policyNoteId,
        comment: this.formData.comment,
        policyId: this.policyService.formData.policyId,
        sytemDate: new Date(),
        handlerId: 1001,
        isNewAdded: true,
        updatedByUserName: ''
      };
      
      this.notesLocalData.push(this.data);
      this.policyService.policyNotes = this.notesLocalData;
      this.LoadFrm();
      this.refreshGrid();
      //this.LoadFrm();
      this.formPanel = false;
    }
  }
}

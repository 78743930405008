import { IValuation } from "./claim-valuation.model";
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked, ViewChildren, QueryList, HostListener, Inject } from '@angular/core';
import { ClaimValuationService } from './claim-valuation.service';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LookupService } from '../../../shared/services/lookup.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { enumYesNo } from '../../../shared/models/common.enum';
import { NgForm } from '@angular/forms';
import { ValuationCalculation } from "./claim-valuation-calculation.model";
import { CRULiability } from "./claim-valuation-cruLiability.model";
import { DataTableDirective } from "angular-datatables";
import { Subject, Observable } from "rxjs";
import { concat } from "rxjs/operators";
import { ComponentCanDeactivate } from "../../../shared/guard/unsaved-changes.guard";
import { ClaimComponent } from "../claim.component";
import { BaseComponentComponent } from "../../base-component/base-component.component";
import { SecurityService } from "../../../shared/services/security.service";
import { ClaimDetailService } from "../claim-detail/claim-detail.service";
import { StorageService } from '../../../shared/services/storage.service';
import { ApportionmentService } from "../../../shared/services/apportionment.service";
import { error } from "protractor";
import * as _ from "lodash";
import { CalculateApportionmentService } from "../apportionment/apportionment.service";

@Component({
  selector: 'app-claim-valuation',
  templateUrl: './claim-valuation.component.html',
  styleUrls: ['./claim-valuation.component.css']
})
export class ClaimValuationComponent extends BaseComponentComponent implements OnDestroy, OnInit, AfterViewChecked, ComponentCanDeactivate {

  @ViewChild('valuationForm', null) valuationForm: NgForm;
  @ViewChildren(DataTableDirective)
  private datatableElements: QueryList<DataTableDirective>;

  @ViewChild('valuationCalculationTable') valuationCalculationTable: ElementRef;
  @ViewChild('cruLiabilityTable') cruLiabilityTable: ElementRef;
  public dtOptions: any = {};
  public dtcruLiabilityOptions: any = {};
  public ClaimStageLookups: any[];

  loading: boolean = false;
  yesNokeys: any[];
  yesNoEnum = enumYesNo;
  headOfClaimkeys: any[];
  valuationCalculation: ValuationCalculation;
  valuationCalculations: ValuationCalculation[] = [];
  isValuationBreakDownValid: boolean = true;
  updateIndex: number = null;
  dtTrigger: Subject<any> = new Subject();
  dtcruLiabilityTrigger: Subject<any> = new Subject();
  valuationCalculationSum = { sumClaimantValuation: null, sumDefendantValuation: null };
  valuationCRULiablitySum = { sumAmount: 0, sumDeductible: 0, sumNonDeductible: 0 };
  tempValuationSummary: any[];
  tempEditValuationSummary: any[];
  isLoaded: boolean = false;

  cruLiability: CRULiability;
  cruLiabilities: CRULiability[] = [];
  iscruLiabilityValid: boolean = true;
  cruUpdateIndex: number = null;
  isGridLoaded: boolean = false;
  iscruLiabilityGridLoaded: boolean = false;
  handlingAuthorityValue: number;
  apportionmentData: any
  isPolicyDateFlag: boolean = false;
  policyId: number;
  claimId: number;
  isMMIUser: boolean = false;
  hideWorkflowButton: boolean = true;

  saveMethod: any;
  isFormSubmitted: boolean = true;
  formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean { return false; };
  isSettledButtonClicked: boolean = false;
  reserveLargeLosslimitMessageShownCount: number = 0;
  reserveBreachedDALimitMessageShownCount: number = 0;  
  totalExcessPaid: string;
  utilizeExcess: string;
  availableExcess: string;
  remainingExcess: string;  
  isLineofBussinessEL: boolean = false;
  @HostListener('window:beforeunload')
  // CR-18 Merge Request Changes
  //canDeactivate(component: ClaimValuationComponent,
  //  currentRoute: ActivatedRouteSnapshot,
  //  currentState: RouterStateSnapshot,
  //  nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
  //  if (nextState.url.includes('apportionment') || nextState.url.includes('claimdetail')) {
  //    if (this.valuationForm.dirty) {
  //      this.storageService.store("valuationData", this.valuationService.formData);
  //      this.storageService.store("valuationCalculations", this.valuationCalculations);
  //      this.storageService.store("valuationCalculation", this.valuationCalculation);
  //      this.storageService.store("cruLiabilities", this.cruLiabilities);
  //      this.storageService.store("cruLiability", this.cruLiability);
  //    }
  //    return true;
  //  }
  //  else {
  //    let hasPendingChanges: boolean = !this.valuationForm.dirty;
  //    //if(this.storageService.store("valuationData", this.valuationService.formData);
  //    return hasPendingChanges;
  //}
  canDeactivate(): Observable<boolean> | boolean {
    this.saveMethod = this.onSubmit;
    let hasPendingChanges: boolean = !this.valuationForm.dirty;
    return hasPendingChanges;

  }
  
  constructor(private valuationService: ClaimValuationService,
    private currentRoute: ActivatedRoute,
    private lookupService: LookupService,
    private router: Router,
    private notificationService: NotificationService,
    private claimDetailService: ClaimDetailService,    
    @Inject(ClaimComponent) private parent: ClaimComponent,
    securityService: SecurityService, private storageService: StorageService, private calculateApportionmentService: CalculateApportionmentService, private apportionmentService: ApportionmentService) {
    super(securityService, 1143);
    this.loadLookups();
  }
  loadLookups() {
    this.yesNokeys = Object.values(this.yesNoEnum).filter(Number);
  }
  ngAfterViewChecked(): void {
    if (!this.isGridLoaded) {
      this.refreshGrid();
    }
    if (!this.iscruLiabilityGridLoaded) {
      this.refreshcruLiabilityGrid();
    }
  }


  ngOnInit(): void {    
    this.loading = true;
    this.claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    this.policyId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('policyId'));

    this.handlingAuthorityValue = this.securityService.getUserData().handlingAuthority;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      destroy: true

    };
    this.dtcruLiabilityOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      destroy: true
    };
    //if (this.storageService.retrieve("apportionmentData") != null) {
    //  this.apportionmentData = this.storageService.retrieve("apportionmentData");
    //}
    if (this.securityService.getUserData() != null) {
      this.isMMIUser = this.securityService.getUserData().organisationId == 1 ? true : false;
      this.hideWorkflowButton = (this.isMMIUser) ? false : true
    }
    if (this.claimId != null) 
      this.setLoadData(this.claimId);
    this.checkPolicyDateOutOfRange(this.claimId);
    if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
      this.isReadOnlyMode = true;
    }
    this.parent.claimStatusId$.subscribe(x => {
      if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
        this.isReadOnlyMode = true;
      }
    });

    this.ClaimStageLookups = this.lookupService.getLookupsByTypeId(66);
  }

  getExcesApplicableForValuation(claimId: number) {
    this.valuationService.getValuationExcessApplicable(claimId).subscribe((response: any) => {
      let claimExcess = response;
      console.log(claimExcess);
      this.availableExcess = String(response.availableExcess.toFixed(2));
      this.utilizeExcess = String(response.utilisedExcess.toFixed(2));
      this.totalExcessPaid = String(response.excessPaid.toFixed(2));
      this.remainingExcess = String(response.remainingExcess.toFixed(2));
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.dtcruLiabilityTrigger.unsubscribe();
  }
  setLoadData(claimId) {    
    // CR-18 Merge Request Changes
    //if (this.apportionmentData.id != null) {
    this.valuationService.getValuationByClaimId(parseInt(claimId)).subscribe(res => {
      this.valuationResetFrom(res, false);
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );    
  }

  valuationResetFrom(response: any, reload: boolean) {    
    // CR-18 Merge Request Changes
    //if (this.storageService.retrieve("valuationData") != null) {
    //  this.valuationService.formData = this.storageService.retrieve("valuationData");
    //}
    // else if (this.apportionmentData.id != null) {
    //this.valuationService.formData = response;
    //}
    //else {    
    this.valuationService.formData = response;
    //}
    this.valuationService.formData.reserveReviewDate = response.reserveReviewDate;
    //All for 717
    if (!reload) {
      if (this.valuationService.formData.jurisdiction == 717) {
        const englandJurisdiction = this.lookupService.getLookupByParentId(718);
        const scotlandJurisdiction = this.lookupService.getLookupByParentId(719);
        this.headOfClaimkeys = englandJurisdiction.concat(scotlandJurisdiction);
      }
      else {
        this.headOfClaimkeys = this.lookupService.getLookupByParentId(this.valuationService.formData.jurisdiction);
      }
    }

    // CR-18 Merge Request Changes
    //if (this.storageService.retrieve("valuationCalculations") != null) {
    //  this.valuationCalculations = this.valuationService.formData.valuationCalculations = this.storageService.retrieve("valuationCalculations");
    //}
    //else {
    if (this.valuationService.formData.valuationCalculations == null) {
      this.valuationService.formData.valuationCalculations = [];
    }
    if (response.valuationCalculations == null) {
      this.valuationCalculations = [];
    }
    else {
      this.valuationCalculations = response.valuationCalculations;

    }
    //}
    if (this.valuationCalculations.length > 0) {
      this.refreshGrid();
    }
    // CR-18 Merge Request Changes
    //if (this.storageService.retrieve("cruLiabilities") != null) {
    //  this.cruLiabilities = this.valuationService.formData.cruLiabilities = this.storageService.retrieve("cruLiabilities");
    //}
    //else {
    if (this.valuationService.formData.cruLiabilities == null) {
      this.valuationService.formData.cruLiabilities = [];
    }
    if (response.cruLiabilities == null) {
      this.cruLiabilities = [];
    }
    else {
      this.cruLiabilities = response.cruLiabilities;
      //if (this.cruLiabilities.length > 0) {
      //  this.refreshcruLiabilityGrid();
      //}
    }
    //}
    if (this.cruLiabilities.length > 0) {
      this.refreshcruLiabilityGrid();
    }

    this.initalvaluationCalculation();
    this.initalcruLiability();

    // CR-18 Merge Request Changes
    //this.setCRULiabilityAndValuationCalculation();

    this.tempValuationSummary = [];
    this.tempEditValuationSummary = [];
    if (this.valuationService.formData.standardReserve == 2) {
      this.storeEditTempValues();
    }
    
    this.standardReserveChange();
    this.calculateValuationBreakdown();
    this.calculateValuationCRULiablity();
    this.getExcesApplicableForValuation(this.claimId);

    if (response.loggedInUserDetail != null) {
      if 
         ((response.loggedInUserDetail.userRoleId == 1048 && response.loggedInUserDetail.organisationId == 1 && response.loggedInUserDetail.superUser == 1)
        || (response.loggedInUserDetail.userRoleId == 1049 && response.loggedInUserDetail.organisationId == 1 && response.loggedInUserDetail.superUser == 1)
        || (response.loggedInUserDetail.userRoleId == 1049 && response.loggedInUserDetail.organisationId == 1))
      {
        this.isMMIUser = true;
      }
      else {
        this.isMMIUser = false;
      }
    }
    
  }

  bindJurisdiction() {
    const englandJurisdiction = this.lookupService.getLookupByParentId(718);
    const scotlandJurisdiction = this.lookupService.getLookupByParentId(719);
    var allJurisdiction = englandJurisdiction.map(a => a.text).concat(scotlandJurisdiction.map(a => a.text));
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    }
    let distinctJurisdiction = [];
    distinctJurisdiction
    distinctJurisdiction = allJurisdiction.filter(distinct);

    distinctJurisdiction = distinctJurisdiction.map(item => {
      let item2 = englandJurisdiction.find(i2 => i2.text === item);
      return item2 ? { ...item.id = item2.id } : item;
    });

    this.headOfClaimkeys = englandJurisdiction.concat(englandJurisdiction, scotlandJurisdiction);
  }

  standardReserveChange() {    
    if (this.valuationService.formData.standardReserve == 2) {
      if (this.tempEditValuationSummary.length > 0) {
        for (let index = 0; index < this.valuationService.formData.valuationSummary.length; index++) {
          this.valuationService.formData.valuationSummary[index].fullLiabilityValuation = this.tempEditValuationSummary[index].fullLiabilityValue;
        }
      }
      else {
        for (let index = 0; index < this.valuationService.formData.valuationSummary.length; index++) {
          this.valuationService.formData.valuationSummary[index].fullLiabilityValuation = null;
        }
      }
      this.setValuationAndCRUTotal();
    }
    else {
      //this.storeEditTempValues();
      //this.tempValuationSummary = [];
      this.valuationService.formData.valuationSummary[0].fullLiabilityValuation = this.valuationService.formData.fullLiability.damages;
      this.valuationService.formData.valuationSummary[1].fullLiabilityValuation = this.valuationService.formData.fullLiability.costs;
      this.valuationService.formData.valuationSummary[2].fullLiabilityValuation = this.valuationService.formData.fullLiability.nonDeductibleCRU;
      this.valuationService.formData.valuationSummary[3].fullLiabilityValuation = this.valuationService.formData.fullLiability.defenceCosts;

      //if (this.valuationService.formData.id == 0) {
      //  for (let index = 0; index < this.valuationService.formData.valuationSummary.length; index++) {
      //    this.valuationService.formData.valuationSummary[index].fullLiabilityValuation = this.tempValuationSummary[index].fullLiabilityValue;
      //  }
      //  this.tempValuationSummary = [];
      //}
      //else {
      //  this.valuationService.formData.valuationSummary[0].fullLiabilityValuation = this.valuationService.formData.fullLiability.damages;
      //  this.valuationService.formData.valuationSummary[1].fullLiabilityValuation = this.valuationService.formData.fullLiability.costs;
      //  this.valuationService.formData.valuationSummary[2].fullLiabilityValuation = this.valuationService.formData.fullLiability.nonDeductibleCRU;
      //  this.valuationService.formData.valuationSummary[3].fullLiabilityValuation = this.valuationService.formData.fullLiability.defenceCosts;
      //}
    }
    this.calculateSummary();
  }

  storeTempValues() {
    for (let index = 0; index < this.valuationService.formData.valuationSummary.length; index++) {
      let temp =
      {
        paymentCategory: this.valuationService.formData.valuationSummary[index].paymentCategoryID,
        fullLiabilityValue: this.valuationService.formData.valuationSummary[index].fullLiabilityValuation
      };
      this.tempValuationSummary.push(temp);
      this.valuationService.formData.valuationSummary[index].fullLiabilityValuation = null;
    }
  }
  storeEditTempValues() {
    for (let index = 0; index < this.valuationService.formData.valuationSummary.length; index++) {
      let temp =
      {
        paymentCategory: this.valuationService.formData.valuationSummary[index].paymentCategoryID,
        fullLiabilityValue: this.valuationService.formData.valuationSummary[index].fullLiabilityValuation
      };
      this.tempEditValuationSummary.push(temp);
    }
  }
  //added by rahul
  setValuationDamage() {
    if (this.valuationCalculations.length == 0) {
      this.valuationService.formData.valuationSummary[0].fullLiabilityValuation = 0;
    }
  }
  setValuationCRU() {
    if (this.cruLiabilities.length == 0) {
     // this.valuationService.formData.valuationSummary[2].fullLiabilityValuation = 0;
    }
  }


  setValuationAndCRUTotal() {
    if (this.valuationService.formData.standardReserve == 2) {
      if (this.valuationCalculations.length > 0) {
        this.valuationService.formData.valuationSummary[0].fullLiabilityValuation = this.valuationCalculationSum.sumDefendantValuation
      }
      if (this.cruLiabilities.length > 0) {
       // this.valuationService.formData.valuationSummary[2].fullLiabilityValuation = this.valuationCRULiablitySum.sumNonDeductible
      }

      this.calculateSummary();
    }
  }

  initalvaluationCalculation() {
    this.valuationCalculation = {
      id: null, defendantValuation: null, headOfClaimId: null, valuationID: this.valuationService.formData.id, comments: null, claimantValuation: null
    };
  }
  initalcruLiability() {
    this.cruLiability = {
      id: null, amount: null, benefitDescription: null, deductibleAmount: null, nonDeductibleAmount: null, valuationId: this.valuationService.formData.id
    };
  }

  calculateSummary() {
    this.resetValue();
    // CR-18 Merge Request Changes
    //this.checkApportionmentDataInLocalStorage();
    for (let index = 0; index < this.valuationService.formData.valuationSummary.length; index++) {
      this.valuationService.formData.valuationSummary[index].mmiValuation = Number((this.valuationService.formData.valuationSummary[index].fullLiabilityValuation *
        this.valuationService.formData.valuationSummary[index].mmiPercentageValue / 100).toFixed(2));
      this.valuationService.formData.valuationSummary[index].netPaid = Number((this.valuationService.formData.valuationSummary[index].paidValue -
        this.valuationService.formData.valuationSummary[index].recoveries).toFixed(2));
      this.valuationService.formData.valuationSummary[index].mmiReserve = Number((this.valuationService.formData.valuationSummary[index].mmiValuation -
        this.valuationService.formData.valuationSummary[index].netPaid -
        this.valuationService.formData.valuationSummary[index].excessPaid).toFixed(2));
      this.valuationService.formData.valuationSummary[index].totalIncurred = Number((this.valuationService.formData.valuationSummary[index].netPaid +
        this.valuationService.formData.valuationSummary[index].mmiReserve).toFixed(2));

      this.valuationService.formData.totalfullLiabilityValuation += this.valuationService.formData.valuationSummary[index].fullLiabilityValuation;
      this.valuationService.formData.totalmmiPercentageValue += this.valuationService.formData.valuationSummary[index].mmiPercentageValue;
      this.valuationService.formData.totalmmiValuation += this.valuationService.formData.valuationSummary[index].mmiValuation;
      this.valuationService.formData.totalpaidValue += this.valuationService.formData.valuationSummary[index].paidValue;
      this.valuationService.formData.totalrecoveries += this.valuationService.formData.valuationSummary[index].recoveries;
      this.valuationService.formData.totalnetPaid += this.valuationService.formData.valuationSummary[index].netPaid;
      this.valuationService.formData.totalexcessPaid += this.valuationService.formData.valuationSummary[index].excessPaid;
      this.valuationService.formData.totalmmiReserve += this.valuationService.formData.valuationSummary[index].mmiReserve;
      this.valuationService.formData.totaltotalIncurred += this.valuationService.formData.valuationSummary[index].totalIncurred;
    }
    
    ////let netReserve = Number(this.valuationService.formData.totalmmiReserve.toFixed(2));          
   // this.totalExcessPaid = this.valuationService.formData.totalexcessPaid.toFixed(2);      
    //let remainingXS = (Number(this.utilizeExcess) - Number(this.totalExcessPaid));
    //this.remainingExcess = String(remainingXS.toFixed(2));
  }

  resetValue() {
    this.valuationService.formData.totalfullLiabilityValuation = 0;
    this.valuationService.formData.totalmmiPercentageValue = 0;
    this.valuationService.formData.totalmmiValuation = 0;
    this.valuationService.formData.totalpaidValue = 0;
    this.valuationService.formData.totalrecoveries = 0;
    this.valuationService.formData.totalnetPaid = 0;
    this.valuationService.formData.totalexcessPaid = 0;
    this.valuationService.formData.totalmmiReserve = 0;
    this.valuationService.formData.totaltotalIncurred = 0;
  }

  //checkApportionmentDataInLocalStorage() {
  //  if (this.apportionmentData.overwrite == true) {
  //    this.valuationService.formData.valuationSummary[0].mmiPercentageValue = this.apportionmentData.overwriteMMIDamages;
  //    this.valuationService.formData.valuationSummary[1].mmiPercentageValue = this.apportionmentData.overwriteMMICosts;
  //    this.valuationService.formData.valuationSummary[2].mmiPercentageValue = this.apportionmentData.overwriteMMICRU;
  //    this.valuationService.formData.valuationSummary[3].mmiPercentageValue = this.apportionmentData.overwriteMMIDefenceCosts;
  //  }
  //  else {
  //    this.valuationService.formData.valuationSummary[0].mmiPercentageValue = this.apportionmentData.mmiDamages;
  //    this.valuationService.formData.valuationSummary[1].mmiPercentageValue = this.apportionmentData.mmiCosts;
  //    this.valuationService.formData.valuationSummary[2].mmiPercentageValue = this.apportionmentData.mmicru;
  //    this.valuationService.formData.valuationSummary[3].mmiPercentageValue = this.apportionmentData.mmiDefenceCosts;
  //  }
  //  if (this.valuationService.formData.id == null) {
  //    this.valuationService.formData.valuationSummary.find(i => i.netPaid).netPaid = 0;
  //    this.valuationService.formData.valuationSummary.find(i => i.excessPaid).excessPaid = 0;
  //    this.valuationService.formData.valuationSummary.find(i => i.paidValue).paidValue = 0;
  //  }

  //}

  valuationBreakDownValidation() {
    this.isValuationBreakDownValid = true;
    if (this.valuationCalculation.headOfClaimId == null || this.valuationCalculation.claimantValuation == null || this.valuationCalculation.defendantValuation == null) {
      return this.isValuationBreakDownValid = false;
    }
    return this.isValuationBreakDownValid;
  }
  addValuationBreakDown() {
    if (this.valuationBreakDownValidation()) {
      if (this.updateIndex == null) {
        this.valuationCalculations.push(this.valuationCalculation);
      }
      else {
        this.valuationCalculations[this.updateIndex] = this.valuationCalculation;
        this.updateIndex = null;
      }
      this.valuationCalculation.headOfClaim = this.getHeadOfClaimById(this.valuationCalculation.headOfClaimId);
      this.initalvaluationCalculation();
      this.calculateValuationBreakdown();
      this.refreshGrid();
    }
  }

  displayNotification(cru: boolean) {
    if (cru) {
      this.notificationService.printWarningMessage('To create a specific CRU Liability, please first update the Standard Reserve to "No".');
    }
    else {
      this.notificationService.printWarningMessage('To create a specific Valuation Calculation, please first update the Standard Reserve to "No".');
    }
  }

  getHeadOfClaimById(id: number): string {
    let headOfclaim = this.headOfClaimkeys.find(a => a.id == this.valuationCalculation.headOfClaimId);
    if (typeof headOfclaim !== 'undefined') {
      return headOfclaim.text;
    }
    return '';
  }
  editValuationBreakDown(indexItem) {
    this.valuationCalculation = Object.assign({}, this.valuationCalculations[indexItem]);
    this.updateIndex = indexItem;
  }
  deleteValuationBreakDown(indexItem) {
    this.valuationCalculations = this.valuationCalculations.filter(function (place, index) {
      return index != indexItem
    });

    this.valuationForm.form.markAsDirty();
    this.setValuationDamage();
    this.calculateValuationBreakdown();
    this.updateIndex = null;
    this.refreshGrid();
  }

  calculateValuationBreakdown() {
    this.valuationCalculationSum.sumClaimantValuation = null;
    this.valuationCalculationSum.sumDefendantValuation = null;

    for (let index = 0; index < this.valuationCalculations.length; index++) {
      this.valuationCalculationSum.sumClaimantValuation += this.valuationCalculations[index].claimantValuation;
      this.valuationCalculationSum.sumDefendantValuation += this.valuationCalculations[index].defendantValuation;
    }
    this.setValuationAndCRUTotal();
  }
  refreshGrid() {
    if (this.valuationCalculationTable) {
      if (this.datatableElements.toArray()[0] && this.datatableElements.toArray()[0].dtInstance) {
        this.datatableElements.toArray()[0].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
      this.isGridLoaded = true;
    }
  }


  calculateValuationCRULiablity() {
    this.valuationCRULiablitySum.sumAmount = 0;
    this.valuationCRULiablitySum.sumDeductible = 0;
    this.valuationCRULiablitySum.sumNonDeductible = 0

    for (let index = 0; index < this.cruLiabilities.length; index++) {
      this.valuationCRULiablitySum.sumAmount += this.cruLiabilities[index].amount;
      this.valuationCRULiablitySum.sumDeductible += this.cruLiabilities[index].deductibleAmount;
      this.valuationCRULiablitySum.sumNonDeductible += this.cruLiabilities[index].nonDeductibleAmount;
    }
    this.setValuationAndCRUTotal();
  }
  calculateCRULiability() {
    this.cruLiability.nonDeductibleAmount = this.cruLiability.amount - this.cruLiability.deductibleAmount;
  }
  cruLiabilityValidation() {
    this.iscruLiabilityValid = true;
    if (this.cruLiability.benefitDescription == null || this.cruLiability.amount == null || this.cruLiability.deductibleAmount == null) {
      return this.iscruLiabilityValid = false;
    }
    else if (this.cruLiability.benefitDescription.trim() == '') {
      return this.iscruLiabilityValid = false;
    }
    return this.iscruLiabilityValid;
  }
  addcruLiability() {
    if (this.cruLiabilityValidation()) {
      if (this.cruUpdateIndex == null)
        this.cruLiabilities.push(this.cruLiability);
      else {
        this.cruLiabilities[this.cruUpdateIndex] = this.cruLiability;
        this.cruUpdateIndex = null;
      }
      this.initalcruLiability();
      this.calculateValuationCRULiablity();
      this.refreshcruLiabilityGrid();
    }


  }
  cancelButton(isCRU) {
    if (isCRU) {
      this.initalcruLiability();
      this.calculateValuationCRULiablity();
      this.cruUpdateIndex = null;
    }
    else {
      this.initalvaluationCalculation();
      this.calculateValuationBreakdown();
      this.updateIndex = null;
    }

  }

  editcruLiability(indexItem) {
    this.cruLiability = Object.assign({}, this.cruLiabilities[indexItem]);
    this.cruUpdateIndex = indexItem;
  }
  deletecruLiability(indexItem) {
    this.cruLiabilities = this.cruLiabilities.filter(function (place, index) {
      return index != indexItem
    });
    this.valuationForm.form.markAsDirty();
    this.initalcruLiability();
    this.cruUpdateIndex = null;
    this.setValuationCRU();
    this.calculateValuationCRULiablity();
    this.refreshcruLiabilityGrid();
  }
  refreshcruLiabilityGrid() {
    if (this.cruLiabilityTable) {
      if (this.datatableElements.toArray()[1] && this.datatableElements.toArray()[1].dtInstance) {
        this.datatableElements.toArray()[1].dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtcruLiabilityTrigger.next();
        });
      }
      else {
        this.dtcruLiabilityTrigger.next();
      }
      this.iscruLiabilityGridLoaded = true;
    }
  }

  onSubmit() {
    this.isFormSubmitted = true;
    if (!this.checkValidation()) {
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      return;
    }
    //if (!this.claimHandlerAssigmentForMMIReserve()) {
    //  this.notificationService.printWarningMessage("The proposed valuation exceeds your Handling Authority. Please refer to a colleague with a sufficient Handling Authority to enter the new valuation.");
    //  this.isFormSubmitted = false;
    //  this.formValidityFlagLoaded.next();
    //  return;
    //}
    else {
      this.valuationService.formData.valuationCalculations = this.valuationCalculations;
      this.valuationService.formData.cruLiabilities = this.cruLiabilities;
      var mmiReserve = _.sumBy(this.valuationService.formData.valuationSummary, (o: any) => { return o.mmiReserve });
      if (mmiReserve < 0) {
        if (confirm("This change has resulted in a negative MMI Reserve. Please review/amend the Apportionment and Full Liability Valuation as a matter of urgency.")) {
          this.saveValuation();
        }
        else {
          return;
        }
      }
      else {
        this.saveValuation();
      }
    }
  }


  saveValuation() {
    this.valuationService.saveOrUpdateValuation().subscribe(res => {
      this.notificationService.printSuccessMessage('Saved Successfully');
      this.valuationResetFrom(res, true);
      this.parent.isValuationAvailble = true;
      this.isFormSubmitted = true;
      this.formValidityFlagLoaded.next();

      if (this.valuationService.formData.totalmmiReserve >= 250000 && this.reserveLargeLosslimitMessageShownCount == 0) {
        if (this.valuationService.formData.handlingOrganisationId == 1) {
          this.notificationService.printWarningMessage("The reserve set has reached the Large Loss limit. Please prepare a large loss advice within 5 working days.");
        }
        else {
          this.notificationService.printWarningMessage("The reserve set has reached the Large Loss limit. Please prepare a large loss advice and send to the MMI key contact within 5 working days.");
        }
        this.reserveLargeLosslimitMessageShownCount += 1;
      }
      else if (this.valuationService.formData.totalmmiReserve < 250000) {
        this.reserveLargeLosslimitMessageShownCount = 0;
      }

      var DAAuthorityValue = this.lookupService.getLookupById(this.valuationService.formData.claimTypeId)[0].value;
      if (this.valuationService.formData.totalmmiValuation > DAAuthorityValue && this.valuationService.formData.handlingOrganisationId != 1 && this.reserveBreachedDALimitMessageShownCount == 0) {
        this.notificationService.printWarningMessage("The reserve has breached the D.A. limit. This claim is now Non-D.A. Please refer the claim under the SLA to the MMI key contact within 3 months. If you consider that this should remain as D.A. please email the MMI key contact.");
        this.reserveBreachedDALimitMessageShownCount += 1;
      }
      else if (this.valuationService.formData.totalmmiValuation < DAAuthorityValue) {
        this.reserveBreachedDALimitMessageShownCount = 0;
      }
      this.valuationForm.form.markAsPristine();
    },
      error => {
        if (error.error.includes('You are about to save changes to this claim. Another user is also in the claim')) {
          this.notificationService.printHtmlErrorMessage(error.error);
        }
        else {
          this.notificationService.printErrorMessage(error.message);
        }
      }
    )
  }
  refresh(): void {
    if (confirm('Are you sure want to reset this page?')) {
      window.location.reload();
    }
  }

  checkValidation(): boolean {
    let isValidValuation = true;
    if (this.handlingAuthorityValue < this.valuationService.formData.totaltotalIncurred) {
      isValidValuation = false
      this.notificationService.printWarningMessage("The proposed valuation exceeds your Handling Authority. Please refer to a colleague with a sufficient Handling Authority to enter the new valuation.");
      return false;
    }
    if (this.valuationService.formData.totalmmiReserve >= 250000 && this.valuationService.formData.handlingOrganisationId == 1 && this.valuationService.formData.claimHandlerId == 0) {
      isValidValuation = false
      this.notificationService.printWarningMessage("LLA is now applicable please assign an MMI handler");
      return false;
    }
    if (this.valuationService.formData.totalmmiReserve >= 250000 && this.valuationService.formData.handlingOrganisationId != 1 && this.valuationService.formData.mmiKeyContact == 0) {
      this.notificationService.printWarningMessage("LLA is now applicable please assign an MMI Key Contact");
      isValidValuation = false
      return isValidValuation;
    }
    var DAAuthorityValue = this.lookupService.getLookupById(this.valuationService.formData.claimTypeId)[0].value;
    if (this.valuationService.formData.totalmmiValuation > DAAuthorityValue && this.valuationService.formData.handlingOrganisationId != 1 && this.valuationService.formData.mmiKeyContact == 0) {
      this.notificationService.printWarningMessage("You are attempting to save the claim without an MMI key contact. If this is a disease claim, please select Chad Williams as the key contact and save the claim. if this is an abuse claim, please select Dan Heaton as the key contact and save the claim.");
      isValidValuation = false
      return isValidValuation;
    }
    return isValidValuation;
  }

  getEFile() {
    if (this.valuationService.formData.eFileURL != '') {
      this.goToLink(this.valuationService.formData.eFileURL);
    }
    else {
      if (confirm("Claim folder does not exist for this claim. Do you want to create.")) {
        this.createClaimFolder();
      }
    }
  }

  createClaimFolder() {
    let claimId = parseInt(this.currentRoute.parent.snapshot.paramMap.get('claimId'));
    this.claimDetailService.createClaimFolder(claimId).subscribe(res => {
      if (res != null) {
        this.valuationService.formData.eFileURL = res.filepath;
        if (this.valuationService.formData.eFileURL != '') {
          this.goToLink(this.valuationService.formData.eFileURL);
        }
        else {
          this.notificationService.printErrorMessage("Folder not created.");
        }
      }
    },
      error => {
        this.notificationService.printErrorMessage(error.message);
      }
    );
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  checkPolicyDateOutOfRange(claimid) {
    this.apportionmentService.getApportionment(claimid)
      .subscribe(response => {
        if (response != null) {
          if (response.apportionmentPolicies != null) {
            this.updatePolicyDatesOutRangeFlag(response.apportionmentPolicies);
          }
        }
      },
        error => {
          this.notificationService.printErrorMessage(error.message);
        });
  }

  updatePolicyDatesOutRangeFlag(apportionmentPoliciesData) {
    if (apportionmentPoliciesData.filter(i => i.isPolicyDateOutRangeFlag == true).length > 0) {
      this.isPolicyDateFlag = true;
    }
    else {
      this.isPolicyDateFlag = false;
    }
  }

  navigateToWorkflowComponent() {
    this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/add-workflow-item']);
  }

  claimHandlerAssigmentForMMIReserve() {
    return (this.valuationService.formData.totalmmiReserve >= 250000 && this.valuationService.formData.handlingOrganisationId == 1 && this.valuationService.formData.claimHandlerId == 0) ? false : true;
  }
  // CR-18 Merge Request Changes
  //setCRULiabilityAndValuationCalculation() {
  //  if (this.storageService.retrieve("valuationCalculation") != null) {
  //    this.valuationCalculation = this.storageService.retrieve("valuationCalculation");
  //    this.isValuationBreakDownValid = true;
  //  }
  //  else {
  //    this.initalvaluationCalculation();
  //  }

  //  if (this.storageService.retrieve("cruLiability") != null) {
  //    this.cruLiability = this.storageService.retrieve("cruLiability");
  //  }
  //  else {
  //    this.initalcruLiability();
  //  }
  //}

  // CR-18 Merge Request Changes
  //saveOrUpdateApportionmentData() {
  //  if (this.storageService.retrieve("apportionmentData") !== null) {
  //    var apportionmentData = this.storageService.retrieve("apportionmentData");
  //    var body = {
  //      ...apportionmentData
  //    };
  //    if (apportionmentData.apportionmentPolicies.length > 0) {
  //      if (apportionmentData.id > 0) {
  //        this.apportionmentService.updateApportionment(body).subscribe(res => { },
  //          error => {
  //            this.notificationService.printErrorMessage(error.message);
  //          });
  //      }
  //      else {
  //        this.apportionmentService.addApportionment(body).subscribe(res => { },
  //          error => {
  //            this.notificationService.printErrorMessage(error.message);
  //          });
  //      }
  //    }
  //  }

  //}

  // CR-18 Merge Request Changes

  //updateClaimsData() {
  //  if (this.storageService.retrieve("claimsData") != null) {
  //    this.claimDetailService.saveOrUpdateClaim(this.storageService.retrieve("claimsData")).subscribe(res => { },
  //      error => {
  //        this.notificationService.printErrorMessage(error.message);
  //      });
  //  }
  //}
}



import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReinsuranceIndexationService {

  private indexationUrl: string = "/api/indexation/";
  constructor(private dataService: DataService, private configurationService: ConfigurationService, private http: HttpClient) {

    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.indexationUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.indexationUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.indexationUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.indexationUrl);
  }

  getReinsuranceIndexations() {
    let Url = this.indexationUrl + 'getReinsuranceIndexations';
    return this.dataService.get(Url);
  }

  
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentBordereauxService } from '../../../shared/services/payment-bordereaux.service'
import { IPaymentBordereaux } from '../../../shared/models/payment-bordereaux.model';
import { PaymentBordereaux } from '../../../shared/models/search-payment-bordereaux.model';


@Injectable({
  providedIn: 'root'
})
export class CancelChecquesService {


  constructor(private paymentBordereauxService: PaymentBordereauxService) { }


  searchCancelChequePayments(data: PaymentBordereaux): any {
    return this.paymentBordereauxService.searchChequePaymentsBordereaux(data);
  }

  approveCancelChequePayments(paymentIds, loggedInUserId): any {
    return this.paymentBordereauxService.approveChequePaymentsBordereaux(paymentIds, loggedInUserId);
  }

  rejectCancelChequePayments(paymentIds): any {
    return this.paymentBordereauxService.rejectChequePaymentsBordereaux(paymentIds);
  }
}

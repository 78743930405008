import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { ClaimService } from '../services/claim.service';
import { RoleGuardService } from '../../shared/services/role-guard.service';
import { NavigationService } from '../services/navigation.service';
import { NotificationService } from '../services/notification.service';



export interface ComponentCanDeactivate {

  canDeactivate: () => boolean | Observable<boolean>;
  saveMethod: () => Promise<boolean>;
  isValidInput$: Observable<any>;
  formValidityFlagLoaded: Observable<any>
  //isValid: boolean;
  isFormSubmitted: boolean;
  isFormDirty: () => boolean;
  isSettledButtonClicked: boolean;
  //claimStatusLoaded = new Subject();
  // CR-18 Merge Request Changes
  //  canDeactivate: (component: ComponentCanDeactivate,
  //    currentRoute: ActivatedRouteSnapshot,
  //    currentState: RouterStateSnapshot,
  //    nextState?: RouterStateSnapshot, callbackFunction?: any) => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private storageService: StorageService,
    private claimsService: ClaimService,
    private navigationService: NavigationService,
    private notificationService: NotificationService) {
  }
  canDeactivate(component: ComponentCanDeactivate, callbackFunction?: any): Observable<boolean> | boolean {
    if (component.isFormDirty()) {
      return false;
    }
    else {
      if (component.canDeactivate()) {
        if (component.isSettledButtonClicked) {
          this.notificationService.printWarningMessage("Claim has not been settled yet. Please click the 'Settle' button again to settle the claim.");
        }
        return true;
      }
      else {
        if (component.isSettledButtonClicked) {
          this.notificationService.printWarningMessage("Claim has not been settled yet. Please click the 'Settle' button again to settle the claim.");
        }

        return this.navigationService.setNavigationConfirmation(function () {
          if (component.saveMethod != undefined) {
            component.saveMethod();
          }
        },
          function () {
          }).then((res) => {
            if (res) {
              return res;
            }
            else {
              var saveChangesPromise = new Promise((resolve, reject) => {
                component.isValidInput$.subscribe(x => {
                  return resolve(component.isFormSubmitted);
                });
              });
              return saveChangesPromise;
            }

            //return component.isValidInput$.subscribe(x => {
            //  debugger;
            //   return component.isFormSubmitted;
            //});


          });


      }
    }
  }
  //isFormSubmitted
  //

  //component.validFlagLoaded.next();
  //component.isValidInput$.subscribe(x => {
  //  debugger;
  //alert(component.isValidFlag);
  //if (this.parent.isClaimSettledRIOS || this.parent.isClaimSettled) {
  //  this.isReadOnlyMode = true;
  //}
  //});

  //}
  // CR-18 Merge Request Changes
  //canDeactivate(component: ComponentCanDeactivate,
  //  currentRoute: ActivatedRouteSnapshot,
  //  currentState: RouterStateSnapshot,
  //  nextState?: RouterStateSnapshot, callbackFunction?: Function): boolean | Observable<boolean> {

  //
  //if (component.canDeactivate(component, currentRoute, currentState, nextState)) {
  //  return true;
  //}
  //else {
  //  var confirmation = confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
  //  if (confirmation) {
  //    this.claimsService.clearLocalStorage();
  //  }
  //  return confirmation;
  //    }

  //    this.role.confirmThis("Are you sure to delete?", function () {
  //      alert("Yes clicked");
  //    }, function () {
  //      alert("No clicked");
  //    })  
}

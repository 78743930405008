import { Injectable } from '@angular/core';
import { HandlingOrganisationService } from '../../../shared/services/handling-organisation.service';
import { AutoEmailSetupService } from '../../../shared/services/auto-email-setup.service';
import { HandlingOrganisationSetting } from './email-setup.model';

@Injectable({
  providedIn: 'root'
})
export class EmailSetupService {

  constructor(private autoEmailSetupService: AutoEmailSetupService,) { }

  getAllHandlingOrganisationsSetting() {
    return this.autoEmailSetupService.getAllHandlingOrganisationSetting();
  }

  putHandlingOrganisationsSetting(hOSetting: HandlingOrganisationSetting) {
    var body = {
      ...hOSetting
    };
    return this.autoEmailSetupService.putHandlingOrganisationSetting(body);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import { SearchParticipantVM } from '../../components/home/search-participant/search-participant.model';

@Injectable()
export class ParticipantService {
  private participantUrl: string = '/api/participant/';

  constructor(private dataService: DataService, private configurationService: ConfigurationService) {
    if (this.configurationService.isReady || this.configurationService.serverSettings != undefined)
      this.participantUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.participantUrl;
    else
      this.configurationService.settingsLoaded$.subscribe(x => this.participantUrl = this.configurationService.serverSettings.policyClaimsAgentUrl + this.participantUrl);
  }

  searchParticipants(filterCriteria: SearchParticipantVM) {
    let url = this.participantUrl + "search";

    return this.dataService.get(url, filterCriteria).pipe<SearchParticipantVM>(tap((response: any) => {
      return response;
    }));
  }
}

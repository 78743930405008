import { Component, OnInit, ɵpublishDefaultGlobalUtils, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ParticipantClaimHandlerService } from './participant-claim-handler.service';
import { Observable } from 'rxjs/internal/Observable';
import { ClaimHandler } from './participant-claim-handler.model';
import { IPager } from '../../../shared/models/pager.model';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { SecurityService } from '../../../shared/services/security.service';
import { DataTableDirective } from 'angular-datatables';
import { LookupService } from '../../../shared/services/lookup.service';
import { Subject, Subscription } from 'rxjs';
import { enumYesNo } from '../../../shared/models/common.enum';
import * as _ from 'lodash';
import { NotificationService } from '../../../shared/services/notification.service';
import { NgForm } from '@angular/forms';
import { ComponentCanDeactivate } from '../../../shared/guard/unsaved-changes.guard';
import { BaseComponentComponent } from '../../base-component/base-component.component';
import { Location } from "@angular/common";

@Component({
  selector: 'app-claim-handler',
  templateUrl: './claim-handler.component.html',
  styleUrls: ['./claim-handler.component.css']
})

export class ParticipantClaimHandlerComponent extends BaseComponentComponent implements OnInit, ComponentCanDeactivate {

  //dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @ViewChild(NgForm, { static: false })
  private form: NgForm;
  handlingOrganisations: any[];
  errorReceived: boolean;
  public claimHandlerVM: ClaimHandler = new ClaimHandler();

  public claimHandlerResult: any;
  public dtOptions: any = {};
  public authenticated: boolean = false;
  public authSubscription: Subscription;
  public dtTrigger: Subject<any>;
  public selfComponent: any = this;
  enumYesNo: any[];
  isShown: boolean = false; // hidden by default
  isAddButtonShow: boolean = false;
  id;
  isValid: boolean = true;
  statuskeys: any[];
  isEditMode: boolean = false;
  isShowSearchButton: boolean = false;

  public userRoles: any[];
  loggedInUser: any
  isSuperUserFlag: boolean = true;
  isColleaguesSearchFlag: boolean = false;
  isDisabledFields: boolean = false;
  editableData: {};
  isDenyToEditSuperUserItself: boolean = false;
  authorityDisable: boolean = false;

  saveMethod: any;
  isFormSubmitted: boolean = true;
  formValidityFlagLoaded = new Subject();
  isValidInput$ = this.formValidityFlagLoaded.asObservable();
  isFormDirty(): boolean { return false; };
  isSettledButtonClicked: boolean = false;

  route: string;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    this.saveMethod = this.onSubmit;
    let hasPendingChanges: boolean = !this.form.dirty;
    return hasPendingChanges;
  }

  displayedColumns: any[] = [
    { title: 'User Name', data: 'claimsHandlers' },
    { title: 'Super User', data: 'superUser' },
    { title: 'Job Title', data: 'jobTitle', visible: false },
    { title: 'User Role', data: 'userRoleName' },
    { title: 'Payment Authority', data: 'paymentAuthority', render: $.fn.dataTable.render.number(',', '.', 2, '£') },
    { title: 'Handling Authority', data: 'handlingAuthority', render: $.fn.dataTable.render.number(',', '.', 2, '£') },
    { title: 'Certified as Fit and Proper', data: 'certifiedAsFitAndProper', visible: false },
    { title: 'Telephone Number', data: 'telephoneNumber', visible: false },
    { title: 'Email', data: 'email', visible: false },
    { title: 'Approval Stage 1 (Claims) Authority', data: 'claimsStage1ApprovalAuthority', render: $.fn.dataTable.render.number(',', '.', 2, '£') },
    { title: 'Approval (Finance) Authority', data: 'financeApprovalAuthority', render: $.fn.dataTable.render.number(',', '.', 2, '£') },
    { title: 'Status', data: 'isDelete' },
    { title: 'Action', data: null, orderable: false }
  ];

  constructor(public participantClaimHandlerService: ParticipantClaimHandlerService, private _Activatedroute: ActivatedRoute,
    private _router: Router, public lookUpService: LookupService, private notificationService: NotificationService,
    private activeRoute: ActivatedRoute, securityService: SecurityService, location: Location) {
    //To do getting data from enum
    //let enums: enumYesNo;
    //this.enumYesNo = _.values(enumYesNo)
    super(securityService, 1161);
    this.dtTrigger = new Subject();

  }


  ngOnInit() {
    //if (this.isReadOnlyMode) {
    //  this.displayedColumns[8].visible = false;
    //}
    this.userRoles = this.lookUpService.getLookupsByTypeId(47);
    this.isEditMode = false;
    //this.claimHandlerVM.organisationId = parseInt(this.activeRoute.snapshot.queryParams.organisationid);
    this.claimHandlerVM.certifiedAsFitAndProper = null;
    this.claimHandlerVM.superUser = null;
    // this.claimHandlerVM.status = null;
    //this.statuskeys = this.lookUpService.getLookupsByTypeId(4);
    this.initialiseDataTable();
    let organisationId = this.activeRoute.snapshot.queryParams.organisationid;
    let claimHandlerId = this.activeRoute.snapshot.queryParams.claimhanderid;
    let isSearch = (this.activeRoute.snapshot.queryParams.isSearchFlag === 'true');     //Boolean(this.activeRoute.snapshot.queryParams.isSearchFlag);
    this.claimHandlerVM.organisationId = organisationId == undefined ? null : parseInt(organisationId);
    this.claimHandlerVM.userRoleId = null;

    if (isSearch) {
      this.isShowSearchButton = true;
    }
    else {
      this.isShowSearchButton = false;
    }
    this.EnableDisableFields();
    this.participantClaimHandlerService.getAllHandlingOrganisations()
      .subscribe((reponse: any) => {
        this.handlingOrganisations = reponse;

        if (claimHandlerId != undefined) {
          this.getClaimHandlerById(claimHandlerId).then(response => {
            let data: any = response;
            this.claimHandlerVM = {
              ID: data.id,
              claimsHandlers: data.claimsHandlers,
              handlingAuthority: data.handlingAuthority,
              organisationId: data.organisationId,
              paymentAuthority: data.paymentAuthority,
              handlerId: data.handlerId,
              certifiedAsFitAndProper: data.certifiedAsFitAndProper,
              superUser: data.superUser,
              // status: data.status,
              jobTitle: data.jobTitle,
              telephoneNumber: data.telephoneNumber,
              email: data.email,
              isDelete: data.isDelete,

              userRoleId: data.userRoleId,
              userRoleName: data.userRoleName,
              claimsStage1ApprovalAuthority: data.claimsStage1ApprovalAuthority,
              financeApprovalAuthority: data.financeApprovalAuthority,
            }
            console.log("filterd data", data);
            console.log("filterd data", this.claimHandlerVM);
            this.isEditMode = true;
            this.hideAddButton(false);
            this.denyToEditSuperUserItself();

          });
        }

        //organisation ddl calling coming from search screen
        this.onOrganisationChange();

      });


  }


  initialiseDataTable(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedColumns,
      //columnDefs: [{
      //  targets: -1,
      //  data: null,
      //  defaultContent: "<button type='button' class='btn btn-primary'>Edit</button>"
      //}],
      'columnDefs': [
        { width: "18%", targets: 0 },
        {
          render: function (data, type, row) {
            return data == 1 ? "Yes" : "No"
          },
          width: "10%", targets: 1
        },
        { width: "14%", targets: 2 },
        { width: "10%", className: "dt-body-right", targets: 3 },
        { width: "10%", className: "dt-body-right", targets: 4 },
        { width: "10%", className: "dt-body-right", targets: 5 },
        {
          render: function (data, type, row) {
            return data == "1" ? "Yes" : "No"
          },
          width: "10%", className: "dt-body-right", targets: 6
        },
        {
          render: function (data, type, row) {
            return data == false ? "Active" : "Inactive"
          },
          width: "9%", targets: 11
        },
        {
          render: function (data, type, row) {
            if (location.pathname != "") {              
              if (location.pathname === '/claim-handler') {
                return '<button class="btn edit-button"><i class="fa fa-edit"></i></button>';
              } else {
                return '<button class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></button>';
              }
            }
            // let deleteButton = (!that.isDisabledFields) ? '<button class="btn remove-button"><i class="fa fa-trash-alt"></i></button>' : '';
            //return '<button class="btn edit-button"><i class="fa fa-edit"></i></button>';
            
          },
          width: "9%", targets: 12
        },
      ],
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          action: this.newExportAction,
          messageTop: "",
          title: "User Details",
          filename: "UserDetails-" + Math.random(),
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 11]
          }
        }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler

        $('.btn-primary', row).unbind('click');
        $('.btn-primary', row).bind('click', () => {
          this.editableData = { ...data };
          self.filterDataBasedOnEditButton(data);
        });
        $('.edit-button', row).bind('click', () => {
          this.editableData = { ...data };
          self.filterDataBasedOnEditButton(data);
        });

        //$('.remove-button', row).unbind('click');
        //$('.remove-button', row).bind('click', () => {
        //  if (self.isColleaguesSearchFlag) {
        //    return false;
        //  }
        //  self.removeButtonClick(data);
        //});
        return row;
      },
      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        //this.searchClaimVM.sortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
        //this.searchClaimVM.sortOrder = dataTablesParameters.order[0].dir;
        //this.searchClaimVM.pageIndex = dataTablesParameters.start;
        //this.searchClaimVM.pageSize = dataTablesParameters.length;
        this.getClaimHandlers(this.claimHandlerVM.organisationId, true).subscribe(response => {
          /*debugger*/
          this.claimHandlerResult = response;
          callback({
            //recordsTotal: that.paginationInfo.totalItems,
            //recordsFiltered: that.paginationInfo.itemsPerPage,            
            data: response
          });
        });
      }
    };

  }
  filterDataBasedOnEditButton(userdata) {
    let data = null;
    if (userdata != undefined) {
      data = this.findClaimHandlerDetailsById(userdata.id);
    } else {
      let claimHandlerId = this.activeRoute.snapshot.queryParams.claimhanderid;
      if (claimHandlerId > 0)
        data = this.findClaimHandlerDetailsById(claimHandlerId);
    }
    if (data != null) {
      this.claimHandlerVM = {
        ID: data.id,
        claimsHandlers: data.claimsHandlers,
        handlingAuthority: data.handlingAuthority,
        organisationId: data.organisationId,
        paymentAuthority: data.paymentAuthority,
        handlerId: data.handlerId,
        certifiedAsFitAndProper: data.certifiedAsFitAndProper,
        superUser: data.superUser,
        // status: data.status,
        jobTitle: data.jobTitle,
        telephoneNumber: data.telephoneNumber,
        email: data.email,
        isDelete: data.isDelete,

        userRoleId: data.userRoleId,
        userRoleName: data.userRoleName,
        claimsStage1ApprovalAuthority: data.claimsStage1ApprovalAuthority,
        financeApprovalAuthority: data.financeApprovalAuthority,
      }
    }
    
    this.isEditMode = true;
    this.hideAddButton(false);
    this.denyToEditSuperUserItself();
    this.disableAuthorityFields();

  }

  removeButtonClick(data) {
    let isdeleted = true;
    data.isDelete = isdeleted;
    this.deleteClaimHandler(data);
    this.isEditMode = false;
    this.hideform();
    console.log("Remove button call", data);

  }

  getClaimHandlers(organisationId, includeInActive: boolean) {
    this.errorReceived = false;
    if (organisationId > 0) {
      return this.participantClaimHandlerService.getClaimHandlersByOrganisationId(organisationId, includeInActive);
    }
    return;
  }

  getClaimHandlerById(claimHandlerId): Promise<any> {
    var promise = new Promise((resolve, reject) => {

      this.participantClaimHandlerService.getClaimHandler(claimHandlerId).subscribe(claimhandler => {
        resolve(claimhandler);
      })
    })

    return promise;
    //if (claimHandlerId > 0) {
    //  return this.participantClaimHandlerService.getClaimHandler(claimHandlerId);
    //}
  }

  onOrganisationChange(): void {
    //this.hideform();

    if (this.claimHandlerVM.organisationId > 0) {
      var orgid = this.claimHandlerVM.organisationId;
      //show Addclaimhandler button if organisation selected
      this.isAddButtonShow = true;
      this.isShown = false;
      this.claimHandlerReset();
      this.claimHandlerVM.organisationId = orgid;
      this.isEditMode = false;
      if (orgid != 1) {
        //this.userRoles = this.userRoles.filter(({ id }) => id == 1049);  //Claims
        this.userRoles = this.userRoles.filter(({ id }) => id == 1049 || id == 1050);
      }
      //if (orgid != 1 && this.claimHandlerVM.superUser==0) {
      //  this.userRoles = this.userRoles.filter(({ id }) => id == 1049 || id == 1050);
      //}
      else if (orgid === 1) {
        this.userRoles = this.lookUpService.getLookupsByTypeId(47);
      }
      if (this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      }
      else {
        this.dtTrigger.next();
      }
    }
    else {
      //hide Addclaimhandler if no organisation selected
      this.isAddButtonShow = false;
    }
    this.disableAuthorityFields();
  }

  refresh(): void {
    if (confirm('Are you sure want to reset this page?')) {
      if (this.isEditMode == false) {
        let currentOrganisationId = this.claimHandlerVM.organisationId
        this.form.resetForm();
        this.claimHandlerReset();
        this.claimHandlerVM.organisationId = currentOrganisationId;
      }
      else {
        
        let data = this.editableData;
        this.filterDataBasedOnEditButton(data);
      }
    }
  }

  private handleError(error: any) {
    this.errorReceived = true;
    return Observable.throw(error);
  }

  EnableDisableFields() {
    this.isColleaguesSearchFlag = (this.activeRoute.snapshot.queryParams.isColleaguesSearch == 'true');
    if (this.isColleaguesSearchFlag) {
      this.isDisabledFields = true;
    }
    if (!(this.securityService.getUserData().organisationId == 1 && this.securityService.getUserData().superUser == 1)) {
      this.isDisabledFields = true;
    }
  }

  saveClaimHandler() {
    if (this.claimHandlerVM.ID > 0) {
      this.claimHandlerVM.certifiedAsFitAndProper = Number(this.claimHandlerVM.certifiedAsFitAndProper);
      this.claimHandlerVM.superUser = Number(this.claimHandlerVM.superUser);
      this.putClaimHandler(this.claimHandlerVM);
    }
    else {
      this.postClaimHandler();
    }
  }

  postClaimHandler() {
    console.log("postClaimHandler method called");
    this.participantClaimHandlerService.postClaimHandlers(this.claimHandlerVM).subscribe(data => {
      this.notificationService.printSuccessMessage('Claim Handler saved successfully');
      this.claimHandlerReset();
      this.hideform();
      this.onOrganisationChange();
      this.isFormSubmitted = true;
      this.formValidityFlagLoaded.next();
      this.form.form.markAsPristine();      
    },
      error => {
        if (error.error == "Email already exist") {
          this.notificationService.printWarningMessage(error.error);
        }
        else if (error.error == "User does not exist in system") {
          this.notificationService.printErrorMessage(error.error);
        }
        else {
          this.notificationService.printErrorMessage("Error occured  in saving Claim Handler");
        }
      }
    );
  }


  putClaimHandler(data) {
    this.participantClaimHandlerService.putClaimHandlers(data).subscribe(data => {
      this.notificationService.printSuccessMessage('Claim Handler updated successfully');
      this.claimHandlerReset();
      this.hideform();
      this.onOrganisationChange();
      this.isFormSubmitted = true;
      this.formValidityFlagLoaded.next();
      this.form.form.markAsPristine();
    },
      error => {
        if (error.error == "Email already exist") {
          this.notificationService.printWarningMessage(error.error);
        }
        else if (error.error == "User does not exist in system") {
          this.notificationService.printErrorMessage(error.error);
        }
        else {
          this.notificationService.printErrorMessage('Error occured  in updating Claim Handler');
        }
      }
    );
  }

  deleteClaimHandler(data) {
    this.participantClaimHandlerService.putClaimHandlers(data).subscribe(data => {
      this.notificationService.printSuccessMessage('Claim Handler deleted successfully');
      this.onOrganisationChange();
    }),
      error => {
        this.notificationService.printErrorMessage('Error occured  in deleting Claim Handler');
      }
  }


  hideAddButton(showHide) {
    //this.claimHandlerReset();
    this.isAddButtonShow = showHide;
    this.isShown = true;
    //this.isEditMode = false;
    this.isDenyToEditSuperUserItself = false;
    this.authorityDisable = false;
  }

  hideform() {
    let currentOrganisationId = this.claimHandlerVM.organisationId
    this.form.resetForm();
    this.claimHandlerReset();
    this.claimHandlerVM.organisationId = currentOrganisationId;
    this.isAddButtonShow = true;
    this.isShown = false;
    this.isEditMode = false;
    this.editableData = {};
    //this.authorityDisable = false;
  }

  claimHandlerReset() {
    let claimHandlerVMNew = this.claimHandlerVM
    this.claimHandlerVM = new ClaimHandler();
    this.claimHandlerVM.organisationId = claimHandlerVMNew.organisationId;
    this.claimHandlerVM.certifiedAsFitAndProper = null;
    this.claimHandlerVM.superUser = null;
    //this.claimHandlerVM.status = null;
    this.claimHandlerVM.userRoleId = null;
    this.claimHandlerVM.isDelete = null;
  }

  validateForm() {
    this.isValid = true;
    this.isFormSubmitted = true;

    this.isValid = this.validateClaimHandlerProperties();
    
    if (this.form.invalid || !this.isValid) {
      this.isFormSubmitted = false;
      this.formValidityFlagLoaded.next();
      this.isValid = false;
    }

    return this.isValid;
  }

  validateClaimHandlerProperties() {
    this.isValid = true;
    if (this.claimHandlerVM.claimsHandlers == null || this.claimHandlerVM.claimsHandlers == undefined || this.claimHandlerVM.claimsHandlers == '') {
      this.notificationService.printWarningMessage("Please input name.");
      return this.isValid = false;
    }
    if (this.claimHandlerVM.userRoleId == 1048 && this.claimHandlerVM.superUser != 1) {
      this.notificationService.printWarningMessage("(Claims and Finance) user role can only be a superuser.");
      return this.isValid = false;
    }
    if (this.claimHandlerVM.userRoleId == 1051 && this.claimHandlerVM.superUser != 0) {
      this.notificationService.printWarningMessage("(NED) user role cannot be a superuser.");
      return this.isValid = false;
    }
    if (this.claimHandlerVM.userRoleId == 1052 && this.claimHandlerVM.superUser != 0) {
      this.notificationService.printWarningMessage("(Auditor) user role cannot be a superuser.");
      return this.isValid = false;
    }
    if (this.claimHandlerVM.userRoleId == 1050 && this.claimHandlerVM.superUser != 0 && this.claimHandlerVM.organisationId != 1) {
      this.notificationService.printWarningMessage("Finance user role cannot be a superuser.");
      return this.isValid = false;
    }
    return this.isValid;
  }

  onSubmit(form: NgForm) {
    console.log('form', form);
    if (this.validateForm()) {
      //this.loading = true;
      //this.setAggregatedStatus();
      // alert("Saved Data");
      this.saveClaimHandler();
      this.editableData = {};
    }
  }
  navigateToSearch(): void {
    this._router.navigate(['/search-participant']);
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  denyToEditSuperUserItself() {
    if (this.securityService.getUserData().superUser == 1 && this.securityService.getUserData().id == this.claimHandlerVM.ID) {
      this.isDenyToEditSuperUserItself = true;
    }
    else {
      this.isDenyToEditSuperUserItself = false;
    }
  }

  disableAuthorityFields() {
    this.authorityDisable = false;
    if (this.claimHandlerVM.organisationId != 1) {
      if (this.claimHandlerVM.superUser == 0 && this.claimHandlerVM.userRoleId == 1050) {
        this.authorityDisable = true;
        this.claimHandlerVM.handlingAuthority = 0.0;
        this.claimHandlerVM.claimsStage1ApprovalAuthority = 0.0;
        this.claimHandlerVM.financeApprovalAuthority = 0.0;
      }
    }
  }

  findClaimHandlerDetailsById(id) {
    return this.claimHandlerResult.find(
      item => {
        return item.id == id
      }
    )
  }

  public newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    console.log("buttons");
    //console.log($.fn.dataTable.ext.buttons);
    dt.one('preXhr', function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 500;

      dt.one('preDraw', function (e, settings) {
        // Call the original action function
        oldExportAction(self, e, dt, button, config);

        dt.one('preXhr', function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    // Requery the server with the new one-time export settings
    dt.ajax.reload();

    function oldExportAction(self, e, dt, button, config) {
      if (button[0].className.indexOf('buttons-excel') >= 0) {
        //this.datatableElement.dtInstance.
        var buttons: any = ($.fn.dataTable.ext as any).buttons;
        if (buttons.excelHtml5.available(dt, config)) {
          buttons.excelHtml5.action.call(self, e, dt, button, config);
        }
        else {
          buttons.excelFlash.action.call(self, e, dt, button, config);
        }
      } else if (button[0].className.indexOf('buttons-print') >= 0) {
        buttons.print.action(e, dt, button, config);
      }
    }
  };

  public oldExportAction(self, e, dt, button, config) {
    if (button[0].className.indexOf('buttons-excel') >= 0) {
      //this.datatableElement.dtInstance.
      var buttons: any = ($.fn.dataTable.ext as any).buttons;
      if (buttons.excelHtml5.available(dt, config)) {
        buttons.excelHtml5.action.call(self, e, dt, button, config);
      }
      else {
        buttons.excelFlash.action.call(self, e, dt, button, config);
      }
    } else if (button[0].className.indexOf('buttons-print') >= 0) {
      buttons.print.action(e, dt, button, config);
    }
  }

 

}



import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClaimDetailComponent } from './claim-detail/claim-detail.component';
import { ClaimDetailService } from './claim-detail/claim-detail.service';
import { SecurityService } from '../../shared/services/security.service';
import { enumClaimStatus } from '../../shared/models/common.enum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.css']
})
export class ClaimComponent implements OnInit {
  isTabsDisabled = false;
  claimId: string;
  policyId: string;
  isApportionmentAvailble: boolean = false;
  isValuationAvailble: boolean = false;
  mmiClaimReference: string;
  claimantName: string;
  policyholderName: string;
  isNewClaim: boolean = true;
  isClaimSettled: boolean = false;
  isClaimSettledRIOS: boolean = false;
  private claimStatusLoaded = new Subject();
  claimStatusId$ = this.claimStatusLoaded.asObservable();

  constructor(private activeRoute: ActivatedRoute, private router: Router, private claimDetailService: ClaimDetailService,
    private securityService: SecurityService) { }

  ngOnInit(): void {
    this.claimId = this.activeRoute.snapshot.paramMap.get('claimId');
    this.policyId = this.activeRoute.snapshot.paramMap.get('policyId');
    if (this.claimId != null && parseInt(this.claimId) > 0) {
      this.isNewClaim = false;
      this.claimDetailService.isApportionmentAvailble(parseInt(this.claimId)).then(res => {
        this.isApportionmentAvailble = res;
      });
      this.claimDetailService.checkValuationAvailble(parseInt(this.claimId)).then(res => {
        this.isValuationAvailble = res;
      });
      this.claimDetailService.getClaimById(parseInt(this.claimId)).then(res => {
        this.mmiClaimReference = res.mmiClaimsReference
        this.policyholderName = res.schemeCreditorName
        this.claimantName = res.claimantDetail.firstName + ' ' + res.claimantDetail.surname;
        this.isClaimSettled = (res.claimStatus == enumClaimStatus.Settled || res.claimStatus == enumClaimStatus.ReSettled) ? true : false;
        this.isClaimSettledRIOS = (res.claimStatus == enumClaimStatus.SettledRIOS) ? true : false;
        this.claimStatusLoaded.next();
      });
    }
  }

  onActivate(componentReference) {
    if (componentReference.updateHeaderPanel != undefined) {
      componentReference.updateHeaderPanel.subscribe((data) => {
        this.mmiClaimReference = data.controls['mmiClaimsReference'].value;
        this.policyholderName = data.controls['schemeCreditorName'].value;
        this.claimantName = data.controls['claimantDetail'].value.firstName + ' ' + data.controls['claimantDetail'].value.surname;
      });
    }
  }

  navigateToPageComponent(id: number) {
    switch (id) {
      case 1:
        {
          if (this.policyId == null) {
            return false;
          }
          else if (this.claimId == null) {
            this.router.navigate(['/claims/' + this.policyId]);
          }
          else {
            this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId]);
          }
          break;
        }


      case 2:
        {
          if (this.claimId == null) {
            return false;
          }
          else {
            this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/apportionment'])
          }
          break;
        }


      case 3:
        {
          if (this.claimId == null) {
            return false;
          } else {
            this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/valuation']);

          }
          break;
        }
      case 4:
        {
          if (this.claimId == null) {
            return false;
          } else {
            this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/reservehistory']);

          }
          break;
        }
      case 5:
        {
          if (this.claimId == null) {
            return false;
          } else {
            this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/claim-detail-note']);

          }
          break;
        }
      case 6:
        {
          if (this.claimId == null) {
            return false;
          } else {
            this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/payment']);

          }
          break;
        }


      case 7:
        {
          if (this.claimId == null) {
            return false;
          } else {
            //this.router.navigate(['claims/1/1/litigation']);
            this.router.navigate(['/claims/' + this.policyId + '/' + this.claimId + '/litigation']);
          }
          break;
        }

      default:
        {
          return false;
          break
        }
    }
  }

}
